<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <section class="modal-card-body has-text-centered">
    <p class="title is-4"> Save Hub Credentials </p>
    <b-field label="Username" expanded>
      <b-input v-model="username"></b-input>
    </b-field>
    <b-field label="Password" expanded>
      <b-input v-model="password"></b-input>
    </b-field>
    <b-button expanded :disabled="username == null || password == null" type="button is-success" @click="updateValue">Update</b-button>
</section>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      username: null,
      password: null,
    }
  },
  props: [],
  computed: {},
  methods: {
    updateValue() {
      this.$emit('update', {
        'username': this.username,
        'password': this.password,
      });
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
