export default {
  financialInformationEditor: 'Financial Information Editor',
  launchAdmin: 'Launch Admin',
  launchViewer: 'Launch Viewer',
  campaignViewer: 'Campaign Viewer',
  supplyPricesImporter: 'Supply Prices Importer',
  kitchenCampaignViewer: 'Kitchen Campaign Viewer',

  kitchenReferenceUser: "Kitchen Reference User",
  kitchenTechAdministrator: "Kitchen Tech Administrator",
  kitchenLifecycleManager: "Kitchen Lifecycle Manager",
  marketingManagerViewer: "Marketing Manager Viewer",
  marketingManagerUser: "Marketing Manager User",
  kitchenOffboarder: "Kitchen Offboarder",
  userManager: "User Manager",
}
