<template>
  <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close">
    <section class="modal-card-body has-text-centered">
      <div v-if="kitchen.address && !useNewAddress">
        <b-field label="Address" expanded>
          <b-input v-model="kitchen.address" type="text" placeholder="Address" disabled> </b-input>
        </b-field>
        <div class="buttons">
          <b-button expanded type="button is-success" @click="updateTask">Validate Address</b-button>
          <b-button expanded type="button is-warning" @click="displayNewAddress()">{{useAddressButtonText}}</b-button>
        </div>
      </div>
      <div v-if="!kitchen.address || useNewAddress">
        <b> Address </b>
        <b-switch
          v-model="manualAddressMode"
          passive-type='is-success'
          false-value="Google Map"
          true-value="Manual"
          type='is-danger'
          size='is-small'
        >
          {{ manualAddressMode }}
        </b-switch>
        <b-field label="" expanded>
          <div v-if="manualAddressMode == 'Google Map'">
            <vue-google-autocomplete
              id="map"
              v-model="value"
              classname="google-autocomplete"
              v-on:placechanged="getAddressData"
              :country="['fr', 'uk', 'es', 'be', 'nl']">
            </vue-google-autocomplete>
            <b-button class="button-gap" expanded :disabled="value == null" type="button is-success" @click="updateValue">Set Up</b-button>
          </div>
          <div v-else>
            <b-input v-model="manualAddress.address" type="text" placeholder="Address"> </b-input>
            <b-field grouped>
              <b-field label="Country" expanded>
                <b-input v-model="kitchen.country" type="text" required :disabled="true" placeholder="Kitchen Country"> </b-input>
              </b-field>
              <b-field label="City" expanded>
                <b-input v-model="location.city" type="text" required :disabled="true" placeholder="Kitchen City">  </b-input>
              </b-field>
            </b-field>
            <b-field grouped>
              <b-field label="Postcode" expanded>
                <b-input v-model="manualAddress.address_information.postcode" type="text" placeholder="Postcode" required>
                </b-input>
              </b-field>
              <b-field label="Route" expanded>
                <b-input v-model="manualAddress.address_information.route" type="text" placeholder="Route" required>
                </b-input>
              </b-field>
              <b-field label="Street Number" expanded>
                <b-input v-model="manualAddress.address_information.street_number" type="text" placeholder="Street Number">
                </b-input>
              </b-field>
            </b-field>
            <b-field grouped>
              <b-field label="Latitude" expanded>
                <b-input step="0.000000000001" v-model="manualAddress.address_information.latitude" type="number" placeholder="Latitude" required>
                </b-input>
              </b-field>
              <b-field label="Longitude" expanded>
                <b-input step="0.000000000001" v-model="manualAddress.address_information.longitude" type="number" placeholder="Longitude" required>
                </b-input>
              </b-field>
            </b-field>
            <b-button class="button-gap" expanded :disabled="disableManualAddress" type="button is-success" @click="updateManualValue">Set Up</b-button>
          </div>
        </b-field>
      </div>
    </section>
  </b-modal>
</template>

<script>
/* eslint-disable */
import VueGoogleAutocomplete from 'vue-google-autocomplete';

  export default {
  components: { VueGoogleAutocomplete },
  data() {
    return {
      openModal: true,
      value: null,
      useNewAddress: false,
      manualAddressMode: 'Google Map',
      manualAddress: {
        'address': null,
        'address_information': {
          'city': this.location.city,
          'latitude': null,
          'longitude': null,
          'postcode': null,
          'route': null,
          'street_number': null,
        }
      }
    }
  },
  props: ['kitchen', 'location'],
  computed: {
    useAddressButtonText() {
      if (!this.useNewAddress) {
        return 'Use New Address'
      }
      return 'Use Store Address'
    },
    disableManualAddress() {
      return this.manualAddress.address == null || this.manualAddress.address_information.route == null || this.manualAddress.address_information.postcode == null || this.manualAddress.address_information.latitude == null || this.manualAddress.address_information.longitude == null
    }
  },
  methods: {
    displayNewAddress() {
      this.useNewAddress = !this.useNewAddress;
    },
    getAddressData: function(place, addressData, _) {
      this.value = {
        'address': '',
        'address_information': {}
      };
      this.value.address = addressData.formatted_address;
      this.value.address_information.city = this.location.city;
      this.value.address_information.latitude = place.latitude;
      this.value.address_information.longitude = place.longitude;
      this.value.address_information.postcode = place.postal_code;
      this.value.address_information.route = place.route;
      this.value.address_information.street_number = place.street_number;
    },
    close() {
      this.$emit('close');
    },
    updateTask() {
      this.$emit('updateTask');
      this.$emit('close');
    },
    updateManualValue() {
      this.$emit('updateValue', this.manualAddress);
      this.$emit('close');
    },
    updateValue() {
      this.$emit('updateValue', this.value);
      this.$emit('close');
    },
  }
  }
</script>

<style>
.field:not(:last-child) {
  margin-bottom: 0px;
}
.google-autocomplete {
  display: block;
  width: 100%;
  font-size: 12px;
  height: 3.25em;
}
.pac-container {
  position: fixed !important;
}
.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
