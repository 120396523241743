var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box modal-card", staticStyle: { overflow: "visible" } },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("p", { staticClass: "title is-4" }, [_vm._v(" Set Up Menu ")]),
      _c(
        "div",
        [
          _c(
            "b-field",
            { attrs: { label: "Menu", expanded: "" } },
            [
              _c(
                "b-select",
                {
                  attrs: { type: "text", placeholder: "Menu", expanded: "" },
                  model: {
                    value: _vm.selectedMenu,
                    callback: function ($$v) {
                      _vm.selectedMenu = $$v
                    },
                    expression: "selectedMenu",
                  },
                },
                _vm._l(_vm.menusSorted, function (menu, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: menu } },
                    [_vm._v(" " + _vm._s(menu.label) + " ")]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm.selectedMenu
            ? _c(
                "b-button",
                {
                  staticClass: "button-gap",
                  attrs: { type: "is-info", expanded: "" },
                  on: {
                    click: function ($event) {
                      return _vm.select()
                    },
                  },
                },
                [_vm._v(" Select ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }