import axios from '@/axios';
import { baseOnboardingUrlV2 } from '@/apis'


const ressourcePathV2 = `${baseOnboardingUrlV2}/actions`;

export default {
/* eslint-disable */
  exportCatalogue(opts) {
    return axios.post(`${ressourcePathV2}/export_catalogue`, opts)
                .then(response => response.data)
  },
  importCatalogue(opts) {
    return axios.post(`${ressourcePathV2}/import_catalogue`, opts)
                .then(response => response.data)
  },
};
