<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <section class="modal-card-body has-text-centered">
      <div class="columns">
        <div class="column is-11">
        </div>
        <div class="column is-1">
          <b-button expanded type="button is-danger is-small" icon-left="close" rounded @click="close"></b-button>
        </div>
      </div>
      <h4 class="title has-text-centered is-4"> {{task.label}}</h4>
      <b-message title="Description" type="is-info" :closable="false">
         {{task.description}}
      </b-message>
      <div v-if="task.links && task.links.length > 0">
        <div v-for="(link, index) in task.links" :key="index">
          <b-button tag="a"
            type="is-link"
            :href=link.url
            target="_blank">
            {{link.label}}
          </b-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
  data() {
    return {}
  },
  props: ['task'],
  computed: {},
  methods: {
    close() {
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
