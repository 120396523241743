import hubsAPI from '../../../apis/supply/hubs';
import { hubTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  fetchAll: {
    pending: false,
    error: null,
  },
};

const actions = {
  async fetchAll({ commit }, payload) {
    commit(hubTypes.FETCH_ALL_REQUEST);
    return hubsAPI.fetchAll(payload)
      .then((menus) => commit(hubTypes.FETCH_ALL_SUCCESS, menus))
      .catch((error) => commit(hubTypes.FETCH_ALL_FAILURE, error));
  },
};

const mutations = {
  [hubTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [hubTypes.FETCH_ALL_SUCCESS](state, response) {
    state.all = response.items;
    state.fetchAll.pending = false;
  },
  [hubTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
