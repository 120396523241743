var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-field",
        { attrs: { grouped: "" } },
        [
          _c(
            "b-field",
            {
              staticClass: "control",
              attrs: { label: "Launch UUID", expanded: "" },
            },
            [
              _c("b-input", {
                attrs: {
                  type: "text",
                  required: "",
                  disabled: true,
                  placeholder: "Launch UUID",
                },
                model: {
                  value: _vm.launch.uuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.launch, "uuid", $$v)
                  },
                  expression: "launch.uuid",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              staticClass: "control",
              attrs: { label: "Kitchen UUID", expanded: "" },
            },
            [
              _c("b-input", {
                attrs: {
                  type: "text",
                  required: "",
                  disabled: true,
                  placeholder: "Kitchen UUID",
                },
                model: {
                  value: _vm.kitchen.uuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.kitchen, "uuid", $$v)
                  },
                  expression: "kitchen.uuid",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              staticClass: "control",
              attrs: { label: "Location UUID", expanded: "" },
            },
            [
              _c("b-input", {
                attrs: {
                  type: "text",
                  required: "",
                  disabled: true,
                  placeholder: "Location UUID",
                },
                model: {
                  value: _vm.kitchen.location_uuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.kitchen, "location_uuid", $$v)
                  },
                  expression: "kitchen.location_uuid",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              staticClass: "control",
              attrs: { label: "Concept UUID", expanded: "" },
            },
            [
              _c("b-input", {
                attrs: {
                  type: "text",
                  required: "",
                  disabled: true,
                  placeholder: "Concept UUID",
                },
                model: {
                  value: _vm.launch.concept_uuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.launch, "concept_uuid", $$v)
                  },
                  expression: "launch.concept_uuid",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(
        _vm.kitchenRestaurants.filter(function (restaurant) {
          return restaurant.concept_uuid == _vm.launch.concept_uuid
        }),
        function (restaurant, index) {
          return _c(
            "b-field",
            { key: index, attrs: { grouped: "", value: restaurant } },
            [
              _c(
                "b-field",
                { staticClass: "control", attrs: { label: "Platform" } },
                [
                  _c("b-input", {
                    attrs: {
                      type: "text",
                      required: "",
                      disabled: true,
                      placeholder: "Platform",
                    },
                    model: {
                      value: restaurant.platform_restaurant.label,
                      callback: function ($$v) {
                        _vm.$set(restaurant.platform_restaurant, "label", $$v)
                      },
                      expression: "restaurant.platform_restaurant.label",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  staticClass: "control",
                  attrs: { label: "Platform UUID", expanded: "" },
                },
                [
                  _c("b-input", {
                    attrs: {
                      type: "text",
                      required: "",
                      disabled: true,
                      placeholder: "Platform UUID",
                    },
                    model: {
                      value: restaurant.platform_uuid,
                      callback: function ($$v) {
                        _vm.$set(restaurant, "platform_uuid", $$v)
                      },
                      expression: "restaurant.platform_uuid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  staticClass: "control",
                  attrs: { label: "Restaurant UUID", expanded: "" },
                },
                [
                  _c("b-input", {
                    attrs: {
                      type: "text",
                      required: "",
                      disabled: true,
                      placeholder: "Restaurant UUID",
                    },
                    model: {
                      value: restaurant.uuid,
                      callback: function ($$v) {
                        _vm.$set(restaurant, "uuid", $$v)
                      },
                      expression: "restaurant.uuid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  staticClass: "control",
                  attrs: { label: "Restaurant Platform ID", expanded: "" },
                },
                [
                  _c("b-input", {
                    attrs: {
                      type: "text",
                      required: "",
                      disabled: true,
                      placeholder: "Restaurant Platform ID",
                    },
                    model: {
                      value: restaurant.restaurant_platform_id,
                      callback: function ($$v) {
                        _vm.$set(restaurant, "restaurant_platform_id", $$v)
                      },
                      expression: "restaurant.restaurant_platform_id",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }