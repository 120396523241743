var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-dropdown",
        {
          attrs: {
            multiple: "",
            scrollable: _vm.isScrollable,
            "max-height": _vm.maxHeight,
            "aria-role": "list",
          },
          on: { "active-change": _vm.selectCampaignTimeline },
          model: {
            value: _vm.currentCampaignTimelines,
            callback: function ($$v) {
              _vm.currentCampaignTimelines = $$v
            },
            expression: "currentCampaignTimelines",
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "button is-info",
              attrs: { slot: "trigger", type: "button" },
              slot: "trigger",
            },
            [
              _c("span", [
                _vm._v(
                  " Campaign Timelines (" +
                    _vm._s(_vm.currentCampaignTimelines.length) +
                    ")"
                ),
              ]),
              _c("b-icon", { attrs: { icon: "menu-down" } }),
            ],
            1
          ),
          _vm._l(_vm.campaignTimelines, function (campaignTimeline, index) {
            return _c(
              "b-dropdown-item",
              {
                key: index,
                attrs: { value: campaignTimeline, "aria-role": "listitem" },
              },
              [_c("span", [_vm._v(" " + _vm._s(campaignTimeline) + " ")])]
            )
          }),
        ],
        2
      ),
      _c(
        "b-dropdown",
        {
          attrs: { "aria-role": "list" },
          on: { "active-change": _vm.selectDates },
          model: {
            value: _vm.currentDates,
            callback: function ($$v) {
              _vm.currentDates = $$v
            },
            expression: "currentDates",
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "button is-info",
              attrs: { slot: "trigger", type: "button" },
              slot: "trigger",
            },
            [
              _vm.currentDates.length == 2
                ? _c("span", [
                    _vm._v(
                      " Dates (" +
                        _vm._s(
                          _vm.formatDateWord(_vm.currentDates[0]) +
                            " - " +
                            _vm.formatDateWord(_vm.currentDates[1])
                        ) +
                        ")"
                    ),
                  ])
                : _c("span", [_vm._v(" Dates ")]),
              _c("b-icon", { attrs: { icon: "menu-down" } }),
            ],
            1
          ),
          _c("b-datepicker", {
            attrs: {
              inline: "",
              "max-date": _vm.maxDate,
              "min-date": _vm.minDate,
              locale: "fr-Fr",
              icon: "calendar-today",
              position: "is-bottom-right",
              range: "",
            },
            model: {
              value: _vm.currentDates,
              callback: function ($$v) {
                _vm.currentDates = $$v
              },
              expression: "currentDates",
            },
          }),
          _vm.currentDates.length == 2
            ? _c(
                "b-button",
                {
                  staticClass: "button",
                  attrs: { expanded: "", type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.clearDates()
                    },
                  },
                },
                [_vm._v("Clear")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-dropdown",
        {
          attrs: {
            scrollable: _vm.isScrollable,
            "max-height": _vm.maxHeight,
            "aria-role": "list",
          },
          on: { "active-change": _vm.selectCountry },
          model: {
            value: _vm.currentCountry,
            callback: function ($$v) {
              _vm.currentCountry = $$v
            },
            expression: "currentCountry",
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "button is-info",
              attrs: { slot: "trigger", type: "button" },
              slot: "trigger",
            },
            [
              _vm.currentCountry.label == "All"
                ? _c("span", [
                    _vm._v(
                      "Countries (" + _vm._s(_vm.activeCountries.length) + ")"
                    ),
                  ])
                : _c("span", [_vm._v("Countries (1)")]),
              _c("b-icon", { attrs: { icon: "menu-down" } }),
            ],
            1
          ),
          _vm._l(_vm.activeCountries, function (country, index) {
            return _c(
              "b-dropdown-item",
              {
                key: index,
                attrs: { value: country, "aria-role": "listitem" },
              },
              [_c("span", [_vm._v(" " + _vm._s(country.label) + " ")])]
            )
          }),
        ],
        2
      ),
      _c(
        "b-dropdown",
        {
          attrs: {
            scrollable: _vm.isScrollable,
            "max-height": _vm.maxHeight,
            "aria-role": "list",
          },
          on: { "active-change": _vm.selectConcept },
          model: {
            value: _vm.currentConcept,
            callback: function ($$v) {
              _vm.currentConcept = $$v
            },
            expression: "currentConcept",
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "button is-info",
              attrs: { slot: "trigger", type: "button" },
              slot: "trigger",
            },
            [
              _vm.currentConcept.label == "All"
                ? _c("span", [
                    _vm._v(
                      "Concepts (" + _vm._s(_vm.activeConcepts.length) + ")"
                    ),
                  ])
                : _c("span", [_vm._v("Concepts (1)")]),
              _c("b-icon", { attrs: { icon: "menu-down" } }),
            ],
            1
          ),
          _vm._l(_vm.activeConcepts, function (concept, index) {
            return _c(
              "b-dropdown-item",
              {
                key: index,
                attrs: { value: concept, "aria-role": "listitem" },
              },
              [_c("span", [_vm._v(" " + _vm._s(concept.label) + " ")])]
            )
          }),
        ],
        2
      ),
      _c(
        "b-dropdown",
        {
          attrs: {
            scrollable: _vm.isScrollable,
            "max-height": _vm.maxHeight,
            "text-align": "center",
          },
          on: { "active-change": _vm.selectPlatform },
          model: {
            value: _vm.currentPlatform,
            callback: function ($$v) {
              _vm.currentPlatform = $$v
            },
            expression: "currentPlatform",
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "button is-info",
              attrs: { slot: "trigger", type: "button" },
              slot: "trigger",
            },
            [
              _vm.currentPlatform.label == "All"
                ? _c("span", [
                    _vm._v(
                      "Platforms (" + _vm._s(_vm.activePlatforms.length) + ")"
                    ),
                  ])
                : _c("span", [_vm._v("Platforms (1)")]),
              _c("b-icon", { attrs: { icon: "menu-down" } }),
            ],
            1
          ),
          _vm._l(_vm.activePlatforms, function (platform, index) {
            return _c(
              "b-dropdown-item",
              {
                key: index,
                attrs: { value: platform, "aria-role": "listitem" },
              },
              [_c("span", [_vm._v(" " + _vm._s(platform.label) + " ")])]
            )
          }),
        ],
        2
      ),
      _c(
        "b-dropdown",
        {
          attrs: {
            scrollable: _vm.isScrollable,
            "max-height": _vm.maxHeight,
            "text-align": "center",
          },
          on: { "active-change": _vm.selectCampaignType },
          model: {
            value: _vm.currentCampaignType,
            callback: function ($$v) {
              _vm.currentCampaignType = $$v
            },
            expression: "currentCampaignType",
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "button is-info",
              attrs: { slot: "trigger", type: "button" },
              slot: "trigger",
            },
            [
              _vm.currentCampaignType == "All"
                ? _c("span", [
                    _vm._v(
                      "Campaign Types (" +
                        _vm._s(_vm.campaignTypes.length) +
                        ")"
                    ),
                  ])
                : _c("span", [_vm._v("Campaign Types (1)")]),
              _c("b-icon", { attrs: { icon: "menu-down" } }),
            ],
            1
          ),
          _vm._l(_vm.campaignTypes, function (campaignType, index) {
            return _c(
              "b-dropdown-item",
              {
                key: index,
                attrs: { value: campaignType, "aria-role": "listitem" },
              },
              [_c("span", [_vm._v(" " + _vm._s(campaignType) + " ")])]
            )
          }),
        ],
        2
      ),
      _vm.displayLocationFilter
        ? _c(
            "b-dropdown",
            {
              attrs: {
                scrollable: _vm.isScrollable,
                "max-height": _vm.maxHeight,
                "text-align": "center",
              },
              on: { "active-change": _vm.selectLocation },
              model: {
                value: _vm.currentLocation,
                callback: function ($$v) {
                  _vm.currentLocation = $$v
                },
                expression: "currentLocation",
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "button is-info",
                  attrs: { slot: "trigger", type: "button" },
                  slot: "trigger",
                },
                [
                  _vm.currentLocation == "All"
                    ? _c("span", [
                        _vm._v(
                          " Kitchens (" +
                            _vm._s(_vm.activeLocations.length) +
                            ")"
                        ),
                      ])
                    : _c("span", [_vm._v("Kitchens (1)")]),
                  _c("b-icon", { attrs: { icon: "menu-down" } }),
                ],
                1
              ),
              _vm._l(_vm.activeLocations, function (location, index) {
                return _c(
                  "b-dropdown-item",
                  {
                    key: index,
                    attrs: { value: location, "aria-role": "listitem" },
                  },
                  [_c("span", [_vm._v(" " + _vm._s(location.label) + " ")])]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.filtersToApply
        ? _c(
            "b-button",
            {
              staticClass: "is-success fil",
              on: {
                click: function ($event) {
                  return _vm.applyFilters()
                },
              },
            },
            [_vm._v(" Apply ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }