var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card my-modal",
      staticStyle: { overflow: "visible" },
    },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("div", { staticClass: "columns" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "column" },
          [
            _c(
              "b-button",
              {
                attrs: {
                  "icon-left": "plus",
                  rounded: "",
                  type: "is-link is-small",
                },
                on: {
                  click: function ($event) {
                    return _vm.openCreateTaskModal()
                  },
                },
              },
              [_vm._v(" New ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-tabs",
        {
          attrs: { expanded: "" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "b-tab-item",
            { attrs: { label: "Task Groups" } },
            [
              _c("b-table", {
                attrs: {
                  data: _vm.activeTaskGroups,
                  striped: true,
                  hoverable: true,
                  scrollable: true,
                  "per-page": 10,
                  paginated: true,
                  "default-sort": ["label", "asc"],
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "b-table-column",
                          {
                            attrs: {
                              field: "label",
                              label: "Label",
                              searchable: "",
                              sortable: "",
                            },
                          },
                          [_vm._v(" " + _vm._s(props.row.label) + " ")]
                        ),
                        _c(
                          "b-table-column",
                          { attrs: { filed: "actions", label: "Actions" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { type: "is-link is-small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openTaskGroupForm(props.row)
                                  },
                                },
                              },
                              [_vm._v(" Edit ")]
                            ),
                            props.row.task_uuids &&
                            props.row.task_uuids.length > 0
                              ? _c(
                                  "b-button",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { type: "is-info is-small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToTasks(props.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" Go to Tasks ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "b-tab-item",
            { attrs: { label: "Tasks" } },
            [
              _vm.selectedTaskGroup
                ? _c(
                    "b-tag",
                    {
                      attrs: {
                        type: "is-warning",
                        "close-type": "is-danger",
                        closable: "",
                        attached: "",
                        "close-icon": "delete",
                      },
                      on: {
                        close: function ($event) {
                          _vm.selectedTaskGroup = null
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.selectedTaskGroup.label) + " ")]
                  )
                : _vm._e(),
              _c("hr"),
              _c("b-table", {
                attrs: {
                  data: _vm.activeTasks,
                  striped: true,
                  hoverable: true,
                  scrollable: true,
                  "per-page": 10,
                  paginated: true,
                  "default-sort": ["label", "asc"],
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "b-table-column",
                          {
                            attrs: {
                              field: "label",
                              label: "Label",
                              searchable: "",
                              sortable: "",
                            },
                          },
                          [_vm._v(" " + _vm._s(props.row.label) + " ")]
                        ),
                        _c(
                          "b-table-column",
                          {
                            attrs: {
                              field: "task_group_label",
                              label: "TaskGroup",
                              sortable: "",
                            },
                          },
                          [
                            props.row.task_group_label
                              ? _c("b-tag", { attrs: { type: "is-info" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(props.row.task_group_label) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "b-table-column",
                          {
                            attrs: {
                              field: "owner",
                              label: "Owner",
                              sortable: "",
                            },
                          },
                          [
                            _c("b-tag", { attrs: { type: "is-primary" } }, [
                              _vm._v(" " + _vm._s(props.row.owner) + " "),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "b-table-column",
                          { attrs: { label: "Actions" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { type: "is-link is-small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openTaskForm(props.row)
                                  },
                                },
                              },
                              [_vm._v(" Edit ")]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "button is-small is-danger",
                                staticStyle: { "margin-left": "5px" },
                                attrs: { title: "delete" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteTaskConfirm(props.row)
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: { icon: "delete", size: "is-small" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("p", { staticClass: "title is-4" }, [_vm._v(" Tasks Manager ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }