<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <section class="modal-card-body has-text-centered">
      <b-field
        label="Restaurant Platform ID"
        :type="validationType()"
        :message="validationMessage()"
      >
        <b-input v-model="value"></b-input>
      </b-field>
    </section>
      <b-button expanded
        v-if="isValid() && !isInternalLaunch && platformThatCanBeCheckUuids.includes(platformUUID)"
        type="button is-link"
        style="margin-bottom: 16px"
        tag="a"
        target="_blank"
        :href="platformCheckUrl"
        >
          Verify
      </b-button>
      <b-button expanded :disabled="!isValid()" type="button is-success" @click="updateValue">Set Up</b-button>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      value: null,
      platformThatCanBeCheckUuids: ['15982f81-da4f-4035-b528-740f9664e5bd', '98fee123-068c-487a-af61-efcee6cb85fe'],
      platformsWithUuid: ['15982f81-da4f-4035-b528-740f9664e5bd', '36d8e8b3-5602-4d45-861e-8bff8a439ab4'],
      platformsWithNumber: ['712312b2-52df-4661-8f5e-1dc526ed2a48', '98fee123-068c-487a-af61-efcee6cb85fe', 'bf02c476-5512-4f33-8556-a6700049274c'],
    }
  },
  props: ['isInternalLaunch', 'platformUUID'],
  computed: {
    platformCheckUrl: function() {
      if (this.platformUUID == '98fee123-068c-487a-af61-efcee6cb85fe') {
        return `https://deliveroo.co.uk/orderapp/v1/restaurants/${this.value}?include_unavailable=true&restaurant_fulfillments_supported=true`
      }
      return `https://cn.cfe.uber.com/rt/eats/v2/eater-store/${this.value}`
    }
  },
  methods: {
    isValid() {
      const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      const numberRegex = /^\d+$/;
      return (this.platformsWithUuid.includes(this.platformUUID) && uuidRegex.test(this.value)) || (this.platformsWithNumber.includes(this.platformUUID) && numberRegex.test(this.value))
    },
    validationType() {
      if (!this.isValid()) {
        return 'is-danger';
      }
      return 'is-light';
    },
    validationMessage() {
      if (!this.isValid()) {
        return 'Not Correct Format';
      }
      return '';
    },
    updateValue() {
      this.$emit('updateValue', this.value);
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
