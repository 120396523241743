<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <section class="modal-card-body has-text-centered">
      <b-field label="Menu UUID Source" expanded>
        <b-input type="text" size="is-small" rounded placeholder="menuUUIDSource" v-model="menuUUIDSource" expanded></b-input>
      </b-field>
      <b-field label="Menu UUID Destination" expanded>
        <b-input type="text" size="is-small" rounded placeholder="menuUUIDDestination" v-model="menuUUIDDestination" expanded></b-input>
      </b-field>
    </section>
      <b-button expanded :disabled="menuUUIDSource == null || menuUUIDDestination == null" type="button is-success" @click="updateValue">Set Up</b-button>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      menuUUIDSource: null,
      menuUUIDDestination: null,
    }
  },
  props: ['launch'],
  computed: {},
  methods: {
    updateValue() {
      this.$emit('update', {source_menu_uuid: this.menuUUIDSource, destination_menu_uuid: this.menuUUIDDestination});
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
