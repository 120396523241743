<template>
  <div class="box modal-card" style="overflow: visible;">
  <b-loading :active.sync="isLoading"></b-loading>
    <p class="title is-4">Actions</p>
    <div class="is-flex-direction-column is-justify-content-flex-end">
      <div class="has-text-centered">
        <b-button type="is-success" class="button-gap" @click="openOnboardingNewModal()" expanded>
          New Opening
        </b-button>
      </div>
      <div class="has-text-centered">
        <b-button type="is-warning" class="button-gap" @click="openReopenModal()" expanded>
          Re-Opening
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import NewLaunchModal from '@/components/Onboarding/Launch/NewLaunchModal.vue';
import ReopenModal from '@/components/Onboarding/Launch/ReopenModal.vue';

export default {
  components: {},
  props: ['countries', 'concepts', 'locations', 'platforms'],
  data(){
    return {}
  },
  computed: mapState({
    isLoading: (state) => state.kitchens.fetchAll.pending,
    kitchens: (state) =>
        state.kitchens.all
          .filter((kitchen) => !kitchen.is_deleted)
          .sort((kitchen1, kitchen2) => kitchen1.id.localeCompare(kitchen2.id))
  }),
  methods: {
    openOnboardingNewModal: function() {
      this.$buefy.modal.open({
        component: NewLaunchModal,
        parent: this,
        hasModalCard: true,
        fullScreen: true,
        events: {
          closed() {}
        },
        props: {
          concepts: this.concepts,
          countries: this.countries,
          platforms: this.platforms,
          kitchens: this.kitchens,
          locations: this.locations,
        },
        customClass: 'my-modal',
      });
    },
    openReopenModal: function() {
      this.$buefy.modal.open({
        component: ReopenModal,
        parent: this,
        fullScreen: true,
        hasModalCard: true,
        events: {
          closed() {this.$parent.close()}
        },
        props: {
          concepts: this.concepts,
          countries: this.countries,
          platforms: this.platforms,
          kitchens: this.kitchens,
          locations: this.locations,
        },
        customClass: 'my-modal',
      });
    },
  },
  mounted() {}
};
</script>

<style>
.my-modal .modal-close {
  background: red;
}
</style>
