import axios from '@/axios';
import { baseOnboardingUrlV2 } from '@/apis'


const ressourcePathV2 = `${baseOnboardingUrlV2}/accesses`;

export default {
  /* eslint-disable */
  fetchUser(userUUID) {
    return axios.get(`${ressourcePathV2}/users/${userUUID}`)
                .then(response => response.data)
  },
  createUser(opts) {
    return axios.post(`${ressourcePathV2}/users`, opts)
    .then(response => response.data)
  },
  editRights(opts) {
    var userUUID = opts.userUUID;
    delete opts.userUUID;
    return axios.post(`${ressourcePathV2}/users/${userUUID}/rights`, opts)
                .then(response => response.data)
  },
  fetchGoogleGroups(opts) {
    return axios.get(`${ressourcePathV2}/google_groups`, opts)
                  .then(response => response.data)
  },
  fetchGroups(opts) {
    return axios.get(`${ressourcePathV2}/groups`, opts)
    .then(response => response.data)
  },
  fetchUsers(opts) {
    return axios.get(`${ressourcePathV2}/users`, opts)
    .then(response => response.data)
  },
  removeUser(userUUID) {
    return axios.delete(`${ressourcePathV2}/users/${userUUID}`)
    .then(response => response.data)
  },
};
