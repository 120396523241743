var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.openModal,
            scroll: "keep",
            "on-cancel": _vm.close,
          },
          on: {
            "update:active": function ($event) {
              _vm.openModal = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _vm._v(_vm._s(_vm.message)),
                ]),
                _c("button", {
                  staticClass: "delete",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                }),
              ]),
              _c(
                "section",
                { staticClass: "modal-card-body" },
                [
                  _c("div", { staticClass: "content" }, [
                    _vm._v(" Are you sure you want to Opt-Out "),
                    _c("b", [_vm._v(_vm._s(_vm.kitchenLabel))]),
                    _vm._v(
                      " from the following campaign `" +
                        _vm._s(_vm.campaignInformation.label) +
                        "`? "
                    ),
                  ]),
                  _c(
                    "b-field",
                    { attrs: { label: "Reason", expanded: "" } },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: { placeholder: "Reason", expanded: "" },
                          model: {
                            value: _vm.reason,
                            callback: function ($$v) {
                              _vm.reason = $$v
                            },
                            expression: "reason",
                          },
                        },
                        _vm._l(_vm.optOutReasons, function (reason, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: reason } },
                            [_vm._v(" " + _vm._s(reason) + " ")]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { attrs: { label: "Comments", expanded: "" } },
                    [
                      _c("b-input", {
                        attrs: {
                          type: "text",
                          required: "",
                          placeholder: "Comments",
                        },
                        model: {
                          value: _vm.comment,
                          callback: function ($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "footer",
                { staticClass: "modal-card-foot" },
                [
                  _c("b-button", {
                    attrs: {
                      expanded: "",
                      label: "Close",
                      type: "button is-warning",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  }),
                  _c("b-button", {
                    attrs: {
                      disabled: _vm.comment == null || _vm.reason == null,
                      expanded: "",
                      label: "Opt Out",
                      type: "button is-danger",
                    },
                    on: { click: _vm.update },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }