<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <section class="modal-card-body has-text-centered">
      <p class="title is-4"> Ask for Tech Activation </p>
        <b-steps v-model="activeStep" size="is-small" type="is-info" :has-navigation="false">
          <b-step-item label="Integration Type" step="1">
            <div>
              <div class="has-text-centered">
                <b-button type="is-link" class="button-gap" @click="goToStep(1)" expanded>
                  Tablet Integration
                </b-button>
              </div>
              <!-- <div class="has-text-centered">
                <b-button type="is-danger" class="button-gap" @click="goToStep(2)" expanded :disabled="restaurant.concept_restaurant.is_internal">
                  Tabletless Integration
                </b-button>
              </div> -->
              <div class="has-text-centered">
                <b-button type="is-warning" class="button-gap" @click="sendAuto()" expanded>
                  Tablet Integration Automatique (Beta)
                </b-button>
              </div>
            </div>
          </b-step-item>
          <b-step-item label="Tablet Integration" step="2">
            <b-table :data="steps">
              <template slot-scope="props">
                <b-table-column field="id" label="ID">
                    <b-tag type="is-info"> {{ props.row.id }} </b-tag>
                </b-table-column>
                <b-table-column field="step" label="Step">
                    {{ props.row.step }}
                </b-table-column>
                <b-table-column field="info" label="Info">
                  <b-button
                    v-if="props.row.link"
                    tag="a"
                    type="is-link"
                    :href=props.row.link.url
                    target="_blank">
                    {{props.row.link.label}}
                  </b-button>
                  <div v-else>
                    <strong> {{ props.row.info }} </strong>
                  </div>
                </b-table-column>
              </template>
            </b-table>
            <div class="has-text-centered">
              <b-button expanded type="button is-success" @click="updateValue">Done</b-button>
            </div>
          </b-step-item>
          <b-step-item label="Tabletless Integration" step="3">
            <section class="modal-card-body has-text-centered">
              <b-field label="Your Taster Mail (e.g elsa@taster.com sebastien.rybka@taster.com...)" expanded>
                <b-input type="email" v-model="sender"></b-input>
              </b-field>
            </section>
              <b-button expanded :disabled="sender == null || !sender.includes('@taster')" type="button is-success" @click="send">Send Mail</b-button>
          </b-step-item>
        </b-steps>
    </section>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      activeStep: 0,
      sender: null,
      steps: [
        {
          'id': 1,
          'step': 'Go to Activation Site',
          'link': {
            'label': 'Activation Site',
            'url': 'https://deliveroo.atlassian.net/servicedesk/customer/portal/10/create/272',
            'info': null,
          }
        },
        {
          'id': 2,
          'step': 'Select Your POS',
          'link': null,
          'info': '`Taster`',
        },
        {
          'id': 3,
          'step': 'Deliveroo ID',
          'link': null,
          'info': `${this.restaurant.restaurant_platform_id}`,
        },
        {
          'id': 4,
          'step': 'Brand ID',
          'link': null,
          'info': '`taster-ownpos-fr` for France, `tasterbe-be` for Belgium and `taster-uk-gb` for UK',
        },
        {
          'id': 5,
          'step': 'Click on `Send`',
          'link': null,
          'info': '',
        },
      ],
    }
  },
  props: ['restaurant'],
  computed: {},
  methods: {
    goToStep(activeStep) {
      this.activeStep = activeStep;
    },
    updateValue() {
      this.$emit('update', {});
      this.$emit('close');
    },
    send() {
      this.$emit('send', this.sender);
      this.$emit('close');
    },
    sendAuto() {
      this.$emit('sendAuto');
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
