import axios from '@/axios';
import { baseMenuManagerUrl } from '@/apis'


const ressourcePath = `${baseMenuManagerUrl}/menus`;
const ressourcePathVariant = `${baseMenuManagerUrl}/menu-variants`;


export default {
/* eslint-disable */
  fetchAll(opts) {
    return axios.get(`${ressourcePath}${opts}`).then(response => response.data)
  },
  fetchAllVariants(opts) {
    return axios.get(`${ressourcePathVariant}${opts}`).then(response => response.data)
  },
};
