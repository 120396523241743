var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "section" }, [
    _c("div", { staticClass: "container" }, [
      _c("h1", { staticClass: "title has-text-dark" }, [
        _c("div", { staticClass: "columns is-mobile" }, [
          _c("div", { staticClass: "column is-narrow" }, [
            _vm._v(" " + _vm._s(_vm.$t("concepts.title")) + " "),
          ]),
          _c("div", { staticClass: "column" }, [
            _c(
              "button",
              {
                staticClass: "add-button button is-success is-small",
                on: {
                  click: function ($event) {
                    return _vm.OpenConceptModal(null)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("concepts.add")) + " ")]
            ),
          ]),
        ]),
      ]),
      _c("hr"),
      _c("section", [
        _vm.concepts.length > 0
          ? _c(
              "section",
              [
                _c("ConceptTable", {
                  attrs: { concepts: _vm.concepts },
                  on: {
                    activate: _vm.activateConcept,
                    edit: _vm.OpenConceptModal,
                    upload_picture: _vm.openPictureModal,
                  },
                }),
                _c("hr"),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }