<template>
  <div class="box modal-card" style="overflow: visible;">
    <b-loading :active.sync="isLoading"></b-loading>
    <p class="title is-4"> Set Up Recipes </p>
    <div class="has-text-centered">
      <h4 class="title has-text-centered is-4">Select Among Existing Kitchens</h4>
        <b-field grouped>
          <b-field label="Country" >
            <b-select
              type="text"
              v-model="selectedCountry"
              placeholder="Country"
              expanded
            >
              <option v-for="(kitchenCountry, index) in getExistingKitchenCountries(countries, locations, kitchens)" :key="index" :value="kitchenCountry.uuid">
                {{ kitchenCountry.label }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Licensee">
            <b-select
              type="text"
              v-model="selectedPartner"
              placeholder="Licensee"
              expanded
            >
              <option v-for="(kitchenStat, index) in kitchenStatus" :key="index" :value="kitchenStat.value">
                {{ kitchenStat.label }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Kitchen" expanded>
            <b-select
              type="text"
              v-model="selectedExisingKitchen"
              placeholder="Kitchen"
              expanded
            >
              <option v-for="(kitchen, index) in getExistingKitchens(selectedCountry, selectedPartner, locations, kitchens, false, kitchenUUID)" :key="index" :value="kitchen">
                {{ kitchen.id }}
              </option>
            </b-select>
          </b-field>
        </b-field>
        <b-button v-if="selectedCountry && selectedPartner != null && selectedExisingKitchen" type="is-info" class="button-gap"  @click="copy(selectedExisingKitchen)" expanded>
          Copy
        </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { utils } from '@/mixins';

export default {
  mixins: [utils],
  components: {
  },
  props: ['conceptUUID', 'kitchenUUID'],
  data() {
    return {
      activeStep: 0,
      kitchenStatus: [
        { label: 'Franchise', value: true },
        { label: 'Taster', value: false },
      ],
      selectedCountry: null,
      selectedPartner: null,
      selectedExisingKitchen: null,
    }
  },
  computed: mapState({
    countries: (state) => state.countries.all,
    locations: (state) => state.locations.all,
    kitchens: (state) =>
      state.kitchens.all.filter(kitchen => !kitchen.is_deleted).sort((kitchen1, kitchen2) =>
        kitchen1.id.localeCompare(kitchen2.id),
    ),
    isLoading: (state) => state.kitchens.fetchAll.pending,
  }),
  methods: {
    copy(selectedKitchen) {
      this.$emit('update', {
          concept_uuid: this.conceptUUID,
          kitchen_uuid_destination: this.kitchenUUID,
          kitchen_uuid_source: selectedKitchen.uuid,
      })
      this.$emit('close');
    },
  },
  mounted() {
    this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false', true);
  }
}
</script>


<style>

.my-modal .modal-close {
  background: red;
}

</style>
