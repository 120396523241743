import axios from '@/axios';
import { baseMenuManagerUrl } from '@/apis'


const ressourcePath = `${baseMenuManagerUrl}/menu-items`;


export default {
/* eslint-disable */
  fetchAll(opts) {
    return axios.get(ressourcePath, {params: opts}).then(response => response.data)
  },
};
