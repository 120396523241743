var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.openModal,
            scroll: "keep",
            "on-cancel": _vm.close,
            "full-screen": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.openModal = $event
            },
          },
        },
        [
          _c("section", [
            _c("div", { staticClass: "card", staticStyle: { width: "auto" } }, [
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "columns is-flex" }, [
                  _c(
                    "div",
                    { staticClass: "column is-narrow has-text-centered" },
                    [
                      _c("p", { staticClass: "title is-4" }, [
                        _vm._v(" " + _vm._s(_vm.campaignTitle)),
                      ]),
                    ]
                  ),
                  _vm.campaignConcept
                    ? _c("div", { staticClass: "column is-narrow" }, [
                        _c("img", {
                          staticClass: "concept-logo",
                          attrs: {
                            src: _vm.campaignConcept.logo,
                            alt: _vm.campaignConcept.label,
                            onerror: "this.style.display='none'",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.campaignPlatform
                    ? _c("div", { staticClass: "column is-narrow" }, [
                        _c("img", {
                          staticClass: "concept-logo",
                          attrs: {
                            src: _vm.campaignPlatform.logo,
                            alt: _vm.campaignPlatform.label,
                            onerror: "this.style.display='none'",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c(
                "section",
                { staticClass: "card-content" },
                [
                  _c(
                    "b-steps",
                    {
                      attrs: {
                        size: "is-small",
                        type: "is-info",
                        "has-navigation": false,
                        clickable: false,
                      },
                      model: {
                        value: _vm.step,
                        callback: function ($$v) {
                          _vm.step = $$v
                        },
                        expression: "step",
                      },
                    },
                    [
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Campaign General Configuration",
                            step: "1",
                            clickable: false,
                          },
                        },
                        [
                          _c("hr"),
                          _c("CampaignGeneralForm", {
                            attrs: {
                              activeStep: _vm.activeStepComputed,
                              concepts: _vm.concepts,
                              countries: _vm.countries,
                              locations: _vm.locations,
                              platforms: _vm.platforms,
                              events: _vm.events,
                              isAdhocCampaign: _vm.isAdhocCampaign,
                              campaign: _vm.currentCampaign,
                              isEditingMode: false,
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStepComputed = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStepComputed = $event
                              },
                              "update:campaign": function ($event) {
                                _vm.currentCampaign = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Campaign Type Configuration",
                            step: "2",
                            clickable: false,
                          },
                        },
                        [
                          _c("hr"),
                          _c("CampaignTypeForm", {
                            attrs: {
                              countryLocation: _vm.campaignCountryLocation,
                              campaign: _vm.currentCampaign,
                              isAdhocCampaign: _vm.isAdhocCampaign,
                              isEditingMode: false,
                              campaignItems: null,
                            },
                            on: {
                              "update:campaign": function ($event) {
                                _vm.currentCampaign = $event
                              },
                              selectKitchens: _vm.selectKitchens,
                              selectOneKitchen: _vm.selectOneKitchen,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Kitchens",
                            step: "3",
                            clickable: false,
                          },
                        },
                        [
                          _c("hr"),
                          _c(
                            "b-tabs",
                            {
                              attrs: { expanded: "", "destroy-on-hide": "" },
                              model: {
                                value: _vm.activeTabCategory,
                                callback: function ($$v) {
                                  _vm.activeTabCategory = $$v
                                },
                                expression: "activeTabCategory",
                              },
                            },
                            _vm._l(
                              _vm.kitchenCategories,
                              function (category, index) {
                                return _c(
                                  "b-tab-item",
                                  {
                                    key: index,
                                    attrs: {
                                      label: _vm.getCategoryLabel(category),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.selectCategory(category)
                                      },
                                    },
                                  },
                                  [
                                    _c("CampaignKitchensForm", {
                                      attrs: {
                                        locations: _vm.locations,
                                        campaign: _vm.currentCampaign,
                                        kitchenCampaignsInformations:
                                          _vm.kitchenCampaignsInformations,
                                        isEditingMode: false,
                                        category: category,
                                      },
                                      on: {
                                        "update:campaign": function ($event) {
                                          _vm.currentCampaign = $event
                                        },
                                        "update:kitchenCampaignsInformations":
                                          function ($event) {
                                            _vm.kitchenCampaignsInformations =
                                              $event
                                          },
                                        "update:kitchen-campaigns-informations":
                                          function ($event) {
                                            _vm.kitchenCampaignsInformations =
                                              $event
                                          },
                                        create: _vm.create,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }