<template>
    <b-modal :active.sync="openModal" scroll="keep" full-screen :on-cancel="close">
      <b-loading :active.sync="isLoading"></b-loading>
      <section>
        <div class="card" style="width: auto">
          <div class="card-content">
            <div class="media">
              <div class="media-content">
               <p class="title is-4"> Actions</p>
              </div>
            </div>
          </div>
          <section class="card-content">
            <div class="is-flex-direction-column is-justify-content-flex-end">
              <div class="has-text-centered" v-if="isEngineer()">
                <b-button type="is-primary" class="button-gap" @click="openKitchenModal()" expanded :disabled=disableKitchenButton>
                  Kitchens
                </b-button>
              </div>
              <div class="has-text-centered">
                <b-button type="is-link" class="button-gap" @click="openPeopleModal()" expanded :disabled=disablePeopleButton>
                  People
                </b-button>
              </div>
            </div>
          </section>
        </div>
      </section>
    </b-modal>
</template>

<script>
import { mapState } from 'vuex';


import OpenCloseModal from '@/components/Onboarding/Launch/OpenCloseModal.vue';
import PeopleModal from '@/components/Onboarding/People/PeopleModal.vue';
import { auth, utils } from '@/mixins';
import roles from '@/router/roles';

export default {
  components: {},
  mixins: [auth, utils],
  props: ['countries', 'concepts', 'locations', 'platforms'],
  data(){
    return {
      openModal: true,
    }
  },
  computed: mapState({
    isLoading: (state) => state.kitchens.fetchAll.pending || state.accesses.fetchGoogleGroups.pending ||state.accesses.fetchGroups.pending || state.accesses.fetchUsers.pending || state.accesses.createUser.pending|| state.accesses.editRights.pending,
    kitchens: (state) => state.kitchens.all
                              .filter((kitchen) => !kitchen.is_deleted)
                              .sort((kitchen1, kitchen2) => kitchen1.id.localeCompare(kitchen2.id)),
    disableKitchenButton() {
      return !this.$auth.getUserRoles().includes(roles.kitchenLifecycleManager) && !this.$auth.getUserRoles().includes(roles.kitchenOffboarder);
    },
    disablePeopleButton() {
      return !this.$auth.getUserRoles().includes(roles.userManager);
    },
  }),
  methods: {
    openKitchenModal: function() {
      this.$buefy.modal.open({
        component: OpenCloseModal,
        parent: this,
        hasModalCard: true,
        fullScreen: true,
        events: {
          closed: () => {
            this.close()
          }
        },
        props: {
          concepts: this.concepts,
          countries: this.countries,
          kitchens: this.kitchens,
          platforms: this.platforms,
          locations: this.locations,
        },
        customClass: 'my-modal',
      });
    },
    openPeopleModal: function() {
      this.$buefy.modal.open({
        component: PeopleModal,
        parent: this,
        fullScreen: true,
        hasModalCard: true,
        events: {},
        props: {
          concepts: this.concepts,
          countries: this.countries,
          kitchens: this.kitchens,
          platforms: this.platforms,
          locations: this.locations,
        },
        customClass: 'my-modal',
      });
    },
    close() {
      this.$parent.close();
    },
  },
  mounted() {
    this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false', true);
  }
};
</script>

<style>
.my-modal .modal-close {
  background: red;
}
</style>
