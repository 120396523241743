var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        attrs: {
          data: _vm.tasks,
          striped: true,
          hoverable: true,
          "default-sort": ["desc"],
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c(
                  "b-table-column",
                  { attrs: { field: "task", label: "Task" } },
                  [_vm._v(" " + _vm._s(props.row.id) + " ")]
                ),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "available_tasks",
                      label: "Available Tasks",
                    },
                  },
                  [_vm._v(" " + _vm._s(props.row.availableTasks) + " ")]
                ),
                _c(
                  "b-table-column",
                  { attrs: { field: "progress", label: "Progress" } },
                  [
                    _c(
                      "b-progress",
                      {
                        attrs: {
                          type: "is-success",
                          value: props.row.progression,
                          "show-value": "",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(props.row.progression.toFixed(2)) + " % "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }