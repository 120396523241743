<template>
  <div>
    <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close">
      <section>
        <div class="card" style="width: auto">
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p v-if="restaurant.label" p class="title is-4">{{ restaurant.label }}</p>
              </div>
            </div>
          </div>
          <section class="card-content">
            <b-tabs expanded>
              <b-tab-item label="Info" icon="account-box">
                <b-field grouped>
                  <b-field
                    class="control"
                    label="Label"
                    message="Label is the name displayed on platforms"
                    :type="ValidationFieldType(restaurant.label)"
                    expanded
                  >
                    <b-input
                      v-model="restaurant.label"
                      type="text"
                      :disabled="modificationDisabled"
                      placeholder="Restaurant Label"
                    >
                    </b-input>
                  </b-field>
                  <b-field label="Launch Date">
                    <b-datepicker
                      :disabled="modificationDisabled"
                      show-week-number
                      v-model="restaurant.launch_date"
                      :min-date="new Date(2017, 0, 1)"
                      placeholder="Click to select..."
                      icon="calendar-today"
                    >
                    </b-datepicker>
                  </b-field>
                </b-field>
                <b-field grouped>
                <b-field label="Url" message="Restaurant URL page (public)" expanded>
                  <b-input
                    :disabled="modificationDisabled"
                    type="text"
                    v-model="restaurant.url"
                    placeholder="Restaurant Url"
                  >
                  </b-input>
                </b-field>
                  <b-field label="Restaurant Platform ID" expanded>
                    <b-input
                      :disabled="modificationDisabled || !canEditInformation()"
                      type="text"
                      v-model="restaurant.restaurant_platform_id"
                      placeholder="Restaurant Platform ID"
                    >
                    </b-input>
                  </b-field>
                </b-field>
                <b-field grouped>
                  <b-field label="POS" :message="posDisableMessage()" :type="posDisableMessageType()">
                    <b-select placeholder="POS" v-model="restaurant.is_pos_integrated" :disabled="posDisabled()">
                      <option v-for="posValue in posValues" :value="posValue" :key="posValue">
                        {{ posValue }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field
                    label="One Tablet"
                    :message="oneTabletDisableMessage()"
                    :type="oneTabletDisableMessageType()"
                  >
                    <b-select placeholder="POS" v-model="restaurant.is_one_tablet" :disabled="oneTabletDisabled()">
                      <option v-for="oneTabletValue in oneTabletValues" :value="oneTabletValue" :key="oneTabletValue">
                        {{ oneTabletValue }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field label="Active">
                    <b-select
                      placeholder="Active"
                      v-model="restaurant.is_active"
                      :disabled="modificationDisabled || !canEditInformation()"
                    >
                      <option v-for="activeValue in activeValues" :value="activeValue" :key="activeValue">
                        {{ activeValue }}
                      </option>
                    </b-select>
                  </b-field>
                  <!-- <b-field label="Delivery">
                    <b-select
                      v-model="restaurant.is_delivery_enabled"
                      :disabled="modificationDisabled || !canEditInformation()"
                    >
                      <option v-for="boolValue in booleanValues" :value="boolValue" :key="boolValue">
                        {{ boolValue }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field label="Pickup">
                    <b-select
                      v-model="restaurant.is_pickup_enabled"
                      :disabled="modificationDisabled || !canEditInformation()"
                    >
                      <option v-for="boolValue in booleanValues" :value="boolValue" :key="boolValue">
                        {{ boolValue }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field label="Room Service">
                    <b-select
                      v-model="restaurant.is_room_service_enabled"
                      :disabled="modificationDisabled || !canEditInformation()"
                    >
                      <option v-for="boolValue in booleanValues" :value="boolValue" :key="boolValue">
                        {{ boolValue }}
                      </option>
                    </b-select>
                  </b-field> -->
                  <b-field
                    label="Loyalty"
                  >
                    <b-select placeholder="Loyalty" v-model="restaurant.is_loyalty_enable" :disabled="loyaltyDisabled()">
                      <option v-for="loyaltyValue in loyaltyValues" :value="loyaltyValue" :key="loyaltyValue">
                        {{ loyaltyValue }}
                      </option>
                    </b-select>
                  </b-field>
                </b-field>
                <b-field label="UUID">
                  <b-input
                    v-model="restaurant.uuid"
                    type="text"
                    required
                    :disabled="true"
                    placeholder="Restaurant Uuid"
                  >
                  </b-input>
                </b-field>
                <b-field label="ID" v-if="deliveryPlatforms.includes(restaurant.platform_uuid)">
                  <b-input
                    v-model="restaurant.id"
                    type="text"
                    required
                    :disabled="modificationDisabled"
                    placeholder="Restaurant Id"
                  >
                  </b-input>
                </b-field>
                <b-field label="Restaurant Delivery ID" expanded v-if="deliveryPlatforms.includes(restaurant.platform_uuid)">
                  <b-input
                    :disabled="modificationDisabled"
                    type="text"
                    v-model="restaurant.restaurant_delivery_id"
                    placeholder="Restaurant Delivery ID"
                  >
                  </b-input>
                </b-field>
              </b-tab-item>
              <b-tab-item label="Food" icon="hamburger">
                <b-field label="Preptime">
                  <b-numberinput
                    :disabled="modificationDisabled"
                    v-model="restaurant.restaurant_information.preptime"
                    type="number"
                    placeholder="Preptime">
                  </b-numberinput>
                </b-field>
              </b-tab-item>
              <b-tab-item label="Costs" icon="credit-card">
                <b-field label="Platform Commission (%)">
                  <b-numberinput
                    :disabled="modificationDisabled"
                    v-model="restaurant.financial_informations.platform_commission"
                    type="number"
                    placeholder="Platform Commission">
                  </b-numberinput>
                </b-field>
                <b-field label="Platform Pickup Commission (%)">
                  <b-numberinput
                    :disabled="modificationDisabled"
                    v-model="restaurant.financial_informations.platform_pickup_commission"
                    type="number"
                    placeholder="Platform Pickup Commission">
                  </b-numberinput>
                </b-field>
                <b-field label="Restaurant VAT (%)">
                  <b-numberinput
                    :disabled="modificationDisabled || !canEditFinancialInformation()"
                    v-model="restaurant.financial_informations.vat"
                    type="number"
                    step="0.01"
                    placeholder="Restaurant VAT">
                  </b-numberinput>
                </b-field>
              </b-tab-item>
              <b-tab-item label="Tablet" icon="tablet">
                <b-field label="Tablet ID/Login">
                  <b-input
                    :disabled="modificationDisabled"
                    type="text"
                    v-model="restaurant.secrets.tablet_id"
                    placeholder="Tablet ID/Login"
                  >
                  </b-input>
                </b-field>
                <b-field label="Tablet Password">
                  <b-input
                    :disabled="modificationDisabled"
                    type="text"
                    v-model="restaurant.secrets.tablet_password"
                    placeholder="Tablet Password"
                  >
                  </b-input>
                </b-field>
                <b-field label="Tablet PIN">
                  <b-input
                    :disabled="modificationDisabled"
                    type="text"
                    v-model="restaurant.secrets.tablet_pin"
                    placeholder="Tablet PIN"
                  >
                  </b-input>
                </b-field>
              </b-tab-item>
              <b-tab-item label="Back Office" icon="archive">
                <b-field label="Tech Mail">
                  <b-input
                    :disabled="modificationDisabled"
                    type="text"
                    v-model="restaurant.secrets.deliveroo_back_office_mail"
                    placeholder="Mail"
                  >
                  </b-input>
                </b-field>
                <b-field label="Tech Password">
                  <b-input
                    :disabled="modificationDisabled"
                    type="text"
                    v-model="restaurant.secrets.deliveroo_back_office_password"
                    placeholder="Password"
                  >
                  </b-input>
                </b-field>
              </b-tab-item>
            </b-tabs>
          </section>
          <footer class="card-foot buttons is-right">
            <b-button
              v-if="modificationDisabled === false"
              type="button is-small is-outlined"
              :disabled="createOrUpdateDisabled"
              @click="update"
              >{{ $t('restaurants.update') }}</b-button
            >
            <b-button type="button is-danger is-small is-outlined" @click="close">{{
              $t('restaurants.close')
            }}</b-button>
          </footer>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { auth } from '@/mixins';

export default {
  props: [
    'restaurant',
    'modificationDisabled',
  ],
  mixins: [auth],
  data() {
    return {
      openModal: true,
      activeValues: [true, false],
      loyaltyValues: [true, false],
      oneTabletValues: [true, false],
      posValues: [true, false],
      booleanValues: [false, true],
    };
  },
  computed: {
    deliveryPlatforms() {
      return this.$store.getters['platforms/getDeliveryPlatforms'].map((platform) => platform.uuid);
    },
    createOrUpdateDisabled() {
      if (
        !this.restaurant.label ||
        this.restaurant.concept_uuid === null ||
        this.restaurant.platform_uuid === null
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    update() {
      var launch_date = this.restaurant.launch_date;
      if (launch_date) {
        launch_date = new Date(launch_date.getTime() - launch_date.getTimezoneOffset() * 60000)
          .toISOString()
          .split('T')[0];
      }
      var body = {
        financial_informations: this.restaurant.financial_informations,
        id: this.restaurant.id,
        is_active: this.restaurant.is_active,
        is_internal: this.restaurant.is_internal,
        is_one_tablet: this.restaurant.is_one_tablet,
        is_loyalty_enable: this.restaurant.is_loyalty_enable,
        label: this.restaurant.label,
        launch_date: launch_date,
        is_pos_integrated: this.restaurant.is_pos_integrated,
        is_delivery_enabled: this.restaurant.is_delivery_enabled,
        is_pickup_enabled: this.restaurant.is_pickup_enabled,
        is_room_service_enabled: this.restaurant.is_room_service_enabled,
        restaurant_information: {
          platform_commission: this.restaurant.financial_informations.platform_commission,
          platform_pickup_commission: this.restaurant.financial_informations.platform_pickup_commission,
          preptime: this.restaurant.restaurant_information.preptime,
          tablet_id: this.restaurant.secrets.tablet_id,
          tablet_password: this.restaurant.secrets.tablet_password,
          tablet_pin: this.restaurant.secrets.tablet_pin,
          vat: this.restaurant.financial_informations.vat
        },
        restaurant_delivery_id: this.restaurant.restaurant_delivery_id,
        restaurant_platform_id: this.restaurant.restaurant_platform_id,
        secrets: this.restaurant.secrets,
        url: this.restaurant.url,
      }
      body = Object.fromEntries(
        Object.entries(body).filter(([_, v]) => v != null || (v && v.constructor === Object && Object.keys(v).length > 0))
      );
      this.$emit('updated', this.restaurant.uuid, body);
      this.$parent.close();
    },
    close() {
      this.$emit('updateclosed', {});
      this.$parent.close();
    },
    ValidationFieldType(field) {
      if (field) {
        return 'is-grey';
      }
      return 'is-danger';
    },
    posDisabled() {
      if (!this.modificationDisabled && this.restaurant.restaurant_platform_id && this.canEditInformation()) {
        return false;
      }
      return true;
    },
    posDisableMessage() {
      if (!this.modificationDisabled && !this.restaurant.restaurant_platform_id) {
        return 'Missing Restaurant Platform ID';
      }
      return '';
    },
    posDisableMessageType() {
      if (!this.modificationDisabled && !this.restaurant.restaurant_platform_id) {
        return 'is-danger';
      }
      return 'is-light';
    },
    oneTabletDisabled() {
      if (!this.modificationDisabled && this.restaurant.is_pos_integrated && this.canEditInformation()) {
        return false;
      }
      return true;
    },
    oneTabletDisableMessage() {
      if (!this.modificationDisabled && !this.restaurant.is_pos_integrated) {
        return 'Not POS Integrated';
      }
      return '';
    },
    oneTabletDisableMessageType() {
      if (!this.modificationDisabled && !this.restaurant.is_pos_integrated) {
        return 'is-danger';
      }
      return 'is-light';
    },
    loyaltyDisabled() {
      if (!this.modificationDisabled) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
.field:not(:last-child) {
  margin-bottom: 0px;
}
</style>
