<template>
  <div>
    <div class="has-text-centered">
      <b-button type="is-link" class="button-gap" @click="goToNewConceptSelectionForm(false)" expanded>
        Create New Restaurants
      </b-button>
    </div>
    <div class="has-text-centered">
      <b-button type="is-info" class="button-gap" @click="goToExistingConceptSelectionForm()" expanded>
        Select Existing Restaurants
      </b-button>
    </div>
    <div class="has-text-centered">
      <b-button type="is-warning" class="button-gap" @click="goToNewConceptSelectionForm(true)" expanded>
        Create New Restaurants based on a Transfer
      </b-button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: ['activeStep', 'newKitchen', 'newRestaurants', 'isTransfer'],
    computed: {},
    methods: {
      goToNewConceptSelectionForm(isTransfer) {
        this.$emit('update:isTransfer', isTransfer);
        this.$emit('update:activeStep', 3);
      },
      goToExistingConceptSelectionForm() {
        this.$emit('update:newRestaurants', false);
        this.$emit('update:activeStep', 3);
      },
    }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
