import locationsAPI from '../../../apis/kitchen/locations';
import { locationsTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  current: null,
  fetchOne: {
    pending: false,
    error: null,
  },
  fetchAll: {
    pending: false,
    error: null,
  },
};


const actions = {
  async getLocation({ commit }, locationUUID) {
    commit(locationsTypes.FETCH_ONE_REQUEST);
    return locationsAPI
      .getLocation(locationUUID)
      .then(function(location) {
        commit(locationsTypes.FETCH_ONE_SUCCESS, location);
      })
      .catch(function(error) {
        commit(locationsTypes.FETCH_ONE_FAILURE, error.response.data.detail);
      });
  },
  async getLocations({ commit }, queryParameters) {
    commit(locationsTypes.FETCH_ALL_REQUEST);
    return locationsAPI
      .getLocations(queryParameters)
      .then(function(locations) {
        commit(locationsTypes.SET_LOCATIONS, locations);
      })
      .catch(function(error) {
        commit(locationsTypes.SET_CURRENT_ERROR, error.response.data.detail);
      });
  },
  async createLocation({ commit }, payload) {
    return locationsAPI
      .createLocation(payload.location)
      .then(function(location) {
        commit(locationsTypes.CREATE_LOCATION, location);
      })
      .catch(function(error) {
        commit(locationsTypes.SET_CURRENT_ERROR, error.response.data.detail);
      });
  },
};

const mutations = {
  [locationsTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [locationsTypes.FETCH_ONE_SUCCESS](state, location) {
    state.current = location;
    const index = state.all.findIndex((item) => item.uuid === location.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, location);
    }
    else {
      state.all.push(location);
    }
    state.fetchOne.pending = false;
    state.fetchOne.error = null;
  },
  [locationsTypes.FETCH_ONE_FAILURE](state, error) {
    state.fetchOne.pending = false;
    state.fetchOne.error = error;
  },
  [locationsTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [locationsTypes.SET_LOCATIONS](state, locations) {
    state.all = locations;
    state.fetchAll.pending = false;
  },
  [locationsTypes.CREATE_LOCATION](state, location) {
    state.all.push(location);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
