var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section" },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("div", { staticClass: "container" }, [
        _c("h1", { staticClass: "title has-text-dark" }, [
          _c("div", { staticClass: "columns is-mobile" }, [
            _c("div", { staticClass: "column is-narrow" }, [
              _vm._v(" Lifecycle "),
            ]),
            _c("div", { staticClass: "column" }, [
              _c(
                "button",
                {
                  staticClass: "add-button button is-link is-small",
                  on: {
                    click: function ($event) {
                      return _vm.openActionsModal()
                    },
                  },
                },
                [_vm._v(" Actions ")]
              ),
              _c(
                "button",
                {
                  staticClass: "add-button button is-info is-small",
                  staticStyle: { "margin-left": "10px" },
                  on: {
                    click: function ($event) {
                      return _vm.openTasksManager()
                    },
                  },
                },
                [_vm._v(" Tasks Manager ")]
              ),
            ]),
            _c("div"),
          ]),
        ]),
        _c("hr"),
        _c("section", [
          _vm.launches &&
          _vm.locations &&
          _vm.locations.length > 0 &&
          _vm.concepts &&
          _vm.concepts.length > 0 &&
          _vm.countries &&
          _vm.countries.length > 0
            ? _c(
                "section",
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: { "aria-role": "list" },
                      model: {
                        value: _vm.selectedLaunchType,
                        callback: function ($$v) {
                          _vm.selectedLaunchType = $$v
                        },
                        expression: "selectedLaunchType",
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button is-link",
                          attrs: { slot: "trigger", type: "button" },
                          slot: "trigger",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Launch Type (" +
                                _vm._s(_vm.selectedLaunchType) +
                                ")"
                            ),
                          ]),
                          _c("b-icon", { attrs: { icon: "menu-down" } }),
                        ],
                        1
                      ),
                      _vm._l(_vm.launchTypes, function (launchType, index) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: index,
                            attrs: {
                              value: launchType,
                              "aria-role": "listitem",
                            },
                          },
                          [_c("span", [_vm._v(" " + _vm._s(launchType) + " ")])]
                        )
                      }),
                    ],
                    2
                  ),
                  _c("OnboardingTable", {
                    attrs: {
                      selectedLaunchType: _vm.selectedLaunchType,
                      isMounted: _vm.isMounted,
                    },
                  }),
                  _c("hr"),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }