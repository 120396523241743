<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close" full-screen>
      <section>
        <div class="card" style="width: auto">
          <div class="card-content">
            <div class="columns is-flex">
              <div class="column is-narrow has-text-centered">
                <p class="title is-4"> {{campaignTitle}}</p>
              </div>
              <div class="column is-narrow" v-if="campaignConcept">
                <img
                  :src="campaignConcept.logo"
                  :alt="campaignConcept.label"
                  onerror="this.style.display='none'"
                  class="concept-logo"
                />
              </div>
              <div class="column is-narrow" v-if="campaignPlatform">
                <img
                  :src="campaignPlatform.logo"
                  :alt="campaignPlatform.label"
                  onerror="this.style.display='none'"
                  class="concept-logo"
                />
              </div>
            </div>
          </div>
          <section class="card-content">
            <b-steps v-model="step" size="is-small" type="is-info" :has-navigation="false" :clickable="false">
              <b-step-item label="Campaign General Configuration" step="1" :clickable="false">
                <hr>
                <CampaignGeneralForm
                  :activeStep.sync="activeStepComputed"
                  :concepts="concepts"
                  :countries="countries"
                  :locations="locations"
                  :platforms="platforms"
                  :events="events"
                  :isAdhocCampaign="isAdhocCampaign"
                  :campaign.sync="currentCampaign"
                  :isEditingMode="false"
                />
              </b-step-item>
              <b-step-item label="Campaign Type Configuration" step="2" :clickable="false">
                <hr>
                <CampaignTypeForm
                  :countryLocation="campaignCountryLocation"
                  :campaign.sync="currentCampaign"
                  :isAdhocCampaign="isAdhocCampaign"
                  @selectKitchens="selectKitchens"
                  @selectOneKitchen="selectOneKitchen"
                  :isEditingMode="false"
                  :campaignItems="null"
                />
              </b-step-item>
              <b-step-item label="Kitchens" step="3" :clickable="false">
                <hr>
                <b-tabs v-model="activeTabCategory" expanded destroy-on-hide>
                  <b-tab-item :label="getCategoryLabel(category)" v-for="(category, index) in kitchenCategories" :key="index" @input="selectCategory(category)">
                    <CampaignKitchensForm
                      :locations="locations"
                      :campaign.sync="currentCampaign"
                      :kitchenCampaignsInformations.sync="kitchenCampaignsInformations"
                      :isEditingMode="false"
                      :category="category"
                      @create="create"
                    />
                  </b-tab-item>
                </b-tabs>
              </b-step-item>
            </b-steps>
          </section>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { campaignsTypes, eventsTypes, onboardingActionTypes } from '@/store/mutation-types';
import { utils } from '@/mixins';

import CampaignGeneralForm from '@/components/Marketing/Forms/CampaignGeneralForm.vue'
import CampaignKitchensForm from '@/components/Marketing/Forms/CampaignKitchensForm.vue'
import CampaignTypeForm from '@/components/Marketing/Forms/CampaignTypeForm.vue'

export default {
  components: {
    CampaignGeneralForm,
    CampaignKitchensForm,
    CampaignTypeForm,
  },
  props: ['concepts', 'countries', 'kitchens', 'locations', 'platforms', 'events', 'isAdhocCampaign', 'activeStep'],
  mixins: [utils],
  data() {
    return {
      step: 0,
      openModal: true,
      activeTabCategory: 0,
      kitchenCampaigns: [],
      discountedItems: [],
    }
  },
  computed: {
    ...mapState({
      currentCampaign: (state) => state.campaigns.newCampaign,
      currentCampaignUUID: (state) => state.campaigns.currentCampaignUUID,
      currentEventLabel: (state) => state.events.currentEventLabel,
      isLoading: (state) => state.kitchens.fetchAll.pending || state.menuInformation.fetchAll.pending || state.menuItems.fetchAll.pending,
      error: (state) => state.campaigns.createOne.error || state.kitchenCampaigns.createOne.error,
    }),
    campaignTitle() {
      if (this.currentCampaign && this.currentCampaign.label) {
        return this.currentCampaign.label
      }
      return 'New Campaign'
    },
    campaignConcept() {
      if (this.currentCampaign && this.currentCampaign.concept_uuid) {
        return this.concepts.find(concept => concept.uuid == this.currentCampaign.concept_uuid);
      }
      return null
    },
    campaignCountryLocation() {
      if (this.currentCampaign && this.currentCampaign.country_location_uuid) {
        return this.locations.find(location => location.uuid == this.currentCampaign.country_location_uuid);
      }
      return null
    },
    campaignPlatform() {
      if (this.currentCampaign && this.currentCampaign.platform_uuid) {
        return this.platforms.find(platform => platform.uuid == this.currentCampaign.platform_uuid);
      }
      return null
    },
    kitchenCategories() {
      return [null].concat(this.$store.getters['kitchens/getKitchenCategories']);
    },
    selectedCategory() {
      return this.kitchenCategories[this.activeTabCategory];
    },
    kitchenCampaignsInformations() {
      return this.kitchenCampaigns.filter(kitchenCampaign => kitchenCampaign.category == this.selectedCategory);
    },
    activeStepComputed: {
      get() {
        return this.activeStep
      },
      set(value) {
        this.step = value;
      }
    },
  },
  methods: {
    ...mapActions('campaigns', {createCampaign: 'createOne'}),
    ...mapActions('campaignItems', {createCampaignItem: 'createOne'}),
    ...mapActions('events', {createEvent: 'createOne'}),
    ...mapActions('kitchenCampaigns', {createKitchenCampaign: 'createOne', createMultipleKitchenCampaigns: 'createMultiple'}),
    ...mapMutations('campaigns', { resetCampaign: campaignsTypes.RESET_ONE}),
    ...mapMutations('events', { setCurrentEventLabel: eventsTypes.SET_CURRENT_EVENT_LABEL}),
    ...mapMutations('onboardingActions', {'setCurrentKitchen': onboardingActionTypes.SET_CURRENT_KITCHEN}),
    selectKitchens() {
      var kitchenCampaignsToCreate = [];
      delete this.currentCampaign.locationUUID;
      var locationUUIDS = this.locations.filter(location => location.parent_uuid == this.currentCampaign.country_location_uuid)
                                        .map(location => location.uuid);
      var kitchens = this.kitchens.filter(kitchen => locationUUIDS.includes(kitchen.location_uuid))
                                  .filter(kitchen => kitchen.restaurants && kitchen.restaurants.map(restaurant => restaurant.concept_uuid).includes(this.currentCampaign.concept_uuid));
      var kitchensWithLocations = kitchens.map(kitchen => {
        return {...kitchen, 'location': this.locations.find(location => location.uuid == kitchen.location_uuid)}
      })
      kitchenCampaignsToCreate = kitchensWithLocations.map(kitchen => {
        return {
          'locationLabel': kitchen.location.label,
          'locationUUID': kitchen.location.uuid,
          'campaignUUID': this.currentCampaign.uuid,
          'dates': this.currentCampaign.dates,
          'financingModel': this.currentCampaign.financing_model,
          'isUpdated': false,
          'isEligible': true,
          'concept_uuid': this.currentCampaign.concept_uuid,
          'location_uuid': kitchen.location_uuid,
          'platform_uuid': this.currentCampaign.platform_uuid,
          'category': this.getKitchenCategory(kitchen),
        }
      })
      this.activeStepComputed = this.step + 1;
      this.kitchenCampaigns = kitchenCampaignsToCreate;
      this.discountedItems = this.currentCampaign.discounted_items;
    },
    selectOneKitchen() {
      var locationUUID = this.currentCampaign.locationUUID;
      delete this.currentCampaign.locationUUID;
      var location = this.locations.find(location => location.uuid == locationUUID);
      var kitchenCampaignsToCreate = [{
        'locationLabel': location.label,
        'locationUUID': location.uuid,
        'campaignUUID': this.currentCampaign.uuid,
        'dates':this.currentCampaign.dates,
        'financingModel': this.currentCampaign.financing_model,
        'isUpdated': false,
        'isEligible': true,
        'concept_uuid': this.currentCampaign.concept_uuid,
        'location_uuid': locationUUID,
        'platform_uuid': this.currentCampaign.platform_uuid,
      }];
      this.kitchenCampaigns = kitchenCampaignsToCreate;
      this.discountedItems = this.currentCampaign.discounted_items;
      this.create();
    },
    create() {
      this.getOrCreateEvent()
          .then((event) => this.createCampaignWithEvent(event).then((campaign) => this.createCampaignObjects(campaign)))
    },
    /* eslint-disable */
    getOrCreateEvent() {
      var event = null;
      if (this.currentCampaign.event_uuid) {
        event = this.events.find(event => event.uuid == this.currentCampaign.event_uuid);
      }
      else if (!this.currentEventLabel) {
        var p = new Promise(function(resolve, reject) {
          if(true) { // eslint-disable-line no-constant-condition
            resolve(null);
          }
        });
        return p;
      }
      else {
        event = this.events.find(event => event.label == this.currentEventLabel);
      }
      if (event) {
        var p = new Promise(function(resolve, reject) {
          if(true) { // eslint-disable-line no-constant-condition
            resolve(event);
          }
        });
        return p;
      }
      return this.createEvent({
        'label': this.currentEventLabel
      })
    },
    createCampaignWithEvent(event) {
      if (this.isAdhocCampaign) {
        this.currentCampaign.campaign_scope = 'DEDICATED';
      }
      else {
        this.currentCampaign.campaign_scope = 'GENERAL';
      }
      delete this.currentCampaign.financing_model;
      delete this.currentCampaign.discountedItems;
      var startDate = this.currentCampaign.dates[0];
      var endDate = this.currentCampaign.dates[1];
      var startDateFormatted = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0];
      var endDateFormatted = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0];
      var maxOptinDateFormatted = new Date(this.currentCampaign.max_optin_date.getTime() - this.currentCampaign.max_optin_date.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0];
      var eventUUID = null;
      if (event) {
        eventUUID = event.uuid
      }
      return this.createCampaign(
        {
        ...this.currentCampaign,
        'end_date': endDateFormatted,
        'start_date': startDateFormatted,
        'max_optin_date': maxOptinDateFormatted,
        'event_uuid': eventUUID
        }
      )
    },
    createCampaignObjects(campaign) {
      var campaignItemToCreate = this.createCampaignItems(campaign);
      var kitchenCampaignsToCreate = this.createKitchenCampaigns(campaign);
      Promise.all([
        ...campaignItemToCreate.map((campaignItem) => this.createCampaignItem(campaignItem)),
        this.createMultipleKitchenCampaigns(kitchenCampaignsToCreate),
      ]).then(() => {
        this.$emit('reloadTables')
        this.close();
      })
    },
    createCampaignItems(campaign) {
      var campaignItemToCreate = [];
      if (this.shouldCreateCampaignItem(campaign) && this.discountedItems && this.discountedItems.length > 0) {
        for (let discountedItem of this.discountedItems) {
          campaignItemToCreate.push(
            {
              'campaign_uuid': campaign.uuid,
              'item_uuid': discountedItem.uuid,
              'label': discountedItem.label,
              'food_cost': discountedItem.foodCost || 0,
              'selling_price': discountedItem.selling_price || 0,
              'reimbursed_food_cost': discountedItem.reimbursedFoodCost || 0,
            }
          )
        }
      }
      return campaignItemToCreate
    },
    createKitchenCampaigns(campaign) {
      var kitchenCampaignsToCreate = [];
      var kitchenCampaignsInformationsToCreate = this.kitchenCampaigns.filter((info) => info.isEligible);
      for (let kitchenCampaignInfo of kitchenCampaignsInformationsToCreate) {
        var startDate = new Date(kitchenCampaignInfo.dates[0].getTime() - kitchenCampaignInfo.dates[0].getTimezoneOffset() * 60000)
          .toISOString()
          .split('T')[0];
        var endDate = new Date(kitchenCampaignInfo.dates[1].getTime() - kitchenCampaignInfo.dates[1].getTimezoneOffset() * 60000)
          .toISOString()
          .split('T')[0];
        kitchenCampaignsToCreate.push({
          'campaign_uuid': campaign.uuid,
          'location_uuid': kitchenCampaignInfo.locationUUID,
          'start_date': startDate,
          'end_date': endDate,
          'financing_model': kitchenCampaignInfo.financingModel,
        })
      }
      return kitchenCampaignsToCreate
    },
    shouldCreateCampaignItem(campaign) {
      return campaign.campaign_type && (campaign.campaign_type.toLowerCase().includes('discount_items') || campaign.campaign_type.toLowerCase().includes('bogo') || campaign.campaign_type.toLowerCase().includes('free_items') );
    },
    close() {
      this.resetCampaign();
      this.$emit('close');
    },
  },
  mounted() {
    this.setCurrentEventLabel(null);
  }
};
</script>

<style scoped>
.my-modal .modal-close {
  background: red;
}
.column {
  text-align: center;
}
</style>
