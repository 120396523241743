<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close" full-screen>
      <section>
        <div class="card" style="width: auto">
          <div class="card-content">
            <div class="columns is-flex">
              <div class="column is-narrow has-text-centered">
                <p class="title is-4"> Calendar</p>
              </div>
              <div class="column is-narrow" v-if="currentSelection.concept">
                <img
                  :src="currentSelection.concept.logo"
                  :alt="currentSelection.concept.label"
                  onerror="this.style.display='none'"
                  class="concept-logo"
                />
              </div>
              <div class="column is-narrow" v-if="currentSelection.platform">
                <img
                  :src="currentSelection.platform.logo"
                  :alt="currentSelection.platform.label"
                  onerror="this.style.display='none'"
                  class="concept-logo"
                />
              </div>
            </div>
          </div>
          <section class="card-content">
            <b-steps v-model="step" size="is-small" type="is-info" :clickable="false" :has-navigation="false">
              <b-step-item label="Calendar Parameters" step="1">
                <hr>
                <CalendarParametersForm
                  :activeStep.sync="activeStepComputed"
                  :concepts="concepts"
                  :countries="countries"
                  :kitchens="kitchens"
                  :locations="locations"
                  :platforms="platforms"
                  :events="events"
                  :currentSelection.sync="currentSelection"
                  @select="select"
                />
              </b-step-item>
              <b-step-item label="Management Parameter" step="2" :clickable="false">
                <div>
                  <div class="has-text-centered" v-for="calendarType in calendarTypes" :key="calendarType.label">
                    <b-button :icon-left="calendarType.icon" type="is-info" class="button-gap" @click="selectCalendarType(calendarType)" expanded>
                      {{calendarType.label}}
                    </b-button>
                  </div>
                </div>
              </b-step-item>
              <b-step-item label="Calendar" step="3" :clickable="false">
                <!-- <b-tabs type="is-toggle" v-model="activeTab" expanded destroy-on-hide> -->
                  <!-- <b-tab-item :label="getCategoryLabel(kitchenCategory)" v-for="(kitchenCategory, index) in kitchenCategories" :key="index"> -->
                    <CampaignReport
                      :kitchenCampaignInfo="kitchenCampaignInfo"
                      :concepts="concepts"
                      :countries="countries"
                      :kitchens="kitchens"
                      :locations="locations"
                      :platforms="platforms"
                      :kitchenCategory="kitchenCategory"
                    />
                <!-- </b-tab-item> -->
                <!-- </b-tabs> -->
              </b-step-item>
            </b-steps>
          </section>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { commons, marketingCalendar, utils } from '@/mixins';

import CalendarParametersForm from '@/components/Marketing/Forms/CalendarParametersForm.vue'
import CampaignReport from '@/components/Marketing/Reports/CampaignReport.vue'

export default {
  components: {
    CalendarParametersForm,
    CampaignReport,
  },
  props: ['concepts', 'countries', 'kitchens', 'locations', 'platforms', 'events', 'activeStep'],
  mixins: [commons, utils, marketingCalendar],
  data() {
    return {
      step: 0,
      kitchenCampaignInfo: [],
      openModal: true,
      currentSelection: {
        concept: null,
        country: null,
        location: null,
        platform: null,
        category: null,
        dates: [],
      },
      cancelState: 'OPT_OUT',
      activeTab: 0,
      kitchenCategory: null,
    }
  },
  computed: {
    ...mapState({
      kitchenCampaigns: (state) => state.kitchenCampaigns.all,
      campaigns: (state) => state.campaigns.all,
      isLoading: (state) => state.kitchens.fetchAll.pending || state.campaigns.fetchAll.pending || state.kitchenCampaigns.fetchAll.pending,
      kitchenCategories() {
        return [null].concat(this.$store.getters['kitchens/getKitchenCategories']);
      },
      calendarTypes: function() {
        return this.kitchenCategories.map((kitchenCategory) => {
          return {
            'label': this.getCategoryLabel(kitchenCategory),
            'value': 'category',
            'type': 'category',
            'field': 'category',
            'icon': 'folder-open'
          }
        });
      }
    }),
    activeStepComputed: {
      get() {
        return this.activeStep
      },
      set(value) {
        this.step = value;
      }
    },
  },
  methods: {
    ...mapActions('campaigns', { fetchCampaigns: 'fetchAll' }),
    ...mapActions('kitchenCampaigns', { fetchKitchenCampaigns: 'fetchAll' }),
    refresh() {
      var opts = {
        perPage: 1000,
        conceptUUID: null,
        locationUUID: null,
        countryLocationUUID: this.getCountryLocationUUID(this.currentSelection.country.uuid),
        platformUUID: null,
        startDateAfter: this.formatDate(this.getDateWithOffset(this.currentSelection.dates[0], -1)),
        endDateBefore: this.formatDate(this.getDateWithOffset(this.currentSelection.dates[1], 1)),
      }
      if (this.currentSelection.concept) {
        opts.conceptUUID = this.currentSelection.concept.uuid;
      }
      if (this.currentSelection.location) {
        opts.locationUUID = this.currentSelection.location.uuid;
      }
      if (this.currentSelection.platform) {
        opts.platformUUID = this.currentSelection.platform.uuid;
      }
      return Promise.all([
        this.fetchCampaigns(
          opts
        ),
        this.fetchKitchenCampaigns(
          opts
        ),
      ])
    },
    selectCalendarType(calendarType) {
      this.kitchenCategory = calendarType;
      this.activeStepComputed = this.step + 1;
    },
    select() {
      this.refresh().then(
        () => {
          var conceptUUID = null;
          var locationUUID = null;
          var platformUUID = null;
          if (this.currentSelection.concept) {
            conceptUUID = this.currentSelection.concept.uuid;
          }
          if (this.currentSelection.location) {
            locationUUID = this.currentSelection.location.uuid;
          }
          if (this.currentSelection.platform) {
            platformUUID = this.currentSelection.platform.uuid;
          }
          var countryLocationUUID = this.getCountryLocationUUID(this.currentSelection.country.uuid);
          this.kitchenCampaignInfo = this.getMarketingCalendarInfo(this.kitchens, this.campaigns, this.kitchenCampaigns, this.events, countryLocationUUID, conceptUUID, locationUUID, platformUUID);
          this.activeStepComputed = this.step + 1;
        }
      )
    },
    close() {
      this.$emit('close');
    },
  },
  mounted() {
    this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false');
  }
};
</script>

<style scoped>
.my-modal .modal-close {
  background: red;
}
.column {
  text-align: center;
}
</style>
