<template>
  <div class="box modal-card my-modal" style="overflow: visible;">
    <b-loading :active.sync="isLoading"></b-loading>
    <div class="columns">
      <div class="column is-narrow">
        <p class="title is-4"> Tasks Manager </p>
      </div>
      <div class="column">
        <b-button icon-left="plus" rounded type="is-link is-small"
          @click="openCreateTaskModal()">
          New
        </b-button>
      </div>
    </div>
    <b-tabs v-model="activeTab" expanded>
      <b-tab-item label="Task Groups">
        <b-table
          :data="activeTaskGroups"
          :striped="true"
          :hoverable="true"
          :scrollable="true"
          :per-page="10"
          :paginated="true"
          :default-sort="['label', 'asc']"
          >
          <template slot-scope="props">
            <b-table-column field="label" label="Label" searchable sortable>
                {{ props.row.label }}
            </b-table-column>
            <b-table-column filed="actions" label="Actions">
              <b-button type="is-link is-small"
                @click="openTaskGroupForm(props.row)">
                Edit
              </b-button>
              <b-button style="margin-left: 10px;" type="is-info is-small"
                v-if="props.row.task_uuids && props.row.task_uuids.length > 0"
                @click="goToTasks(props.row)">
                Go to Tasks
              </b-button>
            </b-table-column>
          </template>
        </b-table>
      </b-tab-item>
      <b-tab-item label="Tasks">
        <b-tag v-if="selectedTaskGroup"
            type="is-warning"
            close-type='is-danger'
            closable
            attached
            close-icon='delete'
            @close="selectedTaskGroup = null">
            {{selectedTaskGroup.label}}
        </b-tag>
        <hr>
        <b-table
          :data="activeTasks"
          :striped="true"
          :hoverable="true"
          :scrollable="true"
          :per-page="10"
          :paginated="true"
          :default-sort="['label', 'asc']"
          >
          <template slot-scope="props">
            <b-table-column field="label" label="Label" searchable sortable>
                {{ props.row.label }}
            </b-table-column>
            <b-table-column field="task_group_label" label="TaskGroup" sortable>
              <b-tag v-if="props.row.task_group_label" type="is-info"> {{ props.row.task_group_label }} </b-tag>
            </b-table-column>
            <b-table-column field="owner" label="Owner" sortable>
              <b-tag type="is-primary"> {{ props.row.owner }} </b-tag>
            </b-table-column>
            <b-table-column label="Actions">
              <b-button type="is-link is-small"
                @click="openTaskForm(props.row)">
                Edit
              </b-button>
              <b-button style="margin-left: 5px;" title="delete" class="button is-small is-danger" @click="deleteTaskConfirm(props.row)">
                <b-icon icon="delete" size="is-small"></b-icon>
              </b-button>
            </b-table-column>
          </template>
        </b-table>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { onboardingActionTypes } from '@/store/mutation-types';

import NewTaskSelection from '@/components/Onboarding/TasksManager/Forms/NewTaskSelection.vue'
import TaskForm from '@/components/Onboarding/TasksManager/Forms/TaskForm.vue'
import TaskGroupForm from '@/components/Onboarding/TasksManager/Forms/TaskGroupForm.vue'
import TaskGroupFormTasks from '@/components/Onboarding/TasksManager/Forms/TaskGroupFormTasks.vue'

export default {
  components: {
  },
  props: [],
  data() {
    return {
      activeTab: 0,
      selectedTaskGroup: null,
    }
  },
  computed: {
    ...mapState({
    countries: (state) => state.countries.all,
    isLoading: (state) => state.taskGroups.fetchAll.pending || state.tasks.fetchAll.pending || state.taskGroups.createOne.pending || state.taskGroups.updateOne.pending || state.tasks.createOne.pending || state.tasks.updateOne.pending,
    platforms: (state) => state.platforms.all,
    tasks: (state) => state.tasks.all,
    taskGroups: (state) => state.taskGroups.all,
    }),
    activeTasks() {
      var activeTasks = this.tasks.filter(task => !task.is_deleted);
      if (this.selectedTaskGroup) {
        activeTasks = activeTasks.filter(task => task.task_group_uuid == this.selectedTaskGroup.uuid);
      }
      return activeTasks;
    },
    activeTaskGroups() {
      return this.taskGroups.filter(taskGroup => !taskGroup.is_deleted)
    },
  },
  methods: {
    ...mapActions('launches', { fetchLaunches: 'fetchAll'}),
    ...mapActions('taskGroups', { createTaskGroup: 'createOne', fetchTaskGroup: 'fetchOne', fetchTaskGroups: 'fetchAll', updateTaskGroup: 'updateOne', deleteTaskGroup: 'deleteOne' }),
    ...mapActions('tasks', { createTask: 'createOne', fetchTasks: 'fetchAll', updateTask: 'updateOne', deleteTask: 'deleteOne' }),
    ...mapMutations('onboardingActions', {'setCurrentKitchen': onboardingActionTypes.SET_CURRENT_KITCHEN}),
    openCreateTaskModal: function() {
      this.$buefy.modal.open({
        component: NewTaskSelection,
        parent: this,
        hasModalCard: true,
        events: {
          createTaskGroup: () => this.openTaskGroupForm(null),
          createTask: () => this.openTaskForm(null),
        },
        props: {}
      })
    },
    openTaskGroupForm: function(taskGroup) {
      if (!taskGroup) {
        taskGroup = this.$store.getters['taskGroups/getNewTaskGroup']
      }
      this.$buefy.modal.open({
        component: TaskGroupForm,
        parent: this,
        hasModalCard: true,
        events: {
          createTaskGroup: (taskGroup) => this.createTaskGroup(taskGroup)
                                              .then(() => this.$store.dispatch('taskGroups/resetNewTaskGroup')),
          updateTaskGroup: (taskGroup) => this.updateTaskGroup(taskGroup),
        },
        props: {
          platforms: this.platforms,
          taskGroup: taskGroup,
          tasks: this.tasks,
          taskGroups: this.taskGroups,
        }
      })
    },
    openTaskForm: function(task) {
      if (!task) {
        task = this.$store.getters['tasks/getNewTask']
      }
      this.$buefy.modal.open({
        component: TaskForm,
        parent: this,
        canCancel: true,
        events: {
          createTask: (task) => this.createTask(task)
                                    .then(() => this.$store.dispatch('tasks/resetNewTask'))
                                    .then(() => this.fetchTaskGroups()),
          updateTask: (task) => this.updateTask(task)
                                    .then(() => this.fetchLaunches({'current': true})),
        },
        props: {
          countries: this.countries,
          task: task,
          tasks: this.tasks,
          taskGroups: this.taskGroups,
        }
      })
    },
    openTaskGroupTaskForm: function(taskGroup) {
      this.$buefy.modal.open({
        component: TaskGroupFormTasks,
        parent: this,
        hasModalCard: true,
        events: {
          createTaskGroup: (taskGroup) => this.createTaskGroup(taskGroup),
          updateTaskGroup: (taskGroup) => this.updateTaskGroup(taskGroup),
        },
        props: {
          taskGroup: taskGroup,
          tasks: this.tasks,
          taskGroups: this.taskGroups,
        }
      })
    },
    deleteTaskGroupConfirm: function(taskGroup) {
      this.$buefy.dialog.confirm({
        title: `Delete ${taskGroup.label}`,
        message:
          `Are you sure you want to <b>delete</b> this Task Group? It will remove unlink all tasks from this Task Group.`,
        confirmText: `Delete Task Group`,
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteTaskGroup(taskGroup.uuid).then(() => this.fetchTasks()),
      });
    },
    deleteTaskConfirm: function(task) {
      this.$buefy.dialog.confirm({
        title: `Delete ${task.label}`,
        message:
          `Are you sure you want to <b>delete</b> this task? It will remove it from <b>${task.task_group_label}</b> Task Group.`,
        confirmText: `Delete Task`,
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteTask(task.uuid),
      });
    },
    close() {
      if (this.creationModal) {
        this.$emit('creationclosed', {});
      }
      else{
        this.$emit('updateclosed', {});
      }
      this.$parent.close();
    },
    goToTasks(taskGroup) {
      this.selectedTaskGroup = taskGroup;
      this.activeTab = 1;
    }
  }
}
</script>

<style>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}

.my-modal .modal-close {
  background: red;
}

</style>
