var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { active: _vm.openModal, scroll: "keep", "full-screen": true },
          on: {
            "update:active": function ($event) {
              _vm.openModal = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title titleCase" }, [
                  _vm._v("Marketing Calendar"),
                ]),
              ]),
              _c("section", { staticClass: "modal-card-body" }, [
                _c("table", [
                  _vm.kitchenCampaignsInformations
                    ? _c("tbody", [
                        _c("td", { staticClass: "titleCase" }, [
                          _c("thead", [
                            _c(
                              "tr",
                              [
                                _c("td", { staticClass: "titleCase" }, [
                                  _vm._v("Dates"),
                                ]),
                                _vm._l(_vm.dateKeys, function (dateKey, index) {
                                  return _c(
                                    "td",
                                    { key: index, staticClass: "titleCase" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.formatDateKey(dateKey)) +
                                          " "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                            _c(
                              "tr",
                              [
                                _c("td", { staticClass: "titleCase" }, [
                                  _vm._v("Events"),
                                ]),
                                _vm._l(_vm.dateKeys, function (dateKey) {
                                  return _vm._l(
                                    _vm.getEventsNames(dateKey),
                                    function (eventName) {
                                      return _c(
                                        "td",
                                        {
                                          key: _vm.getEventKey(
                                            dateKey,
                                            eventName
                                          ),
                                          staticClass: "titleCase",
                                        },
                                        [_vm._v(" " + _vm._s(eventName) + " ")]
                                      )
                                    }
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.getPlatformKeys(),
                              function (platformKey, index) {
                                return _c(
                                  "tr",
                                  { key: index },
                                  [
                                    _c("td", { staticClass: "platform" }, [
                                      _c("img", {
                                        attrs: {
                                          width: "50px",
                                          src: _vm.getPlatformLogo(platformKey),
                                        },
                                      }),
                                    ]),
                                    _vm._l(_vm.dateKeys, function (dateKey) {
                                      return [
                                        _vm._l(
                                          _vm.getEventsNames(dateKey),
                                          function (eventName) {
                                            return _vm._l(
                                              _vm.getCampaignNames(
                                                dateKey,
                                                eventName,
                                                platformKey
                                              ),
                                              function (campaignName) {
                                                return _c(
                                                  "td",
                                                  {
                                                    key: _vm.getCampaignKey(
                                                      dateKey,
                                                      eventName,
                                                      campaignName
                                                    ),
                                                  },
                                                  [
                                                    campaignName
                                                      ? _c(
                                                          "table",
                                                          [
                                                            _c("tr", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "titleCase",
                                                                  attrs: {
                                                                    colspan:
                                                                      "4",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        campaignName
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "Concept"
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  "Campaign Type"
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  "Financing Model"
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  "Uplift"
                                                                ),
                                                              ]),
                                                            ]),
                                                            _vm._l(
                                                              _vm.getCampaigns(
                                                                dateKey,
                                                                eventName,
                                                                platformKey,
                                                                campaignName
                                                              ),
                                                              function (
                                                                campaign
                                                              ) {
                                                                return _c(
                                                                  "tr",
                                                                  {
                                                                    key: _vm.getUniqueCampaignKey(
                                                                      dateKey,
                                                                      eventName,
                                                                      campaignName,
                                                                      campaign
                                                                    ),
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "platform",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                width:
                                                                                  "50px",
                                                                                src: _vm.getConceptLogo(
                                                                                  campaign.conceptUUID
                                                                                ),
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          campaign.campaignType
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          campaign.financingModel
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          campaign.estimatedUplift
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        ),
                                      ]
                                    }),
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("footer", { staticClass: "modal-card-foot" }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }