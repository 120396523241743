<template>
  <div class="has-text-centered">
    <b-loading :active.sync="isLoading"></b-loading>
    <h4 class="title has-text-centered is-4"> Ops members Accesses</h4>
    <b-field style="justify-content: center;">
      <div v-for="(team, index) in launchTeamsToSelect" :key="index">
        <b-checkbox
          v-for="(member, index) in team.members"
          :key="index"
          @input="addTeamMember(member)"
        >
          <div>{{ member }}</div>
        </b-checkbox>
      </div>
    </b-field>
    <b-button expanded type="button is-success button-location-gap" @click="selectMembers">Select</b-button>
    <h4 class="title is-italic is-5"> NB: The Tech, Marketing and Support teams will have the necessary right on this new kitchen. The Supply and Expansion teams of the country as well.</h4>
  </div>
</template>

<script>

import { mapState } from 'vuex';

  export default {
    data() {
      return {
        selectedMembers: [],
      }
    },
    props: ['activeStep', 'kitchen', 'launchTeams'],
    computed: {
      ...mapState({
        isLoading: (state) => state.actions.importIAMLocation.pending || state.launches.createLaunch.pending,
        launch : (state) => state.launches.current,
      }),
      launchTeamsToSelect() {
        var launchTeamsToSelect = this.launchTeams.filter((team) => team.label == 'Ops');
        return launchTeamsToSelect
      }
    },
    methods: {
      addTeamMember(teamMember) {
        if (this.selectedMembers.includes(teamMember)) {
          this.selectedMembers = this.selectedMembers.filter(member => member !== teamMember);
        }
        else {
          this.selectedMembers.push(teamMember)
        }
      },
      selectMembers() {
        this.$emit('selectMembers', [{'label': 'Ops', members: this.selectedMembers}]);
      },
    }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
