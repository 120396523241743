<template>
  <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close" :full-screen="true">
    <b-loading :active.sync="isLoading"></b-loading>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title"> Kitchen Management </p>
      </header>
    <section class="modal-card-body">
      <b-tabs type="is-toggle" v-model="activeTabBaseState" expanded destroy-on-hide>
        <b-tab-item :label="managementType.label" v-for="(managementType, index) in managementTypes" :key="index">
          <KitchenManagementTable
            :key="index"
            :managementType="managementType"
            :kitchens="kitchens"
          />
        </b-tab-item>
      </b-tabs>
    </section>
    <footer class="modal-card-foot">
      <b-button expanded :disabled="isUpdateDisable" type="button is-link" @click="update">Update</b-button>
      <b-button expanded type="button is-danger" @click="close">Close</b-button>
    </footer>
    </div>
    </b-modal>
</template>


<script>
import { mapActions, mapState } from 'vuex';

import KitchenManagementTable from '@/components/Kitchen/Kitchen/KitchenManagementTable.vue';
import { utils } from '@/mixins';

export default {
    mixins: [utils],
    props: [],
    components: { KitchenManagementTable },
    data() {
      return {
        openModal: true,
        activeTabBaseState: 0,
        managementTypes: [
          {
            'label': 'Tier',
            'value': 'category',
            'type': 'category',
            'field': 'category',
            'icon': 'folder-open'
          },
          {
            'label': 'Sales Manager',
            'value': 'sm',
            'type': 'user',
            'field': 'sales_manager_uuid',
            'icon': 'pokeball'
          },
          {
            'label': 'Area Manager',
            'value': 'am',
            'type': 'user',
            'field': 'account_manager_uuid',
            'icon': 'pokeball'
          },
          {
            'label': 'Launch Manager',
            'value': 'om',
            'type': 'user',
            'field': 'operation_manager_uuid',
            'icon': 'spray'
          },
        ],
      }
    },
    computed: {
      ...mapState({
        currentKitchensManagementInformation: (state) => state.kitchens.currentKitchensManagementInformation,
        isLoading: (state) => state.kitchens.fetchAll.pending || state.kitchens.updateOne.pending || state.accesses.fetchUsers.pending,
        kitchens: (state) => state.kitchens.all,
      }),
      isUpdateDisable() {
        var updates = false;
        if (!this.currentKitchensManagementInformation) {
          return true;
        }
        for (let type of this.currentKitchensManagementInformation.children) {
          if (type.children.length == 0) {
            continue;
          }
          for (let kitchen of type.children) {
            if (kitchen.data.isUpdated) {
              updates = true;
              break;
            }
          }
          if (updates) {
            break;
          }
        }
        if (!updates) {
          return true;
        }
        return false;
      },
    },
    methods: {
      ...mapActions('kitchens', ['updateKitchen']),
      ...mapActions('accesses', ['fetchGroups', 'fetchUsers']),
      update() {
        var kitchenToUpdates = [];
        for (let type of this.currentKitchensManagementInformation.children) {
          if (type.children.length == 0) {
            continue;
          }
          for (let child of type.children) {
            if (child.data.isUpdated) {
              var updates = {};
              var kitchen = this.$store.getters['kitchens/getKitchen'](child.data.uuid);
              var updateValue = type.name;
              if (updateValue == 'null') {
                updateValue = null;
              }
              updates[this.currentKitchensManagementInformation.field] = updateValue;
              kitchenToUpdates.push({
                'kitchenUUID': child.data.uuid,
                'kitchen': {
                  'management_information': {...kitchen.management_information, ...updates}
                }
              })
            }
          }
        }
        Promise.all([
          ...kitchenToUpdates.map((kitchenInfo) => this.updateKitchen(kitchenInfo)),
        ]).then(() => {
          this.close();
        })
      },
      close() {
          this.$router.push('/kitchens')
      },
    },
    watch: {},
    mounted() {
      this.getKitchens('?load_restaurants=false&load_secrets=false&load_restaurants_deleted=false&is_deleted=false', true);
      this.getUsers();
    }
}
</script>


<style scoped>
</style>
