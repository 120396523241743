import axios from '@/axios';
import { baseCommissionUrl } from '@/apis';


const ressourcePath = `${baseCommissionUrl}/commissions`;

export default {
  /* eslint-disable */
  buildCommissions(opts) {
    return axios.post(`${ressourcePath}/build`, opts)
      .then(response => response.data)
  },
  createCommission(opts) {
    return axios.post(`${ressourcePath}`, opts).then(response => response.data)
  }
};
