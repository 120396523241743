import Vue from 'vue';
import axios from 'axios';
axios.interceptors.request.use(config => {
    var accessToken = Vue.prototype.$auth.getToken();
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers['Content-Type'] = 'application/json';
    return Promise.resolve(config)
}, error => {
    return Promise.reject(error)
})
export default axios;
