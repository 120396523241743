<template>
  <div class="box modal-card" style="overflow: visible;">
    <p class="title is-4"> Set Up Fake Opening Hours </p>
    <div class="has-text-centered">
      <h6 class="is-italic"> By clicking here, you will close the restaurant all week.</h6>
      <b-button type="is-info" class="button-gap"  @click="set()" expanded>
        Set Up
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: ['restaurantUUID'],
  data() {
    return {}
  },
  computed: {},
  methods: {
    set() {
      this.$emit('update', {
        restaurant_uuid: this.restaurantUUID,
      })
      this.$emit('close');
    },
  }
}
</script>


<style>

.my-modal .modal-close {
  background: red;
}

</style>
