var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box modal-card modal-container" },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _vm._m(0),
      _c(
        "b-steps",
        {
          attrs: { size: "is-small", type: "is-info", "has-navigation": false },
          model: {
            value: _vm.activeStep,
            callback: function ($$v) {
              _vm.activeStep = $$v
            },
            expression: "activeStep",
          },
        },
        [
          _c("b-step-item", { attrs: { label: "Closure Type", step: "1" } }, [
            _c("div", [
              _c(
                "div",
                { staticClass: "has-text-centered" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button-gap",
                      attrs: { type: "is-danger", expanded: "" },
                      on: {
                        click: function ($event) {
                          return _vm.getKitchenClosureType("final")
                        },
                      },
                    },
                    [_vm._v(" Final Closure ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "has-text-centered" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button-gap",
                      attrs: { type: "is-warning", expanded: "" },
                      on: {
                        click: function ($event) {
                          return _vm.getKitchenClosureType("temporary")
                        },
                      },
                    },
                    [_vm._v(" Temporary Closure ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "b-step-item",
            { attrs: { label: "Restaurant Selection", step: "2" } },
            [
              _c("div", { staticClass: "has-text-centered" }, [
                _vm.isFinalClosure
                  ? _c("p", { staticClass: "title is-4" }, [
                      _vm._v("Final Closure"),
                    ])
                  : _c("p", { staticClass: "title is-4" }, [
                      _vm._v("Temporary Closure"),
                    ]),
              ]),
              _c(
                "b-field",
                { attrs: { grouped: "" } },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Country" } },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: {
                            type: "text",
                            placeholder: "Country",
                            expanded: "",
                          },
                          model: {
                            value: _vm.selectedCountry,
                            callback: function ($$v) {
                              _vm.selectedCountry = $$v
                            },
                            expression: "selectedCountry",
                          },
                        },
                        _vm._l(
                          _vm.getExistingKitchenCountries(
                            _vm.countries,
                            _vm.locations,
                            _vm.kitchens
                          ),
                          function (kitchenCountry, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                domProps: { value: kitchenCountry.uuid },
                              },
                              [_vm._v(" " + _vm._s(kitchenCountry.label) + " ")]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { attrs: { label: "Licensee" } },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: {
                            type: "text",
                            placeholder: "Licensee",
                            expanded: "",
                          },
                          model: {
                            value: _vm.selectedPartner,
                            callback: function ($$v) {
                              _vm.selectedPartner = $$v
                            },
                            expression: "selectedPartner",
                          },
                        },
                        _vm._l(
                          _vm.kitchenStatus,
                          function (kitchenStat, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                domProps: { value: kitchenStat.value },
                              },
                              [_vm._v(" " + _vm._s(kitchenStat.label) + " ")]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { attrs: { label: "Kitchen", expanded: "" } },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: {
                            type: "text",
                            placeholder: "Kitchen",
                            expanded: "",
                          },
                          model: {
                            value: _vm.selectedKitchen,
                            callback: function ($$v) {
                              _vm.selectedKitchen = $$v
                            },
                            expression: "selectedKitchen",
                          },
                        },
                        _vm._l(
                          _vm.getExistingKitchens(
                            _vm.selectedCountry,
                            _vm.selectedPartner,
                            _vm.locations,
                            _vm.kitchens,
                            false,
                            null
                          ),
                          function (kitchen, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: kitchen } },
                              [_vm._v(" " + _vm._s(kitchen.id) + " ")]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "has-text-centered" },
                [
                  _vm.selectedKitchen
                    ? _c(
                        "b-field",
                        {
                          staticStyle: {
                            "justify-content": "center",
                            "margin-bottom": "16px",
                          },
                        },
                        [
                          _vm.availableConcepts &&
                          _vm.availableConcepts.length > 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "title has-text-centered is-4",
                                    },
                                    [_vm._v("Select Concept")]
                                  ),
                                  _vm._l(
                                    _vm.availableConcepts,
                                    function (concept, index) {
                                      return _c(
                                        "b-radio",
                                        {
                                          key: index,
                                          attrs: {
                                            value: concept,
                                            name: "concept",
                                            "native-value": concept,
                                          },
                                          model: {
                                            value: _vm.selectedConcept,
                                            callback: function ($$v) {
                                              _vm.selectedConcept = $$v
                                            },
                                            expression: "selectedConcept",
                                          },
                                        },
                                        [
                                          concept.logo
                                            ? _c("div", [
                                                _c("img", {
                                                  staticClass: "image",
                                                  attrs: {
                                                    src: concept.logo,
                                                    alt: "",
                                                    width: "48",
                                                  },
                                                }),
                                              ])
                                            : _c("div", [
                                                _vm._v(_vm._s(concept.label)),
                                              ]),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "has-text-centered" },
                [
                  _vm.selectedConcept
                    ? _c(
                        "b-field",
                        {
                          staticStyle: {
                            "justify-content": "center",
                            "margin-top": "16px",
                          },
                        },
                        [
                          _vm.availablePlatforms &&
                          _vm.availablePlatforms.length > 0
                            ? _c("div", [
                                _c(
                                  "h4",
                                  {
                                    staticClass: "title has-text-centered is-4",
                                  },
                                  [_vm._v("Select Platform")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "block" },
                                  _vm._l(
                                    _vm.availablePlatforms,
                                    function (platform, index) {
                                      return _c(
                                        "b-checkbox",
                                        {
                                          key: index,
                                          attrs: {
                                            value: platform,
                                            name: "platform",
                                            "native-value": platform,
                                          },
                                          model: {
                                            value: _vm.selectedPlatforms,
                                            callback: function ($$v) {
                                              _vm.selectedPlatforms = $$v
                                            },
                                            expression: "selectedPlatforms",
                                          },
                                        },
                                        [
                                          platform.logo
                                            ? _c("div", [
                                                _c("img", {
                                                  staticClass: "image",
                                                  attrs: {
                                                    src: platform.logo,
                                                    alt: "",
                                                    width: "48",
                                                  },
                                                }),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("hr"),
              _vm.isFinalClosure
                ? _c(
                    "div",
                    [
                      _vm.selectedConcept && _vm.selectedPlatforms.length > 0
                        ? _c(
                            "b-field",
                            {
                              staticStyle: { "justify-content": "center" },
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "Close Date" } },
                                [
                                  _c("b-datepicker", {
                                    attrs: {
                                      placeholder: "Click to select...",
                                      icon: "calendar-today",
                                      position: "is-bottom-right",
                                      "trap-focus": "",
                                    },
                                    model: {
                                      value: _vm.closeDate,
                                      callback: function ($$v) {
                                        _vm.closeDate = $$v
                                      },
                                      expression: "closeDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { label: "Remove Onboarding ?" } },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      attrs: {
                                        type: "is-warning",
                                        "true-value": "Yes",
                                        "false-value": "No",
                                      },
                                      model: {
                                        value: _vm.removeOnboarding,
                                        callback: function ($$v) {
                                          _vm.removeOnboarding = $$v
                                        },
                                        expression: "removeOnboarding",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.removeOnboarding) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.availableConcepts.length == 1
                                ? _c(
                                    "b-field",
                                    { attrs: { label: "Close Kitchen ?" } },
                                    [
                                      _c(
                                        "b-checkbox",
                                        {
                                          attrs: {
                                            type: "is-warning",
                                            "true-value": "Yes",
                                            "false-value": "No",
                                          },
                                          model: {
                                            value: _vm.closeKitchen,
                                            callback: function ($$v) {
                                              _vm.closeKitchen = $$v
                                            },
                                            expression: "closeKitchen",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.closeKitchen) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedKitchen &&
              _vm.isFinalClosure &&
              _vm.availableConcepts &&
              _vm.availableConcepts.length == 0
                ? _c(
                    "div",
                    [
                      _c(
                        "b-field",
                        {
                          staticStyle: { "justify-content": "center" },
                          attrs: { grouped: "" },
                        },
                        [
                          _c(
                            "b-field",
                            { attrs: { label: "Close Date" } },
                            [
                              _c("b-datepicker", {
                                attrs: {
                                  placeholder: "Click to select...",
                                  icon: "calendar-today",
                                  position: "is-bottom-right",
                                  "trap-focus": "",
                                },
                                model: {
                                  value: _vm.closeDate,
                                  callback: function ($$v) {
                                    _vm.closeDate = $$v
                                  },
                                  expression: "closeDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { label: "Remove Onboarding ?" } },
                            [
                              _c(
                                "b-checkbox",
                                {
                                  attrs: {
                                    type: "is-success",
                                    "true-value": "Yes",
                                    "false-value": "No",
                                  },
                                  model: {
                                    value: _vm.removeOnboarding,
                                    callback: function ($$v) {
                                      _vm.removeOnboarding = $$v
                                    },
                                    expression: "removeOnboarding",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.removeOnboarding) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { label: "Close Kitchen ?" } },
                            [
                              _c(
                                "b-checkbox",
                                {
                                  attrs: {
                                    type: "is-success",
                                    "true-value": "Yes",
                                    "false-value": "No",
                                  },
                                  model: {
                                    value: _vm.closeKitchen,
                                    callback: function ($$v) {
                                      _vm.closeKitchen = $$v
                                    },
                                    expression: "closeKitchen",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.closeKitchen) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCountry &&
              _vm.selectedPartner != null &&
              _vm.selectedKitchen &&
              ((_vm.closeDate && _vm.isFinalClosure) || !_vm.isFinalClosure) &&
              ((_vm.selectedConcept && _vm.selectedPlatforms.length > 0) ||
                (_vm.availableConcepts.length == 0 && _vm.closeKitchen))
                ? _c(
                    "b-button",
                    {
                      staticClass: "button-gap",
                      attrs: { type: "is-danger", expanded: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeRestaurant()
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns is-mobile" }, [
      _c("div", { staticClass: "column is-narrow" }, [
        _c("p", { staticClass: "title is-4" }, [_vm._v("Offboarding")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }