var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card",
      staticStyle: { "overflow-y": "auto", "justify-content": "center" },
    },
    [
      _c("KitchenMailsCreationForm", {
        attrs: {
          activeStep: _vm.activeStep,
          kitchen: _vm.kitchen,
          users: _vm.users,
        },
        on: {
          "update:activeStep": function ($event) {
            _vm.activeStep = $event
          },
          "update:active-step": function ($event) {
            _vm.activeStep = $event
          },
          createMails: _vm.createMails,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }