<template>
  <div class="box modal-card" style="overflow: visible;">
    <b-loading :active.sync="isLoading"></b-loading>
    <p class="title is-4">{{title}}</p>
    <div>
      <b-field grouped>
        <b-field label="Licensee">
          <b-select type="text" v-model="selectedPartner" placeholder="Licensee" expanded>
            <option v-for="(kitchenStat, index) in kitchenStatus" :key="index" :value="kitchenStat.value">
              {{ kitchenStat.label }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Kitchen" expanded>
          <b-select type="text" v-model="selectedKitchen" placeholder="Kitchen" expanded>
            <option v-for="(kitchen, index) in getExistingKitchens(kitchenCountryUUID, selectedPartner, locations, kitchens, true, kitchenUUID)" :key="index" :value="kitchen">
              {{ kitchen.id }}
            </option>
          </b-select>
        </b-field>
      </b-field>
      <h6 class="is-italic"> Select the close date of the restaurant that will be transferred (the close is immediate into our systems)</h6>
      <b-field style="justify-content: center;" grouped>
        <b-field label="Transfer Restaurant Close Date">
          <b-datepicker
            v-model="closeDate"
            placeholder="Click to select..."
            icon="calendar-today"
            position="is-top-right"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </b-field>
      <b-button
        v-if="selectedPartner != null && selectedKitchen && closeDate"
        type="is-danger"
        class="button-gap"
        @click="transferRestaurant()"
        expanded
      >
        Transfer
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { utils } from '@/mixins';

export default {
  mixins: [utils],
  components: {},
  props: ['conceptUUID', 'kitchenCountryUUID', 'kitchenUUID', 'locationUUID', 'platformUUID', 'kitchenID', 'taskUUID', 'launchUUID'],
  data() {
    return {
      id: null,
      closeDate: null,
      kitchenStatus: [
        { label: 'Franchise', value: true },
        { label: 'Taster', value: false },
      ],
      selectedPartner: null,
      selectedKitchen: null,
    };
  },
  computed: {
    ...mapState({
      concepts: (state) =>
        state.concepts.all.filter(concept => !concept.is_deleted),
      locations: (state) => state.locations.all,
      platforms: (state) =>
        state.platforms.all.filter(platform => !platform.is_deleted),
      kitchens: (state) =>
        state.kitchens.all,
      isLoading: (state) => state.actions.transferRestaurant.pending || state.launches.updateTask.pending || state.kitchens.fetchAll.pending,
    }),
    title() {
      return `Select restaurant to transfer to ${this.kitchenID}`;
    },
    availableConcepts() {
      if (!this.selectedKitchen || !this.selectedKitchen.restaurants || this.selectedKitchen.restaurants.length == 0) {
        return [];
      }
      var concepts = [];
      var existingRestaurantsConceptUUIDS = this.selectedKitchen.restaurants
        .map((restaurant) => restaurant.concept_uuid);
      for (let concept of this.concepts) {
        if (!concept.is_deleted && existingRestaurantsConceptUUIDS.includes(concept.uuid)) {
          concepts.push(concept);
        }
      }
      return concepts;
    },
  },
  methods: {
    ...mapActions('onboardingActions', ['transferRestaurantAction']),
    ...mapActions('launches', { updateTask: 'updateTask', removeTask: 'removeTask'}),
    transferRestaurant() {
      var concept = this.concepts.find(concept => concept.uuid == this.conceptUUID);
      // var platform = this.platforms.find(platform => platform.uuid == this.platformUUID);
      this.$buefy.dialog.confirm({
        title: `Transfer ${concept.label} in ${this.selectedKitchen.id}`,
        message:
          'Are you sure you want to <b>transfer</b> this restaurant. This action will be run directly (even with close date in the future) and cannot be undone.',
        confirmText: `Transfer`,
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          var closeDateFormatted = new Date(this.closeDate.getTime() - this.closeDate.getTimezoneOffset() * 60000)
            .toISOString()
            .split('T')[0];
          this.transferRestaurantAction({
            old_concept_uuid: this.conceptUUID,
            old_location_uuid: this.selectedKitchen.location_uuid,
            old_platform_uuid: this.platformUUID,
            close_date: closeDateFormatted,
            new_kitchen_uuid: this.kitchenUUID,
            new_location_uuid: this.locationUUID,
          }).then((transferRestaurantResponse) => {
              if (transferRestaurantResponse && transferRestaurantResponse.status == 'OK') {
                this.updateTask(
                  {
                    launchUUID: this.launchUUID,
                    taskUUID: this.taskUUID,
                    is_done: true,
                  }
                ).then(() => this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false', true)).then(
                   this.close()
                )
              }
          });
        }
      })
    },
    close() {
      this.$parent.close();
    },
  },
  mounted() {
    this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false', true);
  }
};
</script>

<style>
.my-modal .modal-close {
  background: red;
}
</style>
