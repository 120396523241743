var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c(
        "div",
        { staticClass: "has-text-centered" },
        [
          _c("b-loading", {
            attrs: { active: _vm.isLoading },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          }),
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                {
                  staticStyle: { "justify-content": "center" },
                  attrs: { label: "Campaign Type", expanded: "" },
                },
                [
                  _c(
                    "b-select",
                    {
                      attrs: {
                        type: "text",
                        placeholder: "Campaign Type",
                        expanded: "",
                      },
                      model: {
                        value: _vm.campaign.campaign_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.campaign, "campaign_type", $$v)
                        },
                        expression: "campaign.campaign_type",
                      },
                    },
                    _vm._l(_vm.campaignTypes, function (campaignType, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: campaignType } },
                        [_vm._v(" " + _vm._s(campaignType) + " ")]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              !_vm.isEditingMode
                ? _c(
                    "b-field",
                    {
                      staticStyle: { "justify-content": "center" },
                      attrs: {
                        label: "Campaign Financing Model",
                        expanded: "",
                      },
                    },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: {
                            type: "text",
                            placeholder: "Campaign Financing Model",
                            expanded: "",
                          },
                          model: {
                            value: _vm.campaign.financing_model,
                            callback: function ($$v) {
                              _vm.$set(_vm.campaign, "financing_model", $$v)
                            },
                            expression: "campaign.financing_model",
                          },
                        },
                        _vm._l(
                          _vm.campaignFinancingModels,
                          function (campaignFinancingModel, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                domProps: { value: campaignFinancingModel },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(campaignFinancingModel) + " "
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.isDiscountCampaign
            ? _c(
                "b-field",
                { attrs: { grouped: "" } },
                [
                  _c(
                    "b-field",
                    {
                      staticStyle: { "justify-content": "center" },
                      attrs: {
                        label: "Discount Type",
                        expanded: "",
                        message: _vm.discountTypeMessage,
                      },
                    },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: {
                            type: "text",
                            placeholder: "Discount Type",
                            expanded: "",
                          },
                          model: {
                            value: _vm.campaign.discount_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.campaign, "discount_type", $$v)
                            },
                            expression: "campaign.discount_type",
                          },
                        },
                        _vm._l(
                          _vm.campaignDiscountTypes,
                          function (discountType, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: discountType } },
                              [_vm._v(" " + _vm._s(discountType) + " ")]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    {
                      attrs: {
                        label: _vm.discountValueFieldLabel,
                        expanded: "",
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          step: "0.01",
                          type: "number",
                          placeholder: _vm.discountValuePlaceHolder,
                        },
                        model: {
                          value: _vm.campaign.discount_value,
                          callback: function ($$v) {
                            _vm.$set(_vm.campaign, "discount_value", $$v)
                          },
                          expression: "campaign.discount_value",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isOverCampaign
                    ? _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Discount Over (Local Currency)",
                            expanded: "",
                          },
                        },
                        [
                          _c("b-input", {
                            attrs: {
                              step: "0.01",
                              type: "number",
                              placeholder: "30",
                            },
                            model: {
                              value: _vm.campaign.discount_over,
                              callback: function ($$v) {
                                _vm.$set(_vm.campaign, "discount_over", $$v)
                              },
                              expression: "campaign.discount_over",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.isItemCampaign
            ? _c(
                "b-field",
                { attrs: { grouped: "" } },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Items", expanded: "" } },
                    [
                      _c("b-autocomplete", {
                        attrs: {
                          size: "is-medium",
                          expanded: "",
                          data: _vm.filteredItems,
                          "clear-on-select": true,
                          placeholder: "Select Item",
                          icon: "magnify",
                          field: "label",
                          "open-on-focus": true,
                        },
                        on: {
                          select: function (option) {
                            return _vm.selectItem(option)
                          },
                        },
                        model: {
                          value: _vm.searchTerm,
                          callback: function ($$v) {
                            _vm.searchTerm = $$v
                          },
                          expression: "searchTerm",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isItemCampaign && this.selectedCampaignItems.length > 0
            ? _c("hr", {
                staticStyle: {
                  height: "1px",
                  border: "none",
                  color: "#333",
                  "background-color": "#333",
                },
              })
            : _vm._e(),
          _vm.isItemCampaign && this.selectedCampaignItems.length > 0
            ? _c(
                "b-field",
                {
                  staticStyle: {
                    "align-content": "center text-align: center",
                    "vertical-align": "middle",
                  },
                  attrs: { grouped: "" },
                },
                [
                  _c("b-table", {
                    attrs: {
                      data: _vm.selectedCampaignItems,
                      striped: true,
                      hoverable: true,
                      "default-sort": ["label", "desc"],
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "b-table-column",
                                {
                                  attrs: {
                                    field: "itemLabel",
                                    label: "Label",
                                    width: _vm.columnWidth,
                                  },
                                },
                                [_vm._v(" " + _vm._s(props.row.label) + " ")]
                              ),
                              _c(
                                "b-table-column",
                                {
                                  attrs: {
                                    field: "sellingPrice",
                                    label: "Selling Price",
                                    width: _vm.columnWidth,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(props.row.selling_price) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-table-column",
                                {
                                  attrs: {
                                    field: "foodCost",
                                    label: "Food Cost",
                                    width: _vm.columnWidth,
                                  },
                                },
                                [_vm._v(" " + _vm._s(props.row.foodCost) + " ")]
                              ),
                              _c(
                                "b-table-column",
                                {
                                  attrs: {
                                    field: "reimbursedFoodCost",
                                    label: "Reimbursed Food Cost",
                                    width: _vm.columnWidth,
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      step: "0.001",
                                      type: "number",
                                      placeholder: "20",
                                    },
                                    model: {
                                      value: props.row.reimbursedFoodCost,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          props.row,
                                          "reimbursedFoodCost",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "props.row.reimbursedFoodCost",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-table-column",
                                {
                                  attrs: {
                                    field: "action",
                                    label: "Actions",
                                    width: _vm.columnWidth,
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        type: "button is-danger is-small is-outlined",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeCampaignItem(
                                            props.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Delete ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4049329859
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isItemCampaign && this.selectedItems.length > 0
            ? _c("hr", {
                staticStyle: {
                  height: "1px",
                  border: "none",
                  color: "#333",
                  "background-color": "#333",
                },
              })
            : _vm._e(),
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                { attrs: { label: "Estimated Uplift (%)", expanded: "" } },
                [
                  _c("b-input", {
                    attrs: { step: "0.01", type: "number", placeholder: "20" },
                    model: {
                      value: _vm.campaign.estimated_uplift,
                      callback: function ($$v) {
                        _vm.$set(_vm.campaign, "estimated_uplift", $$v)
                      },
                      expression: "campaign.estimated_uplift",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "Budget (Local Currency)", expanded: "" } },
                [
                  _c("b-input", {
                    attrs: {
                      step: "0.01",
                      type: "number",
                      placeholder: "10000",
                    },
                    model: {
                      value: _vm.campaign.budget,
                      callback: function ($$v) {
                        _vm.$set(_vm.campaign, "budget", $$v)
                      },
                      expression: "campaign.budget",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isEditingMode
            ? _c(
                "b-button",
                {
                  attrs: {
                    expanded: "",
                    disabled: _vm.isSetUpDisable(),
                    type: _vm.getButtonType(),
                  },
                  on: { click: _vm.selectKitchens },
                },
                [_vm._v(_vm._s(_vm.getButtonLabel()))]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }