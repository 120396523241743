const baseUrl = process.env.VUE_APP_SERVER_URL;
const baseUrlV2 = process.env.VUE_APP_SERVER_URL_V2;
const baseOnboardingUrl = process.env.VUE_APP_KITCHEN_ONBOARDING_API;
const baseOnboardingUrlV2 = process.env.VUE_APP_KITCHEN_ONBOARDING_API_V2;
const baseCommissionUrl = process.env.VUE_APP_COMMISSION_API;
const baseMarketingUrl = process.env.VUE_APP_MARKETING_API;
const basePlatformStateUrl = process.env.VUE_APP_TPS_API;
const baseItemManagerUrl = process.env.VUE_APP_ITEM_MANAGER_API;
const marketingMock = process.env.VUE_APP_MARKETING_MOCK;
const baseMenuManagerUrl = process.env.VUE_APP_MENU_MANAGER_API
const baseProductManagerV3 = process.env.VUE_APP_PRODUCT_MANAGER_API_V3;


export {
  baseUrl,
  baseUrlV2,
  baseOnboardingUrl,
  baseOnboardingUrlV2,
  baseCommissionUrl,
  baseMarketingUrl,
  basePlatformStateUrl,
  baseItemManagerUrl,
  marketingMock,
  baseMenuManagerUrl,
  baseProductManagerV3
}
