import axios from '@/axios';
import { baseProductManagerV3 } from '@/apis'


const ressourcePathV2 = `${baseProductManagerV3}/hubs`;

export default {
/* eslint-disable */
  fetchAll() {
    return axios.get(`${ressourcePathV2}?per_page=100&order_by=label&desc=false`)
                .then(response => response.data)
  },
};
