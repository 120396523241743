<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-loading :active.sync="isSettingCurrent"></b-loading>
    <b-table
      :data="campaignsInformations"
      :striped="true"
      :hoverable="true"
      :sticky-header="false"
      :show-detail-icon="true"
      detailed
      paginated
      backend-pagination
      :total="campaignsTotal"
      :per-page="perPage"
      :current-page="page"
      @page-change="onPageChange"
      @details-open="(row, index) => fetchCampaignElements(row)"
      >
      <template slot-scope="props">
        <b-table-column field="label" label="Label" :width="columnWidth">
            {{ props.row.label }}
        </b-table-column>
        <b-table-column field="country" label="Country" :width="columnWidth">
            {{ props.row.country }}
        </b-table-column>
        <b-table-column field="concept" label="Concept" :width="columnWidth">
          <div v-if="props.row.concept_internal">
            <img
              :src="props.row.concept_logo"
              :alt="props.row.concept_label"
              onerror="this.style.display='none'"
              class="concept-logo"
            />
          </div>
          <div v-else>
            {{ props.row.concept_label }}
          </div>
        </b-table-column>
        <b-table-column field="platform" label="Platform" :width="columnWidth">
          <img
            :src="props.row.platform_logo"
            :alt="props.row.platform_label"
            onerror="this.style.display='none'"
            class="concept-logo"
          />
        </b-table-column>
        <b-table-column field="event_label" label="Event Label" :width="columnWidth">
            {{ props.row.eventName }}
        </b-table-column>
        <b-table-column field="start_date" label="Start Date" :width="columnWidth" :custom-sort="sortByDate">
            {{ props.row.startDate }}
        </b-table-column>
        <b-table-column field="end_date" label="End Date" :width="columnWidth"  :custom-sort="sortByDate">
            {{ props.row.endDate }}
        </b-table-column>
        <b-table-column label="Actions">
          <b-dropdown hoverable aria-role="list" position="is-bottom-left">
            <button class="button is-info is-small" slot="trigger">
              <span>Actions</span>
            </button>
            <b-dropdown-item aria-role="listitem" @click="openCampaignModal(props.row.campaignUUID)">
              <b-icon icon="pencil" size="is-small"></b-icon>
              Edit
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="duplicate(props.row.campaignUUID)">
              <b-icon icon="qrcode" size="is-small"></b-icon>
              Duplicate
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="deleteCampaign(props.row.campaignUUID)">
              <b-icon icon="delete" size="is-small"></b-icon>
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="campaign_props">
        <b-field grouped>
          <b-field label="Campaign Type" style="justify-content: center;" expanded>
            {{campaign_props.row.campaign_type}}
          </b-field>
          <b-field label="Campaign Scope" style="justify-content: center;" expanded>
            {{campaign_props.row.campaign_scope}}
          </b-field>
          <b-field label="Max Optin Date" style="justify-content: center;" expanded>
            {{campaign_props.row.maxOptinDate}}
          </b-field>
        </b-field>
        <b-field grouped v-if="isDiscountCampaign(campaign_props.row)">
          <b-field label="Discount Type" style="justify-content: center;" expanded>
            {{campaign_props.row.discount_type}}
          </b-field>
          <b-field label="Discount Value" style="justify-content: center;" expanded>
            {{campaign_props.row.discount_value}}
          </b-field>
          <b-field v-if="isOverCampaign(campaign_props.row)" label="Discount Over" style="justify-content: center;" expanded>
            {{campaign_props.row.discount_over}}
          </b-field>
        </b-field>
        <b-field grouped>
          <b-field label="Items" position="is-centered" v-if="isDiscountItemCampaign(campaign_props.row)">
            <b-taglist>
              <b-tag v-for="(item, index) in getCampaignItems(campaign_props.row)" :key="index" type="is-info">
                {{item.label}}
              </b-tag>
            </b-taglist>
          </b-field>
        </b-field>
        <b-field grouped>
          <b-field label="Estimated Uplift (%)" style="justify-content: center;" expanded>
            {{campaign_props.row.estimated_uplift}}
          </b-field>
          <b-field label="Budget" style="justify-content: center;" expanded>
            {{campaign_props.row.budget}}
          </b-field>
        </b-field>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"></b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState, mapMutations } from 'vuex';
import { errorToasterDetailled } from '@/mixins';
import { campaignsTypes, kitchenCampaignsTypes } from '@/store/mutation-types';

export default {
  props: ['concepts', 'countries', 'platforms', 'page', 'perPage'],
  mixins: [errorToasterDetailled],
  data() {
    return {
      columnWidth: 250,
      isEmpty: false,
      displaySuccess: false,
      isSettingCurrent: false,
      campaignItemsEmpty : {},
    }
  },
  computed: {
    ...mapState({
      campaigns: (state) => state.campaigns.all,
      campaignsTotal: (state) => state.campaigns.total,
      currentCampaign: (state) => state.campaigns.current,
      currentCampaignItems: (state) => state.campaignItems.currents,
      events: (state) => state.events.all,
      locations: (state) => state.locations.all,
      campaignTimelines() {
        return this.$store.getters['kitchenCampaigns/getKitchenCampaignTimelines'];
      },
      selectedConcepts: (state) => state.concepts.selected,
      selectedPlatforms: (state) => state.platforms.selected,
      selectedCampaignTimelines: (state) => state.campaigns.selectedCampaignTimelines,
      campaignsInformations: function() {
        return this.campaigns.map((campaign) => {
          var concept = this.concepts.find(concept => concept.uuid == campaign.concept_uuid);
          var platform = this.platforms.find(platform => platform.uuid == campaign.platform_uuid);
          var location = this.locations.find(location => location.uuid == campaign.country_location_uuid);
          if (concept && platform && location) {
            return {
              'concept_label': concept.label,
              'concept_uuid': concept.uuid,
              'concept_logo': concept.logo,
              'concept_internal': concept.is_internal,
              'country_location_uuid': location.uuid,
              'country': location.label,
              'platform_label': platform.label,
              'platform_uuid': platform.uuid,
              'platform_logo': platform.logo,
              'campaignUUID': campaign.uuid,
              'startDate': campaign.start_date,
              'endDate': campaign.end_date,
              'maxOptinDate': campaign.max_optin_date,
              'label': campaign.label,
              'campaign_type': campaign.campaign_type,
              'campaign_scope': campaign.campaign_scope,
              'financing_model': campaign.financing_model,
              'estimated_uplift': campaign.estimated_uplift,
              'budget': campaign.budget,
              'discount_type': campaign.discount_type,
              'discount_value': campaign.discount_value,
              'discount_over': campaign.discount_over,
              'discounted_items': campaign.discounted_items,
              'eventName': this.getEventName(campaign.event_uuid),
              'is_deleted': campaign.is_deleted,
              }
            }
          else {return {}}
        }).filter(campaignInfo => campaignInfo.campaignUUID)
      },
      isLoading: (state) => state.kitchenCampaigns.createOne.pending || state.campaigns.createOne.pending || state.events.createOne.pending || state.campaignItems.createOne.pending  || state.campaigns.updateOne.pending || state.kitchenCampaigns.updateOne.pending || state.events.updateOne.pending || state.campaignItems.updateOne.pending || state.campaignItems.fetchAll.pending,
    }),
    campaignItems: {
      get() {
        return this.campaignItemsEmpty;
      },
      set(value) {
        this.campaignItemsEmpty[value.uuid] = value.items;
      }
    }
  },
  methods: {
    ...mapActions('campaigns', {deleteCampaign: 'deleteOne', fetchCampaigns: 'fetchAll', fetchCampaign: 'fetchOne' }),
    ...mapMutations('campaigns', {'setCurrentCampaign': campaignsTypes.SET_CURRENT, 'duplicateCampaign': campaignsTypes.DUPLICATE}),
    ...mapActions('campaignItems', {fetchCampaignItems: 'fetchAll'}),
    ...mapMutations('kitchenCampaigns', {'setCurrentsKitchenCampaigns': kitchenCampaignsTypes.SET_CURRENTS}),
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return new Date(b.campaignDate).getTime() - new Date(a.campaignDate).getTime();
      } else {
        return new Date(a.campaignDate).getTime() - new Date(b.campaignDate).getTime();
      }
    },
    isItemCampaign: function(campaign) {
      return campaign.campaign_type && (campaign.campaign_type.toLowerCase().includes('discount_items') || campaign.campaign_type.toLowerCase().includes('bogo') || campaign.campaign_type.toLowerCase().includes('free_items') );
    },
    isDiscountCampaign: function(campaign) {
      return campaign.campaign_type && campaign.campaign_type.toLowerCase().includes('discount');
    },
    isOverCampaign: function(campaign) {
      return this.isDiscountCampaign(campaign) && campaign.discount_type && campaign.discount_type.toLowerCase().includes('over');
    },
    isDiscountItemCampaign: function(campaign) {
      return campaign.campaign_type && campaign.campaign_type.toLowerCase().includes('discount_items');
    },
    getEventName: function(eventUUID) {
      if (!eventUUID || this.events == null || this.events.length == 0){
        return ''
      }
      var event = this.events.find(event => event.uuid == eventUUID)
      if (event) {
        return event.label;
      }
      return ''
    },
    getCampaignItems(campaign) {
      if (this.campaignItemsEmpty.hasOwnProperty(campaign.campaignUUID)) {
        return this.campaignItemsEmpty[campaign.campaignUUID];
      }
      return [];
    },
    openCampaignModal: function(campaignUUID) {
      this.$router.push('/campaigns/' + campaignUUID)
    },
    onPageChange: function(page) {
      this.$emit('changePage', page)
    },
    fetchCampaignElements(campaign) {
      if (this.isItemCampaign(campaign)) {
        this.fetchCampaignItems({campaignUUID: campaign.campaignUUID}).then((items) => {
          this.campaignItems[campaign.campaignUUID] = {uuid: campaign.campaignUUID, items: items.items};
        })
      }
    },
    duplicate(campaignUUID) {
      var campaign = {...this.campaigns.find(campaign => campaign.uuid == campaignUUID)};
      campaign.end_date = new Date(campaign.end_date);
      campaign.start_date = new Date(campaign.start_date);
      campaign.max_optin_date = new Date(campaign.max_optin_date);
      campaign.dates = [campaign.start_date, campaign.end_date];
      this.duplicateCampaign(campaign);
      var modalStep = 0;
      this.$emit('openNew', modalStep, campaign.campaign_scope == "DEDICATED")
    }
  },
  mounted() {},
  watch: {},
}
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
