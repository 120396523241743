<template>
  <section>
    <b-dropdown
        v-model="currentCampaignTimelines"
        multiple
        @active-change="selectCampaignTimeline"
        :scrollable="isScrollable"
        :max-height="maxHeight"
        aria-role="list"
    >
      <button class="button is-info" type="button" slot="trigger">
          <span> Campaign Timelines ({{ currentCampaignTimelines.length }})</span>
          <b-icon icon="menu-down"></b-icon>
      </button>
      <b-dropdown-item v-for="(campaignTimeline, index) in campaignTimelines"
        :key="index"
        :value="campaignTimeline"
        aria-role="listitem">
          <span> {{ campaignTimeline }} </span>
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-model="currentDates"
        @active-change="selectDates"
        aria-role="list"
    >
      <button class="button is-info" type="button" slot="trigger">
          <span v-if="currentDates.length == 2"> Dates ({{ `${formatDateWord(currentDates[0])} - ${formatDateWord(currentDates[1])}` }})</span>
          <span v-else> Dates </span>
          <b-icon icon="menu-down"></b-icon>
      </button>
        <b-datepicker
          v-model="currentDates"
          inline
          :max-date="maxDate"
          :min-date="minDate"
          locale='fr-Fr'
          icon="calendar-today"
          position="is-bottom-right"
          range>
        </b-datepicker>
        <b-button v-if="currentDates.length == 2" class="button" expanded type="button" @click='clearDates()'>Clear</b-button>
    </b-dropdown>
    <b-dropdown
        v-model="currentCountry"
        @active-change="selectCountry"
        :scrollable="isScrollable"
        :max-height="maxHeight"
        aria-role="list">
        <button class="button is-info" type="button" slot="trigger">
            <span v-if="currentCountry.label == 'All'">Countries ({{ activeCountries.length }})</span>
            <span v-else>Countries (1)</span>
            <b-icon icon="menu-down"></b-icon>
        </button>
        <b-dropdown-item v-for="(country, index) in activeCountries"
          :key="index"
          :value="country"
          aria-role="listitem">
            <span> {{ country.label }} </span>
        </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-model="currentConcept"
        @active-change="selectConcept"
        :scrollable="isScrollable"
        :max-height="maxHeight"
        aria-role="list">
        <button class="button is-info" type="button" slot="trigger">
            <span v-if="currentConcept.label == 'All'">Concepts ({{ activeConcepts.length }})</span>
            <span v-else>Concepts (1)</span>
            <b-icon icon="menu-down"></b-icon>
        </button>
        <b-dropdown-item v-for="(concept, index) in activeConcepts"
          :key="index"
          :value="concept"
          aria-role="listitem">
            <span> {{ concept.label }} </span>
        </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-model="currentPlatform"
        @active-change="selectPlatform"
        :scrollable="isScrollable"
        :max-height="maxHeight"
        text-align="center">
        <button class="button is-info" type="button" slot="trigger">
            <span v-if="currentPlatform.label == 'All'">Platforms ({{ activePlatforms.length }})</span>
            <span v-else>Platforms (1)</span>
            <b-icon icon="menu-down"></b-icon>
        </button>
        <b-dropdown-item v-for="(platform, index) in activePlatforms"
          :key="index"
          :value="platform"
          aria-role="listitem">
            <span> {{ platform.label }} </span>
        </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-model="currentCampaignType"
        @active-change="selectCampaignType"
        :scrollable="isScrollable"
        :max-height="maxHeight"
        text-align="center">
        <button class="button is-info" type="button" slot="trigger">
            <span v-if="currentCampaignType  == 'All'">Campaign Types ({{ campaignTypes.length }})</span>
            <span v-else>Campaign Types (1)</span>
            <b-icon icon="menu-down"></b-icon>
        </button>
        <b-dropdown-item v-for="(campaignType, index) in campaignTypes"
          :key="index"
          :value="campaignType"
          aria-role="listitem">
            <span> {{ campaignType }} </span>
        </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-model="currentLocation"
        v-if="displayLocationFilter"
        @active-change="selectLocation"
        :scrollable="isScrollable"
        :max-height="maxHeight"
        text-align="center">
        <button class="button is-info" type="button" slot="trigger">
            <span v-if="currentLocation  == 'All'"> Kitchens ({{ activeLocations.length }})</span>
            <span v-else>Kitchens (1)</span>
            <b-icon icon="menu-down"></b-icon>
        </button>
        <b-dropdown-item v-for="(location, index) in activeLocations"
          :key="index"
          :value="location"
          aria-role="listitem">
            <span> {{ location.label }} </span>
        </b-dropdown-item>
    </b-dropdown>
    <b-button class="is-success fil" v-if="filtersToApply" @click="applyFilters()">
        Apply
    </b-button>
  </section>
</template>

<script>
import { commons, utils } from '@/mixins';

export default {
  mixins: [commons, utils],
  props: ['activeConcepts', 'activeCountries', 'activePlatforms', 'activeLocations', 'campaignTypes', 'campaignTimelines', 'selectedCampaignType', 'selectedConcept', 'selectedCountry', 'selectedDates', 'selectedLocation', 'selectedPlatform', 'selectedCampaignTimelines', 'displayLocationFilter'],
  data() {
    return {
      isScrollable: true,
      filtersToApply: false,
      maxHeight: 200,
      currentConcept: this.selectedConcept,
      currentCountry: this.selectedCountry,
      currentDates: this.selectedDates,
      currentLocation: this.selectedLocation,
      currentPlatform: this.selectedPlatform,
      currentCampaignType: this.selectedCampaignType,
      currentCampaignTimelines: [...this.selectedCampaignTimelines],
    }
  },
  computed: {
    maxDate() {
      if (this.currentCampaignTimelines.length == 0 || this.currentCampaignTimelines.includes('Upcoming') || this.currentCampaignTimelines.includes('Current')) {
        return null;
      }
      return this.getDateWithOffset(new Date(), -1);
    },
    minDate() {
      if (this.currentCampaignTimelines.length == 0 || this.currentCampaignTimelines.includes('Past') || this.currentCampaignTimelines.includes('Current')) {
        return null;
      }
      return new Date();
    },
  },
  methods: {
    clearDates: function () {
      this.currentDates = [];
    },
    selectConcept: function(active) {
      if (!active && this.selectedConcept.uuid != this.currentConcept.uuid) {
        this.filtersToApply = true;
        this.$emit('select-concept', this.currentConcept)
      }
    },
    selectCountry: function(active) {
      if (!active && this.selectedCountry.uuid != this.currentCountry.uuid) {
        this.filtersToApply = true;
        this.$emit('select-country', this.currentCountry)
      }
    },
    selectDates: function(active) {
      if (!active && this.currentDates && this.currentDates.length > 0 ) {
        this.filtersToApply = true;
        this.$emit('select-dates', this.currentDates)
      }
    },
    selectLocation: function(active) {
      if (!active && this.selectedLocation.uuid != this.currentLocation.uuid) {
        this.filtersToApply = true;
        this.$emit('select-location', this.currentLocation)
      }
    },
    selectPlatform: function(active) {
      if (!active && this.selectedPlatform.uuid != this.currentPlatform.uuid) {
        this.filtersToApply = true;
        this.$emit('select-platform', this.currentPlatform)
      }
    },
    selectCampaignTimeline: function(active) {
      if (!active && !this.setsAreEqual(new Set(this.selectedCampaignTimelines), new Set(this.currentCampaignTimelines))) {
        this.filtersToApply = true;
        this.$emit('select-campaign-timelines', this.currentCampaignTimelines)
      }
    },
    selectCampaignType: function(active) {
      if (!active && this.selectedCampaignType != this.currentCampaignType) {
        this.filtersToApply = true;
        this.$emit('select-campaign-type', this.currentCampaignType)
      }
    },
    applyFilters() {
      this.$emit('refresh');
      this.filtersToApply = false;
    }
  },
};
</script>

<style>
.dropdown-trigger.a.dropdown-item.is-active {
  background-color: #ff3860;
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
  background-color: #ff3860;
}
.fil {
  margin-left: 8px;
}
</style>
