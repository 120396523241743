var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card",
      staticStyle: { "overflow-y": "auto", "justify-content": "center" },
    },
    [
      _c("p", { staticClass: "title is-4" }, [_vm._v(" Task ")]),
      _c(
        "section",
        { staticClass: "modal-card-body has-text-centered" },
        [
          _c(
            "b-tabs",
            {
              attrs: { expanded: "" },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "b-tab-item",
                { attrs: { label: "General" } },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Label", expanded: "" } },
                    [
                      _c("b-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.task.label,
                          callback: function ($$v) {
                            _vm.$set(_vm.task, "label", $$v)
                          },
                          expression: "task.label",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { attrs: { label: "Description", expanded: "" } },
                    [
                      _c("b-input", {
                        attrs: { type: "textarea", placeholder: "" },
                        model: {
                          value: _vm.task.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.task, "description", $$v)
                          },
                          expression: "task.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { attrs: { grouped: "" } },
                    [
                      _c(
                        "b-field",
                        { attrs: { label: "Owner", expanded: "" } },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: {
                                type: "text",
                                placeholder: "Task Owner",
                                expanded: "",
                              },
                              model: {
                                value: _vm.task.owner,
                                callback: function ($$v) {
                                  _vm.$set(_vm.task, "owner", $$v)
                                },
                                expression: "task.owner",
                              },
                            },
                            _vm._l(_vm.owners, function (owner, index) {
                              return _c(
                                "option",
                                { key: index, domProps: { value: owner } },
                                [_vm._v(" " + _vm._s(owner) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        { attrs: { label: "Task Group", expanded: "" } },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: {
                                type: "text",
                                placeholder: "Task Group",
                                expanded: "",
                              },
                              model: {
                                value: _vm.task.task_group_uuid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.task, "task_group_uuid", $$v)
                                },
                                expression: "task.task_group_uuid",
                              },
                            },
                            _vm._l(_vm.taskGroups, function (taskGroup, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: { value: taskGroup.uuid },
                                },
                                [_vm._v(" " + _vm._s(taskGroup.label) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { attrs: { label: "Countries", position: "is-centered" } },
                    [
                      _c(
                        "b-taglist",
                        _vm._l(_vm.taskCountries, function (country, index) {
                          return _c(
                            "b-tag",
                            {
                              key: index,
                              attrs: {
                                type: "is-info",
                                closable: "",
                                "close-icon": "delete",
                                "close-type": "is-danger",
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.removeCountry(country)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(country.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    [
                      _c("b-autocomplete", {
                        attrs: {
                          size: "is-small",
                          expanded: "",
                          data: _vm.countriesSorted,
                          "clear-on-select": true,
                          placeholder: "Add new countries...",
                          icon: "magnify",
                          field: "label",
                          "append-to-body": true,
                        },
                        on: {
                          select: function (option) {
                            return _vm.addCountry(option)
                          },
                        },
                        model: {
                          value: _vm.searchTerm,
                          callback: function ($$v) {
                            _vm.searchTerm = $$v
                          },
                          expression: "searchTerm",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tab-item",
                { attrs: { label: "Dependencies" } },
                [
                  _c(
                    "b-field",
                    {
                      attrs: { label: "Dependencies", position: "is-centered" },
                    },
                    [
                      _c(
                        "b-taglist",
                        _vm._l(_vm.taskDependencies, function (task, index) {
                          return _c(
                            "b-tag",
                            {
                              key: index,
                              attrs: {
                                type: "is-info",
                                closable: "",
                                "close-icon": "delete",
                                "close-type": "is-danger",
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.removeTask(task)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(task.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    [
                      _c("b-autocomplete", {
                        attrs: {
                          size: "is-small",
                          expanded: "",
                          data: _vm.filteredTasks,
                          "clear-on-select": true,
                          placeholder: "Add new tasks...",
                          icon: "magnify",
                          field: "label",
                          "append-to-body": true,
                        },
                        on: {
                          select: function (option) {
                            return _vm.addTask(option)
                          },
                        },
                        model: {
                          value: _vm.searchTerm,
                          callback: function ($$v) {
                            _vm.searchTerm = $$v
                          },
                          expression: "searchTerm",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tab-item",
                { attrs: { label: "Links" } },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "16px" } },
                    [
                      _c("b", [_vm._v("Links")]),
                      _c(
                        "b-button",
                        {
                          staticStyle: { "margin-left": "16px" },
                          attrs: { "icon-left": "plus", size: "is-small" },
                          on: {
                            click: function () {
                              return _vm.task.links.push({ label: "", url: "" })
                            },
                          },
                        },
                        [_vm._v("Add")]
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.task.links, function (link, index) {
                    return _c(
                      "b-field",
                      { key: index, attrs: { grouped: "" } },
                      [
                        _c(
                          "b-field",
                          { attrs: { expanded: "" } },
                          [
                            _c("b-input", {
                              attrs: {
                                type: "text",
                                size: "is-small",
                                rounded: "",
                                placeholder: "Label",
                                expanded: "",
                              },
                              model: {
                                value: link.label,
                                callback: function ($$v) {
                                  _vm.$set(link, "label", $$v)
                                },
                                expression: "link.label",
                              },
                            }),
                            _c("b-input", {
                              staticStyle: { "margin-left": "10px" },
                              attrs: {
                                type: "text",
                                size: "is-small",
                                rounded: "",
                                placeholder: "URL",
                                expanded: "",
                              },
                              model: {
                                value: link.url,
                                callback: function ($$v) {
                                  _vm.$set(link, "url", $$v)
                                },
                                expression: "link.url",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { "icon-left": "delete", size: "is-small" },
                            on: {
                              click: function () {
                                return _vm.task.links.splice(index, 1)
                              },
                            },
                          },
                          [_vm._v("Remove")]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "b-tab-item",
                { attrs: { label: "Tech" } },
                [
                  _c(
                    "b-field",
                    { attrs: { grouped: "" } },
                    [
                      _c(
                        "b-field",
                        { attrs: { label: "Automatic" } },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: { placeholder: "Automatic" },
                              model: {
                                value: _vm.task.is_automatic,
                                callback: function ($$v) {
                                  _vm.$set(_vm.task, "is_automatic", $$v)
                                },
                                expression: "task.is_automatic",
                              },
                            },
                            _vm._l(
                              _vm.automaticValues,
                              function (automaticValue) {
                                return _c(
                                  "option",
                                  {
                                    key: automaticValue,
                                    domProps: { value: automaticValue },
                                  },
                                  [_vm._v(" " + _vm._s(automaticValue) + " ")]
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        { attrs: { label: "Manual" } },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: { placeholder: "Manual" },
                              model: {
                                value: _vm.task.is_manual,
                                callback: function ($$v) {
                                  _vm.$set(_vm.task, "is_manual", $$v)
                                },
                                expression: "task.is_manual",
                              },
                            },
                            _vm._l(_vm.manualValues, function (manualValue) {
                              return _c(
                                "option",
                                {
                                  key: manualValue,
                                  domProps: { value: manualValue },
                                },
                                [_vm._v(" " + _vm._s(manualValue) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        { attrs: { label: "Reopen Required" } },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: { placeholder: "Reopen Required" },
                              model: {
                                value: _vm.task.is_reopen_required,
                                callback: function ($$v) {
                                  _vm.$set(_vm.task, "is_reopen_required", $$v)
                                },
                                expression: "task.is_reopen_required",
                              },
                            },
                            _vm._l(_vm.reopenValues, function (reopenValue) {
                              return _c(
                                "option",
                                {
                                  key: reopenValue,
                                  domProps: { value: reopenValue },
                                },
                                [_vm._v(" " + _vm._s(reopenValue) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        { attrs: { label: "Onboarding Type" } },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: { placeholder: "Onboarding Type" },
                              model: {
                                value: _vm.task.task_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.task, "task_type", $$v)
                                },
                                expression: "task.task_type",
                              },
                            },
                            _vm._l(_vm.typeValues, function (typeValue) {
                              return _c(
                                "option",
                                {
                                  key: typeValue,
                                  domProps: { value: typeValue },
                                },
                                [_vm._v(" " + _vm._s(typeValue) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { attrs: { grouped: "" } },
                    [
                      _c(
                        "b-field",
                        { attrs: { label: "Launch Type" } },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: { placeholder: "Launch Type" },
                              model: {
                                value: _vm.task.launch_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.task, "launch_type", $$v)
                                },
                                expression: "task.launch_type",
                              },
                            },
                            _vm._l(_vm.launchTypeValues, function (typeValue) {
                              return _c(
                                "option",
                                {
                                  key: typeValue,
                                  domProps: { value: typeValue },
                                },
                                [_vm._v(" " + _vm._s(typeValue) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        { attrs: { label: "Level Type" } },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: { placeholder: "Level Type" },
                              model: {
                                value: _vm.task.task_level,
                                callback: function ($$v) {
                                  _vm.$set(_vm.task, "task_level", $$v)
                                },
                                expression: "task.task_level",
                              },
                            },
                            _vm._l(_vm.levelValues, function (levelValue) {
                              return _c(
                                "option",
                                {
                                  key: levelValue,
                                  domProps: { value: levelValue },
                                },
                                [_vm._v(" " + _vm._s(levelValue) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { attrs: { label: "UUID" } },
                    [
                      _c("b-input", {
                        attrs: { disabled: true, placeholder: "" },
                        model: {
                          value: _vm.task.uuid,
                          callback: function ($$v) {
                            _vm.$set(_vm.task, "uuid", $$v)
                          },
                          expression: "task.uuid",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.task.uuid
        ? _c(
            "b-button",
            {
              attrs: {
                expanded: "",
                disabled: _vm.saveOrUpdateDisabled,
                type: "button is-success",
              },
              on: { click: _vm.createTask },
            },
            [_vm._v("Create")]
          )
        : _c(
            "b-button",
            {
              attrs: {
                expanded: "",
                disabled: _vm.saveOrUpdateDisabled,
                type: "button is-success",
              },
              on: { click: _vm.updateTask },
            },
            [_vm._v("Update")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }