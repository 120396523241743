var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box modal-card", staticStyle: { overflow: "visible" } },
    [
      _c("p", { staticClass: "title is-4" }, [
        _vm._v(" Set Up Fake Opening Hours "),
      ]),
      _c(
        "div",
        { staticClass: "has-text-centered" },
        [
          _c("h6", { staticClass: "is-italic" }, [
            _vm._v(
              " By clicking here, you will close the restaurant all week."
            ),
          ]),
          _c(
            "b-button",
            {
              staticClass: "button-gap",
              attrs: { type: "is-info", expanded: "" },
              on: {
                click: function ($event) {
                  return _vm.set()
                },
              },
            },
            [_vm._v(" Set Up ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }