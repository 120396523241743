<template>
  <div>
    <b-field grouped>
      <b-field class="control" label="Launch UUID" expanded>
        <b-input
          v-model="launch.uuid"
          type="text"
          required
          :disabled="true"
          placeholder="Launch UUID"
        >
        </b-input>
      </b-field>
      <b-field class="control" label="Kitchen UUID" expanded>
        <b-input
          v-model="kitchen.uuid"
          type="text"
          required
          :disabled="true"
          placeholder="Kitchen UUID"
        >
        </b-input>
      </b-field>
      <b-field class="control" label="Location UUID" expanded>
        <b-input
          v-model="kitchen.location_uuid"
          type="text"
          required
          :disabled="true"
          placeholder="Location UUID"
        >
        </b-input>
      </b-field>
      <b-field class="control" label="Concept UUID" expanded>
        <b-input
          v-model="launch.concept_uuid"
          type="text"
          required
          :disabled="true"
          placeholder="Concept UUID"
        >
        </b-input>
      </b-field>
    </b-field>
    <b-field grouped v-for="(restaurant, index) in kitchenRestaurants.filter(restaurant => restaurant.concept_uuid == launch.concept_uuid)" :key="index" :value="restaurant">
      <b-field class="control" label="Platform">
        <b-input
          v-model="restaurant.platform_restaurant.label"
          type="text"
          required
          :disabled="true"
          placeholder="Platform"
        >
        </b-input>
      </b-field>
      <b-field class="control" label="Platform UUID" expanded>
        <b-input
          v-model="restaurant.platform_uuid"
          type="text"
          required
          :disabled="true"
          placeholder="Platform UUID"
        >
        </b-input>
      </b-field>
      <b-field class="control" label="Restaurant UUID" expanded>
        <b-input
          v-model="restaurant.uuid"
          type="text"
          required
          :disabled="true"
          placeholder="Restaurant UUID"
        >
        </b-input>
      </b-field>
      <b-field class="control" label="Restaurant Platform ID" expanded>
        <b-input
          v-model="restaurant.restaurant_platform_id"
          type="text"
          required
          :disabled="true"
          placeholder="Restaurant Platform ID"
        >
        </b-input>
      </b-field>
    </b-field>
  </div>
</template>

<script>

export default {
  props: ['kitchen', 'launch'],
  data() {
    return {}
  },
  computed: {
    kitchenRestaurants() {
      return this.kitchen.restaurants || [];
    }
  },
  methods: {}
}
</script>

