<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <p class="title is-4"> Task Group </p>
    <section class="modal-card-body has-text-centered">
      <b-tabs v-model="activeTab" expanded>
        <b-tab-item label="General">
          <b-field label="Label" expanded>
            <b-input v-model="taskGroup.label" placeholder=""></b-input>
          </b-field>
          <!-- <b-field label="Launch Required">
            <b-select
              placeholder="Launch Required"
              v-model="taskGroup.is_required"
              expanded
            >
              <option v-for="requiredValue in requiredValues" :value="requiredValue" :key="requiredValue">
                {{ requiredValue }}
              </option>
            </b-select>
          </b-field> -->
          <div v-if="!taskGroup.is_required">
            <div style="margin-bottom: 16px">
              <b>Conditions</b>
              <b-button
                icon-left="plus"
                size="is-small"
                class="button-gap"
                @click="() => taskGroup.launch_conditions.push({ condition_type: '', value: '' })"
                >Add</b-button
              >
            </div>
            <b-field grouped v-for="(condition, index) in taskGroup.launch_conditions" :key="index">
              <b-field expanded>
                <b-select
                  placeholder="Type"
                  size="is-small"
                  v-model="condition.condition_type"
                  expanded
                  rounded
                >
                  <option v-for="conditionType in conditionTypes" :value="conditionType" :key="conditionType">
                    {{ conditionType }}
                  </option>
                </b-select>
                <b-select
                  style="margin-left: 10px;"
                  placeholder="Type"
                  size="is-small"
                  v-model="condition.value"
                  expanded
                  rounded
                >
                  <option v-for="conditionValue in getConditionValues(condition.condition_type)" :value="conditionValue.uuid" :key="conditionValue.uuid">
                    {{ conditionValue.label }}
                  </option>
                </b-select>
              </b-field>
              <b-button icon-left="delete" size="is-small" @click="() => taskGroup.launch_conditions.splice(index, 1)">Remove</b-button>
            </b-field>
          </div>
          <b-button v-if="!taskGroup.uuid" expanded :disabled="saveOrUpdateDisabled" type="button is-success" @click="createTaskGroup">Create</b-button>
          <b-button v-else expanded :disabled="saveOrUpdateDisabled" type="button is-success" @click="updateTaskGroup">Update</b-button>
        </b-tab-item>
        <b-tab-item label="Task Orders" v-if="taskGroup.uuid && taskGroupTasks && taskGroupTasks.length > 0">
          <h6 class="is-italic"> You can grab task with &#x2630; to edit the order of tasks.</h6>
          <br>
          <Container
            @drop="onDrop"
            drag-handle-selector=".column-drag-handle"
          >
            <Draggable v-for="task in taskGroupTasks" :key="task.uuid">
              <b-field class="modifier has-text-centered" grouped>
                <h3 class="column-drag-handle is-size-9">
                  &#x2630;
                </h3>
                <b-field>
                  <h3 style="margin-left:10px;">
                    {{task.label}}
                  </h3>
                </b-field>
                <!-- <b-field>
                  <b-button size="is-small is-danger" @click="remove(task.uuid)">
                    Remove
                  </b-button>
                </b-field> -->
              </b-field>
            </Draggable>
          </Container>
          <hr/>
          <b-button expanded type="button is-success" @click="updateTaskGroupOrder">Update</b-button>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd';
import { mapActions, mapMutations, mapState } from 'vuex';
import { taskGroupsTypes } from '@/store/mutation-types';

  export default {
  components: { Container, Draggable },
  data() {
    return {
      activeTab: 0,
      conditionTypes: ['Platform'],
      label: null,
      requiredValues: [true, false],
      taskGroupSorted: null,
    }
  },
  props: ['platforms', 'taskGroup', 'tasks', 'taskGroups'],
  computed: {
    ...mapState({
        current: (state) => state.taskGroups.currentTaskGroupTasks,
    }),
    taskGroupTasks: {
      get() {
        if (this.current) {
          return this.current
        }
        var tasks = [];
        for (let taskUUID of this.taskGroup.task_uuids) {
          var task = this.tasks.find(task => task.uuid == taskUUID);
          tasks.push(task)
        }
        return tasks;
      },
      set(value) {
        this.setCurrent(
          value
        )
      },
    },
    saveOrUpdateDisabled() {
      if (
        !this.taskGroup.label
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions('taskGroups', ['updateOne']),
    ...mapMutations('taskGroups', { setCurrent: taskGroupsTypes.SET_CURRENT_TASKS }),
    onDrop(dropResult) {
      this.taskGroupTasks = this.applyDrag(this.taskGroupTasks, dropResult);
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      const result = [...arr];
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },
    updateTaskGroupOrder() {
      this.updateOne(
        {
          task_uuids: this.current.map((task) => task.uuid),
          uuid: this.taskGroup.uuid
        }
      ).then(() => this.$emit('close'))
    },
    getConditionValues(conditionType) {
      if (conditionType == 'Platform') {
        return this.platforms.map((platform) => ({'label': platform.label, 'uuid': platform.uuid}))
      }
      return []
    },
    createTaskGroup() {
      this.$emit('createTaskGroup', this.taskGroup);
      this.$parent.close();
    },
    updateTaskGroup() {
      this.$emit('updateTaskGroup', this.taskGroup);
      this.$parent.close();
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>

<style lang="scss" scoped>
.modifier {
  &-fields {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    min-width: 0%;

    &-label {
      flex: 0.7 1 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-price {
      flex: 0.3 1 0;
    }
  }
}
</style>
