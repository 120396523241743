<template>
  <div>
    <b-modal :active.sync="openModal"
             has-modal-card
             :on-cancel="close">
      <section>
          <div class="card" style="width: auto">
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                    <figure class="image is-48x48">
                        <img onerror="this.style.display='none'" :src="concept.logo" alt="Image">
                    </figure>
                </div>
                <div class="media-content">
                  <p v-if="concept.label" p class="title is-4">{{concept.label}}</p>
                </div>
              </div>
            </div>
              <section class="card-content">
                <b-field label="Picture Type">
                  <b-select
                      type="text"
                      v-model="picture.picture_type"
                      placeholder="Picture Type" expanded>
                      <option v-for="(picture_type, index) in picture_types" :key="index" :value="picture_type">
                        {{picture_type}}
                      </option>
                  </b-select>
                </b-field>
                <b-field label="Picture Platform"
                 v-if="picture.picture_type=='platform'">
                  <b-select
                      type="text"
                      v-model="picture.platform_uuid"
                      placeholder="Picture Platform" expanded>
                      <option v-for="(platform, index) in platforms" :key="index" :value="platform.uuid">
                        {{platform.id}}
                      </option>
                  </b-select>
                </b-field>
                <b-field class="file">
                    <b-upload v-model="picture.picture">
                        <a class="button is-info">
                            <b-icon icon="upload"></b-icon>
                            <span>Upload</span>
                        </a>
                    </b-upload>
                    <span class="file-name" v-if="logo_file">
                        {{ picture.picture.name }}
                    </span>
                </b-field>
              </section>
              <footer class="card-foot buttons is-right">
                  <b-button type="button is-small is-outlined" :disabled="createOrUpdateDisabled" @click="update">{{ $t('concepts.update') }}</b-button>
                  <b-button type="button is-danger is-small is-outlined" @click="close">{{ $t('concepts.close') }}</b-button>
              </footer>
          </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ['concept', 'picture', 'platforms'],
  data() {
    return {
      openModal: true,
      logo_file: null,
      picture_types: ["platform", "logo"]
    }
  },
  computed: {
    createOrUpdateDisabled: function(){
      if(! this.picture.picture || ! this.picture.picture_type) {
        return true
      }
      return false
    }
  },
  methods: {
    update() {
      this.$emit('updated',
        this.concept.uuid,
        this.picture,
      );
      this.$parent.close();
    },
    close() {
      this.$emit('updateclosed', {});
      this.$parent.close();
    },
  }
}
</script>

<style>
 .field:not(:last-child){
   margin-bottom:0px;
 }
</style>
