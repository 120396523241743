<template>
  <section class="section">
    <b-loading :active.sync="isLoading"></b-loading>
    <div>
      <h1 class="title has-text-dark">
        <div class="columns is-mobile">
          <div class="column is-narrow">
            Marketing
          </div>
          <div class="column">
            <button class="add-button button is-link" @click="openActionsModal()">
              Actions
            </button>
            <!-- <button class="add-button button is-success" style="margin-left: 10px;" @click="openCalendarModal()">
              Calendar
            </button> -->
          </div>
          <div>
          </div>
        </div>
      </h1>
      <section>
        <CampaignFilters
          :activeConcepts="availableConceptsFilters"
          :activeCountries="availableCountriesFilters"
          :activeLocations="availableLocationsFilters"
          :activePlatforms="availablePlatformsFilters"
          :campaignTimelines="campaignTimelines"
          :campaignTypes="availableCampaignTypesFilters"
          :selectedConcept="selectedConcept"
          :selectedCountry="selectedCountry"
          :selectedDates="selectedDates"
          :selectedLocation="selectedLocation"
          :selectedPlatform="selectedPlatform"
          :selectedCampaignTimelines="selectedCampaignTimelines"
          :selectedCampaignType="selectedCampaignType"
          :displayLocationFilter="displayLocationFilter"
          @select-concept="selectConcept"
          @select-country="selectCountry"
          @select-dates="selectDates"
          @select-location="selectLocation"
          @select-platform="selectPlatform"
          @select-campaign-timelines="selectCampaignTimelines"
          @select-campaign-type="selectCampaignType"
          @refresh="refresh"
        />
        <hr/>
        <section v-if="(campaigns || kitchenCampaigns) && locations && locations.length > 0">
          <b-tabs type="is-toggle" expanded v-model="activeTab">
              <b-tab-item label="Campaigns" icon="view-list">
                <CampaignTable
                  :concepts="activeConceptsSorted"
                  :countries="activeCountriesSorted"
                  :platforms="activePlatformsSorted"
                  :perPage="perPage"
                  :page="page"
                  @changePage="changePage"
                  @openNew="openCampaignModal"
                  v-if="isMounted && !isLoading"
                />
              </b-tab-item>
              <b-tab-item label="Kitchen Campaigns" icon="home">
                <section>
                  <b-tabs type="is-toggle" v-model="activeTabBaseState" expanded destroy-on-hide>
                    <b-tab-item :label="getKitchenCampaignTabLabel(baseState)" v-for="(baseState, index) in baseStates" :key="index">
                      <KitchenCampaignTable
                        :key="refreshKitchenCampaignTableIndex"
                        :baseState="baseState"
                        :concepts="activeConceptsSorted"
                        :countries="activeCountriesSorted"
                        :platforms="activePlatformsSorted"
                        :perPage="perPageKitchenCampaign"
                        :page="pageKitchenCampaign"
                        @changePage="changePageKitchenCampaign"
                        v-if="isMounted && !isLoading"
                      />
                    </b-tab-item>
                  </b-tabs>
                </section>
              </b-tab-item>
          </b-tabs>
          <hr />
        </section>
      </section>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions, mapMutations } from 'vuex';
import { commons, utils } from '@/mixins';
import { campaignsTypes } from '@/store/mutation-types';

import CampaignFilters from '@/components/Marketing/CampaignFilters.vue'
import CampaignTable from '@/components/Marketing/CampaignTable.vue';
import KitchenCampaignTable from '@/components/Marketing/KitchenCampaignTable.vue';
import ActionsModal from '@/components/Marketing/ActionsModal.vue';
import NewCampaign from '@/components/Marketing/NewCampaign.vue';
import Calendar from '@/components/Marketing/Calendar.vue';

export default {
  components: {
    CampaignTable,
    KitchenCampaignTable,
    CampaignFilters,
    Calendar,
  },
  mixins: [commons, utils],
  data() {
    return {
      activeTab: 0,
      activeTabBaseState: 0,
      page: 1,
      perPage: 20,
      pageKitchenCampaign: 1,
      perPageKitchenCampaign: 20000,
      isMounted: false,
      allConcepts: [{'label': 'All', 'uuid': null}],
      currentConcept: null,
      allPlatforms: [{'label': 'All', 'uuid': null}],
      currentPlatform: null,
      allCountries: [{'label': 'All', 'uuid': null}],
      currentCountry: null,
      currentLocation: null,
      allLocations: [{'label': 'All', 'uuid': null}],
      allCampaignTypes: ['All'],
      currentCampaignType: null,
      currentCampaignTimelines: ['Current', 'Upcoming'],
      selectedDates: [],
      baseStates: ['CREATED', 'OPT_IN', 'PLATFORM_SETUP_DONE'],
      refreshCampaignTableIndex: 1,
      refreshKitchenCampaignTableIndex: 1,
    };
  },
  computed: {
    ...mapState({
      activeConceptsSorted(state) {
        return state.concepts.all.sort((concept1, concept2) =>
          concept1.label.localeCompare(concept2.label),
        );
      },
      activeCountriesSorted: (state) => {
        return state.countries.all.sort((country1, country2) =>
          country1.label.localeCompare(country2.label),
        );
      },
      activeLocationsSorted(state) {
        return state.locations.all.filter(location => location.parent_uuid && !location.is_deleted).sort((location1, location2) =>
          location1.label.localeCompare(location2.label),
        );
      },
      activePlatformsSorted(state) {
        return state.platforms.all.sort((platform1, platform2) =>
          platform1.label.localeCompare(platform2.label),
        );
      },
      kitchens: (state) =>
          state.kitchens.all
            .filter((kitchen) => !kitchen.is_deleted)
            .sort((kitchen1, kitchen2) => kitchen1.id.localeCompare(kitchen2.id)),
      campaigns: (state) => state.campaigns.all,
      kitchenCampaigns: (state) => state.kitchenCampaigns.all,
      campaignItems: (state) => state.campaignItems.all,
      locations: (state) => state.locations.all,
      marketingEvents: (state) => state.events.all,
      isLoading: (state) => state.concepts.fetchAll.pending || state.countries.fetchAll.pending || state.kitchens.fetchAll.pending || state.locations.fetchAll.pending || state.platforms.fetchAll.pending || state.campaigns.fetchAll.pending || state.kitchenCampaigns.fetchAll.pending || state.kitchenCampaigns.createOne.pending || state.kitchenCampaigns.createMultiple.pending || state.events.fetchAll.pending || state.events.createOne.pending || state.campaigns.deleteOne.pending,
    }),
    availableConceptsFilters() {
      return this.allConcepts.concat(this.activeConceptsSorted.filter(({is_internal}) => is_internal));
    },
    campaignTimelines() {
      var timelines = this.$store.getters['campaigns/getCampaignTimelines'];
      return timelines
    },
    campaignTypes() {
      var timelines = this.$store.getters['campaigns/getCampaignTypes'];
      return timelines
    },
    selectedConcept: {
      get() {
        if (this.currentConcept) {
          return {...this.currentConcept};
        }
        return this.availableConceptsFilters[0];
      },
      set(value) {
        this.currentConcept = value;
      }
    },
    availableCountriesFilters() {
      var activeCountryLocations = this.locations.filter((location) => !location.parent_uuid).map(({country_uuid}) => country_uuid);
      return this.allCountries.concat(this.activeCountriesSorted.filter(({uuid}) => activeCountryLocations.includes(uuid)));
    },
    selectedCountry: {
      get() {
        if (this.currentCountry) {
          return {...this.currentCountry};
        }
        return this.availableCountriesFilters[0];
      },
      set(value) {
        this.currentCountry = value;
      }
    },
    availableLocationsFilters() {
      return this.allLocations.concat(this.activeLocationsSorted);
    },
    selectedLocation: {
      get() {
        if (this.currentLocation) {
          return {...this.currentLocation};
        }
        return this.availableLocationsFilters[0];
      },
      set(value) {
        this.currentLocation = value;
      }
    },
    availablePlatformsFilters() {
      return this.allPlatforms.concat(this.activePlatformsSorted);
    },
    selectedPlatform: {
      get() {
        if (this.currentPlatform) {
          return {...this.currentPlatform};
        }
        return this.availablePlatformsFilters[0];
      },
      set(value) {
        this.currentPlatform = value;
      }
    },
    selectedCampaignTimelines: {
      get() {
        if (this.currentCampaignTimelines) {
          return [...this.currentCampaignTimelines];
        }
        return [this.campaignTimelines[0]];
      },
      set(value) {
        this.currentCampaignTimelines = [...value];
      }
    },
    availableCampaignTypesFilters() {
      return this.allCampaignTypes.concat(this.campaignTypes);
    },
    selectedCampaignType: {
      get() {
        if (this.currentCampaignType) {
          return this.currentCampaignType;
        }
        return this.availableCampaignTypesFilters[0];
      },
      set(value) {
        this.currentCampaignType = value;
      }
    },
    displayLocationFilter() {
      return this.activeTab == 1;
    }
  },
  methods: {
    ...mapActions('campaigns', { fetchCampaigns: 'fetchAll' }),
    ...mapActions('events', { fetchEvents: 'fetchAll' }),
    ...mapActions('kitchenCampaigns', { fetchKitchenCampaigns: 'fetchAll' }),
    ...mapActions('campaignItems', {fetchCampaignItems: 'fetchAll'}),
    ...mapMutations('campaigns', { resetCampaign: campaignsTypes.RESET_ONE}),
    openActionsModal: function() {
      this.resetCampaign();
      this.$buefy.modal.open({
        component: ActionsModal,
        canCancel	: true,
        parent: this,
        hasModalCard: true,
        fullScreen: true,
        events: {
          close :
          () => {}
        },
        props: {
          concepts: this.activeConceptsSorted,
          countries: this.activeCountriesSorted,
          locations: this.locations,
          platforms: this.activePlatformsSorted,
          events: this.marketingEvents,
          isAdhocCampaign: false,
        },
        customClass: 'my-modal',
      });
    },
    openCampaignModal: function(modalStep, isAdhocCampaign) {
      this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false')
          .then(
            ()=> this.$buefy.modal.open({
            component: NewCampaign,
            parent: this,
            hasModalCard: true,
            fullScreen: true,
            events: {
              close :() => {},
              reloadTables: () =>{
                this.refreshCampaignTableIndex += 1;
                this.refreshKitchenCampaignTableIndex += 1;
              }
            },
            props: {
              concepts: this.activeConceptsSorted,
              countries: this.activeCountriesSorted,
              kitchens: this.kitchens,
              platforms: this.activePlatformsSorted,
              locations: this.locations,
              isAdhocCampaign: isAdhocCampaign,
              events: this.marketingEvents,
              activeStep: modalStep,
            },
            customClass: 'my-modal',
          })
        );
    },
    openCalendarModal: function() {
      this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false')
          .then(
            ()=> this.$buefy.modal.open({
            component: Calendar,
            parent: this,
            hasModalCard: true,
            fullScreen: true,
            events: {
              close :
              () => {}
            },
            props: {
              concepts: this.activeConceptsSorted,
              countries: this.activeCountriesSorted,
              kitchens: this.kitchens,
              platforms: this.activePlatformsSorted,
              locations: this.locations,
              events: this.marketingEvents,
              activeStep: 0,
            },
            customClass: 'my-modal',
          })
        );
    },
    changePage(page) {
      this.page = page;
      this.refresh();
    },
    changePageKitchenCampaign(page) {
      this.pageKitchenCampaign = page;
      this.refresh();
    },
    selectConcept(concept) {
      this.selectedConcept = concept;
      this.page = 1;
    },
    selectDates(dates) {
      this.selectedDates = dates;
      this.page = 1;
    },
    selectLocation(location) {
      this.selectedLocation = location;
      this.page = 1;
    },
    selectPlatform(platform) {
      this.selectedPlatform = platform;
      this.page = 1;
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.page = 1;
    },
    selectCampaignTimelines(campaignTimelines) {
      this.selectedCampaignTimelines = campaignTimelines;
      this.page = 1;
    },
    selectCampaignType(campaignType) {
      this.selectedCampaignType = campaignType;
      this.page = 1;
    },
    refresh() {
      Promise.all([
        this.fetchCampaigns(
          {
            page: this.page,
            perPage: this.perPage,
            conceptUUID: this.selectedConcept.uuid,
            countryLocationUUID: this.getCountryLocationUUID(this.selectedCountry.uuid),
            platformUUID: this.selectedPlatform.uuid,
            campaignType: this.getCampaignType(this.selectedCampaignType),
            ...this.getDates(this.selectedCampaignTimelines, this.selectedDates)
          }
        ),
        this.fetchKitchenCampaigns(
          {
            page: this.pageKitchenCampaign,
            perPage: this.perPageKitchenCampaign,
            conceptUUID: this.selectedConcept.uuid,
            countryLocationUUID: this.getCountryLocationUUID(this.selectedCountry.uuid),
            platformUUID: this.selectedPlatform.uuid,
            locationUUID: this.selectedLocation.uuid,
            ...this.getDates(this.selectedCampaignTimelines, this.selectedDates)
          }
        ),
      ]).then(() => this.refreshKitchenCampaignTableIndex += 1);
    },
    getCampaignType(campaignType) {
      if (campaignType == 'All') {
        return null;
      }
      return campaignType;
    },
    getDates(campaignTimelines, selectedDates) {
      var todayDate = new Date();
      var yesterdayDate = this.getDateWithOffset(new Date(), -1);
      var tomorrowDate = this.getDateWithOffset(new Date(), 1);
      var campaignTimelinesSet =  new Set(campaignTimelines);
      if (selectedDates.length == 2) {
        return {
          startDateAfter: this.formatDate(selectedDates[0]),
          startDateBefore: null,
          endDateAfter: null,
          endDateBefore: this.formatDate(this.getDateWithOffset(selectedDates[1], 1)),
        }
      }
      if (this.setsAreEqual(campaignTimelinesSet, new Set(['Current']))) {
        return {
          startDateAfter: null,
          startDateBefore: this.formatDate(todayDate),
          endDateAfter: this.formatDate(yesterdayDate),
          endDateBefore: null,
        };
      }
      else if (this.setsAreEqual(campaignTimelinesSet, new Set(['Upcoming']))) {
        return {
          startDateAfter: this.formatDate(todayDate),
          startDateBefore: null,
          endDateAfter: null,
          endDateBefore: null,
        };
      }
      else if (this.setsAreEqual(campaignTimelinesSet, new Set(['Past']))) {
        return {
          startDateAfter: null,
          startDateBefore: null,
          endDateAfter: null,
          endDateBefore: this.formatDate(todayDate),
        };
      }
      else if (this.setsAreEqual(campaignTimelinesSet, new Set(['Current', 'Upcoming']))) {
        return {
          startDateAfter: null,
          startDateBefore: null,
          endDateAfter: this.formatDate(yesterdayDate),
          endDateBefore: null,
        };
      }
      else if (this.setsAreEqual(campaignTimelinesSet, new Set(['Current', 'Past']))) {
        return {
          startDateAfter: null,
          startDateBefore: null,
          endDateAfter: null,
          endDateBefore: this.formatDate(tomorrowDate),
        };
      }
      return {
        startDateAfter: null,
        startDateBefore: null,
        endDateAfter: null,
        endDateBefore: null,
      };
    },
    getKitchenCampaignTabLabel(baseState) {
      if (baseState == 'CREATED') {
        return 'HQ Validation'
      }
      else if (baseState == 'OPT_IN') {
        return 'Partner Validation'
      }
      return 'Platform Validation'
    },
    getKitchenCampaignTabIcon(baseState) {
      if (baseState == 'CREATED') {
        return 'office-building'
      }
      else if (baseState == 'OPT_IN') {
        return 'food'
      }
      return 'motorbike'
    },
    close() {
      this.$parent.close();
    },
  },
  mounted() {
    var yesterdayDate = this.getDateWithOffset(new Date(), -1);
    Promise.all(
      [
        this.fetchEvents(),
        this.fetchCampaigns({page: this.page, perPage: this.perPage, endDateAfter: this.formatDate(yesterdayDate)}),
        this.fetchKitchenCampaigns({page: this.pageKitchenCampaign, perPage: this.perPageKitchenCampaign, endDateAfter: this.formatDate(yesterdayDate)}),
        this.fetchCampaignItems({page: this.pageKitchenCampaign, perPage: this.perPageKitchenCampaign}),
        this.getConcepts(''),
        this.getCountries(''),
        this.getPlatforms(''),
        this.getLocations(''),
      ]
    ).then(() => this.isMounted = true)
  },
  watch: {},
};
</script>

<style>
.add-button {
  margin-top: 8px;
  margin-right: 8px;
}
</style>
