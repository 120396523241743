<template>
  <div class="has-text-centered" v-if="kitchen && newRestaurants">
    <b-field label="Restaurant Name (Convention : Out Fry - Korean Chicken by Taster - Neighbour)" message="Same as the one communicated to platforms" expanded>
      <b-input v-model="label" placeholder="Restaurant Name"></b-input>
    </b-field>
    <b-field label="Launch Date">
      <b-datepicker
        v-model="kitchenLaunchDate"
        placeholder="Click to select..."
        icon="calendar-today"
        position="is-bottom-right"
        trap-focus>
      </b-datepicker>
    </b-field>
    <b-button expanded :disabled="label == null || kitchenLaunchDate == null" type="button is-success" @click="createRestaurant">Create</b-button>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      label: null,
      kitchenLaunchDate: null,
    }
  },
  props: ['activeStep', 'kitchen', 'newRestaurants'],
  computed: {},
  methods: {
    createRestaurant() {
      var launchdateFormatted = new Date(this.kitchenLaunchDate.getTime() - this.kitchenLaunchDate.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0];
      this.$emit('createRestaurants', {
        'launch_date': launchdateFormatted,
        'label': this.label,
      });
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
