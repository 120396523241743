var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box modal-card", staticStyle: { overflow: "visible" } },
    [
      _c("p", { staticClass: "title is-4" }, [_vm._v(" New Task ")]),
      _c(
        "div",
        { staticClass: "has-text-centered" },
        [
          _c(
            "b-button",
            {
              staticClass: "button-gap",
              attrs: { "icon-left": "plus", type: "is-link", expanded: "" },
              on: {
                click: function ($event) {
                  return _vm.createTaskGroup()
                },
              },
            },
            [_vm._v(" Create Task Group ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "has-text-centered" },
        [
          _c(
            "b-button",
            {
              staticClass: "button-gap",
              attrs: { "icon-left": "plus", type: "is-info", expanded: "" },
              on: {
                click: function ($event) {
                  return _vm.createTask()
                },
              },
            },
            [_vm._v(" Create Task ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }