<template>
  <b-modal :active.sync="openModal" scroll="keep" full-screen :on-cancel="close">
    <section>
      <div class="card" style="width: auto">
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-4"> Actions</p>
            </div>
          </div>
        </div>
        <section class="card-content">
          <div class="has-text-centered">
            <b-button type="is-success" class="button-gap" @click="openOnboardingNewModal()" expanded :disabled=openingsButtonDisabled>
              Opening
            </b-button>
          </div>
          <div class="has-text-centered">
            <b-button type="is-danger" class="button-gap" @click="openCloseRestaurantModal()" expanded :disabled=closuresButtonDisabled>
              Closure
            </b-button>
          </div>
        </section>
      </div>
    </section>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';

import roles from '@/router/roles';

import OpenRestaurantModal from '@/components/Onboarding/Launch/OpenRestaurantModal.vue';
import CloseRestaurantModal from '@/components/Onboarding/Launch/CloseRestaurantModal.vue';

export default {
  components: {},
  props: ['countries', 'concepts', 'kitchens', 'locations', 'platforms'],
  data(){
    return {
      openModal: true,
    }
  },
  computed: mapState({
    openingsButtonDisabled() {
      return !this.$auth.getUserRoles().includes(roles.kitchenLifecycleManager);
    },
    closuresButtonDisabled() {
      return !this.$auth.getUserRoles().includes(roles.kitchenOffboarder);
    },
  }),
  methods: {
    openOnboardingNewModal: function() {
      this.$buefy.modal.open({
        component: OpenRestaurantModal,
        parent: this,
        hasModalCard: true,
        fullScreen: true,
        events: {},
        props: {
          concepts: this.concepts,
          countries: this.countries,
          platforms: this.platforms,
          kitchens: this.kitchens,
          locations: this.locations,
        },
        customClass: 'my-modal',
      });
    },
    openCloseRestaurantModal: function() {
      this.$buefy.modal.open({
        component: CloseRestaurantModal,
        parent: this,
        fullScreen: true,
        hasModalCard: true,
        events: {},
        props: {
          concepts: this.concepts,
          countries: this.countries,
          platforms: this.platforms,
          kitchens: this.kitchens,
          locations: this.locations,
        },
        customClass: 'my-modal',
      });
    },
    close() {
      this.$emit('closed')
    }
  },
  mounted() {}
};
</script>

<style>
.my-modal .modal-close {
  background: red;
}
</style>
