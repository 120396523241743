var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      this.taskType != "All Tasks"
        ? _c(
            "div",
            { staticClass: "has-text-centered" },
            _vm._l(_vm.tasks, function (task, index) {
              return _c(
                "b-button",
                {
                  key: index,
                  staticStyle: {
                    "margin-left": "10px",
                    "margin-bottom": "10px",
                  },
                  attrs: { outlined: _vm.taskOutline(task), type: "is-link" },
                  on: {
                    click: function ($event) {
                      return _vm.selectTask(task)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(task.id) + " ")]
              )
            }),
            1
          )
        : _vm._e(),
      this.taskType == "All Tasks"
        ? _c(
            "div",
            { staticClass: "has-text-centered" },
            [
              _c("div", { staticClass: "columns is-mobile" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c(
                      "b-dropdown",
                      {
                        attrs: { multiple: "", "aria-role": "list" },
                        model: {
                          value: _vm.taskStatusSelected,
                          callback: function ($$v) {
                            _vm.taskStatusSelected = $$v
                          },
                          expression: "taskStatusSelected",
                        },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "button is-warning is-small",
                            attrs: { slot: "trigger", type: "button" },
                            slot: "trigger",
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " Task Status (" +
                                  _vm._s(_vm.taskStatusSelected.length) +
                                  ")"
                              ),
                            ]),
                            _c("b-icon", { attrs: { icon: "menu-down" } }),
                          ],
                          1
                        ),
                        _vm._l(_vm.taskStatus, function (taskStatus, index) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: index,
                              attrs: {
                                value: taskStatus,
                                "aria-role": "listitem",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(" " + _vm._s(taskStatus) + " "),
                              ]),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                this.taskType != "By Teams"
                  ? _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: { multiple: "", "aria-role": "list" },
                            model: {
                              value: _vm.ownerSelected,
                              callback: function ($$v) {
                                _vm.ownerSelected = $$v
                              },
                              expression: "ownerSelected",
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "button is-warning is-small",
                                attrs: { slot: "trigger", type: "button" },
                                slot: "trigger",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " Owner (" +
                                      _vm._s(_vm.ownerSelected.length) +
                                      ")"
                                  ),
                                ]),
                                _c("b-icon", { attrs: { icon: "menu-down" } }),
                              ],
                              1
                            ),
                            _vm._l(_vm.owner, function (owner, index) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: index,
                                  attrs: {
                                    value: owner,
                                    "aria-role": "listitem",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(" " + _vm._s(owner) + " "),
                                  ]),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                this.taskType != "By Task Groups"
                  ? _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: { multiple: "", "aria-role": "list" },
                            model: {
                              value: _vm.taskGroupSelected,
                              callback: function ($$v) {
                                _vm.taskGroupSelected = $$v
                              },
                              expression: "taskGroupSelected",
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "button is-warning is-small",
                                attrs: { slot: "trigger", type: "button" },
                                slot: "trigger",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " Theme (" +
                                      _vm._s(_vm.taskGroupSelected.length) +
                                      ")"
                                  ),
                                ]),
                                _c("b-icon", { attrs: { icon: "menu-down" } }),
                              ],
                              1
                            ),
                            _vm._l(_vm.taskGroup, function (taskGroup, index) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: index,
                                  attrs: {
                                    value: taskGroup,
                                    "aria-role": "listitem",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(" " + _vm._s(taskGroup) + " "),
                                  ]),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "column is-10" }),
              ]),
              _c("b-table", {
                attrs: {
                  data: _vm.getTasksToDisplay(_vm.tasks[0].tasks),
                  striped: true,
                  hoverable: true,
                  scrollable: true,
                  "per-page": 10,
                  paginated: true,
                  "default-sort": ["label"],
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "b-table-column",
                            {
                              attrs: {
                                field: "label",
                                label: "Label",
                                searchable: "",
                                sortable: "",
                              },
                            },
                            [_vm._v(" " + _vm._s(props.row.label) + " ")]
                          ),
                          _vm.taskType != "By Task Groups"
                            ? _c(
                                "b-table-column",
                                {
                                  attrs: {
                                    field: "task_group_label",
                                    label: "TaskGroup",
                                    sortable: "",
                                  },
                                },
                                [
                                  props.row.task_group_label
                                    ? _c(
                                        "b-tag",
                                        { attrs: { type: "is-info" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.row.task_group_label
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.taskType != "By Teams"
                            ? _c(
                                "b-table-column",
                                { attrs: { field: "owner", label: "Owner" } },
                                [
                                  _c(
                                    "b-tag",
                                    { attrs: { type: "is-primary" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(props.row.owner) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-table-column",
                            { attrs: { field: "status", label: "Status" } },
                            [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    type: _vm.getTaskStatusIconType(props.row),
                                    label: _vm.getTaskStatusMessage(props.row),
                                    multilined: "",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: _vm.getTaskStatusIcon(props.row),
                                      type: _vm.getTaskStatusIconType(
                                        props.row
                                      ),
                                      size: "is-medium",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-table-column",
                            { attrs: { field: "hint", label: "Explanation" } },
                            [
                              props.row.description
                                ? _c("b-button", {
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: { "icon-left": "library-books" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openTaskDetail(props.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-table-column",
                            { attrs: { field: "hint", label: "Action" } },
                            [
                              !props.row.is_manual
                                ? _c("b-button", {
                                    attrs: {
                                      "icon-left": "wrench",
                                      disabled: _vm.getActionDisableStatus(
                                        props.row
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openTask(props.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-table-column",
                            { attrs: { label: "Done" } },
                            [
                              _c("b-switch", {
                                attrs: {
                                  "passive-type": "is-danger",
                                  type: "is-done",
                                  disabled:
                                    props.row.is_lock || !props.row.is_manual,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.updateTaskStatus(
                                      props.row,
                                      props.row.is_done
                                    )
                                  },
                                },
                                model: {
                                  value: props.row.is_done,
                                  callback: function ($$v) {
                                    _vm.$set(props.row, "is_done", $$v)
                                  },
                                  expression: "props.row.is_done",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2194077864
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedTask && this.taskType != "All Tasks"
        ? _c(
            "div",
            { staticClass: "has-text-centered" },
            [
              _c("div", { staticClass: "columns is-mobile" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c(
                      "b-dropdown",
                      {
                        attrs: { multiple: "", "aria-role": "list" },
                        model: {
                          value: _vm.taskStatusSelected,
                          callback: function ($$v) {
                            _vm.taskStatusSelected = $$v
                          },
                          expression: "taskStatusSelected",
                        },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "button is-warning is-small",
                            attrs: { slot: "trigger", type: "button" },
                            slot: "trigger",
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " Task Status (" +
                                  _vm._s(_vm.taskStatusSelected.length) +
                                  ")"
                              ),
                            ]),
                            _c("b-icon", { attrs: { icon: "menu-down" } }),
                          ],
                          1
                        ),
                        _vm._l(_vm.taskStatus, function (taskStatus, index) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: index,
                              attrs: {
                                value: taskStatus,
                                "aria-role": "listitem",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(" " + _vm._s(taskStatus) + " "),
                              ]),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                this.taskType != "By Teams"
                  ? _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: { multiple: "", "aria-role": "list" },
                            model: {
                              value: _vm.ownerSelected,
                              callback: function ($$v) {
                                _vm.ownerSelected = $$v
                              },
                              expression: "ownerSelected",
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "button is-warning is-small",
                                attrs: { slot: "trigger", type: "button" },
                                slot: "trigger",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " Owner (" +
                                      _vm._s(_vm.ownerSelected.length) +
                                      ")"
                                  ),
                                ]),
                                _c("b-icon", { attrs: { icon: "menu-down" } }),
                              ],
                              1
                            ),
                            _vm._l(_vm.owner, function (owner, index) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: index,
                                  attrs: {
                                    value: owner,
                                    "aria-role": "listitem",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(" " + _vm._s(owner) + " "),
                                  ]),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                this.taskType != "By Task Groups"
                  ? _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: { multiple: "", "aria-role": "list" },
                            model: {
                              value: _vm.taskGroupSelected,
                              callback: function ($$v) {
                                _vm.taskGroupSelected = $$v
                              },
                              expression: "taskGroupSelected",
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "button is-warning is-small",
                                attrs: { slot: "trigger", type: "button" },
                                slot: "trigger",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " Theme (" +
                                      _vm._s(_vm.taskGroupSelected.length) +
                                      ")"
                                  ),
                                ]),
                                _c("b-icon", { attrs: { icon: "menu-down" } }),
                              ],
                              1
                            ),
                            _vm._l(_vm.taskGroup, function (taskGroup, index) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: index,
                                  attrs: {
                                    value: taskGroup,
                                    "aria-role": "listitem",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(" " + _vm._s(taskGroup) + " "),
                                  ]),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "column is-10" }),
              ]),
              _c("b-table", {
                attrs: {
                  data: _vm.getTasksToDisplay(_vm.selectedTask.tasks),
                  striped: true,
                  hoverable: true,
                  scrollable: true,
                  "per-page": 7,
                  paginated: true,
                  "default-sort": ["desc"],
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "b-table-column",
                            {
                              attrs: {
                                field: "label",
                                label: "Label",
                                searchable: "",
                              },
                            },
                            [_vm._v(" " + _vm._s(props.row.label) + " ")]
                          ),
                          _vm.taskType != "By Task Groups"
                            ? _c(
                                "b-table-column",
                                {
                                  attrs: {
                                    field: "task_group_label",
                                    label: "TaskGroup",
                                    sortable: "",
                                  },
                                },
                                [
                                  props.row.task_group_label
                                    ? _c(
                                        "b-tag",
                                        { attrs: { type: "is-info" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.row.task_group_label
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.taskType != "By Teams"
                            ? _c(
                                "b-table-column",
                                { attrs: { field: "owner", label: "Owner" } },
                                [
                                  _c(
                                    "b-tag",
                                    { attrs: { type: "is-primary" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(props.row.owner) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-table-column",
                            { attrs: { field: "status", label: "Status" } },
                            [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    type: _vm.getTaskStatusIconType(props.row),
                                    label: _vm.getTaskStatusMessage(props.row),
                                    multilined: "",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: _vm.getTaskStatusIcon(props.row),
                                      type: _vm.getTaskStatusIconType(
                                        props.row
                                      ),
                                      size: "is-medium",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-table-column",
                            { attrs: { field: "hint", label: "Explanation" } },
                            [
                              props.row.description
                                ? _c("b-button", {
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: { "icon-left": "library-books" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openTaskDetail(props.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-table-column",
                            { attrs: { field: "hint", label: "Action" } },
                            [
                              !props.row.is_manual
                                ? _c("b-button", {
                                    attrs: {
                                      "icon-left": "wrench",
                                      disabled: _vm.getActionDisableStatus(
                                        props.row
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openTask(props.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-table-column",
                            { attrs: { label: "Done" } },
                            [
                              _c("b-switch", {
                                attrs: {
                                  "passive-type": "is-danger",
                                  type: "is-done",
                                  disabled: _vm.isTaskDisabled(props.row),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.updateTaskStatus(
                                      props.row,
                                      props.row.is_done
                                    )
                                  },
                                },
                                model: {
                                  value: props.row.is_done,
                                  callback: function ($$v) {
                                    _vm.$set(props.row, "is_done", $$v)
                                  },
                                  expression: "props.row.is_done",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-table-column",
                            { attrs: { label: "Delete" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { size: "is-small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmTaskRemoveFromLaunch(
                                        props.row
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "delete", size: "is-small" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.canEditInformation()
                            ? _c(
                                "b-table-column",
                                { attrs: { label: "Show UUID" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { size: "is-small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showUUIDForm(props.row)
                                        },
                                      },
                                    },
                                    [_vm._v(" Show UUID ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1650978058
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }