import kitchensAPI from '../../../apis/kitchen/kitchens';
import { kitchensTypes, restaurantsTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  current: null,
  fetchOne: {
    pending: false,
    error: null,
  },
  fetchAll: {
    pending: false,
    error: null,
  },
  updateOne: {
    pending: false,
    error: null,
  },
  current_error: '',
  current_success: '',
  newKitchen: {
    address: '',
    address_information: {},
    communication_channels: {},
    contacts: [],
    country: null,
    city: null,
    company_name: '',
    campany_address: '',
    company_type: '',
    deputy_id: '',
    financial_informations: {},
    fixed_costs: 0,
    id: '',
    is_franchise: false,
    kitchen_information: {},
    launch_date: new Date(),
    mails: {},
    qr_codes: [],
    secrets: {},
    supply: {},
    status: {
      kitchenOpeningHoursCompleted: false,
      kitchenPlatformIDCompleted: false,
      kitchenPOSCompleted: false,
      kitchenOneTabletCompleted: false,
      kitchenLoyaltyCompleted: false,
    },
    taster_commission: 0,
  },
  newKitchenEmpty: {
    address: '',
    address_information: {},
    communication_channels: {},
    contacts: [],
    country: null,
    city: null,
    company_name: '',
    campany_address: '',
    company_type: '',
    deputy_id: '',
    financial_informations: {},
    fixed_costs: 0,
    id: '',
    is_franchise: false,
    kitchen_information: {},
    launch_date: new Date(),
    mails: {},
    qr_codes: [],
    secrets: {},
    supply: {},
    status: {
      kitchenOpeningHoursCompleted: false,
      kitchenPlatformIDCompleted: false,
      kitchenPOSCompleted: false,
      kitchenOneTabletCompleted: false,
      kitchenLoyaltyCompleted: false,
    },
    taster_commission: 0,
  },
  kitchenStatuses: [
    { label: 'Franchise', value: true },
    { label: 'Taster', value: false },
  ],
  selectedKitchenStatuses: ['Franchise', 'Taster'],
  kitchenInformationToExport: '',
  currentKitchensManagementInformation: null,
  kitchenCategories: [
    'Tier 1',
    'Tier 2',
    'Tier 3',
  ],
};

const getters = {
  getKitchen: (state) => (uuid) => {
    return state.all.find((kitchen) => kitchen.uuid === uuid);
  },
  getRestaurant: (state, getters) => (uuid, restaurantUUID) => {
    const selectedKitchen = getters.getKitchen(uuid);
    var restaurant = selectedKitchen.restaurants.find(
      (restaurant) => restaurant.uuid === restaurantUUID
    );
    if (restaurant) {
      var restaurantToUpdate = Object.assign({}, restaurant);
      delete restaurantToUpdate.concept_restaurant;
      delete restaurantToUpdate.kitchen_restaurant;
      delete restaurantToUpdate.platform_restaurant;
    }
    return restaurantToUpdate;
  },
  getRestaurants: (state, getters) => (uuid) => {
    const selectedKitchen = getters.getKitchen(uuid);
    if (selectedKitchen) {
      var currentRestaurants = [];
      for (var i = 0; i < selectedKitchen.restaurants.length; i++) {
        var restaurant = selectedKitchen.restaurants[i];
        if (!restaurant.is_deleted) {
          currentRestaurants.push(restaurant);
        }
      }
      return currentRestaurants;
    }
    return [];
  },
  getKitchenCategories: (state) => {
    return state.kitchenCategories;
  },
};

const actions = {
  async getKitchen({ commit }, kitchenUUID) {
    commit(kitchensTypes.FETCH_ONE_REQUEST);
    return kitchensAPI
      .getKitchen(kitchenUUID)
      .then(function(kitchen) {
        commit(kitchensTypes.FETCH_ONE_SUCCESS, kitchen);
      })
      .catch(function(error) {
        commit(kitchensTypes.FETCH_ONE_FAILURE, error.response.data.detail);
      });
  },
  async getKitchens({ commit }, queryParameters) {
    commit(kitchensTypes.FETCH_ALL_REQUEST);
    return kitchensAPI
      .getKitchens(queryParameters)
      .then(function(kitchens) {
        commit(kitchensTypes.FETCH_ALL_SUCCESS, kitchens);
      })
      .catch(function(error) {
        commit(kitchensTypes.FETCH_ALL_FAILURE, error.response.data.detail);
      });
  },
  async createKitchen({ commit }, payload) {
    return kitchensAPI
      .createKitchen(payload)
      .then(function(kitchen) {
        commit(kitchensTypes.CREATE_KITCHEN, kitchen);
      })
      .catch(function(error) {
        commit(kitchensTypes.SET_CURRENT_ERROR, error.response.data.detail);
      });
  },
  async updateKitchen({ commit, dispatch }, payload) {
    state.updateOne.pending = true;
    state.updateOne.error = null;
    return kitchensAPI
      .updateKitchen(payload)
      .then(function(kitchen) {
        commit(kitchensTypes.UPDATE_KITCHEN, kitchen);
        commit(kitchensTypes.SET_CURRENT_SUCCESS, 'Kitchen Updated');
      })
      .catch(function(error) {
        commit(kitchensTypes.SET_CURRENT_ERROR, error.response.data.detail);
        dispatch('getKitchens');
      });
  },
  async deleteKitchen({ commit, dispatch }, kitchenUUID) {
    return kitchensAPI
      .deleteKitchen(kitchenUUID)
      .then(function() {
        commit(kitchensTypes.DELETE_KITCHEN, kitchenUUID);
      })
      .catch(function(error) {
        commit(kitchensTypes.SET_CURRENT_ERROR, error.response.data.detail);
        dispatch('getKitchens');
      });
  },
  resetCurrentError({ commit }) {
    commit(kitchensTypes.SET_CURRENT_ERROR, '');
  },
  resetCurrentSuccess({ commit }) {
    commit(kitchensTypes.SET_CURRENT_SUCCESS, '');
  },
};

const mutations = {
  [kitchensTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [kitchensTypes.FETCH_ONE_SUCCESS](state, kitchen) {
    state.current = kitchen;
    const index = state.all.findIndex((item) => item.uuid === kitchen.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, kitchen);
    }
    else {
      state.all.push(kitchen);
    }
    state.fetchOne.pending = false;
    state.fetchOne.error = null;
  },
  [kitchensTypes.FETCH_ONE_FAILURE](state, error) {
    state.createdKitchenUUID = null;
    state.fetchOne.pending = false;
    state.fetchOne.error = error;
    state.current_error = error;
  },
  [kitchensTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [kitchensTypes.FETCH_ALL_SUCCESS](state, kitchens) {
    state.all = kitchens;
    state.fetchAll.pending = false;
  },
  [kitchensTypes.SET_KITCHENS](state, kitchens) {
    state.all = kitchens;
    state.fetchAll.pending = false;
  },
  [kitchensTypes.CREATE_KITCHEN](state, kitchen) {
    state.all.push(kitchen);
  },
  [kitchensTypes.ADD_KITCHEN](state, kitchen) {
    state.all.push(kitchen)
  },
  [kitchensTypes.UPDATE_KITCHEN](state, kitchen) {
    const index = state.all.findIndex((item) => item.uuid === kitchen.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, kitchen);
    }
    state.updateOne.pending = false;
    state.updateOne.error = null;
  },
  [kitchensTypes.DELETE_KITCHEN](state, kitchenUUID) {
    const index = state.all.findIndex((kitchen) => kitchen.uuid === kitchenUUID);
    if (index !== -1) {
      state.all.splice(index, 1);
    }
  },
  [kitchensTypes.SET_CURRENT_ERROR](state, error) {
    state.current_error = error;
  },
  [kitchensTypes.SET_CURRENT_SUCCESS](state, success) {
    state.current_success = success;
  },
  [restaurantsTypes.SET_RESTAURANTS](state, restaurants) {
    state.restaurants = restaurants;
  },
  [kitchensTypes.SET_EXPORT_KITCHEN_INFORMATION](state, payload) {
    state.kitchenInformationToExport = payload;
  },
  [kitchensTypes.SET_CURRENT_MANAGEMENT_INFORMATION](state, kitchensManagementInformation) {
    state.currentKitchensManagementInformation = {...kitchensManagementInformation};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
