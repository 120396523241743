var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.kitchenCampaignsInformations,
            striped: true,
            hoverable: true,
            paginated: "",
            "per-page": "10",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "locationLabel",
                        label: "Label",
                        width: _vm.columnWidth,
                      },
                    },
                    [_vm._v(" " + _vm._s(props.row.locationLabel) + " ")]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "startDate",
                        label: "Start Date",
                        width: _vm.columnWidth,
                      },
                    },
                    [
                      _c("b-datepicker", {
                        attrs: {
                          icon: "calendar-today",
                          position: "is-bottom-right",
                          locale: "fr-Fr",
                          range: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.updateKitchenCampaign(
                              props.row,
                              true,
                              "dates"
                            )
                          },
                        },
                        model: {
                          value: props.row.dates,
                          callback: function ($$v) {
                            _vm.$set(props.row, "dates", $$v)
                          },
                          expression: "props.row.dates",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "financingModel",
                        label: "Financing Model",
                        width: _vm.columnWidth,
                      },
                    },
                    [
                      _c(
                        "b-field",
                        {
                          staticStyle: { "justify-content": "center" },
                          attrs: { label: "", expanded: "" },
                        },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: {
                                type: "text",
                                placeholder: "Campaign Financing Model",
                                expanded: "",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.updateKitchenCampaign(
                                    props.row,
                                    true,
                                    "finance"
                                  )
                                },
                              },
                              model: {
                                value: props.row.financingModel,
                                callback: function ($$v) {
                                  _vm.$set(props.row, "financingModel", $$v)
                                },
                                expression: "props.row.financingModel",
                              },
                            },
                            _vm._l(
                              _vm.campaignFinancingModels,
                              function (campaignFinancingModel, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: { value: campaignFinancingModel },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(campaignFinancingModel) + " "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isEditingMode
                    ? _c(
                        "b-table-column",
                        {
                          attrs: {
                            field: "eligible",
                            label: "Eligible",
                            width: _vm.columnWidth,
                            sortable: "",
                          },
                        },
                        [
                          _c(
                            "b-field",
                            {
                              staticStyle: { "justify-content": "center" },
                              attrs: { label: "", expanded: "" },
                            },
                            [
                              _c(
                                "b-checkbox-button",
                                {
                                  attrs: {
                                    type: _vm.getStatusButtonType(props.row),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.updateKitchenCampaign(
                                        props.row,
                                        false,
                                        null
                                      )
                                    },
                                  },
                                  model: {
                                    value: props.row.isEligible,
                                    callback: function ($$v) {
                                      _vm.$set(props.row, "isEligible", $$v)
                                    },
                                    expression: "props.row.isEligible",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: _vm.getStatusButtonIcon(props.row),
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getStatusButtonValue(props.row)
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: { icon: "emoticon-sad", size: "is-large" },
                      }),
                    ],
                    1
                  ),
                  _c("p", [_vm._v("Nothing here.")]),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
      _c("hr"),
      !_vm.isEditingMode
        ? _c(
            "b-button",
            {
              attrs: { expanded: "", type: "button is-success" },
              on: { click: _vm.create },
            },
            [_vm._v("Create")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }