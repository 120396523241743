<template>
  <div class="has-text-centered">
    <b-loading :active.sync="isLoading"></b-loading>
      <b-field grouped v-if="users && users.length > 0">
        <b-field label="Sales Manager" expanded>
          <b-autocomplete
            rounded
            field='name'
            placeholder="Select Sales Manager"
            icon="magnify"
            clearable
            v-model="salesManagerLabel"
            :data="filteredUsersSales"
            :open-on-focus="true"
            :clear-on-select="true"
            @select="option => selectUser(option, 'SM')"
            >
              <template #empty>No results found</template>
          </b-autocomplete>
        </b-field>
        <b-field label="Area Manager" expanded>
          <b-autocomplete
            rounded
            field='name'
            placeholder="Select Area Manager"
            icon="magnify"
            clearable
            v-model="accountManagerLabel"
            :data="filteredUsersAccount"
            :open-on-focus="true"
            :clear-on-select="true"
            @select="option => selectUser(option, 'AM')"
            >
              <template #empty>No results found</template>
          </b-autocomplete>
        </b-field>
        <b-field label="Launcher" expanded>
          <b-autocomplete
            rounded
            field='name'
            placeholder="Select Launcher"
            icon="magnify"
            clearable
            v-model="operationManagerLabel"
            :data="filteredUsersOperation"
            :open-on-focus="true"
            :clear-on-select="true"
            @select="option => selectUser(option, 'OM')"
            >
              <template #empty>No results found</template>
          </b-autocomplete>
        </b-field>
      </b-field>
      <b-field label="Category (Optional)" style="justify-content: center;" expanded>
        <b-select
          type="text"
          v-model="management_information.category"
          placeholder="Category"
          expanded
          >
          <option v-for="(kitchenCategory, index) in kitchenCategories" :key="index" :value="kitchenCategory">
            {{kitchenCategory}}
          </option>
        </b-select>
      </b-field>
    <b-button expanded type="button is-success" :disabled="isDisabled" @click="createLaunchProcess">Create Launch Process</b-button>
  </div>
</template>

<script>

import { mapState } from 'vuex';

  export default {
    data() {
      return {
        management_information: {
          'account_manager_uuid': null,
          'operation_manager_uuid': null,
          'sales_manager_uuid': null,
          'category': null,
        },
        selectedTeams: {},
        currentSales: null,
        currentOperation: null,
        currentAccount: null,
      }
    },
    props: ['activeStep', 'kitchen', 'launchTeams', 'selectedConcept'],
    computed: {
      ...mapState({
        isLoading: (state) => state.actions.importIAMLocation.pending || state.launches.createLaunch.pending,
        launch : (state) => state.launches.current,
        users: (state) => state.accesses.users,
      }),
      kitchenCategories() {
        return this.$store.getters['kitchens/getKitchenCategories'];
      },
      usersSorted() {
        var usersSorted = [...this.users]
        for (let user of usersSorted) {
          user.name = `${user.first_name} ${user.last_name}`;
        }
        return usersSorted.sort((user1, user2) => user1.first_name.localeCompare(user2.first_name));
      },
      salesManagerLabel: {
        get() {
          if (this.management_information.sales_manager_uuid) {
            return this.getUser(this.management_information.sales_manager_uuid);
          }
          else {
            return ''
          }
        },
        set(value) {
          this.currentSales = value;
        }
      },
      operationManagerLabel: {
        get() {
          if (this.management_information.operation_manager_uuid) {
            return this.getUser(this.management_information.operation_manager_uuid);
          }
          else {
            return ''
          }
        },
        set(value) {
          this.currentOperation = value;
        }
      },
      accountManagerLabel: {
        get() {
          if (this.management_information.account_manager_uuid) {
            return this.getUser(this.management_information.account_manager_uuid);
          }
          else {
            return ''
          }
        },
        set(value) {
          this.currentAccount = value;
        }
      },
      filteredUsersSales() {
        if (this.usersSorted && this.usersSorted.length > 0) {
          if (this.currentSales) {
            return this.usersSorted.filter((user) => user.first_name.toLowerCase().includes(this.currentSales.toLowerCase()));
          }
          return this.usersSorted
        }
        return [];
      },
      filteredUsersOperation() {
        if (this.usersSorted && this.usersSorted.length > 0) {
          if (this.currentOperation) {
            return this.usersSorted.filter((user) => user.first_name.toLowerCase().includes(this.currentOperation.toLowerCase()));
          }
          return this.usersSorted
        }
        return [];
      },

      filteredUsersAccount() {
        if (this.usersSorted && this.usersSorted.length > 0) {
          if (this.currentAccount) {
            return this.usersSorted.filter((user) => user.first_name.toLowerCase().includes(this.currentAccount.toLowerCase()));
          }
          return this.usersSorted
        }
        return [];
      },
      isDisabled() {
        if (!this.management_information.account_manager_uuid || !this.management_information.sales_manager_uuid || !this.management_information.operation_manager_uuid) {
          return true;
        }
        return false;
      }
    },
    methods: {
      selectUser(user, type) {
        if (user) {
          if (type == 'OM') {
            this.management_information.operation_manager_uuid = user.uuid;
            this.operationManagerLabel = this.getUser(this.management_information.operation_manager_uuid);

          }
          else if (type == 'AM') {
            this.management_information.account_manager_uuid = user.uuid;
            this.accountManagerLabel = this.getUser(this.management_information.operation_manager_uuid);
          }
          else if (type == 'SM') {
            this.management_information.sales_manager_uuid = user.uuid;
            this.salesManagerLabel = this.getUser(this.management_information.operation_manager_uuid);
          }
        }
      },
      getUser(userUUID) {
        if (userUUID) {
          var user = this.users.find(user => user.uuid == userUUID);
          if (user) {
            return `${user.first_name} ${user.last_name}`;
          }
        }
        return '';
      },
      getUserUUID(userName) {
        if (userName) {
          var user = this.usersSorted.find(user => user.name == userName);
          if (user) {
            return user.uuid;
          }
        }
        return '';
      },
      addTeamMember(teamLabel, teamMember) {
        if (!Object.keys(this.selectedTeams).includes(teamLabel)){
          this.selectedTeams[teamLabel] = [];
        }
        if (this.selectedTeams[teamLabel].includes(teamMember)) {
          this.selectedTeams[teamLabel] = this.selectedTeams[teamLabel].filter(member => member !== teamMember);
        }
        else {
          this.selectedTeams[teamLabel].push(teamMember)
        }
      },
      createLaunchProcess() {
        var selectedTeamsList = [];
        for (const [key, value] of Object.entries(this.selectedTeams)) {
          selectedTeamsList.push({'label': key, 'members': value})
        }
        this.$emit('createLaunchProcess', this.getUser(this.management_information.account_manager_uuid), selectedTeamsList, this.management_information);
      },
    }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
