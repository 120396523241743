import axios from '@/axios';
import { baseOnboardingUrl } from '@/apis'


const ressourcePath = `${baseOnboardingUrl}/launches`;

export default {
/* eslint-disable */
  createLaunch(opts) {
    return axios.post(ressourcePath, opts)
                .then(response => response.data)
  },
  fetchAll(opts) {
    var path = ressourcePath
    if (opts.current != null || opts.summary != null) {
      path = `${path}?`
    }
    if (opts.current != null){
      path = `${path}current=${opts.current}&`
    }
    if (opts.summary != null){
      path = `${path}summary=${opts.summary}`
    }
    return axios.get(path)
                .then(response => response.data)
  },
  fetchOne(launchUUID) {
    return axios.get(`${ressourcePath}/${launchUUID}`)
                .then(response => response.data)
  },
  updateOne(opts) {
    var launchUUID = opts.launchUUID
    delete opts.launchUUID
    return axios.patch(`${ressourcePath}/${launchUUID}`, opts)
                .then(response => response.data)
  },
  updateTask(opts) {
    var launchUUID = opts.launchUUID
    delete opts.launchUUID
    var taskUUID = opts.taskUUID
    delete opts.taskUUID
    return axios.patch(`${ressourcePath}/${launchUUID}/tasks/${taskUUID}`, opts)
                .then(response => response.data)
  },
  removeTask(opts) {
    var launchUUID = opts.launchUUID
    var taskUUID = opts.taskUUID
    return axios.delete(`${ressourcePath}/${launchUUID}/tasks/${taskUUID}`)
                .then(response => response.data)
  },
  addTaskGroup(opts) {
    var launchUUID = opts.launchUUID
    delete opts.launchUUID
    var taskGroupUUID = opts.taskGroupUUID
    delete opts.taskGroupUUID
    return axios.post(`${ressourcePath}/${launchUUID}/task_groups/${taskGroupUUID}/tasks`)
                .then(response => response.data)
  },
  removeTaskGroup(opts) {
    var launchUUID = opts.launchUUID
    delete opts.launchUUID
    var taskGroupUUID = opts.taskGroupUUID
    delete opts.taskGroupUUID
    return axios.delete(`${ressourcePath}/${launchUUID}/task_groups/${taskGroupUUID}/tasks`)
                .then(response => response.data)
  },
};
