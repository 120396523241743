<template>
  <div class="has-text-centered" v-if="newUser">
    <b-field grouped>
      <b-field label="Organisation" expanded>
        <b-select
          v-model="isFranchise"
          placeholder="Taster/Franchise"
          expanded
        >
          <option v-for="(organisationType, index) in organisationTypes" :key="index" :value="organisationType.value">
            {{ organisationType.label }}
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field grouped>
      <b-field label="First Name" expanded>
        <b-input
          v-model="firstName"
          placeholder="First Name"
        ></b-input>
      </b-field>
      <b-field label="Last Name" expanded>
        <b-input
          v-model="lastName"
          placeholder="Last Name"
        ></b-input>
      </b-field>
    </b-field>
    <b-field grouped>
      <b-field label="Team" expanded>
        <b-select
          v-model="groupUUID"
          placeholder="Team"
          expanded
        >
          <option v-for="(group, index) in groupsSorted" :key="index" :value="group.uuid">
            {{ group.name }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Mail" expanded  v-if="isFranchise">
        <b-input type="email" v-model="mail"></b-input>
      </b-field>
      <b-field label="Google Groups" expanded class="input-text-center" v-else>
        <b-dropdown
            v-model="googleGroupIds"
            :scrollable="true"
            multiple
            aria-role="list"
            expanded>
            <template #trigger>
              <b-button
                type="is-grey"
                icon-right="menu-down"
                expanded>
                Selected ({{ googleGroupIds.length }})
              </b-button>
            </template>
            <b-dropdown-item
              v-for="(group, index) in googleGroupsSorted"
              :key="index"
              :value="group.id"
              aria-role="listitem">
                <span> {{ group.name }} </span>
            </b-dropdown-item>
        </b-dropdown>
      </b-field>
    </b-field>
    <b-button expanded :disabled="firstName == null || lastName == null || groupUUID == null || (isFranchise &&  mail == null)" type="button is-success" @click="createUser">Create</b-button>
  </div>
  <div class="has-text-centered" v-else>
    <h4 class="title has-text-centered is-4">Select User</h4>
      <b-field>
        <b-switch v-model="searchBy"
          true-value='Search By Name'
          false-value='Search By Mail'>
          {{ searchBy }}
        </b-switch>
      </b-field>
      <b-field>
        <b-autocomplete
          v-if="searchBy == 'Search By Name'"
          expanded
          rounded
          v-model="searchTerm"
          :data="filteredUsers"
          :clear-on-select="true"
          placeholder="Select User"
          icon="magnify"
          field="completedName"
          :append-to-body="true"
          :open-on-focus="true"
          @select="option => selectUser(option)"
        >
        </b-autocomplete>
        <b-autocomplete
          v-else
          expanded
          rounded
          v-model="searchTerm"
          :data="filteredUsersByMail"
          :clear-on-select="true"
          placeholder="Select User"
          icon="magnify"
          field="completedName"
          :append-to-body="true"
          :open-on-focus="true"
          @select="option => selectUser(option)"
        >
        </b-autocomplete>
      </b-field>
      <b-field label="" position="is-centered">
        <b-tag v-if="user" size="is-large" type="is-info">
          {{user.first_name}} {{user.last_name}}
        </b-tag>
      </b-field>
  </div>
</template>

<script>
  import { auth, utils } from '@/mixins';
  export default {
    mixins: [auth, utils],
    data() {
      return {
        user: null,
        searchTerm: '',
        isFranchise: null,
        firstName: null,
        lastName: null,
        groupUUID: null,
        googleGroupIds: [],
        mail: null,
        searchBy: 'Search By Name',
      }
    },
    props: ['activeStep', 'newUser', 'removeUserAction', 'users', 'groups', 'googleGroups', 'externalUserType'],
    computed: {
      googleGroupsSorted() {
        var googleGroupsSorted = [...this.googleGroups]
        return googleGroupsSorted.sort((group1, group2) => group1.name.localeCompare(group2.name));
      },
      groupsSorted() {
        var groupsSorted = [...this.groups]
        if (this.isFranchise) {
          groupsSorted = [...this.groups].filter(group => group.name.includes('Kitchen'));
        }
        return groupsSorted.sort((group1, group2) => group1.name.localeCompare(group2.name));
      },
      usersSorted() {
        var usersSorted = [...this.users]
        return usersSorted.sort((user1, user2) => user1.first_name.localeCompare(user2.first_name));
      },
      filteredUsers() {
        var filteredUsers = this.usersSorted.filter((user) => user.first_name.toLowerCase().includes(this.searchTerm.toLowerCase()) || user.last_name.toLowerCase().includes(this.searchTerm.toLowerCase()));
        for (let user of filteredUsers) {
          user.completedName = `${user.name} (${user.email})`
        }
        return filteredUsers;
      },
      filteredUsersByMail() {
        var filteredUsers = this.usersSorted.filter((user) => user.email.toLowerCase().includes(this.searchTerm.toLowerCase()));
        for (let user of filteredUsers) {
          user.completedName = `${user.name} (${user.email})`
        }
        return filteredUsers;
      },
      organisationTypes() {
        if (this.canDeleteAccess()) {
          return [
            { label: 'Franchise', value: true },
            { label: 'Taster', value: false },
          ]
        }
        else {
          return [
            { label: 'Franchise', value: true },
          ]
        }
      }
    },
    methods: {
      selectUser(option) {
        if (option != null) {
          if (!this.removeUserAction) {
            this.$emit('selectUser', option);
          }
          else {
            this.deleteUser(option);
          }
        }
      },
      createUser() {
        if (this.isFranchise) {
          var userGroup = this.groups.find(group => group.uuid == this.groupUUID);
          var userType = 'user';
          if (userGroup.name.includes('Manager')) {
            userType = 'manager';
          }
          this.$emit('update:externalUserType', userType);
        }
        this.$emit('createUser', {
          'first_name': this.firstName,
          'group_uuid': this.groupUUID,
          'google_group_ids': this.googleGroupIds || [],
          'is_franchise': this.isFranchise,
          'last_name': this.lastName,
          'mail': this.mail,
        });
      },
      deleteUser(user) {
        this.$buefy.dialog.confirm({
          title: `Delete ${user.first_name} ${user.last_name}`,
          message:
            `Are you sure you want to <b>delete</b> this team member? This action will be run directly (even with close date in the future) and cannot be undone.`,
          confirmText: `Delete`,
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.$emit('deleteUser', {'userUUID': user.uuid}),
        });
      },
    }
  }
</script>

<style scoped>

.dropdown-trigger.a.dropdown-item.is-active {
  background-color: #4BB543;
}
.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
