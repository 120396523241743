<template>
  <div>
    <div class="has-text-centered">
      <b-button type="is-success" class="button-gap" @click="selectOperationType('ADD')" expanded>
        Add Rights
      </b-button>
    </div>
    <div class="has-text-centered">
      <b-button type="is-danger" class="button-gap"  @click="selectOperationType('REMOVE')" expanded>
        Remove Rights
      </b-button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: ['activeStep', 'operation'],
    computed: {},
    methods: {
      selectOperationType(operation) {
        this.$emit('update:activeStep', 3);
        this.$emit('update:operationType', operation);
      },
      close() {
        this.$parent.close();
      },
    }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
