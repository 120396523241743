<template>
  <section class="section">
    <b-loading :active.sync="isLoading"></b-loading>
    <div class="container">
      <h1 class="title has-text-dark">
        <div class="columns is-mobile">
          <div class="column is-narrow">
            Lifecycle
          </div>
          <div class="column">
            <button class="add-button button is-link is-small" @click="openActionsModal()">
              Actions
            </button>
            <button style="margin-left: 10px;" class="add-button button is-info is-small" @click="openTasksManager()">
              Tasks Manager
            </button>
          </div>
          <div>
          </div>
        </div>
      </h1>
      <hr />
      <section>
        <section v-if="launches && locations && locations.length > 0 && concepts && concepts.length > 0 && countries && countries.length > 0">
          <b-dropdown v-model="selectedLaunchType" aria-role="list">
            <button class="button is-link" type="button" slot="trigger">
              <span>Launch Type ({{ selectedLaunchType }})</span>
              <b-icon icon="menu-down"></b-icon>
            </button>
            <b-dropdown-item
              v-for="(launchType, index) in launchTypes"
              :key="index"
              :value="launchType"
              aria-role="listitem"
            >
              <span> {{ launchType }} </span>
            </b-dropdown-item>
          </b-dropdown>
          <OnboardingTable :selectedLaunchType="selectedLaunchType" :isMounted="isMounted" />
          <hr />
        </section>
      </section>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from 'vuex';
import { utils } from '@/mixins';

import OnboardingTable from '@/components/Onboarding/OnboardingTable.vue';
import TasksManagerModal from '@/components/Onboarding/TasksManager/TasksManagerModal.vue';
import ActionsModal from '@/components/Onboarding/ActionsModal.vue';

export default {
  mixins: [utils],
  data() {
    return {
      isMounted: false,
      launchTypes: ['All', 'Past', 'Upcoming'],
      selectedLaunchType: 'Upcoming',
    };
  },
  computed: {
    ...mapState({
      activeConceptsSorted(state) {
        return state.concepts.all.sort((concept1, concept2) =>
          concept1.label.localeCompare(concept2.label),
        );
      },
      activePlatformsSorted(state) {
        return state.platforms.all.sort((platform1, platform2) =>
          platform1.label.localeCompare(platform2.label),
        );
      },
      concepts: (state) => state.concepts.all,
      countries: (state) => state.countries.all,
      launches: (state) => state.launches.all,
      locations: (state) => state.locations.all,
      isLoadingState: (state) => state.concepts.fetchAll.pending || state.countries.fetchAll.pending || state.locations.fetchAll.pending || state.platforms.fetchAll.pending || state.launches.fetchAll.pending,
    }),
    isLoading() {
      return this.isLoadingState|| !this.isMounted;
    }
  },
  methods: {
    ...mapActions('launches', { fetchLaunches: 'fetchAll' }),
    ...mapActions('taskGroups', { fetchTaskGroups: 'fetchAll' }),
    ...mapActions('tasks', { fetchTasks: 'fetchAll' }),
    openActionsModal: function() {
      this.$buefy.modal.open({
        component: ActionsModal,
        parent: this,
        hasModalCard: true,
        fullScreen: true,
        events: {},
        props: {
          concepts: this.activeConceptsSorted,
          countries: this.countries,
          platforms: this.activePlatformsSorted,
          locations: this.locations,
        },
        customClass: 'my-modal',
      });
    },
    openTasksManager: function() {
      this.$buefy.modal.open({
        component: TasksManagerModal,
        parent: this,
        fullScreen: true,
        hasModalCard: true,
        events: {},
        props: {},
        customClass: 'my-modal',
      });
    },
  },
  mounted() {
    Promise.all(
      [
        this.fetchLaunches({'current': true, 'summary': true}),
        this.fetchTaskGroups('?is_deleted=false'),
        this.fetchTasks('?is_deleted=false'),
        this.getConcepts('?is_deleted=false', true),
        this.getCountries('?is_deleted=false', true),
        this.getPlatforms('?is_deleted=false', true),
        this.getLocations('?is_deleted=false', true),
      ]
    ).then(() => this.isMounted = true)
  },
  watch: {
    selectedLaunchType(newVal, oldVal) {
      if (oldVal != newVal) {
        this.isMounted = false;
        if (newVal == 'All') {
          Promise.all(
            [
              this.fetchLaunches({'summary': true}),
              this.getConcepts('', true),
              this.getCountries('', true),
              this.getPlatforms('', true),
              this.getLocations('', true),
            ]
          ).then(() => this.isMounted = true)
        }
        if (newVal == 'Upcoming') {
          Promise.all(
            [
              this.fetchLaunches({'current': true, 'summary': true}),
              this.getConcepts('?is_deleted=false', true),
              this.getCountries('?is_deleted=false', true),
              this.getPlatforms('?is_deleted=false', true),
              this.getLocations('?is_deleted=false', true),
            ]
          ).then(() => this.isMounted = true)
        }
        if (newVal == 'Past') {
          Promise.all(
            [
              this.fetchLaunches({'current': false, 'summary': true}),
              this.getConcepts('?is_deleted=false', true),
              this.getCountries('?is_deleted=false', true),
              this.getPlatforms('?is_deleted=false', true),
              this.getLocations('?is_deleted=false', true),
            ]
          ).then(() => this.isMounted = true)
        }
      }
    },
  },
  components: {
    OnboardingTable,
  },
};
</script>

<style>
.add-button {
  margin-top: 8px;
}
</style>
