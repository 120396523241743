<template>
  <div class="box modal-card" style="overflow: visible;">
    <p class="title is-4"> Update Opening Hours on Platforms </p>
    <div class="has-text-centered">
      <h6 class="is-italic"> By clicking here, you will update restaurant opening hours on platform.</h6>
      <b-button type="is-info" class="button-gap" @click="set()" expanded>
        Set Up
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: ['restaurantUUID'],
  data() {
    return {}
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    set() {
      this.$emit('update', {
        restaurant_uuid: this.restaurantUUID,
      })
      this.$emit('close');
    },
  }
}
</script>


<style>

.my-modal .modal-close {
  background: red;
}

</style>
