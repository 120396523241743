<template>
  <div>
    <b-table
      :data="
      kitchenCampaignsInformations"
      :striped="true"
      :hoverable="true"
      paginated
      per-page='10'
      >
      <template slot-scope="props">
        <b-table-column field="locationLabel" label="Label" :width="columnWidth">
            {{ props.row.locationLabel }}
        </b-table-column>
        <b-table-column field="startDate" label="Start Date" :width="columnWidth">
          <b-datepicker
            v-model="props.row.dates"
            icon="calendar-today"
            position="is-bottom-right"
            @input="updateKitchenCampaign(props.row, true, 'dates')"
            locale='fr-Fr'
            range>
          </b-datepicker>
        </b-table-column>
        <b-table-column field="financingModel" label="Financing Model" :width="columnWidth">
          <b-field label="" style="justify-content: center;" expanded>
            <b-select
              type="text"
              v-model="props.row.financingModel"
              placeholder="Campaign Financing Model"
              expanded
              @input="updateKitchenCampaign(props.row, true, 'finance')"
              >
              <option v-for="(campaignFinancingModel, index) in campaignFinancingModels" :key="index" :value="campaignFinancingModel">
                {{campaignFinancingModel}}
              </option>
            </b-select>
          </b-field>
        </b-table-column>
        <b-table-column field="eligible" label="Eligible" :width="columnWidth" sortable v-if="!isEditingMode">
          <b-field label="" style="justify-content: center;" expanded>
            <b-checkbox-button
              v-model="props.row.isEligible"
              :type="getStatusButtonType(props.row)"
              @input="updateKitchenCampaign(props.row, false, null)"
            >
              <b-icon :icon="getStatusButtonIcon(props.row)"></b-icon>
              <span>{{getStatusButtonValue(props.row)}}</span>
            </b-checkbox-button>
          </b-field>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"></b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
    <hr>
    <b-button v-if="!isEditingMode" expanded type="button is-success" @click="create">Create</b-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

  export default {
  props: ['isEditingMode', 'locations', 'campaign', 'kitchenCampaignsInformations', 'category'],
  data() {
    return {
      columnWidth: 250,
      isEmpty: false,
      displaySuccess: false,
    }
  },
  computed: {
    ...mapState({
    isLoading: (state) => state.kitchenCampaigns.fetchAll.pending,
    }),
    campaignFinancingModels() {
      var campaignFinancingModels = this.$store.getters['campaigns/getCampaignFinancingModels'];
      if (!(this.campaign.campaign_type && this.campaign.campaign_type.toLowerCase().includes('discount') && this.campaign.discount_type.toLowerCase().includes('second'))) {
        return campaignFinancingModels.filter(financingModel => financingModel != 'TASTER_FOOD_COST_SECOND_ITEM');
      }
      return campaignFinancingModels;
    }
  },
  methods: {
    getStatusButtonType: function(kitchenCampaignInformation) {
      if (kitchenCampaignInformation.isEligible) {
        return 'is-info'
      }
      return 'is-danger'
    },
    getStatusButtonValue: function(kitchenCampaignInformation) {
      if (kitchenCampaignInformation.isEligible) {
        return 'Eligible'
      }
      return 'Not Eligible'
    },
    getStatusButtonIcon: function(kitchenCampaignInformation) {
      if (kitchenCampaignInformation.isEligible) {
        return 'check'
      }
      return 'close'
    },
    create() {
      this.$emit('create');
    },
    updateKitchenCampaign(kitchenCampaign, askForGlobalApply, updateType) {
      kitchenCampaign.isUpdated = true;
      if (askForGlobalApply) {
        if (updateType == 'finance') {
          this.$buefy.dialog.confirm({
            title: `Apply Financing Model`,
            message: `Do you want to apply same Financing Model to all kitchens with ${this.getCategoryLabel(this.category)}`,
            confirmText: `Apply`,
            cancelText: `No`,
            type: 'is-info',
            hasIcon: true,
            onConfirm: () => {
              for (let kitchenCampaignInfo of this.kitchenCampaignsInformations) {
                kitchenCampaignInfo.isUpdated = true;
                kitchenCampaignInfo.financingModel = kitchenCampaign.financingModel;
              }
            }
          });
        }
        if (updateType == 'dates') {
          this.$buefy.dialog.confirm({
            title: `Apply Dates`,
            message: `Do you want to apply same Dates to all kitchens with ${this.getCategoryLabel(this.category)}`,
            confirmText: `Apply`,
            cancelText: `No`,
            type: 'is-info',
            hasIcon: true,
            onConfirm: () => {
              for (let kitchenCampaignInfo of this.kitchenCampaignsInformations) {
                kitchenCampaignInfo.isUpdated = true;
                kitchenCampaignInfo.dates = kitchenCampaign.dates;
              }
            }
          });
        }
      }
    },
    getCategoryLabel(category) {
      if (!category){
        return 'No Category';
      }
      return category;
    },
    getCampaignDates(dates) {
      return `${dates[0].toISOString().split('T')[0]} - ${dates[1].toISOString().split('T')[0]}`
    }
  },
}
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
