var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card",
      staticStyle: { "overflow-y": "auto", "justify-content": "center" },
    },
    [
      _c(
        "section",
        { staticClass: "modal-card-body has-text-centered" },
        [
          _c("p", { staticClass: "title is-4" }, [
            _vm._v(" Ask for Tech Activation "),
          ]),
          _c(
            "b-steps",
            {
              attrs: {
                size: "is-small",
                type: "is-info",
                "has-navigation": false,
              },
              model: {
                value: _vm.activeStep,
                callback: function ($$v) {
                  _vm.activeStep = $$v
                },
                expression: "activeStep",
              },
            },
            [
              _c(
                "b-step-item",
                { attrs: { label: "Integration Type", step: "1" } },
                [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "has-text-centered" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "button-gap",
                            attrs: { type: "is-link", expanded: "" },
                            on: {
                              click: function ($event) {
                                return _vm.goToStep(1)
                              },
                            },
                          },
                          [_vm._v(" Tablet Integration ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "has-text-centered" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "button-gap",
                            attrs: { type: "is-warning", expanded: "" },
                            on: {
                              click: function ($event) {
                                return _vm.sendAuto()
                              },
                            },
                          },
                          [_vm._v(" Tablet Integration Automatique (Beta) ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "b-step-item",
                { attrs: { label: "Tablet Integration", step: "2" } },
                [
                  _c("b-table", {
                    attrs: { data: _vm.steps },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "b-table-column",
                              { attrs: { field: "id", label: "ID" } },
                              [
                                _c("b-tag", { attrs: { type: "is-info" } }, [
                                  _vm._v(" " + _vm._s(props.row.id) + " "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-table-column",
                              { attrs: { field: "step", label: "Step" } },
                              [_vm._v(" " + _vm._s(props.row.step) + " ")]
                            ),
                            _c(
                              "b-table-column",
                              { attrs: { field: "info", label: "Info" } },
                              [
                                props.row.link
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          tag: "a",
                                          type: "is-link",
                                          href: props.row.link.url,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(props.row.link.label) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c("div", [
                                      _c("strong", [
                                        _vm._v(
                                          " " + _vm._s(props.row.info) + " "
                                        ),
                                      ]),
                                    ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "div",
                    { staticClass: "has-text-centered" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { expanded: "", type: "button is-success" },
                          on: { click: _vm.updateValue },
                        },
                        [_vm._v("Done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-step-item",
                { attrs: { label: "Tabletless Integration", step: "3" } },
                [
                  _c(
                    "section",
                    { staticClass: "modal-card-body has-text-centered" },
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label:
                              "Your Taster Mail (e.g elsa@taster.com sebastien.rybka@taster.com...)",
                            expanded: "",
                          },
                        },
                        [
                          _c("b-input", {
                            attrs: { type: "email" },
                            model: {
                              value: _vm.sender,
                              callback: function ($$v) {
                                _vm.sender = $$v
                              },
                              expression: "sender",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        expanded: "",
                        disabled:
                          _vm.sender == null || !_vm.sender.includes("@taster"),
                        type: "button is-success",
                      },
                      on: { click: _vm.send },
                    },
                    [_vm._v("Send Mail")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }