<template>
  <div class="has-text-centered">
    <b-loading :active.sync="isLoading"></b-loading>
    <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title"> Select Kitchens</p>
          <button
            type="button"
            class="delete"
            @click="close()"/>
        </header>
        <section class="modal-card-body">
            <b-field>
              <b-autocomplete
                rounded
                size="is-medium"
                expanded
                v-model="searchTerm"
                :data="filteredLocations"
                :clear-on-select="true"
                placeholder="Select Kitchen"
                icon="magnify"
                field="label"
                @select="option => selectKitchen(option)"
                :append-to-body="true"
                native-size="4"
                :open-on-focus="true"
              >
              </b-autocomplete>
            </b-field>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { auth, utils } from '@/mixins';

  export default {
  mixins: [auth, utils],
  data() {
    return {
      openModal: true,
      selectedLocations: [],
      selectedLocationUUIDs: [],
      searchTerm: '',
    }
  },
  props: ['conceptUUID', 'platformUUID', 'countryLocationUUID', 'existingLocationUUIDS'],
  computed: {
    ...mapState({
        isLoading: (state) => state.kitchens.fetchAll.pending,
        locations: (state) => state.locations.all,
        kitchens: (state) => state.kitchens.all
    }),
    locationsEligible() {
        var locationUUIDS = this.locations.filter(location => location.parent_uuid == this.countryLocationUUID)
                                          .map(location => location.uuid);
        var kitchens = this.getKitchenWithGivenConceptAndPlatform(this.kitchens, this.conceptUUID, this.platformUUID, locationUUIDS);
        var kitchenLocationUUIDS = kitchens.map(kitchen => kitchen.location_uuid);
        var locations = this.locations.filter(location => kitchenLocationUUIDS.includes(location.uuid) && ! this.existingLocationUUIDS.includes(location.uuid));
        return locations.sort((location1, location2) => location1.id.localeCompare(location2.id));
    },
    filteredLocations() {
      return this.locationsEligible.filter((location) => location.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
  },
  methods: {
    selectKitchen(location) {
      if (location) {
        if (this.selectedLocationUUIDs.includes(location.uuid)) {
          this.selectedLocationUUIDs = this.selectedLocationUUIDs.filter((locationUUID) => locationUUID != location.uuid);
          this.selectedLocations = this.selectedLocations.filter((selectedLocation) => selectedLocation.uuid != location.uuid);
        }
        else {
          this.selectedLocationUUIDs.push(location.uuid);
          this.selectedLocations.push(location);
          this.$emit('createKitchenCampaigns', {'locationUUIDS': this.selectedLocationUUIDs});
          this.$emit('close');
        }
      }
    },
    close() {
      this.$emit('close');
    },
  },
  mounted() {
    this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false', true);
  }
}
</script>

<style scoped>

.dropdown-trigger.a.dropdown-item.is-active {
  background-color: #4BB543;
}
.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
