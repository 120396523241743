var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        active: _vm.openModal,
        scroll: "keep",
        "full-screen": "",
        "on-cancel": _vm.close,
      },
      on: {
        "update:active": function ($event) {
          _vm.openModal = $event
        },
      },
    },
    [
      _c("section", [
        _c("div", { staticClass: "card", staticStyle: { width: "auto" } }, [
          _c("div", { staticClass: "card-content" }, [
            _c("div", { staticClass: "media" }, [
              _c("div", { staticClass: "media-content" }, [
                _c("p", { staticClass: "title is-4" }, [_vm._v(" Actions")]),
              ]),
            ]),
          ]),
          _c("section", { staticClass: "card-content" }, [
            _c(
              "div",
              { staticClass: "has-text-centered" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "button-gap",
                    attrs: {
                      type: "is-success",
                      expanded: "",
                      disabled: _vm.openingsButtonDisabled,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openOnboardingNewModal()
                      },
                    },
                  },
                  [_vm._v(" Opening ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "has-text-centered" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "button-gap",
                    attrs: {
                      type: "is-danger",
                      expanded: "",
                      disabled: _vm.closuresButtonDisabled,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openCloseRestaurantModal()
                      },
                    },
                  },
                  [_vm._v(" Closure ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }