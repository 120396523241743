import axios from '@/axios';
import { baseMarketingUrl } from '@/apis'


const ressourcePath = `${baseMarketingUrl}/campaigns`;

export default {
/* eslint-disable */
  createOne(opts) {
    return axios.post(ressourcePath, opts)
                .then(response => response.data)
  },
  updateOne(campaignUUID, opts) {
    return axios.patch(`${ressourcePath}/${campaignUUID}`, opts)
                .then(response => response.data)
  },
  deleteOne(campaignUUID) {
    return axios.delete(`${ressourcePath}/${campaignUUID}`)
                .then(response => response.data)
  },
  fetchOne(campaignUUID) {
    return axios.get(`${ressourcePath}/${campaignUUID}`)
                .then(response => response.data)
  },
  fetchAll({page, perPage, conceptUUID, countryLocationUUID, platformUUID, campaignType, startDateAfter, startDateBefore, endDateAfter, endDateBefore}) {
    return axios.get(ressourcePath, {params: {
      page,
      per_page: perPage,
      concept_uuid: conceptUUID,
      country_location_uuid: countryLocationUUID,
      platform_uuid: platformUUID,
      campaign_type: campaignType,
      end_date_after: endDateAfter,
      end_date_before: endDateBefore,
      start_date_after: startDateAfter,
      start_date_before: startDateBefore,
    }})
    .then(response => response.data)
  },
};
