<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <div class="has-text-centered" v-if="this.taskType != 'All Tasks'">
      <b-button v-for="(task, index) in tasks" :key="index" :outlined="taskOutline(task)" type="is-link" style="margin-left: 10px; margin-bottom: 10px;" @click="selectTask(task)">
        {{task.id}}
      </b-button>
    </div>
    <div class="has-text-centered" v-if="this.taskType == 'All Tasks'">
      <div class="columns is-mobile">
        <div class="column">
          <b-dropdown
            v-model="taskStatusSelected"
            multiple
            aria-role="list">
            <button class="button is-warning is-small" type="button" slot="trigger">
              <span> Task Status ({{ taskStatusSelected.length }})</span>
              <b-icon icon="menu-down"></b-icon>
            </button>
            <b-dropdown-item v-for="(taskStatus, index) in taskStatus"
              :key="index"
              :value="taskStatus"
              aria-role="listitem">
                <span> {{ taskStatus }} </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column" v-if="this.taskType != 'By Teams'">
          <b-dropdown
            v-model="ownerSelected"
            multiple
            aria-role="list">
            <button class="button is-warning is-small" type="button" slot="trigger">
              <span> Owner ({{ ownerSelected.length }})</span>
              <b-icon icon="menu-down"></b-icon>
            </button>
            <b-dropdown-item v-for="(owner, index) in owner"
              :key="index"
              :value="owner"
              aria-role="listitem">
                <span> {{ owner }} </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column" v-if="this.taskType != 'By Task Groups'">
          <b-dropdown
            v-model="taskGroupSelected"
            multiple
            aria-role="list">
            <button class="button is-warning is-small" type="button" slot="trigger">
              <span> Theme ({{ taskGroupSelected.length }})</span>
              <b-icon icon="menu-down"></b-icon>
            </button>
            <b-dropdown-item v-for="(taskGroup, index) in taskGroup"
              :key="index"
              :value="taskGroup"
              aria-role="listitem">
                <span> {{ taskGroup }} </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column is-10">
        </div>
      </div>
      <b-table
        :data="getTasksToDisplay(tasks[0].tasks)"
        :striped="true"
        :hoverable="true"
        :scrollable="true"
        :per-page="10"
        :paginated="true"
        :default-sort="['label']"
        >
        <template slot-scope="props">
          <b-table-column field="label" label="Label" searchable sortable>
              {{ props.row.label }}
          </b-table-column>
          <b-table-column field="task_group_label" label="TaskGroup" sortable v-if="taskType != 'By Task Groups'">
              <b-tag v-if="props.row.task_group_label" type="is-info"> {{ props.row.task_group_label }} </b-tag>
          </b-table-column>
          <b-table-column field="owner" label="Owner" v-if="taskType != 'By Teams'">
              <b-tag type="is-primary"> {{ props.row.owner }} </b-tag>
          </b-table-column>
          <b-table-column field="status" label="Status">
              <b-tooltip
              :type="getTaskStatusIconType(props.row)"
              :label="getTaskStatusMessage(props.row)"
              multilined>
              <b-icon
                  :icon="getTaskStatusIcon(props.row)"
                  :type="getTaskStatusIconType(props.row)"
                  size="is-medium">
              </b-icon>
              </b-tooltip>
          </b-table-column>
          <b-table-column field="hint" label="Explanation">
              <b-button style="margin-right: 10px;" icon-left='library-books' @click="openTaskDetail(props.row)" v-if="props.row.description"></b-button>
          </b-table-column>
          <b-table-column field="hint" label="Action">
              <b-button icon-left='wrench' @click="openTask(props.row)" :disabled="getActionDisableStatus(props.row)" v-if="!props.row.is_manual"></b-button>
          </b-table-column>
          <b-table-column label="Done">
              <b-switch
              v-model="props.row.is_done"
              passive-type='is-danger'
              type='is-done'
              :disabled="props.row.is_lock || !props.row.is_manual"
              @input="updateTaskStatus(props.row, props.row.is_done)">
              </b-switch>
          </b-table-column>
        </template>
     </b-table>
    </div>
    <div v-if="selectedTask && this.taskType != 'All Tasks'" class="has-text-centered">
        <div class="columns is-mobile">
          <div class="column">
            <b-dropdown
              v-model="taskStatusSelected"
              multiple
              aria-role="list">
              <button class="button is-warning is-small" type="button" slot="trigger">
                <span> Task Status ({{ taskStatusSelected.length }})</span>
                <b-icon icon="menu-down"></b-icon>
              </button>
              <b-dropdown-item v-for="(taskStatus, index) in taskStatus"
                :key="index"
                :value="taskStatus"
                aria-role="listitem">
                  <span> {{ taskStatus }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="column" v-if="this.taskType != 'By Teams'">
            <b-dropdown
              v-model="ownerSelected"
              multiple
              aria-role="list">
              <button class="button is-warning is-small" type="button" slot="trigger">
                <span> Owner ({{ ownerSelected.length }})</span>
                <b-icon icon="menu-down"></b-icon>
              </button>
              <b-dropdown-item v-for="(owner, index) in owner"
                :key="index"
                :value="owner"
                aria-role="listitem">
                  <span> {{ owner }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="column" v-if="this.taskType != 'By Task Groups'">
            <b-dropdown
              v-model="taskGroupSelected"
              multiple
              aria-role="list">
              <button class="button is-warning is-small" type="button" slot="trigger">
                <span> Theme ({{ taskGroupSelected.length }})</span>
                <b-icon icon="menu-down"></b-icon>
              </button>
              <b-dropdown-item v-for="(taskGroup, index) in taskGroup"
                :key="index"
                :value="taskGroup"
                aria-role="listitem">
                  <span> {{ taskGroup }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="column is-10">
          </div>
        </div>
      <b-table
        :data="getTasksToDisplay(selectedTask.tasks)"
        :striped="true"
        :hoverable="true"
        :scrollable="true"
        :per-page="7"
        :paginated="true"
        :default-sort="['desc']"
        >
        <template slot-scope="props">
          <b-table-column field="label" label="Label" searchable>
              {{ props.row.label }}
          </b-table-column>
          <b-table-column field="task_group_label" label="TaskGroup" sortable v-if="taskType != 'By Task Groups'">
            <b-tag v-if="props.row.task_group_label" type="is-info"> {{ props.row.task_group_label }} </b-tag>
          </b-table-column>
          <b-table-column field="owner" label="Owner" v-if="taskType != 'By Teams'">
            <b-tag type="is-primary"> {{ props.row.owner }} </b-tag>
          </b-table-column>
          <b-table-column field="status" label="Status">
            <b-tooltip
              :type="getTaskStatusIconType(props.row)"
              :label="getTaskStatusMessage(props.row)"
              multilined>
              <b-icon
                :icon="getTaskStatusIcon(props.row)"
                :type="getTaskStatusIconType(props.row)"
                size="is-medium">
              </b-icon>
            </b-tooltip>
          </b-table-column>
          <b-table-column field="hint" label="Explanation">
            <b-button style="margin-right: 10px;" icon-left='library-books' @click="openTaskDetail(props.row)" v-if="props.row.description"></b-button>
          </b-table-column>
          <b-table-column field="hint" label="Action">
            <b-button icon-left='wrench' @click="openTask(props.row)" :disabled="getActionDisableStatus(props.row)" v-if="!props.row.is_manual"></b-button>
          </b-table-column>
          <b-table-column label="Done">
            <b-switch
              v-model="props.row.is_done"
              passive-type='is-danger'
              type='is-done'
              :disabled="isTaskDisabled(props.row)"
              @input="updateTaskStatus(props.row, props.row.is_done)">
            </b-switch>
          </b-table-column>
          <b-table-column label="Delete">
            <b-button
              @click="confirmTaskRemoveFromLaunch(props.row)"
              size="is-small"
            >
            <b-icon icon="delete" size="is-small"></b-icon>
            </b-button>
          </b-table-column>
          <b-table-column label="Show UUID" v-if="canEditInformation()">
            <b-button
              @click="showUUIDForm(props.row)"
              size="is-small"
            >
             Show UUID
            </b-button>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { mapActions, mapState } from 'vuex';
import { auth } from '@/mixins';

import CompanyInformationForm from '@/components/Onboarding/LaunchTasks/Forms/CompanyInformationForm.vue'
import DeliverooTabletInformationForm from '@/components/Onboarding/LaunchTasks/Forms/DeliverooTabletInformationForm.vue'
import DeliverooTechAccountForm from '@/components/Onboarding/LaunchTasks/Forms/DeliverooTechAccountForm.vue'
import DeliveryInformationForm from '@/components/Onboarding/LaunchTasks/Forms/DeliveryInformationForm.vue'
import FakeOpeningHoursForm from '@/components/Onboarding/LaunchTasks/Forms/FakeOpeningHoursForm.vue'
import ForecastsForm from '@/components/Onboarding/LaunchTasks/Forms/ForecastsForm.vue'
import HolidaysForm from '@/components/Onboarding/LaunchTasks/Forms/HolidaysForm.vue'
import HubOpeningHoursForm from '@/components/Onboarding/LaunchTasks/Forms/HubOpeningHoursForm.vue'
import KitchenAddressForm from '@/components/Onboarding/LaunchTasks/Forms/KitchenAddressForm.vue'
import KitchenForm from '@/components/Onboarding/LaunchTasks/Forms/KitchenForm.vue'
import MailForm from '@/components/Onboarding/LaunchTasks/Forms/MailForm.vue'
import CommissionForm from '@/components/Onboarding/LaunchTasks/Forms/CommissionForm.vue'
import MenuForm from '@/components/Onboarding/LaunchTasks/Forms/MenuForm.vue'
import MenuFormV2 from '@/components/Onboarding/LaunchTasks/Forms/MenuFormV2.vue'
import OpeningHoursForm from '@/components/Onboarding/LaunchTasks/Forms/OpeningHoursForm.vue'
import ProductsInformationForm from '@/components/Onboarding/LaunchTasks/Forms/ProductsInformationForm.vue'
import RecipesForm from '@/components/Onboarding/LaunchTasks/Forms/RecipesForm.vue'
import RestaurantForm from '@/components/Onboarding/LaunchTasks/Forms/RestaurantForm.vue'
import RestaurantPlatformIDForm from '@/components/Onboarding/LaunchTasks/Forms/RestaurantPlatformIDForm.vue'
import TaskDetailForm from '@/components/Onboarding/LaunchTasks/Forms/TaskDetailForm.vue'
import UberPOSMailForm from '@/components/Onboarding/LaunchTasks/Forms/UberPOSMailForm.vue'
import UberTabletInformationForm from '@/components/Onboarding/LaunchTasks/Forms/UberTabletInformationForm.vue'
import BackOfficeForm from '@/components/Onboarding/LaunchTasks/Forms/BackOfficeForm.vue'
import ImportMenuForm from '@/components/Onboarding/LaunchTasks/Forms/ImportMenuForm.vue'
import MigrateMenuForm from '@/components/Onboarding/LaunchTasks/Forms/MigrateMenuForm.vue'
import PosDeliverooForm from '@/components/Onboarding/LaunchTasks/Forms/PosDeliverooForm.vue'
import SubstitutesForm from '@/components/Onboarding/LaunchTasks/Forms/SubstitutesForm.vue'
import TransferRestaurantForm from '@/components/Onboarding/LaunchTasks/Forms/TransferRestaurantForm.vue'


export default {
  props: ['allTasks', 'tasksByTaskGroup', 'tasksByTaskOwner', 'taskType', 'kitchen', 'location'],
  mixins: [auth],
  watch: {
    currentError(newValue, oldValue) {
      if(newValue) {
        this.displayError(newValue)
      }
    },
    taskType(newValue, oldValue) {
      if(newValue) {
        this.selectedTask = null;
      }
    },
    tasksByTaskGroup(newValue, oldValue) {
      if(newValue && this.taskType == 'By Task Groups' && this.selectedTask) {
        var selectedTaskByTaskGroup = this.tasksByTaskGroup.find(taskByTaskGroup => taskByTaskGroup.id == this.selectedTask.id);
        this.selectedTask = selectedTaskByTaskGroup
      }
    },
    tasksByTaskOwner(newValue, oldValue) {
      if (newValue && this.taskType == 'By Teams' && this.selectedTask) {
        var selectedTaskByOwner = newValue.find(taskByOwner => taskByOwner.id == this.selectedTask.id);
        this.selectedTask = selectedTaskByOwner
      }
    },
  },
  data() {
    return {
      selectedTask : null,
      taskStatus: ['Done', 'Lock', 'Available'],
      taskStatusSelected: ['Done', 'Lock', 'Available'],
      owner: [],
      ownerSelected: [],
      taskGroup: [],
      taskGroupSelected: [],
      restaurantPlatformIdTasksUUIDS: ['05adacbe-2aa8-49b4-9598-359c1c7e0e64', '956d1b48-1646-4f42-ac24-fed07436d05d', 'd74a9355-740a-4a2d-a727-95c93a3a4972', '12f0f5f9-6bf2-4a0a-9feb-a4314a9adf38'],
      restaurantPlatformUrlTasksUUIDS: ['6834a203-06f5-47a2-be60-26066a204ed4', '5db8e954-7ba1-4238-b51b-472f2dc80921', 'b729a1e3-f687-4b0a-a4e8-aaeece03a3e6', 'ecb875a2-ed0b-4f1e-82cb-5917e908cac6'],
      restaurantPlatformCommissionTasksUUIDS: ['4be09a99-27b2-4ac4-a718-bce289e2cf5b', 'dcd61b43-1b61-4ad8-bdeb-010e98706540', '58a92895-a044-49b5-85f3-5448c4a1d4ca', '098420ce-699d-4ac8-94b1-c9818229d4e2'],
      restaurantActivationTasksUUIDS: ['931d812b-e5eb-450f-8fad-ad86e305c84e', '3ca4e9b6-fdc2-4ba3-ba13-719ab088474e', '0f49b61d-b37f-4214-90b1-d978686e4d91', '08c4b994-57e6-4605-b74b-067331983d6b', '040b4b53-7b63-4a16-8c75-5cad443280d2'],
      restaurantDeliverooPOSAskUUIDS: ['8aa5db43-e50d-4a90-8dfc-187c1e09e3f7'],
      restaurantPosStatusTasksUUIDS: ['006e4a71-6bea-4388-b744-fed8479d9ac6', '3ff5d081-4a9a-4dfa-b5d5-ba0212e0ced4', '08e6c773-4ee6-435d-b580-2c946ba0a11e', 'd229ef16-64d5-4327-b3df-7fd8c6d398d7'],
      restaurantOpeningHoursPushTasksUUIDS: ['b5c938b2-6fc7-4b92-b58d-a2007500449d', 'fc8d3b74-343c-439a-aa64-51cf6c8e7af1', '2d995deb-4904-4f96-8a31-1af5a3dfc9df', 'b7cc9f5a-b196-43d7-9ae1-2b59d893670a'],
      restaurantMenuPushTasksUUIDS: ['90c981f8-6271-4fe5-a682-0aaa072039da', 'c4436cf3-dc75-495d-9d54-25e187c5e8bb', '44731703-1da6-496b-99ae-3e72dda124a4', 'd9af077b-7acb-4da9-a96b-c048c89369f2'],
      restaurantTabletInformationTasksUUIDS: ['840ac6f2-1e29-4618-9ef4-a1c108d8b5fb', '5066eaf4-5d64-42e5-8e5d-b60df265df36', '69cd47a8-324b-4be3-9349-f9ed434a9703'],
      restaurantCheckPosIntegrationTasksUUIDS: ['d5b355e4-cf37-43ec-b0d9-bd2b9a87a745', 'c43b3cb2-5aef-47f5-8f13-0bea32ab6809', '6b44d612-7e4b-46ff-b740-d1dc332ce702', '2af5a3db-84cf-454d-96d8-8b7ebc826514'],
      restaurantSetUpHolidaysTasksUUIDS: ['28dbd1e0-84a5-463f-882a-7d785210368c', 'caf6d0d6-c3dd-4619-bf7a-b0146781170f', '063bd188-4f8b-4bef-88ab-217359f92322'],
      restaurantBackOfficeTasksUUIDS: ['88a7c6e2-ba20-430d-b967-b9be19a9afe0', '120289ee-af78-4661-b964-866155c14096'],
      restaurantPOSMailTasksUUIDS: ['83b93315-7ba8-4618-a7bb-fff2b6063d37', 'b4dd298d-244b-47d0-96ad-5aa019f83226', 'c90c52b0-3ed3-4921-9727-f3e12e79dbdc'],
      restaurantTransferTasksUUIDS: ['12fa7462-2a4d-431a-b3a3-0a3978e1ba0c', '98c005b9-fe45-4441-ad1a-7a998e6ee4d0', '7f35d984-e2a1-470f-b6c7-6cfeea1876c1', '342feaf8-0ae0-4590-81e4-b73a43fc5243', '49f3ffa1-71c1-4f64-966b-ef87829107fd']
    }
  },
  computed: {
    ...mapState({
      concepts: (state) => state.concepts.all,
      currentError: (state) => state.launches.updateTask.error || state.launches.removeTask.error || state.actions.activatePOSIntegration.error || state.actions.checkPOSIntegration.error || state.actions.updateRestaurant.error || state.actions.updateKitchen.error || state.actions.copyRecipes.error || state.actions.copyMenu.error || state.actions.createForecasts.error || state.actions.copyProductsInformations.error || state.actions.setUpHolidays.error || state.actions.setUpFakeOpeningHours.error || state.actions.updateOpeningHours.error || state.actions.uploadMenu.error || state.actions.exportMenu.error || state.actions.importDummyMenu.error || state.actions.configureUATEnvironment.error || state.actions.migrateMenu.error || state.actions.setUpSubstitutesRules.error || state.actions.transferRestaurant.error || state.actions.configureTasterRestaurant.error || state.actions.grantIAMAccess.error || state.actions.createMail.error || state.actions.createIntercomInformation.error,
      isLoading: (state) => state.launches.updateTask.pending || state.launches.removeTask.pending || state.actions.activatePOSIntegration.pending || state.actions.checkPOSIntegration.pending || state.actions.updateRestaurant.pending || state.actions.updateKitchen.pending || state.actions.copyRecipes.pending || state.actions.copyMenu.pending || state.actions.createForecasts.pending || state.actions.copyProductsInformations.pending || state.actions.setUpHolidays.pending || state.actions.setUpFakeOpeningHours.pending || state.actions.updateOpeningHours.pending || state.actions.uploadMenu.pending || state.actions.exportMenu.pending|| state.actions.importDummyMenu.pending || state.actions.configureUATEnvironment.pending|| state.actions.migrateMenu.pending || state.actions.setUpSubstitutesRules.pending || state.actions.transferRestaurant.pending || state.actions.configureTasterRestaurant.pending || state.actions.grantIAMAccess.pending || state.actions.createMail.pending || state.actions.createIntercomInformation.pending,
      launch : (state) => state.launches.current,
      taskGroups: (state) => state.taskGroups.all,
    }),
    tasks() {
      if (this.taskType == 'All Tasks') {
        return this.allTasks
      }
      else if (this.taskType == 'By Task Groups') {
        return this.tasksByTaskGroup.sort((taskGroup1, taskgroup2) =>
        taskGroup1.id.localeCompare(taskgroup2.id),
    )
      }
      else if (this.taskType == 'By Teams') {
        return this.tasksByTaskOwner.sort((team1, team2) =>
        team1.id.localeCompare(team2.id),
    )
      }
      return []
    },
  },
  methods: {
    ...mapActions('actions', { activatePOSIntegration: 'activatePOSIntegration', checkPOSIntegration: 'checkPOSIntegration', copyMenu: 'copyMenu', copyProductsInformations: 'copyProductsInformations', copyRecipes: 'copyRecipes', createMailAction: 'createMailAction', createIntercomInformationAction: 'createIntercomInformationAction', createForecasts: 'createForecasts', setUpFakeOpeningHours: 'setUpFakeOpeningHours', setUpHolidays: 'setUpHolidays', updateKitchen: 'updateKitchen', updateOpeningHoursPlatform: 'updateOpeningHoursPlatform', updateRestaurant: 'updateRestaurant', uploadMenu: 'uploadMenu', exportMenu: 'exportMenu', importDummyMenu: 'importDummyMenu', configureUATEnvironment: 'configureUATEnvironment', grantIAMAccessAction: 'grantIAMAccessAction', migrateMenu: 'migrateMenu', setUpSubstitutesRules: 'setUpSubstitutesRules', transferRestaurant: 'transferRestaurant', configureTasterRestaurantAction: 'configureTasterRestaurantAction'}),
    ...mapActions('launches', { updateTask: 'updateTask', removeTask: 'removeTask'}),
    setFilters() {
        var ownerTemp = this.tasksByTaskOwner.map(task => task.id);
        this.owner = Array.from(new Set([...ownerTemp]));
        this.ownerSelected = Array.from(new Set([...ownerTemp]));
        var taskGroupTemp = this.tasksByTaskGroup.map(task => task.id);
        this.taskGroup = Array.from(new Set([...taskGroupTemp]));
        this.taskGroupSelected = Array.from(new Set([...taskGroupTemp]));
    },
    getTasksToDisplay(tasks) {
      var displayedTasks = [];
      for (let task of tasks) {
        var taskToAdd = false;
        if (this.taskStatusSelected.includes('Done') && task.is_done) {
          taskToAdd = true;
        }
        if (this.taskStatusSelected.includes('Lock') && task.is_lock) {
          taskToAdd = true;
        }
        if (this.taskStatusSelected.includes('Available') && !task.is_lock && !task.is_done) {
          taskToAdd = true;
        }
        if (!this.ownerSelected.includes(task.owner)) {
          taskToAdd = false;
        }
        if (!this.taskGroupSelected.includes(task.task_group_label)) {
          taskToAdd = false;
        }
        if (taskToAdd) {
          displayedTasks.push(task);
        }
      }
      return displayedTasks
    },
    isTaskDisabled(task){
      if (this.isLaunchAdmin()) {
        return false;
      }
      return task.is_lock || !task.is_manual
    },
    getTaskStatusIcon(task) {
      if (task.is_done) {
        return 'check'
      }
      if (task.is_lock) {
        return 'lock'
      }
      return 'run'
    },
    getActionIcon(task) {
      if (task.is_manual) {
        return 'library-books'
      }
      return 'wrench'
    },
    getActionDisableStatus(task) {
      if ((!task.is_manual && task.is_lock) || task.is_done) {
        return true
      }
      return false
    },
    getTaskStatusIconType(task) {
      if (task.is_done) {
        return 'is-success'
      }
      if (task.is_lock) {
        return 'is-danger'
      }
      return 'is-dark'
    },
    getTaskStatusMessage(task) {
      if (task.is_done) {
        return 'Done'
      }
      if (task.is_lock) {
        var message = ``;
        for (let i = 0; i < task.task_dependencies.length; i++) {
          var taskUUID = task.task_dependencies[i];
          var dependantTask = this.allTasks[0].tasks.find(task => task.uuid == taskUUID);
          if (dependantTask && !dependantTask.is_done) {
            message += `${dependantTask.label}`
            if (i != task.task_dependencies.length - 1) {
              message += ` | `
            }
          }
        }
        if (message == ` | `) {
          message = '';
        }
        return message
      }
      return 'Task Available'
    },
    taskOutline: function (task) {
      if (this.selectedTask && task.id == this.selectedTask.id) {
        return false
      }
      return true
    },
    subTaskColorType: function (task) {
      if (task.is_done) {
        return 'is-success'
      }
      return 'is-danger'
    },
    subTaskDisabledType: function (task) {
      if (task.is_lock) {
        return true
      }
      return false
    },
    subTaskIcon: function (task) {
      if (task.is_lock) {
        return 'lock'
      }
      if (task.is_done) {
        return 'approval'
      }
      return ''
    },
    selectTask(task) {
      this.selectedTask = task;
    },
    updateTaskStatus(task, status) {
      this.updateTask(
        {
          launchUUID: this.launch.uuid,
          taskUUID: task.uuid,
          is_done: status
        }
      )
    },
    showUUIDForm(task) {
      this.$buefy.dialog.alert({
        title: `${task.label}`,
        message:
          `${task.uuid}`,
      });
    },
    confirmTaskRemoveFromLaunch(task) {
      this.$buefy.dialog.confirm({
        title: `Delete ${task.label}`,
        message:
          'Are you sure you want to <b>delete</b> this task from the launch. This action cannot be undone.',
        confirmText: `Delete task`,
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteTask(task),
      });
    },
    deleteTask(task) {
      this.removeTask(
        {
          launchUUID: this.launch.uuid,
          taskUUID: task.uuid,
        }
      )
    },
    openTask(task) {
      if (this.restaurantPlatformCommissionTasksUUIDS.includes(task.uuid)) {
        var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
        var platform_uuid = taskGroup.launch_conditions[0].value;
        var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
        var commission = 0;
        if (task.uuid == 'dcd61b43-1b61-4ad8-bdeb-010e98706540') {
          commission = 25;
        }
        else if (task.uuid == '4be09a99-27b2-4ac4-a718-bce289e2cf5b') {
          commission = 24;
        }
        else if (task.uuid == '58a92895-a044-49b5-85f3-5448c4a1d4ca') {
          commission = 25;
        }
        else if (task.uuid == '098420ce-699d-4ac8-94b1-c9818229d4e2') {
          commission = 22.5;
        }
        this.openCommissionForm(task, restaurant.uuid, commission)
      }
      else if (this.restaurantPlatformIdTasksUUIDS.includes(task.uuid)) {
        this.openRestaurantPlatformIDForm(task)
      }
      else if (this.restaurantPlatformUrlTasksUUIDS.includes(task.uuid)) {
        this.openRestaurantForm(task, 'Restaurant URL', 'string', null)
      }
      else if (this.restaurantPosStatusTasksUUIDS.includes(task.uuid)) {
        this.updateRestaurantInformation(task, null)
      }
      else if (this.restaurantActivationTasksUUIDS.includes(task.uuid)) {
        this.updateRestaurantInformation(task, true)
      }
      else if (task.uuid == '1e2614cb-49c2-4898-ba61-6b5c7421498d') {
        this.openMenuForm(task)
      }
      else if (task.uuid == '956ec2e9-75d5-4ebb-a792-c12bf14c8e5c') {
        this.openKitchenForm(task, 'Taster Commission', 'number', 30)
      }
      else if (task.uuid == 'bc309804-512b-41c0-aaa8-2102d519712a') {
        this.openKitchenForm(task, 'One Tablet Pin', 'string', null)
      }
      else if (task.uuid == 'efeb4a3c-1e8b-4266-8a56-84fe71f6b3bb') {
        this.openCompanyInformationForm(task)
      }
      else if (task.uuid == '6baa0b52-8fec-452b-8d09-4480c0f698f7') {
        this.openMailInformationForm(task)
      }
      else if (task.uuid == 'eb1ff2a2-b465-40c0-8460-d9d056e121d3') {
        this.openKitchenAddressForm(task)
      }
      else if (task.uuid == '4b72e876-9cbc-4448-b9c5-15e2a5e0c681') {
        this.openOpeningHoursForm(task)
      }
      else if (task.uuid == '20478bc7-4ab8-4b27-a4e7-200d1624eefc') {
        this.openRecipesForm(task)
      }
      else if (task.uuid == '159230f5-0775-4b26-b390-57700cef6aa5') {
        this.openForecastsForm(task)
      }
      else if (this.restaurantSetUpHolidaysTasksUUIDS.includes(task.uuid)) {
        this.openHolidaysForm(task)
      }
      else if (task.uuid == '121fe41e-609d-4d82-b2b8-3e3e7fb011a8') {
        this.openFakeOpeningHoursForm(task)
      }
      else if (this.restaurantOpeningHoursPushTasksUUIDS.includes(task.uuid)) {
        this.openHubOpeningHoursForm(task)
      }
      else if (task.uuid == 'ba5f8ae3-6f3b-4e9d-9776-1ac3d98c06b2') {
        this.openCopyProductsInformationForm(task)
      }
      else if (task.uuid == 'ba73d31a-b476-4750-91a1-0ad5f1d0416c') {
        this.openDeliverooTabletInformationForm(task)
      }
      else if (task.uuid == 'df58c866-3b77-4259-8aee-afc00c89edd0') {
        this.openDeliverooTechAccountForm(task)
      }
      else if (this.restaurantTabletInformationTasksUUIDS.includes(task.uuid)) {
        this.openUberTabletInformationForm(task)
      }
      else if (this.restaurantPOSMailTasksUUIDS.includes(task.uuid)) {
        this.openPOSMailForm(task)
      }
      else if (task.uuid == 'd6c603a2-b745-410e-b3c4-b3363b4be7a2') {
        this.openDeliveryInformationForm(task)
      }
      else if (task.uuid == '7a17c9d6-51c3-49de-a05b-7c7bddaa5bc6') {
        this.updateIntercomInformation(task)
      }
      else if (this.restaurantBackOfficeTasksUUIDS.includes(task.uuid)) {
        this.openBackOfficeForm(task)
      }
      else if (this.restaurantDeliverooPOSAskUUIDS.includes(task.uuid)) {
        this.openDeliverooPOSForm(task)
      }
      else if (task.uuid == '7bf2248f-490a-4cd3-82bf-e11e41ae3cd7') {
        this.openSubsitutesForm(task)
      }
      else if (this.restaurantTransferTasksUUIDS.includes(task.uuid)) {
        this.openTransferRestaurantForm(task)
      }
      else if (this.restaurantCheckPosIntegrationTasksUUIDS.includes(task.uuid)) {
        var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
        var platform_uuid = taskGroup.launch_conditions[0].value;
        var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && ! restaurant.is_deleted);
        this.checkPOSIntegration({restaurant_platform_id: restaurant.restaurant_platform_id}).then((checkPOSIntegrationResponse) => {
          if (checkPOSIntegrationResponse && checkPOSIntegrationResponse.pos_integration_enabled == true) {
            this.updateTask(
              {
                launchUUID: this.launch.uuid,
                taskUUID: task.uuid,
                is_done: true,
              }
            )
          }
          else {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: checkPOSIntegrationResponse.status,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
          }
        })
      }
      else if (this.restaurantMenuPushTasksUUIDS.includes(task.uuid)) {
        var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
        var platform_uuid = taskGroup.launch_conditions[0].value;
        var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
        this.$buefy.dialog.confirm({
            title: 'Upload Menu',
            confirmText: 'Upload',
            message: `You are going to upload menu on <b> ${restaurant.platform_restaurant.label} </b>`,
            type: 'is-success',
            onConfirm: () => this.uploadMenu({launch_uuid: this.launch.uuid, restaurant_uuid: restaurant.uuid}).then((updateHolidaysResponse) => {
            if (updateHolidaysResponse && updateHolidaysResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          })
        })
      }
      else if (task.uuid == 'e0c16515-2fd2-4f8e-a260-d96f06bf6c55') {
        this.$buefy.dialog.confirm({
            title: 'Configure UAT Environment',
            confirmText: 'Create',
            message: `You are going to export to create the concept in UAT env </b>`,
            type: 'is-success',
            onConfirm: () => this.configureUATEnvironment(
              {
                launch_uuid: this.launch.uuid,
              }
            ).then((configureUATResponse) => {
            if (configureUATResponse && configureUATResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          })
        })
      }
      else if (task.uuid == 'e70c65c3-90ad-4ce4-b81c-5aea2383ea60') {
        var platform_uuid = '98fee123-068c-487a-af61-efcee6cb85fe'
        var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
        this.$buefy.dialog.confirm({
            title: 'Export Menu From Deliveroo',
            confirmText: 'Export',
            message: `You are going to export menu from <b> ${restaurant.restaurant_platform_id} </b>`,
            type: 'is-success',
            onConfirm: () => this.exportMenu(
              {
                  launch_uuid: this.launch.uuid,
                  restaurant_platform_id: restaurant.restaurant_platform_id,
              }
            ).then((exportMenuResponse) => {
            if (exportMenuResponse && exportMenuResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          })
        })
      }
      else if (task.uuid == 'e0dc77ae-fad3-4591-b842-f2265472df3a') {
        this.$buefy.modal.open({
          component: ImportMenuForm,
          parent: this,
          hasModalCard: true,
          events: {
            update: (opts) => this.importDummyMenu(opts).then((response) => {
              if (response && response.status == 'OK') {
                this.updateTask(
                  {
                    launchUUID: this.launch.uuid,
                    taskUUID: task.uuid,
                    is_done: true,
                  }
                )
              }
            })
          },
          props: {
            launch: this.launch,
          }
        })
      }
      else if (task.uuid == '7f054a13-301c-4746-b68e-ee305201f761') {
        this.$buefy.modal.open({
          component: MigrateMenuForm,
          parent: this,
          hasModalCard: true,
          events: {
            update: (opts) => this.migrateMenu(opts).then((response) => {
              if (response && response.status == 'OK') {
                this.updateTask(
                  {
                    launchUUID: this.launch.uuid,
                    taskUUID: task.uuid,
                    is_done: true,
                  }
                )
              }
            })
          },
          props: {
            launch: this.launch
          }
        })
      }
      else if (task.uuid == '1a33c62d-8d2b-4fbf-8883-dde2d797adf3') {
        this.$buefy.dialog.confirm({
            title: 'Create C&C Restaurant',
            confirmText: 'Create',
            message: `You are going to create C&C Restaurant </b>`,
            type: 'is-success',
            onConfirm: () => this.configureTasterRestaurantAction(
              {
                  concept_uuid: this.launch.concept_uuid,
                  location_uuid: this.launch.location_uuid,
                  launch_uuid: this.launch.uuid,
              }
            ).then((configureTasterRestaurantActionResponse) => {
            if (configureTasterRestaurantActionResponse && configureTasterRestaurantActionResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          })
        })
      }
      else {
        this.openTaskDetail(task)
      }
    },
    getOpenTaskMessage(task) {
      if (task.description) {
        return task.description
      }
      return 'No Description'
    },
    openTaskDetail: function(task) {
      this.$buefy.modal.open({
        component: TaskDetailForm,
        parent: this,
        hasModalCard: true,
        canCancel: false,
        events: {},
        props: {
          task: task
        }
      })
    },
    openCommissionForm: function(task, restaurantUuid, platformCommission) {
      this.$buefy.modal.open({
        component: CommissionForm,
        parent: this,
        hasModalCard: true,
        events: {
          updateValue: (value) => this.updateRestaurantInformation(task, value)
        },
        props: {
          restaurantUuid: restaurantUuid,
          platformCommission: platformCommission,
          isInternalLaunch: this.launch.is_internal,
        }
      })
    },
    openKitchenForm: function(task, fieldLabel, fieldType, defaultValue) {
      this.$buefy.modal.open({
        component: KitchenForm,
        parent: this,
        hasModalCard: true,
        events: {
          updateValue: (value) => this.updateKitchenInformation(task, value)
        },
        props: {
          fieldLabel: fieldLabel,
          fieldType: fieldType,
          defaultValue: defaultValue
        }
      })
    },
    openKitchenAddressForm: function(task) {
      this.$buefy.modal.open({
        component: KitchenAddressForm,
        parent: this,
        hasModalCard: true,
        events: {
          updateValue: (value) => this.updateKitchenInformation(task, value),
          updateTask: () => this.updateTask(
            {
              launchUUID: this.launch.uuid,
              taskUUID: task.uuid,
              is_done: true
            }
          )
        },
        props: {
          kitchen: this.kitchen,
          location: this.location,
        }
      })
    },
    openDeliveryInformationForm: function(task) {
      if (Object.keys(this.kitchen.supply).length > 0) {
        var deliveryInformations = {
          'deliveryAddress': this.kitchen.supply.address,
          'deliveryContact': this.kitchen.supply.delivery_contact,
          'deliveryTimes': this.kitchen.supply.delivery_times,
          'deliveryInstructions': this.kitchen.supply.delivery_instructions,
        }
      }
      else {
        var deliveryInformations = {
          'deliveryAddress': null,
          'deliveryContact': {
            'first_name': '',
            'last_name': '',
            'personal_mail': '',
            'phone_number': '',
          },
          'deliveryInstructions': null,
          'deliveryTimes': {},
        }
      }
      this.$buefy.modal.open({
        component: DeliveryInformationForm,
        parent: this,
        hasModalCard: true,
        events: {
          updateValue: (value) => this.updateKitchenInformation(task, value)
        },
        props: {
          deliveryInformations: deliveryInformations
        }
      })
    },
    openMailInformationForm: function(task) {
      this.$buefy.modal.open({
        component: MailForm,
        parent: this,
        hasModalCard: true,
        events: {
          createMails: (value) => this.updateManagerInformation(task, value)
        },
        props: {
          activeStep: 0,
          kitchen: this.kitchen
        }
      })
    },
    openCompanyInformationForm: function(task) {
      var companyInformations = {
        'companyName': this.kitchen.company_name,
        'companyType': this.kitchen.company_type,
        'companyAddress': this.kitchen.company_address,
      }
      this.$buefy.modal.open({
        component: CompanyInformationForm,
        parent: this,
        hasModalCard: true,
        events: {
          updateValue: (value) => this.updateKitchenInformation(task, value)
        },
        props: {
          companyInformations: companyInformations,
        }
      })
    },
    updateKitchenInformation(task, value) {
      var fields = null;
      if (task.uuid == '956ec2e9-75d5-4ebb-a792-c12bf14c8e5c') {
        fields = [
            {
              'financial_informations': {'taster_commission': value}
            }
        ]
      }
      if (task.uuid == 'bc309804-512b-41c0-aaa8-2102d519712a') {
        fields = [
            {
              'secrets': {'pin_one_tablet': value}
            }
        ]
      }
      if (task.uuid == '4b72e876-9cbc-4448-b9c5-15e2a5e0c681') {
        fields = [
          value
        ]
      }
      if (task.uuid == 'efeb4a3c-1e8b-4266-8a56-84fe71f6b3bb') {
        fields = [
          value
        ]
      }
      if (task.uuid == 'eb1ff2a2-b465-40c0-8460-d9d056e121d3') {
        fields = [
          value
        ]
      }
      if (task.uuid == 'd6c603a2-b745-410e-b3c4-b3363b4be7a2') {
        fields = [
          {
            'supply': value
          }
        ]
      }
      this.updateKitchen({
        fields: fields,
        kitchen_uuid: this.launch.kitchen_uuid,
      }).then((updateKitchenResponse) => {
        if (updateKitchenResponse && updateKitchenResponse.status == 'OK') {
          this.updateTask(
            {
              launchUUID: this.launch.uuid,
              taskUUID: task.uuid,
              is_done: true,
            }
          )
        }
        else {
          this.$buefy.dialog.alert({
            title: 'Error',
            message: updateKitchenResponse.status,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
      })
    },
    openRestaurantForm: function(task, fieldLabel, fieldType, defaultValue) {
      this.$buefy.modal.open({
        component: RestaurantForm,
        parent: this,
        hasModalCard: true,
        events: {
          updateValue: (value) => this.updateRestaurantInformation(task, value)
        },
        props: {
          fieldLabel: fieldLabel,
          fieldType: fieldType,
          defaultValue: defaultValue
        }
      })
    },
    openRestaurantPlatformIDForm: function(task) {
      var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
      var platform_uuid = taskGroup.launch_conditions[0].value;
      this.$buefy.modal.open({
        component: RestaurantPlatformIDForm,
        parent: this,
        hasModalCard: true,
        events: {
          updateValue: (value) => this.updateRestaurantInformation(task, value)
        },
        props: {
          isInternalLaunch: this.launch.is_internal,
          platformUUID: platform_uuid,
        }
      })
    },
    updateRestaurantInformation(task, value) {
      var fields = null;
      if (this.restaurantPlatformCommissionTasksUUIDS.includes(task.uuid)) {
        fields = [
            {
              'financial_informations': {'platform_commission': value},
              'restaurant_information': {'platform_commission': value}
            }
        ]
      }
      if (this.restaurantPlatformIdTasksUUIDS.includes(task.uuid)) {
        fields = [
            {
              'restaurant_platform_id': value
            }
        ]
      }
      if (this.restaurantPlatformUrlTasksUUIDS.includes(task.uuid)) {
        fields = [
            {
              'url': value
            }
        ]
      }
      if (this.restaurantPosStatusTasksUUIDS.includes(task.uuid)) {
        fields = [
            {
              'is_pos_integrated': true,
              'is_one_tablet': true,
            }
        ]
      }
      if (this.restaurantActivationTasksUUIDS.includes(task.uuid)) {
        fields = [
            {
              'is_active': value
            }
        ]
      }
      var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
      var platform_uuid = taskGroup.launch_conditions[0].value;
      var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
      this.updateRestaurant({
        fields: fields,
        kitchen_uuid: this.launch.kitchen_uuid,
        restaurant_uuid: restaurant.uuid,
      }).then((updateRestaurantResponse) => {
        if (updateRestaurantResponse && updateRestaurantResponse.status == 'OK') {
          this.updateTask(
            {
              launchUUID: this.launch.uuid,
              taskUUID: task.uuid,
              is_done: true,
            }
          )
        }
        else {
          this.$buefy.dialog.alert({
              title: 'Error',
              message: updateRestaurantResponse.status,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
          })
        }
      })
    },
    openOpeningHoursForm: function(task) {
      this.$buefy.modal.open({
        component: OpeningHoursForm,
        parent: this,
        hasModalCard: true,
        events: {
          updateValue: (updatedOpeningHours) => this.updateOpeningHours(
            this.launch.kitchen_uuid, this.launch.concept_uuid, updatedOpeningHours, task)
        },
        props: {
          conceptUUID: this.launch.concept_uuid,
          kitchen: this.kitchen,
          openingHours: {
            'Monday': { lunch_shift: [], dinner_shift: [] },
            'Tuesday': { lunch_shift: [], dinner_shift: [] },
            'Wednesday': { lunch_shift: [], dinner_shift: [] },
            'Thursday': { lunch_shift: [], dinner_shift: [] },
            'Friday': { lunch_shift: [], dinner_shift: [] },
            'Saturday': { lunch_shift: [], dinner_shift: [] },
            'Sunday': { lunch_shift: [], dinner_shift: [] },
          }
        }
      })
    },
    updateOpeningHours: function(kitchenUUID, conceptUUID, openingHours, task) {
      var restaurants = this.kitchen.restaurants.filter(restaurant => restaurant.concept_uuid == conceptUUID && this.launch.platform_uuids.includes(restaurant.platform_uuid));
      var fields = [
        {
          'opening_hours': openingHours
        }
      ]
      Promise.all([
        ...restaurants.map((restaurant) =>
          this.updateRestaurant({
            fields: fields,
            kitchen_uuid: kitchenUUID,
            restaurant_uuid: restaurant.uuid,
          })
        ),
        ]).then((updateRestaurantResponses) => {
          var responsStatus = updateRestaurantResponses.map(response => response.status).filter(status => status == 'OK');
          if (updateRestaurantResponses.length == responsStatus.length) {
            this.updateTask(
              {
                launchUUID: this.launch.uuid,
                taskUUID: task.uuid,
                is_done: true,
              }
            )
          }
        })
    },
    openMenuForm: function(task) {
      this.$buefy.modal.open({
        component: MenuFormV2,
        parent: this,
        hasModalCard: true,
        events: {
          setUpMenu: (opts) => this.copyMenu({...opts, ...{'concept_uuid': this.launch.concept_uuid, 'location_uuid': this.launch.location_uuid, 'launch_uuid': this.launch.uuid}}).then((updateMenuResponse) => {
            if (updateMenuResponse && updateMenuResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          }),
        },
        props: {
          conceptUUID: this.launch.concept_uuid
        }
      })
    },
    // openMenuForm: function(task) {
    //   this.$buefy.modal.open({
    //     component: MenuForm,
    //     parent: this,
    //     hasModalCard: true,
    //     events: {
    //       setUpMenu: (opts) => this.copyMenu({...opts, ...{'launch_uuid': this.launch.uuid}}).then((updateMenuResponse) => {
    //         if (updateMenuResponse && updateMenuResponse.status == 'OK') {
    //           this.updateTask(
    //             {
    //               launchUUID: this.launch.uuid,
    //               taskUUID: task.uuid,
    //               is_done: true,
    //             }
    //           )
    //         }
    //       }),
    //     createManually: () => this.updateTask(
    //           {
    //             launchUUID: this.launch.uuid,
    //             taskUUID: task.uuid,
    //             is_done: true,
    //           }
    //         )
    //     },
    //     props: {
    //       conceptUUID: this.launch.concept_uuid,
    //       kitchenCountryUUID: this.kitchen.kitchen_country.uuid,
    //       kitchenUUID: this.launch.kitchen_uuid,
    //       locationUUID: this.launch.location_uuid,
    //     }
    //   })
    // },
    openRecipesForm: function(task) {
      this.$buefy.modal.open({
        component: RecipesForm,
        parent: this,
        hasModalCard: true,
        events: {
          update: (opts) => this.copyRecipes(opts).then((updateRecipestResponse) => {
            if (updateRecipestResponse && updateRecipestResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          })
        },
        props: {
          conceptUUID: this.launch.concept_uuid,
          kitchenUUID: this.launch.kitchen_uuid
        }
      })
    },
    openForecastsForm: function(task) {
      this.$buefy.modal.open({
        component: ForecastsForm,
        parent: this,
        hasModalCard: true,
        events: {
          create: (opts) => this.createForecasts(opts).then((updateForecastsResponse) => {
            if (updateForecastsResponse && updateForecastsResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          })
        },
        props: {
          conceptUUID: this.launch.concept_uuid,
          kitchenUUID: this.launch.kitchen_uuid
        }
      })
    },
    openCopyProductsInformationForm: function(task) {
      this.$buefy.modal.open({
        component: ProductsInformationForm,
        parent: this,
        hasModalCard: true,
        events: {
          update: (opts) => this.copyProductsInformations(opts).then((copyProductsInformationsResponse) => {
            if (copyProductsInformationsResponse && copyProductsInformationsResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          })
        },
        props: {
          conceptUUID: this.launch.concept_uuid,
          kitchenUUID: this.launch.kitchen_uuid
        }
      })
    },
    openHolidaysForm: function(task) {
      var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
      var platform_uuid = taskGroup.launch_conditions[0].value;
      var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
      var startDate = new Date()
      var endDate = new Date(restaurant.launch_date.substring(0,4), restaurant.launch_date.substring(5,7) - 1, restaurant.launch_date.substring(8,10));
      endDate = new Date(endDate.setTime(endDate.getTime() - 24*60*60*1000))
      this.$buefy.modal.open({
        component: HolidaysForm,
        parent: this,
        hasModalCard: true,
        events: {
          update: (opts) => this.setUpHolidays({...opts, ...{'platform_uuid': platform_uuid}}).then((updateHolidaysResponse) => {
            if (updateHolidaysResponse && updateHolidaysResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          })
        },
        props: {
          endDate: endDate,
          startDate: startDate,
          conceptUUID: this.launch.concept_uuid,
          kitchenUUID: this.launch.kitchen_uuid
        }
      })
    },
    openFakeOpeningHoursForm: function(task) {
      var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
      var platform_uuid = taskGroup.launch_conditions[0].value;
      var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
      this.$buefy.modal.open({
        component: FakeOpeningHoursForm,
        parent: this,
        hasModalCard: true,
        events: {
          update: (opts) => this.setUpFakeOpeningHours(opts).then((setUpFakeOpeningHoursResponse) => {
            if (setUpFakeOpeningHoursResponse && setUpFakeOpeningHoursResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          })
        },
        props: {
          restaurantUUID: restaurant.uuid
        }
      })
    },
    openHubOpeningHoursForm: function(task) {
      var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
      var platform_uuid = taskGroup.launch_conditions[0].value;
      var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
      this.$buefy.modal.open({
        component: HubOpeningHoursForm,
        parent: this,
        hasModalCard: true,
        events: {
          update: (opts) => this.updateOpeningHoursPlatform(opts).then((updateOpeningHoursResponse) => {
            if (updateOpeningHoursResponse && updateOpeningHoursResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          })
        },
        props: {
          restaurantUUID: restaurant.uuid
        }
      })
    },
    openDeliverooTabletInformationForm: function(task) {
      var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
      var platform_uuid = taskGroup.launch_conditions[0].value;
      var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
      this.$buefy.modal.open({
        component: DeliverooTabletInformationForm,
        parent: this,
        hasModalCard: true,
        events: {
          update: (opts) => this.updateRestaurant({
            fields: [opts],
            kitchen_uuid: this.launch.kitchen_uuid,
            restaurant_uuid: restaurant.uuid,
          }).then((updateRestaurantResponse) => {
            if (updateRestaurantResponse && updateRestaurantResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
        })
      },
        props: {
          restaurantUUID: restaurant.uuid
        }
      })
    },
    openUberTabletInformationForm: function(task) {
      var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
      var platform_uuid = taskGroup.launch_conditions[0].value;
      var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
      this.$buefy.modal.open({
        component: UberTabletInformationForm,
        parent: this,
        hasModalCard: true,
        events: {
          update: (opts) => this.updateRestaurant({
            fields: [opts],
            kitchen_uuid: this.launch.kitchen_uuid,
            restaurant_uuid: restaurant.uuid,
          }).then((updateRestaurantResponse) => {
            if (updateRestaurantResponse && updateRestaurantResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
        })
      },
        props: {
          restaurantUUID: restaurant.uuid
        }
      })
    },
    openDeliverooTechAccountForm: function(task) {
      this.$buefy.modal.open({
        component: DeliverooTechAccountForm,
        parent: this,
        hasModalCard: true,
        events: {
          update: (opts) => this.updateKitchen({
            fields: [opts],
            kitchen_uuid: this.launch.kitchen_uuid,
            }).then((updateKitchenResponse) => {
              if (updateKitchenResponse && updateKitchenResponse.status == 'OK') {
                var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
                var platform_uuid = taskGroup.launch_conditions[0].value;
                var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
                this.updateRestaurant({
                  fields: [{
                    'secrets': {
                      'deliveroo_back_office_mail': opts.kitchen_information.deliveroo_mail,
                      'deliveroo_back_office_password': opts.kitchen_information.deliveroo_back_office_password,
                    }
                  }],
                  kitchen_uuid: this.launch.kitchen_uuid,
                  restaurant_uuid: restaurant.uuid,
                }).then((updateRestaurantResponse) => {
                  if (updateRestaurantResponse && updateRestaurantResponse.status == 'OK') {
                    this.updateTask(
                      {
                        launchUUID: this.launch.uuid,
                        taskUUID: task.uuid,
                        is_done: true,
                      }
                    )
                  }
                })
              }
          }),
          updateExisting: () => {
            var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
            var platform_uuid = taskGroup.launch_conditions[0].value;
            var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
            this.updateRestaurant({
              fields: [{
                'secrets': {
                  'deliveroo_back_office_mail': this.kitchen.mails.mail_deliveroo_tech,
                  'deliveroo_back_office_password': this.kitchen.secrets.password_deliveroo_back_office,
                }
              }],
              kitchen_uuid: this.launch.kitchen_uuid,
              restaurant_uuid: restaurant.uuid,
            }).then((updateRestaurantResponse) => {
              if (updateRestaurantResponse && updateRestaurantResponse.status == 'OK') {
                this.updateTask(
                  {
                    launchUUID: this.launch.uuid,
                    taskUUID: task.uuid,
                    is_done: true,
                  }
                )
              }
            })
          },
          updateExternal: (opts) => {
            var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
            var platform_uuid = taskGroup.launch_conditions[0].value;
            var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
            this.updateRestaurant({
              fields: [{
                'secrets': {
                  'deliveroo_back_office_mail': opts.username,
                  'deliveroo_back_office_password': opts.password,
                }
              }],
              kitchen_uuid: this.launch.kitchen_uuid,
              restaurant_uuid: restaurant.uuid,
            }).then((updateRestaurantResponse) => {
              if (updateRestaurantResponse && updateRestaurantResponse.status == 'OK') {
                this.updateTask(
                  {
                    launchUUID: this.launch.uuid,
                    taskUUID: task.uuid,
                    is_done: true,
                  }
                )
              }
            })
          }
        },
        props: {kitchen: this.kitchen, launch: this.launch}
      })
    },
    openPOSMailForm: function(task) {
      var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
      var platform_uuid = taskGroup.launch_conditions[0].value;
      var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
      this.$buefy.modal.open({
        component: UberPOSMailForm,
        parent: this,
        hasModalCard: true,
        events: {
          update: (sender) => this.activatePOSIntegration({
            sender: sender,
            restaurant_uuid: restaurant.uuid,
            platformUUID: platform_uuid
          }).then((activatePOSIntegrationResponse) => {
            if (activatePOSIntegrationResponse && activatePOSIntegrationResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
        })
      },
        props: {
          restaurantUUID: restaurant.uuid
        }
      })
    },
    openBackOfficeForm: function(task) {
      this.$buefy.modal.open({
        component: BackOfficeForm,
        parent: this,
        hasModalCard: true,
        events: {
          update: (opts) => {
            var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
            var platform_uuid = taskGroup.launch_conditions[0].value;
            var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
            this.updateRestaurant({
              fields: [{
                'secrets': {
                  'deliveroo_back_office_mail': opts.username,
                  'deliveroo_back_office_password': opts.password,
                }
              }],
              kitchen_uuid: this.launch.kitchen_uuid,
              restaurant_uuid: restaurant.uuid,
            }).then((updateRestaurantResponse) => {
              if (updateRestaurantResponse && updateRestaurantResponse.status == 'OK') {
                this.updateTask(
                  {
                    launchUUID: this.launch.uuid,
                    taskUUID: task.uuid,
                    is_done: true,
                  }
                )
              }
            })
          }
        },
        props: {kitchen: this.kitchen}
      })
    },
    openDeliverooPOSForm: function(task) {
      var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
      var platform_uuid = taskGroup.launch_conditions[0].value;
      var restaurant = this.kitchen.restaurants.find(restaurant => restaurant.concept_uuid == this.launch.concept_uuid && restaurant.platform_uuid == platform_uuid && !restaurant.is_deleted);
      this.$buefy.modal.open({
        component: PosDeliverooForm,
        parent: this,
        hasModalCard: true,
        events: {
          update: () => {
            this.updateTask(
              {
                launchUUID: this.launch.uuid,
                taskUUID: task.uuid,
                is_done: true,
              }
            )
          },
          send: (sender) => this.activatePOSIntegration({
            sender: sender,
            restaurant_uuid: restaurant.uuid,
            platformUUID: platform_uuid
          }).then((activatePOSIntegrationResponse) => {
            if (activatePOSIntegrationResponse && activatePOSIntegrationResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          }),
          sendAuto: () => this.activatePOSIntegration({
            auto: true,
            restaurant_uuid: restaurant.uuid,
            platformUUID: platform_uuid
          }).then((activatePOSIntegrationResponse) => {
            if (activatePOSIntegrationResponse && activatePOSIntegrationResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          })
        },
        props: {kitchen: this.kitchen, launch: this.launch, restaurant: restaurant}
      })
    },
    openSubsitutesForm: function(task) {
      this.$buefy.modal.open({
        component: SubstitutesForm,
        parent: this,
        hasModalCard: true,
        props: {
          conceptUUID: this.launch.concept_uuid,
          kitchenCountryUUID: this.kitchen.kitchen_country.uuid,
          kitchenUUID: this.launch.kitchen_uuid,
          locationUUID: this.launch.location_uuid
        },
        events: {
          setUpSubstitutes: (opts) => this.setUpSubstitutesRules(opts).then((setUpSubstitutesRulesResponse) => {
            if (setUpSubstitutesRulesResponse && setUpSubstitutesRulesResponse.status == 'OK') {
              this.updateTask(
                {
                  launchUUID: this.launch.uuid,
                  taskUUID: task.uuid,
                  is_done: true,
                }
              )
            }
          }),
        },
      })
    },
    openTransferRestaurantForm: function(task) {
      var taskGroup = this.taskGroups.find(taskGroup => task.task_group_uuid == taskGroup.uuid);
      var platformUUID = taskGroup.launch_conditions[0].value;
      this.$buefy.modal.open({
        component: TransferRestaurantForm,
        parent: this,
        hasModalCard: true,
        props: {
          conceptUUID: this.launch.concept_uuid,
          kitchenID: this.kitchen.id,
          kitchenCountryUUID: this.kitchen.kitchen_country.uuid,
          kitchenUUID: this.launch.kitchen_uuid,
          locationUUID: this.launch.location_uuid,
          platformUUID: platformUUID,
          launchUUID: this.launch.uuid,
          taskUUID: task.uuid,
        },
        events: {},
      })
    },
    updateManagerInformation(task, managerInformation) {
      var concept = this.concepts.find(concept => concept.uuid == this.launch.concept_uuid);
      this.createMail(
        managerInformation.kitchenMail,
        'kitchen',
        null,
        null,
        concept
      ).then(() => {
        Promise.all(
          [
            this.createMail(
              managerInformation.managerMail,
              'manager',
              managerInformation.first_name,
              managerInformation.last_name,
              concept
            ),
            this.grantAccess(
              managerInformation.managerMail,
              'manager',
              managerInformation.first_name,
              managerInformation.last_name,
              concept
            ),
            this.grantAccess(
            managerInformation.kitchenMail,
            'kitchen',
            'Kitchen',
            managerInformation.kitchen_name,
            concept
          ),
          ]
        ).then((responses) => {
          if (responses.every(response => response.status == 'OK')) {
            this.updateTask(
              {
                launchUUID: this.launch.uuid,
                taskUUID: task.uuid,
                is_done: true,
              }
            )
          }
        })
       }
      )
    },
    createMail(mail, mailType, firstName, lastName, concept){
      var body = {
        'add_mail_to_google_franchise_group': concept.is_internal,
        'mail_type': mailType,
        'mail': mail,
        'kitchen_uuid': this.kitchen.uuid,
      };
      if (firstName) {
        body['first_name'] = firstName;
      }
      if (lastName) {
        body['last_name'] = lastName;
      }
      if (mail) {
        return this.createMailAction(body);
      }
      else {
        var p = new Promise(function(resolve, reject) {
          if(true) { // eslint-disable-line no-constant-condition
            resolve('Success!');
          }
          else {
            reject('Failure!');
          }
        });
        return p
      }
    },
    grantAccess(mail, mailType, firstName, lastName, concept) {
      var body = {
        'access_type': mailType,
        'concept_uuid': concept.uuid,
        'kitchen_uuid': this.kitchen.uuid,
        'mail': mail,
      };
      if (firstName) {
        body['first_name'] = firstName;
      }
      if (lastName) {
        body['last_name'] = lastName;
      }
      if (mail) {
        return this.grantIAMAccessAction(body);
      }
      else {
        var p = new Promise(function(resolve, reject) {
          if(true) { // eslint-disable-line no-constant-condition
            resolve('Success!');
          }
          else {
            reject('Failure!');
          }
        });
        return p
      }
    },
    updateIntercomInformation(task) {
      this.createIntercomInformationAction(
        {
          kitchen_uuid: this.launch.kitchen_uuid,
        }
      ).then((response) => {
          if (response.status == 'OK') {
            this.updateTask(
              {
                launchUUID: this.launch.uuid,
                taskUUID: task.uuid,
                is_done: true,
              }
            )
          }
        })
    },
    displayError(errorMessage) {
      this.$buefy.toast.open({
          duration: 5000,
          message: errorMessage,
          type: 'is-danger'
      })
    }
  },
  mounted() {
    this.setFilters();
  },
}
</script>

<style>
.is-sticky-column-one {
    background: #23d160 !important;
    color: white !important;
}
.is-sticky-column-two {
    background: #167df0 !important;
    color: white !important;
}
</style>
