var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box modal-card", staticStyle: { overflow: "visible" } },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("p", { staticClass: "title is-4" }, [_vm._v("Actions")]),
      _c(
        "div",
        { staticClass: "is-flex-direction-column is-justify-content-flex-end" },
        [
          _c(
            "div",
            { staticClass: "has-text-centered" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button-gap",
                  attrs: { type: "is-success", expanded: "" },
                  on: {
                    click: function ($event) {
                      return _vm.openOnboardingNewModal()
                    },
                  },
                },
                [_vm._v(" New Opening ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "has-text-centered" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button-gap",
                  attrs: { type: "is-warning", expanded: "" },
                  on: {
                    click: function ($event) {
                      return _vm.openReopenModal()
                    },
                  },
                },
                [_vm._v(" Re-Opening ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }