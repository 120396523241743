var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("b-loading", {
        attrs: { active: _vm.isSettingCurrent },
        on: {
          "update:active": function ($event) {
            _vm.isSettingCurrent = $event
          },
        },
      }),
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.campaignsInformations,
            striped: true,
            hoverable: true,
            "sticky-header": false,
            "show-detail-icon": true,
            detailed: "",
            paginated: "",
            "backend-pagination": "",
            total: _vm.campaignsTotal,
            "per-page": _vm.perPage,
            "current-page": _vm.page,
          },
          on: {
            "page-change": _vm.onPageChange,
            "details-open": function (row, index) {
              return _vm.fetchCampaignElements(row)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "label",
                        label: "Label",
                        width: _vm.columnWidth,
                      },
                    },
                    [_vm._v(" " + _vm._s(props.row.label) + " ")]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "country",
                        label: "Country",
                        width: _vm.columnWidth,
                      },
                    },
                    [_vm._v(" " + _vm._s(props.row.country) + " ")]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "concept",
                        label: "Concept",
                        width: _vm.columnWidth,
                      },
                    },
                    [
                      props.row.concept_internal
                        ? _c("div", [
                            _c("img", {
                              staticClass: "concept-logo",
                              attrs: {
                                src: props.row.concept_logo,
                                alt: props.row.concept_label,
                                onerror: "this.style.display='none'",
                              },
                            }),
                          ])
                        : _c("div", [
                            _vm._v(" " + _vm._s(props.row.concept_label) + " "),
                          ]),
                    ]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "platform",
                        label: "Platform",
                        width: _vm.columnWidth,
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "concept-logo",
                        attrs: {
                          src: props.row.platform_logo,
                          alt: props.row.platform_label,
                          onerror: "this.style.display='none'",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "event_label",
                        label: "Event Label",
                        width: _vm.columnWidth,
                      },
                    },
                    [_vm._v(" " + _vm._s(props.row.eventName) + " ")]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "start_date",
                        label: "Start Date",
                        width: _vm.columnWidth,
                        "custom-sort": _vm.sortByDate,
                      },
                    },
                    [_vm._v(" " + _vm._s(props.row.startDate) + " ")]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "end_date",
                        label: "End Date",
                        width: _vm.columnWidth,
                        "custom-sort": _vm.sortByDate,
                      },
                    },
                    [_vm._v(" " + _vm._s(props.row.endDate) + " ")]
                  ),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Actions" } },
                    [
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            hoverable: "",
                            "aria-role": "list",
                            position: "is-bottom-left",
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button is-info is-small",
                              attrs: { slot: "trigger" },
                              slot: "trigger",
                            },
                            [_c("span", [_vm._v("Actions")])]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { "aria-role": "listitem" },
                              on: {
                                click: function ($event) {
                                  return _vm.openCampaignModal(
                                    props.row.campaignUUID
                                  )
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: { icon: "pencil", size: "is-small" },
                              }),
                              _vm._v(" Edit "),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { "aria-role": "listitem" },
                              on: {
                                click: function ($event) {
                                  return _vm.duplicate(props.row.campaignUUID)
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: { icon: "qrcode", size: "is-small" },
                              }),
                              _vm._v(" Duplicate "),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { "aria-role": "listitem" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteCampaign(
                                    props.row.campaignUUID
                                  )
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: { icon: "delete", size: "is-small" },
                              }),
                              _vm._v(" Delete "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "detail",
              fn: function (campaign_props) {
                return [
                  _c(
                    "b-field",
                    { attrs: { grouped: "" } },
                    [
                      _c(
                        "b-field",
                        {
                          staticStyle: { "justify-content": "center" },
                          attrs: { label: "Campaign Type", expanded: "" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(campaign_props.row.campaign_type) + " "
                          ),
                        ]
                      ),
                      _c(
                        "b-field",
                        {
                          staticStyle: { "justify-content": "center" },
                          attrs: { label: "Campaign Scope", expanded: "" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(campaign_props.row.campaign_scope) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "b-field",
                        {
                          staticStyle: { "justify-content": "center" },
                          attrs: { label: "Max Optin Date", expanded: "" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(campaign_props.row.maxOptinDate) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.isDiscountCampaign(campaign_props.row)
                    ? _c(
                        "b-field",
                        { attrs: { grouped: "" } },
                        [
                          _c(
                            "b-field",
                            {
                              staticStyle: { "justify-content": "center" },
                              attrs: { label: "Discount Type", expanded: "" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(campaign_props.row.discount_type) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "b-field",
                            {
                              staticStyle: { "justify-content": "center" },
                              attrs: { label: "Discount Value", expanded: "" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(campaign_props.row.discount_value) +
                                  " "
                              ),
                            ]
                          ),
                          _vm.isOverCampaign(campaign_props.row)
                            ? _c(
                                "b-field",
                                {
                                  staticStyle: { "justify-content": "center" },
                                  attrs: {
                                    label: "Discount Over",
                                    expanded: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(campaign_props.row.discount_over) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-field",
                    { attrs: { grouped: "" } },
                    [
                      _vm.isDiscountItemCampaign(campaign_props.row)
                        ? _c(
                            "b-field",
                            {
                              attrs: {
                                label: "Items",
                                position: "is-centered",
                              },
                            },
                            [
                              _c(
                                "b-taglist",
                                _vm._l(
                                  _vm.getCampaignItems(campaign_props.row),
                                  function (item, index) {
                                    return _c(
                                      "b-tag",
                                      {
                                        key: index,
                                        attrs: { type: "is-info" },
                                      },
                                      [_vm._v(" " + _vm._s(item.label) + " ")]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { attrs: { grouped: "" } },
                    [
                      _c(
                        "b-field",
                        {
                          staticStyle: { "justify-content": "center" },
                          attrs: {
                            label: "Estimated Uplift (%)",
                            expanded: "",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(campaign_props.row.estimated_uplift) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "b-field",
                        {
                          staticStyle: { "justify-content": "center" },
                          attrs: { label: "Budget", expanded: "" },
                        },
                        [_vm._v(" " + _vm._s(campaign_props.row.budget) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: { icon: "emoticon-sad", size: "is-large" },
                      }),
                    ],
                    1
                  ),
                  _c("p", [_vm._v("Nothing here.")]),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }