import countriesAPI from '../../../apis/kitchen/countries';
import { countriesTypes } from '../../mutation-types.js'

/* eslint-disable */

const state = {
  all: [],
  current_error: '',
  fetchAll: {
    pending: false,
    error: null,
  },
};

const getters = {
}

const actions = {
  async getCountries({ commit }, queryParameters) {
    commit(countriesTypes.FETCH_ALL_REQUEST);
    return countriesAPI.getCountries(queryParameters)
    .then(function (countries) {
      commit(countriesTypes.SET_COUNTRIES, countries)
    })
    .catch(function (error) {
      commit(countriesTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  resetCurrentError({ commit }) {
    commit(countriesTypes.SET_CURRENT_ERROR, '')
  },
}

const mutations = {
  [countriesTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [countriesTypes.SET_COUNTRIES] (state, countries) {
    state.all = countries
    state.fetchAll.pending = false;
  },
  [countriesTypes.SET_CURRENT_ERROR] (state, error) {
    state.current_error = error
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
