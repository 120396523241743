<template>
  <div class="box modal-card" style="overflow-y: auto;">
    <div class="columns">
      <div class="column is-narrow">
        <p class="title is-4"> Tasks </p>
      </div>
    </div>
    <div v-if="taskGroupTasks && taskGroupTasks.length > 0">
      <h6 class="is-italic"> You can grab task with &#x2630; to edit the order of tasks.</h6>
      <br>
      <Container
        @drop="onDrop"
        drag-handle-selector=".column-drag-handle"
      >
        <Draggable v-for="task in taskGroupTasks" :key="task.uuid">
          <b-field class="modifier has-text-centered" grouped>
            <h3 class="column-drag-handle is-size-9">
              &#x2630;
            </h3>
            <b-field>
              <h3 style="margin-left:10px;">
                {{task.label}}
              </h3>
            </b-field>
            <!-- <b-field>
              <b-button size="is-small is-danger" @click="remove(task.uuid)">
                Remove
              </b-button>
            </b-field> -->
          </b-field>
        </Draggable>
      </Container>
    </div>
    <hr/>
    <b-button expanded type="button is-success" @click="updateTaskGroup">Update</b-button>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd';
import { mapActions, mapMutations, mapState } from 'vuex';
import { taskGroupsTypes } from '@/store/mutation-types';

export default {
  components: { Container, Draggable },
  data() {
    return {
      taskGroupSorted: null,
    }
  },
  props: ['taskGroup', 'tasks', 'taskGroups'],
  computed: {
    ...mapState({
        current: (state) => state.taskGroups.currentTaskGroupTasks,
    }),
    taskGroupTasks: {
      get() {
        if (this.current) {
          return this.current
        }
        var tasks = [];
        for (let taskUUID of this.taskGroup.task_uuids) {
          var task = this.tasks.find(task => task.uuid == taskUUID);
          tasks.push(task)
        }
        return tasks;
      },
      set(value) {
        this.setCurrent(
          value
        )
      },
    },
  },
  methods: {
    ...mapActions('taskGroups', ['updateOne']),
    ...mapMutations('taskGroups', { setCurrent: taskGroupsTypes.SET_CURRENT_TASKS }),
    onDrop(dropResult) {
      this.taskGroupTasks = this.applyDrag(this.taskGroupTasks, dropResult);
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      const result = [...arr];
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },
    updateTaskGroupOrder() {
      this.updateOne(
        {
          task_uuids: this.current.map((task) => task.uuid),
          uuid: this.taskGroup.uuid
        }
      ).then(() => this.$emit('close'))
    }
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>

<style lang="scss" scoped>
.modifier {
  &-fields {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    min-width: 0%;

    &-label {
      flex: 0.7 1 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-price {
      flex: 0.3 1 0;
    }
  }
}
</style>
