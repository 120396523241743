<template>
  <div>
    <b-modal :active.sync="openModal"
             has-modal-card
             :on-cancel="close">
      <section>
          <div class="card" style="width: auto">
            <div class="card-content">
              <div class="media">
                <div class="media-left" v-if="creationModal===false">
                    <figure class="image is-48x48">
                        <img onerror="this.style.display='none'" :src="concept.logo" alt="Image">
                    </figure>
                </div>
                <div class="media-content">
                  <p v-if="concept.label" p class="title is-4">{{concept.label}}</p>
                  <p v-if="concept.label=='' && creationModal" class="title is-4">{{ $t('concepts.add') }}</p>
                </div>
              </div>
            </div>
              <section class="card-content">
                <b-field grouped>
                  <b-field class="control" label="ID" label-position="inside">
                    <b-input
                      v-model="concept.id"
                      type="text"
                      required
                      placeholder="Concept Id">
                    </b-input>
                  </b-field>
                </b-field>
                <b-field label="Label" label-position="inside">
                    <b-input
                      type="text"
                      required
                      v-model="concept.label"
                      placeholder="Concept Label"></b-input>
                </b-field>
                <b-field label="Category">
                  <b-select
                      type="text"
                      v-model="concept.category"
                      placeholder="Kitchen Franchise" expanded>
                      <option v-for="(conceptCategory, index) in conceptCategories" :key="index" :value="conceptCategory">
                        {{conceptCategory}}
                      </option>
                  </b-select>
                </b-field>
              </section>
              <footer class="card-foot buttons is-right">
                  <b-button v-if="creationModal" type="button is-small is-outlined" :disabled="createOrUpdateDisabled" @click="create">{{ $t('concepts.create') }}</b-button>
                  <b-button v-if="creationModal===false" type="button is-small is-outlined" :disabled="createOrUpdateDisabled" @click="update">{{ $t('concepts.update') }}</b-button>
                  <b-button type="button is-danger is-small is-outlined" @click="close">{{ $t('concepts.close') }}</b-button>
              </footer>
          </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ['creationModal', 'concept', 'conceptSchema', 'conceptCategories'],
  data() {
    return {
      openModal: true,
    }
  },
  computed: {
    createOrUpdateDisabled: function(){
      if(this.concept.label.length < this.conceptSchema.label.minLength ||
         this.concept.label.length > this.conceptSchema.label.maxLength ||
         this.concept.id.length < this.conceptSchema.id.minLength ||
         this.concept.id.length > this.conceptSchema.id.maxLength ||
         ! this.concept.category) {
        return true
      }
      return false
    }
  },
  methods: {
    create() {
      this.$emit('created', {
        category: this.concept.category,
        id: this.concept.id,
        label: this.concept.label,
      });
      this.$parent.close();
    },
    update() {
      var updatedConcept = {
          category: this.concept.category,
          id: this.concept.id,
          is_deleted: this.concept.is_deleted,
          label: this.concept.label,
      };
      this.$emit('updated',
        this.concept.uuid,
        updatedConcept
      );
      this.$parent.close();
    },
    close() {
      if (this.creationModal) {
        this.$emit('creationclosed', {});
      }
      else{
        this.$emit('updateclosed', {});
      }
      this.$parent.close();
    },
  }
}
</script>

<style>
 .field:not(:last-child){
   margin-bottom:0px;
 }
</style>
