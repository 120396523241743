var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-dropdown",
        {
          attrs: { multiple: "", "aria-role": "list" },
          model: {
            value: _vm.selectedConcepts,
            callback: function ($$v) {
              _vm.selectedConcepts = $$v
            },
            expression: "selectedConcepts",
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "button is-primary",
              attrs: { slot: "trigger", type: "button" },
              slot: "trigger",
            },
            [
              _c("span", [
                _vm._v(
                  "Concepts (" + _vm._s(_vm.selectedConcepts.length) + ")"
                ),
              ]),
              _c("b-icon", { attrs: { icon: "menu-down" } }),
            ],
            1
          ),
          _vm._l(_vm.activeConcepts, function (concept, index) {
            return _c(
              "b-dropdown-item",
              {
                key: index,
                attrs: { value: concept, "aria-role": "listitem" },
              },
              [_c("span", [_vm._v(" " + _vm._s(concept.label) + " ")])]
            )
          }),
        ],
        2
      ),
      _c(
        "b-dropdown",
        {
          attrs: { multiple: "", "text-align": "center" },
          model: {
            value: _vm.selectedPlatforms,
            callback: function ($$v) {
              _vm.selectedPlatforms = $$v
            },
            expression: "selectedPlatforms",
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "button is-primary",
              attrs: { slot: "trigger", type: "button" },
              slot: "trigger",
            },
            [
              _c("span", [
                _vm._v(
                  "Platforms (" + _vm._s(_vm.selectedPlatforms.length) + ")"
                ),
              ]),
              _c("b-icon", { attrs: { icon: "menu-down" } }),
            ],
            1
          ),
          _vm._l(_vm.activePlatforms, function (platform, index) {
            return _c(
              "b-dropdown-item",
              {
                key: index,
                attrs: { value: platform, "aria-role": "listitem" },
              },
              [_c("span", [_vm._v(" " + _vm._s(platform.label) + " ")])]
            )
          }),
        ],
        2
      ),
      _c(
        "b-dropdown",
        {
          attrs: { multiple: "", "text-align": "center" },
          model: {
            value: _vm.selectedKitchenStatuses,
            callback: function ($$v) {
              _vm.selectedKitchenStatuses = $$v
            },
            expression: "selectedKitchenStatuses",
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "button is-primary",
              attrs: { slot: "trigger", type: "button" },
              slot: "trigger",
            },
            [
              _c("span", [
                _vm._v(
                  " Kitchen Owner (" +
                    _vm._s(_vm.selectedKitchenStatuses.length) +
                    ")"
                ),
              ]),
              _c("b-icon", { attrs: { icon: "menu-down" } }),
            ],
            1
          ),
          _vm._l(_vm.kitchenStatusKeys, function (kitchenStat, index) {
            return _c(
              "b-dropdown-item",
              {
                key: index,
                attrs: { value: kitchenStat, "aria-role": "listitem" },
              },
              [_c("span", [_vm._v(" " + _vm._s(kitchenStat) + " ")])]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }