<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <section class="modal-card-body has-text-centered">
      <b-field grouped>
        <b-field label="Hub" expanded>
          <b-select
            placeholder="Hub"
            v-model="hub"
            expanded>
            <option v-for="(hub, index) in hubs" :key="index" :value="hub">
              {{ hub.label }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Country" expanded>
          <b-select
            placeholder="Country"
            v-model="countryLocation"
            expanded>
            <option v-for="(countryLocation, index) in countryLocations" :key="index" :value="countryLocation">
              {{ countryLocation.id }}
            </option>
          </b-select>
        </b-field>
      </b-field>
    </section>
      <b-button expanded :disabled="countryLocation == null || hub == null" type="button is-success" @click="upload">Export</b-button>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      countryLocation: null,
      spreadsheetURL: null,
      hub: null,
    }
  },
  props: ['countryLocations', 'hubs'],
  computed: {},
  methods: {
    upload() {
      this.$emit('update', {location_uuid: this.countryLocation.uuid, hub_uuid: this.hub.uuid});
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
