var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "has-text-centered" },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _vm.users && _vm.users.length > 0
        ? _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                { attrs: { label: "Sales Manager", expanded: "" } },
                [
                  _c("b-autocomplete", {
                    attrs: {
                      rounded: "",
                      field: "name",
                      placeholder: "Select Sales Manager",
                      icon: "magnify",
                      clearable: "",
                      data: _vm.filteredUsersSales,
                      "open-on-focus": true,
                      "clear-on-select": true,
                    },
                    on: {
                      select: function (option) {
                        return _vm.selectUser(option, "SM")
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "empty",
                          fn: function () {
                            return [_vm._v("No results found")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      478364837
                    ),
                    model: {
                      value: _vm.salesManagerLabel,
                      callback: function ($$v) {
                        _vm.salesManagerLabel = $$v
                      },
                      expression: "salesManagerLabel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "Area Manager", expanded: "" } },
                [
                  _c("b-autocomplete", {
                    attrs: {
                      rounded: "",
                      field: "name",
                      placeholder: "Select Area Manager",
                      icon: "magnify",
                      clearable: "",
                      data: _vm.filteredUsersAccount,
                      "open-on-focus": true,
                      "clear-on-select": true,
                    },
                    on: {
                      select: function (option) {
                        return _vm.selectUser(option, "AM")
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "empty",
                          fn: function () {
                            return [_vm._v("No results found")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      478364837
                    ),
                    model: {
                      value: _vm.accountManagerLabel,
                      callback: function ($$v) {
                        _vm.accountManagerLabel = $$v
                      },
                      expression: "accountManagerLabel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "Launcher", expanded: "" } },
                [
                  _c("b-autocomplete", {
                    attrs: {
                      rounded: "",
                      field: "name",
                      placeholder: "Select Launcher",
                      icon: "magnify",
                      clearable: "",
                      data: _vm.filteredUsersOperation,
                      "open-on-focus": true,
                      "clear-on-select": true,
                    },
                    on: {
                      select: function (option) {
                        return _vm.selectUser(option, "OM")
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "empty",
                          fn: function () {
                            return [_vm._v("No results found")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      478364837
                    ),
                    model: {
                      value: _vm.operationManagerLabel,
                      callback: function ($$v) {
                        _vm.operationManagerLabel = $$v
                      },
                      expression: "operationManagerLabel",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-field",
        {
          staticStyle: { "justify-content": "center" },
          attrs: { label: "Category (Optional)", expanded: "" },
        },
        [
          _c(
            "b-select",
            {
              attrs: { type: "text", placeholder: "Category", expanded: "" },
              model: {
                value: _vm.management_information.category,
                callback: function ($$v) {
                  _vm.$set(_vm.management_information, "category", $$v)
                },
                expression: "management_information.category",
              },
            },
            _vm._l(_vm.kitchenCategories, function (kitchenCategory, index) {
              return _c(
                "option",
                { key: index, domProps: { value: kitchenCategory } },
                [_vm._v(" " + _vm._s(kitchenCategory) + " ")]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "b-button",
        {
          attrs: {
            expanded: "",
            type: "button is-success",
            disabled: _vm.isDisabled,
          },
          on: { click: _vm.createLaunchProcess },
        },
        [_vm._v("Create Launch Process")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }