import axios from '@/axios';
import { baseOnboardingUrl, baseOnboardingUrlV2 } from '@/apis'


const ressourcePath = `${baseOnboardingUrl}/actions`;
const ressourcePathV2 = `${baseOnboardingUrlV2}/actions`;

export default {
/* eslint-disable */
  activatePOSIntegration(opts) {
    var platformUUID = opts.platformUUID;
    delete opts.platformUUID;
    if (platformUUID == '15982f81-da4f-4035-b528-740f9664e5bd') {
      return axios.post(`${ressourcePath}/activate_uber_pos_integration`, opts)
                  .then(response => response.data)
    }
    if (platformUUID == '712312b2-52df-4661-8f5e-1dc526ed2a48') {
      return axios.post(`${ressourcePathV2}/activate_glovo_pos_integration`, opts)
                  .then(response => response.data)
    }
    if (platformUUID == 'bf02c476-5512-4f33-8556-a6700049274c') {
      delete opts.sender;
      return axios.post(`${ressourcePathV2}/activate_justeat_pos_integration`, opts)
                  .then(response => response.data)
    }
    if (platformUUID == '98fee123-068c-487a-af61-efcee6cb85fe') {
      return axios.post(`${ressourcePathV2}/activate_deliveroo_pos_integration`, opts)
                  .then(response => response.data)
    }
  },
  checkPOSIntegration(opts) {
    return axios.post(`${ressourcePathV2}/check_pos_integration`, opts)
                .then(response => response.data)
  },
  createKitchen(opts) {
    return axios.post(`${ressourcePathV2}/create_kitchen`, opts)
                .then(response => response.data)
  },
  createRestaurant(opts) {
    return axios.post(`${ressourcePathV2}/create_restaurant`, opts)
                .then(response => response.data)
  },
  createMail(opts) {
    return axios.post(`${ressourcePath}/create_mail`, opts)
                .then(response => response.data)
  },
  importIAMLocationAction(opts) {
    return axios.post(`${ressourcePathV2}/import_location`, opts)
                .then(response => response.data)
  },
  fetchLaunchTeams(kitchenUUID) {
    return axios.get(`${ressourcePathV2}/get_launch_teams?kitchen_uuid=${kitchenUUID}`)
                .then(response => response.data)
  },
  grantIAMAccessAction(opts) {
    return axios.post(`${ressourcePath}/grant_iam_access`, opts)
                .then(response => response.data)
  },
  updateKitchenAction(opts) {
    return axios.post(`${ressourcePath}/update_kitchen`, opts)
                .then(response => response.data)
  },
  updateRestaurantAction(opts) {
    return axios.post(`${ressourcePath}/update_restaurant`, opts)
                .then(response => response.data)
  },
  copyMenu(opts) {
    return axios.post(`${ressourcePathV2}/create_menu`, opts)
                .then(response => response.data)
  },
  copyRecipes(opts) {
    return axios.post(`${ressourcePath}/copy_recipes`, opts)
                .then(response => response.data)
  },
  createForecasts(opts) {
    return axios.post(`${ressourcePath}/create_forecasts`, opts)
                .then(response => response.data)
  },
  copyProductsInformations(opts) {
    return axios.post(`${ressourcePath}/copy_products_informations`, opts)
                .then(response => response.data)
  },
  setUpFakeOpeningHours(opts) {
    return axios.post(`${ressourcePath}/set_up_fake_opening_hours`, opts)
                .then(response => response.data)
  },
  setUpHolidays(opts) {
    return axios.post(`${ressourcePath}/set_up_holidays`, opts)
                .then(response => response.data)
  },
  updateOpeningHours(opts) {
    return axios.post(`${ressourcePathV2}/update_opening_hours`, opts)
                .then(response => response.data)
  },
  uploadMenu(opts) {
    return axios.post(`${ressourcePathV2}/upload_menu`, opts)
                .then(response => response.data)
  },
  configureTasterRestaurant(opts) {
    return axios.post(`${ressourcePath}/configure_foodkit_restaurant`, opts)
                .then(response => response.data)
  },
  createClickAndCollectRestaurant(opts) {
    return axios.post(`${ressourcePathV2}/create_click_and_collect_restaurant`, opts)
                .then(response => response.data)
  },
  openRestaurants(opts) {
    return axios.post(`${ressourcePathV2}/open_restaurants`, opts)
                .then(response => response.data)
  },
  closeRestaurants(opts) {
    return axios.post(`${ressourcePathV2}/close_restaurants`, opts)
                .then(response => response.data)
  },
  configureUATEnvironment(opts) {
    return axios.post(`${ressourcePathV2}/configure_uat_environment`, opts)
                .then(response => response.data)
  },
  exportMenu(opts) {
    return axios.post(`${ressourcePathV2}/export_platform_menu`, opts)
                .then(response => response.data)
  },
  importDummyMenu(opts) {
    return axios.post(`${ressourcePathV2}/import_dummy_menu`, opts)
                .then(response => response.data)
  },
  migrateMenu(opts) {
    return axios.post(`${ressourcePathV2}/migrate_menu`, opts)
                .then(response => response.data)
  },
  setUpSubstitutesRules(opts) {
    return axios.post(`${ressourcePathV2}/set_up_substitutes_rules`, opts)
                .then(response => response.data)
  },
  transferRestaurant(opts) {
    return axios.post(`${ressourcePathV2}/transfer_restaurant`, opts)
                .then(response => response.data)
  },
  createIntercomInformation(opts) {
    return axios.post(`${ressourcePathV2}/create_intercom_information`, opts)
                .then(response => response.data)
  },
};
