<template>
  <div class="box modal-card my-modal" style="overflow: visible;">
    <b-loading :active.sync="isLoading"></b-loading>
    <p class="title is-4"> Update Tasks Group's tasks for Launch </p>
    <h6 class="is-italic"> You can add tasks to your actual launch by category</h6>
    <b-table
      :data="activeTaskGroups"
      :striped="true"
      :hoverable="true"
      :scrollable="true"
      :per-page="10"
      :paginated="true"
      :default-sort="['desc']"
      >
      <template slot-scope="props">
        <b-table-column field="label" label="Label" sortable>
            {{ props.row.label }}
        </b-table-column>
        <b-table-column filed="actions" label="Actions">
          <b-button v-if="!isAllTasksFromTaskGroupInLaunch(props.row)" style="margin-left: 10px;" title="delete" class="button is-small is-primary" @click="addLaunchTaskGroupTasks(props.row.uuid)">
            <b-icon icon="refresh" size="is-small"></b-icon>
          </b-button>
          <!-- <b-button v-if="isTaskGroupInLaunch(props.row.uuid)" style="margin-left: 10px;" title="delete" class="button is-small is-danger" @click="deleteLaunchTaskGroupTasks(props.row.uuid)">
            <b-icon icon="delete" size="is-small"></b-icon>
          </b-button> -->
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
  components: {},
  props: [],
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.launches.addTaskGroup.pending || state.launches.removeTaskGroup.pending,
      launch : (state) => state.launches.current,
      taskGroups: (state) => state.taskGroups.all,
    }),
    activeTaskGroups() {
      return this.taskGroups.filter(taskGroup => !taskGroup.is_deleted)
    },
  },
  methods: {
    isAllTasksFromTaskGroupInLaunch(taskGroup) {
      var launchTaskUUIDS = this.launch.tasks.filter(task => task.task_group_uuid == taskGroup.uuid).map(task => task.uuid);
      for(let taskUUID of taskGroup.task_uuids) {
        if (!launchTaskUUIDS.includes(taskUUID)) {
          return false;
        }
      }
      return true;
    },
    isTaskGroupInLaunch(taskGroupUUID) {
      return this.launch.tasks.filter(task => task.task_group_uuid == taskGroupUUID).length > 0
    },
    addLaunchTaskGroupTasks(taskGroupUUID) {
      this.$emit('addLaunchTaskGroupTasks', {
        launchUUID: this.launch.uuid,
        taskGroupUUID: taskGroupUUID
      });
    },
    deleteLaunchTaskGroupTasks(taskGroupUUID) {
      this.$emit('deleteLaunchTaskGroupTasks', {
        launchUUID: this.launch.uuid,
        taskGroupUUID: taskGroupUUID
      });
    },
  }
}
</script>



<style>

.my-modal .modal-close {
  background: red;
}

</style>
