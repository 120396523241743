<template>
  <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close">
    <section class="modal-card-body has-text-centered">
      <b-field grouped>
        <b-field label="Company Name" expanded>
          <b-input
            v-model="companyName"
            type="text"
            required
            placeholder="Company Name"
          >
          </b-input>
        </b-field>
        <b-field label="Company Type" expanded>
          <b-select
            type="text"
            v-model="companyType"
            placeholder="Company Type"
            expanded
          >
            <option v-for="(companyType, index) in companyTypes" :key="index" :value="companyType">
              {{ companyType }}
            </option>
          </b-select>
        </b-field>
      </b-field>
      <b> Company Address </b>
      <b-switch
        v-model="manualCompanyAddressMode"
        passive-type='is-success'
        false-value="Google Map"
        true-value="Manual"
        type='is-danger'
        size='is-small'
      >
        {{ manualCompanyAddressMode }}
      </b-switch>
      <b-field label="" expanded>
        <vue-google-autocomplete
          v-if="manualCompanyAddressMode == 'Google Map'"
          id="mapCompany"
          v-model="companyAddress"
          classname="google-autocomplete"
          v-on:placechanged="getCompanyAddressData"
          :country="['fr', 'uk', 'es', 'be', 'nl']"
        >
        </vue-google-autocomplete>
        <b-input v-else v-model="companyAddress" type="text" placeholder="Address"> </b-input>
      </b-field>
      <b-button expanded :disabled="isUpdateDisable()" type="button is-success" @click="updateValue">Set Up</b-button>
    </section>
  </b-modal>
</template>

<script>
/* eslint-disable */
import VueGoogleAutocomplete from 'vue-google-autocomplete';

  export default {
  components: { VueGoogleAutocomplete },
  data() {
    return {
      openModal: true,
      companyName: this.companyInformations.companyName,
      companyType: this.companyInformations.companyType,
      companyAddress: this.companyInformations.companyAddress,
      companyTypes: ['Enterprise', 'Restaurant'],
      manualCompanyAddressMode: 'Google Map'
    }
  },
  props: ['companyInformations'],
  computed: {},
  methods: {
    getCompanyAddressData: function(place, addressData, _) {
      this.companyAddress = addressData.formatted_address;
    },
    isUpdateDisable() {
      return this.companyName == '' || this.companyType == '' || this.companyName == null || this.companyType == null
    },
    close() {
      this.$emit('close');
    },
    updateValue() {
      this.$emit('updateValue', {
        "company_name": this.companyName,
        "company_type": this.companyType,
        "company_address": this.companyAddress,
      });
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
