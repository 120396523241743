var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        active: _vm.openModal,
        scroll: "keep",
        "full-screen": "",
        "on-cancel": _vm.close,
      },
      on: {
        "update:active": function ($event) {
          _vm.openModal = $event
        },
      },
    },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("section", [
        _c("div", { staticClass: "card", staticStyle: { width: "auto" } }, [
          _c("div", { staticClass: "card-content" }, [
            _c("div", { staticClass: "media" }, [
              _c("div", { staticClass: "media-content" }, [
                _c("p", { staticClass: "title is-4" }, [_vm._v(" Actions")]),
              ]),
            ]),
          ]),
          _c("section", { staticClass: "card-content" }, [
            _c(
              "div",
              {
                staticClass:
                  "is-flex-direction-column is-justify-content-flex-end",
              },
              [
                _vm.isEngineer()
                  ? _c(
                      "div",
                      { staticClass: "has-text-centered" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "button-gap",
                            attrs: {
                              type: "is-primary",
                              expanded: "",
                              disabled: _vm.disableKitchenButton,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openKitchenModal()
                              },
                            },
                          },
                          [_vm._v(" Kitchens ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "has-text-centered" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "button-gap",
                        attrs: {
                          type: "is-link",
                          expanded: "",
                          disabled: _vm.disablePeopleButton,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openPeopleModal()
                          },
                        },
                      },
                      [_vm._v(" People ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }