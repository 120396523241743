var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "has-text-centered" },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.openModal,
            scroll: "keep",
            "on-cancel": _vm.close,
          },
          on: {
            "update:active": function ($event) {
              _vm.openModal = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _vm._v(" Select Kitchens"),
                ]),
                _c("button", {
                  staticClass: "delete",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                }),
              ]),
              _c(
                "section",
                { staticClass: "modal-card-body" },
                [
                  _c(
                    "b-field",
                    [
                      _c("b-autocomplete", {
                        attrs: {
                          rounded: "",
                          size: "is-medium",
                          expanded: "",
                          data: _vm.filteredLocations,
                          "clear-on-select": true,
                          placeholder: "Select Kitchen",
                          icon: "magnify",
                          field: "label",
                          "append-to-body": true,
                          "native-size": "4",
                          "open-on-focus": true,
                        },
                        on: {
                          select: function (option) {
                            return _vm.selectKitchen(option)
                          },
                        },
                        model: {
                          value: _vm.searchTerm,
                          callback: function ($$v) {
                            _vm.searchTerm = $$v
                          },
                          expression: "searchTerm",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }