import Vue from 'vue';
import Keycloak from 'keycloak-js'
import jwt from 'jwt-decode';
const realm = process.env.VUE_APP_IAM_REALM;
const authServerURL = process.env.VUE_APP_IAM_AUTH_SERVER_URL;
const clientId = process.env.VUE_APP_IAM_CLIENT_ID;
let auth = new Vue({
  data: () => ({
    keycloak: null,
    isAuthenticated: false,
    user: null,
    groups: [],
    resourceAccess: {},
  }),
  methods: {
    async signIn() {
      return new Promise((resolve, reject) => {
        this.keycloak.init({
          onLoad: "login-required",
          checkLoginIframe: false,
          responseMode: "query"
        }).then(async (auth) => {
          if (auth) {
            await this.updateUser();
            setInterval(() => {
              this.keycloak.updateToken(60).then((isRefreshed) => {
                if (isRefreshed) {
                  this.updateUser();
                }
              })
            }, 3000);
            resolve()
          }
          else {
            reject("Not able to authenticate the user.")
          }
          this.isAuthenticated = auth;
        })
      });
    },
    logout() {
      this.keycloak.logout();
    },
    getToken() {
      return this.keycloak.token;
    },
    async updateUser() {
      return await new Promise((resolve, reject) => {
        this.keycloak.loadUserInfo().then((userInfo) => {
          this.groups = userInfo.groups;
          this.user = this.keycloak.userInfo;
          this.resourceAccess = this.keycloak.resourceAccess;
          resolve()
        }
        ).catch((error) => {
          reject(error);
        })
      })
    },
    refreshToken() {
      return this.keycloak.updateToken(-1);
    },
    isExpiring(token) {
      var decodedToken = jwt(token)
      var nowTimestamp = Math.floor(Date.now() / 1000)
      var expirationTimestamp = decodedToken.exp
      if ((expirationTimestamp - nowTimestamp) < 60) {
        return true;
      }
      return false;
    },
    getUserGroups() {
      let reg = new RegExp(`/(.+?)/groups/`);
      let matched = this.groups.filter((group) => {
          return group.match(reg);
      });
      let groups = [];
      matched.forEach((group) => {
          let splitted = group.split('/');
          groups.push(splitted[splitted.length - 1]);
      });
      return groups;
    },
    getUserRoles() {
      var roles = this.user ? this.user.realmRoles : [];
      return roles ? roles : [];
    },
  },
  created() {
    this.keycloak = new Keycloak({
      url: authServerURL,
      realm: realm,
      clientId: clientId,
    })
  },
});
export default {
  install: function (Vue) {
    Vue.prototype.$auth = auth
  }
}
