var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card",
      staticStyle: { "overflow-y": "auto", "justify-content": "center" },
    },
    [
      _c(
        "section",
        { staticClass: "modal-card-body has-text-centered" },
        [
          _c("p", { staticClass: "title is-4" }, [
            _vm._v(" Set Up Tablet Information "),
          ]),
          _c(
            "b-steps",
            {
              attrs: { size: "is-small", type: "is-info" },
              model: {
                value: _vm.activeStep,
                callback: function ($$v) {
                  _vm.activeStep = $$v
                },
                expression: "activeStep",
              },
            },
            [
              _c(
                "b-step-item",
                { attrs: { label: "Explanation", step: "1" } },
                [
                  _c("b-table", {
                    attrs: { data: _vm.data },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "b-table-column",
                              { attrs: { field: "id", label: "ID" } },
                              [
                                _c("b-tag", { attrs: { type: "is-info" } }, [
                                  _vm._v(" " + _vm._s(props.row.id) + " "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-table-column",
                              { attrs: { field: "step", label: "Step" } },
                              [_vm._v(" " + _vm._s(props.row.step) + " ")]
                            ),
                            _c(
                              "b-table-column",
                              { attrs: { field: "link", label: "Link" } },
                              [
                                props.row.link
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          tag: "a",
                                          type: "is-link",
                                          href: props.row.link.url,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(props.row.link.label) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-step-item",
                { attrs: { label: "Tablet Information", step: "2" } },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Tablet ID", expanded: "" } },
                    [
                      _c("b-input", {
                        model: {
                          value: _vm.tablet_id,
                          callback: function ($$v) {
                            _vm.tablet_id = $$v
                          },
                          expression: "tablet_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { attrs: { label: "Tablet Password", expanded: "" } },
                    [
                      _c("b-input", {
                        model: {
                          value: _vm.tablet_password,
                          callback: function ($$v) {
                            _vm.tablet_password = $$v
                          },
                          expression: "tablet_password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        expanded: "",
                        disabled:
                          _vm.tablet_id == null || _vm.tablet_password == null,
                        type: "button is-success",
                      },
                      on: { click: _vm.updateValue },
                    },
                    [_vm._v("Update")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }