var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { active: _vm.openModal, "on-cancel": _vm.close },
          on: {
            "update:active": function ($event) {
              _vm.openModal = $event
            },
          },
        },
        [
          _c("div", { staticClass: "card" }, [
            _c("header", { staticClass: "card-header" }, [
              _c("p", { staticClass: "card-header-title" }, [
                _vm._v("Opening hours"),
              ]),
              _c(
                "div",
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        "aria-role": "list",
                        position: "is-bottom-left",
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button is-warning",
                          attrs: { slot: "trigger", type: "button" },
                          slot: "trigger",
                        },
                        [
                          _c("span", [_vm._v("Import")]),
                          _c("b-icon", { attrs: { icon: "menu-down" } }),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.otherKitchenConcepts,
                        function (restaurant, index) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: index,
                              attrs: {
                                value: restaurant,
                                "aria-role": "listitem",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.importOpeningHours(restaurant)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _c("img", {
                                  staticClass: "image",
                                  staticStyle: { "vertical-align": "middle" },
                                  attrs: {
                                    onerror: "this.style.display='none'",
                                    src: restaurant.concept_restaurant.logo,
                                    width: "30",
                                    alt: "Image",
                                  },
                                }),
                                _vm._v(" " + _vm._s(restaurant.label) + " "),
                              ]),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "card-content" }, [
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "table",
                    _vm._l(_vm.daysSorted, function (day, dayIndex) {
                      return _c(
                        "tr",
                        { key: dayIndex },
                        [
                          _c("td", [_vm._v(_vm._s(day))]),
                          _vm._l(_vm.shifts, function (shift, index) {
                            return _c("td", { key: index }, [
                              _vm.daysFormatted[day][shift].length > 0
                                ? _c("div", { staticClass: "columns" }, [
                                    _c(
                                      "div",
                                      { staticClass: "column is-narrow" },
                                      [
                                        _vm.daysFormatted[day][shift].length > 0
                                          ? _c("b-timepicker", {
                                              staticClass: "is-small",
                                              attrs: {
                                                inline: "",
                                                incrementMinutes: _vm.minutes,
                                              },
                                              model: {
                                                value:
                                                  _vm.daysFormatted[day][
                                                    shift
                                                  ][0],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.daysFormatted[day][
                                                      shift
                                                    ],
                                                    0,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "daysFormatted[day][shift][0]",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "column is-narrow" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "columns is-gapless" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "column" },
                                              [
                                                _vm.daysFormatted[day][shift]
                                                  .length > 1
                                                  ? _c("b-timepicker", {
                                                      staticClass: "is-small",
                                                      attrs: {
                                                        inline: "",
                                                        incrementMinutes:
                                                          _vm.minutes,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.daysFormatted[
                                                            day
                                                          ][shift][1],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.daysFormatted[
                                                              day
                                                            ][shift],
                                                            1,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "daysFormatted[day][shift][1]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "column" },
                                              [
                                                _vm.daysFormatted[day][shift]
                                                  .length > 1 &&
                                                dayIndex !=
                                                  _vm.daysSorted.length - 1
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "button is-small is-primary",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.copyShift(
                                                              day,
                                                              dayIndex,
                                                              shift
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "content-copy",
                                                            size: "is-small",
                                                            inline: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.daysFormatted[day][shift]
                                                  .length > 1
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "button is-small is-danger",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeShift(
                                                              day,
                                                              shift
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "minus-circle",
                                                            size: "is-small",
                                                            inline: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.daysFormatted[day][shift].length == 0 &&
                              shift == "lunch_shift"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "button is-small is-info",
                                      attrs: { title: "Add Lunch Shift" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addShift(day, shift)
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "plus",
                                          size: "is-small",
                                        },
                                      }),
                                      _vm._v(" Add lunch shift "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.daysFormatted[day][shift].length == 0 &&
                              shift == "dinner_shift"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "button is-small is-link",
                                      attrs: { title: "Add dinner Shift" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addShift(day, shift)
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "plus",
                                          size: "is-small",
                                        },
                                      }),
                                      _vm._v(" Add dinner shift "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        expanded: "",
                        disabled: _vm.areOpeningHoursEmpty,
                        type: "button is-success",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveOpeningHours()
                        },
                      },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }