<template>
  <div class="box modal-card" style="overflow: visible;">
    <b-loading :active.sync="isLoading"></b-loading>
    <p class="title is-4"> Set Up Substitutes </p>
    <div class="">
        <h6 class="is-italic"> Select the kitchen reference from with substitutes rules will be duplicate from.</h6>
        <hr>
        <b-field>
          <b-field label="Licensee">
            <b-select
              type="text"
              v-model="selectedPartner"
              placeholder="Licensee"
              expanded
            >
              <option v-for="(kitchenStat, index) in kitchenStatus" :key="index" :value="kitchenStat.value">
                {{ kitchenStat.label }}
              </option>
            </b-select>
          </b-field>
          <b-field style="margin-left: 10px;" label="Kitchen" expanded>
            <b-select
              type="text"
              v-model="selectedExisingKitchen"
              placeholder="Kitchen"
              expanded
            >
              <option v-for="(kitchen, index) in getExistingKitchens(kitchenCountryUUID, selectedPartner, locations, kitchens, false, kitchenUUID)" :key="index" :value="kitchen">
                {{ kitchen.id }}
              </option>
            </b-select>
          </b-field>
        </b-field>
        <b-button v-if="selectedPartner != null && selectedExisingKitchen" type="is-info" class="button-gap"  @click="copy(selectedExisingKitchen)" expanded>
          Copy
        </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { utils } from '@/mixins';

export default {
  mixins: [utils],
  components: {
  },
  props: ['conceptUUID', 'kitchenCountryUUID', 'kitchenUUID', 'locationUUID'],
  data() {
    return {
      activeStep: 0,
      kitchenStatus: [
        { label: 'Franchise', value: true },
        { label: 'Taster', value: false },
      ],
      selectedPartner: null,
      selectedExisingKitchen: null,
    }
  },
  computed: mapState({
    countries: (state) => state.countries.all,
    kitchens: (state) =>
      state.kitchens.all.filter(kitchen => !kitchen.is_deleted).sort((kitchen1, kitchen2) =>
        kitchen1.id.localeCompare(kitchen2.id),
    ),
    locations: (state) => state.locations.all,
    isLoading: (state) => state.kitchens.fetchAll.pending,
  }),
  methods: {
    selectMenuType(menuType) {
      this.newMenu = menuType;
      this.activeStep = 1;
    },
    copy(selectedKitchen) {
      this.$emit('setUpSubstitutes', {
          source_location_uuid: selectedKitchen.location_uuid,
          target_location_uuid: this.locationUUID,
      })
      this.$emit('close');
    },
    createManually() {
      this.$emit('createManually')
      this.$emit('close');
    }
  },
  mounted() {
    this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false', true);
  }
}
</script>


<style>

.my-modal .modal-close {
  background: red;
}

</style>
