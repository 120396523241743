var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card",
      staticStyle: { "overflow-y": "auto", "justify-content": "center" },
    },
    [
      _c("p", { staticClass: "title is-4" }, [_vm._v(" Task Group ")]),
      _c(
        "section",
        { staticClass: "modal-card-body has-text-centered" },
        [
          _c(
            "b-tabs",
            {
              attrs: { expanded: "" },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "b-tab-item",
                { attrs: { label: "General" } },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Label", expanded: "" } },
                    [
                      _c("b-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.taskGroup.label,
                          callback: function ($$v) {
                            _vm.$set(_vm.taskGroup, "label", $$v)
                          },
                          expression: "taskGroup.label",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.taskGroup.is_required
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "16px" } },
                            [
                              _c("b", [_vm._v("Conditions")]),
                              _c(
                                "b-button",
                                {
                                  staticClass: "button-gap",
                                  attrs: {
                                    "icon-left": "plus",
                                    size: "is-small",
                                  },
                                  on: {
                                    click: function () {
                                      return _vm.taskGroup.launch_conditions.push(
                                        { condition_type: "", value: "" }
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Add")]
                              ),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.taskGroup.launch_conditions,
                            function (condition, index) {
                              return _c(
                                "b-field",
                                { key: index, attrs: { grouped: "" } },
                                [
                                  _c(
                                    "b-field",
                                    { attrs: { expanded: "" } },
                                    [
                                      _c(
                                        "b-select",
                                        {
                                          attrs: {
                                            placeholder: "Type",
                                            size: "is-small",
                                            expanded: "",
                                            rounded: "",
                                          },
                                          model: {
                                            value: condition.condition_type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                condition,
                                                "condition_type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "condition.condition_type",
                                          },
                                        },
                                        _vm._l(
                                          _vm.conditionTypes,
                                          function (conditionType) {
                                            return _c(
                                              "option",
                                              {
                                                key: conditionType,
                                                domProps: {
                                                  value: conditionType,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(conditionType) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _c(
                                        "b-select",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            placeholder: "Type",
                                            size: "is-small",
                                            expanded: "",
                                            rounded: "",
                                          },
                                          model: {
                                            value: condition.value,
                                            callback: function ($$v) {
                                              _vm.$set(condition, "value", $$v)
                                            },
                                            expression: "condition.value",
                                          },
                                        },
                                        _vm._l(
                                          _vm.getConditionValues(
                                            condition.condition_type
                                          ),
                                          function (conditionValue) {
                                            return _c(
                                              "option",
                                              {
                                                key: conditionValue.uuid,
                                                domProps: {
                                                  value: conditionValue.uuid,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      conditionValue.label
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        "icon-left": "delete",
                                        size: "is-small",
                                      },
                                      on: {
                                        click: function () {
                                          return _vm.taskGroup.launch_conditions.splice(
                                            index,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Remove")]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  !_vm.taskGroup.uuid
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            expanded: "",
                            disabled: _vm.saveOrUpdateDisabled,
                            type: "button is-success",
                          },
                          on: { click: _vm.createTaskGroup },
                        },
                        [_vm._v("Create")]
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: {
                            expanded: "",
                            disabled: _vm.saveOrUpdateDisabled,
                            type: "button is-success",
                          },
                          on: { click: _vm.updateTaskGroup },
                        },
                        [_vm._v("Update")]
                      ),
                ],
                1
              ),
              _vm.taskGroup.uuid &&
              _vm.taskGroupTasks &&
              _vm.taskGroupTasks.length > 0
                ? _c(
                    "b-tab-item",
                    { attrs: { label: "Task Orders" } },
                    [
                      _c("h6", { staticClass: "is-italic" }, [
                        _vm._v(
                          " You can grab task with ☰ to edit the order of tasks."
                        ),
                      ]),
                      _c("br"),
                      _c(
                        "Container",
                        {
                          attrs: {
                            "drag-handle-selector": ".column-drag-handle",
                          },
                          on: { drop: _vm.onDrop },
                        },
                        _vm._l(_vm.taskGroupTasks, function (task) {
                          return _c(
                            "Draggable",
                            { key: task.uuid },
                            [
                              _c(
                                "b-field",
                                {
                                  staticClass: "modifier has-text-centered",
                                  attrs: { grouped: "" },
                                },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "column-drag-handle is-size-9",
                                    },
                                    [_vm._v(" ☰ ")]
                                  ),
                                  _c("b-field", [
                                    _c(
                                      "h3",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                      },
                                      [_vm._v(" " + _vm._s(task.label) + " ")]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c("hr"),
                      _c(
                        "b-button",
                        {
                          attrs: { expanded: "", type: "button is-success" },
                          on: { click: _vm.updateTaskGroupOrder },
                        },
                        [_vm._v("Update")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }