import { baseMarketingUrl } from '@/apis';
import axios from '@/axios';


const ressourcePath = `${baseMarketingUrl}/events`;


export default {
/* eslint-disable */
  createOne(opts) {
    return axios.post(ressourcePath, opts)
                .then(response => response.data)
  },
  updateOne(eventUUID, opts) {
    return axios.patch(`${ressourcePath}/${eventUUID}`, opts)
                .then(response => response.data)
  },
  deleteOne(eventUUID) {
    return axios.delete(`${ressourcePath}/${eventUUID}`)
                .then(response => response.data)
  },
  fetchOne(eventUUID) {
    return axios.get(`${ressourcePath}/${eventUUID}`)
                .then(response => response.data)
  },
  fetchAll() {
    var perPage = 1000;
    return axios.get(ressourcePath, {params: {
      per_page: perPage,
    }})
    .then(response => response.data)
  },
};
