<template>
  <div class="box modal-card" style="overflow: visible;">
    <b-loading :active.sync="isLoading"></b-loading>
    <p class="title is-4"> Set Up Forecasts </p>
    <div class="has-text-centered">
      <h5 class="title has-text-centered is-5">Orders per Week</h5>
      <h6 class="is-italic"> Input the expected number of orders per week for the launch of the new kitchen.</h6>
      <b-field label=" " expanded>
        <b-numberinput
          size="is-small"
          v-model="selectedOrdersPerWeek"
          type="number">
        </b-numberinput>
      </b-field>
      <h4 class="title has-text-centered is-5">Source Kitchen</h4>
      <h6 class="is-italic"> The forecasts created are based on the distribution of Menu Items per day of the selected kitchen.</h6>
        <b-field grouped>
          <b-field label="Country" >
            <b-select
              type="text"
              v-model="selectedCountry"
              placeholder="Country"
              expanded
            >
              <option v-for="(kitchenCountry, index) in getExistingKitchenCountries(countries, locations, kitchens)" :key="index" :value="kitchenCountry.uuid">
                {{ kitchenCountry.label }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Licensee">
            <b-select
              type="text"
              v-model="selectedPartner"
              placeholder="Licensee"
              expanded
            >
              <option v-for="(kitchenStat, index) in kitchenStatus" :key="index" :value="kitchenStat.value">
                {{ kitchenStat.label }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Kitchen" expanded>
            <b-select
              type="text"
              v-model="selectedExisingKitchen"
              placeholder="Kitchen"
              expanded
            >
              <option v-for="(kitchen, index) in getExistingKitchens(selectedCountry, selectedPartner, locations, kitchens, false, kitchenUUID)" :key="index" :value="kitchen">
                {{ kitchen.id }}
              </option>
            </b-select>
          </b-field>
        </b-field>
      <h4 class="title has-text-centered is-5">Base Week</h4>
      <h6 class="is-italic"> Select the week from which the sales will be copied. The selected week must have sales on every day of the week. If you don't select anything, last week is used.</h6>
      <b-field label=" ">
        <b-datepicker
          v-model="selectedSourceDate"
          placeholder="Select Date"
          icon="calendar-today"
          position="is-top-right"
          :unselectable-dates="unselectableSourceDates"
          :unselectable-days-of-week="unselectableDaysOfWeek"
          trap-focus>
        </b-datepicker>
      </b-field>
        <b-button v-if="selectedOrdersPerWeek && selectedCountry && selectedPartner != null && selectedExisingKitchen" type="is-info" class="button-gap"  @click="create(selectedExisingKitchen, selectedOrdersPerWeek, selectedSourceDate)" expanded>
          Set Up
        </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { utils } from '@/mixins';

export default {
  mixins: [utils],
  components: {
  },
  props: ['conceptUUID', 'kitchenUUID'],
  data() {
    return {
      activeStep: 0,
      kitchenStatus: [
        { label: 'Franchise', value: true },
        { label: 'Taster', value: false },
      ],
      selectedCountry: null,
      selectedPartner: null,
      selectedExisingKitchen: null,
      selectedOrdersPerWeek: null,
      selectedSourceDate: null,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.kitchens.fetchAll.pending,
      countries: (state) => state.countries.all,
      locations: (state) => state.locations.all,
      kitchens: (state) =>
        state.kitchens.all.filter(kitchen => !kitchen.is_deleted).sort((kitchen1, kitchen2) =>
          kitchen1.id.localeCompare(kitchen2.id),
      ),
    }),
    unselectableSourceDates() {
      var nextDate = new Date();
      nextDate.setDate(nextDate.getDate() + 60);
      var nextDates = this.getDaysArray(new Date(), nextDate);
      var curr = new Date();
      var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
      var weekDates = this.getDaysArray(firstday, new Date());
      var unselectableSourceDates = [...weekDates, ...nextDates];
      return unselectableSourceDates
    },
    unselectableDaysOfWeek() {
      return [0, 2, 3, 4, 5, 6]
    },
  },
  methods: {
    create(selectedKitchen, selectedOrdersPerWeek, selectedSourceDate) {
      var sourcedateFormatted = new Date(selectedSourceDate.getTime() - selectedSourceDate.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0];
      this.$emit('create', {
          concept_uuid: this.conceptUUID,
          kitchen_uuid_destination: this.kitchenUUID,
          kitchen_uuid_source: selectedKitchen.uuid,
          orders_per_week: selectedOrdersPerWeek,
          source_date: sourcedateFormatted,
      })
      this.$emit('close');
    },
    getDaysArray(start, end) {
      for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
          arr.push(new Date(dt));
      }
      return arr;
    },
  },
  mounted() {
    this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false', true);
  }
}
</script>


<style>

.my-modal .modal-close {
  background: red;
}

</style>
