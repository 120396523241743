import kitchenCampaignsAPI from '../../../apis/marketing/kitchenCampaigns';
import { kitchenCampaignsTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  total: 0,
  total_current: 0,
  current: null,
  currents: [],
  current_error: '',
  fetchAll: {
    pending: false,
    error: null,
  },
  fetchOne: {
    pending: false,
    error: null,
  },
  createOne: {
    pending: false,
    error: null,
  },
  createMultiple: {
    pending: false,
    error: null,
  },
  updateOne: {
    pending: false,
    error: null,
  },
  updateOneStatus: {
    pending: false,
    error: null,
  },
  deleteOne: {
    pending: false,
    error: null,
  },
  newKitchenCampaign: {
    'campaign_uuid': '',
    'location_uuid': '',
    'start_date': '',
    'end_date': '',
    'financing_model': '',
    'status': '',
    'status_reason': null,
    'status_comment': null,
  },
  newKitchenCampaign: {
    'campaign_uuid': '',
    'location_uuid': '',
    'start_date': '',
    'end_date': '',
    'financing_model': '',
    'status': '',
    'status_reason': null,
    'status_comment': null,
  },
  selectedCampaignTimelines: ['Current'],
  campaignTimelines: ['Past', 'Upcoming'],
  states: {
    CREATED: {
      STATES: {
        CREATED: {
          next: 'OPT_OUT',
          disabled: false,
          color: 'is-info',
          icon: 'mouse',
          confirmationModalRequired: true,
          text: 'ELIGIBLE'
        },
        OPT_OUT: {
          next: 'CREATED',
          disabled: false,
          color: 'is-danger',
          icon: 'mouse',
          confirmationModalRequired: false,
          text: 'OPT OUT'
        },
        OPT_IN: {
          next: null,
          disabled: true,
          color: 'is-success',
          icon: '',
          confirmationModalRequired: false,
          text: 'OPT IN'
        },
        PLATFORM_SETUP_DONE: {
          next: null,
          disabled: true,
          color: 'is-success',
          icon: '',
          confirmationModalRequired: true,
          text: 'SETUP'
        }
      }
    },
    OPT_IN : {
      STATES: {
        OPT_IN: {
          next: 'OPT_OUT',
          disabled: false,
          color: 'is-success',
          icon: 'mouse',
          confirmationModalRequired: true,
          text: 'OPT IN'
        },
        OPT_OUT: {
          next: 'CREATED',
          disabled: false,
          color: 'is-danger',
          icon: 'mouse',
          confirmationModalRequired: false,
          text: 'OPT OUT'
        },
        CREATED: {
          next: 'OPT_IN',
          disabled: false,
          color: 'is-info',
          icon: '',
          confirmationModalRequired: false,
          text: 'ELIGIBLE'
        },
        PLATFORM_SETUP_DONE: {
          next: null,
          disabled: true,
          color: 'is-success',
          icon: '',
          confirmationModalRequired: true,
          text: 'SETUP'
        }
      }
    },
    PLATFORM_SETUP_DONE : {
      STATES: {
        OPT_IN: {
          next: 'PLATFORM_SETUP_DONE',
          disabled: false,
          color: 'is-warning',
          icon: 'mouse',
          confirmationModalRequired: false,
          text: 'OPT IN'
        },
        PLATFORM_SETUP_DONE: {
          next: 'OPT_IN',
          disabled: false,
          color: 'is-success',
          icon: 'mouse',
          confirmationModalRequired: false,
          text: 'SETUP'
        },
        CREATED: {
          next: null,
          disabled: true,
          color: 'is-info',
          icon: '',
          confirmationModalRequired: false,
          text: 'ELIGIBLE'
        },
        OPT_OUT: {
          next: null,
          disabled: true,
          color: 'is-danger',
          icon: '',
          confirmationModalRequired: false,
          text: 'OPT OUT'
        },
      }
    },
    DEFAULT: {
      next: null,
      disabled: true,
      color: 'is-light',
      icon: '',
      confirmationModalRequired: false,
      text: 'NOT ELIGIBLE'
    }
  },
  optOutReasons: [
    "NO_STOCKS",
    "NO_STAFF",
    "OTHER",
  ]
};

const actions = {
  async fetchAll({ commit }, opts) {
    commit(kitchenCampaignsTypes.FETCH_ALL_REQUEST);
    return kitchenCampaignsAPI.fetchAll(opts)
      .then((kitchenCampaigns) => commit(kitchenCampaignsTypes.FETCH_ALL_SUCCESS, {'response': kitchenCampaigns, 'payload': opts}))
      .catch((error) => commit(kitchenCampaignsTypes.FETCH_ALL_FAILURE, error));
  },
  async fetchOne({ commit }, kitchenCampaignUUID) {
    commit(kitchenCampaignsTypes.FETCH_ONE_REQUEST);

    return kitchenCampaignsAPI
      .fetchOne(kitchenCampaignUUID)
      .then(function(kitchenCampaign) {
        commit(kitchenCampaignsTypes.FETCH_ONE_SUCCESS, kitchenCampaign);
      })
      .catch(function(error) {
        commit(kitchenCampaignsTypes.FETCH_ONE_FAILURE, error.response.data.detail);
      });
  },
  async createOne({ commit }, payload) {
    commit(kitchenCampaignsTypes.CREATE_ONE_REQUEST);

    return kitchenCampaignsAPI
      .createOne(payload)
      .then(function(kitchenCampaign) {
        commit(kitchenCampaignsTypes.CREATE_ONE_SUCCESS, kitchenCampaign);
        return kitchenCampaign;
      })
      .catch(function(error) {
        commit(kitchenCampaignsTypes.CREATE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async createMultiple({ commit }, payload) {
    commit(kitchenCampaignsTypes.CREATE_MULTIPLE_REQUEST);

    return kitchenCampaignsAPI
      .createMultiple(payload)
      .then(function(kitchenCampaign) {
        commit(kitchenCampaignsTypes.CREATE_MULTIPLE_SUCCESS, kitchenCampaign);
        return kitchenCampaign;
      })
      .catch(function(error) {
        commit(kitchenCampaignsTypes.CREATE_MULTIPLE_FAILURE, error.response.data.detail);
      });
  },
  async updateOne({ commit }, payload) {
    var newPayload = {...payload}
    var kitchenCampaignUUID = payload.uuid;
    delete newPayload.uuid;
    commit(kitchenCampaignsTypes.UPDATE_ONE_REQUEST);

    return kitchenCampaignsAPI
      .updateOne(kitchenCampaignUUID, newPayload)
      .then(function(kitchenCampaign) {
        commit(kitchenCampaignsTypes.UPDATE_ONE_SUCCESS, kitchenCampaign);
        return kitchenCampaign;
      })
      .catch(function(error) {
        commit(kitchenCampaignsTypes.UPDATE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async deleteOne({ commit }, kitchenCampaignUUID) {
    commit(kitchenCampaignsTypes.DELETE_ONE_REQUEST);

    return kitchenCampaignsAPI
      .deleteOne(kitchenCampaignUUID)
      .then(function() {
        commit(kitchenCampaignsTypes.DELETE_ONE_SUCCESS, kitchenCampaignUUID);
      })
      .catch(function(error) {
        commit(kitchenCampaignsTypes.DELETE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async updateOneStatus({ commit }, payload) {
    var newPayload = {...payload}
    var kitchenCampaignUUID = payload.uuid;
    delete newPayload.uuid;
    commit(kitchenCampaignsTypes.UPDATE_ONE_STATUS_REQUEST);

    return kitchenCampaignsAPI
      .updateOneStatus(kitchenCampaignUUID, newPayload)
      .then(function(kitchenCampaign) {
        commit(kitchenCampaignsTypes.UPDATE_ONE_STATUS_SUCCESS, kitchenCampaign);
        return kitchenCampaign;
      })
      .catch(function(error) {
        commit(kitchenCampaignsTypes.UPDATE_ONE_STATUS_FAILURE, error.response.data.detail);
      });
  },
  reset({ commit }) {
    commit(kitchenCampaignsTypes.RESET_ONE);
  },
};

const mutations = {
  [kitchenCampaignsTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [kitchenCampaignsTypes.FETCH_ALL_SUCCESS](state, {response, payload}) {
    if (!Object.keys(payload).includes('campaignUUID')) {
      state.all = response.items;
      state.total = response.total;
    }
    else {
      state.currents = response.items;
      state.total_current = response.total;
    }
    state.fetchAll.pending = false;
  },
  [kitchenCampaignsTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
  [kitchenCampaignsTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [kitchenCampaignsTypes.FETCH_ONE_SUCCESS](state, kitchenCampaign) {
    state.current = kitchenCampaign;
    const index = state.all.findIndex((item) => item.uuid === kitchenCampaign.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, kitchenCampaign);
    }
    state.fetchOne.pending = false;
    state.fetchOne.error = null;
  },
  [kitchenCampaignsTypes.FETCH_ONE_FAILURE](state, error) {
    state.current = null;
    state.fetchOne.pending = false;
    state.fetchOne.error = error;
  },
  [kitchenCampaignsTypes.CREATE_ONE_REQUEST](state) {
    state.createOne.pending = true;
  },
  [kitchenCampaignsTypes.CREATE_ONE_SUCCESS](state, kitchenCampaign) {
    state.current = kitchenCampaign;
    state.all.push(kitchenCampaign);
    state.createOne.pending = false;
    state.createOne.error = null;
  },
  [kitchenCampaignsTypes.CREATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.createOne.pending = false;
    state.createOne.error = error;
  },
  [kitchenCampaignsTypes.CREATE_MULTIPLE_REQUEST](state) {
    state.createMultiple.pending = true;
  },
  [kitchenCampaignsTypes.CREATE_MULTIPLE_SUCCESS](state, kitchenCampaigns) {
    state.all = state.all.concat(kitchenCampaigns);
    state.createMultiple.pending = false;
    state.createMultiple.error = null;
  },
  [kitchenCampaignsTypes.CREATE_MULTIPLE_FAILURE](state, error) {
    state.current = null;
    state.createMultiple.pending = false;
    state.createMultiple.error = error;
  },
  [kitchenCampaignsTypes.UPDATE_ONE_REQUEST](state) {
    state.updateOne.pending = true;
  },
  [kitchenCampaignsTypes.UPDATE_ONE_SUCCESS](state, kitchenCampaign) {
    // state.current = kitchenCampaign;
    const index = state.all.findIndex((item) => item.uuid === kitchenCampaign.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, kitchenCampaign);
    }
    state.updateOne.pending = false;
    state.updateOne.error = null;
  },
  [kitchenCampaignsTypes.UPDATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.updateOne.pending = false;
    state.updateOne.error = error;
  },
  [kitchenCampaignsTypes.DELETE_ONE_REQUEST](state) {
    state.deleteOne.pending = true;
  },
  [kitchenCampaignsTypes.DELETE_ONE_SUCCESS](state, kitchenCampaignUUID) {
    const index = state.all.findIndex((kitchenCampaign) => kitchenCampaign.uuid === kitchenCampaignUUID);
    if (index !== -1) {
      state.all.splice(index, 1);
    }
    state.deleteOne.pending = false;
    state.deleteOne.error = null;
  },
  [kitchenCampaignsTypes.DELETE_ONE_FAILURE](state, error) {
    state.deleteOne.pending = false;
    state.deleteOne.error = error;
  },
  [kitchenCampaignsTypes.UPDATE_ONE_STATUS_REQUEST](state) {
    state.updateOne.pending = true;
  },
  [kitchenCampaignsTypes.UPDATE_ONE_STATUS_SUCCESS](state, kitchenCampaign) {
    // state.current = kitchenCampaign;
    const index = state.all.findIndex((item) => item.uuid === kitchenCampaign.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, kitchenCampaign);
    }
    state.updateOne.pending = false;
    state.updateOne.error = null;
  },
  [kitchenCampaignsTypes.UPDATE_ONE_STATUS_FAILURE](state, error) {
    state.current = null;
    state.updateOne.pending = false;
    state.updateOne.error = error;
  },
  [kitchenCampaignsTypes.RESET_ONE](state) {
    state.newKitchenCampaign = Object.assign({}, state.newKitchenCampaign);
  },
  [kitchenCampaignsTypes.SET_SELECTED_CAMPAIGN_TIMELINES](state, campaignTimelines) {
    state.selectedCampaignTimelines = campaignTimelines;
  },
  [kitchenCampaignsTypes.SET_SELECTED_CAMPAIGN_TYPE](state, campaignType) {
    state.selectedCampaignType = campaignType;
  },
  [kitchenCampaignsTypes.SET_CURRENT](state, kitchenCampaign) {
    state.current = kitchenCampaign;
  },
  [kitchenCampaignsTypes.SET_CURRENTS](state, campaignUUID) {
    state.currents = state.all.filter(kitchenCampaign => kitchenCampaign.campaign_uuid == campaignUUID);
  },
};

const getters = {
  getnewKitchenCampaign: (state) => {
    return state.newKitchenCampaign;
  },
  getKitchenCampaignTimelines: (state) => {
    return state.campaignTimelines;
  },
  getKitchenCampaignStates: (state) => {
    return state.states;
  },
  getOptOutReasons: (state) => {
    return state.optOutReasons;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
