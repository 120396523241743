import platformsAPI from '../../../apis/kitchen/platforms';
import { platformsTypes } from '../../mutation-types.js'

/* eslint-disable */

const state = {
  all: [],
  selected: [],
  platform_with_delivery_required: [],
  schema: {},
  current_error: '',
  newPlatformEmpty: {
    id: '',
    is_active: false,
    label: '',
    logo: '',
    logo_file: null,
  },
  newPlatform: {
    id: '',
    is_active: false,
    label: '',
    logo: '',
    logo_file: null,
  },
  newPicture: {
    picture: '',
  },
  fetchAll: {
    pending: false,
    error: null,
  },
  fetchOne: {
    pending: false,
    error: null,
  },
};

const getters = {
  getNewPlatform: state => {
    return state.newPlatform;
  },
  getPlatform: state => id => {
    return state.all.find(platform => platform.uuid === id)
  },
  getPlatformLabels: state => {
    return state.all.map(value => value.label);
  },
  getActivePlatforms: state => {
    return state.all.filter(platform => !platform.is_deleted)
  },
  getInactivePlatforms: state => {
    return state.all.filter(platform => platform.is_deleted)
  },
  getPlatformSchema: state => {
    return state.schema.components.schemas.PlatformRequest.properties
  },
  getDeliveryPlatforms: state => {
    return state.all.filter(platform => platform.id === 'foodkit')
  },
  getNewPicture: state => {
    return state.newPicture;
  },
}

const actions = {
  async fetchAll({ commit }, opts) {
    commit(platformsTypes.FETCH_ALL_REQUEST);

    return platformsAPI.fetchAll(opts)
      .then((platforms) => commit(platformsTypes.FETCH_ALL_SUCCESS, platforms))
      .catch((error) => commit(platformsTypes.FETCH_ALL_FAILURE, error));
  },
  async fetchOne({ commit }, platformUUID) {
    commit(platformsTypes.FETCH_ONE_REQUEST);

    return platformsAPI
      .fetchOne(platformUUID)
      .then(function(platform) {
        commit(platformsTypes.FETCH_ONE_SUCCESS, platform);
      })
      .catch(function(error) {
        commit(platformsTypes.FETCH_ONE_FAILURE, error.response.data.detail);
      });
  },
  async getPlatforms({ commit }, queryParameters) {
    commit(platformsTypes.FETCH_ALL_REQUEST);
    return platformsAPI.getPlatforms(queryParameters)
    .then(function (platforms) {
      commit(platformsTypes.SET_PLATFORMS, platforms)
    })
    .catch(function (error) {
      commit(platformsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  async createPlatform({ commit }, payload) {
    return platformsAPI.createPlatform(payload)
    .then(function (platform) {
      commit(platformsTypes.CREATE_PLATFORM, platform)
    })
    .catch(function (error) {
      commit(platformsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  resetNewPlatform({ commit }) {
    commit(platformsTypes.RESET_NEW_PLATFORM, '')
  },
  async updatePlatform({ commit, dispatch }, payload) {
    return platformsAPI.updatePlatform(payload)
    .then(function (platform) {
      commit(platformsTypes.UPDATE_PLATFORM, platform)
    })
    .catch(function (error) {
      commit(platformsTypes.SET_CURRENT_ERROR, error.response.data.detail)
      dispatch('getPlatforms')
    })
  },
  async updatePlatformPicture({ commit, dispatch }, payload) {
    return platformsAPI.updatePlatformPicture(payload)
    .then(function (platform) {
      commit(platformsTypes.UPDATE_PLATFORM, platform)
    })
    .catch(function (error) {
      commit(platformsTypes.SET_CURRENT_ERROR, error.response.data.detail)
      dispatch('getPlatforms')
    })
  },
  async activatePlatform({ commit }, platformUUID) {
    return platformsAPI.activatePlatform(platformUUID)
    .then(function (_) {
      commit(platformsTypes.ACTIVATE_PLATFORM, platformUUID)
    })
    .catch(function (error) {
      commit(platformsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  async deactivatePlatform({ commit }, platformUUID) {
    return platformsAPI.deactivatePlatform(platformUUID)
    .then(function (_) {
      commit(platformsTypes.DEACTIVATE_PLATFORM, platformUUID)
    })
    .catch(function (error) {
      commit(platformsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  async getSchema({ commit }) {
    return platformsAPI.getSchema()
    .then(function (schema) {
      commit(platformsTypes.SET_SCHEMA, schema)
    })
    .catch(function (error) {
      commit(platformsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  resetCurrentError({ commit }) {
    commit(platformsTypes.SET_CURRENT_ERROR, '')
  },
}

const mutations = {
  [platformsTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [platformsTypes.FETCH_ALL_SUCCESS](state, items) {
    state.all = items;
    state.fetchAll.pending = false;
  },
  [platformsTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
  [platformsTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [platformsTypes.FETCH_ONE_SUCCESS](state, platform) {
    state.current = platform;
    const index = state.all.findIndex((item) => item.uuid === platform.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, platform);
    }
    state.fetchOne.pending = false;
    state.fetchOne.error = null;
  },
  [platformsTypes.FETCH_ONE_FAILURE](state, error) {
    state.current = null;
    state.fetchOne.pending = false;
    state.fetchOne.error = error;
  },
  [platformsTypes.SET_PLATFORMS] (state, platforms) {
    state.all = platforms;
    state.selected = [...platforms];
    state.fetchAll.pending = false;
  },
  [platformsTypes.CREATE_PLATFORM] (state, platform) {
    state.all.push(platform)
  },
  [platformsTypes.RESET_NEW_PLATFORM] (state) {
    state.newPlatform = Object.assign({}, state.newPlatformEmpty);
  },
  [platformsTypes.UPDATE_PLATFORM] (state, platform) {
    const index = state.all.findIndex(item => item.id === platform.id);
    if (index !== -1) {
      state.all.splice(index, 1, platform);
    };
  },
  [platformsTypes.ACTIVATE_PLATFORM] (state, platformUUID) {
    const index = state.all.findIndex(item => item.uuid === platformUUID);
    if (index !== -1) {
      state.all[index]["is_active"] = true;
    };
  },
  [platformsTypes.DEACTIVATE_PLATFORM] (state, platformUUID) {
    const index = state.all.findIndex(item => item.uuid === platformUUID);
    if (index !== -1) {
      state.all[index]["is_active"] = false;
    };
  },
  [platformsTypes.SET_CURRENT_ERROR] (state, error) {
    state.current_error = error
    },
  [platformsTypes.SET_SCHEMA] (state, schema) {
    state.schema = schema
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
