import Vue from 'vue';
import Vuex from 'vuex';

import actions from './modules/onboarding/actions';
import accesses from './modules/onboarding/accesses';
import campaigns from './modules/marketing/campaigns';
import campaignItems from './modules/marketing/campaignItems';
import concepts from './modules/kitchen/concepts';
import countries from './modules/kitchen/countries';
import events from './modules/marketing/events';
import hubs from './modules/supply/hubs';
import kitchens from './modules/kitchen/kitchens';
import kitchenCampaigns from './modules/marketing/kitchenCampaigns';
import costs from './modules/itemManager/costs';
import menus from './modules/menuManager/menus';
import menuItems from './modules/menuManager/menuItems';
import menuInformation from './modules/platform_state/menuInformation';
import locations from './modules/kitchen/locations';
import onboardingActions from './modules/onboarding/actions';
import launches from './modules/onboarding/launches';
import platforms from './modules/kitchen/platforms';
import restaurants from './modules/kitchen/restaurants';
import supplyActions from './modules/supply/actions';
import taskGroups from './modules/onboarding/taskGroups';
import tasks from './modules/onboarding/tasks';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    accesses,
    actions,
    campaigns,
    campaignItems,
    concepts,
    costs,
    countries,
    events,
    hubs,
    launches,
    locations,
    kitchenCampaigns,
    kitchens,
    menuInformation,
    menus,
    menuItems,
    onboardingActions,
    platforms,
    restaurants,
    supplyActions,
    taskGroups,
    tasks
  },
});
