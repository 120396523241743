var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box modal-card", staticStyle: { overflow: "visible" } },
    [
      _c("p", { staticClass: "title is-4" }, [_vm._v(" Set Up Holidays ")]),
      _c(
        "div",
        { staticClass: "has-text-centered" },
        [
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                { attrs: { label: "Start Date", expanded: "" } },
                [
                  _c("b-datepicker", {
                    attrs: {
                      placeholder: "Click to select...",
                      icon: "calendar-today",
                      position: "is-top-right",
                      "unselectable-dates": _vm.unselectableStartDates,
                      "trap-focus": "",
                    },
                    model: {
                      value: _vm.startDate,
                      callback: function ($$v) {
                        _vm.startDate = $$v
                      },
                      expression: "startDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "End Date", expanded: "" } },
                [
                  _c("b-datepicker", {
                    attrs: {
                      placeholder: "Click to select...",
                      icon: "calendar-today",
                      position: "is-top-right",
                      "unselectable-dates": _vm.unselectableEndDates,
                      "trap-focus": "",
                    },
                    model: {
                      value: _vm.endDate,
                      callback: function ($$v) {
                        _vm.endDate = $$v
                      },
                      expression: "endDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.startDate && _vm.endDate
            ? _c(
                "b-button",
                {
                  staticClass: "button-gap",
                  attrs: { type: "is-info", expanded: "" },
                  on: {
                    click: function ($event) {
                      return _vm.set(_vm.startDate, _vm.endDate)
                    },
                  },
                },
                [_vm._v(" Set Up ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }