<template>
  <section class="section">
    <b-loading :active.sync="isLoading"></b-loading>
    <div class="container">
      <h1 class="title has-text-dark">
        <div class="columns is-mobile">
          <div class="column is-narrow">
            Catalog
          </div>
        </div>
      </h1>
      <hr />
      <section>
        <b-button class="is-large is-info button-gap" @click="openExportModal()" expanded>
          Export
        </b-button>
        <b-button class="is-large is-link button-gap" @click="openImportModal()" expanded>
          Import
        </b-button>
      </section>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from 'vuex';
import { utils } from '@/mixins';
import ExportCatalogue from '@/components/Supply/ExportCatalogue.vue'
import ImportCatalogue from '@/components/Supply/ImportCatalogue.vue'


export default {
  components: {},
  mixins: [utils],
  data() {
    return {}
  },
  computed: {
    ...mapState({
      currentError: (state) => state.hubs.fetchAll.error || state.locations.fetchAll.error || state.supplyActions.exportCatalogue.error || state.supplyActions.importCatalogue.error,
      currentSpreadsheetURL: (state) => state.supplyActions.currentSpreadsheetURL,
      isLoading: (state) => state.hubs.fetchAll.pending || state.locations.fetchAll.pending || state.supplyActions.exportCatalogue.pending || state.supplyActions.importCatalogue.pending,
      hubs: (state) => state.hubs.all,
      locations: (state) => state.locations.all,
    }),
    countryLocations() {
      return this.locations.filter(location => !location.parent_uuid);
    }
  },
  methods: {
    ...mapActions('hubs', { fetchHubs: 'fetchAll' }),
    ...mapActions('supplyActions', {exportCatalogue: 'exportCatalogue', importCatalogue: 'importCatalogue'}),
    openExportModal: function() {
      this.$buefy.modal.open({
        component: ExportCatalogue,
        parent: this,
        hasModalCard: true,
        events: {
          update: (opts) => this.exportCatalogue(opts).then((response) => {
            if (response && response.status == 'OK') {
              this.$buefy.dialog.confirm({
                message: 'Successful Export',
                type: 'is-success',
                confirmText: 'Spreadsheet',
                cancelText: 'Close',
                onConfirm: () => window.open(this.currentSpreadsheetURL, '_blank')
              });
            }
            else {
              this.$buefy.dialog.alert({
                  title: 'Error',
                  message: `Export has failed ! <br> Please contact Tech.`,
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true
              });
            }
          })
        },
        props: {
          countryLocations: this.countryLocations,
          hubs: this.hubs,
        }
      })
    },
    openImportModal: function() {
      this.$buefy.modal.open({
        component: ImportCatalogue,
        parent: this,
        hasModalCard: true,
        events: {
          update: (opts) => this.importCatalogue(opts).then((response) => {
            if (response && response.status == 'OK') {
              this.$buefy.dialog.confirm({
                message: 'Successful Import',
                type: 'is-success',
                confirmText: 'Spreadsheet',
                cancelText: 'Close',
                onConfirm: () => window.open(this.currentSpreadsheetURL, '_blank')
              });
            }
            else {
              this.$buefy.dialog.alert({
                  title: 'Error',
                  message: `Import has failed ! <br> Please contact Tech.`,
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true
              });
            }
          })
        },
        props: {
          countryLocations: this.countryLocations,
          hubs: this.hubs,
        }
      })
    },
  },
  mounted() {
    Promise.all([
      this.fetchHubs(),
      this.getLocations('?is_deleted=false', true),
    ])
  },
  watch: {},
};
</script>

<style lang="css" scoped>
  .button-gap {
    margin-top: 10px;
  }
</style>
