<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close">
      <section>
        <div class="card" style="width: auto">
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p v-if="kitchen.id" p class="title is-4">{{ kitchen.id }}</p>
              </div>
            </div>
          </div>
          <section class="card-content">
            <b-tabs :multiline="true" expanded>
              <b-tab-item label="Info" icon="account-box">
                <b-field grouped>
                  <b-field class="control" label="ID" expanded :disabled="true" :type="validationFieldType(kitchen.id)">
                    <b-input
                      v-model="kitchen.id"
                      type="text"
                      required
                      :disabled="modificationDisabled"
                      :message="idMessage()"
                      placeholder="Kitchen Id"
                    >
                    </b-input>
                  </b-field>
                  <b-field label="Owner">
                    <b-select
                      type="text"
                      v-model="kitchen.is_franchise"
                      :disabled="modificationDisabled"
                      placeholder="Kitchen Franchise"
                    >
                      <option v-for="(kitchenStat, index) in kitchenStatuses" :key="index" :value="kitchenStat.value">
                        {{ kitchenStat.label }}
                      </option>
                    </b-select>
                  </b-field>
                </b-field>
                <b-field label="Launch Date">
                  <b-datepicker
                    :disabled="modificationDisabled"
                    show-week-number
                    v-model="kitchen.launch_date"
                    placeholder="Click to select..."
                    icon="calendar-today"
                    :min-date="new Date(2017, 0, 1)"
                  >
                  </b-datepicker>
                </b-field>
                <b-field grouped>
                  <b-field label="UUID" expanded>
                    <b-input v-model="kitchen.uuid" type="text" required :disabled="true" placeholder="Kitchen Uuid">
                    </b-input>
                  </b-field>
                  <b-field label="Location UUID" expanded>
                    <b-input v-model="kitchen.location_uuid" type="text" required :disabled="true" placeholder="Kitchen Uuid">
                    </b-input>
                  </b-field>
                </b-field>
              </b-tab-item>
              <b-tab-item label="Address" icon="home">
                <b> Address </b>
                <b-switch
                  v-if="!modificationDisabled"
                  v-model="manualAddressMode"
                  passive-type='is-success'
                  false-value='Google Map'
                  true-value='Manual'
                  type='is-danger'
                  size='is-small'
                >
                  {{ manualAddressMode }}
                </b-switch>
                <b-field grouped>
                  <b-field label="" expanded v-if="manualAddressMode == 'Google Map'">
                    <vue-google-autocomplete
                      id="map"
                      v-model="selectedAddress"
                      classname="google-autocomplete"
                      v-on:placechanged="getAddressData"
                      :country="['fr', 'uk', 'es', 'be', 'nl']"
                      :disabled="modificationDisabled">
                    </vue-google-autocomplete>
                  </b-field>
                  <b-field expanded v-else>
                    <b-input v-model="kitchen.address" type="text" placeholder="Address"> </b-input>
                  </b-field>
                </b-field>
                <b-field grouped>
                  <b-field label="Country" :type="validationFieldType(kitchen.country)" expanded>
                    <b-input v-model="kitchen.country" type="text" required :disabled="true" placeholder="Kitchen Country">
                    </b-input>
                  </b-field>
                  <b-field label="City" :type="validationFieldType(kitchen.city)" expanded>
                    <b-input v-model="kitchen.city" type="text" required :disabled="true" placeholder="Kitchen City">
                    </b-input>
                  </b-field>
                </b-field>
                <b-field grouped>
                  <b-field label="Postcode" expanded>
                    <b-input v-model="kitchen.address_information.postcode" type="text" placeholder="Postcode" :disabled="manualAddressMode != 'Manual'">
                    </b-input>
                  </b-field>
                  <b-field label="Route" expanded>
                    <b-input v-model="kitchen.address_information.route" type="text" placeholder="Route" :disabled="manualAddressMode != 'Manual'">
                    </b-input>
                  </b-field>
                  <b-field label="Street Number" expanded>
                    <b-input v-model="kitchen.address_information.street_number" type="text" placeholder="Street Number" :disabled="manualAddressMode != 'Manual'">
                    </b-input>
                  </b-field>
                </b-field>
                <b-field grouped>
                  <b-field label="Latitude" expanded>
                    <b-input step="0.000000000001" v-model="kitchen.address_information.latitude" type="number" placeholder="Latitude" :disabled="manualAddressMode != 'Manual'">
                    </b-input>
                  </b-field>
                  <b-field label="Longitude" expanded>
                    <b-input step="0.000000000001" v-model="kitchen.address_information.longitude" type="number" placeholder="Longitude" :disabled="manualAddressMode != 'Manual'">
                    </b-input>
                  </b-field>
                </b-field>
              </b-tab-item>
              <b-tab-item label="Company" icon="office-building">
                <b-field grouped>
                  <b-field label="Company Name" expanded>
                    <b-input
                      v-model="kitchen.company_name"
                      type="text"
                      required
                      :disabled="modificationDisabled || !canEditFinancialInformation()"
                      placeholder="Company Name"
                    >
                    </b-input>
                  </b-field>
                  <b-field label="Company Type" expanded>
                    <b-select
                      :disabled="modificationDisabled || !canEditFinancialInformation()"
                      type="text"
                      v-model="kitchen.company_type"
                      placeholder="Company Type"
                      expanded
                    >
                      <option v-for="(companyType, index) in companyTypes" :key="index" :value="companyType">
                        {{ companyType }}
                      </option>
                    </b-select>
                  </b-field>
                </b-field>
                <b> Company Address </b>
                <b-switch
                  v-model="manualCompanyAddressMode"
                  passive-type='is-success'
                  false-value="Google Map"
                  true-value="Manual"
                  type='is-danger'
                  size='is-small'
                  v-if="!modificationDisabled"
                >
                  {{ manualCompanyAddressMode }}
                </b-switch>
                <b-field label="" expanded>
                  <vue-google-autocomplete
                    v-if="manualCompanyAddressMode == 'Google Map'"
                    id="mapCompany"
                    v-model="selectedCompanyAddress"
                    classname="google-autocomplete"
                    v-on:placechanged="getCompanyAddressData"
                    :country="['fr', 'uk', 'es', 'be', 'nl']"
                    :disabled="modificationDisabled || !canEditFinancialInformation()"
                  >
                  </vue-google-autocomplete>
                  <b-input v-else v-model="kitchen.company_address" type="text" placeholder="Address" :disabled="modificationDisabled || !canEditFinancialInformation()"> </b-input>
                </b-field>
                <div>
                  <div style="margin-bottom: 16px">
                    <b> Contacts</b>
                    <b-button
                      v-if="!modificationDisabled && canEditFinancialInformation()"
                      icon-left="plus"
                      size="is-small"
                      type="is-text"
                      @click="() => kitchen.contacts.push({ first_name: '', last_name: '', personal_mail: '', phone_number: '',role: '' })"
                      >Add</b-button
                    >
                  </div>
                  <b-field grouped v-for="(contact, index) in kitchen.contacts" :key="index">
                    <b-field label="First Name" label-position="on-border" expanded>
                      <b-input type="text" size="is-small" rounded placeholder="First Name" v-model="contact.first_name" :disabled="modificationDisabled || !canEditFinancialInformation()" expanded></b-input>
                    </b-field>
                    <b-field label="Last Name" label-position="on-border" expanded>
                      <b-input type="text" size="is-small" rounded placeholder="Last Name" v-model="contact.last_name" :disabled="modificationDisabled || !canEditFinancialInformation()" expanded></b-input>
                    </b-field>
                    <b-field label="Mail" label-position="on-border" expanded>
                      <b-input type="text" size="is-small" rounded placeholder="Mail" v-model="contact.personal_mail" :disabled="modificationDisabled || !canEditFinancialInformation()" expanded></b-input>
                    </b-field>
                    <b-field label="Phone" label-position="on-border" expanded>
                      <b-input type="text" size="is-small" rounded placeholder="Phone" v-model="contact.phone_number" :disabled="modificationDisabled || !canEditFinancialInformation()" expanded></b-input>
                    </b-field>
                    <b-field label="Role" label-position="on-border" expanded>
                      <b-input type="text" size="is-small" rounded placeholder="Role" v-model="contact.role" :disabled="modificationDisabled || !canEditFinancialInformation()" expanded></b-input>
                    </b-field>
                    <b-button icon-left="delete" v-if="!modificationDisabled" size="is-small" @click="() => kitchen.contacts.splice(index, 1)">Remove</b-button>
                  </b-field>
                </div>
              </b-tab-item>
              <b-tab-item label="Back Office" icon="contacts">
                <p class="title is-6" style="text-align:center;"> Mails </p>
                <b-field horizontal label="Manager Mail">
                    <b-input
                      :disabled="modificationDisabled"
                      type="mail"
                      v-model="kitchen.mails.mail_manager"
                      placeholder="Manager Mail">
                    </b-input>
                    <b-tooltip
                        type="is-info"
                        position="is-right"
                        label="This mail is used for One Board, One Tablet, One Source & Deliveroo Back Office with Manager access"
                        multilined>
                        <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                    </b-tooltip>
                </b-field>
                <b-field horizontal label="Cook Mail">
                    <b-input
                      :disabled="modificationDisabled"
                      type="mail"
                      v-model="kitchen.mails.mail_cook"
                      placeholder="Cook Mail">
                    </b-input>
                    <b-tooltip
                        type="is-info"
                        position="is-right"
                        label="This mail is used for One Board, One Tablet, One Source & Deliveroo Back Office with level access"
                        multilined>
                        <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                    </b-tooltip>
                </b-field>
                <b-field horizontal label="Deliveroo Tech Mail">
                    <b-input
                      :disabled="modificationDisabled || !canEditInformation()"
                      type="mail"
                      v-model="kitchen.mails.mail_deliveroo_tech"
                      placeholder="Deliveroo Tech Mail">
                    </b-input>
                    <b-tooltip
                        type="is-info"
                        position="is-right"
                        label="This mail is used for Deliveroo Back Office for One Tablet -> do not edit it"
                        multilined>
                        <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                    </b-tooltip>
                </b-field>
                <div>
                  <div style="margin-bottom: 16px">
                    <p class="title is-6" style="text-align:center;"> Taster Partner Mails </p>
                    <!-- <b-button
                      v-if="!modificationDisabled"
                      icon-left="plus"
                      size="is-small"
                      type="is-text"
                      @click="() => kitchen.mails.mail_partners.push({ mail: '', role: '' })"
                      >Add</b-button
                    > -->
                  </div>
                  <b-field grouped v-for="(mail_partner, index) in kitchen.mails.mail_partners" :key="index">
                    <b-field expanded>
                      <b-input type="text" size="is-small" rounded placeholder="Mail" v-model="mail_partner.mail" :disabled="true" expanded></b-input>
                      <b-input style="margin-left: 10px;" type="text" size="is-small" rounded placeholder="Role" v-model="mail_partner.role" :disabled="true" expanded></b-input>
                    </b-field>
                    <!-- <b-button icon-left="delete" v-if="!modificationDisabled" size="is-small" @click="() => kitchen.mails.mail_partners.splice(index, 1)">Remove</b-button> -->
                  </b-field>
                </div>
              </b-tab-item>
              <b-tab-item label="Supply" icon="car-pickup">
                <b> Delivery Address </b>
                <b-switch
                  v-model="manualSupplyAddressMode"
                  passive-type='is-success'
                  false-value="Google Map"
                  true-value="Manual"
                  type='is-danger'
                  size='is-small'
                  v-if="!modificationDisabled"
                >
                  {{ manualSupplyAddressMode }}
                </b-switch>
                <b-field label="" expanded>
                  <vue-google-autocomplete
                    v-if="manualSupplyAddressMode == 'Google Map'"
                    id="mapDelivery"
                    v-model="selectedDeliveryAddress"
                    classname="google-autocomplete"
                    v-on:placechanged="getDeliveryAddressData"
                    :country="['fr', 'uk', 'es', 'be', 'nl']"
                    :disabled="modificationDisabled">
                  </vue-google-autocomplete>
                  <b-input v-else v-model="kitchen.supply.address" type="text" placeholder="Address" :disabled="modificationDisabled"> </b-input>
                </b-field>
                <div style="margin-bottom: 16px">
                  <b> Delivery Contact</b>
                  <b-button
                    v-if="!modificationDisabled && !kitchen.supply.delivery_contact"
                    icon-left="plus"
                    size="is-small"
                    type="is-text"
                    @click="() => this.$set(kitchen.supply, 'delivery_contact', {})"
                    >Add</b-button
                  >
                </div>
                <b-field grouped v-if="kitchen.supply.delivery_contact">
                  <b-field label="First Name" label-position="on-border" expanded>
                    <b-input type="text" size="is-small" rounded placeholder="First Name" v-model="kitchen.supply.delivery_contact.first_name" :disabled="modificationDisabled" expanded></b-input>
                  </b-field>
                  <b-field label="Last Name" label-position="on-border" expanded>
                    <b-input type="text" size="is-small" rounded placeholder="Last Name" v-model="kitchen.supply.delivery_contact.last_name" :disabled="modificationDisabled" expanded></b-input>
                  </b-field>
                  <b-field label="Mail" label-position="on-border" expanded>
                    <b-input type="text" size="is-small" rounded placeholder="Mail" v-model="kitchen.supply.delivery_contact.personal_mail" :disabled="modificationDisabled" expanded></b-input>
                  </b-field>
                  <b-field label="Phone" label-position="on-border" expanded>
                    <b-input type="text" size="is-small" rounded placeholder="Phone" v-model="kitchen.supply.delivery_contact.phone_number" :disabled="modificationDisabled" expanded></b-input>
                  </b-field>
                </b-field>
                <div style="margin-bottom: 16px">
                  <b> Delivery Times</b>
                  <b-button
                    v-if="!modificationDisabled && !kitchen.supply.delivery_times"
                    icon-left="plus"
                    size="is-small"
                    type="is-text"
                    @click="() => this.$set(kitchen.supply, 'delivery_times', {})"
                    >Add</b-button
                  >
                </div>
                <div class="columns" v-if="kitchen.supply.delivery_times">
                  <div class="column is-narrow">
                    <h2> Start Time</h2>
                    <b-timepicker :disabled="modificationDisabled" v-model="deliveryTimeStart" inline :incrementMinutes="minutes" size="is-small"></b-timepicker>
                  </div>
                  <div class="column is-narrow">
                    <h2> End Time</h2>
                    <b-timepicker :disabled="modificationDisabled" v-model="deliveryTimeEnd" inline :incrementMinutes="minutes" size="is-small"></b-timepicker>
                  </div>
                </div>
                <div>
                <b-field label="Delivery Instructions">
                  <b-input
                    :disabled="modificationDisabled"
                    v-model="kitchen.supply.delivery_instructions"
                    type="text"
                    placeholder="Instructions for delivers"
                  >
                  </b-input>
                </b-field>
                  <div style="margin-bottom: 16px">
                    <b> Delivery Mail CC</b>
                    <b-button
                      v-if="!modificationDisabled"
                      icon-left="plus"
                      size="is-small"
                      type="is-text"
                      @click="() => addDeliveryMail(kitchen)"
                      >Add</b-button
                    >
                  </div>
                  <b-field grouped v-for="(mail, index) in kitchen.supply.delivery_mails_cc" :key="index">
                    <b-field label="Mail" label-position="on-border" expanded>
                      <b-input type="text" size="is-small" rounded placeholder="First Name" v-model="mail.mail" :disabled="modificationDisabled" expanded></b-input>
                    </b-field>
                    <b-button icon-left="delete" v-if="!modificationDisabled" size="is-small" @click="() => kitchen.supply.delivery_mails_cc.splice(index, 1)">Remove</b-button>
                  </b-field>
                </div>
              </b-tab-item>
              <b-tab-item label="Secrets" icon="lock">
                <p class="title is-6" style="text-align:center;"> Passwords </p>
                <b-field horizontal label="Manager Mail Password">
                    <b-input
                      :disabled="modificationDisabled  || !canEditInformation()"
                      type="mail"
                      v-model="kitchen.secrets.password_mail_manager"
                      placeholder="Manager Mail Password">
                    </b-input>
                </b-field>
                <b-field horizontal label="Cook Mail Password">
                    <b-input
                      :disabled="modificationDisabled || !canEditInformation()"
                      type="mail"
                      v-model="kitchen.secrets.password_mail_cook"
                      placeholder="Cook Mail Password">
                    </b-input>
                </b-field>
                <b-field horizontal label="Deliveroo BackOffice Password">
                    <b-input
                      :disabled="modificationDisabled || !canEditInformation()"
                      type="mail"
                      v-model="kitchen.secrets.password_deliveroo_back_office"
                      placeholder="Deliveroo BackOffice Password">
                    </b-input>
                </b-field>
                <b-field horizontal label="One Tablet Pin">
                    <b-input
                    :disabled="modificationDisabled"
                    type="text"
                    v-model="kitchen.secrets.pin_one_tablet"
                    placeholder="One Tablet Pin">
                    </b-input>
                </b-field>
                <b-field horizontal label="Ops Base Password">
                    <b-input
                    :disabled="modificationDisabled"
                    type="text"
                    v-model="kitchen.secrets.password_opsbase"
                    placeholder="Ops Base Password">
                    </b-input>
                </b-field>
              </b-tab-item>
              <b-tab-item label="Costs" icon="credit-card">
                <b-field label="Taster Commission" expanded>
                  <b-numberinput
                    :disabled="modificationDisabled"
                    v-model="kitchen.financial_informations.taster_commission"
                    type="number"
                    placeholder="Taster Commission"
                  >
                  </b-numberinput>
                </b-field>
                <b-field label="Monthly Fixed Costs" expanded>
                  <b-numberinput
                    :disabled="modificationDisabled"
                    v-model="kitchen.financial_informations.fixed_costs"
                    type="number"
                    placeholder="Monthly Fixed Costs"
                  >
                  </b-numberinput>
                </b-field>
                <b-field label="Deputy Kitchen Label">
                  <b-input
                    :disabled="modificationDisabled"
                    v-model="kitchen.financial_informations.deputy_id"
                    type="text"
                    placeholder="Deputy Kitchen Label"
                  >
                  </b-input>
                </b-field>
              </b-tab-item>
              <b-tab-item label="Detail" icon="attachment">
                <b-field label="Building Code" expanded>
                  <b-input
                    :disabled="modificationDisabled"
                    v-model="kitchen.kitchen_information.building_code"
                    type="text"
                    placeholder="Building Code"
                  >
                  </b-input>
                </b-field>
                <b-field label="Instructions">
                  <b-input
                    :disabled="modificationDisabled"
                    v-model="kitchen.kitchen_information.instructions"
                    type="text"
                    placeholder="Instructions for delivers"
                  >
                  </b-input>
                </b-field>
                <b-field grouped>
                  <b-field label="Outer Box Code" expanded>
                    <b-input
                      :disabled="modificationDisabled"
                      v-model="kitchen.kitchen_information.outer_box_code"
                      type="text"
                      placeholder="Outer box code"
                    >
                    </b-input>
                  </b-field>
                  <b-field label="Inner Box Code" expanded>
                    <b-input
                      :disabled="modificationDisabled"
                      v-model="kitchen.kitchen_information.inner_box_code"
                      type="text"
                      placeholder="Inner box code"
                    >
                    </b-input>
                  </b-field>
                </b-field>
              </b-tab-item>
              <b-tab-item label="Phone" icon="phone-hangup">
                <p class="title is-6" style="text-align:center;"> Communication Channels </p>
                  <b-field horizontal label="Phone">
                      <b-input
                      :disabled="modificationDisabled"
                      type="text"
                      v-model="kitchen.communication_channels.phone"
                      placeholder="Kitchen Phone">
                      </b-input>
                  </b-field>
                  <b-field horizontal label="Slack Channel">
                      <b-input
                      :disabled="modificationDisabled"
                      type="text"
                      v-model="kitchen.communication_channels.slack_channel"
                      placeholder="Slack Channel">
                      </b-input>
                  </b-field>
                  <b-field horizontal label="Slack ID">
                      <b-input
                      :disabled="modificationDisabled"
                      type="text"
                      v-model="kitchen.communication_channels.slack_id"
                      placeholder="Slack ID">
                      </b-input>
                  </b-field>
              </b-tab-item>
              <b-tab-item label="QR Codes" icon="qrcode">
                <div>
                  <b-table
                    :data="kitchen.qr_codes"
                    hoverable
                  >
                    <template slot-scope="props">
                      <b-table-column field="QR Code" label="QR Code">
                        <b-button tag="a"
                            icon-left="qrcode"
                            :href="props.row.qr_code_path"
                            target="_blank">
                        </b-button>
                      </b-table-column>
                      <b-table-column field="Order Type" label="Order Type">
                        {{ props.row.order_type }}
                      </b-table-column>
                      <b-table-column field="Language" label="Language">
                        {{ props.row.locale }}
                      </b-table-column>
                      <b-table-column field="Url" label="Url" v-if="showQrCodeUrl">
                        {{ props.row.url }}
                      </b-table-column>
                      <b-table-column field="ButtonUrl" label="ButtonUrl">
                        <b-button
                          label="Show URL"
                          size="is-small"
                          v-if="!showQrCodeUrl"
                          @click="() => showQrCodeUrl = !showQrCodeUrl" />
                        <b-button
                          label="Hide URL"
                          size="is-small"
                          v-if="showQrCodeUrl"
                          @click="() => showQrCodeUrl = !showQrCodeUrl" />
                      </b-table-column>
                    </template>
                  </b-table>
                </div>
              </b-tab-item>
              <b-tab-item label="Management" icon="worker">
                <b-field grouped>
                  <b-field label="Sales Manager" expanded>
                    <b-autocomplete
                      v-if="!modificationDisabled"
                      rounded
                      field='name'
                      placeholder="Select Sales Manager"
                      icon="magnify"
                      clearable
                      v-model="salesManagerLabel"
                      :data="filteredUsersSales"
                      :open-on-focus="true"
                      :clear-on-select="true"
                      @select="option => selectUser(option, 'SM')"
                      >
                        <template #empty>No results found</template>
                    </b-autocomplete>
                    <b-input
                      v-else
                      :disabled="modificationDisabled"
                      type="text"
                      v-model="salesManagerLabel"
                      placeholder="Sales Manager Label"
                      expanded
                    >
                    </b-input>
                  </b-field>
                  <b-field label="Account Manager" expanded>
                    <b-autocomplete
                      v-if="!modificationDisabled"
                      rounded
                      field='name'
                      placeholder="Select Account Manager"
                      icon="magnify"
                      clearable
                      v-model="accountManagerLabel"
                      :data="filteredUsersAccount"
                      :open-on-focus="true"
                      :clear-on-select="true"
                      @select="option => selectUser(option, 'AM')"
                      >
                        <template #empty>No results found</template>
                    </b-autocomplete>
                    <b-input
                      v-else
                      :disabled="modificationDisabled"
                      type="text"
                      v-model="accountManagerLabel"
                      placeholder="Account Manager Label"
                      expanded
                    >
                    </b-input>
                  </b-field>
                  <b-field label="Operation Manager" expanded>
                    <b-autocomplete
                      v-if="!modificationDisabled"
                      rounded
                      field='name'
                      placeholder="Select Operation Manager"
                      icon="magnify"
                      clearable
                      v-model="operationManagerLabel"
                      :data="filteredUsersOperation"
                      :open-on-focus="true"
                      :clear-on-select="true"
                      @select="option => selectUser(option, 'OM')"
                      >
                        <template #empty>No results found</template>
                    </b-autocomplete>
                    <b-input
                      v-else
                      :disabled="modificationDisabled"
                      type="text"
                      v-model="operationManagerLabel"
                      placeholder="Operation Manager Label"
                      expanded
                    >
                    </b-input>
                  </b-field>
                </b-field>
                <b-field label="Category" style="justify-content: center;" expanded>
                  <b-select
                    type="text"
                    v-model="kitchen.management_information.category"
                    :disabled="modificationDisabled"
                    placeholder="Category"
                    expanded
                    >
                    <option v-for="(kitchenCategory, index) in kitchenCategories" :key="index" :value="kitchenCategory">
                      {{kitchenCategory}}
                    </option>
                  </b-select>
                </b-field>
              </b-tab-item>
            </b-tabs>
          </section>
          <footer class="card-foot buttons is-right">
            <b-button
              v-if="modificationDisabled === false"
              type="button is-small is-outlined"
              @click="update"
              >{{ $t('kitchens.update') }}</b-button
            >
            <b-button type="button is-danger is-small is-outlined" @click="close">{{ $t('kitchens.close') }}</b-button>
          </footer>
          <p v-if="saveMessage" class="is-size-7 is-italic has-text-right has-text-weight-light has-text-danger">{{saveMessage}}</p>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { auth } from '@/mixins';

export default {
  components: { VueGoogleAutocomplete },
  props: ['kitchenStatuses', 'kitchen', 'modificationDisabled', 'location'],
  mixins: [auth],
  data() {
    return {
      openModal: true,
      minutes: 15,
      companyTypes: ['Enterprise', 'Restaurant'],
      selectedAddress: this.kitchen.address,
      selectedDeliveryAddress: this.kitchen.supply.address,
      deliveryTimeStart: this.getStartDeliveryDate(this.kitchen),
      deliveryTimeEnd: this.getEndDeliveryDate(this.kitchen),
      selectedCompanyAddress: this.kitchen.company_address,
      showQrCodeUrl: false,
      manualAddressMode: 'Google Map',
      manualSupplyAddressMode: 'Google Map',
      manualCompanyAddressMode: 'Google Map',
      currentSales: null,
      currentOperation: null,
      currentAccount: null,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.accesses.fetchUsers.pending,
      users: (state) => state.accesses.users,
    }),
    kitchenCategories() {
      return this.$store.getters['kitchens/getKitchenCategories'];
    },
    usersSorted() {
      var usersSorted = [...this.users]
      for (let user of usersSorted) {
        user.name = `${user.first_name} ${user.last_name}`;
      }
      return usersSorted.sort((user1, user2) => user1.first_name.localeCompare(user2.first_name));
    },
    salesManagerLabel: {
      get() {
        if (this.currentSales) {
          return this.currentSales
        }
        if (this.kitchen && this.kitchen.management_information && this.kitchen.management_information.sales_manager_uuid) {
          return this.getUser(this.kitchen.management_information.sales_manager_uuid);
        }
        else {
          return ''
        }
      },
      set(value) {
        this.currentSales = value;
      }
    },
    operationManagerLabel: {
      get() {
        if (this.currentOperation) {
          return this.currentOperation
        }
        if (this.kitchen && this.kitchen.management_information && this.kitchen.management_information.operation_manager_uuid) {
          return this.getUser(this.kitchen.management_information.operation_manager_uuid);
        }
        else {
          return ''
        }
      },
      set(value) {
        this.currentOperation = value;
      }
    },
    accountManagerLabel: {
      get() {
        if (this.currentAccount) {
          return this.currentAccount
        }
        if (this.kitchen && this.kitchen.management_information && this.kitchen.management_information.account_manager_uuid) {
          return this.getUser(this.kitchen.management_information.account_manager_uuid);
        }
        else {
          return ''
        }
      },
      set(value) {
        this.currentAccount = value;
      }
    },
    filteredUsersSales() {
      if (this.usersSorted && this.usersSorted.length > 0) {
        if (this.salesManagerLabel) {
          return this.usersSorted.filter((user) => user.first_name.toLowerCase().includes(this.salesManagerLabel.toLowerCase()));
        }
        return this.usersSorted
      }
      return [];
    },
    filteredUsersOperation() {
      if (this.usersSorted && this.usersSorted.length > 0) {
        if (this.operationManagerLabel) {
          return this.usersSorted.filter((user) => user.first_name.toLowerCase().includes(this.operationManagerLabel.toLowerCase()));
        }
        return this.usersSorted
      }
      return [];
    },
    filteredUsersAccount() {
      if (this.usersSorted && this.usersSorted.length > 0) {
        if (this.accountManagerLabel) {
          return this.usersSorted.filter((user) => user.first_name.toLowerCase().includes(this.accountManagerLabel.toLowerCase()));
        }
        return this.usersSorted
      }
      return [];
    },
    saveMessage() {
      var messages = [
        this.idMessage(), this.cityMessage(), this.countryMessage(),
        this.kitchenAddressMessage(), this.kitchenAddressLongitudeMessage(),this.kitchenAddressLatitudeMessage(), this.kitchenAddressPostcodeMessage()
      ]
      for (let message of messages) {
        if (message) {
          return message
        }
      }
      return '';
    },
  },
  methods: {
    selectUser(user, type) {
      if (user) {
        if (type == 'OM') {
          this.kitchen.management_information.operation_manager_uuid = user.uuid;
        }
        else if (type == 'AM') {
          this.kitchen.management_information.account_manager_uuid = user.uuid;
        }
        else if (type == 'SM') {
          this.kitchen.management_information.sales_manager_uuid = user.uuid;
        }
      }
    },
    getUser(userUUID) {
      if (userUUID) {
        var user = this.users.find(user => user.uuid == userUUID);
        if (user) {
          return `${user.first_name} ${user.last_name}`;
        }
      }
      return '';
    },
    getUserUUID(userName) {
      if (userName) {
        var user = this.usersSorted.find(user => user.name == userName);
        if (user) {
          return user.uuid;
        }
      }
      return '';
    },
    kitchenAddressMessage() {
      if (this.kitchen.address == null || this.kitchen.address == '') {
        return 'Missing Kitchen Address';
      }
      return '';
    },
    kitchenAddressMessageType() {
      if (this.kitchen.address == null || this.kitchen.address == '') {
        return 'is-danger';
      }
      return 'is-light';
    },
    kitchenAddressLongitudeMessage() {
      if (this.kitchen.address_information.longitude  == null || this.kitchen.address_information.longitude  == '') {
        return 'Missing Kitchen Address Longitude';
      }
      return '';
    },
    kitchenAddressLongitudeMessageType() {
      if (this.kitchen.address_information.longitude  == null || this.kitchen.address_information.longitude  == '') {
        return 'is-danger';
      }
      return 'is-light';
    },
    kitchenAddressLatitudeMessage() {
      if (this.kitchen.address_information.latitude  == null || this.kitchen.address_information.latitude  == '') {
        return 'Missing Kitchen Address Latitude';
      }
      return '';
    },
    kitchenAddressLatitudeMessageType() {
      if (this.kitchen.address_information.latitude  == null || this.kitchen.address_information.latitude  == '') {
        return 'is-danger';
      }
      return 'is-light';
    },
    kitchenAddressPostcodeMessage() {
      if (this.kitchen.address_information.postcode  == null || this.kitchen.address_information.postcode  == '') {
        return 'Missing Kitchen Address Postcode';
      }
      return '';
    },
    kitchenAddressPostcodeMessageType() {
      if (this.kitchen.address_information.postcode  == null || this.kitchen.address_information.postcode  == '') {
        return 'is-danger';
      }
      return 'is-light';
    },
    idMessage() {
      if (!this.kitchen.id) {
        return 'Missing Kitchen ID';
      }
      return '';
    },
    cityMessage() {
      if (!this.kitchen.city) {
        return 'Missing Kitchen City';
      }
      return '';
    },
    countryMessage() {
      if (!this.kitchen.country) {
        return 'Missing Kitchen Country';
      }
      return '';
    },
    update() {
      var launch_date = this.kitchen.launch_date;
      if (this.kitchen.launch_date) {
        launch_date = new Date(launch_date.getTime() - launch_date.getTimezoneOffset() * 60000)
          .toISOString()
          .split('T')[0];
      }
      if (this.deliveryTimeStart) {
        var hour = this.addZero(this.deliveryTimeStart.getHours());
        var minute = this.addZero(this.deliveryTimeStart.getMinutes());
        this.$set(this.kitchen.supply.delivery_times, 'start_time', `${hour}:${minute}:00`)
      }
      if (this.deliveryTimeEnd) {
        var hour = this.addZero(this.deliveryTimeEnd.getHours());
        var minute = this.addZero(this.deliveryTimeEnd.getMinutes());
        this.$set(this.kitchen.supply.delivery_times, 'end_time', `${hour}:${minute}:00`)
      }
      this.$emit('updated', this.kitchen.uuid, {
        address: this.kitchen.address,
        address_information: this.kitchen.address_information,
        communication_channels: this.kitchen.communication_channels,
        company_name: this.kitchen.company_name,
        company_address: this.kitchen.company_address,
        company_type: this.kitchen.company_type,
        contacts: this.kitchen.contacts,
        deputy_id: this.kitchen.financial_informations.deputy_id || '',
        financial_informations: this.kitchen.financial_informations,
        fixed_costs: this.kitchen.financial_informations.fixed_costs || 0,
        id: this.kitchen.id,
        is_franchise: this.kitchen.is_franchise,
        kitchen_information: {
          building_code: this.kitchen.kitchen_information.building_code,
          deliveroo_back_office_password: this.kitchen.secrets.password_deliveroo_back_office,
          deliveroo_mail: this.kitchen.mails.mail_deliveroo_tech,
          inner_box_code: this.kitchen.kitchen_information.inner_box_code,
          instructions: this.kitchen.kitchen_information.instructions,
          kitchen_manager_mail: this.kitchen.mails.mail_manager,
          kitchen_manager_slack: this.kitchen.communication_channels.slack_id,
          mail: this.kitchen.mails.mail_cook,
          mail_password: this.kitchen.secrets.password_mail_cook,
          one_tablet_mail: this.kitchen.mails.mail_cook,
          one_tablet_pin: this.kitchen.secrets.pin_one_tablet,
          opsbase_id: this.kitchen.mails.mail_cook,
          opsbase_password: this.kitchen.secrets.password_opsbase,
          outer_box_code: this.kitchen.kitchen_information.outer_box_code,
          phone: this.kitchen.communication_channels.phone,
          slack: this.kitchen.communication_channels.slack_channel,
        },
        launch_date: launch_date,
        mails: this.kitchen.mails,
        management_information: this.kitchen.management_information,
        secrets: this.kitchen.secrets,
        supply: this.kitchen.supply,
        taster_commission: this.kitchen.financial_informations.taster_commission || 0,
      });
      this.$parent.close();
    },
    getAddressData: function(place, addressData, _) {
      this.kitchen.address = addressData.formatted_address;
      this.kitchen.address_information.city = this.location.city;
      this.kitchen.address_information.latitude = place.latitude;
      this.kitchen.address_information.longitude = place.longitude;
      this.kitchen.address_information.postcode = place.postal_code;
      this.kitchen.address_information.route = place.route;
      this.kitchen.address_information.street_number = place.street_number;
    },
    getDeliveryAddressData: function(place, addressData, _) {
      this.kitchen.supply.address = addressData.formatted_address;
    },
    getCompanyAddressData: function(place, addressData, _) {
      this.kitchen.company_address = addressData.formatted_address;
    },
    validationFieldType(field) {
      if (field) {
        return 'is-grey';
      }
      return 'is-danger';
    },
    close() {
      this.$emit('updateclosed', {});
      this.$parent.close();
    },
    addDeliveryMail(kitchen) {
      if (!kitchen.supply.delivery_mails_cc) {
        this.$set(kitchen.supply, 'delivery_mails_cc', [{'mail': ''}])
      }
      else {
        kitchen.supply.delivery_mails_cc.push({'mail': ''})
      }
    },
    addZero(timePart) {
      if (timePart < 10) {
        timePart = "0" + timePart;
      }
      return timePart;
    },
    getStartDeliveryDate(kitchen) {
      if (kitchen && kitchen.supply && kitchen.supply.delivery_times && kitchen.supply.delivery_times.start_time) {
        const time = kitchen.supply.delivery_times.start_time;
        const timeSplitted = time.split(':');
        const hour = timeSplitted[0];
        const minutes = timeSplitted[1];
        return new Date(90, 0, 0, hour, minutes);
      }
      return new Date(90, 0, 0, 0, 0);
    },
    getEndDeliveryDate(kitchen) {
      if (kitchen && kitchen.supply && kitchen.supply.delivery_times && kitchen.supply.delivery_times.end_time) {
        const time = kitchen.supply.delivery_times.end_time;
        const timeSplitted = time.split(':');
        const hour = timeSplitted[0];
        const minutes = timeSplitted[1];
        return new Date(90, 0, 0, hour, minutes);
      }
      return new Date(90, 0, 0, 0, 0);
    },
  },
};
</script>

<style>
.field:not(:last-child) {
  margin-bottom: 0px;
}
.google-autocomplete {
  display: block;
  width: 100%;
  font-size: 12px;
  height: 3.25em;
}
.pac-container {
  position: fixed !important;
}
hr
{
  background-color: black;
  border: outset;
}
.button-gap {
  margin-left: 8px;
}
</style>
