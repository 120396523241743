var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card",
      staticStyle: { "overflow-y": "auto", "justify-content": "center" },
    },
    [
      _c(
        "section",
        { staticClass: "modal-card-body has-text-centered" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: "Restaurant Platform ID",
                type: _vm.validationType(),
                message: _vm.validationMessage(),
              },
            },
            [
              _c("b-input", {
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isValid() &&
      !_vm.isInternalLaunch &&
      _vm.platformThatCanBeCheckUuids.includes(_vm.platformUUID)
        ? _c(
            "b-button",
            {
              staticStyle: { "margin-bottom": "16px" },
              attrs: {
                expanded: "",
                type: "button is-link",
                tag: "a",
                target: "_blank",
                href: _vm.platformCheckUrl,
              },
            },
            [_vm._v(" Verify ")]
          )
        : _vm._e(),
      _c(
        "b-button",
        {
          attrs: {
            expanded: "",
            disabled: !_vm.isValid(),
            type: "button is-success",
          },
          on: { click: _vm.updateValue },
        },
        [_vm._v("Set Up")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }