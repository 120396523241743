import tasksAPI from '../../../apis/onboarding/tasks';
import { tasksTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  current: null,
  current_error: '',
  fetchAll: {
    pending: false,
    error: null,
  },
  fetchOne: {
    pending: false,
    error: null,
  },
  createOne: {
    pending: false,
    error: null,
  },
  updateOne: {
    pending: false,
    error: null,
  },
  deleteOne: {
    pending: false,
    error: null,
  },
  newTask: {
    'country_uuids': [],
    'description': '',
    'label': '',
    'is_automatic': false,
    'is_checkable': false,
    'is_manual': true,
    'is_reopen_required': false,
    'links': [],
    'owner': '',
    'task_group_uuid': '',
    'task_dependencies': [],
    'task_type': 'ALL',
    'launch_type': 'ALL_LAUNCHES',
    'task_level': 'RESTAURANT_LEVEL',
  },
  newTaskEmpty: {
    'country_uuids': [],
    'description': '',
    'label': '',
    'is_automatic': false,
    'is_checkable': false,
    'is_manual': true,
    'is_reopen_required': false,
    'links': [],
    'owner': '',
    'task_group_uuid': '',
    'task_dependencies': [],
    'task_type': 'ALL',
    'launch_type': 'ALL_LAUNCHES',
    'task_level': 'RESTAURANT_LEVEL',
  },
};

const actions = {
  async fetchAll({ commit }, queryParameters) {
    commit(tasksTypes.FETCH_ALL_REQUEST);

    return tasksAPI.fetchAll(queryParameters)
      .then((tasks) => commit(tasksTypes.FETCH_ALL_SUCCESS, tasks))
      .catch((error) => commit(tasksTypes.FETCH_ALL_FAILURE, error));
  },
  async fetchOne({ commit }, taskUUID) {
    commit(tasksTypes.FETCH_ONE_REQUEST);

    return tasksAPI
      .fetchOne(taskUUID)
      .then(function(task) {
        commit(tasksTypes.FETCH_ONE_SUCCESS, task);
      })
      .catch(function(error) {
        commit(tasksTypes.FETCH_ONE_FAILURE, error.response.data.detail);
      });
  },
  async createOne({ commit }, payload) {
    commit(tasksTypes.CREATE_ONE_REQUEST);
    return tasksAPI
      .createOne(payload)
      .then(function(task) {
        commit(tasksTypes.CREATE_ONE_SUCCESS, task);
      })
      .catch(function(error) {
        commit(tasksTypes.CREATE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async updateOne({ commit }, payload) {
    var newPayload = {...payload}
    var taskUUID = payload.uuid;
    delete newPayload.uuid;
    commit(tasksTypes.UPDATE_ONE_REQUEST);
    return tasksAPI
      .updateOne(taskUUID, newPayload)
      .then(function(task) {
        commit(tasksTypes.UPDATE_ONE_SUCCESS, task);
      })
      .catch(function(error) {
        commit(tasksTypes.UPDATE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async deleteOne({ commit }, taskUUID) {
    commit(tasksTypes.DELETE_ONE_REQUEST);
    return tasksAPI
      .deleteOne(taskUUID)
      .then(function() {
        commit(tasksTypes.DELETE_ONE_SUCCESS, taskUUID);
      })
      .catch(function(error) {
        commit(tasksTypes.DELETE_ONE_FAILURE, error.response.data.detail);
      });
  },
  resetNewTask({ commit }) {
    commit(tasksTypes.RESET_NEW_TASK);
  },
};

const mutations = {
  [tasksTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [tasksTypes.FETCH_ALL_SUCCESS](state, tasks) {
    state.all = tasks;
    state.fetchAll.pending = false;
  },
  [tasksTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
  [tasksTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [tasksTypes.FETCH_ONE_SUCCESS](state, task) {
    state.current = task;
    const index = state.all.findIndex((item) => item.uuid === task.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, task);
    }
    state.fetchOne.pending = false;
    state.fetchOne.error = null;
  },
  [tasksTypes.FETCH_ONE_FAILURE](state, error) {
    state.current = null;
    state.fetchOne.pending = false;
    state.fetchOne.error = error;
  },
  [tasksTypes.CREATE_ONE_REQUEST](state) {
    state.createOne.pending = true;
  },
  [tasksTypes.CREATE_ONE_SUCCESS](state, task) {
    state.current = task;
    state.all.push(task);
    state.createOne.pending = false;
    state.createOne.error = null;
  },
  [tasksTypes.CREATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.createOne.pending = false;
    state.createOne.error = error;
  },
  [tasksTypes.UPDATE_ONE_REQUEST](state) {
    state.updateOne.pending = true;
  },
  [tasksTypes.UPDATE_ONE_SUCCESS](state, task) {
    state.current = task;
    const index = state.all.findIndex((item) => item.uuid === task.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, task);
    }
    state.updateOne.pending = false;
    state.updateOne.error = null;
  },
  [tasksTypes.UPDATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.updateOne.pending = false;
    state.updateOne.error = error;
  },
  [tasksTypes.DELETE_ONE_REQUEST](state) {
    state.deleteOne.pending = true;
  },
  [tasksTypes.DELETE_ONE_SUCCESS](state, taskUUID) {
    const index = state.all.findIndex((task) => task.uuid === taskUUID);
    if (index !== -1) {
      state.all.splice(index, 1);
    }
    state.deleteOne.pending = false;
    state.deleteOne.error = null;
  },
  [tasksTypes.DELETE_ONE_FAILURE](state, error) {
    state.deleteOne.pending = false;
    state.deleteOne.error = error;
  },
  [tasksTypes.RESET_NEW_TASK](state) {
    state.newTask = Object.assign({}, state.newTaskEmpty);
  },
};

const getters = {
  getNewTask: (state) => {
    return state.newTask;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
