import menuItemsAPI from '../../../apis/menu_manager/menuItems';
import { menuItemsTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  currentConceptUUID: null,
  fetchAll: {
    pending: false,
    error: null,
  },
};

const actions = {
  async fetchAll({ commit }, payload) {
    commit(menuItemsTypes.FETCH_ALL_REQUEST);
    return menuItemsAPI.fetchAll(payload)
      .then((menuItems) => commit(menuItemsTypes.FETCH_ALL_SUCCESS, {payload, menuItems}))
      .catch((error) => commit(menuItemsTypes.FETCH_ALL_FAILURE, error));
  },
};

const mutations = {
  [menuItemsTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [menuItemsTypes.FETCH_ALL_SUCCESS](state, response) {
    state.currentConceptUUID = response.payload.concept_uuid;
    state.all = response.menuItems.filter(menuItem => menuItem.menu_item_type == "MENU_ITEM");
    state.fetchAll.pending = false;
  },
  [menuItemsTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
