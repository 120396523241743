<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close" full-screen>
      <section>
        <div class="card" style="width: auto">
          <div class="card-content">
            <div class="columns is-flex">
              <div class="column is-narrow has-text-centered">
                <p class="title is-4"> {{campaignTitle}}</p>
              </div>
              <div class="column is-narrow" v-if="campaignConcept">
                <img
                  :src="campaignConcept.logo"
                  :alt="campaignConcept.label"
                  onerror="this.style.display='none'"
                  class="concept-logo"
                />
              </div>
              <div class="column is-narrow" v-if="campaignPlatform">
                <img
                  :src="campaignPlatform.logo"
                  :alt="campaignPlatform.label"
                  onerror="this.style.display='none'"
                  class="concept-logo"
                />
              </div>
            </div>
          </div>
          <section class="card-content">
            <b-tabs v-model="activeTab" expanded>
              <b-tab-item label="General">
                <CampaignGeneralForm
                  :activeStep.sync="activeStep"
                  :concepts="concepts"
                  :countries="countries"
                  :locations="locations"
                  :platforms="platforms"
                  :events="events"
                  :isAdhocCampaign="isAdhocCampaign"
                  :campaign.sync="currentCampaign"
                  :isEditingMode="isEditingMode"
                />
              </b-tab-item>
              <b-tab-item label="Campaign Type">
                <CampaignTypeForm
                  :countryLocation="campaignCountryLocation"
                  :campaign.sync="currentCampaign"
                  :isEditingMode="isEditingMode"
                  :campaignItems="campaignItems"
                  :menu="currentMenu"
                  @removeCampaignItem="removeItem"
                />
              </b-tab-item>
              <b-tab-item label="Kitchens">
                <b-button icon-left="plus" type="is-info is-small"
                  rounded
                  @click="openAddKitchensModal()"
                >
                  Add Kitchens to Current Campaign
                </b-button>
                <hr/>
                <b-tabs v-model="activeTabCategory" expanded>
                  <b-tab-item :label="getCategoryLabel(category)" v-for="(category, index) in kitchenCategories" :key="index" @input="selectCategory(category)">
                    <CampaignKitchensForm
                      :locations="locations"
                      :campaign.sync="currentCampaign"
                      :kitchenCampaignsInformations.sync="kitchenCampaignsInformations"
                      :isEditingMode="isEditingMode"
                      :category="category"
                    />
                  </b-tab-item>
                </b-tabs>
              </b-tab-item>
            </b-tabs>
          </section>
        </div>
    </section>
    <b-button expanded :disabled="isUpdateDisable" type="button is-link" @click="update">Update</b-button>
    </b-modal>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex';
  import { campaignsTypes } from '@/store/mutation-types';
  import { utils } from '@/mixins';
  import CampaignGeneralForm from '@/components/Marketing/Forms/CampaignGeneralForm.vue'
  import CampaignKitchensForm from '@/components/Marketing/Forms/CampaignKitchensForm.vue'
  import CampaignTypeForm from '@/components/Marketing/Forms/CampaignTypeForm.vue'
  import NewKitchensForm from '@/components/Marketing/Forms/NewKitchensForm.vue'

  export default {
    components: { CampaignGeneralForm, CampaignKitchensForm, CampaignTypeForm },
    mixins: [utils],
    data() {
      return {
        activeTab: 0,
        activeTabCategory: 0,
        openModal: true,
        activeStep: 0,
        isEditingMode:true,
        isAdhocCampaign: false,
        discountedItems: [],
      }
    },
    props: [],
    computed: {
      ...mapState({
        concepts(state) {
          return state.concepts.all.sort((concept1, concept2) =>
            concept1.label.localeCompare(concept2.label),
          );
        },
        countries: (state) => {
          return state.countries.all.sort((country1, country2) =>
            country1.label.localeCompare(country2.label),
          );
        },
        countrylocations(state) {
          return state.locations.all.filter(location => !location.parent_uuid && !location.is_deleted).sort((location1, location2) =>
            location1.label.localeCompare(location2.label),
          );
        },
        locations(state) {
          return state.locations.all.filter(location => location.parent_uuid && !location.is_deleted).sort((location1, location2) =>
            location1.label.localeCompare(location2.label),
          );
        },
        platforms(state) {
          return state.platforms.all.sort((platform1, platform2) =>
            platform1.label.localeCompare(platform2.label),
          );
        },
        currentCampaign: (state) => state.campaigns.current,
        currentEventLabel: (state) => state.events.currentEventLabel,
        currentCampaignItems: (state) => state.campaignItems.currents,
        currentKitchenCampaigns: (state) => state.kitchenCampaigns.currents,
        events: (state) => state.events.all,
        kitchens: (state) => state.kitchens.all,
        currentMenu: (state) => state.menuInformation,
        currentMenuConceptUUID: (state) => state.menuInformation.currentConceptUUID,
        currentMenuCountryLocationUUID: (state) => state.menuInformation.currentCountryLocationUUID,
        currentMenuItemsConceptUUID: (state) => state.menuItems.currentConceptUUID,
        isLoading: (state) => state.kitchens.fetchAll.pending || state.kitchenCampaigns.fetchAll.pending || state.kitchenCampaigns.updateOne.pending || state.campaignItems.createOne.pending || state.campaignItems.fetchAll.pending || state.campaignItems.deleteOne.pending || state.menuInformation.fetchAll.pending || state.menuItems.fetchAll.pending,
        error: (state) => state.kitchens.fetchAll.error || state.kitchenCampaigns.fetchAll.error || state.kitchenCampaigns.updateOne.error || state.campaignItems.fetchAll.error || state.campaignItems.deleteOne.error,
      }),
      campaignTitle() {
        if (this.currentCampaign && this.currentCampaign.label) {
          return this.currentCampaign.label
        }
        return 'New Campaign'
      },
      campaignConcept() {
        if (this.currentCampaign && this.currentCampaign.concept_uuid) {
          return this.concepts.find(concept => concept.uuid == this.currentCampaign.concept_uuid);
        }
        return null
      },
      campaignCountryLocation() {
        if (this.currentCampaign && this.currentCampaign.country_location_uuid) {
          return this.locations.find(location => location.uuid == this.currentCampaign.country_location_uuid);
        }
        return null
      },
      campaignPlatform() {
        if (this.currentCampaign && this.currentCampaign.platform_uuid) {
          return this.platforms.find(platform => platform.uuid == this.currentCampaign.platform_uuid);
        }
        return null
      },
      kitchenCategories() {
        return [null].concat(this.$store.getters['kitchens/getKitchenCategories']);
      },
      selectedCategory() {
        return this.kitchenCategories[this.activeTabCategory];
      },
      kitchenCampaignsInformations() {
        if (!this.currentCampaign) {
          return [];
        }
        var kitchenCampaignsInformations = [];
        for (let currentKitchenCampaign of this.currentKitchenCampaigns) {
          var kitchen = this.kitchens.find(kitchen => kitchen.location_uuid == currentKitchenCampaign.location_uuid);
          var location = this.locations.find(location => location.uuid == currentKitchenCampaign.location_uuid);
          if (location && kitchen && this.selectedCategory == this.getKitchenCategory(kitchen)) {
            kitchenCampaignsInformations.push({
              'locationLabel': location.label,
              'locationUUID': location.uuid,
              'campaignUUID': currentKitchenCampaign.campaign_uuid,
              'dates': [new Date(currentKitchenCampaign.start_date), new Date(currentKitchenCampaign.end_date)],
              'financingModel': currentKitchenCampaign.financing_model,
              'isUpdated': false,
              'isEligible': true,
              'concept_uuid': this.currentCampaign.concept_uuid,
              'location_uuid': currentKitchenCampaign.location_uuid,
              'platform_uuid': this.currentCampaign.platform_uuid,
              'uuid': currentKitchenCampaign.uuid,
            })
          }
        }
        return kitchenCampaignsInformations;
      },
      isUpdateDisable() {
        if (!this.currentCampaign) {
          return true;
        }
        if (this.currentCampaign.label == null || this.currentCampaign.label == '' || this.currentCampaign.start_date == null || this.currentCampaign.end_date == null) {
          return true;
        }
        if (this.currentCampaign.campaign_type == null) {
          return true;
        }
        if (this.isDiscountCampaign && (this.currentCampaign.discount_type == null || this.currentCampaign.discount_value || this.currentCampaign.discount_over)) {
          return true;
        }
        if (this.isDiscountItemCampaign && (this.currentCampaign.discount_type == null || this.currentCampaign.discount_value || this.currentCampaign.discount_over || this.currentCampaign.discounted_items.length == 0)) {
          return true;
        }
        return false;
      },
      campaignFinancingModels() {
        return this.$store.getters['campaigns/getCampaignFinancingModels'];
      },
      campaignItems() {
        if (this.currentCampaignItems) {
          return this.currentCampaignItems.map(
            campaignItem => {
                return {
                ...campaignItem,
                ...{
                  'foodCost': campaignItem.food_cost,
                  'reimbursedFoodCost': campaignItem.reimbursed_food_cost,
                }
              }
            }
          )
        }
        return null;
      }
    },
    methods: {
      ...mapActions('campaigns', {updateCampaign: 'updateOne', fetchCampaign: 'fetchOne'}),
      ...mapActions('events', {createEvent: 'createOne', fetchEvents: 'fetchAll'}),
      ...mapMutations('campaigns', { resetCampaign: campaignsTypes.RESET_ONE}),
      ...mapActions('kitchenCampaigns', {createKitchenCampaign: 'createOne', fetchKitchenCampaigns: 'fetchAll', updateKitchenCampaign: 'updateOne' }),
      ...mapActions('campaignItems', {fetchCampaignItems: 'fetchAll', createCampaignItem: 'createOne', removeCampaignItem: 'deleteOne'}),
      ...mapActions('menuInformation', { fetchMenuInformation: 'fetchAll' }),
      ...mapActions('menuItems', { fetchMenuItems: 'fetchAll' }),
      fetchMenu() {
        var isItemCampaign = this.currentCampaign && this.currentCampaign.campaign_type && (this.currentCampaign.campaign_type.toLowerCase().includes('discount_items') || this.currentCampaign.campaign_type.toLowerCase().includes('bogo') || this.currentCampaign.campaign_type.toLowerCase().includes('free_items'));
        // if (isItemCampaign && (this.currentCampaign.concept_uuid != this.currentMenuConceptUUID || this.currentCampaign.country_location_uuid != this.currentMenuCountryLocationUUID)) {
        //   // this.fetchMenuInformation({'concept_uuid': this.currentCampaign.concept_uuid, 'country_location_uuid': this.currentCampaign.country_location_uuid, 'country_uuid': this.countrylocations.find(location => location.uuid == this.currentCampaign.country_location_uuid).country_uuid});
        // }
        if (isItemCampaign && (this.currentCampaign.concept_uuid != this.currentMenuItemsConceptUUID)) {
          this.fetchMenuItems({'concept_uuid': this.currentCampaign.concept_uuid});
        }
      },
      update() {
        this.discountedItems = this.currentCampaign.discounted_items;
        var startDate = this.currentCampaign.dates[0];
        var endDate = this.currentCampaign.dates[1];
        var startDateFormatted = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
          .toISOString()
          .split('T')[0];
        var endDateFormatted = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
          .toISOString()
          .split('T')[0];
        var maxOptinDateFormatted = new Date(this.currentCampaign.max_optin_date.getTime() - this.currentCampaign.max_optin_date.getTimezoneOffset() * 60000)
          .toISOString()
          .split('T')[0];

        this.getOrCreateEvent()
            .then((event) => {
              this.updateCampaign(
                {
                  'label': this.currentCampaign.label,
                  'campaign_scope': this.currentCampaign.campaign_scope,
                  'estimated_uplift': this.currentCampaign.estimated_uplift,
                  'discount_type': this.currentCampaign.discount_type,
                  'discount_value': this.currentCampaign.discount_value,
                  'discount_over': this.currentCampaign.discount_over,
                  'budget': this.currentCampaign.budget,
                  'end_date': endDateFormatted,
                  'start_date': startDateFormatted,
                  'max_optin_date': maxOptinDateFormatted,
                  'uuid': this.currentCampaign.uuid,
                  'event_uuid': event.uuid,
                }
              ).then(() => {
                var campaignItemToCreate = this.createCampaignItems(this.currentCampaign);
                var kitchenCampaignToUpdate = this.getUpdatedKitchenCampaigns();
                Promise.all([
                  ...campaignItemToCreate.map((campaignItem) => this.createCampaignItem(campaignItem)),
                  ...kitchenCampaignToUpdate.map((kitchenCampaign) => this.updateKitchenCampaign(kitchenCampaign)),
                ]).then(() => {
                  this.close();
                })
            })
          }
        )
      },
      /* eslint-disable */
      getOrCreateEvent() {
        var event = null;
        if (this.currentEventLabel) {
          event = this.events.find(event => event.label == this.currentEventLabel);
        }
        else {
          event = this.events.find(event => event.uuid == this.currentCampaign.event_uuid);
        }
        if (event) {
          var p = new Promise(function(resolve, reject) {
            if(true) { // eslint-disable-line no-constant-condition
              resolve(event);
            }
          });
          return p
        }
        if (!this.currentEventLabel) {
          var p = new Promise(function(resolve, reject) {
            if(true) { // eslint-disable-line no-constant-condition
              resolve({'uuid': null});
            }
          });
          return p
        }
        return this.createEvent({
          'label': this.currentEventLabel
        })
      },
      getUpdatedKitchenCampaigns() {
        var kitchenCampaignsToUpdate = [];
        var kitchenCampaignsUpdated = this.kitchenCampaignsInformations.filter(kitchenCampaign => kitchenCampaign.isUpdated);
        for (let kitchenCampaignInfo of kitchenCampaignsUpdated) {
          var startDate = kitchenCampaignInfo.dates[0];
          var endDate = kitchenCampaignInfo.dates[1];
          var startDateFormatted = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
            .toISOString()
            .split('T')[0];
          var endDateFormatted = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
            .toISOString()
            .split('T')[0];
          kitchenCampaignsToUpdate.push({
            'start_date': startDateFormatted,
            'end_date': endDateFormatted,
            'financing_model': kitchenCampaignInfo.financingModel,
            'uuid': kitchenCampaignInfo.uuid,
          })
        }
        return kitchenCampaignsToUpdate
      },
      createCampaignItems(campaign) {
        var campaignItemToCreate = [];
        if (this.shouldCreateCampaignItem(campaign) && this.discountedItems && this.discountedItems.length > 0) {
          for (let discountedItem of this.discountedItems) {
            campaignItemToCreate.push(
              {
                'campaign_uuid': campaign.uuid,
                'item_uuid': discountedItem.uuid,
                'label': discountedItem.label,
                'food_cost': discountedItem.foodCost || 0,
                'selling_price': discountedItem.selling_price || 0,
                'reimbursed_food_cost': discountedItem.reimbursedFoodCost || 0,
              }
            )
          }
        }
        return campaignItemToCreate
      },
      shouldCreateCampaignItem(campaign) {
        return campaign.campaign_type && (campaign.campaign_type.toLowerCase().includes('discount_items') || campaign.campaign_type.toLowerCase().includes('bogo') || campaign.campaign_type.toLowerCase().includes('free_items') );
      },
      openAddKitchensModal: function() {
        this.$buefy.modal.open({
          component: NewKitchensForm,
          parent: this,
          hasModalCard: true,
          events: {
            createKitchenCampaigns: (opts) => {
              var startDate = this.currentCampaign.dates[0];
              var endDate = this.currentCampaign.dates[1];
              var kitchenCampaignsToCreate = [];
              for (let locationUUID of opts.locationUUIDS) {
                var startDateFormatted = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
                  .toISOString()
                  .split('T')[0];
                var endDateFormatted = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
                  .toISOString()
                  .split('T')[0];
                kitchenCampaignsToCreate.push({
                  'campaign_uuid': this.currentCampaign.uuid,
                  'location_uuid': locationUUID,
                  'start_date': startDateFormatted,
                  'end_date': endDateFormatted,
                  'financing_model': this.campaignFinancingModels[0],
                })
              }
              Promise.all([
                ...kitchenCampaignsToCreate.map((kitchenCampaign) => this.createKitchenCampaign(kitchenCampaign))
              ]).then(() =>  this.fetchKitchenCampaigns({ 'campaignUUID': this.$route.params.campaignUUID }))
            },
          },
          props: {
            'conceptUUID': this.currentCampaign.concept_uuid,
            'platformUUID': this.currentCampaign.platform_uuid,
            'countryLocationUUID': this.currentCampaign.country_location_uuid,
            'existingLocationUUIDS': this.currentKitchenCampaigns.map(kitchenCampaignInfo => kitchenCampaignInfo.location_uuid),
          }
        })
      },
      removeItem(campaignItemUUID) {
        this.removeCampaignItem(campaignItemUUID).then(() => this.fetchCampaignItems({campaignUUID: this.$route.params.campaignUUID }));
      },
      close() {
        this.resetCampaign();
        this.$router.push('/marketing')
      },
    },
    mounted() {
      Promise.all(
        [
          this.fetchCampaign(this.$route.params.campaignUUID),
          this.fetchEvents(),
          this.fetchKitchenCampaigns({campaignUUID: this.$route.params.campaignUUID }),
          this.fetchCampaignItems({campaignUUID: this.$route.params.campaignUUID }),
          this.getKitchens(''),
          this.fetchMenu(),
          this.getConcepts(''),
          this.getCountries(''),
          this.getPlatforms(''),
          this.getLocations(''),
        ]
      ).then(() => {
        if (!this.currentMenu || this.currentMenu.all.length == 0) {
          this.fetchMenu()
        }
      })
  }
}
</script>
