var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.openModal, scroll: "keep", "on-cancel": _vm.close },
      on: {
        "update:active": function ($event) {
          _vm.openModal = $event
        },
      },
    },
    [
      _c(
        "section",
        { staticClass: "modal-card-body has-text-centered" },
        [
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                { attrs: { label: "Company Name", expanded: "" } },
                [
                  _c("b-input", {
                    attrs: {
                      type: "text",
                      required: "",
                      placeholder: "Company Name",
                    },
                    model: {
                      value: _vm.companyName,
                      callback: function ($$v) {
                        _vm.companyName = $$v
                      },
                      expression: "companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "Company Type", expanded: "" } },
                [
                  _c(
                    "b-select",
                    {
                      attrs: {
                        type: "text",
                        placeholder: "Company Type",
                        expanded: "",
                      },
                      model: {
                        value: _vm.companyType,
                        callback: function ($$v) {
                          _vm.companyType = $$v
                        },
                        expression: "companyType",
                      },
                    },
                    _vm._l(_vm.companyTypes, function (companyType, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: companyType } },
                        [_vm._v(" " + _vm._s(companyType) + " ")]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b", [_vm._v(" Company Address ")]),
          _c(
            "b-switch",
            {
              attrs: {
                "passive-type": "is-success",
                "false-value": "Google Map",
                "true-value": "Manual",
                type: "is-danger",
                size: "is-small",
              },
              model: {
                value: _vm.manualCompanyAddressMode,
                callback: function ($$v) {
                  _vm.manualCompanyAddressMode = $$v
                },
                expression: "manualCompanyAddressMode",
              },
            },
            [_vm._v(" " + _vm._s(_vm.manualCompanyAddressMode) + " ")]
          ),
          _c(
            "b-field",
            { attrs: { label: "", expanded: "" } },
            [
              _vm.manualCompanyAddressMode == "Google Map"
                ? _c("vue-google-autocomplete", {
                    attrs: {
                      id: "mapCompany",
                      classname: "google-autocomplete",
                      country: ["fr", "uk", "es", "be", "nl"],
                    },
                    on: { placechanged: _vm.getCompanyAddressData },
                    model: {
                      value: _vm.companyAddress,
                      callback: function ($$v) {
                        _vm.companyAddress = $$v
                      },
                      expression: "companyAddress",
                    },
                  })
                : _c("b-input", {
                    attrs: { type: "text", placeholder: "Address" },
                    model: {
                      value: _vm.companyAddress,
                      callback: function ($$v) {
                        _vm.companyAddress = $$v
                      },
                      expression: "companyAddress",
                    },
                  }),
            ],
            1
          ),
          _c(
            "b-button",
            {
              attrs: {
                expanded: "",
                disabled: _vm.isUpdateDisable(),
                type: "button is-success",
              },
              on: { click: _vm.updateValue },
            },
            [_vm._v("Set Up")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }