<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close" full-screen>
      <section>
        <div class="card" style="width: auto">
          <section class="card-content">
            <div class="has-text-centered">
              <p class="title is-4">Re Opening</p>
            </div>
            <b-field grouped>
              <b-field label="Country">
                <b-select type="text" v-model="selectedCountry" placeholder="Country" expanded>
                  <option
                    v-for="(kitchenCountry, index) in getExistingKitchenCountries(countries, locations, kitchens)"
                    :key="index"
                    :value="kitchenCountry.uuid"
                  >
                    {{ kitchenCountry.label }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Licensee">
                <b-select type="text" v-model="selectedPartner" placeholder="Licensee" expanded>
                  <option v-for="(kitchenStat, index) in kitchenStatus" :key="index" :value="kitchenStat.value">
                    {{ kitchenStat.label }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Kitchen" expanded>
                <b-select type="text" v-model="selectedKitchen" placeholder="Kitchen" expanded>
                  <option v-for="(kitchen, index) in getExistingKitchens(selectedCountry, selectedPartner, locations, kitchens, true, null)" :key="index" :value="kitchen">
                    {{ kitchen.id }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <div class="has-text-centered">
              <b-field style="justify-content: center; margin-bottom: 16px;" v-if="selectedKitchen">
                <div v-if="availableConcepts && availableConcepts.length > 0">
                  <h4 class="title has-text-centered is-4">Select Concept</h4>
                  <b-radio
                    v-for="(concept, index) in availableConcepts"
                    v-model="selectedConcept"
                    :key="index"
                    :value="concept"
                    name="concept"
                    :native-value="concept"
                  >
                    <div v-if="concept.logo"><img :src="concept.logo" alt="" class="image" width="48" /></div>
                    <div v-else>{{ concept.label }}</div>
                  </b-radio>
                </div>
              </b-field>
            </div>
            <div class="has-text-centered">
              <b-field style="justify-content: center; margin-top: 16px;" v-if="selectedConcept">
                <div v-if="availablePlatforms && availablePlatforms.length > 0">
                  <h4 class="title has-text-centered is-4">Select Platform</h4>
                  <div class="block">
                    <b-checkbox
                      v-for="(platform, index) in availablePlatforms"
                      v-model="selectedPlatforms"
                      :key="index"
                      :value="platform"
                      name="platform"
                      :native-value="platform"
                    >
                      <div v-if="platform.logo"><img :src="platform.logo" alt="" class="image" width="48" /></div>
                    </b-checkbox>
                  </div>
                </div>
              </b-field>
            </div>
            <div class="has-text-centered" v-if="selectedPlatforms && selectedPlatforms.length > 0">
              <b-field label="Open Date">
                <b-datepicker
                  v-model="openDate"
                  placeholder="Click to select..."
                  icon="calendar-today"
                  position="is-bottom-right"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <hr />
            <b-button
              v-if="
                selectedCountry &&
                selectedPartner != null &&
                selectedKitchen &&
                selectedConcept &&
                selectedPlatforms.length > 0
                && openDate
              "
              type="is-success"
              class="button-gap"
              @click="openRestaurant()"
              expanded
            >
              Open
            </b-button>
          </section>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { utils } from '@/mixins';

export default {
  mixins: [utils],
  components: {},
  props: ['countries', 'concepts', 'kitchens', 'locations', 'platforms'],
  data() {
    return {
      openModal: true,
      id: null,
      openDate: null,
      kitchenStatus: [
        { label: 'Franchise', value: true },
        { label: 'Taster', value: false },
      ],
      selectedConcept: null,
      selectedCountry: null,
      selectedPartner: null,
      selectedKitchen: null,
      selectedPlatforms: [],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.actions.openRestaurants.pending,
    }),
    availableKitchens() {
      var todayDate = new Date();
      var availableKitchens = [];
      for (let kitchen of this.kitchens) {
        for (let restaurant of kitchen.restaurants || []) {
          if(!restaurant.is_active && new Date(restaurant.launch_date) < todayDate) {
            availableKitchens.push(kitchen);
            break;
          }
        }
      }
      return availableKitchens;
    },
    availableConcepts() {
      if (!this.selectedKitchen || !this.selectedKitchen.restaurants || this.selectedKitchen.restaurants.length == 0) {
        return [];
      }
      var concepts = [];
      var existingRestaurantsConceptUUIDS = this.selectedKitchen.restaurants
        .filter((restaurant) => !restaurant.is_deleted && !restaurant.is_active)
        .map((restaurant) => restaurant.concept_uuid);
      for (let concept of this.concepts) {
        if (!concept.is_deleted && existingRestaurantsConceptUUIDS.includes(concept.uuid)) {
          concepts.push(concept);
        }
      }
      return concepts;
    },
    availablePlatforms() {
      if (
        !this.selectedKitchen ||
        !this.selectedKitchen.restaurants ||
        this.selectedKitchen.restaurants.length == 0 ||
        !this.selectedConcept
      ) {
        return [];
      }
      var platforms = [];
      var existingRestaurantsplatformUUIDS = this.selectedKitchen.restaurants
        .filter(
          (restaurant) =>
            !restaurant.is_deleted && restaurant.concept_uuid == this.selectedConcept.uuid && !restaurant.is_active,
        )
        .map((restaurant) => restaurant.platform_uuid);
      for (let platform of this.platforms) {
        if (!platform.is_deleted && existingRestaurantsplatformUUIDS.includes(platform.uuid)) {
          platforms.push(platform);
        }
      }
      return platforms;
    },
  },
  methods: {
    ...mapActions('onboardingActions', ['openRestaurantsAction']),
    openRestaurant() {
      var openDateFormatted = new Date(this.openDate.getTime() - this.openDate.getTimezoneOffset() * 60000)
          .toISOString()
          .split('T')[0];
      this.$buefy.dialog.confirm({
        title: `Re-Open ${this.selectedConcept.label} in ${this.selectedKitchen.id}`,
        message: `The Onboarding will appear again with <b>${openDateFormatted}</b> as launch date and you will have to complete some of steps`,
        confirmText: `Open`,
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          var body = {
            concept_uuid: this.selectedConcept.uuid,
            kitchen_uuid: this.selectedKitchen.uuid,
            location_uuid: this.selectedKitchen.location_uuid,
            platform_uuids: this.selectedPlatforms.map((platform) => platform.uuid),
            open_date: openDateFormatted,
          };
          this.openRestaurantsAction(body)
              .then(() => this.close().then(() => this.close()));
        },
      });
    },
    close() {
      this.$parent.close();
      this.$parent.close();
    },
  },
};
</script>


<style>

.my-modal .modal-close {
  background: red;
}

</style>
