<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <p class="title is-4"> Send Uber Mail POS Integration </p>
    <section class="modal-card-body has-text-centered">
      <b-field label="Your Taster Mail (e.g jai.kharbanda@taster.com, sebastien.rybka@taster.com...)" expanded>
        <b-input type="email" v-model="value"></b-input>
      </b-field>
    </section>
      <b-button expanded :disabled="value == null || !value.includes('@taster')" type="button is-success" @click="update">Send Mail</b-button>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      value: null,
    }
  },
  props: [],
  computed: {},
  methods: {
    update() {
      this.$emit('update', this.value);
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
