<template>
  <div class="box modal-card modal-container">
    <b-loading :active.sync="isLoading"></b-loading>
    <div class="columns is-mobile">
      <div class="column is-narrow">
        <p class="title is-4">Offboarding</p>
      </div>
    </div>
    <b-steps v-model="activeStep" size="is-small" type="is-info" :has-navigation="false">
      <b-step-item label="Closure Type" step="1">
        <div>
          <div class="has-text-centered">
            <b-button type="is-danger" class="button-gap" @click="getKitchenClosureType('final')" expanded>
              Final Closure
            </b-button>
          </div>
          <div class="has-text-centered">
            <b-button type="is-warning" class="button-gap" @click="getKitchenClosureType('temporary')" expanded>
              Temporary Closure
            </b-button>
          </div>
        </div>
      </b-step-item>
      <b-step-item label="Restaurant Selection" step="2">
        <div class="has-text-centered">
          <p class="title is-4" v-if="isFinalClosure">Final Closure</p>
          <p class="title is-4" v-else>Temporary Closure</p>
        </div>
        <b-field grouped>
          <b-field label="Country">
            <b-select type="text" v-model="selectedCountry" placeholder="Country" expanded>
              <option
                v-for="(kitchenCountry, index) in getExistingKitchenCountries(countries, locations, kitchens)"
                :key="index"
                :value="kitchenCountry.uuid"
              >
                {{ kitchenCountry.label }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Licensee">
            <b-select type="text" v-model="selectedPartner" placeholder="Licensee" expanded>
              <option v-for="(kitchenStat, index) in kitchenStatus" :key="index" :value="kitchenStat.value">
                {{ kitchenStat.label }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Kitchen" expanded>
            <b-select type="text" v-model="selectedKitchen" placeholder="Kitchen" expanded>
              <option v-for="(kitchen, index) in getExistingKitchens(selectedCountry, selectedPartner, locations, kitchens, false, null)" :key="index" :value="kitchen">
                {{ kitchen.id }}
              </option>
            </b-select>
          </b-field>
        </b-field>
        <div class="has-text-centered">
          <b-field style="justify-content: center; margin-bottom: 16px;" v-if="selectedKitchen">
            <div v-if="availableConcepts && availableConcepts.length > 0">
              <h4 class="title has-text-centered is-4">Select Concept</h4>
              <b-radio
                v-for="(concept, index) in availableConcepts"
                v-model="selectedConcept"
                :key="index"
                :value="concept"
                name="concept"
                :native-value="concept"
              >
                <div v-if="concept.logo"><img :src="concept.logo" alt="" class="image" width="48" /></div>
                <div v-else>{{ concept.label }}</div>
              </b-radio>
            </div>
          </b-field>
        </div>
        <div class="has-text-centered">
          <b-field style="justify-content: center; margin-top: 16px;" v-if="selectedConcept">
            <div v-if="availablePlatforms && availablePlatforms.length > 0">
              <h4 class="title has-text-centered is-4">Select Platform</h4>
              <div class="block">
                <b-checkbox
                  v-for="(platform, index) in availablePlatforms"
                  v-model="selectedPlatforms"
                  :key="index"
                  :value="platform"
                  name="platform"
                  :native-value="platform"
                >
                  <div v-if="platform.logo"><img :src="platform.logo" alt="" class="image" width="48" /></div>
                </b-checkbox>
              </div>
            </div>
          </b-field>
        </div>
        <hr />
        <div v-if="isFinalClosure">
          <b-field style="justify-content: center;" v-if="selectedConcept && selectedPlatforms.length > 0" grouped>
            <b-field label="Close Date">
              <b-datepicker
                v-model="closeDate"
                placeholder="Click to select..."
                icon="calendar-today"
                position="is-bottom-right"
                trap-focus
              >
              </b-datepicker>
            </b-field>
            <b-field label="Remove Onboarding ?">
              <b-checkbox v-model="removeOnboarding" type="is-warning" true-value="Yes" false-value="No">
                {{ removeOnboarding }}
              </b-checkbox>
            </b-field>
            <b-field label="Close Kitchen ?" v-if="availableConcepts.length == 1">
              <b-checkbox v-model="closeKitchen" type="is-warning" true-value="Yes" false-value="No">
                {{ closeKitchen }}
              </b-checkbox>
            </b-field>
          </b-field>
        </div>
        <div v-if="selectedKitchen && isFinalClosure && availableConcepts && availableConcepts.length == 0">
          <b-field style="justify-content: center;" grouped>
            <b-field label="Close Date">
              <b-datepicker
                v-model="closeDate"
                placeholder="Click to select..."
                icon="calendar-today"
                position="is-bottom-right"
                trap-focus
              >
              </b-datepicker>
            </b-field>
            <b-field label="Remove Onboarding ?">
              <b-checkbox v-model="removeOnboarding" type="is-success" true-value="Yes" false-value="No">
                {{ removeOnboarding }}
              </b-checkbox>
            </b-field>
            <b-field label="Close Kitchen ?">
              <b-checkbox v-model="closeKitchen" type="is-success" true-value="Yes" false-value="No">
                {{ closeKitchen }}
              </b-checkbox>
            </b-field>
          </b-field>
        </div>
        <b-button
          v-if="
            selectedCountry &&
              selectedPartner != null &&
              selectedKitchen &&
              ((closeDate && isFinalClosure) || !isFinalClosure) &&
              ((selectedConcept && selectedPlatforms.length > 0) || (availableConcepts.length == 0 && closeKitchen))
          "
          type="is-danger"
          class="button-gap"
          @click="closeRestaurant()"
          expanded
        >
          Close
        </b-button>
      </b-step-item>
    </b-steps>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { utils } from '@/mixins';

export default {
  mixins: [utils],
  components: {},
  props: ['countries', 'concepts', 'kitchens', 'locations', 'platforms'],
  data() {
    return {
      id: null,
      closeDate: null,
      isFinalClosure: null,
      kitchenStatus: [
        { label: 'Franchise', value: true },
        { label: 'Taster', value: false },
      ],
      selectedConcept: null,
      selectedCountry: null,
      selectedPartner: null,
      selectedKitchen: null,
      selectedPlatforms: [],
      closeKitchen: 'No',
      removeOnboarding: 'No',
      activeStep: 0,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.actions.closeRestaurants.pending,
    }),
    availableConcepts() {
      if (!this.selectedKitchen || !this.selectedKitchen.restaurants || this.selectedKitchen.restaurants.length == 0) {
        return [];
      }
      var concepts = [];
      var existingRestaurantsConceptUUIDS = this.selectedKitchen.restaurants
        .filter((restaurant) => !restaurant.is_deleted)
        .map((restaurant) => restaurant.concept_uuid);
      for (let concept of this.concepts) {
        if (!concept.is_deleted && existingRestaurantsConceptUUIDS.includes(concept.uuid)) {
          concepts.push(concept);
        }
      }
      return concepts;
    },
    availablePlatforms() {
      if (
        !this.selectedKitchen ||
        !this.selectedKitchen.restaurants ||
        this.selectedKitchen.restaurants.length == 0 ||
        !this.selectedConcept
      ) {
        return [];
      }
      var platforms = [];
      var existingRestaurantsplatformUUIDS = this.selectedKitchen.restaurants
        .filter(
          (restaurant) =>
            !restaurant.is_deleted && restaurant.concept_uuid == this.selectedConcept.uuid,
        )
        .map((restaurant) => restaurant.platform_uuid);
      for (let platform of this.platforms) {
        if (!platform.is_deleted && existingRestaurantsplatformUUIDS.includes(platform.uuid)) {
          platforms.push(platform);
        }
      }
      return platforms;
    },
  },
  methods: {
    ...mapActions('onboardingActions', ['closeRestaurantsAction']),
    showStep(stepNumber) {
      if (this.activeStep == stepNumber || this.activeStep == stepNumber + 1 || this.activeStep == stepNumber + 2) {
        return true;
      }
      return false;
    },
    getKitchenClosureType(closureType) {
      this.isFinalClosure = closureType == 'final';
      this.activeStep += 1;
    },
    closeRestaurant() {
      var closeMessage = '';
      if (this.isFinalClosure) {
        closeMessage =
          'Are you sure you want to <b>close</b>. This action will be run directly (even with close date in the future) and cannot be undone.';
      } else {
        closeMessage =
          'Are you sure you want to <b>close temporary</b>. This action will be run directly.';
      }
      var closeMessageTitle = '';
      if (this.selectedConcept) {
        closeMessageTitle = `Close ${this.selectedConcept.label} in ${this.selectedKitchen.id}`;
      }
      else {
        closeMessageTitle = `Close ${this.selectedKitchen.id}`;
      }
      this.$buefy.dialog.confirm({
        title: closeMessageTitle,
        message: closeMessage,
        confirmText: `Close`,
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          var closeDateFormatted = null;
          if (this.closeDate) {
            closeDateFormatted = new Date(this.closeDate.getTime() - this.closeDate.getTimezoneOffset() * 60000)
              .toISOString()
              .split('T')[0];
          }
          var conceptUUID = null;
          if (this.selectedConcept) {
            conceptUUID = this.selectedConcept.uuid;
          }
          var body = {
            concept_uuid: conceptUUID,
            kitchen_uuid: this.selectedKitchen.uuid,
            location_uuid: this.selectedKitchen.location_uuid,
            platform_uuids: this.selectedPlatforms.map((platform) => platform.uuid),
            close_date: closeDateFormatted,
            close_kitchen: this.closeKitchen === 'Yes',
            remove_onboarding: this.removeOnboarding === 'Yes',
            temporary_closure: !this.isFinalClosure,
          };
          if (!this.closeDate) {
            delete body.close_date;
          }
          this.closeRestaurantsAction(body)
              .then(() => this.close());
        },
      });
    },
    close() {
      this.$parent.close();
      this.$parent.close();
    },
  },
};
</script>

<style scoped>
.modal-container {
  overflow-y: auto;
}
</style>
