import axios from '@/axios';
import { basePlatformStateUrl } from '@/apis'


const ressourcePath = `${basePlatformStateUrl}/menu-information`;


export default {
/* eslint-disable */
  fetchAll(opts) {
    return axios.post(ressourcePath, opts)
                .then(response => response.data)
  },
};
