<template>
  <div>
    <div class="has-text-centered">
      <b-button type="is-link" :disabled="!canCreateOrEditAccess()" class="button-gap" @click="createMemberForm()" expanded>
        Create New Member
      </b-button>
    </div>
    <div class="has-text-centered">
      <b-button type="is-info" :disabled="!canCreateOrEditAccess()" class="button-gap"  @click="selectExistingMemberForm()" expanded>
        Select Existing Member
      </b-button>
    </div>
    <div class="has-text-centered">
      <b-button type="is-danger" :disabled="!canDeleteAccess()" class="button-gap"  @click="removeExistingMemberForm()" expanded>
        Remove Existing Member
      </b-button>
    </div>
  </div>
</template>

<script>
  import { auth, utils } from '@/mixins';

  export default {
    data() {
      return {}
    },
    mixins: [auth, utils],
    props: ['activeStep', 'newUser', 'removeUserAction'],
    computed: {},
    methods: {
      createMemberForm() {
        this.$emit('update:newUser', true);
        this.$emit('update:activeStep', 1);
      },
      selectExistingMemberForm() {
        this.$emit('update:newUser', false);
        this.$emit('update:removeUserAction', false);
        this.$emit('update:activeStep', 1);
      },
      removeExistingMemberForm() {
        this.$emit('update:newUser', false);
        this.$emit('update:removeUserAction', true);
        this.$emit('update:activeStep', 1);
      },
      close() {
        this.$parent.close();
      },
    }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
