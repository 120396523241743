import campaignItemsAPI from '../../../apis/marketing/campaignItems';
import { campaignItemsTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  current: null,
  currents: [],
  current_error: '',
  fetchAll: {
    pending: false,
    error: null,
  },
  fetchOne: {
    pending: false,
    error: null,
  },
  createOne: {
    pending: false,
    error: null,
  },
  updateOne: {
    pending: false,
    error: null,
  },
  deleteOne: {
    pending: false,
    error: null,
  },
  newCampaignItem: {
    'campaign_uuid': null,
    'food_cost': null,
    'item_uuid': null,
    'label': null,
    'reimbursed_food_cost': null,
    'uuid': null,
    'created_at': null,
    'updated_at': null,
    'is_deleted': null
  },
  newCampaignItemEmpty: {
    'campaign_uuid': null,
    'food_cost': null,
    'item_uuid': null,
    'label': null,
    'reimbursed_food_cost': null,
    'uuid': null,
    'created_at': null,
    'updated_at': null,
    'is_deleted': null
  },
};

const actions = {
  async fetchAll({ commit }, opts) {
    commit(campaignItemsTypes.FETCH_ALL_REQUEST);

    return campaignItemsAPI.fetchAll(opts)
      .then((campaignItems) => {
        commit(campaignItemsTypes.FETCH_ALL_SUCCESS, {response: campaignItems, payload: opts})
        return campaignItems;
        })
      .catch((error) => commit(campaignItemsTypes.FETCH_ALL_FAILURE, error));
  },
  async fetchOne({ commit }, campaignItemUUID) {
    commit(campaignItemsTypes.FETCH_ONE_REQUEST);

    return campaignItemsAPI
      .fetchOne(campaignItemUUID)
      .then(function(campaignItem) {
        commit(campaignItemsTypes.FETCH_ONE_SUCCESS, campaignItem);
      })
      .catch(function(error) {
        commit(campaignItemsTypes.FETCH_ONE_FAILURE, error.response.data.detail);
      });
  },
  async createOne({ commit }, payload) {
    commit(campaignItemsTypes.CREATE_ONE_REQUEST);

    return campaignItemsAPI
      .createOne(payload)
      .then(function(campaignItem) {
        commit(campaignItemsTypes.CREATE_ONE_SUCCESS, campaignItem);
        return campaignItem;
      })
      .catch(function(error) {
        commit(campaignItemsTypes.CREATE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async updateOne({ commit }, payload) {
    var newPayload = {...payload}
    var campaignItemUUID = payload.uuid;
    delete newPayload.uuid;
    commit(campaignItemsTypes.UPDATE_ONE_REQUEST);
    return campaignItemsAPI
      .updateOne(campaignItemUUID, newPayload)
      .then(function(campaignItem) {
        commit(campaignItemsTypes.UPDATE_ONE_SUCCESS, campaignItem);
        return campaignItem;
      })
      .catch(function(error) {
        commit(campaignItemsTypes.UPDATE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async deleteOne({ commit }, campaignItemUUID) {
    commit(campaignItemsTypes.DELETE_ONE_REQUEST);

    return campaignItemsAPI
      .deleteOne(campaignItemUUID)
      .then(function() {
        commit(campaignItemsTypes.DELETE_ONE_SUCCESS, campaignItemUUID);
      })
      .catch(function(error) {
        commit(campaignItemsTypes.DELETE_ONE_FAILURE, error.response.data.detail);
      });
  },
  reset({ commit }) {
    commit(campaignItemsTypes.RESET_ONE);
  },
};

const mutations = {
  [campaignItemsTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [campaignItemsTypes.FETCH_ALL_SUCCESS](state, {response, payload}) {
    if (!payload.campaignUUID) {
      state.all = response.items.filter(item => !item.is_deleted);
      state.total = response.total;
    }
    else {
      state.currents = response.items.filter(item => !item.is_deleted);
      state.total_current = response.total;
    }
    state.fetchAll.pending = false;
  },
  [campaignItemsTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
  [campaignItemsTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [campaignItemsTypes.FETCH_ONE_SUCCESS](state, campaignItem) {
    state.current = campaignItem;
    const index = state.all.findIndex((item) => item.uuid === campaignItem.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, campaignItem);
    }
    state.fetchOne.pending = false;
    state.fetchOne.error = null;
  },
  [campaignItemsTypes.FETCH_ONE_FAILURE](state, error) {
    state.current = null;
    state.fetchOne.pending = false;
    state.fetchOne.error = error;
  },
  [campaignItemsTypes.CREATE_ONE_REQUEST](state) {
    state.createOne.pending = true;
  },
  [campaignItemsTypes.CREATE_ONE_SUCCESS](state, campaignItem) {
    state.current = campaignItem;
    state.all.push(campaignItem);
    state.currentCampaignItemLabel = null;
    state.createOne.pending = false;
    state.createOne.error = null;
  },
  [campaignItemsTypes.CREATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.createOne.pending = false;
    state.createOne.error = error;
  },
  [campaignItemsTypes.UPDATE_ONE_REQUEST](state) {
    state.updateOne.pending = true;
  },
  [campaignItemsTypes.UPDATE_ONE_SUCCESS](state, campaignItem) {
    // state.current = campaignItem;
    const index = state.all.findIndex((item) => item.uuid === campaignItem.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, campaignItem);
    }
    state.updateOne.pending = false;
    state.updateOne.error = null;
  },
  [campaignItemsTypes.UPDATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.updateOne.pending = false;
    state.updateOne.error = error;
  },
  [campaignItemsTypes.DELETE_ONE_REQUEST](state) {
    state.deleteOne.pending = true;
  },
  [campaignItemsTypes.DELETE_ONE_SUCCESS](state, campaignItemUUID) {
    const index = state.all.findIndex((campaignItem) => campaignItem.uuid === campaignItemUUID);
    if (index !== -1) {
      state.all.splice(index, 1);
    }
    state.deleteOne.pending = false;
    state.deleteOne.error = null;
  },
  [campaignItemsTypes.DELETE_ONE_FAILURE](state, error) {
    state.deleteOne.pending = false;
    state.deleteOne.error = error;
  },
  [campaignItemsTypes.RESET_ONE](state) {
    state.newCampaignItem = Object.assign({}, state.newCampaignItemEmpty);
  },
  [campaignItemsTypes.SET_CURRENT](state, campaignItem) {
    state.current = campaignItem;
  },
};

const getters = {
  getnewCampaignItem: (state) => {
    return {...state.newCampaignItem};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
