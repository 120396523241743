<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <section class="modal-card-body has-text-centered">
    <p class="title is-4"> Create Deliveroo Tech Account </p>
      <b-steps v-model="activeStep" size="is-small" type="is-info" :has-navigation="false" v-if="launch.is_internal">
        <b-step-item label="Explanation" step="1">
          <div class="has-text-centered">
            <b-button type="is-link" class="button-gap" @click="() => this.activeStep = 2" expanded>
              New Kitchen
            </b-button>
          </div>
          <div class="has-text-centered">
            <b-button type="is-info" class="button-gap" @click="() => this.activeStep = 1" expanded>
              Existing Kitchen
            </b-button>
          </div>
        </b-step-item>
        <b-step-item label="Existing Kitchen" step="2">
          <b-table :data="dataOldKitchen">
            <template slot-scope="props">
              <b-table-column field="id" label="ID">
                  <b-tag type="is-info"> {{ props.row.id }} </b-tag>
              </b-table-column>
              <b-table-column field="step" label="Step">
                {{ props.row.step }}
              </b-table-column>
              <b-table-column field="info" label="Info">
                  <b-button
                    v-if="props.row.link"
                    tag="a"
                    type="is-link"
                    :href=props.row.link.url
                    target="_blank">
                    {{props.row.link.label}}
                  </b-button>
                  <div v-else>
                  <strong> {{ props.row.info }} </strong>
                  </div>
              </b-table-column>
            </template>
          </b-table>
          <div class="has-text-centered">
            <b-button type="is-info" class="button-gap" @click="updateExisting()" expanded>
              Done
            </b-button>
          </div>
        </b-step-item>
        <b-step-item label="New Kitchen" step="3">
          <b-table :data="dataNewKitchen">
            <template slot-scope="props">
              <b-table-column field="id" label="ID">
                  <b-tag type="is-info"> {{ props.row.id }} </b-tag>
              </b-table-column>
              <b-table-column field="step" label="Step">
                  {{ props.row.step }}
              </b-table-column>
              <b-table-column field="info" label="Info">
                <b-button
                  v-if="props.row.link"
                  tag="a"
                  type="is-link"
                  :href=props.row.link.url
                  target="_blank">
                  {{props.row.link.label}}
                </b-button>
                <div v-else>
                  <strong> {{ props.row.info }} </strong>
                </div>
              </b-table-column>
            </template>
          </b-table>
          <div class="has-text-centered">
            <b-button type="is-info" class="button-gap" @click="() => this.activeStep = 3" expanded>
              Next
            </b-button>
          </div>
        </b-step-item>
        <b-step-item label="Tablet Information" step="4">
          <b-field label="Deliveroo Account Password" expanded>
            <b-input v-model="password"></b-input>
          </b-field>
          <b-button expanded :disabled="password == null" type="button is-success" @click="updateValue">Update</b-button>
        </b-step-item>
      </b-steps>
    <b-steps v-model="activeStep" size="is-small" type="is-info" :has-navigation="false" v-else>
        <b-step-item label="New Kitchen" step="1">
          <b-table :data="dataExternalKitchen">
            <template slot-scope="props">
              <b-table-column field="id" label="ID">
                  <b-tag type="is-info"> {{ props.row.id }} </b-tag>
              </b-table-column>
              <b-table-column field="step" label="Step">
                  {{ props.row.step }}
              </b-table-column>
              <b-table-column field="info" label="Info">
                <b-button
                  v-if="props.row.link"
                  tag="a"
                  type="is-link"
                  :href=props.row.link.url
                  target="_blank">
                  {{props.row.link.label}}
                </b-button>
                <div v-else>
                  <strong> {{ props.row.info }} </strong>
                </div>
              </b-table-column>
            </template>
          </b-table>
          <div class="has-text-centered">
            <b-button type="is-info" class="button-gap" @click="() => this.activeStep = 1" expanded>
              Next
            </b-button>
          </div>
        </b-step-item>
        <b-step-item label="Tablet Information" step="2">
          <b-field label="Deliveroo Account" expanded>
            <b-input v-model="username"></b-input>
          </b-field>
          <b-field label="Deliveroo Account Password" expanded>
            <b-input v-model="password"></b-input>
          </b-field>
          <b-button expanded :disabled="password == null" type="button is-success" @click="updateExternal">Update</b-button>
        </b-step-item>
      </b-steps>
    </section>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      activeStep: 0,
      usernameDyn: null,
      password: null,
      dataOldKitchen: [
        {
          'id': 1,
          'step': 'Go to Hub',
          'link': {
            'label': 'Hub',
            'url': 'https://restaurant-hub.deliveroo.net/admin/users?orgId=all&branchId=all',
            'info': null,
          }
        },
        {
          'id': 2,
          'step': 'Find and Edit following user',
          'link': null,
          'info': `${this.kitchen.mails.mail_deliveroo_tech}`,
        },
        {
          'id': 3,
          'step': 'Select missing Kitchen\'s restaurants in the list',
          'link': null,
          'info': null,
        },
      ],
      dataNewKitchen: [
        {
          'id': 1,
          'step': 'Go to Hub',
          'link': {
            'label': 'Hub',
            'url': 'https://restaurant-hub.deliveroo.net/admin/users?orgId=all&branchId=all',
            'info': null,
          }
        },
        {
          'id': 2,
          'step': 'Select "Add new user"',
          'link': null,
          'info': null,
        },
        {
          'id': 4,
          'step': `Use Name :`,
          'link': null,
          'info': `Tech${this.kitchen.id}`,
        },
        {
          'id': 5,
          'step': `Use Following Mail:`,
          'link': null,
          'info': `tech-deliveroo+${this.kitchen.id.toLowerCase()}@taster.com`
        },
        {
          'id': 6,
          'step': 'Select following access',
          'link': null,
          'info': 'Personal Access',
        },
        {
          'id': 7,
          'step': 'Select Kitchen\'s restaurants in the list',
          'link': null,
          'info': null,
        },
        {
          'id': 8,
          'step': 'Click on send Invitation',
          'link': null,
          'info': null,
        },
        {
          'id': 8,
          'step': 'You now have to connect to Gmail Inbox with following account to accept invitation',
          'link': null,
          'info': 'mail : \'tech-deliveroo@taster.com\' & password \'Techdeliverootaster*\'',
        },
        {
          'id': 9,
          'step': 'Generate Password & copy it for next step',
          'link': null,
          'info': '',
        },
      ],
      dataExternalKitchen: [
        {
          'id': 1,
          'step': 'Go to Hub',
          'link': {
            'label': 'Hub',
            'url': 'https://restaurant-hub.deliveroo.net/admin/users?orgId=all&branchId=all',
            'info': null,
          }
        },
        {
          'id': 2,
          'step': 'Select "Add new user"',
          'link': null,
          'info': null,
        },
        {
          'id': 4,
          'step': `Use Name :`,
          'link': null,
          'info': `Tech${this.kitchen.id}`,
        },
        {
          'id': 5,
          'step': `Use Following Mail:`,
          'link': null,
          'info': `tech-deliveroo+${this.kitchen.id.toLowerCase()}-ot@taster.com`
        },
        {
          'id': 6,
          'step': 'Select following access',
          'link': null,
          'info': 'Admin (Limited)',
        },
        {
          'id': 7,
          'step': 'Select Kitchen\'s restaurants in the list',
          'link': null,
          'info': null,
        },
        {
          'id': 8,
          'step': 'Click on send Invitation',
          'link': null,
          'info': null,
        },
        {
          'id': 8,
          'step': 'You now have to connect to Gmail Inbox with following account to accept invitation',
          'link': null,
          'info': 'mail : \'tech-deliveroo@taster.com\' & password \'Techdeliverootaster*\'',
        },
        {
          'id': 9,
          'step': 'Generate Password & copy it for next step',
          'link': null,
          'info': '',
        },
      ]
    }
  },
  props: ['kitchen', 'launch'],
  computed: {
    username: {
      get() {
        return `tech-deliveroo+${this.kitchen.id.toLowerCase()}-ot@taster.com`
      },
      set(value) {
        this.usernameDyn = value;
      }
    }
  },
  methods: {
    updateValue() {
      this.$emit('update', {
        'kitchen_information': {
          'deliveroo_back_office_password': this.password,
          'deliveroo_mail': `tech-deliveroo+${this.kitchen.id.toLowerCase()}@taster.com`
        },
        'mails': {
          'mail_deliveroo_tech': `tech-deliveroo+${this.kitchen.id.toLowerCase()}@taster.com`,
        },
        'secrets': {
          'password_deliveroo_back_office': this.password,
        }
      });
      this.$emit('close');
    },
    updateExisting() {
      this.$emit('updateExisting');
      this.$emit('close');
    },
    updateExternal() {
      this.$emit('updateExternal', {
        'username': `tech-deliveroo+${this.kitchen.id.toLowerCase()}-ot@taster.com`,
        'password': this.password,
        }
      );
      this.$emit('close');
    }
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
