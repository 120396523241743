const errorToaster = {
  watch: {
    isSaveLoading(value) {
      if (!value) {
        if (!this.saveError) {
          this.$buefy.toast.open({
            message: 'Successfully saved',
            type: 'is-success',
            position: 'is-bottom',
          });
        } else {
          this.$buefy.toast.open({
            message: 'Saving failed',
            type: 'is-danger',
            position: 'is-bottom',
          });
        }
      }
    },
  },
};

const errorToasterDetailled = {
  watch: {
    isLoading(value) {
      if (!value) {
        if (!this.error && this.displaySuccess) {
          this.$buefy.toast.open({
            message: 'Successfully saved',
            type: 'is-success',
            position: 'is-bottom',
          });
        } else {
          if (this.error) {
            this.$buefy.toast.open({
              duration: 10000,
              message: this.error,
              type: 'is-danger',
              position: 'is-bottom',
            });
          }
        }
      }
    },
  },
};

import roles from '@/router/roles';
import {groups} from '@/router/groups';

const utils = {
  methods: {
    setsAreEqual(a, b) {
      if (a.size !== b.size) {
        return false;
      }

      return Array.from(a).every(element => {
        return b.has(element);
      });
    },
    formatDate(dateToFormat) {
      return dateToFormat.toISOString()
                         .split('T')[0];
    },
    checkExistence(element) {
      if (element === null || element === undefined || (element && element.length == 0)) {
        return false;
      }
      return true;
    },
    getKitchens(queryParameters, fetch) {  // to benefit from then()
      if (!this.checkExistence(this.$store.state.kitchens.all) || fetch || (queryParameters.includes('load_restaurants=true') && this.$store.state.kitchens.all && this.$store.state.kitchens.all[0].restaurants == null)) {
        return this.$store.dispatch('kitchens/getKitchens', queryParameters);
      }
      var p = new Promise(function(resolve, reject) {
        if(true) { // eslint-disable-line no-constant-condition
          resolve('Success!');
        }
        else {
          reject('Failure!');
        }
      });
      return p
    },
    getConcepts(queryParameters, fetch) {  // to benefit from then()
      if (!this.checkExistence(this.$store.state.concepts.all) || fetch) {
        return this.$store.dispatch('concepts/getConcepts', queryParameters);
      }
      var p = new Promise(function(resolve, reject) {
        if(true) { // eslint-disable-line no-constant-condition
          resolve('Success!');
        }
        else {
          reject('Failure!');
        }
      });
      return p
    },
    getCountries(queryParameters, fetch) {  // to benefit from then()
      if (!this.checkExistence(this.$store.state.countries.all) || fetch) {
        return this.$store.dispatch('countries/getCountries', queryParameters);
      }
      var p = new Promise(function(resolve, reject) {
        if(true) { // eslint-disable-line no-constant-condition
          resolve('Success!');
        }
        else {
          reject('Failure!');
        }
      });
      return p
    },
    getPlatforms(queryParameters, fetch) {  // to benefit from then()
      if (!this.checkExistence(this.$store.state.platforms.all) || fetch) {
        return this.$store.dispatch('platforms/getPlatforms', queryParameters);
      }
      var p = new Promise(function(resolve, reject) {
        if(true) { // eslint-disable-line no-constant-condition
          resolve('Success!');
        }
        else {
          reject('Failure!');
        }
      });
      return p
    },
    getLocations(queryParameters, fetch) {  // to benefit from then()
      if (!this.checkExistence(this.$store.state.locations.all) || fetch) {
        return this.$store.dispatch('locations/getLocations', queryParameters);
      }
      var p = new Promise(function(resolve, reject) {
        if(true) { // eslint-disable-line no-constant-condition
          resolve('Success!');
        }
        else {
          reject('Failure!');
        }
      });
      return p
    },
    getGroups() {  // to benefit from then()
      if (!this.checkExistence(this.$store.state.accesses.groups)) {
        return this.fetchGroups();
      }
      var p = new Promise(function(resolve, reject) {
        if(true) { // eslint-disable-line no-constant-condition
          resolve('Success!');
        }
        else {
          reject('Failure!');
        }
      });
      return p
    },
    getUsers() {  // to benefit from then()
      if (!this.checkExistence(this.$store.state.accesses.users)) {
        return this.fetchUsers();
      }
      var p = new Promise(function(resolve, reject) {
        if(true) { // eslint-disable-line no-constant-condition
          resolve('Success!');
        }
        else {
          reject('Failure!');
        }
      });
      return p
    },
    getExistingKitchenCountries(countries, locations, kitchens) {
      var correspondingCountries = [];
      var countryUUIDS = [];
      for (let kitchen of kitchens) {
        var location = locations.find(location => location.uuid == kitchen.location_uuid);
        var country = countries.find(country => country.uuid == location.country_uuid);
        if (!countryUUIDS.includes(location.country_uuid)) {
          correspondingCountries.push({
            label: country.label,
            uuid: country.uuid,
          });
        }
        countryUUIDS.push(country.uuid);
      }
      return correspondingCountries.sort((country1, country2) => country1.label.localeCompare(country2.label));
    },
    getExistingKitchens(countryUUID, partnerType, locations, kitchens, filterOnRestaurants, kitchenUUID) {
      var correspondingKitchens = [];
      if (countryUUID == null || partnerType == null) {
        return [];
      }
      var countryLocationUUIDs = locations.filter(location => location.country_uuid == countryUUID).map(location => location.uuid);
      correspondingKitchens = kitchens.filter(
        (kitchen) =>
          countryLocationUUIDs.includes(kitchen.location_uuid) &&
          kitchen.is_franchise == partnerType &&
          !kitchen.is_deleted
        );
      if (filterOnRestaurants) {
        correspondingKitchens = correspondingKitchens.filter(kitchen => kitchen.restaurants.filter(restaurant => !restaurant.is_deleted).length > 0);
      }
      if (kitchenUUID) {
        correspondingKitchens = correspondingKitchens.filter(kitchen => kitchen.uuid != kitchenUUID);
      }
      return correspondingKitchens.sort((kitchen1, kitchen2) => kitchen1.id.localeCompare(kitchen2.id));
    },
    getKitchenCategory(kitchen) {
      if (kitchen && kitchen.management_information && kitchen.management_information.category){
        return kitchen.management_information.category
      }
      return null;
    },
    getCategoryLabel(category) {
      if (!category){
        return 'No Category';
      }
      return category;
    },
    getCountryLocationUUID(country_uuid) {
      if (country_uuid) {
        var countryLocation = this.locations.find((location) => location.country_uuid == country_uuid && !location.parent_uuid);
        if (countryLocation) {
          return countryLocation.uuid;
        }
      }
      return null;
    },
    getCountryLocations(locations, countryLocationUUID) {
      if (locations && countryLocationUUID) {
        locations = locations.filter(location => location.parent_uuid == countryLocationUUID);
        if (locations) {
          return locations.sort((location1, location2) => location1.label.localeCompare(location2.label))
        }
      }
      return []
    },
    getKitchenWithGivenConceptAndPlatform(kitchens, conceptUUID, platformUUID, locationUUIDS) {
      var kitchensFiltered = kitchens.filter(kitchen => locationUUIDS.includes(kitchen.location_uuid))
                             .filter(kitchen => kitchen.restaurants && kitchen.restaurants.map(restaurant => restaurant.concept_uuid).includes(conceptUUID))
      if (platformUUID) {
        kitchensFiltered = kitchensFiltered.filter(kitchen => kitchen.restaurants.map(restaurant => restaurant.platform_uuid).includes(platformUUID))
      }
      return kitchensFiltered
    },
    getMenus(queryParameters, fetch) {  // to benefit from then()
      if (!this.checkExistence(this.$store.state.menus.all) || fetch ) {
        return this.$store.dispatch('menus/fetchAll', queryParameters);
      }
      var p = new Promise(function(resolve, reject) {
        if(true) { // eslint-disable-line no-constant-condition
          resolve('Success!');
        }
        else {
          reject('Failure!');
        }
      });
      return p
    },
    getMenuVariants(queryParameters, fetch) {  // to benefit from then()
      if (!this.checkExistence(this.$store.state.menus.all) || fetch ) {
        return this.$store.dispatch('menus/fetchAllVariants', queryParameters);
      }
      var p = new Promise(function(resolve, reject) {
        if(true) { // eslint-disable-line no-constant-condition
          resolve('Success!');
        }
        else {
          reject('Failure!');
        }
      });
      return p
    },
  }
}

const auth = {
  methods: {
    canEditFinancialInformation() {
      return this.$auth.getUserRoles().includes(roles.financialInformationEditor) || this.$auth.getUserRoles().includes(roles.kitchenReferenceUser);
    },
    isLaunchAdmin() {
      return this.$auth.getUserRoles().includes(roles.kitchenTechAdministrator);
    },
    isEngineer() {
      return this.$auth.getUserGroups().includes(groups.Engineers);
    },
    canEditInformation() {
      return this.$auth.getUserGroups().includes(groups.Engineers) || this.$auth.getUserGroups().includes(groups.Managers) || this.$auth.getUserRoles().includes(roles.kitchenTechAdministrator);
    },
    canCreateOrEditAccess() {
      return this.$auth.getUserGroups().includes(groups.Engineers) || this.$auth.getUserGroups().includes(groups.ExpansionManagers) || this.$auth.getUserGroups().includes(groups.Support) || this.$auth.getUserRoles().includes(roles.userManager);
    },
    canDeleteAccess() {
      return this.$auth.getUserGroups().includes(groups.Engineers) || this.$auth.getUserGroups().includes(groups.Managers) || this.$auth.getUserRoles().includes(roles.userManager);
    },
    getUserConcepts() {
      let reg = new RegExp(`/rights/concepts/*/(.+?)`);
      let matched = this.groups.filter((group) => {
          return group.match(reg);
      });
      let concepts = [];
      matched.forEach((group) => {
          let splitted = group.split('/');
          concepts.push(splitted[splitted.length - 1]);
      });
      return new Set(concepts);
    },
    getUserLocations() {
      let reg = new RegExp(`/rights/locations/*/(.+?)`);
      let matched = this.groups.filter((group) => {
          return group.match(reg);
      });
      let locations = [];
      matched.forEach((group) => {
          let splitted = group.split('/');
          locations.push(splitted[splitted.length - 1]);
      });
      return new Set(locations);
    },
  }
}

const commons = {
  methods: {
    formatDateWord(date) {
      return `${date.getDate()} ${this.capitalizeFirstLetter(date.toLocaleString('default', { month: 'long' }))} ${date.getFullYear()}`;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getDateWithOffset(date = new Date(), offset) {
      var computedDate = new Date(date);
      if (offset > 0) {
        offset = offset + 1;
      }
      computedDate.setDate(computedDate.getDate() + offset);
      return computedDate;
    }
  }
}

const marketingCalendar = {
  methods: {
    getMarketingCalendarInfo(kitchens, campaigns, kitchenCampaigns, events, countryLocationUUID, conceptUUID, locationUUID, platformUUID) {
      var kitchenCampaignsInformationsByCategory = {};
      var kitchenLocations = this.getKitchenLocations(countryLocationUUID, locationUUID);
      var filteredCampaigns = this.getFilteredCampaigns(campaigns, kitchenCampaigns, kitchenLocations, conceptUUID, platformUUID);
      var filteredKitchenCampaigns = this.getFilteredKitchenCampaigns(filteredCampaigns, kitchenCampaigns, kitchenLocations, conceptUUID, platformUUID);
      for (let kitchenCampaign of filteredKitchenCampaigns) {
        var kitchen = kitchens.find(kitchen => kitchen.location_uuid == kitchenCampaign.location_uuid);
        var campaign = filteredCampaigns.find(campaign => campaign.uuid == kitchenCampaign.campaign_uuid);
        var category = null;
        if (kitchen && kitchen.management_information && kitchen.management_information.category) {
          category = kitchen.management_information.category;
        }
        if (!kitchenCampaignsInformationsByCategory.hasOwnProperty(category)) {
          kitchenCampaignsInformationsByCategory[category] = [];
        }
        var eventLabel = '';
        var event = events.find(event => event.uuid == campaign.event_uuid);
        if (event) {
          eventLabel = event.label;
        }
        kitchenCampaignsInformationsByCategory[category].push(
          {
              campaignUUID : campaign.uuid,
              conceptUUID: campaign.concept_uuid,
              platformUUID: campaign.platform_uuid,
              label: campaign.label,
              startDate: kitchenCampaign.start_date,
              endDate: kitchenCampaign.end_date,
              status: kitchenCampaign.status,
              eventLabel: eventLabel,
              uuid: kitchenCampaign.uuid,
              campaignType: campaign.discount_type,
              estimatedUplift: campaign.estimated_uplift,
              financingModel: kitchenCampaign.financing_model,
          }
        )
      }
      var kitchenCampaignsInformationsByCategoryUnique = {}
      for (let key of Object.keys(kitchenCampaignsInformationsByCategory)) {
        kitchenCampaignsInformationsByCategoryUnique[key] = {'campaigns': []}
        var uniqueCampaignUUIDS = [];
        for (let campaign of kitchenCampaignsInformationsByCategory[key]) {
          if (!uniqueCampaignUUIDS.includes(campaign.campaignUUID)) {
            kitchenCampaignsInformationsByCategoryUnique[key]['campaigns'].push(campaign);
            uniqueCampaignUUIDS.push(campaign.campaignUUID)
          }
        }
      }
      return kitchenCampaignsInformationsByCategoryUnique;
    },
    getKitchenLocations(countryLocationUUID, locationUUID) {
      if (!locationUUID) {
        return this.locations.filter(location => location.parent_uuid == countryLocationUUID && !location.is_deleted).sort((location1, location2) =>
          location1.label.localeCompare(location2.label),
      );
      }
      return this.locations.filter(location => location.uuid == locationUUID && !location.is_deleted);
    },
    getFilteredCampaigns: function(allCampaigns, allKitchenCampaigns, kitchenLocations, conceptUUID, platformUUID) {
      var conceptUUIDs = this.concepts.map(concept => concept.uuid);
      if (conceptUUID) {
        conceptUUIDs = [conceptUUID];
      }
      var platformUUIDs = this.platforms.map(platform => platform.uuid);
      if (platformUUID) {
        platformUUIDs = [platformUUID];
      }
      var campaignsWithCurrentConceptAndPlatform = allCampaigns.filter(campaign => conceptUUIDs.includes(campaign.concept_uuid) && platformUUIDs.includes(campaign.platform_uuid));
      var allCampaignUUIDS = new Set(
        allKitchenCampaigns.filter(kitchenCampaign => kitchenLocations.map(location => location.uuid).includes(kitchenCampaign.location_uuid) && kitchenCampaign.status != this.cancelState).map(kitchenCampaign => kitchenCampaign.campaign_uuid)
      );
      var campaignsWithCurrentConceptAndPlatformAndKitchenCampaigns = campaignsWithCurrentConceptAndPlatform.filter(campaign => allCampaignUUIDS.has(campaign.uuid));
      return campaignsWithCurrentConceptAndPlatformAndKitchenCampaigns;
    },
    getFilteredKitchenCampaigns: function(campaigns, allKitchenCampaigns, kitchenLocations, conceptUUID, platformUUID) {
      var kitchenLocationUUIDS = Array.from(new Set(
        kitchenLocations.map(kitchenLocation => kitchenLocation.uuid)
      ));
      return allKitchenCampaigns.filter(kitchenCampaign => campaigns.map(campaign => campaign.uuid).includes(kitchenCampaign.campaign_uuid) && kitchenCampaign.concept_uuid == conceptUUID && kitchenCampaign.platform_uuid == platformUUID && kitchenLocationUUIDS.includes(kitchenCampaign.location_uuid) && kitchenCampaign.status != this.cancelState);
    },
  }
}

export { errorToaster, errorToasterDetailled, auth, utils, commons, marketingCalendar };
