<template>
  <div class="card-kitchenInformation" v-if="currentManagementInformation && managementType">
    <Container
    orientation="horizontal"
    drag-handle-selector=".column-drag-handle"
    @drag-start="dragStart"
    :drop-placeholder="upperDropPlaceholderOptions"
    >
    <Draggable v-for="column in kitchenInformation.children" :key="getColumnUniqueID(column)">
      <div :class="column.props.className">
      <div class="card-column-header">
        {{ getName(column.name) }}
        </div>
      <Container
        group-name="col"
        @drop="(e) => onCardDrop(column.id, e)"
        :get-child-payload="getCardPayload(column.id)"
        drag-class="card-ghost"
        drop-class="card-ghost-drop"
        :drop-placeholder="dropPlaceholderOptions"
      >
        <Draggable v-for="card in column.children" :key="card.id">
        <div :class="card.props.className" :style="card.props.style">
          <!-- <span class="column-drag-handle">&#x2630;</span> -->
          <p><b>{{ card.data.id }} </b></p>
        </div>
        </Draggable>
      </Container>
        </div>
    </Draggable>
    </Container>
  </div>
</template>


<script>
import { mapMutations, mapState } from 'vuex';
import { kitchensTypes } from '@/store/mutation-types';
import { Container, Draggable } from "vue-smooth-dnd";

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  const result = [...arr];
  let itemToAdd = payload;
  itemToAdd.data.isUpdated = true;
  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }
  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }
  return result;
};

const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};

const cardColors = [
  'khaki',
  'azure',
  'bisque',
  'blanchedalmond',
  'burlywood',
  'cornsilk',
  'gainsboro',
  'ghostwhite',
  'ivory',
  'beige',
  'khaki',
  'azure',
  'bisque',
  'blanchedalmond',
  'burlywood',
  'cornsilk',
  'gainsboro',
  'ghostwhite',
  'ivory',
  'beige',
]

export default {
  props: ['index', 'managementType', 'kitchens'],
  components: {
    Container,
    Draggable,
  },
  data() {
    return {
      upperDropPlaceholderOptions: {
        className: 'cards-drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
    }
  },
  computed: {
    ...mapState({
      currentKitchensManagementInformation: (state) => state.kitchens.currentKitchensManagementInformation,
      isLoading: (state) => state.kitchens.fetchAll.pending,
      users: (state) => state.accesses.users,
    }),
    currentManagementInformation() {
      if (this.managementType.value == 'am') {
        return this.kitchensByAM;
      }
      else if (this.managementType.value == 'om') {
        return this.kitchensByOM;
      }
      else if (this.managementType.value == 'sm') {
        return this.kitchensBySM;
      }
      return this.kitchensByTier;
    },
    kitchensByTier() {
      var kitchensByType = {};
      for (let kitchen of this.kitchens) {
        var type = null;
        if (kitchen.management_information && kitchen.management_information.category) {
          type = kitchen.management_information.category;
        }
        if (!kitchensByType.hasOwnProperty(type)) {
          kitchensByType[type] = [];
        }
        kitchensByType[type].push(kitchen);
      }
      return this.sort(kitchensByType);
    },
    kitchensBySM() {
      var kitchensByType = {};
      for (let kitchen of this.kitchens) {
        var type = null;
        if (kitchen.management_information && kitchen.management_information.sales_manager_uuid) {
          type = kitchen.management_information.sales_manager_uuid;
        }
        if (!kitchensByType.hasOwnProperty(type)) {
          kitchensByType[type] = [];
        }
        kitchensByType[type].push(kitchen);
      }
      return this.sort(kitchensByType);
    },
    kitchensByAM() {
      var kitchensByType = {};
      for (let kitchen of this.kitchens) {
        var type = null;
        if (kitchen.management_information && kitchen.management_information.account_manager_uuid) {
          type = kitchen.management_information.account_manager_uuid;
        }
        if (!kitchensByType.hasOwnProperty(type)) {
          kitchensByType[type] = [];
        }
        kitchensByType[type].push(kitchen);
      }
      return this.sort(kitchensByType);
    },
    kitchensByOM() {
      var kitchensByType = {};
      for (let kitchen of this.kitchens) {
        var type = null;
        if (kitchen.management_information && kitchen.management_information.operation_manager_uuid) {
          type = kitchen.management_information.operation_manager_uuid;
        }
        if (!kitchensByType.hasOwnProperty(type)) {
          kitchensByType[type] = [];
        }
        kitchensByType[type].push(kitchen);
      }
      return this.sort(kitchensByType);
    },
    usersSorted() {
      var usersSorted = [...this.users]
      for (let user of usersSorted) {
        user.name = `${user.first_name} ${user.last_name}`;
      }
      return usersSorted.sort((user1, user2) => user1.first_name.localeCompare(user2.first_name));
    },
    kitchenInformation: {
      get() {
        if (this.currentKitchensManagementInformation && this.managementType.field == this.currentKitchensManagementInformation.field) {
          return this.currentKitchensManagementInformation;
        }
        var uniqueTypes = Array.from(Object.keys(this.currentManagementInformation)).sort();
        return {
          type: 'container',
          props: {
            orientation: 'horizontal'
          },
          field: this.managementType.field,
          children: generateItems(uniqueTypes.length, i => ({
            id: `column${i}`,
            type: 'container',
            name: uniqueTypes[i],
            props: {
              orientation: 'vertical',
              className: 'card-container'
            },
            children: generateItems(this.currentManagementInformation[uniqueTypes[i]].length, j => ({
              type: 'draggable',
              id: `${i}${j}`,
              props: {
                className: 'card',
                style: {backgroundColor: cardColors[i]}
              },
              data: {
                'uuid': this.currentManagementInformation[uniqueTypes[i]][j].uuid,
                'id': this.currentManagementInformation[uniqueTypes[i]][j].id,
                'isUpdated': false,
              }
            }))
          }))
        }
      },
      set(value) {
        this.setCurrentManagementInformations(
          value
        )
      }
    },
  },
  methods: {
    ...mapMutations('kitchens', { setCurrentManagementInformations: kitchensTypes.SET_CURRENT_MANAGEMENT_INFORMATION }),
    sort(kitchensByType) {
      var sortedKitchensByType = {}
      for (let type of Object.keys(kitchensByType)) {
        sortedKitchensByType[type] = kitchensByType[type].sort((kitchen1, kitchen2) =>
          kitchen1.id.localeCompare(kitchen2.id),
        );
      }
      return sortedKitchensByType;
    },
    getName(name) {
      if (this.managementType && this.managementType.type == 'user') {
        return this.getUser(name);
      }
      return name;
    },
    getUser(userUUID) {
      if (userUUID) {
        var user = this.users.find(user => user.uuid == userUUID);
        if (user) {
          return `${user.first_name} ${user.last_name}`;
        }
      }
      return '';
    },
    onCardDrop (columnId, dropResult) {
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const kitchenInformation = Object.assign({}, this.kitchenInformation)
        const column = kitchenInformation.children.filter(p => p.id === columnId)[0]
        const columnIndex = kitchenInformation.children.indexOf(column)
        const newColumn = Object.assign({}, column)
        newColumn.children = applyDrag(newColumn.children, dropResult)
        kitchenInformation.children.splice(columnIndex, 1, newColumn)
        this.kitchenInformation = kitchenInformation
      }
    },
    getCardPayload (columnId) {
      return index => {
        return this.kitchenInformation.children.filter(p => p.id === columnId)[0].children[index]
      }
    },
    dragStart () {},
    getColumnUniqueID(column) {
      return column.children.map(child => child.data.uuid).join('_');
    },
    updateKitchen(kitchenCampaign) {
      kitchenCampaign.isUpdated = true;
    },
    close() {
      this.$emit('close');
    },
  },
  watch: {},
  mounted() {}
}
</script>


<style scoped>
.draggable-item {
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: block;
    background-color: #fff;
    outline: 0;
    border: 1px solid rgba(0, 0, 0, .125);
    margin-bottom: 2px;
    margin-top: 2px;
    cursor: default;
    user-select: none;
}

.draggable-item-horizontal {
    height: 300px;
    padding: 10px;
    line-height: 100px;
    text-align: center;
    /* width : 200px; */
    display: block;
    background-color: #fff;
    outline: 0;
    border: 1px solid rgba(0, 0, 0, .125);
    margin-right: 4px;
    cursor: default;
}

.dragging {
    background-color: yellow;
}

.card-kitchenInformation {
    padding: 50px;
    /* background-color: #fff; */
}

.card-container {
    width: 320px;
    padding: 10px;
    margin: 5px;
    margin-right: 45px;
    background-color: #f3f3f3;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
}

.card {
    margin: 5px;
    /* border: 1px solid #ccc; */
    background-color: white;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
    padding: 10px;
}

.card-column-header {
    font-size: 18px;
}

.column-drag-handle {
    cursor: move;
    padding: 5px;
}

.card-ghost {
    transition: transform 0.18s ease;
    transform: rotateZ(5deg)
}

.card-ghost-drop {
    transition: transform 0.18s ease-in-out;
    transform: rotateZ(0deg)
}

.opacity-ghost {
    transition: all .18s ease;
    opacity: 0.8;
    /* transform: rotateZ(5deg); */
    background-color: cornflowerblue;
    box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}

.opacity-ghost-drop {
    opacity: 1;
    /* transform: rotateZ(0deg); */
    background-color: white;
    box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.0);
}

.form-demo {
    width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    display: flex
}

.form {
    flex: 3;
    /* width: 500px; */
    /* background-color: #f3f3f3; */
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 6px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.08), 0px 3px 3px rgba(0, 0, 0, 0.08);
}

.form-fields-panel {
    flex: 1;
    margin-right: 50px;
}

.form-ghost {
    transition: 0.18s ease;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
}

.form-ghost-drop {
    box-shadow: 0 0 2px 5px rgba(0, 0, 0, 0.0);
}

.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px;
}

.cards-drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px 45px 5px 5px;
}
</style>
