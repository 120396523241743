import campaignsAPI from '../../../apis/marketing/campaigns';
import { campaignsTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  total: 0,
  current: null,
  current_error: '',
  fetchAll: {
    pending: false,
    error: null,
  },
  fetchOne: {
    pending: false,
    error: null,
  },
  createOne: {
    pending: false,
    error: null,
  },
  updateOne: {
    pending: false,
    error: null,
  },
  deleteOne: {
    pending: false,
    error: null,
  },
  newCampaign: {
    'concept_uuid': null,
    'country_location_uuid': null,
    'platform_uuid': null,
    'label': null,
    'start_date': null,
    'end_date': null,
    'max_optin_date': null,
    'campaign_type': null,
    'campaign_scope': null,
    'financing_model': null,
    'estimated_uplift': null,
    'budget': null,
    'discount_type': null,
    'discount_value': null,
    'discount_over': null,
    'discounted_items': [],
    'locationUUID': null,
    'eventLabel': null,
    'dates': [],
  },
  newCampaignEmpty: {
    'concept_uuid': null,
    'country_location_uuid': null,
    'platform_uuid': null,
    'label': null,
    'start_date': null,
    'end_date': null,
    'max_optin_date': null,
    'campaign_type': null,
    'campaign_scope': null,
    'financing_model': null,
    'estimated_uplift': null,
    'budget': null,
    'discount_type': null,
    'discount_value': null,
    'discount_over': null,
    'discounted_items': [],
    'locationUUID': null,
    'eventLabel': null,
    'dates': [],
  },
  campaignTimelines: [
    'Current',
    'Upcoming',
    'Past',
  ],
  campaignTypes: [
    'BANNER',
    'BOGO',
    'DISCOUNT_ALL_ORDERS',
    'DISCOUNT_ITEMS',
    'FREE_DELIVERY',
    'FREE_ITEMS',
    'PUSH_NOTIFICATION',
  ],
  campaignFinancingModels: [
    'LICENSEE',
    'EXTERNAL_PARTNER',
    'TASTER_FOOD_COST',
    'TASTER_FOOD_COST_SECOND_ITEM',
    'TASTER_FULL_MARKETING_COST',
  ],
  campaignPerimeters: [
    'DEDICATED',
    'GENERAL',
  ],
  campaignDiscountTypes: [
    'FLAT_OVER',
    'PERCENTAGE',
    'PERCENTAGE_ON_SECOND_ITEM',
    'PERCENTAGE_OVER',
  ]
};

const actions = {
  async fetchAll({ commit }, opts) {
    commit(campaignsTypes.FETCH_ALL_REQUEST);

    return campaignsAPI.fetchAll(opts)
      .then((campaigns) => commit(campaignsTypes.FETCH_ALL_SUCCESS, campaigns))
      .catch((error) => commit(campaignsTypes.FETCH_ALL_FAILURE, error));
  },
  async fetchOne({ commit }, campaignUUID) {
    commit(campaignsTypes.FETCH_ONE_REQUEST);

    return campaignsAPI
      .fetchOne(campaignUUID)
      .then(function(campaign) {
        commit(campaignsTypes.FETCH_ONE_SUCCESS, campaign);
      })
      .catch(function(error) {
        commit(campaignsTypes.FETCH_ONE_FAILURE, error.response.data.detail);
      });
  },
  async createOne({ commit }, payload) {
    commit(campaignsTypes.CREATE_ONE_REQUEST);

    return campaignsAPI
      .createOne(payload)
      .then(function(campaign) {
        commit(campaignsTypes.CREATE_ONE_SUCCESS, campaign);
        return campaign;
      })
      .catch(function(error) {
        commit(campaignsTypes.CREATE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async updateOne({ commit }, payload) {
    var newPayload = {...payload}
    var campaignUUID = payload.uuid;
    delete newPayload.uuid;
    commit(campaignsTypes.UPDATE_ONE_REQUEST);
    return campaignsAPI
      .updateOne(campaignUUID, newPayload)
      .then(function(campaign) {
        commit(campaignsTypes.UPDATE_ONE_SUCCESS, campaign);
        return campaign;
      })
      .catch(function(error) {
        commit(campaignsTypes.UPDATE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async deleteOne({ commit }, campaignUUID) {
    commit(campaignsTypes.DELETE_ONE_REQUEST);

    return campaignsAPI
      .deleteOne(campaignUUID)
      .then(function() {
        commit(campaignsTypes.DELETE_ONE_SUCCESS, campaignUUID);
      })
      .catch(function(error) {
        commit(campaignsTypes.DELETE_ONE_FAILURE, error.response.data.detail);
      });
  },
  reset({ commit }) {
    commit(campaignsTypes.RESET_ONE);
  },
};

const mutations = {
  [campaignsTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [campaignsTypes.FETCH_ALL_SUCCESS](state, {items, total}) {
    state.all = items;
    state.total = total;
    state.fetchAll.pending = false;
  },
  [campaignsTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
  [campaignsTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [campaignsTypes.FETCH_ONE_SUCCESS](state, campaign) {
    var campaignFetch = {...campaign};
    campaignFetch.dates = [new Date(campaign.start_date), new Date(campaign.end_date)];
    if (campaign.max_optin_date) {
      campaignFetch.max_optin_date = new Date(campaign.max_optin_date);
    }
    state.current = campaignFetch;
    const index = state.all.findIndex((item) => item.uuid === campaign.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, campaignFetch);
    }
    state.fetchOne.pending = false;
    state.fetchOne.error = null;
  },
  [campaignsTypes.FETCH_ONE_FAILURE](state, error) {
    state.current = null;
    state.fetchOne.pending = false;
    state.fetchOne.error = error;
  },
  [campaignsTypes.CREATE_ONE_REQUEST](state) {
    state.createOne.pending = true;
  },
  [campaignsTypes.CREATE_ONE_SUCCESS](state, campaign) {
    state.current = campaign;
    state.all.push(campaign);
    state.newCampaign = {...state.newCampaignEmpty};
    state.createOne.pending = false;
    state.createOne.error = null;
  },
  [campaignsTypes.CREATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.createOne.pending = false;
    state.createOne.error = error;
  },
  [campaignsTypes.UPDATE_ONE_REQUEST](state) {
    state.updateOne.pending = true;
  },
  [campaignsTypes.UPDATE_ONE_SUCCESS](state, campaign) {
    // state.current = campaign;
    const index = state.all.findIndex((item) => item.uuid === campaign.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, campaign);
    }
    state.updateOne.pending = false;
    state.updateOne.error = null;
  },
  [campaignsTypes.UPDATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.updateOne.pending = false;
    state.updateOne.error = error;
  },
  [campaignsTypes.DELETE_ONE_REQUEST](state) {
    state.deleteOne.pending = true;
  },
  [campaignsTypes.DELETE_ONE_SUCCESS](state, campaignUUID) {
    const index = state.all.findIndex((campaign) => campaign.uuid === campaignUUID);
    if (index !== -1) {
      state.all.splice(index, 1);
    }
    state.deleteOne.pending = false;
    state.deleteOne.error = null;
  },
  [campaignsTypes.DELETE_ONE_FAILURE](state, error) {
    state.deleteOne.pending = false;
    state.deleteOne.error = error;
  },
  [campaignsTypes.RESET_ONE](state) {
    state.newCampaign = {...state.newCampaignEmpty};
    state.current = {...state.newCampaignEmpty};
  },
  [campaignsTypes.SET_SELECTED_CAMPAIGN_TIMELINES](state, campaignTimelines) {
    state.selectedCampaignTimelines = campaignTimelines;
  },
  [campaignsTypes.SET_SELECTED_CAMPAIGN_TYPE](state, campaignType) {
    state.selectedCampaignType = campaignType;
  },
  [campaignsTypes.SET_CURRENT](state, campaign) {
    state.current = campaign;
  },
  [campaignsTypes.DUPLICATE](state, campaign) {
    state.newCampaign = {...campaign};
  },
};

const getters = {
  getnewCampaign: (state) => {
    return {...state.newCampaign};
  },
  getCampaignTimelines: (state) => {
    return state.campaignTimelines;
  },
  getCampaignTypes: (state) => {
    return state.campaignTypes;
  },
  getCampaignFinancingModels: (state) => {
    return state.campaignFinancingModels;
  },
  getCampaignDiscountTypes: (state) => {
    return state.campaignDiscountTypes;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
