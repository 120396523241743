<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-table
      :data="launchesInformations"
      :striped="true"
      :hoverable="true"
      :default-sort="['launch_date', 'desc']"
      >
      <template slot-scope="props">
          <b-table-column field="id" label="ID" :width="columnWidth" sortable>
              {{ props.row.kitchenID }}
          </b-table-column>
          <b-table-column field="launch_date" label="Launch Date" :width="columnWidth" sortable  :custom-sort="sortByDate">
              {{ props.row.launchDate }}
          </b-table-column>
          <b-table-column field="country" label="Country" :width="columnWidth" sortable>
              {{ props.row.country }}
          </b-table-column>
          <b-table-column field="owner" label="Owner" :width="columnWidth" sortable>
              {{ props.row.owner }}
          </b-table-column>
          <b-table-column field="concept" label="Concept" :width="columnWidth">
            <div v-if="props.row.concept_internal">
              <img
                :src="props.row.concept_logo"
                :alt="props.row.concept_label"
                onerror="this.style.display='none'"
                class="concept-logo"
              />
            </div>
            <div v-else>
              {{ props.row.concept_label }}
            </div>
          </b-table-column>
          <b-table-column field="platforms" label="Platforms" :width="columnWidth">
            <div v-for="(platform, index) in props.row.platforms" :key="index">
              <component
                :is="isRestaurantUrlDefined(props.row, platform) ? 'a' : 'span'"
                :href="getRestaurantUrl(props.row, platform)"
                target="_blank"
              >
                <img
                  :src="platform.logo"
                  :alt="platform.label"
                  onerror="this.style.display='none'"
                  class="concept-logo"
                />
              </component>
            </div>
          </b-table-column>
          <b-table-column label="Actions">
            <b-button type="is-link is-small"
              @click="openOnboarding(props.row.launchUUID, props.row.platforms , props.row.concept_uuid, props.row.concept_logo, props.row.concept_label, props.row.concept_internal)">
              Onboarding
            </b-button>
          </b-table-column>
        </template>
        <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"></b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from 'vuex';
import { errorToasterDetailled } from '@/mixins';

export default {
  props: ['selectedLaunchType', 'isMounted'],
  mixins: [errorToasterDetailled],
  data() {
    return {
      columnWidth: 250,
      isEmpty: false,
      displaySuccess: false,
    }
  },
  computed: {
    ...mapState({
      concepts: (state) => state.concepts.all,
      countries: (state) => state.countries.all,
      currentKitchen: (state) => state.kitchens.current,
      currentLaunch: (state) => state.launches.current,
      locations: (state) => state.locations.all,
      launches: (state) => state.launches.all,
      platforms: (state) => state.platforms.all,
      isLoadingState: (state) => state.actions.fetchLaunchTeams.pending || state.launches.fetchOne.pending || state.launches.updateOne.pending || state.kitchens.fetchOne.pending,
      error: (state) => state.actions.fetchLaunchTeams.error
    }),
    isLoading() {
      return this.isLoadingState|| !this.isMounted;
    },
    launchesInformations() {
      if (!this.isMounted) {
        return [];
      }
      var launchesInformations = []
      for (let launch of this.launches) {
        var displayLaunch = false;
        var concept = this.concepts.find(concept => concept.uuid == launch.concept_uuid);
        var location = this.locations.find(location => location.uuid == launch.location_uuid);
        var platforms = this.platforms.filter(platform => launch.platform_uuids.includes(platform.uuid))
        var country = null;
        if (location) {
          country = this.countries.find(country => country.uuid == location.country_uuid);
        }
        if (location && country && concept && platforms) {
          var launchDates = [launch.launch_date_min, launch.launch_date_max];
          launchDates.sort()
          var launchDate = null;
          if (launchDates && this.selectedLaunchType == 'Past') {
            var newDate = new Date();
            newDate.setHours(0,0,0,0);
            var launchDateSorted = launchDates[launchDates.length -1];
            var endDate = new Date(launchDateSorted.substring(0,4), launchDateSorted.substring(5,7) - 1, launchDateSorted.substring(8,10));
            if (newDate <= endDate) {
              continue;
            }
            displayLaunch = true;
            launchDate = launchDateSorted;
          }
          if (launchDates && this.selectedLaunchType == 'Upcoming') {
            var newDate = new Date();
            newDate.setHours(0,0,0,0);
            for (var launchDateSorted of launchDates) {
              var endDate = new Date(launchDateSorted.substring(0,4), launchDateSorted.substring(5,7) - 1, launchDateSorted.substring(8,10));
              if (newDate <= endDate) {
                displayLaunch = true;
                launchDate = launchDateSorted;
                break;
              }
            }
            if (!displayLaunch) {
              continue;
            }
          }
          if (launchDates && this.selectedLaunchType == 'All') {
            launchDate = launchDates[0];
          }
          launchesInformations.push({
            'availableTasks': this.getAvailableTasks(launch),
            'completion': this.getCompletion(launch),
            'concept_label': concept.label,
            'concept_uuid': concept.uuid,
            'concept_logo': concept.logo,
            'concept_internal': concept.is_internal,
            'location': location,
            'country': country.label,
            'kitchen': null,
            'kitchenID': location.id,
            'launchDate': launchDate,
            'owner': launch.owner,
            'platforms': platforms,
            'launchUUID': launch.uuid,
            'deliverooCompletion': this.getCompletion(launch, 'Uber'),
            'uberCompletion': this.getCompletion(launch, 'Deliveroo'),
          })
        }
      }
      return launchesInformations
    },
  },
  methods: {
    ...mapActions('kitchens', { fetchKitchen: 'getKitchen' }),
    ...mapActions('launches', { updateLaunch: 'updateOne', fetchLaunch: 'fetchOne' }),
    ...mapActions('onboardingActions', ['fetchLaunchTeams']),
    isRestaurantUrlDefined(platform) {
      return false;
    },
    getRestaurantUrl(platform) {
      return ''
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return new Date(b.launchDate).getTime() - new Date(a.launchDate).getTime();
      } else {
        return new Date(a.launchDate).getTime() - new Date(b.launchDate).getTime();
      }
    },
    getAvailableTasks: function(launch) {
      return launch.tasks.filter(task => !task.is_lock && !task.is_done).length;
    },
    getCompletion: function(launch, type) {
      var launchTasks = [];
      if (type) {
        launchTasks = launch.tasks.filter(task => task.task_group_label != type)
      }
      else {
        launchTasks = launch.tasks;
      }
      var doneTasks = launchTasks.filter(task => task.is_done);
      return ((doneTasks.length / launchTasks.length) * 100).toFixed(2);
    },
    getCompletionType(completion) {
      if (completion < 40) {
        return 'is-danger'
      }
      if (completion < 80) {
        return 'is-warning'
      }
      return 'is-success'
    },
    openOnboarding: function(launchUUID, platforms, conceptUUID, conceptLogo, conceptLabel, conceptInternal) {
      this.$router.push('/onboarding/' + launchUUID);
    },
    taskType: function (task) {
      if (task.is_done) {
        return 'is-success'
      }
      return 'is-danger'
    }
  },
}
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
