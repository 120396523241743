<template>
  <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close">
    <section class="modal-card-body has-text-centered">
      <b> Delivery Address </b>
      <b-switch
        v-model="manualDeliveryAddressMode"
        passive-type='is-success'
        false-value="Google Map"
        true-value="Manual"
        type='is-danger'
        size='is-small'
      >
        {{ manualDeliveryAddressMode }}
      </b-switch>
      <b-field label="" expanded>
        <vue-google-autocomplete
          v-if="manualDeliveryAddressMode == 'Google Map'"
          id="mapDelivery"
          v-model="deliveryAddress"
          classname="google-autocomplete"
          v-on:placechanged="getDeliveryAddressData"
          :country="['fr', 'uk', 'es', 'be', 'nl']"
        >
        </vue-google-autocomplete>
        <b-input v-else v-model="deliveryAddress" type="text" placeholder="Address"> </b-input>
      </b-field>
      <div style="margin-bottom: 16px">
        <b> Delivery Contact</b>
        <b-field grouped>
          <b-field label="First Name" expanded>
            <b-input type="text" size="is-small" rounded placeholder="First Name" v-model="deliveryContact.first_name" expanded></b-input>
          </b-field>
          <b-field label="Last Name" expanded>
            <b-input type="text" size="is-small" rounded placeholder="Last Name" v-model="deliveryContact.last_name" expanded></b-input>
          </b-field>
          <b-field label="Mail" expanded>
            <b-input type="text" size="is-small" rounded placeholder="Mail" v-model="deliveryContact.personal_mail" expanded></b-input>
          </b-field>
          <b-field label="Phone" expanded>
            <b-input type="text" size="is-small" rounded placeholder="Phone" v-model="deliveryContact.phone_number" expanded></b-input>
          </b-field>
        </b-field>
      </div>
      <div style="margin-bottom: 16px">
        <b> Delivery Times</b>
          <div class="columns">
            <div class="column is-narrow">
              <h2> Start Time</h2>
              <b-timepicker v-model="deliveryTimeStart" inline :incrementMinutes="minutes" size="is-small"></b-timepicker>
            </div>
            <div class="column is-narrow">
              <h2> End Time</h2>
              <b-timepicker v-model="deliveryTimeEnd" inline :incrementMinutes="minutes" size="is-small"></b-timepicker>
            </div>
          </div>
      </div>
      <b-field label="Delivery Instructions">
        <b-input
          v-model="deliveryInstructions"
          type="text"
          placeholder="Instructions for delivers"
        >
        </b-input>
      </b-field>
      <b-button expanded :disabled="deliveryAddress == null || deliveryTimeStart == null || deliveryTimeEnd == null" type="button is-success" @click="updateValue">Set Up</b-button>
    </section>
  </b-modal>
</template>

<script>
/* eslint-disable */
import VueGoogleAutocomplete from 'vue-google-autocomplete';

  export default {
  components: { VueGoogleAutocomplete },
  data() {
    return {
      openModal: true,
      minutes: 15,
      deliveryAddress: this.deliveryInformations.deliveryAddress,
      deliveryContact: this.deliveryInformations.deliveryContact,
      deliveryInstructions: this.deliveryInformations.deliveryInstructions,
      deliveryTimeStart: this.getStartDeliveryDate(this.deliveryInformations),
      deliveryTimeEnd: this.getEndDeliveryDate(this.deliveryInformations),
      manualDeliveryAddressMode: 'Google Map'
    }
  },
  props: ['deliveryInformations'],
  computed: {},
  methods: {
    getDeliveryAddressData: function(place, addressData, _) {
      this.deliveryAddress = addressData.formatted_address;
    },
    getStartDeliveryDate() {
      if (this.deliveryInformations.deliveryTimes.start_time) {
        const time = this.deliveryInformations.deliveryTimes.start_time;
        const timeSplitted = time.split(':');
        const hour = timeSplitted[0];
        const minutes = timeSplitted[1];
        return new Date(90, 0, 0, hour, minutes);
      }
      return null;
    },
    getEndDeliveryDate() {
      if (this.deliveryInformations.deliveryTimes.end_time) {
        const time = this.deliveryInformations.deliveryTimes.end_time;
        const timeSplitted = time.split(':');
        const hour = timeSplitted[0];
        const minutes = timeSplitted[1];
        return new Date(90, 0, 0, hour, minutes);
      }
      return null;
    },
    updateValue() {
      var delivery_times = {};
      if (this.deliveryTimeStart) {
        var hour = this.addZero(this.deliveryTimeStart.getHours());
        var minute = this.addZero(this.deliveryTimeStart.getMinutes());
        delivery_times['start_time'] = `${hour}:${minute}:00`;
      }
      if (this.deliveryTimeEnd) {
        var hour = this.addZero(this.deliveryTimeEnd.getHours());
        var minute = this.addZero(this.deliveryTimeEnd.getMinutes());
        delivery_times['end_time'] = `${hour}:${minute}:00`;
      }
      this.$emit('updateValue', {
        "address": this.deliveryAddress,
        "delivery_contact": this.deliveryContact,
        "delivery_times": delivery_times,
        "delivery_instructions": this.deliveryInstructions,
      });
      this.$emit('close');
    },
    addZero(timePart) {
      if (timePart < 10) {
        timePart = "0" + timePart;
      }
      return timePart;
    },
    close() {
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
