var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card",
      staticStyle: { "overflow-y": "auto", "justify-content": "center" },
    },
    [
      _c(
        "section",
        { staticClass: "modal-card-body has-text-centered" },
        [
          _c(
            "b-field",
            { attrs: { label: _vm.fieldLabel, expanded: "" } },
            [
              _vm.fieldType == "number"
                ? _c("b-numberinput", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  })
                : _vm.fieldType == "bool"
                ? _c(
                    "b-select",
                    {
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    },
                    _vm._l(_vm.booleanValues, function (boolValue) {
                      return _c(
                        "option",
                        { key: boolValue, domProps: { value: boolValue } },
                        [_vm._v(" " + _vm._s(boolValue) + " ")]
                      )
                    }),
                    0
                  )
                : _c("b-input", {
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-button",
        {
          attrs: {
            expanded: "",
            disabled: _vm.value == null,
            type: "button is-success",
          },
          on: { click: _vm.updateValue },
        },
        [_vm._v("Set Up")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }