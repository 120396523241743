import axios from '@/axios';
import { baseItemManagerUrl } from '@/apis'


const ressourcePath = `${baseItemManagerUrl}/costs`;


export default {
/* eslint-disable */
  fetchOne(opts) {
    var path = ressourcePath
    if (opts.current != null || opts.summary != null) {
      path = `${path}?`
    }
    if (opts.current != null){
      path = `${path}current=${opts.current}&`
    }
    if (opts.summary != null){
      path = `${path}summary=${opts.summary}`
    }
    return axios.get(`${ressourcePath}/current?uuid=${opts.uuid}&type=${opts.type}&location_uuid=${opts.location_uuid}&quantity=${opts.quantity}&unit=${opts.unit}`)
                .then(response => response.data)
  },
};
