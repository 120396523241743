<template>
    <b-modal :active.sync="openModal" scroll="keep" full-screen :on-cancel="close">
      <b-loading :active.sync="isLoading"></b-loading>
      <section>
        <div class="card" style="width: auto">
          <div class="card-content">
            <div class="media">
              <div class="media-content">
               <p class="title is-4"> Actions</p>
              </div>
            </div>
          </div>
          <section class="card-content">
            <div class="is-flex-direction-column is-justify-content-flex-end">
              <div class="has-text-centered">
                <b-button type="is-primary" class="button-gap" @click="openCampaignModal(false)" expanded :disabled=shouldDisableCampaignCreation>
                  Create General Campaign
                </b-button>
              </div>
              <div class="has-text-centered">
                <b-button type="is-link" class="button-gap" @click="openCampaignModal(true)" expanded :disabled=shouldDisableCampaignCreation>
                  Create Dedicated Campaign
                </b-button>
              </div>
            </div>
          </section>
        </div>
      </section>
    </b-modal>
</template>

<script>
import { mapState } from 'vuex';


import NewCampaign from '@/components/Marketing/NewCampaign.vue';
import { auth, utils } from '@/mixins';
import roles from '@/router/roles';

export default {
  components: {},
  mixins: [auth, utils],
  props: ['concepts', 'countries', 'locations', 'platforms', 'events', 'isAdhocCampaign'],
  data(){
    return {
      openModal: true,
    }
  },
  computed: mapState({
    isLoading: (state) => state.kitchens.fetchAll.pending,
    kitchens: (state) =>
        state.kitchens.all
          .filter((kitchen) => !kitchen.is_deleted)
          .sort((kitchen1, kitchen2) => kitchen1.id.localeCompare(kitchen2.id)),
    shouldDisableCampaignCreation() {
      return !this.$auth.getUserRoles().includes(roles.marketingManagerUser);
    },
  }),
  methods: {
    openCampaignModal: function(isAdhocCampaign) {
      var modalStep = 0;
      this.$buefy.modal.open({
        component: NewCampaign,
        parent: this,
        hasModalCard: true,
        fullScreen: true,
        events: {
          close : () => this.$parent.close(),
        },
        props: {
          concepts: this.concepts,
          countries: this.countries,
          kitchens: this.kitchens,
          platforms: this.platforms,
          locations: this.locations,
          isAdhocCampaign: isAdhocCampaign,
          events: this.events,
          activeStep: modalStep,
        },
        customClass: 'my-modal',
      });
    },
    close() {
      this.$parent.close();
    },
  },
  mounted() {
    this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false');
  }
};
</script>

<style>
.my-modal .modal-close {
  background: red;
}
</style>
