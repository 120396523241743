import axios from '@/axios';
import { baseMarketingUrl } from '@/apis'

const ressourcePath = `${baseMarketingUrl}/kitchen-campaigns`;


export default {
/* eslint-disable */
  createOne(opts) {
    return axios.post(ressourcePath, opts)
                .then(response => response.data)
  },
  createMultiple(opts) {
    return axios.post(`${ressourcePath}/bulk`, opts)
                .then(response => response.data)
  },
  updateOne(kitchenCampaignUUID, opts) {
    return axios.patch(`${ressourcePath}/${kitchenCampaignUUID}`, opts)
                .then(response => response.data)
  },
  deleteOne(kitchenCampaignUUID) {
    return axios.delete(`${ressourcePath}/${kitchenCampaignUUID}`)
                .then(response => response.data)
  },
  fetchOne(kitchenCampaignUUID) {
    return axios.get(`${ressourcePath}/${kitchenCampaignUUID}`)
                .then(response => response.data)
  },
  fetchAll({page, perPage, campaignUUID, conceptUUID, countryLocationUUID, platformUUID, locationUUID, campaignType, startDateAfter, startDateBefore, endDateAfter, endDateBefore}) {
    if (campaignUUID) {
      perPage = 1000
    }
    return axios.get(ressourcePath, {params: {
      page,
      per_page: perPage,
      campaign_uuid: campaignUUID,
      concept_uuid: conceptUUID,
      country_location_uuid: countryLocationUUID,
      location_uuid: locationUUID,
      platform_uuid: platformUUID,
      campaign_type: campaignType,
      end_date_after: endDateAfter,
      end_date_before: endDateBefore,
      start_date_after: startDateAfter,
      start_date_before: startDateBefore,
    }})
    .then(response => response.data)
  },
  updateOneStatus(kitchenCampaignUUID, opts) {
    return axios.post(`${ressourcePath}/${kitchenCampaignUUID}/update-status`, opts)
                .then(response => response.data)
  },
};
