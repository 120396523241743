var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card",
      staticStyle: { "overflow-y": "auto", "justify-content": "center" },
    },
    [
      _c(
        "section",
        { staticClass: "modal-card-body has-text-centered" },
        [
          _c("p", { staticClass: "title is-4" }, [
            _vm._v(" Save Hub Credentials "),
          ]),
          _c(
            "b-field",
            { attrs: { label: "Username", expanded: "" } },
            [
              _c("b-input", {
                model: {
                  value: _vm.username,
                  callback: function ($$v) {
                    _vm.username = $$v
                  },
                  expression: "username",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: "Password", expanded: "" } },
            [
              _c("b-input", {
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
            ],
            1
          ),
          _c(
            "b-button",
            {
              attrs: {
                expanded: "",
                disabled: _vm.username == null || _vm.password == null,
                type: "button is-success",
              },
              on: { click: _vm.updateValue },
            },
            [_vm._v("Update")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }