import { render, staticRenderFns } from "./Marketing.vue?vue&type=template&id=32090e5c&"
import script from "./Marketing.vue?vue&type=script&lang=js&"
export * from "./Marketing.vue?vue&type=script&lang=js&"
import style0 from "./Marketing.vue?vue&type=style&index=0&id=32090e5c&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32090e5c')) {
      api.createRecord('32090e5c', component.options)
    } else {
      api.reload('32090e5c', component.options)
    }
    module.hot.accept("./Marketing.vue?vue&type=template&id=32090e5c&", function () {
      api.rerender('32090e5c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Marketing.vue"
export default component.exports