import axios from '@/axios';
import { baseUrlV2 } from '@/apis'


const ressourcePath = `${baseUrlV2}/concepts`;

export default {
/* eslint-disable */
  fetchOne(conceptUUID) {
    return axios.get(`${ressourcePath}/${conceptUUID}`)
                .then(response => response.data);
  },
  fetchAll(opts) {
    return axios.get(ressourcePath)
                .then(response => response.data);
  },
  getConcepts(queryParameters) {
    if (!queryParameters) {
      queryParameters = '';
    }
    return axios.get(`${ressourcePath}${queryParameters}`)
                .then(response => response.data)
  },
  createConcept(payload) {
    return axios.post(
                  ressourcePath,
                  payload.concept,
                  )
                .then(response => response.data)
  },
  updateConcept(payload) {
    const path = `${ressourcePath}/${payload.conceptUUID}`;
    return axios.patch(
            path,
            payload.concept,
          )
          .then(response => response.data)
  },
  updateConceptPicture(payload) {
    const path = `${ressourcePath}/${payload.conceptUUID}/upload_picture`;
    var formData = this.generateFormData(payload.conceptPicture);
    return axios.patch(
            path,
            formData,
            {headers: {'Content-Type': 'multipart/form-data'}}
          )
          .then(response => response.data)
  },
  getSchema() {
    return axios.get(`${baseUrl}/openapi.json`)
      .then(response => response.data)
  },
  generateFormData(payload) {
    var formData = new FormData();
    for (var property in payload) {
      formData.append(property, payload[property])
    }
    return formData
  },
};
