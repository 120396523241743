var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.kitchen && _vm.newRestaurants
    ? _c(
        "div",
        { staticClass: "has-text-centered" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label:
                  "Restaurant Name (Convention : Out Fry - Korean Chicken by Taster - Neighbour)",
                message: "Same as the one communicated to platforms",
                expanded: "",
              },
            },
            [
              _c("b-input", {
                attrs: { placeholder: "Restaurant Name" },
                model: {
                  value: _vm.label,
                  callback: function ($$v) {
                    _vm.label = $$v
                  },
                  expression: "label",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: "Launch Date" } },
            [
              _c("b-datepicker", {
                attrs: {
                  placeholder: "Click to select...",
                  icon: "calendar-today",
                  position: "is-bottom-right",
                  "trap-focus": "",
                },
                model: {
                  value: _vm.kitchenLaunchDate,
                  callback: function ($$v) {
                    _vm.kitchenLaunchDate = $$v
                  },
                  expression: "kitchenLaunchDate",
                },
              }),
            ],
            1
          ),
          _c(
            "b-button",
            {
              attrs: {
                expanded: "",
                disabled: _vm.label == null || _vm.kitchenLaunchDate == null,
                type: "button is-success",
              },
              on: { click: _vm.createRestaurant },
            },
            [_vm._v("Create")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }