var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "has-text-centered" },
      [
        _c(
          "b-button",
          {
            staticClass: "button-gap",
            attrs: { type: "is-link", expanded: "" },
            on: {
              click: function ($event) {
                return _vm.goToNewConceptSelectionForm(false)
              },
            },
          },
          [_vm._v(" Create New Restaurants ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "has-text-centered" },
      [
        _c(
          "b-button",
          {
            staticClass: "button-gap",
            attrs: { type: "is-info", expanded: "" },
            on: {
              click: function ($event) {
                return _vm.goToExistingConceptSelectionForm()
              },
            },
          },
          [_vm._v(" Select Existing Restaurants ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "has-text-centered" },
      [
        _c(
          "b-button",
          {
            staticClass: "button-gap",
            attrs: { type: "is-warning", expanded: "" },
            on: {
              click: function ($event) {
                return _vm.goToNewConceptSelectionForm(true)
              },
            },
          },
          [_vm._v(" Create New Restaurants based on a Transfer ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }