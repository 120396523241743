var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card",
      staticStyle: { "overflow-y": "auto", "justify-content": "center" },
    },
    [
      _c(
        "section",
        { staticClass: "modal-card-body has-text-centered" },
        [
          _c(
            "b-field",
            { attrs: { label: "Menu (UAT) UUID", expanded: "" } },
            [
              _c("b-input", {
                attrs: {
                  type: "text",
                  size: "is-small",
                  rounded: "",
                  placeholder: "menuUUID",
                  expanded: "",
                },
                model: {
                  value: _vm.menuUUID,
                  callback: function ($$v) {
                    _vm.menuUUID = $$v
                  },
                  expression: "menuUUID",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: "Spreadsheet URL", expanded: "" } },
            [
              _c("b-input", {
                attrs: {
                  type: "text",
                  size: "is-small",
                  rounded: "",
                  placeholder: "menuUUID",
                  expanded: "",
                },
                model: {
                  value: _vm.spreadsheetURL,
                  callback: function ($$v) {
                    _vm.spreadsheetURL = $$v
                  },
                  expression: "spreadsheetURL",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-button",
        {
          attrs: {
            expanded: "",
            disabled: _vm.menuUUID == null || _vm.spreadsheetURL == null,
            type: "button is-success",
          },
          on: { click: _vm.updateValue },
        },
        [_vm._v("Set Up")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }