var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section" },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _c("hr"),
        _c(
          "section",
          [
            _c(
              "b-button",
              {
                staticClass: "is-large is-info button-gap",
                attrs: { expanded: "" },
                on: {
                  click: function ($event) {
                    return _vm.openExportModal()
                  },
                },
              },
              [_vm._v(" Export ")]
            ),
            _c(
              "b-button",
              {
                staticClass: "is-large is-link button-gap",
                attrs: { expanded: "" },
                on: {
                  click: function ($event) {
                    return _vm.openImportModal()
                  },
                },
              },
              [_vm._v(" Import ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "title has-text-dark" }, [
      _c("div", { staticClass: "columns is-mobile" }, [
        _c("div", { staticClass: "column is-narrow" }, [_vm._v(" Catalog ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }