var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        active: _vm.openModal,
        scroll: "keep",
        "on-cancel": _vm.close,
        "full-screen": true,
      },
      on: {
        "update:active": function ($event) {
          _vm.openModal = $event
        },
      },
    },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("div", { staticClass: "modal-card", staticStyle: { width: "auto" } }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c("p", { staticClass: "modal-card-title" }, [
            _vm._v(" Kitchen Management "),
          ]),
        ]),
        _c(
          "section",
          { staticClass: "modal-card-body" },
          [
            _c(
              "b-tabs",
              {
                attrs: {
                  type: "is-toggle",
                  expanded: "",
                  "destroy-on-hide": "",
                },
                model: {
                  value: _vm.activeTabBaseState,
                  callback: function ($$v) {
                    _vm.activeTabBaseState = $$v
                  },
                  expression: "activeTabBaseState",
                },
              },
              _vm._l(_vm.managementTypes, function (managementType, index) {
                return _c(
                  "b-tab-item",
                  { key: index, attrs: { label: managementType.label } },
                  [
                    _c("KitchenManagementTable", {
                      key: index,
                      attrs: {
                        managementType: managementType,
                        kitchens: _vm.kitchens,
                      },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "footer",
          { staticClass: "modal-card-foot" },
          [
            _c(
              "b-button",
              {
                attrs: {
                  expanded: "",
                  disabled: _vm.isUpdateDisable,
                  type: "button is-link",
                },
                on: { click: _vm.update },
              },
              [_vm._v("Update")]
            ),
            _c(
              "b-button",
              {
                attrs: { expanded: "", type: "button is-danger" },
                on: { click: _vm.close },
              },
              [_vm._v("Close")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }