<template>
  <section class="section">
    <div class="container">
      <h1 class="title has-text-dark">
        <div class="columns is-mobile">
          <div class="column is-narrow">
          {{ $t('platforms.title') }}
          </div>
          <div class="column">
            <button class="add-button button is-success is-small"
              @click="OpenPlatformModal(null)">
              {{ $t('platforms.add') }}
            </button>
          </div>
        </div>
      </h1>
      <hr>
      <section>
        <section v-if="platforms.length > 0">
          <PlatformTable
            :platforms="platforms"
            @activate="activatePlatform"
            @edit="OpenPlatformModal"
            @upload_picture="openPictureModal" />
          <hr>
        </section>
      </section>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';

import PlatformTable from '@/components/Kitchen/Platform/PlatformTable.vue'
import PlatformForm from '@/components/Kitchen/Platform/PlatformForm.vue'
import PlatformPictureForm from '@/components/Kitchen/Platform/PlatformPictureForm.vue'

export default {
  data() {
    return {}
  },
  computed: mapState({
      platforms: state => state.platforms.all,
      current_error: state => state.platforms.current_error,
  }),
  methods: {
    activatePlatform: function (platformUUID, platformToActive) {
      if (platformToActive) {
        return this.updatePlatform(platformUUID, {'is_deleted': false})
      }
      else {
        return this.updatePlatform(platformUUID, {'is_deleted': true})
      }
    },
    OpenPlatformModal: function(platformUUID) {
      if (!platformUUID) {
        var platform = this.$store.getters['platforms/getNewPlatform'];
        var creationModal = true;
      }
      else {
        var platform = this.$store.getters['platforms/getPlatform'](platformUUID);
        platform.logo_file = null;
        var creationModal = false;
      }
      this.$buefy.modal.open({
          component: PlatformForm,
          parent: this,
          hasModalCard: true,
          events: {
              created: (platform) => this.createPlatform(platform),
              updated: (platformUUID, platform) => this.updatePlatform(platformUUID, platform),
              creationclosed: () => this.$store.dispatch("platforms/resetNewPlatform"),
              updateclosed: () => this.$store.dispatch("platforms/getPlatforms"),
          },
          props: {
            platform: platform,
            platformSchema: this.$store.getters['platforms/getPlatformSchema'],
            creationModal: creationModal
          }
      })
    },
    createPlatform: function(platform) {
      this.$store.dispatch('platforms/createPlatform', {
        platform: platform
      })
      this.$store.dispatch("platforms/resetNewPlatform");
    },
    updatePlatform: function(platformUUID, platform) {
      this.$store.dispatch('platforms/updatePlatform', {
        platformUUID: platformUUID,
        platform: platform
      })
    },
    openPictureModal: function(platformUUID) {
      var platform = this.$store.getters['platforms/getPlatform'](platformUUID);
      var picture = this.$store.getters['platforms/getNewPicture'];

      this.$buefy.modal.open({
          component: PlatformPictureForm,
          parent: this,
          hasModalCard: true,
          events: {
              updated: (platformUUID, updated_picture) => this.updatePlatformPicture(platformUUID, updated_picture),
              updateclosed: () => this.$store.dispatch("platforms/getPlatforms"),
          },
          props: {
            platform: platform,
            picture: picture,
          }
      })
    },
    updatePlatformPicture: function(platformUUID, platformPicture) {
      this.$store.dispatch('platforms/updatePlatformPicture', {
        platformUUID: platformUUID,
        platformPicture: platformPicture
      })
    },
    displayError(errorMessage) {
      this.$buefy.toast.open({
          duration: 5000,
          message: errorMessage,
          type: 'is-danger'
      })
      this.$store.dispatch("platforms/resetCurrentError");
    }
  },
  mounted() {
    this.$store.dispatch("platforms/getPlatforms");
    this.$store.dispatch("platforms/getSchema");
  },
  watch: {
    current_error(newValue, oldValue) {
      if(newValue) {
        this.displayError(newValue)
      }
    }
  },
  components: {
      PlatformTable
  },
}
</script>

<style>
  .add-button {
    margin-top: 8px;
  }
</style>
