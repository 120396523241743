import eventsAPI from '../../../apis/marketing/events';
import { eventsTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  current: null,
  currentEventLabel: null,
  current_error: '',
  fetchAll: {
    pending: false,
    error: null,
  },
  fetchOne: {
    pending: false,
    error: null,
  },
  createOne: {
    pending: false,
    error: null,
  },
  updateOne: {
    pending: false,
    error: null,
  },
  deleteOne: {
    pending: false,
    error: null,
  },
  newEvent: {
    'label': null,
    'uuid': null,
    'created_at': null,
    'updated_at': null,
    'is_deleted': null
  },
  newEventEmpty: {
    'label': null,
    'uuid': null,
    'created_at': null,
    'updated_at': null,
    'is_deleted': null
  },
};

const actions = {
  async fetchAll({ commit }) {
    commit(eventsTypes.FETCH_ALL_REQUEST);

    return eventsAPI.fetchAll()
      .then((events) => commit(eventsTypes.FETCH_ALL_SUCCESS, events))
      .catch((error) => commit(eventsTypes.FETCH_ALL_FAILURE, error));
  },
  async fetchOne({ commit }, eventUUID) {
    commit(eventsTypes.FETCH_ONE_REQUEST);

    return eventsAPI
      .fetchOne(eventUUID)
      .then(function(event) {
        commit(eventsTypes.FETCH_ONE_SUCCESS, event);
      })
      .catch(function(error) {
        commit(eventsTypes.FETCH_ONE_FAILURE, error.response.data.detail);
      });
  },
  async createOne({ commit }, payload) {
    commit(eventsTypes.CREATE_ONE_REQUEST);

    return eventsAPI
      .createOne(payload)
      .then(function(event) {
        commit(eventsTypes.CREATE_ONE_SUCCESS, event);
        return event;
      })
      .catch(function(error) {
        commit(eventsTypes.CREATE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async updateOne({ commit }, payload) {
    var newPayload = {...payload}
    var eventUUID = payload.uuid;
    delete newPayload.uuid;
    commit(eventsTypes.UPDATE_ONE_REQUEST);
    return eventsAPI
      .updateOne(eventUUID, newPayload)
      .then(function(event) {
        commit(eventsTypes.UPDATE_ONE_SUCCESS, event);
        return event;
      })
      .catch(function(error) {
        commit(eventsTypes.UPDATE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async deleteOne({ commit }, eventUUID) {
    commit(eventsTypes.DELETE_ONE_REQUEST);

    return eventsAPI
      .deleteOne(eventUUID)
      .then(function() {
        commit(eventsTypes.DELETE_ONE_SUCCESS, eventUUID);
      })
      .catch(function(error) {
        commit(eventsTypes.DELETE_ONE_FAILURE, error.response.data.detail);
      });
  },
  reset({ commit }) {
    commit(eventsTypes.RESET_ONE);
  },
};

const mutations = {
  [eventsTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [eventsTypes.FETCH_ALL_SUCCESS](state, events) {
    state.all = events.items;
    state.fetchAll.pending = false;
  },
  [eventsTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
  [eventsTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [eventsTypes.FETCH_ONE_SUCCESS](state, event) {
    state.current = event;
    const index = state.all.findIndex((item) => item.uuid === event.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, event);
    }
    state.fetchOne.pending = false;
    state.fetchOne.error = null;
  },
  [eventsTypes.FETCH_ONE_FAILURE](state, error) {
    state.current = null;
    state.fetchOne.pending = false;
    state.fetchOne.error = error;
  },
  [eventsTypes.CREATE_ONE_REQUEST](state) {
    state.createOne.pending = true;
  },
  [eventsTypes.CREATE_ONE_SUCCESS](state, event) {
    state.current = event;
    state.all.push(event);
    state.currentEventLabel = null;
    state.createOne.pending = false;
    state.createOne.error = null;
  },
  [eventsTypes.CREATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.createOne.pending = false;
    state.createOne.error = error;
  },
  [eventsTypes.UPDATE_ONE_REQUEST](state) {
    state.updateOne.pending = true;
  },
  [eventsTypes.UPDATE_ONE_SUCCESS](state, event) {
    // state.current = event;
    const index = state.all.findIndex((item) => item.uuid === event.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, event);
    }
    state.updateOne.pending = false;
    state.updateOne.error = null;
  },
  [eventsTypes.UPDATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.updateOne.pending = false;
    state.updateOne.error = error;
  },
  [eventsTypes.DELETE_ONE_REQUEST](state) {
    state.deleteOne.pending = true;
  },
  [eventsTypes.DELETE_ONE_SUCCESS](state, eventUUID) {
    const index = state.all.findIndex((event) => event.uuid === eventUUID);
    if (index !== -1) {
      state.all.splice(index, 1);
    }
    state.deleteOne.pending = false;
    state.deleteOne.error = null;
  },
  [eventsTypes.DELETE_ONE_FAILURE](state, error) {
    state.deleteOne.pending = false;
    state.deleteOne.error = error;
  },
  [eventsTypes.RESET_ONE](state) {
    state.newEvent = Object.assign({}, state.newEventEmpty);
  },
  [eventsTypes.SET_CURRENT](state, event) {
    state.current = event;
  },
  [eventsTypes.SET_CURRENT_EVENT_LABEL](state, label) {
    state.currentEventLabel = label;
  },
};

const getters = {
  getnewEvent: (state) => {
    return {...state.newEvent};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
