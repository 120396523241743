<template>
  <div class="has-text-centered" v-if="kitchen">
    <b-tabs v-model="activeTab" expanded>
      <!-- <b-tab-item label="Taster Emails">
        <b-field grouped>
          <b-field label="Manager First Name" expanded>
            <b-input v-model="managerFirstName" placeholder="Type Manager First Name"></b-input>
          </b-field>
          <b-field label="Manager Last Name" expanded>
            <b-input v-model="managerLastName" placeholder="Type Manager Last Name"></b-input>
          </b-field>
        </b-field>
        <b-button expanded :disabled="managerFirstName == null || managerLastName == null" type="button is-success" @click="createMails">Create Mails</b-button>
      </b-tab-item> -->
      <b-tab-item label="Personal Emails">
        <b-field grouped>
          <b-field label="Manager First Name" expanded>
            <b-input v-model="managerFirstNameComputed" placeholder="Type Manager First Name"></b-input>
          </b-field>
          <b-field label="Manager Last Name" expanded>
            <b-input v-model="managerLastNameComputed" placeholder="Type Manager Last Name"></b-input>
          </b-field>
        </b-field>
        <b-field grouped>
          <b-field label="Manager Mail (can be fill later)" expanded>
            <b-input v-model="managerMailComputed" class="has-text-centered" type="email"></b-input>
          </b-field>
          <b-field label="Kitchen Mail (can be fill later)" expanded>
            <b-input v-model="kitchenMailComputed" class="has-text-centered" type="email"></b-input>
          </b-field>
        </b-field>
        <b-button expanded :disabled="(managerFirstName == null && managerFirstNameComputed == null) || (managerLastName == null && managerLastNameComputed == null)" type="button is-success" @click="createMails">Create Mails</b-button>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      activeTab: 0,
      managerFirstName: null,
      managerLastName: null,
      managerMail: null,
      kitchenMail: null,
    }
  },
  props: ['activeStep', 'kitchen', 'users'],
  computed: {
    managerMailComputed: {
      get() {
        if (this.kitchen && this.kitchen.mails && this.kitchen.mails.mail_manager) {
          return this.kitchen.mails.mail_manager;
        }
        return null;
      },
      set(value) {
        this.managerMail = value;
      }
    },
    kitchenMailComputed: {
      get() {
        if (this.kitchen && this.kitchen.mails && this.kitchen.mails.mail_cook) {
          return this.kitchen.mails.mail_cook;
        }
        return null;
      },
      set(value) {
        this.kitchenMail = value;
      }
    },
    managerFirstNameComputed: {
      get() {
        if (this.users && this.users.length > 0 && this.kitchen && this.kitchen.mails && this.kitchen.mails.mail_manager) {
          var manager = this.users.find(user => user.email == this.kitchen.mails.mail_manager);
          if (manager) {
            return manager.first_name;
          }
        }
        return null;
      },
      set(value) {
        this.managerFirstName = value;
      }
    },
    managerLastNameComputed: {
      get() {
        if (this.users && this.users.length > 0 && this.kitchen && this.kitchen.mails && this.kitchen.mails.mail_manager) {
          var manager = this.users.find(user => user.email == this.kitchen.mails.mail_manager);
          if (manager) {
            return manager.last_name;
          }
        }
        return null;
      },
      set(value) {
        this.managerLastName = value;
      }
    },
  },
  methods: {
    createMails() {
      var managerFirstName = null;
      var managerLastName = null;
      var kitchenMail = null;
      var managerMail = null;
      if (this.managerFirstNameComputed) {
        managerFirstName = this.managerFirstNameComputed;
      }
      if (this.managerFirstName) {
        managerFirstName = this.managerFirstName;
      }
      if (this.managerLastNameComputed) {
        managerLastName = this.managerLastNameComputed;
      }
      if (this.managerLastName) {
        managerLastName = this.managerLastName;
      }
      if (this.kitchenMailComputed) {
        kitchenMail = this.kitchenMailComputed.trim();
      }
      if (this.kitchenMail) {
        kitchenMail = this.kitchenMail.trim();
      }
      if (this.managerMail) {
        managerMail = this.managerMail.trim();
      }
      if (this.managerMailComputed) {
        managerMail = this.managerMailComputed.trim();
      }
      this.$emit('createMails', {
        'first_name': managerFirstName.trim(),
        'last_name': managerLastName.trim(),
        'kitchen_name': this.kitchen.id.trim(),
        'kitchenMail': kitchenMail,
        'managerMail': managerMail,
      });
      this.$emit('close')
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
