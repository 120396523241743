var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.openModal,
            scroll: "keep",
            "on-cancel": _vm.close,
            "full-screen": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.openModal = $event
            },
          },
        },
        [
          _c("section", [
            _c("div", { staticClass: "card", staticStyle: { width: "auto" } }, [
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "media" }, [
                  _c("div", { staticClass: "media-content" }, [
                    _c("p", { staticClass: "title is-4" }, [
                      _vm._v(" Access Request"),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "section",
                { staticClass: "card-content" },
                [
                  _c(
                    "b-steps",
                    {
                      attrs: {
                        size: "is-small",
                        type: "is-info",
                        "has-navigation": false,
                      },
                      model: {
                        value: _vm.activeStep,
                        callback: function ($$v) {
                          _vm.activeStep = $$v
                        },
                        expression: "activeStep",
                      },
                    },
                    [
                      _c(
                        "b-step-item",
                        {
                          attrs: { label: "Member Type Selection", step: "1" },
                        },
                        [
                          _c("hr"),
                          _c("PeopleTypeSelectionForm", {
                            attrs: {
                              activeStep: _vm.activeStep,
                              newUser: _vm.newUser,
                              removeUserAction: _vm.removeUserAction,
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:newUser": function ($event) {
                                _vm.newUser = $event
                              },
                              "update:new-user": function ($event) {
                                _vm.newUser = $event
                              },
                              "update:removeUserAction": function ($event) {
                                _vm.removeUserAction = $event
                              },
                              "update:remove-user-action": function ($event) {
                                _vm.removeUserAction = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Member Selection",
                            step: "2",
                            visible: _vm.showStep(1),
                          },
                        },
                        [
                          _c("hr"),
                          _c("MemberConfigurationForm", {
                            attrs: {
                              activeStep: _vm.activeStep,
                              groups: _vm.groups,
                              googleGroups: _vm.googleGroups,
                              users: _vm.users,
                              newUser: _vm.newUser,
                              externalUserType: _vm.externalUserType,
                              removeUserAction: _vm.removeUserAction,
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:newUser": function ($event) {
                                _vm.newUser = $event
                              },
                              "update:new-user": function ($event) {
                                _vm.newUser = $event
                              },
                              "update:externalUserType": function ($event) {
                                _vm.externalUserType = $event
                              },
                              "update:external-user-type": function ($event) {
                                _vm.externalUserType = $event
                              },
                              "update:removeUserAction": function ($event) {
                                _vm.removeUserAction = $event
                              },
                              "update:remove-user-action": function ($event) {
                                _vm.removeUserAction = $event
                              },
                              createUser: _vm.createUserFront,
                              selectUser: _vm.selectUser,
                              deleteUser: _vm.deleteUser,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: { label: "Member Type Selection", step: "3" },
                        },
                        [
                          _c("hr"),
                          _c("RightsOperationForm", {
                            attrs: {
                              activeStep: _vm.activeStep,
                              operationType: _vm.operationType,
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:operationType": function ($event) {
                                _vm.operationType = $event
                              },
                              "update:operation-type": function ($event) {
                                _vm.operationType = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Rights Selection",
                            step: "4",
                            visible: _vm.showStep(3),
                          },
                        },
                        [
                          _c("hr"),
                          _c("RightsSelectionForm", {
                            attrs: {
                              currentUser: _vm.currentUser,
                              concepts: _vm.concepts,
                              countries: _vm.countries,
                              locations: _vm.locations,
                              operationType: _vm.operationType,
                            },
                            on: {
                              selectRights: _vm.selectRights,
                              close: _vm.close,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }