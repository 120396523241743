<template>
  <div class="has-text-centered">
    <div v-if="availableConcepts && availableConcepts.length > 0">
      <h4 class="title has-text-centered is-4">Select Concept</h4>
      <b-field style="justify-content: center;">
        <b-select
            type="text"
            v-model="selectedConcept"
            placeholder="Concept" expanded>
            <option v-for="(concept, index) in availableConcepts" :key="index" :value="concept">
              {{concept.label}}
            </option>
        </b-select>
      </b-field>
      <b-button expanded :disabled="!selectedConcept" type="button is-success is-small" @click="selectConcept">Choose</b-button>
    </div>
    <div v-else>
      <b> All available concepts are already created for {{kitchen.id}} for all available platforms </b>
      <b-button expanded type="button is-danger is-small" class="button-gap" @click="$emit('close')">Close</b-button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selectedConcept: null,
      }
    },
    props: ['activeStep', 'concepts', 'newRestaurants', 'kitchen', 'platforms'],
    computed: {
      availableConcepts() {
        if (this.newRestaurants) {
          if (this.kitchen) {
            var availableConcepts = [];
            var platformUUIDS = this.platforms.map((platform) => platform.uuid);
            for (let concept of this.concepts) {
              var restaurants = this.kitchen.restaurants || [];
              var restaurantConceptsPlatformUUIDS = restaurants.filter((restaurant) => !restaurant.is_deleted && restaurant.concept_uuid == concept.uuid).map((restaurant) => restaurant.platform_uuid);
              if (restaurantConceptsPlatformUUIDS && platformUUIDS.every(elem => restaurantConceptsPlatformUUIDS.includes(elem))){
                continue;
              }
              else {
                availableConcepts.push(concept);
              }
            }
            return availableConcepts;
          }
          else {
            return this.concepts;
          }
        }
        else {
          if (!this.kitchen.restaurants || this.kitchen.restaurants.length == 0) {
            return [];
          }
          var concepts = [];
          var conceptUUIDS = [];
          for (let restaurant of this.kitchen.restaurants) {
            if (!conceptUUIDS.includes(restaurant.concept_uuid) && !restaurant.is_deleted) {
              var concept = this.concepts.find(concept => concept.uuid == restaurant.concept_uuid)
              if (concept) {
                concepts.push(concept);
              }
              conceptUUIDS.push(restaurant.concept_uuid);
            }
          }
          return concepts;
        }
      }
    },
    methods: {
      selectConcept() {
        this.$emit('update:selectedConcept', this.selectedConcept);
        this.$emit('update:activeStep', 4);
      }
    }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
