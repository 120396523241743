<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center">
    <KitchenMailsCreationForm
      :activeStep.sync="activeStep"
      :kitchen="kitchen"
      :users="users"
      @createMails="createMails
    "/>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { utils } from '@/mixins';
  import KitchenMailsCreationForm from '@/components/Onboarding/Launch/Forms/KitchenMailsCreationForm.vue'

  export default {
    mixins: [utils],
    components: {
      KitchenMailsCreationForm
    },
    data() {
      return {
        activeTab: 0
      }
    },
    props: ['activeStep', 'kitchen'],
    computed: {
      ...mapState({
        isLoading: (state) => state.accesses.fetchUsers.pending,
        users: (state) => state.accesses.users,
      }),
    },
    methods: {
    ...mapActions('accesses', ['fetchUsers']),
      createMails(opts) {
        this.$emit('createMails', opts);
        this.$emit('close')
      },
    },
    mounted() {
      this.fetchUsers();
    }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
