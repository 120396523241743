<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <p class="title is-4"> Task </p>
    <section class="modal-card-body has-text-centered">
      <b-tabs v-model="activeTab" expanded>
        <b-tab-item label="General">
          <b-field label="Label" expanded>
            <b-input v-model="task.label" placeholder=""></b-input>
          </b-field>
          <b-field label="Description" expanded>
            <b-input v-model="task.description" type="textarea" placeholder=""></b-input>
          </b-field>
          <b-field grouped>
            <b-field label="Owner" expanded>
              <b-select
                type="text"
                v-model="task.owner"
                placeholder="Task Owner"
                expanded
              >
                <option v-for="(owner, index) in owners" :key="index" :value="owner">
                  {{ owner }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Task Group" expanded>
              <b-select
                type="text"
                v-model="task.task_group_uuid"
                placeholder="Task Group"
                expanded
              >
                <option v-for="(taskGroup, index) in taskGroups" :key="index" :value="taskGroup.uuid">
                  {{ taskGroup.label }}
                </option>
              </b-select>
            </b-field>
          </b-field>
          <b-field label="Countries" position="is-centered">
            <b-taglist>
              <b-tag v-for="(country, index) in taskCountries" :key="index" type="is-info" closable close-icon="delete" close-type='is-danger' @close="removeCountry(country)">
                {{country.label}}
              </b-tag>
            </b-taglist>
          </b-field>
          <b-field>
            <b-autocomplete
              size="is-small"
              expanded
              v-model="searchTerm"
              :data="countriesSorted"
              :clear-on-select="true"
              placeholder="Add new countries..."
              icon="magnify"
              field="label"
              @select="option => addCountry(option)"
              :append-to-body="true"
            >
            </b-autocomplete>
          </b-field>
        </b-tab-item>
        <b-tab-item label="Dependencies">
          <b-field label="Dependencies" position="is-centered">
            <b-taglist>
              <b-tag v-for="(task, index) in taskDependencies" :key="index" type="is-info" closable close-icon="delete" close-type='is-danger' @close="removeTask(task)">
                {{task.label}}
              </b-tag>
            </b-taglist>
          </b-field>
          <b-field>
            <b-autocomplete
              size="is-small"
              expanded
              v-model="searchTerm"
              :data="filteredTasks"
              :clear-on-select="true"
              placeholder="Add new tasks..."
              icon="magnify"
              field="label"
              @select="option => addTask(option)"
              :append-to-body="true"
            >
            </b-autocomplete>
          </b-field>
        </b-tab-item>
        <b-tab-item label="Links">
          <div style="margin-bottom: 16px">
            <b>Links</b>
            <b-button
              style="margin-left: 16px"
              icon-left="plus"
              size="is-small"
              @click="() => task.links.push({ label: '', url: '' })"
              >Add</b-button
            >
          </div>
          <b-field grouped v-for="(link, index) in task.links" :key="index">
            <b-field expanded>
              <b-input type="text" size="is-small" rounded placeholder="Label" v-model="link.label" expanded></b-input>
              <b-input style="margin-left: 10px;" type="text" size="is-small" rounded placeholder="URL" v-model="link.url" expanded></b-input>
            </b-field>
            <b-button icon-left="delete" size="is-small" @click="() => task.links.splice(index, 1)">Remove</b-button>
          </b-field>
        </b-tab-item>
        <b-tab-item label="Tech">
          <b-field grouped>
            <b-field label="Automatic">
              <b-select
                placeholder="Automatic"
                v-model="task.is_automatic"
              >
                <option v-for="automaticValue in automaticValues" :value="automaticValue" :key="automaticValue">
                  {{ automaticValue }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Manual">
              <b-select
                placeholder="Manual"
                v-model="task.is_manual"
              >
                <option v-for="manualValue in manualValues" :value="manualValue" :key="manualValue">
                  {{ manualValue }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Reopen Required">
              <b-select
                placeholder="Reopen Required"
                v-model="task.is_reopen_required"
              >
                <option v-for="reopenValue in reopenValues" :value="reopenValue" :key="reopenValue">
                  {{ reopenValue }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Onboarding Type">
              <b-select
                placeholder="Onboarding Type"
                v-model="task.task_type"
              >
                <option v-for="typeValue in typeValues" :value="typeValue" :key="typeValue">
                  {{ typeValue }}
                </option>
              </b-select>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="Launch Type">
              <b-select
                placeholder="Launch Type"
                v-model="task.launch_type"
              >
                <option v-for="typeValue in launchTypeValues" :value="typeValue" :key="typeValue">
                  {{ typeValue }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Level Type">
              <b-select
                placeholder="Level Type"
                v-model="task.task_level"
              >
                <option v-for="levelValue in levelValues" :value="levelValue" :key="levelValue">
                  {{ levelValue }}
                </option>
              </b-select>
            </b-field>
          </b-field>
          <b-field label="UUID">
            <b-input v-model="task.uuid" :disabled="true" placeholder=""></b-input>
          </b-field>
        </b-tab-item>
      </b-tabs>
    </section>
    <b-button v-if="!task.uuid" expanded :disabled="saveOrUpdateDisabled" type="button is-success" @click="createTask">Create</b-button>
    <b-button v-else expanded :disabled="saveOrUpdateDisabled" type="button is-success" @click="updateTask">Update</b-button>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      activeTab: 0,
      label: null,
      launchDate: null,
      automaticValues: [true, false],
      reopenValues: [true, false],
      launchTypeValues: ['ALL_LAUNCHES', 'TRANSFER_ONLY', 'NEW_LAUNCH_ONLY'],
      typeValues: ['ALL', 'INTERNAL_ONLY', 'EXTERNAL_ONLY', 'SELF_SERVICE'],
      levelValues: ['RESTAURANT_LEVEL', 'CONCEPT_LEVEL', 'KITCHEN_LEVEL'],
      manualValues: [true, false],
      owners: ["Coach", "Expansion", "KPC", "Marketing", "Ops", "Supply", 'Tech'],
      searchTerm: '',
    }
  },
  props: ['task', 'tasks', 'taskGroups', 'countries'],
  computed: {
    countriesSorted() {
      var countriesSorted = [...this.countries]
      return countriesSorted.sort((country1, country2) => country1.label.localeCompare(country2.label));
    },
    taskCountries() {
      var taskCountries = [];
      for (let countryUUID of this.task.country_uuids) {
        var country = this.countries.find(country => country.uuid == countryUUID);
        taskCountries.push(country)
      }
      return taskCountries;
    },
    taskDependencies() {
      var taskDependencies = [];
      for (let taskUUID of this.task.task_dependencies) {
        var task = this.tasks.find(task => task.uuid == taskUUID);
        taskDependencies.push(task)
      }
      return taskDependencies;
    },
    tasksByGroupTaskLabel() {
      var tasksByGroupTaskLabel = [];
      for (let task of this.tasks) {
        var taskWithTaskGroupLabel = {...task};
        taskWithTaskGroupLabel.label = `(${task.task_group_label}) - ${task.label}`;
        tasksByGroupTaskLabel.push(taskWithTaskGroupLabel)
      }
      return tasksByGroupTaskLabel.sort((task1, task2) =>
        task1.label.localeCompare(task2.label),
      );
    },
    filteredTasks() {
      return this.tasksByGroupTaskLabel.filter((task) => this.task.uuid != task.uuid && !this.task.task_dependencies.includes(task.uuid) && task.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
    saveOrUpdateDisabled() {
      if (
        !this.task.owner ||
        !this.task.label ||
        !this.task.task_group_uuid ||
        !this.task.country_uuids ||
        this.task.country_uuids.length == 0
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    addCountry(option) {
      if (option != null) {
        this.task.country_uuids.push(option.uuid)
      }
    },
    removeCountry(option) {
      const index = this.task.country_uuids.findIndex(countryUUID => countryUUID === option.uuid);
      if (index !== -1) {
        this.task.country_uuids.splice(index, 1)
      }
    },
    addTask(option) {
      if (option != null) {
        this.task.task_dependencies.push(option.uuid)
      }
    },
    removeTask(option) {
      const index = this.task.task_dependencies.findIndex(taskUUID => taskUUID === option.uuid);
      if (index !== -1) {
        this.task.task_dependencies.splice(index, 1)
      }
    },
    createTask() {
      this.$emit('createTask', this.task);
      this.$parent.close();
    },
    updateTask() {
      this.$emit('updateTask', this.task);
      this.$parent.close();
    },
  }
  }
</script>
