<template>
<div>
  <b-modal :active.sync="openModal" :on-cancel="close">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">Opening hours</p>
        <div>
          <!-- <b-dropdown aria-role="list" position="is-bottom-left">
            <button v-if="canEditInformation()" class="button is-warning" type="button" slot="trigger">
                <span>{{ $t('restaurants.import_opening_hours') }}</span>
                <b-icon icon="menu-down"></b-icon>
            </button>
            <b-dropdown-item v-for="(restaurant, index) in otherKitchenConcepts"
              :key="index"
              :value="restaurant"
              aria-role="listitem"
              @click="importOpeningHours(restaurant)"
            >
              <span>
                <img onerror="this.style.display='none'"
                      :src="restaurant.logo"
                      class="image"
                      width="30"
                      alt="Image"
                      style="vertical-align:middle">
                {{restaurant.label}}
              </span>
            </b-dropdown-item>
          </b-dropdown> -->
        </div>
      </header>
      <div class="card-content">
        <div class="content">
          <table>
            <tr v-for="(day, dayIndex) in daysSorted" :key="dayIndex">
              <td>{{ day }}</td>
              <td v-for="(shift, index) in shifts" :key="index">
                <div class="columns" v-if="daysFormatted[day][shift].length > 0">
                  <div class="column is-narrow">
                    <b-timepicker :disabled="!canEditInformation()" v-if="daysFormatted[day][shift].length > 0" v-model="daysFormatted[day][shift][0]" inline :incrementMinutes="minutes" class="is-small"/>
                  </div>
                  <div class="column is-narrow">
                    <div class="columns is-gapless">
                      <div class="column">
                        <b-timepicker :disabled="!canEditInformation()" v-if="daysFormatted[day][shift].length > 1" v-model="daysFormatted[day][shift][1]" inline :incrementMinutes="minutes" class="is-small"/>
                      </div>
                      <div class="column">
                        <button
                          v-if="daysFormatted[day][shift].length > 1 && dayIndex != (daysSorted.length - 1) && canEditInformation()"
                          class="button is-small is-primary"
                          @click="copyShift(day, dayIndex, shift)">
                           <b-icon icon="content-copy" size="is-small" inline />
                        </button>
                        <button
                          v-if="daysFormatted[day][shift].length > 1 && canEditInformation()"
                          class="button is-small is-danger"
                          @click="removeShift(day, shift)">
                          <b-icon icon="minus-circle" size="is-small" inline />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  v-if="daysFormatted[day][shift].length == 0 && shift =='lunch_shift' && canEditInformation()"
                  title="Add Lunch Shift"
                  class="button is-small is-info"
                  @click="addShift(day, shift)">
                    <b-icon icon="plus" size="is-small"></b-icon> Add lunch shift
                </button>
                <button
                  v-if="daysFormatted[day][shift].length == 0 && shift =='dinner_shift' && canEditInformation()"
                  title="Add dinner Shift"
                  class="button is-small is-link"
                  @click="addShift(day, shift)">
                    <b-icon icon="plus" size="is-small"></b-icon> Add dinner shift
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <footer class="card-footer">
        <a class="card-footer-item" @click="close">Close</a>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
import { auth } from '@/mixins';

export default {
  mixins: [auth],
  props: ['kitchen', 'openingHours', 'currentConcept'],
  data() {
    return {
      openModal: true,
      minutes: 15,
      days: this.openingHours,
      daysSorted: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      shifts: ["lunch_shift", "dinner_shift"],
    }
  },
  computed: {
    daysFormatted: function () {
      return this.deserializeOpeningHours(this.openingHours)
    },
    otherKitchenConcepts: function() {
      return this.kitchen.restaurants_by_concept.filter(restaurant => restaurant.concept_uuid != this.currentConcept);
    },
  },
  methods: {
    importOpeningHours(restaurant) {
      this.openingHours = restaurant.opening_hours;
    },
    close() {
      this.$parent.close();
    },
    deserializeOpeningHours: function (openingHours) {
      var formattedOpeningHours = {};
      for (let day in openingHours) {
        formattedOpeningHours[day] = {}
        for (var i=0; i < this.shifts.length; i++) {
          var shift = this.shifts[i];
          formattedOpeningHours[day][shift] = [];
          if (openingHours[day][shift].length > 0) {
            openingHours[day][shift].split("-").forEach( (shiftPart, indexShiftPart) => {
              formattedOpeningHours[day][shift][indexShiftPart] = this.getDate(shiftPart);
            })
          }
        }
      }
      return formattedOpeningHours;
    },
    serializeOpeningHours: function (openingHours) {
      var formattedOpeningHours = {};
      for (let day in openingHours) {
        formattedOpeningHours[day] = {}
        for (var i=0; i < this.shifts.length; i++) {
          var shift = this.shifts[i];
          formattedOpeningHours[day][shift] = "";
          var formattedShift = ""
          openingHours[day][shift].forEach((shiftPart, indexShiftPart) => {
            var shiftHour = this.addZero(shiftPart.getHours());
            var shiftMinute = this.addZero(shiftPart.getMinutes());
            formattedShift += `${shiftHour}:${shiftMinute}`;
            if(indexShiftPart != (openingHours[day][shift].length -1)){
              formattedShift += "-"
            }
          })
          if (formattedShift) {
            formattedOpeningHours[day][shift] = formattedShift;
          }
        }
      }
      return formattedOpeningHours;
    },
    getDate(time) {
      const timeSplitted = time.split(':');
      const hour = timeSplitted[0];
      const minutes = timeSplitted[1];
      return new Date(90, 0, 0, hour, minutes);
    },
    addZero(timePart) {
      if (timePart < 10) {
        timePart = "0" + timePart;
      }
      return timePart;
    },
    removeShift: function(day, shift) {
      this.days[day][shift] = [];
    },
    addShift: function(day, shift) {
      this.updateDays();
      this.days[day][shift] = "00:00-00:00";
    },
    copyShift: function(day, dayIndex, shift) {
      this.updateDays();
      this.days[this.daysSorted[dayIndex + 1]][shift] = this.days[day][shift];
    },
    updateDays : function() {
      var serializedDaysFormatted = this.serializeOpeningHours(this.daysFormatted);
      for (var day in this.days) {
        var shiftByDay = this.days[day];
        for (var shift in shiftByDay) {
          this.days[day][shift] = serializedDaysFormatted[day][shift];
        }
      }
    },
  },
};
</script>
