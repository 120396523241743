import axios from '@/axios';
import { baseUrlV2 } from '@/apis'


const ressourcePath = `${baseUrlV2}/locations`;

export default {
/* eslint-disable */
getLocation(locationUUID) {
  return axios.get(`${ressourcePath}/${locationUUID}`)
              .then(response => response.data)
},
getLocations(queryParameters) {
  if (!queryParameters) {
    queryParameters = '';
  }
  return axios.get(`${ressourcePath}${queryParameters}`)
              .then(response => response.data)
},
  createLocation(locationPayload) {
    return axios.post(
            ressourcePath,
            locationPayload
          )
          .then(response => response.data)
  },
};
