import restaurantsAPI from '../../../apis/kitchen/restaurants';
import { restaurantsTypes } from '../../mutation-types.js'

/* eslint-disable */

const state = {
  all: [],
  current_error: '',
  current_success: '',
  fetchOne: {
    pending: false,
    error: null,
  },
  updateOne: {
    pending: false,
    error: null,
  },
  newRestaurant: {
    concept_uuid: null,
    financial_informations: {},
    id: '',
    is_active: false,
    is_internal: true,
    is_loyalty_enable: false,
    is_one_tablet: false,
    is_pos_integrated: false,
    is_delivery_enabled: true,
    is_pickup_enabled: false,
    is_room_service_enabled: false,
    label: '',
    launch_date: new Date(),
    opening_hours: {},
    platform_uuid: null,
    restaurant_information: {},
    restaurant_platform_id: '',
    restaurant_delivery_id: '',
    secrets: {},
    url: '',
  },
  newRestaurantEmpty: {
    concept_uuid: null,
    financial_informations: {},
    id: '',
    is_active: false,
    is_internal: true,
    is_loyalty_enable: false,
    is_one_tablet: false,
    is_pos_integrated: false,
    is_delivery_enabled: true,
    is_pickup_enabled: false,
    is_room_service_enabled: false,
    label: '',
    launch_date: new Date(),
    opening_hours: {},
    platform_uuid: null,
    restaurant_information: {},
    restaurant_platform_id: '',
    restaurant_delivery_id: '',
    secrets: {},
    url: '',
  },
  openingHoursToExport: '',
};

const getters = {
  getRestaurant: state => id => {
    return state.all.find(restaurant => restaurant.uuid === id)
  },
  getNewRestaurant: state => {
    return state.newRestaurant;
  },
}

const actions = {
  async getRestaurant({ commit }, restaurantUUID) {
    commit(restaurantsTypes.FETCH_ONE_REQUEST);
    return restaurantsAPI
      .getRestaurant(restaurantUUID)
      .then(function(restaurant) {
        commit(restaurantsTypes.FETCH_ONE_SUCCESS, restaurant);
      })
      .catch(function(error) {
        commit(restaurantsTypes.FETCH_ONE_FAILURE, error.response.data.detail);
      });
  },
  async getRestaurants({ commit }) {
    return restaurantsAPI.getRestaurants()
    .then(function (restaurants) {
      commit(restaurantsTypes.SET_RESTAURANTS, restaurants)
    })
    .catch(function (error) {
      commit(restaurantsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  async createRestaurant({ commit }, payload) {
    return restaurantsAPI.createRestaurant(payload)
    .then(function (restaurant) {
      commit(restaurantsTypes.CREATE_RESTAURANT, restaurant)
    })
    .catch(function (error) {
      commit(restaurantsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  resetNewRestaurant({ commit }) {
    commit(restaurantsTypes.RESET_NEW_RESTAURANT, '')
  },
  async updateRestaurant({ commit, dispatch }, payload) {
    state.updateOne.pending = true;
    state.updateOne.error = null;
    return restaurantsAPI.updateRestaurant(payload)
    .then(function (restaurant) {
      commit(restaurantsTypes.UPDATE_RESTAURANT, restaurant)
      commit(restaurantsTypes.SET_CURRENT_SUCCESS, 'Restaurant Updated');
    })
    .catch(function (error) {
      commit(restaurantsTypes.SET_CURRENT_ERROR, error.response.data.detail)
      dispatch('getRestaurants')
    })
  },
  async deleteRestaurant({ commit, dispatch }, payload) {
    return restaurantsAPI.deleteRestaurant(payload)
    .then(function () {
      commit(restaurantsTypes.DELETE_RESTAURANT, payload)
    })
    .catch(function (error) {
      commit(restaurantsTypes.SET_CURRENT_ERROR, error.response.data.detail)
      dispatch('getRestaurants')
    })
  },
  async activateRestaurant({ commit }, payload) {
    return restaurantsAPI.activateRestaurant(payload.restaurantUUID)
    .then(function (_) {
      commit(restaurantsTypes.ACTIVATE_RESTAURANT, payload)
    })
    .catch(function (error) {
      commit(restaurantsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  async deactivateRestaurant({ commit }, payload) {
    return restaurantsAPI.deactivateRestaurant(payload.restaurantUUID)
    .then(function (_) {
      commit(restaurantsTypes.DEACTIVATE_RESTAURANT, payload)
    })
    .catch(function (error) {
      commit(restaurantsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  async exportOpeningHours({ commit }, payload) {
    return restaurantsAPI.exportOpeningHours(
      payload,
    )
    .then(function(openingHours) {
      commit(restaurantsTypes.SET_EXPORT_OPENING_HOURS, openingHours)
    })
    .catch(function (error) {
      commit(restaurantsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  resetCurrentError({ commit }) {
    commit(restaurantsTypes.SET_CURRENT_ERROR, '')
  },
  resetCurrentSuccess({ commit }) {
    commit(restaurantsTypes.SET_CURRENT_SUCCESS, '');
  },
}

const mutations = {
  [restaurantsTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [restaurantsTypes.FETCH_ONE_SUCCESS](state, restaurant) {
    state.current = restaurant;
    const index = state.all.findIndex((item) => item.uuid === restaurant.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, restaurant);
    }
    else {
      state.all.push(restaurant);
    }
    state.fetchOne.pending = false;
    state.fetchOne.error = null;
  },
  [restaurantsTypes.FETCH_ONE_FAILURE](state, error) {
    state.fetchOne.pending = false;
    state.fetchOne.error = error;
  },
  [restaurantsTypes.SET_RESTAURANTS] (state, restaurants) {
    state.all = restaurants
  },
  [restaurantsTypes.CREATE_RESTAURANT] (state, restaurant) {
    var kitchenIndex = this.state.kitchens.all.findIndex(kitchen => kitchen.uuid === restaurant.kitchen_uuid);
    if (kitchenIndex !== -1) {
      var kitchen = this.state.kitchens.all[kitchenIndex];
      kitchen.restaurants.push(restaurant);
      this.state.kitchens.all.splice(kitchenIndex, 1, kitchen)
    }
    state.all.push(restaurant)
  },
  [restaurantsTypes.RESET_NEW_RESTAURANT] (state) {
    state.newRestaurant = Object.assign({}, state.newRestaurantEmpty);
  },
  [restaurantsTypes.UPDATE_RESTAURANT] (state, payload) {
    var kitchenIndex = this.state.kitchens.all.findIndex(kitchen => kitchen.uuid === payload.kitchen_uuid);
    if (kitchenIndex !== -1) {
      var kitchen = this.state.kitchens.all[kitchenIndex];
      var restaurantIndex = kitchen.restaurants.findIndex(restaurant => restaurant.uuid === payload.uuid);
      if (restaurantIndex !== -1) {
        kitchen.restaurants.splice(restaurantIndex, 1, payload);
        this.state.kitchens.all.splice(kitchenIndex, 1, kitchen)
      }
    }
    state.updateOne.pending = false;
    state.updateOne.error = null;
  },
  [restaurantsTypes.DELETE_RESTAURANT] (state, payload) {
    var kitchenIndex = this.state.kitchens.all.findIndex(kitchen => kitchen.uuid === payload.kitchenUUID);
    if (kitchenIndex !== -1) {
      var kitchen = this.state.kitchens.all[kitchenIndex];
      var restaurantIndex = kitchen.restaurants.findIndex(restaurant => restaurant.uuid === payload.restaurantUUID);
      if (restaurantIndex !== -1) {
        kitchen.restaurants[restaurantIndex].is_deleted = true;
      }
    }
  },
  [restaurantsTypes.ACTIVATE_RESTAURANT] (state, payload) {
    var kitchenIndex = this.state.kitchens.all.findIndex(kitchen => kitchen.uuid === payload.kitchenUUID);
    if (kitchenIndex !== -1) {
      var kitchen = this.state.kitchens.all[kitchenIndex];
      var restaurantIndex = kitchen.restaurants.findIndex(restaurant => restaurant.uuid === payload.restaurantUUID);
      if (restaurantIndex !== -1) {
        var restaurant = kitchen.restaurants[restaurantIndex];
        restaurant["is_active"] = true;
        kitchen.restaurants.splice(restaurantIndex, 1, restaurant);
        this.state.kitchens.all.splice(kitchenIndex, 1, kitchen)
      }
    }
  },
  [restaurantsTypes.DEACTIVATE_RESTAURANT] (state, payload) {
    var kitchenIndex = this.state.kitchens.all.findIndex(kitchen => kitchen.uuid === payload.kitchenUUID);
    if (kitchenIndex !== -1) {
      var kitchen = this.state.kitchens.all[kitchenIndex];
      var restaurantIndex = kitchen.restaurants.findIndex(restaurant => restaurant.uuid === payload.restaurantUUID);
      if (restaurantIndex !== -1) {
        var restaurant = kitchen.restaurants[restaurantIndex];
        restaurant["is_active"] = false;
        kitchen.restaurants.splice(restaurantIndex, 1, restaurant);
        this.state.kitchens.all.splice(kitchenIndex, 1, kitchen)
      }
    }
  },
  [restaurantsTypes.SET_EXPORT_OPENING_HOURS] (state, payload) {
    state.openingHoursToExport = payload;
  },
  [restaurantsTypes.SET_CURRENT_ERROR] (state, error) {
    this.state.kitchens.current_error = error
  },
  [restaurantsTypes.SET_CURRENT_SUCCESS](state, success) {
    state.current_success = success;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
