<template>
  <div>
    <b-modal :active.sync="openModal"
             has-modal-card
             :on-cancel="close">
      <section>
          <div class="card" style="width: auto">
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                    <figure class="image is-48x48">
                        <img onerror="this.style.display='none'" :src="platform.logo" alt="Image">
                    </figure>
                </div>
                <div class="media-content">
                  <p v-if="platform.label" p class="title is-4">{{platform.label}}</p>
                </div>
              </div>
            </div>
              <section class="card-content">
                <b-field class="file">
                    <b-upload v-model="picture.picture">
                        <a class="button is-info">
                            <b-icon icon="upload"></b-icon>
                            <span>Upload</span>
                        </a>
                    </b-upload>
                    <span class="file-name" v-if="logo_file">
                        {{ picture.picture.name }}
                    </span>
                </b-field>
              </section>
              <footer class="card-foot buttons is-right">
                  <b-button type="button is-danger is-small is-outlined" @click="close">{{ $t('platforms.close') }}</b-button>
                  <b-button type="button is-small is-outlined" :disabled="createOrUpdateDisabled" @click="update">{{ $t('platforms.update') }}</b-button>
              </footer>
          </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ['platform', 'picture'],
  data() {
    return {
      openModal: true,
      logo_file: null,
    }
  },
  computed: {
    createOrUpdateDisabled: function(){
      if(! this.picture || ! this.picture.picture) {
        return true
      }
      return false
    }
  },
  methods: {
    update() {
      this.$emit('updated',
        this.platform.uuid,
        this.picture,
      );
      this.$parent.close();
    },
    close() {
      this.$emit('updateclosed', {});
      this.$parent.close();
    },
  }
}
</script>

<style>
 .field:not(:last-child){
   margin-bottom:0px;
 }
</style>
