<template>
  <div class="has-text-centered">
    <div class="has-text-centered">
      <h4 class="title has-text-centered is-4">Select Calendar Parameters</h4>
        <b-field grouped>
          <b-field label="Country (Mandatory)" expanded>
            <b-select
              placeholder="Campaign Country"
              v-model="currentSelection.country"
              expanded>
              <option v-for="(country, index) in countries" :key="index" :value="country">
                {{ country.id }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Campaign Dates" expanded>
            <b-datepicker
              v-model="currentSelection.dates"
              placeholder="Click to select..."
              icon="calendar-today"
              position="is-bottom-right"
              locale='fr-Fr'
              range>
            </b-datepicker>
          </b-field>
        </b-field>
        <b-field grouped>
          <b-field label="Concept" style="justify-content: center;" expanded>
            <b-select
              type="text"
              v-model="currentSelection.concept"
              placeholder="Concept"
              expanded
              >
              <option v-for="(concept, index) in concepts" :key="index" :value="concept">
                {{concept.label}}
              </option>
            </b-select>
          </b-field>
          <b-field label="Platform" style="justify-content: center;" expanded>
            <b-select
              type="text"
              v-model="currentSelection.platform"
              placeholder="Platform"
              expanded>
              <option v-for="(platform, index) in platforms" :key="index" :value="platform">
                {{platform.label}}
              </option>
            </b-select>
          </b-field>
        </b-field>
        <b-field grouped>
        <b-field label="Category" style="justify-content: center;" expanded>
          <b-select
            type="text"
            v-model="currentSelection.category"
            placeholder="Category"
            expanded
            >
            <option v-for="(kitchenCategory, index) in kitchenCategories" :key="index" :value="kitchenCategory">
              {{kitchenCategory}}
            </option>
          </b-select>
        </b-field>
          <b-field label="Kitchen" expanded>
            <b-select
              placeholder="Campaign Kitchen"
              v-model="currentSelection.location"
              expanded>
              <option v-for="(location, index) in countryLocationsLocal" :key="index" :value="location">
                {{ location.id }}
              </option>
            </b-select>
          </b-field>
        </b-field>
        <b-button v-if="currentSelection.country && currentSelection.dates.length > 0" type="is-info" class="button-gap"  @click="select()" expanded>
          Select
        </b-button>
    </div>
  </div>
</template>

<script>
import { utils } from '@/mixins';

export default {
  mixins: [utils],
  components: {
  },
  props: ['concepts', 'countries', 'kitchens', 'locations', 'platforms', 'events', 'activeStep', 'currentSelection'],
  data() {
    return {
      kitchenStatus: [
        { label: 'Franchise', value: true },
        { label: 'Taster', value: false },
      ],
    }
  },
  computed: {
    countryLocationsLocal () {
      if (this.currentSelection.country) {
        var countryLocation = this.locations.find(location => location.country_uuid == this.currentSelection.country.uuid && !location.parent_uuid);
        return this.getCountryLocations(this.locations, countryLocation.uuid);
      }
      return []
    },
    kitchenCategories() {
      return this.$store.getters['kitchens/getKitchenCategories'];
    },
  },
  methods: {
    select() {
      this.$emit('select', {})
    },
  },
  mounted() {}
}
</script>


<style>

.my-modal .modal-close {
  background: red;
}

</style>
