var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card my-modal",
      staticStyle: { overflow: "visible" },
    },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("p", { staticClass: "title is-4" }, [
        _vm._v(" Update Tasks Group's tasks for Launch "),
      ]),
      _c("h6", { staticClass: "is-italic" }, [
        _vm._v(" You can add tasks to your actual launch by category"),
      ]),
      _c("b-table", {
        attrs: {
          data: _vm.activeTaskGroups,
          striped: true,
          hoverable: true,
          scrollable: true,
          "per-page": 10,
          paginated: true,
          "default-sort": ["desc"],
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c(
                  "b-table-column",
                  { attrs: { field: "label", label: "Label", sortable: "" } },
                  [_vm._v(" " + _vm._s(props.row.label) + " ")]
                ),
                _c(
                  "b-table-column",
                  { attrs: { filed: "actions", label: "Actions" } },
                  [
                    !_vm.isAllTasksFromTaskGroupInLaunch(props.row)
                      ? _c(
                          "b-button",
                          {
                            staticClass: "button is-small is-primary",
                            staticStyle: { "margin-left": "10px" },
                            attrs: { title: "delete" },
                            on: {
                              click: function ($event) {
                                return _vm.addLaunchTaskGroupTasks(
                                  props.row.uuid
                                )
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: { icon: "refresh", size: "is-small" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }