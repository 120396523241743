var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("b-table", {
        attrs: {
          data: _vm.kitchenCampaignsInformations,
          striped: true,
          hoverable: true,
          "sticky-header": true,
          paginated: "",
          "backend-pagination": "",
          total: _vm.kitchenCampaignsTotal,
          "per-page": _vm.perPage,
          "current-page": _vm.page,
        },
        on: { "page-change": _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "kitchen",
                      label: "Kitchen",
                      width: _vm.columnWidth,
                      sortable: "",
                      sticky: true,
                    },
                  },
                  [_vm._v(" " + _vm._s(row.kitchen) + " ")]
                ),
                _vm._l(_vm.campaignsFiltered, function (campaign, index) {
                  return _c(
                    "b-table-column",
                    {
                      key: index,
                      attrs: {
                        label: campaign["detail"].columnName,
                        "custom-key": _vm.getUniqueColumnName(
                          index,
                          campaign["detail"].columnKey
                        ),
                        meta: campaign["detail"],
                        width: _vm.columnWidth,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function (ref) {
                              var column = ref.column
                              return [
                                _c(
                                  "b-tooltip",
                                  {
                                    staticClass: "history",
                                    attrs: {
                                      type: "is-dark",
                                      label: _vm.getPromotionModel(campaign),
                                      position: "is-bottom",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _c(
                                        "div",
                                        { staticClass: "columns is-flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "column has-text-centered",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(column.label) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "columns is-flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "column has-text-centered",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    campaign["detail"].startDate
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    campaign["detail"].endDate
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "columns is-flex" },
                                        [
                                          _c("div", { staticClass: "column" }, [
                                            _c("img", {
                                              staticClass: "concept-logo",
                                              attrs: {
                                                src: campaign["detail"]
                                                  .platformLogo,
                                                alt: campaign["detail"]
                                                  .platformLabel,
                                                onerror:
                                                  "this.style.display='none'",
                                              },
                                            }),
                                          ]),
                                          _c("div", { staticClass: "column" }, [
                                            _c("img", {
                                              staticClass: "concept-logo",
                                              attrs: {
                                                src: campaign["detail"]
                                                  .conceptLogo,
                                                alt: campaign["detail"]
                                                  .conceptLabel,
                                                onerror:
                                                  "this.style.display='none'",
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            type: "is-dark",
                            label: _vm.getFinancingModel(campaign.uuid, row),
                            position: "is-top",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: _vm.getStatusButtonType(
                                  campaign.uuid,
                                  row
                                ),
                                disabled: _vm.getStatusButtonDisableState(
                                  campaign.uuid,
                                  row
                                ),
                                "icon-left": _vm.getStatusButtonIcon(
                                  campaign.uuid,
                                  row
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateStatus(campaign.uuid, row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getStatusButtonValue(campaign.uuid, row)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _c(
                  "b-table-column",
                  { attrs: { label: "Preview", width: _vm.columnWidth } },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { type: "is-link is-small" },
                        on: {
                          click: function ($event) {
                            return _vm.openCampaignPreview(row)
                          },
                        },
                      },
                      [_vm._v(" Preview ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }