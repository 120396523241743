<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center">
    <div class="title is-4">Platform Commission</div>
      <section class="has-text-centered">
        <b-field label="Platform commission" expanded>
          <b-numberinput v-model="value" type="number"> </b-numberinput>
        </b-field>
      </section>
    <div v-if="isInternalLaunch">
      <div class="title is-4" style="margin-top: 32px">Taster Commission</div>
        <table class="table">
          <thead>
            <tr>
              <th>Delivery method</th>
              <th>Start date</th>
              <th>End date</th>
              <th>Commmission rate</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(commission, index) in commissions" :key="index">
              <td>{{ commission.delivery_method }}</td>
              <td>{{ commission.start_date }}</td>
              <td>{{ commission.end_date }}</td>
              <td>
                <b-input v-model="commission.rate" type="number" />
              </td>
            </tr>
          </tbody>
        </table>
    </div>
    <b-button
      style="margin-top: 32px"
      expanded
      :loading="!hasCommissions || isLoading"
      :disabled="!hasCommissions || isLoading"
      type="button is-success"
      @click="update"
      >Set Up</b-button
    >
  </div>
</template>

<script>
/* eslint-disable */
import commissionsApi from '@/apis/onboarding/commissions';

export default {
  props: ['restaurantUuid', 'platformCommission', 'isInternalLaunch'],
  data: () => ({
    value: null,
    commissions: [],
    isLoading: false,
  }),
  methods: {
    update() {
      if (this.isInternalLaunch) {
        return this.updateValue()
      }
      else {
        this.$emit('updateValue', this.value);
        this.$parent.close();
      }
    },
    updateValue() {
      this.isLoading = true;

      Promise.all(this.commissions.map((commission) => commissionsApi.createCommission(commission)))
        .then(() => {
          this.$emit('updateValue', this.value);
          this.$parent.close();
        })
        .catch((err) => {
          if (err.response.status === 409) {
            this.$emit('updateValue', this.value);
            this.$parent.close();
          } else {
            this.$buefy.toast.open({
              duration: 5000,
              message: err.response.data.detail,
              type: 'is-danger',
            });
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    hasCommissions() {
      return this.commissions.length !== 0;
    },
  },
  mounted() {
    this.value = this.platformCommission;
    commissionsApi.buildCommissions({ restaurant_uuid: this.restaurantUuid }).then((data) => (this.commissions = data));
  },
};
</script>
