import axios from '@/axios';
import { baseUrl, baseUrlV2 } from '@/apis'


const ressourcePath = `${baseUrlV2}/kitchens`;

export default {
/* eslint-disable */
  getKitchens(queryParameters) {
    return axios.get(`${ressourcePath}${queryParameters}`)
                .then(response => response.data)
  },
  getKitchen(kitchenUUID) {
    return axios.get(`${ressourcePath}/${kitchenUUID}`)
                .then(response => response.data)
  },
  createKitchen(payload) {
    // return axios.post(
    //         ressourcePath,
    //         payload.kitchen
    //       )
    //       .then(response => response.data)
  },
  updateKitchen(payload) {
    const path = `${ressourcePath}/${payload.kitchenUUID}`;
    return axios.patch(
            path,
            payload.kitchen
          )
          .then(response => response.data)
  },
  deleteKitchen(kitchenUUID) {
    const path = `${ressourcePath}/${kitchenUUID}`;
    return axios.delete(
            path,
          )
          .then(response => response.data)
  },
  getSchema() {
    return axios.get(`${baseUrl}/openapi.json`)
      .then(response => response.data)
  },
};
