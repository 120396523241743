import axios from '@/axios';
import { baseUrlV2 } from '@/apis'


const ressourcePath = `${baseUrlV2}/platforms`;

export default {
/* eslint-disable */
  fetchOne(platformUUID) {
    return axios.get(`${ressourcePath}/${platformUUID}`)
                .then(response => response.data);
  },
  fetchAll(opts) {
    return axios.get(ressourcePath)
                .then(response => response.data);
  },
  getPlatforms(queryParameters) {
    if (!queryParameters) {
      queryParameters = '';
    }
    return axios.get(`${ressourcePath}${queryParameters}`)
                .then(response => response.data)
  },
  createPlatform(payload) {
    return axios.post(
                  ressourcePath,
                  payload.platform,
                  )
                .then(response => response.data)
  },
  updatePlatform(payload) {
    const path = `${ressourcePath}/${payload.platformUUID}`;
    return axios.patch(
            path,
            payload.platform,
          )
          .then(response => response.data)
  },
  updatePlatformPicture(payload) {
    const path = `${ressourcePath}/${payload.platformUUID}/upload_picture`;
    var formData = this.generateFormData(payload.platformPicture);
    return axios.patch(
            path,
            formData,
            {headers: {'Content-Type': 'multipart/form-data'}}
          )
          .then(response => response.data)
  },
  getSchema() {
    return axios.get(`${baseUrl}/openapi.json`)
      .then(response => response.data)
  },
  generateFormData(payload) {
    var formData = new FormData();
    for (var property in payload) {
      formData.append(property, payload[property])
    }
    return formData
  },
};
