<template>
  <section class="section">
    <div class="container">
      <h1 class="title has-text-dark">
        <div class="columns is-mobile">
          <div class="column is-narrow">
          {{ $t('concepts.title') }}
          </div>
          <div class="column">
            <button class="add-button button is-success is-small"
              @click="OpenConceptModal(null)">
              {{ $t('concepts.add') }}
            </button>
          </div>
        </div>
      </h1>
      <hr>
      <section>
        <section v-if="concepts.length > 0">
          <ConceptTable
            :concepts="concepts"
            @activate="activateConcept"
            @edit="OpenConceptModal"
            @upload_picture="openPictureModal" />
          <hr>
        </section>
      </section>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';

import ConceptTable from '@/components/Kitchen/Concept/ConceptTable.vue'
import ConceptForm from '@/components/Kitchen/Concept/ConceptForm.vue'
import ConceptPictureForm from '@/components/Kitchen/Concept/ConceptPictureForm.vue'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      concepts: state => state.concepts.all,
      current_error: state => state.concepts.current_error,
    }),
    conceptCategories() {
      return this.$store.getters['concepts/getConceptCategories'];
    },
    activePlatformsSorted(){
      return this.$store.getters['platforms/getActivePlatforms'].sort((platform1, platform2) => platform1.label.localeCompare(platform2.label));
    },
  },
  methods: {
    activateConcept: function (conceptUUID, conceptToActive) {
      if (conceptToActive) {
        return this.updateConcept(conceptUUID, {'is_deleted': false})
      }
      else {
        return this.updateConcept(conceptUUID, {'is_deleted': true})
      }
    },
    OpenConceptModal: function(conceptUUID) {
      if (!conceptUUID) {
        var concept = this.$store.getters['concepts/getNewConcept'];
        var creationModal = true;
      }
      else {
        var concept = this.$store.getters['concepts/getConcept'](conceptUUID);
        var creationModal = false;
      }
      this.$buefy.modal.open({
          component: ConceptForm,
          parent: this,
          hasModalCard: true,
          events: {
              created: (concept) => this.createConcept(concept),
              updated: (conceptUUID, concept) => this.updateConcept(conceptUUID, concept),
              creationclosed: () => this.$store.dispatch("concepts/resetNewConcept"),
              updateclosed: () => this.$store.dispatch("concepts/getConcepts"),
          },
          props: {
            concept: concept,
            conceptSchema: this.$store.getters['concepts/getConceptSchema'],
            creationModal: creationModal,
            conceptCategories: this.conceptCategories,
          }
      })
    },
    createConcept: function(concept) {
      this.$store.dispatch('concepts/createConcept', {
        concept: concept
      })
      this.$store.dispatch("concepts/resetNewConcept");
    },
    updateConcept: function(conceptUUID, concept) {
      this.$store.dispatch('concepts/updateConcept', {
        conceptUUID: conceptUUID,
        concept: concept
      })
    },
    openPictureModal: function(conceptUUID) {
      var concept = this.$store.getters['concepts/getConcept'](conceptUUID);
      var picture = this.$store.getters['concepts/getNewPicture'];
      concept.logo_file = null;

      this.$buefy.modal.open({
          component: ConceptPictureForm,
          parent: this,
          hasModalCard: true,
          events: {
              updated: (conceptUUID, updated_picture) => this.updateConceptPicture(conceptUUID, updated_picture),
              updateclosed: () => this.$store.dispatch("concepts/getConcepts"),
          },
          props: {
            concept: concept,
            picture: picture,
            platforms: this.activePlatformsSorted,
          }
      })
    },
    updateConceptPicture: function(conceptUUID, conceptPicture) {
      this.$store.dispatch('concepts/updateConceptPicture', {
        conceptUUID: conceptUUID,
        conceptPicture: conceptPicture
      })
    },
    displayError(errorMessage) {
      this.$buefy.toast.open({
          duration: 5000,
          message: errorMessage,
          type: 'is-danger'
      })
      this.$store.dispatch("concepts/resetCurrentError");
    }
  },
  mounted() {
    this.$store.dispatch("concepts/getConcepts");
    this.$store.dispatch("concepts/getSchema");
    this.$store.dispatch("platforms/getPlatforms");
  },
  watch: {
    current_error(newValue, oldValue) {
      if(newValue) {
        this.displayError(newValue)
      }
    },
  },
  components: {
      ConceptTable
  },
}
</script>

<style>
  .add-button {
    margin-top: 8px;
  }
</style>
