<template>
  <div>
    <b-modal :active.sync="openModal" scroll="keep" :full-screen="true">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title titleCase">Marketing Calendar</p>
      </header>
    <section class="modal-card-body">
      <table>
        <tbody v-if="kitchenCampaignsInformations">
              <td class='titleCase'>
                <thead>
                    <tr>
                        <td class='titleCase'>Dates</td>
                        <td v-for="(dateKey, index) in dateKeys" :key="index" class='titleCase'> {{formatDateKey(dateKey)}} </td>
                    </tr>
                    <tr>
                      <td class='titleCase'>Events</td>
                      <template v-for="dateKey in dateKeys">
                        <td v-for="(eventName) in getEventsNames(dateKey)" :key="getEventKey(dateKey, eventName)" class='titleCase'>
                          {{eventName}}
                        </td>
                      </template>
                    </tr>
                </thead>
                <tbody>
                  <tr v-for="(platformKey, index) in getPlatformKeys()" :key="index">
                    <td class='platform'><img width='50px' :src="getPlatformLogo(platformKey)"/></td>
                    <template v-for="dateKey in dateKeys" class='eventName'>
                        <template v-for="eventName in getEventsNames(dateKey)">
                          <td v-for="campaignName in getCampaignNames(dateKey, eventName, platformKey)" :key="getCampaignKey(dateKey, eventName, campaignName)">
                            <table v-if="campaignName">
                              <tr>
                                <td class='titleCase' colspan="4"> {{ campaignName }}</td>
                              </tr>
                              <tr>
                                <td>Concept</td>
                                <td>Campaign Type</td>
                                <td>Financing Model</td>
                                <td>Uplift</td>
                              </tr>
                              <tr v-for="campaign in getCampaigns(dateKey, eventName, platformKey, campaignName)" :key="getUniqueCampaignKey(dateKey, eventName, campaignName, campaign)">
                                <td class='platform'><img width='50px' :src="getConceptLogo(campaign.conceptUUID)"/></td>
                                <td>{{campaign.campaignType}}</td>
                                <td>{{campaign.financingModel}}</td>
                                <td>{{campaign.estimatedUplift}}</td>
                              </tr>
                            </table>
                          </td>
                        </template>
                      </template>
                    </tr>
                </tbody>
              </td>
        </tbody>
      </table>
    </section>
    <footer class="modal-card-foot">
    </footer>
    </div>
    </b-modal>
  </div>
</template>


<script>
// import VueHtml2pdf from 'vue-html2pdf'

export default {
    props: ['kitchenCampaignInfo', 'campaigns', 'platforms', 'concepts', 'kitchenCategory'],
    components: {
        // VueHtml2pdf
    },
    computed: {
      kitchenCampaignsInformations: function() {
        if (!this.kitchenCategory) {
          return {}
        }
        var kitchenCampaignInfo = this.kitchenCampaignInfo[this.kitchenCategory.label]
        if (!kitchenCampaignInfo || !Object.keys(kitchenCampaignInfo).includes('campaigns')) {
          return {};
        }
        var kitchenCampaignsByDateAndEventAndPlatform = {};
        for (const campaignInfo of Object.values(kitchenCampaignInfo['campaigns'])) {
          if(!campaignInfo['startDate'] || !campaignInfo['endDate'] || !campaignInfo['platformUUID']) {
            continue;
          }
          var dateKey = `${campaignInfo['startDate']}_${campaignInfo['endDate']}`;
          var eventKey = `${campaignInfo['eventLabel']}`;
          var platformKey = `${campaignInfo['platformUUID']}`;
          var campaignKey = `${campaignInfo['label']}`;
          if (!Object.keys(kitchenCampaignsByDateAndEventAndPlatform).includes(dateKey) ) {
            kitchenCampaignsByDateAndEventAndPlatform[dateKey] = {};
          }
          if (!Object.keys(kitchenCampaignsByDateAndEventAndPlatform[dateKey]).includes(eventKey) ) {
            kitchenCampaignsByDateAndEventAndPlatform[dateKey][eventKey] = {};
          }
          if (!Object.keys(kitchenCampaignsByDateAndEventAndPlatform[dateKey][eventKey]).includes(platformKey) ) {
            kitchenCampaignsByDateAndEventAndPlatform[dateKey][eventKey][platformKey] = {};
          }
          if (!Object.keys(kitchenCampaignsByDateAndEventAndPlatform[dateKey][eventKey][platformKey]).includes(campaignKey) ) {
            kitchenCampaignsByDateAndEventAndPlatform[dateKey][eventKey][platformKey][campaignKey] = [];
          }
          kitchenCampaignsByDateAndEventAndPlatform[dateKey][eventKey][platformKey][campaignKey].push(campaignInfo);
        }
        return kitchenCampaignsByDateAndEventAndPlatform;
      },
      dateKeys: function() {
        return  Array.from(Object.keys(this.kitchenCampaignsInformations)).sort();
      },
    },
    data() {
      return {
        openModal: true,
      }
    },
    methods: {
        formatDateKey(dateKey) {
          var splittedDate = dateKey.split('_').map(date => this.formatDate(new Date(date)));
          return splittedDate.join(' - ');
        },
        formatDate(date) {
          return `${date.getDate()} ${this.capitalizeFirstLetter(date.toLocaleString('default', { month: 'long' }))} ${date.getFullYear()}`;
        },
        capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1);
        },
        getEventsNames(date) {
          return Object.keys(this.kitchenCampaignsInformations[date]);
        },
        getEventKey(date, eventName) {
          return `${date}_${eventName}`
        },
        getPlatformKeys() {
          var platformKeys = [];
          for (let event of Object.values(this.kitchenCampaignsInformations)) {
            for (let platformEvent of Object.values(event)) {
              platformKeys = platformKeys.concat(Array.from(Object.keys(platformEvent)));
            }
          }
          return Array.from(new Set(platformKeys));
        },
        getPlatformLogo(platformUUID) {
          return this.platforms.find(platform => platform.uuid == platformUUID).logo;
        },
        getCampaignNames(dateKey, eventKey, platformKey) {
          try {
            return Array.from(Object.keys(this.kitchenCampaignsInformations[dateKey][eventKey][platformKey]));
          } catch (error) {
            return ['']
          }
        },
        getCampaignKey(date, eventName, campaignName) {
          return `${date}_${eventName}_${campaignName}`
        },
        getCampaigns(dateKey, eventKey, platformKey, campaignName) {
          try {
            return this.kitchenCampaignsInformations[dateKey][eventKey][platformKey][campaignName];
          } catch (error) {
            return {}
          }
        },
        getUniqueCampaignKey(date, eventName, campaignName, campaign) {
          return `${date}_${eventName}_${campaignName}_${campaign.uuid}`
        },
        getConceptLogo(conceptUUID) {
          return this.concepts.find(concept => concept.uuid == conceptUUID).logo;
        },
        generateReport () {
            this.$refs.html2Pdf.generatePdf()
        }
    },
}
</script>


<style scoped>
.name-row {
  background: #ddd;
  border-top: 1px solid black;
  }

  .group-name {
  background: #eee;
  }
  td {
  border: 1px solid #999;
  padding: 0.5rem;
  }
  th {
  border: 1px solid #FF0000;
  padding: 0.5rem;
  }
  .titleCase {
    text-align: center;
    vertical-align: middle;
  }
  .first {
    width: 100%;
  }
  .platform {
    vertical-align: middle;
  }
</style>
