<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close" full-screen>
      <section v-if="kitchen && launch && concept && location && platforms">
        <div class="card" style="width: auto">
          <div class="card-content">
            <div class="columns is-flex" >
              <div class="column is-narrow">
                  <h1 class="title is-3"> {{kitchen.id}} </h1>
              </div>
              <div class="column is-narrow is-one-fifth" v-if="concept.is_internal">
                <img
                  :src="concept.logo"
                  :alt="concept.label"
                  onerror="this.style.display='none'"
                  class="concept-logo"
                />
              </div>
              <div class="column is-narrow is-one-fifth" v-else>
                {{ concept.label }}
              </div>
              <div class="column" v-for="(platform, index) in onboardingPlatforms" :key="index">
                  <component
                    :is="isRestaurantUrlDefined(platform) ? 'a' : 'span'"
                    :href="getRestaurantUrl(platform)"
                    target="_blank"
                  >
                    <img
                      :src="platform.logo"
                      :alt="platform.label"
                      onerror="this.style.display='none'"
                      class="concept-logo"
                    />
                  </component>
              </div>
              <div class="column is-one-fifth">
                <b-button icon-left="plus" type="is-warning is-light is-small"
                  @click="openEditLaunchTaskGroupModal()">
                  Add new tasks to current Launch
                </b-button>
              </div>
            </div>
          </div>
          <section class="card-content">
            <b-tabs expanded>
                <b-tab-item label="Tasks" icon="gavel">
                  <TasksLists
                    :allTasks="allTasks"
                    :kitchen="kitchenWithoutDeleted"
                    :location="location"
                    :tasksByTaskGroup="tasksByTaskGroup"
                    :tasksByTaskOwner="tasksByTaskOwner"
                    :taskType="selectedTaskType"
                  />
                </b-tab-item>
                <b-tab-item label="Summary" icon="book">
                  <LaunchTasksModalSummary
                    :taskType="selectedTaskType"
                    :allTasks="allTasks"
                    :tasksByTaskGroup="tasksByTaskGroup"
                    :tasksByTaskOwner="tasksByTaskOwner"
                  />
                </b-tab-item>
                <b-tab-item label="Information" icon="home">
                  <LaunchInformation
                    :kitchen="kitchenWithoutDeleted"
                    :launch="launch"
                  />
                </b-tab-item>
            </b-tabs>
          </section>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';

import EditLaunchTaskGroupModal from '@/components/Onboarding/LaunchTasks/Forms/EditLaunchTaskGroupModal.vue'
import LaunchInformation from '@/components/Onboarding/LaunchTasks/LaunchInformation.vue'
import LaunchTasksModalSummary from '@/components/Onboarding/LaunchTasks/LaunchTasksModalSummary.vue'
import TasksLists from '@/components/Onboarding/LaunchTasks/LaunchTasksModalLists.vue'

export default {
  components: {
    LaunchInformation,
    LaunchTasksModalSummary,
    TasksLists,
  },
  props: [],
  data() {
    return {
      openModal: true,
      selectedTaskType: 'By Task Groups',
      tasksBy: [
        {
          'label': 'All Tasks'
        },
        {
          'label': 'By Task Groups'
        },
        {
          'label': 'By Teams'
        }
      ],
    }
  },
  computed: {
    ...mapState({
      concept : (state) => state.concepts.current,
      kitchen : (state) => state.kitchens.current,
      launch : (state) => state.launches.current,
      location : (state) => state.locations.current,
      platforms : (state) => state.platforms.all,
      taskGroups: (state) => state.taskGroups.all,
      isLoading: (state) => state.concepts.fetchOne.pending || state.kitchens.fetchOne.pending || state.launches.fetchOne.pending || state.locations.fetchOne.pending || state.platforms.fetchAll.pending || state.taskGroups.fetchAll.pending,
    }),
    onboardingPlatforms() {
      return this.platforms.filter(platform => this.launch.platform_uuids.includes(platform.uuid))
    },
    tasks() {
      return this.tasksByTaskGroup
    },
    allTasks() {
      var allTasks = {'tasks': [], 'id': 'All Tasks'};
      for (let task of this.launch.tasks) {
        allTasks.tasks.push(task)
      }
      this.updateTaskGroupStats([allTasks]);
      return [allTasks]
    },
    tasksByTaskGroup() {
      var tasksByTaskGroup = {};
      for (let task of this.launch.tasks) {
        if (!(task.task_group_label in tasksByTaskGroup)) {
          tasksByTaskGroup[task.task_group_label] = {}
          tasksByTaskGroup[task.task_group_label].id = task.task_group_label
          tasksByTaskGroup[task.task_group_label].tasks = [task]
        }
        else {
          tasksByTaskGroup[task.task_group_label].tasks.push(task)
        }
      }
      var tasks = Object.values(tasksByTaskGroup)
      this.updateTaskGroupStats(tasks);
      for (let task of tasks) {
        var correspondingTaskGroup = this.taskGroups.find(taskGroup => taskGroup.label == task.id);
        if (correspondingTaskGroup) {
          task.tasks.sort(function(a, b){
            return correspondingTaskGroup.task_uuids.indexOf(a.uuid) - correspondingTaskGroup.task_uuids.indexOf(b.uuid);
          });
        }
      }
      return tasks
    },
    tasksByTaskOwner() {
      var tasksByTaskOwner = {};
      for (let task of this.launch.tasks) {
        if (!(task.owner in tasksByTaskOwner)) {
          tasksByTaskOwner[task.owner] = {}
          tasksByTaskOwner[task.owner].id = task.owner
          tasksByTaskOwner[task.owner].tasks = [task]
        }
        else {
          tasksByTaskOwner[task.owner].tasks.push(task)
        }
      }
      var tasks = Object.values(tasksByTaskOwner);
      this.updateTaskGroupStats(tasks);
      return tasks
    },
    kitchenWithoutDeleted() {
      var kitchen = Object.assign({}, this.kitchen);
      kitchen.restaurants = kitchen.restaurants.filter(restaurant => !restaurant.is_deleted);
      return kitchen
    }
  },
  methods: {
    ...mapActions('concepts', { fetchConcept: 'fetchOne' }),
    ...mapActions('kitchens', { fetchKitchen: 'getKitchen' }),
    ...mapActions('launches', ['addTaskGroup', 'fetchAll', 'removeTaskGroup', 'fetchOne']),
    ...mapActions('locations', { fetchLocation: 'getLocation' }),
    ...mapActions('platforms', { fetchPlatforms: 'fetchAll' }),
    ...mapActions('taskGroups', { fetchTaskGroups: 'fetchAll' }),
    isRestaurantUrlDefined(platform) {
      var kitchenRestaurants = this.kitchen.restaurants || [];
      var restaurant = kitchenRestaurants.find(restaurant => !restaurant.is_deleted && restaurant.platform_uuid == platform.uuid && restaurant.concept_uuid == this.concept.uuid);
      if (restaurant && restaurant.url) {
        return true;
      }
      return false;
    },
    getRestaurantUrl(platform) {
      var kitchenRestaurants = this.kitchen.restaurants || [];
      var restaurant = kitchenRestaurants.find(restaurant => !restaurant.is_deleted && restaurant.platform_uuid == platform.uuid && restaurant.concept_uuid == this.concept.uuid);
      if (restaurant && restaurant.url) {
        return restaurant.url
      }
      return ''
    },
    openEditLaunchTaskGroupModal: function() {
      this.$buefy.modal.open({
        component: EditLaunchTaskGroupModal,
        parent: this,
        hasModalCard: true,
        events: {
          addLaunchTaskGroupTasks: (opts) => this.addTaskGroup(opts).then(() => this.fetchAll({'current': true})),
          deleteLaunchTaskGroupTasks: (opts) => this.removeTaskGroup(opts).then(() => this.fetchAll({'current': true})),
        },
        props: {}
      })
    },
    updateTaskGroupStats(tasksGrouped) {
      for (let taskGrouped of tasksGrouped) {
        var doneTasks = taskGrouped.tasks.filter(task => task.is_done);
        taskGrouped.progression = (doneTasks.length / taskGrouped.tasks.length) * 100;
        taskGrouped.availableTasks = taskGrouped.tasks.filter(task => !task.is_lock && !task.is_done).length;
      }
    },
    close() {
      this.$router.push('/onboarding')
    },
  },
  mounted() {
    Promise.all(
      [
        this.fetchOne(this.$route.params.onboardingUUID)
      ]
    ).then(() => {
      Promise.all(
        [
          this.fetchKitchen(this.launch.kitchen_uuid),
          this.fetchLocation(this.launch.location_uuid),
          this.fetchConcept(this.launch.concept_uuid),
          this.fetchPlatforms({}),
          this.fetchTaskGroups('?is_deleted=false'),
        ]
      )
    }
    )
  }
}
</script>



<style scoped>

.task-modal-container{
  overflow-y: auto;
}

</style>
