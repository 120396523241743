var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.openModal, scroll: "keep", "on-cancel": _vm.close },
      on: {
        "update:active": function ($event) {
          _vm.openModal = $event
        },
      },
    },
    [
      _c("section", { staticClass: "modal-card-body has-text-centered" }, [
        _vm.kitchen.address && !_vm.useNewAddress
          ? _c(
              "div",
              [
                _c(
                  "b-field",
                  { attrs: { label: "Address", expanded: "" } },
                  [
                    _c("b-input", {
                      attrs: {
                        type: "text",
                        placeholder: "Address",
                        disabled: "",
                      },
                      model: {
                        value: _vm.kitchen.address,
                        callback: function ($$v) {
                          _vm.$set(_vm.kitchen, "address", $$v)
                        },
                        expression: "kitchen.address",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { expanded: "", type: "button is-success" },
                        on: { click: _vm.updateTask },
                      },
                      [_vm._v("Validate Address")]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { expanded: "", type: "button is-warning" },
                        on: {
                          click: function ($event) {
                            return _vm.displayNewAddress()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.useAddressButtonText))]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        !_vm.kitchen.address || _vm.useNewAddress
          ? _c(
              "div",
              [
                _c("b", [_vm._v(" Address ")]),
                _c(
                  "b-switch",
                  {
                    attrs: {
                      "passive-type": "is-success",
                      "false-value": "Google Map",
                      "true-value": "Manual",
                      type: "is-danger",
                      size: "is-small",
                    },
                    model: {
                      value: _vm.manualAddressMode,
                      callback: function ($$v) {
                        _vm.manualAddressMode = $$v
                      },
                      expression: "manualAddressMode",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.manualAddressMode) + " ")]
                ),
                _c("b-field", { attrs: { label: "", expanded: "" } }, [
                  _vm.manualAddressMode == "Google Map"
                    ? _c(
                        "div",
                        [
                          _c("vue-google-autocomplete", {
                            attrs: {
                              id: "map",
                              classname: "google-autocomplete",
                              country: ["fr", "uk", "es", "be", "nl"],
                            },
                            on: { placechanged: _vm.getAddressData },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          }),
                          _c(
                            "b-button",
                            {
                              staticClass: "button-gap",
                              attrs: {
                                expanded: "",
                                disabled: _vm.value == null,
                                type: "button is-success",
                              },
                              on: { click: _vm.updateValue },
                            },
                            [_vm._v("Set Up")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("b-input", {
                            attrs: { type: "text", placeholder: "Address" },
                            model: {
                              value: _vm.manualAddress.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.manualAddress, "address", $$v)
                              },
                              expression: "manualAddress.address",
                            },
                          }),
                          _c(
                            "b-field",
                            { attrs: { grouped: "" } },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "Country", expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      required: "",
                                      disabled: true,
                                      placeholder: "Kitchen Country",
                                    },
                                    model: {
                                      value: _vm.kitchen.country,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.kitchen, "country", $$v)
                                      },
                                      expression: "kitchen.country",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { label: "City", expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      required: "",
                                      disabled: true,
                                      placeholder: "Kitchen City",
                                    },
                                    model: {
                                      value: _vm.location.city,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.location, "city", $$v)
                                      },
                                      expression: "location.city",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { grouped: "" } },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "Postcode", expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "Postcode",
                                      required: "",
                                    },
                                    model: {
                                      value:
                                        _vm.manualAddress.address_information
                                          .postcode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.manualAddress.address_information,
                                          "postcode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "manualAddress.address_information.postcode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { label: "Route", expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "Route",
                                      required: "",
                                    },
                                    model: {
                                      value:
                                        _vm.manualAddress.address_information
                                          .route,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.manualAddress.address_information,
                                          "route",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "manualAddress.address_information.route",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Street Number",
                                    expanded: "",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "Street Number",
                                    },
                                    model: {
                                      value:
                                        _vm.manualAddress.address_information
                                          .street_number,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.manualAddress.address_information,
                                          "street_number",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "manualAddress.address_information.street_number",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { grouped: "" } },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "Latitude", expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      step: "0.000000000001",
                                      type: "number",
                                      placeholder: "Latitude",
                                      required: "",
                                    },
                                    model: {
                                      value:
                                        _vm.manualAddress.address_information
                                          .latitude,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.manualAddress.address_information,
                                          "latitude",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "manualAddress.address_information.latitude",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { label: "Longitude", expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      step: "0.000000000001",
                                      type: "number",
                                      placeholder: "Longitude",
                                      required: "",
                                    },
                                    model: {
                                      value:
                                        _vm.manualAddress.address_information
                                          .longitude,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.manualAddress.address_information,
                                          "longitude",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "manualAddress.address_information.longitude",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "button-gap",
                              attrs: {
                                expanded: "",
                                disabled: _vm.disableManualAddress,
                                type: "button is-success",
                              },
                              on: { click: _vm.updateManualValue },
                            },
                            [_vm._v("Set Up")]
                          ),
                        ],
                        1
                      ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }