var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "has-text-centered" },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("h4", { staticClass: "title has-text-centered is-4" }, [
        _vm._v(" Ops members Accesses"),
      ]),
      _c(
        "b-field",
        { staticStyle: { "justify-content": "center" } },
        _vm._l(_vm.launchTeamsToSelect, function (team, index) {
          return _c(
            "div",
            { key: index },
            _vm._l(team.members, function (member, index) {
              return _c(
                "b-checkbox",
                {
                  key: index,
                  on: {
                    input: function ($event) {
                      return _vm.addTeamMember(member)
                    },
                  },
                },
                [_c("div", [_vm._v(_vm._s(member))])]
              )
            }),
            1
          )
        }),
        0
      ),
      _c(
        "b-button",
        {
          attrs: {
            expanded: "",
            type: "button is-success button-location-gap",
          },
          on: { click: _vm.selectMembers },
        },
        [_vm._v("Select")]
      ),
      _c("h4", { staticClass: "title is-italic is-5" }, [
        _vm._v(
          " NB: The Tech, Marketing and Support teams will have the necessary right on this new kitchen. The Supply and Expansion teams of the country as well."
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }