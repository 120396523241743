var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.openModal,
            scroll: "keep",
            "on-cancel": _vm.close,
            "full-screen": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.openModal = $event
            },
          },
        },
        [
          _c("section", [
            _c("div", { staticClass: "card", staticStyle: { width: "auto" } }, [
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "media" }, [
                  _c("div", { staticClass: "media-content" }, [
                    _c("p", { staticClass: "title is-4" }, [
                      _vm._v(" New Launch "),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "section",
                { staticClass: "card-content" },
                [
                  _c(
                    "b-steps",
                    {
                      attrs: {
                        size: "is-small",
                        type: "is-info",
                        "has-navigation": false,
                      },
                      model: {
                        value: _vm.activeStep,
                        callback: function ($$v) {
                          _vm.activeStep = $$v
                        },
                        expression: "activeStep",
                      },
                    },
                    [
                      _c(
                        "b-step-item",
                        { attrs: { label: "Kitchen Selection", step: "1" } },
                        [
                          _c("hr"),
                          _c("KitchenSelectionForm", {
                            attrs: {
                              activeStep: _vm.activeStep,
                              newKitchen: _vm.newKitchen,
                              newExternalKitchen: _vm.newExternalKitchen,
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:newKitchen": function ($event) {
                                _vm.newKitchen = $event
                              },
                              "update:new-kitchen": function ($event) {
                                _vm.newKitchen = $event
                              },
                              "update:newExternalKitchen": function ($event) {
                                _vm.newExternalKitchen = $event
                              },
                              "update:new-external-kitchen": function ($event) {
                                _vm.newExternalKitchen = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Kitchen Configuration",
                            step: "2",
                            visible: _vm.showStep(1),
                          },
                        },
                        [
                          _c("hr"),
                          _c("KitchenConfigurationForm", {
                            attrs: {
                              activeStep: _vm.activeStep,
                              countries: _vm.countries,
                              kitchens: _vm.kitchens,
                              locations: _vm.locations,
                              newKitchen: _vm.newKitchen,
                              newExternalKitchen: _vm.newExternalKitchen,
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:newKitchen": function ($event) {
                                _vm.newKitchen = $event
                              },
                              "update:new-kitchen": function ($event) {
                                _vm.newKitchen = $event
                              },
                              "update:newExternalKitchen": function ($event) {
                                _vm.newExternalKitchen = $event
                              },
                              "update:new-external-kitchen": function ($event) {
                                _vm.newExternalKitchen = $event
                              },
                              createKitchen: _vm.createKichen,
                              selectKitchen: _vm.selectKitchen,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Restaurant Selection",
                            step: "3",
                            visible: _vm.showStep(2),
                          },
                        },
                        [
                          _c("hr"),
                          _c("RestaurantSelectionForm", {
                            attrs: {
                              activeStep: _vm.activeStep,
                              newRestaurants: _vm.newRestaurants,
                              isTransfer: _vm.isTransfer,
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:newRestaurants": function ($event) {
                                _vm.newRestaurants = $event
                              },
                              "update:new-restaurants": function ($event) {
                                _vm.newRestaurants = $event
                              },
                              "update:isTransfer": function ($event) {
                                _vm.isTransfer = $event
                              },
                              "update:is-transfer": function ($event) {
                                _vm.isTransfer = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Concept Selection",
                            step: "4",
                            visible: _vm.showStep(3),
                          },
                        },
                        [
                          _c("hr"),
                          _c("ConceptSelectionForm", {
                            attrs: {
                              activeStep: _vm.activeStep,
                              concepts: _vm.concepts,
                              platforms: _vm.platforms,
                              selectedConcept: _vm.selectedConcept,
                              newRestaurants: _vm.newRestaurants,
                              kitchen: _vm.currentKitchen,
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:selectedConcept": function ($event) {
                                _vm.selectedConcept = $event
                              },
                              "update:selected-concept": function ($event) {
                                _vm.selectedConcept = $event
                              },
                              "update:newRestaurants": function ($event) {
                                _vm.newRestaurants = $event
                              },
                              "update:new-restaurants": function ($event) {
                                _vm.newRestaurants = $event
                              },
                              close: _vm.close,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Platforms Selection",
                            step: "5",
                            visible: _vm.showStep(4),
                          },
                        },
                        [
                          _c("hr"),
                          _c("PlatformSelectionForm", {
                            attrs: {
                              activeStep: _vm.activeStep,
                              platforms: _vm.platforms,
                              selectedPlatforms: _vm.selectedPlatforms,
                              newRestaurants: _vm.newRestaurants,
                              kitchen: _vm.currentKitchen,
                              country: _vm.currentCountry,
                              selectedConcept: _vm.selectedConcept,
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:selectedPlatforms": function ($event) {
                                _vm.selectedPlatforms = $event
                              },
                              "update:selected-platforms": function ($event) {
                                _vm.selectedPlatforms = $event
                              },
                              "update:newRestaurants": function ($event) {
                                _vm.newRestaurants = $event
                              },
                              "update:new-restaurants": function ($event) {
                                _vm.newRestaurants = $event
                              },
                              selectPlatforms: _vm.selectPlatforms,
                              close: _vm.close,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Restaurant Configuration",
                            step: "6",
                            visible: _vm.showStep(5),
                          },
                        },
                        [
                          _c("hr"),
                          _c("RestaurantConfigurationForm", {
                            attrs: {
                              activeStep: _vm.activeStep,
                              kitchen: _vm.currentKitchen,
                              newRestaurants: _vm.newRestaurants,
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:newRestaurants": function ($event) {
                                _vm.newRestaurants = $event
                              },
                              "update:new-restaurants": function ($event) {
                                _vm.newRestaurants = $event
                              },
                              createRestaurants: _vm.createRestaurants,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Kitchen Mails Creation",
                            step: "7",
                            visible: _vm.showStep(6),
                          },
                        },
                        [
                          _c("hr"),
                          _c("KitchenMailsCreationForm", {
                            attrs: {
                              activeStep: _vm.activeStep,
                              kitchen: _vm.currentKitchen,
                              users: [],
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStep = $event
                              },
                              createMails: _vm.createMails,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        { attrs: { label: "Team Accesses", step: "8" } },
                        [
                          _c("hr"),
                          _c("IAMForm", {
                            attrs: {
                              kitchen: _vm.currentKitchen,
                              launchTeams: _vm.launchTeams,
                            },
                            on: { selectMembers: _vm.selectMembers },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: { label: "Create Launch Process", step: "9" },
                        },
                        [
                          _c("hr"),
                          _c("CreateLaunchForm", {
                            attrs: {
                              kitchen: _vm.currentKitchen,
                              selectedConcept: _vm.selectedConcept,
                              launchTeams: _vm.launchTeams,
                            },
                            on: {
                              createLaunchProcess: _vm.createLaunchProcess,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }