<template>
  <div class="box modal-card" style="overflow: visible;">
    <b-loading :active.sync="isLoading"></b-loading>
    <p class="title is-4"> Set Up Menu </p>
    <b-steps v-model="activeStep" size="is-small" type="is-info" :has-navigation="false">
      <b-step-item label="Menu Type" step="1">
        <hr>
        <div>
          <div class="has-text-centered">
            <b-button type="is-link" class="button-gap" @click="selectMenuType(true)" expanded>
              Copy Existing Menu
            </b-button>
          </div>
        </div>
      </b-step-item>
      <b-step-item label="Menu Configuration" step="2">
        <hr>
        <div class="has-text-centered" v-if="newMenu">
          <h4 class="title has-text-centered is-4">Select Among Existing Kitchens</h4>
            <b-field grouped>
              <b-field label="Country" >
                <b-select
                  type="text"
                  v-model="selectedCountry"
                  placeholder="Country"
                  expanded
                >
                  <option v-for="(kitchenCountry, index) in getExistingKitchenCountries(countries, locations, kitchens)" :key="index" :value="kitchenCountry.uuid">
                    {{ kitchenCountry.label }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Licensee">
                <b-select
                  type="text"
                  v-model="selectedPartner"
                  placeholder="Licensee"
                  expanded
                >
                  <option v-for="(kitchenStat, index) in kitchenStatus" :key="index" :value="kitchenStat.value">
                    {{ kitchenStat.label }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Kitchen" expanded>
                <b-select
                  type="text"
                  v-model="selectedExisingKitchen"
                  placeholder="Kitchen"
                  expanded
                >
                  <option v-for="(kitchen, index) in getExistingKitchens(selectedCountry, selectedPartner, locations, kitchens, false, kitchenUUID)" :key="index" :value="kitchen">
                    {{ kitchen.id }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-button v-if="selectedCountry && selectedPartner != null && selectedExisingKitchen" type="is-info" class="button-gap"  @click="copy(selectedExisingKitchen)" expanded>
              Copy
            </b-button>
        </div>
        <div v-else>
          Go to <a :href=getPlatformURL() target="_blank">Platform Manager</a> to create dedicated menu.
          <b-button type="is-info" class="button-gap" @click="createManually()" expanded>
            Menu created
          </b-button>
        </div>
      </b-step-item>
    </b-steps>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { utils } from '@/mixins';

export default {
  mixins: [utils],
  components: {
  },
  props: ['conceptUUID', 'kitchenCountryUUID', 'kitchenUUID', 'locationUUID'],
  data() {
    return {
      activeStep: 0,
      kitchenStatus: [
        { label: 'Franchise', value: true },
        { label: 'Taster', value: false },
      ],
      selectedCountry: null,
      selectedPartner: null,
      selectedExisingKitchen: null,
      newMenu: null,
    }
  },
  computed: mapState({
    countries: (state) => state.countries.all,
    kitchens: (state) =>
      state.kitchens.all.filter(kitchen => !kitchen.is_deleted).sort((kitchen1, kitchen2) =>
        kitchen1.id.localeCompare(kitchen2.id),
    ),
    locations: (state) => state.locations.all,
    isLoading: (state) => state.kitchens.fetchAll.pending,
  }),
  methods: {
    selectMenuType(menuType) {
      this.newMenu = menuType;
      this.activeStep = 1;
    },
    getPlatformURL() {
      return `https://items.taster.com/#/platforms/${this.conceptUUID}`
    },
    copy(selectedKitchen) {
      this.$emit('setUpMenu', {
          concept_uuid: this.conceptUUID,
          location_uuid_destination: this.locationUUID,
          location_uuid_source: selectedKitchen.location_uuid,
      })
      this.$emit('close');
    },
    createManually() {
      this.$emit('createManually')
      this.$emit('close');
    },
  },
  mounted() {
    this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false', true);
  }
}
</script>


<style>

.my-modal .modal-close {
  background: red;
}

</style>
