import axios from '@/axios';
import { baseUrl } from '@/apis'


const ressourcePath = `${baseUrl}/restaurants`;

export default {
/* eslint-disable */
  getRestaurant(restaurantUUID) {
    return axios.get(`${ressourcePath}/${restaurantUUID}`)
                .then(response => response.data)
  },
  getRestaurants() {
    return axios.get(ressourcePath)
      .then(response => response.data)
  },
  createRestaurant(payload) {
    return axios.post(
            ressourcePath,
            payload.restaurant
          )
          .then(response => response.data)
  },
  updateRestaurant(payload) {
    const path = `${ressourcePath}/${payload.restaurantUUID}`;
    return axios.patch(
            path,
            payload.restaurant
          )
          .then(response => response.data)
  },
  deleteRestaurant(payload) {
    const path = `${ressourcePath}/${payload.restaurantUUID}`;
    return axios.delete(
            path,
          )
          .then(response => response.data)
  },
  activateRestaurant(restaurantUUID) {
    const path = `${ressourcePath}/${restaurantUUID}/activate`;
    return axios.post(path)
                .then(response => response.data)
  },
  deactivateRestaurant(restaurantUUID) {
    const path = `${ressourcePath}/${restaurantUUID}/deactivate`;
    return axios.post(path)
                .then(response => response.data)
  },
  exportOpeningHours(payload) {
    const path = `${ressourcePath}/opening_hours`;
    return axios.post(path, payload)
                .then(response => response.data)
  },
  getSchema() {
    return axios.get(`${baseUrl}/openapi.json`)
      .then(response => response.data)
  },
};
