<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-table
      :data="kitchenCampaignsInformations"
      :striped="true"
      :hoverable="true"
      :sticky-header="true"
      paginated
      backend-pagination
      :total="kitchenCampaignsTotal"
      :per-page="perPage"
      :current-page="page"
      @page-change="onPageChange"
      >
        <template v-slot="{row}">
          <b-table-column
            field="kitchen"
            label="Kitchen"
            :width="columnWidth"
            sortable
            :sticky="true"
          >
            {{ row.kitchen}}
        </b-table-column>
        <b-table-column
          v-for="(campaign, index) in campaignsFiltered"
          :key="index"
          :label="campaign['detail'].columnName"
          :custom-key="getUniqueColumnName(index, campaign['detail'].columnKey)"
          :meta="campaign['detail']"
          :width="columnWidth"
        >
          <template v-slot:header="{ column }">
            <b-tooltip class="history" type="is-dark" :label="getPromotionModel(campaign)" position="is-bottom">
            <span>
              <div class="columns is-flex">
                <div class="column has-text-centered">
                  {{column.label}}
                </div>
              </div>
              <div class="columns is-flex">
                <div class="column has-text-centered">
                  {{campaign['detail'].startDate}}
                  {{campaign['detail'].endDate}}
                </div>
              </div>
              <div class="columns is-flex">
                <div class="column">
                  <img
                    :src="campaign['detail'].platformLogo"
                    :alt="campaign['detail'].platformLabel"
                    onerror="this.style.display='none'"
                    class="concept-logo"
                  />
                </div>
                <div class="column">
                  <img
                    :src="campaign['detail'].conceptLogo"
                    :alt="campaign['detail'].conceptLabel"
                    onerror="this.style.display='none'"
                    class="concept-logo"
                  />
                </div>
              </div>
            </span>
          </b-tooltip>
          </template>
          <b-tooltip type="is-dark" :label="getFinancingModel(campaign.uuid, row)" position="is-top">
            <b-button
              :type="getStatusButtonType(campaign.uuid, row)"
              :disabled="getStatusButtonDisableState(campaign.uuid, row)"
              :icon-left="getStatusButtonIcon(campaign.uuid, row)"
              @click="updateStatus(campaign.uuid, row)"
            >
              {{getStatusButtonValue(campaign.uuid, row)}}
            </b-button>
          </b-tooltip>
        </b-table-column>
        <b-table-column label="Preview" :width="columnWidth">
          <b-button
            type="is-link is-small"
            @click="openCampaignPreview(row)">
              Preview
              <!-- <CampaignReport ref="myChild" /> -->
          </b-button>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
/* eslint-disable */
import { errorToasterDetailled } from '@/mixins';
import { kitchenCampaignsTypes } from '@/store/mutation-types';
import { mapActions, mapMutations, mapState } from 'vuex';

import OptOutForm from '@/components/Marketing/Forms/OptOutForm.vue';
import CampaignReport from '@/components/Marketing/Reports/CampaignReport.vue';

export default {
  components: { CampaignReport },
  props: ['baseState', 'concepts', 'countries', 'platforms', 'page', 'perPage', 'test'],
  mixins: [errorToasterDetailled],
  data() {
    return {
      columnWidth: 3000,
      isEmpty: false,
      displaySuccess: false,
      i: 0
    }
  },
  computed: {
    ...mapState({
      campaigns: (state) => state.campaigns.all,
      campaignItems: (state) => state.campaignItems.all,
      events: (state) => state.events.all,
      kitchenCampaigns: (state) => state.kitchenCampaigns.all,
      kitchenCampaignsTotal: (state) => state.kitchenCampaigns.total,
      currentKitchenCampaign: (state) => state.kitchenCampaigns.current,
      locations: (state) => state.locations.all,
      selectedConcepts: (state) => state.concepts.selected,
      selectedPlatforms: (state) => state.platforms.selected,
      selectedCampaignTimelines: (state) => state.campaigns.selectedCampaignTimelines,
      isLoading: (state) => state.kitchenCampaigns.createOne.pending || state.kitchenCampaigns.createOne.pending || state.kitchenCampaigns.updateOne.pending || state.kitchenCampaigns.updateOne.pending || state.kitchenCampaigns.updateOneStatus.pending,
    }),
    campaignTimelines() {
      return this.$store.getters['kitchenCampaigns/getKitchenCampaignTimelines'];
    },
    campaignStates () {
      return this.$store.getters['kitchenCampaigns/getKitchenCampaignStates'];
    },
    campaignsFiltered() {
      var allCampaignUUIDS = new Set(
        this.kitchenCampaigns.map(kitchenCampaign => kitchenCampaign.campaign_uuid)
      );
      var allCampaigns = this.campaigns.filter(campaign => allCampaignUUIDS.has(campaign.uuid));
      var filteredCampaigns = this.getFilteredCampaigns(allCampaigns);
      var campaignsEnriched = []
      for (let campaign of filteredCampaigns) {
        var campaignCountryLocation = this.locations.find(location => location.uuid == campaign.country_location_uuid);
        var concept = this.concepts.find(concept => concept.uuid == campaign.concept_uuid);
        var platform = this.platforms.find(platform => platform.uuid == campaign.platform_uuid);
        var campaignCountry = this.countries.find(country => country.uuid == campaignCountryLocation.country_uuid);
        var campaignEnriched = {...campaign}
        campaignEnriched['detail'] = {
          columnName: `${campaign.label} (${campaignCountryLocation.id})`,
          columnKey: `${campaignCountryLocation.id} - ${campaign.label} - (${concept.id} - ${platform.id})`,
          campaignUUID: campaign.uuid,
          campaignLabel: campaign.label,
          conceptLabel: concept.label,
          conceptUUID: concept.uuid,
          conceptLogo: concept.logo,
          conceptInternal: concept.is_internal,
          countryLocationUUID: campaignCountryLocation.uuid,
          country: campaignCountryLocation.label,
          platformLabel: platform.label,
          platformUUID: platform.uuid,
          platformLogo: platform.logo,
          campaignType: campaign.campaign_type,
          startDate: campaign.start_date,
          endDate: campaign.end_date,
          currency: campaignCountry.currency,
        }
        campaignsEnriched.push(campaignEnriched)
      }
      return campaignsEnriched.sort(function (campaign1, campaign2) {
        return campaign1.detail.conceptLabel.localeCompare(campaign2.detail.conceptLabel) || campaign1.detail.platformLabel.localeCompare(campaign2.detail.platformLabel) || campaign1.detail.startDate.localeCompare(campaign2.detail.startDate);
      });
    },
    kitchenCampaignsInformations() {
      var kitchenCampaignsInformations = [];
      var kitchenLocations = this.locations.filter(location => location.parent_uuid && !location.is_deleted).sort((location1, location2) =>
        location1.label.localeCompare(location2.label),
      );
      var allCampaignUUIDS = new Set(
        this.kitchenCampaigns.map(kitchenCampaign => kitchenCampaign.campaign_uuid)
      );
      var allCampaigns = this.campaigns.filter(campaign => allCampaignUUIDS.has(campaign.uuid));
      var filteredCampaigns = this.getFilteredCampaigns(allCampaigns);
      var filteredKitchenCampaigns = this.kitchenCampaigns.filter(kitchenCampaign => filteredCampaigns.map(campaign => campaign.uuid).includes(kitchenCampaign.campaign_uuid));
      var filteredKitchenLocations = kitchenLocations.filter(location => filteredKitchenCampaigns.map(kitchenCampaign => kitchenCampaign.location_uuid).includes(location.uuid));
      for (let kitchenLocation of filteredKitchenLocations) {
        var kitchenInfo = {
          'campaigns': {},
          'kitchen': kitchenLocation.label,
        }
        for (let campaign of filteredCampaigns) {
          var kitchenCampaign = this.kitchenCampaigns.find(
            kitchenCampaign => kitchenCampaign.location_uuid == kitchenLocation.uuid && kitchenCampaign.campaign_uuid == campaign.uuid
          );
          var eventLabel = '';
          var event = this.events.find(event => event.uuid == campaign.event_uuid);
          if (event) {
            eventLabel = event.label;
          }
          var campaignCountryLocation = this.locations.find(location => location.uuid == campaign.country_location_uuid);
          var concept = this.concepts.find(concept => concept.uuid == campaign.concept_uuid);
          var platform = this.platforms.find(platform => platform.uuid == campaign.platform_uuid);
          if (kitchenCampaign && campaignCountryLocation && concept && platform) {
            var kitchenCampaignInfo = {
              campaignUUID : campaign.uuid,
              conceptUUID: campaign.concept_uuid,
              platformUUID: campaign.platform_uuid,
              financingModel: kitchenCampaign.financing_model,
              label: campaign.label,
              startDate: kitchenCampaign.start_date,
              endDate: kitchenCampaign.end_date,
              status: kitchenCampaign.status,
              eventLabel: eventLabel,
              uuid: kitchenCampaign.uuid,
            }
            kitchenInfo['campaigns'][campaign.uuid] = kitchenCampaignInfo;
          }
          // else {
          //   continue;
          //   // var kitchenCampaignInfo = {campaignUUID: campaign.uuid, status: null, uuid:null};
          // }
        }
        kitchenCampaignsInformations.push(kitchenInfo);
      }
      var kitchenCampaignsInformationsFiltered = kitchenCampaignsInformations.filter(kitchenCampaignsInformation => Object.keys(kitchenCampaignsInformation.campaigns).length > 0);
      return kitchenCampaignsInformationsFiltered;
    },
  },
  methods: {
    ...mapActions('kitchenCampaigns', { fetchKitchenCampaigns: 'fetchAll', fetchKitchenCampaign: 'fetchOne', updateOneStatus: 'updateOneStatus' }),
    ...mapMutations('kitchenCampaigns', {'setCurrentKitchenCampaign': kitchenCampaignsTypes.SET_CURRENT}),
    getFilteredCampaigns: function(campaigns) {
      var conceptUUIDs = this.concepts.map(concept => concept.uuid);
      var platformUUIDs = this.platforms.map(platform => platform.uuid);
      var locationUUIDS = this.locations.map(location => location.uuid);
      return campaigns.filter(campaign => conceptUUIDs.includes(campaign.concept_uuid) && platformUUIDs.includes(campaign.platform_uuid) && locationUUIDS.includes(campaign.country_location_uuid));
    },
    getCampaignInfo: function(campaignUUID) {
      if (!this.campaignInformations) {
        return {}
      }
      return this.campaignInformations.find(campaignInfo => campaignInfo.campaignUUID == campaignUUID);
    },
    getStatusButtonType: function(campaignUUID, kitchenCampaignInformation) {
      if (kitchenCampaignInformation['campaigns'][campaignUUID] && kitchenCampaignInformation['campaigns'][campaignUUID].status) {
        return this.campaignStates[this.baseState]['STATES'][kitchenCampaignInformation['campaigns'][campaignUUID].status]['color']
      }
      return this.campaignStates['DEFAULT']['color']
    },
    getStatusButtonIcon: function(campaignUUID, kitchenCampaignInformation) {
      if (kitchenCampaignInformation['campaigns'][campaignUUID] && kitchenCampaignInformation['campaigns'][campaignUUID].status) {
        return this.campaignStates[this.baseState]['STATES'][kitchenCampaignInformation['campaigns'][campaignUUID].status]['icon']
      }
      return this.campaignStates['DEFAULT']['icon']
    },
    getStatusButtonDisableState: function(campaignUUID, kitchenCampaignInformation) {
      if (kitchenCampaignInformation['campaigns'][campaignUUID] && kitchenCampaignInformation['campaigns'][campaignUUID].status) {
        return this.campaignStates[this.baseState]['STATES'][kitchenCampaignInformation['campaigns'][campaignUUID].status]['disabled']
      }
      return this.campaignStates['DEFAULT']['disabled']
    },
    getStatusButtonValue: function(campaignUUID, kitchenCampaignInformation) {
      if (kitchenCampaignInformation['campaigns'][campaignUUID] && kitchenCampaignInformation['campaigns'][campaignUUID].status) {
        return this.campaignStates[this.baseState]['STATES'][kitchenCampaignInformation['campaigns'][campaignUUID].status]['text']
      }
      return this.campaignStates['DEFAULT']['text']
    },
    getPromotionModel: function(campaign) {
      var detail = `${campaign.campaign_type}`;
      if (this.isDiscountCampaign(campaign)) {
        var discountDetails = `\n Type: ${campaign.discount_type}`;
        discountDetails += `\n Value: ${campaign.discount_value}`;
        if (this.isPercentageCampaign(campaign)) {
          discountDetails += '%'
        }
        else {
          discountDetails += campaign.detail.currency;
        }
        detail += discountDetails;
      }
      if (this.isOverCampaign(campaign)) {
        var overDetails = `\n Over: ${campaign.discount_over} ${campaign.detail.currency}`;
        detail += overDetails;
      }
      if (this.isItemCampaign(campaign)) {
        var campaignItemLabels = this.campaignItems.filter(campaignItem => campaignItem.campaign_uuid == campaign.uuid).map(campaignItem => campaignItem.label);
        var detailItems = '\nItems:' + campaignItemLabels.join('\n');
        detail += detailItems
      }
      return detail;
    },
    isItemCampaign: function(campaign) {
      return campaign.campaign_type && (campaign.campaign_type.toLowerCase().includes('discount_items') || campaign.campaign_type.toLowerCase().includes('bogo') || campaign.campaign_type.toLowerCase().includes('free_items') );
    },
    isDiscountCampaign: function(campaign) {
      return campaign.campaign_type && campaign.campaign_type.toLowerCase().includes('discount');
    },
    isOverCampaign: function(campaign) {
      return this.isDiscountCampaign(campaign) && campaign.discount_type && campaign.discount_type.toLowerCase().includes('over');
    },
    isPercentageCampaign: function(campaign) {
      return this.isDiscountCampaign(campaign) && campaign.discount_type.toLowerCase().includes('percentage');
    },
    getFinancingModel: function(campaignUUID, kitchenCampaignInformation) {
      if (kitchenCampaignInformation['campaigns'][campaignUUID] && kitchenCampaignInformation['campaigns'][campaignUUID].status) {
        return kitchenCampaignInformation['campaigns'][campaignUUID].financingModel;
      }
    },
    updateStatus(campaignUUID, kitchenCampaignInformation) {
      var state = this.campaignStates[this.baseState]['STATES'][kitchenCampaignInformation['campaigns'][campaignUUID].status];
      var campaignInformation = kitchenCampaignInformation['campaigns'][campaignUUID];
      var nextState = state.next;
      if (state.confirmationModalRequired) {
        this.$buefy.modal.open({
          component: OptOutForm,
          parent: this,
          hasModalCard: true,
          events: {
            update : (opts)  => {
              this.updateOneStatus({
                  status: nextState,
                  uuid: campaignInformation.uuid,
                  ...opts
                })
          }},
          props: {
            campaignInformation: campaignInformation,
            kitchenLabel: kitchenCampaignInformation.kitchen,
            message: 'Opt-Out',
          },
          fullScreen: false,
          customClass: 'my-modal'
        })
      }
      else {
        this.updateOneStatus({
          status: nextState,
          uuid: campaignInformation.uuid,
        })
      }
    },
    getUniqueColumnName(index, columnName) {
      return `${index}- ${columnName}`
    },
    openCampaignPreview: function(kitchenCampaignInfo) {
      // this.$refs.myChild.generateReport()
      this.$buefy.modal.open({
        component: CampaignReport,
        parent: this,
        hasModalCard: true,
        events: {},
        props: {
          kitchenCampaignInfo: kitchenCampaignInfo,
          campaigns: this.campaigns,
          concepts: this.concepts,
          platforms: this.platforms,
        },
        fullScreen: true,
        customClass: 'my-modal'
      })
    },
    onPageChange: function(page) {
      this.$emit('changePage', page)
    }
  },
  mounted() {},
  watch: {},
}
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}

.b-tooltip:after {
  white-space: pre !important;
}

</style>
