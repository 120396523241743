import costsAPI from '../../../apis/itemManager/costs';
import { costsTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  current: null,
  current_error: '',
  fetchOne: {
    pending: false,
    error: null,
  },
};

const actions = {
  async fetchOne({ commit }, opts) {
    commit(costsTypes.FETCH_ONE_REQUEST);

    return costsAPI
      .fetchOne(opts)
      .then(function(cost) {
        commit(costsTypes.FETCH_ONE_SUCCESS, cost);
        return cost;
      })
      .catch(function(error) {
        commit(costsTypes.FETCH_ONE_FAILURE, error.response.data.detail);
      });
  },
};

const mutations = {
  [costsTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [costsTypes.FETCH_ONE_SUCCESS](state, cost) {
    state.current = cost;
    state.fetchOne.pending = false;
    state.fetchOne.error = null;
  },
  [costsTypes.FETCH_ONE_FAILURE](state, error) {
    state.current = null;
    state.fetchOne.pending = false;
    state.fetchOne.error = error;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
