<template>
  <div class="has-text-centered" v-if="newKitchen || newExternalKitchen">
    <b-field grouped>
      <b-field label="Kitchen Taster Name" message="Taster Convention : KFParis02, KLBrentford01..." expanded>
        <b-input
          v-model="label"
          placeholder="Kitchen Taster Name"
        ></b-input>
      </b-field>
      <b-field label="Owner" >
        <b-select
          type="text"
          v-model="owner"
          placeholder="Kitchen Franchise"
          expanded
        >
          <option v-for="(kitchenStat, index) in kitchenStatus" :key="index" :value="kitchenStat.value">
            {{ kitchenStat.label }}
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field grouped>
      <b-field label="Country" expanded>
        <b-select
          placeholder="Kitchen Country"
          v-model="country"
          expanded>
          <option v-for="(country, index) in countries" :key="index" :value="country">
            {{ country.id }}
          </option>
        </b-select>
      </b-field>
      <b-field label="City" v-if="!newCity" expanded>
        <b-select
          type="text"
          v-model="city"
          placeholder="Kitchen City"
          expanded
        >
          <option v-for="(city, index) in getAvailableCities()" :key="index" :value="city">
            {{ city }}
          </option>
        </b-select>
      </b-field>
      <b-field label="City" v-if="newCity" expanded>
        <b-input
          type="text"
          v-model="city"
          placeholder="Kitchen City"
          expanded
        >
        </b-input>
      </b-field>
      <b-field v-if="country">
        <b-button size="is-small" outlined rounded type="is-gery" @click="addNewCity"> {{cityButtonLabel}}</b-button>
      </b-field>
    </b-field>
    <b-field label="Launch Date">
      <b-datepicker
        v-model="launchDate"
        placeholder="Click to select..."
        icon="calendar-today"
        position="is-top-right"
        trap-focus>
      </b-datepicker>
    </b-field>
    <b-button expanded :disabled="city == null || country == null || label == null || launchDate == null || owner == null" type="button is-success" @click="createKitchen">Create</b-button>
  </div>
  <div class="has-text-centered" v-else>
    <h4 class="title has-text-centered is-4">Select Among Existing Kitchens</h4>
      <b-field grouped>
        <b-field label="Country" >
          <b-select
            type="text"
            v-model="selectedCountry"
            placeholder="Country"
            expanded
          >
            <option v-for="(kitchenCountry, index) in getExistingKitchenCountries(countries, locations, kitchens)" :key="index" :value="kitchenCountry.uuid">
              {{ kitchenCountry.label }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Licensee">
          <b-select
            type="text"
            v-model="selectedPartner"
            placeholder="Licensee"
            expanded
          >
            <option v-for="(kitchenStat, index) in kitchenStatus" :key="index" :value="kitchenStat.value">
              {{ kitchenStat.label }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Kitchen" expanded>
          <b-select
            type="text"
            v-model="selectedExisingKitchen"
            placeholder="Kitchen"
            expanded
          >
            <option v-for="(kitchen, index) in getExistingKitchens(selectedCountry, selectedPartner, locations, kitchens, false, null)" :key="index" :value="kitchen">
              {{ kitchen.id }}
            </option>
          </b-select>
        </b-field>
      </b-field>
      <b-button v-if="selectedCountry && selectedPartner != null && selectedExisingKitchen" type="is-info" class="button-gap"  @click="selectKitchen(selectedExisingKitchen)" expanded>
        Select
      </b-button>
  </div>
</template>

<script>
  import { utils } from '@/mixins';

  export default {
  mixins: [utils],
  data() {
    return {
      city: null,
      country: null,
      label: null,
      launchDate: null,
      owner: null,
      kitchenStatus: [
        { label: 'Franchise', value: true },
        { label: 'Taster', value: false },
      ],
      newCity: false,
      selectedCountry: null,
      selectedPartner: null,
      selectedExisingKitchen: null,
    }
  },
  props: ['activeStep', 'countries', 'kitchens', 'locations', 'newKitchen', 'newExternalKitchen'],
  computed: {
    cityButtonLabel() {
      if (this.newCity) {
        return 'List'
      }
      return 'New'
    }
  },
  methods: {
    createKitchen() {
      var launchdateFormatted = new Date(this.launchDate.getTime() - this.launchDate.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0];
      this.$emit('createKitchen', {
        'city': this.city,
        'country_location_uuid': this.getCountryLocationUUID(),
        'country_uuid': this.country.uuid,
        'id': this.label.trim(),
        'is_franchise': this.owner,
        'launch_date': launchdateFormatted,
      });
    },
    getCountryLocationUUID() {
      var countryLocation = this.locations.find((location) => location.id == this.country.id);
      return countryLocation.uuid;
    },
    selectKitchen(kitchen) {
      this.$emit('selectKitchen', kitchen);
    },
    getAvailableCities() {
      if (!this.country) {
        return [];
      }
      else {
        var country = this.countries.find(country => country.uuid == this.country.uuid);
        var cities = [...country.cities];
        cities.sort()
        return cities;
      }
    },
    addNewCity() {
      this.newCity = !this.newCity;
    },
  }
}
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
