var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.openModal,
            "has-modal-card": "",
            "on-cancel": _vm.close,
          },
          on: {
            "update:active": function ($event) {
              _vm.openModal = $event
            },
          },
        },
        [
          _c("section", [
            _c("div", { staticClass: "card", staticStyle: { width: "auto" } }, [
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "media" }, [
                  _c("div", { staticClass: "media-left" }, [
                    _c("figure", { staticClass: "image is-48x48" }, [
                      _c("img", {
                        attrs: {
                          onerror: "this.style.display='none'",
                          src: _vm.platform.logo,
                          alt: "Image",
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "media-content" }, [
                    _vm.platform.label
                      ? _c(
                          "p",
                          { staticClass: "title is-4", attrs: { p: "" } },
                          [_vm._v(_vm._s(_vm.platform.label))]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c(
                "section",
                { staticClass: "card-content" },
                [
                  _c(
                    "b-field",
                    { staticClass: "file" },
                    [
                      _c(
                        "b-upload",
                        {
                          model: {
                            value: _vm.picture.picture,
                            callback: function ($$v) {
                              _vm.$set(_vm.picture, "picture", $$v)
                            },
                            expression: "picture.picture",
                          },
                        },
                        [
                          _c(
                            "a",
                            { staticClass: "button is-info" },
                            [
                              _c("b-icon", { attrs: { icon: "upload" } }),
                              _c("span", [_vm._v("Upload")]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.logo_file
                        ? _c("span", { staticClass: "file-name" }, [
                            _vm._v(
                              " " + _vm._s(_vm.picture.picture.name) + " "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "footer",
                { staticClass: "card-foot buttons is-right" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "button is-danger is-small is-outlined" },
                      on: { click: _vm.close },
                    },
                    [_vm._v(_vm._s(_vm.$t("platforms.close")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "button is-small is-outlined",
                        disabled: _vm.createOrUpdateDisabled,
                      },
                      on: { click: _vm.update },
                    },
                    [_vm._v(_vm._s(_vm.$t("platforms.update")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }