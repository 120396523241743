import accessAPI from '../../../apis/onboarding/accesses';
import { accessTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  googleGroups: [],
  groups: [],
  users: [],
  currentUser: null,
  current_error: '',
  createUser: {
    pending: false,
    error: null,
  },
  editRights: {
    pending: false,
    error: null,
  },
  fetchGoogleGroups: {
    pending: false,
    error: null,
  },
  fetchGroups: {
    pending: false,
    error: null,
  },
  fetchUsers: {
    pending: false,
    error: null,
  },
  fetchUser: {
    pending: false,
    error: null,
  },
  removeUser: {
    pending: false,
    error: null,
  },
};

const actions = {
  async editRights({ commit }, payload) {
    commit(accessTypes.EDIT_RIGHTS_REQUEST);
    return accessAPI
      .editRights(payload)
      .then(function() {
        commit(accessTypes.EDIT_RIGHTS_SUCCESS);
      })
      .catch(function(error) {
        commit(accessTypes.EDIT_RIGHTS_FAILURE, error.response.data.detail);
      });
  },
  async createUser({ commit }, payload) {
    commit(accessTypes.CREATE_USER_REQUEST);
    return accessAPI
      .createUser(payload)
      .then(function(user) {
        commit(accessTypes.CREATE_USER_SUCCESS, user);
      })
      .catch(function(error) {
        commit(accessTypes.CREATE_USER_FAILURE, error.response.data.detail);
      });
  },
  async fetchGoogleGroups({ commit }) {
    commit(accessTypes.FETCH_GOOGLE_GROUPS_REQUEST);

    return accessAPI.fetchGoogleGroups()
      .then((groups) => commit(accessTypes.FETCH_GOOGLE_GROUPS_SUCCESS, groups))
      .catch((error) => commit(accessTypes.FETCH_GOOGLE_GROUPS_FAILURE, error));
  },
  async fetchGroups({ commit }) {
    commit(accessTypes.FETCH_GROUPS_REQUEST);

    return accessAPI.fetchGroups()
      .then((groups) => commit(accessTypes.FETCH_GROUPS_SUCCESS, groups))
      .catch((error) => commit(accessTypes.FETCH_GROUPS_FAILURE, error));
  },
  async fetchUser({ commit }, userUUID) {
    commit(accessTypes.FETCH_USER_REQUEST);

    return accessAPI.fetchUser(userUUID)
      .then((user) => commit(accessTypes.FETCH_USER_SUCCESS, user))
      .catch((error) => commit(accessTypes.FETCH_USER_FAILURE, error));
  },
  async fetchUsers({ commit }) {
    commit(accessTypes.FETCH_USERS_REQUEST);

    return accessAPI.fetchUsers()
      .then((users) => commit(accessTypes.FETCH_USERS_SUCCESS, users))
      .catch((error) => commit(accessTypes.FETCH_USERS_FAILURE, error));
  },
  async removeUser({ commit }, userUUID) {
    commit(accessTypes.REMOVE_USER_REQUEST);
    return accessAPI
      .removeUser(userUUID)
      .then(function() {
        commit(accessTypes.REMOVE_USER_SUCCESS, userUUID);
      })
      .catch(function(error) {
        commit(accessTypes.REMOVE_USER_FAILURE, error.response.data.detail);
      });
  },
};

const mutations = {
  [accessTypes.EDIT_RIGHTS_REQUEST](state) {
    state.editRights.pending = true;
  },
  [accessTypes.EDIT_RIGHTS_SUCCESS](state) {
    state.editRights.pending = false;
    state.editRights.error = null;
  },
  [accessTypes.EDIT_RIGHTS_FAILURE](state, error) {
    state.editRights.pending = false;
    state.editRights.error = error;
  },
  [accessTypes.CREATE_USER_REQUEST](state) {
    state.createUser.pending = true;
  },
  [accessTypes.CREATE_USER_SUCCESS](state, user) {
    state.currentUser = user;
    state.users.push(user);
    state.createUser.pending = false;
    state.createUser.error = null;
  },
  [accessTypes.CREATE_USER_FAILURE](state, error) {
    state.currentUser = null;
    state.createUser.pending = false;
    state.createUser.error = error;
  },
  [accessTypes.FETCH_GOOGLE_GROUPS_REQUEST](state) {
    state.fetchGoogleGroups.pending = true;
  },
  [accessTypes.FETCH_GOOGLE_GROUPS_SUCCESS](state, groups) {
    state.googleGroups = groups;
    state.fetchGoogleGroups.pending = false;
  },
  [accessTypes.FETCH_GOOGLE_GROUPS_FAILURE](state, error) {
    state.groups = null;
    state.fetchGoogleGroups.pending = false;
    state.fetchGoogleGroups.error = error;
  },
  [accessTypes.FETCH_GROUPS_REQUEST](state) {
    state.fetchGroups.pending = true;
  },
  [accessTypes.FETCH_GROUPS_SUCCESS](state, groups) {
    state.groups = groups;
    state.fetchGroups.pending = false;
  },
  [accessTypes.FETCH_GROUPS_FAILURE](state, error) {
    state.groups = null;
    state.fetchGroups.pending = false;
    state.fetchGroups.error = error;
  },
  [accessTypes.FETCH_USER_REQUEST](state) {
    state.fetchUser.pending = true;
  },
  [accessTypes.FETCH_USER_SUCCESS](state, user) {
    state.currentUser = user;
    state.fetchUser.pending = false;
  },
  [accessTypes.FETCH_USER_FAILURE](state, error) {
    state.currentUser = null;
    state.fetchUser.pending = false;
    state.fetchUser.error = error;
  },
  [accessTypes.FETCH_USERS_REQUEST](state) {
    state.fetchUsers.pending = true;
  },
  [accessTypes.FETCH_USERS_SUCCESS](state, users) {
    state.users = users;
    state.fetchUsers.pending = false;
  },
  [accessTypes.FETCH_USERS_FAILURE](state, error) {
    state.users = null;
    state.fetchUsers.pending = false;
    state.fetchUsers.error = error;
  },
  [accessTypes.REMOVE_USER_REQUEST](state) {
    state.removeUser.pending = true;
  },
  [accessTypes.REMOVE_USER_SUCCESS](state, userUUID) {
    const index = state.users.findIndex((user) => user.uuid === userUUID);
    if (index !== -1) {
      state.users.splice(index, 1);
    }
    state.removeUser.pending = false;
    state.removeUser.error = null;
  },
  [accessTypes.REMOVE_USER_FAILURE](state, error) {
    state.removeUser.pending = false;
    state.removeUser.error = error;
  },
  [accessTypes.SET_CURRENT_USER](state, member) {
    state.currentUser = member;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
