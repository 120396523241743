var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.newUser
    ? _c(
        "div",
        { staticClass: "has-text-centered" },
        [
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                { attrs: { label: "Organisation", expanded: "" } },
                [
                  _c(
                    "b-select",
                    {
                      attrs: { placeholder: "Taster/Franchise", expanded: "" },
                      model: {
                        value: _vm.isFranchise,
                        callback: function ($$v) {
                          _vm.isFranchise = $$v
                        },
                        expression: "isFranchise",
                      },
                    },
                    _vm._l(
                      _vm.organisationTypes,
                      function (organisationType, index) {
                        return _c(
                          "option",
                          {
                            key: index,
                            domProps: { value: organisationType.value },
                          },
                          [_vm._v(" " + _vm._s(organisationType.label) + " ")]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                { attrs: { label: "First Name", expanded: "" } },
                [
                  _c("b-input", {
                    attrs: { placeholder: "First Name" },
                    model: {
                      value: _vm.firstName,
                      callback: function ($$v) {
                        _vm.firstName = $$v
                      },
                      expression: "firstName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "Last Name", expanded: "" } },
                [
                  _c("b-input", {
                    attrs: { placeholder: "Last Name" },
                    model: {
                      value: _vm.lastName,
                      callback: function ($$v) {
                        _vm.lastName = $$v
                      },
                      expression: "lastName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                { attrs: { label: "Team", expanded: "" } },
                [
                  _c(
                    "b-select",
                    {
                      attrs: { placeholder: "Team", expanded: "" },
                      model: {
                        value: _vm.groupUUID,
                        callback: function ($$v) {
                          _vm.groupUUID = $$v
                        },
                        expression: "groupUUID",
                      },
                    },
                    _vm._l(_vm.groupsSorted, function (group, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: group.uuid } },
                        [_vm._v(" " + _vm._s(group.name) + " ")]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm.isFranchise
                ? _c(
                    "b-field",
                    { attrs: { label: "Mail", expanded: "" } },
                    [
                      _c("b-input", {
                        attrs: { type: "email" },
                        model: {
                          value: _vm.mail,
                          callback: function ($$v) {
                            _vm.mail = $$v
                          },
                          expression: "mail",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "b-field",
                    {
                      staticClass: "input-text-center",
                      attrs: { label: "Google Groups", expanded: "" },
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            scrollable: true,
                            multiple: "",
                            "aria-role": "list",
                            expanded: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "trigger",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "is-grey",
                                          "icon-right": "menu-down",
                                          expanded: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " Selected (" +
                                            _vm._s(_vm.googleGroupIds.length) +
                                            ") "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2764292915
                          ),
                          model: {
                            value: _vm.googleGroupIds,
                            callback: function ($$v) {
                              _vm.googleGroupIds = $$v
                            },
                            expression: "googleGroupIds",
                          },
                        },
                        _vm._l(_vm.googleGroupsSorted, function (group, index) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: index,
                              attrs: {
                                value: group.id,
                                "aria-role": "listitem",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(" " + _vm._s(group.name) + " "),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "b-button",
            {
              attrs: {
                expanded: "",
                disabled:
                  _vm.firstName == null ||
                  _vm.lastName == null ||
                  _vm.groupUUID == null ||
                  (_vm.isFranchise && _vm.mail == null),
                type: "button is-success",
              },
              on: { click: _vm.createUser },
            },
            [_vm._v("Create")]
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "has-text-centered" },
        [
          _c("h4", { staticClass: "title has-text-centered is-4" }, [
            _vm._v("Select User"),
          ]),
          _c(
            "b-field",
            [
              _c(
                "b-switch",
                {
                  attrs: {
                    "true-value": "Search By Name",
                    "false-value": "Search By Mail",
                  },
                  model: {
                    value: _vm.searchBy,
                    callback: function ($$v) {
                      _vm.searchBy = $$v
                    },
                    expression: "searchBy",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.searchBy) + " ")]
              ),
            ],
            1
          ),
          _c(
            "b-field",
            [
              _vm.searchBy == "Search By Name"
                ? _c("b-autocomplete", {
                    attrs: {
                      expanded: "",
                      rounded: "",
                      data: _vm.filteredUsers,
                      "clear-on-select": true,
                      placeholder: "Select User",
                      icon: "magnify",
                      field: "completedName",
                      "append-to-body": true,
                      "open-on-focus": true,
                    },
                    on: {
                      select: function (option) {
                        return _vm.selectUser(option)
                      },
                    },
                    model: {
                      value: _vm.searchTerm,
                      callback: function ($$v) {
                        _vm.searchTerm = $$v
                      },
                      expression: "searchTerm",
                    },
                  })
                : _c("b-autocomplete", {
                    attrs: {
                      expanded: "",
                      rounded: "",
                      data: _vm.filteredUsersByMail,
                      "clear-on-select": true,
                      placeholder: "Select User",
                      icon: "magnify",
                      field: "completedName",
                      "append-to-body": true,
                      "open-on-focus": true,
                    },
                    on: {
                      select: function (option) {
                        return _vm.selectUser(option)
                      },
                    },
                    model: {
                      value: _vm.searchTerm,
                      callback: function ($$v) {
                        _vm.searchTerm = $$v
                      },
                      expression: "searchTerm",
                    },
                  }),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: "", position: "is-centered" } },
            [
              _vm.user
                ? _c(
                    "b-tag",
                    { attrs: { size: "is-large", type: "is-info" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.user.first_name) +
                          " " +
                          _vm._s(_vm.user.last_name) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }