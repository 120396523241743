<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <section class="modal-card-body has-text-centered">
      <b-field label="Menu (UAT) UUID" expanded>
        <b-input type="text" size="is-small" rounded placeholder="menuUUID" v-model="menuUUID" expanded></b-input>
      </b-field>
      <b-field label="Spreadsheet URL" expanded>
        <b-input type="text" size="is-small" rounded placeholder="menuUUID" v-model="spreadsheetURL" expanded></b-input>
      </b-field>
    </section>
      <b-button expanded :disabled="menuUUID == null || spreadsheetURL == null" type="button is-success" @click="updateValue">Set Up</b-button>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      menuUUID: null,
      spreadsheetURL: null,
    }
  },
  props: ['launch'],
  computed: {},
  methods: {
    updateValue() {
      this.$emit('update', {menu_uuid: this.menuUUID, url_spreadsheet: this.spreadsheetURL, launch_uuid: this.launch.uuid});
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
