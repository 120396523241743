var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "has-text-centered" },
      [
        _c(
          "b-button",
          {
            staticClass: "button-gap",
            attrs: { type: "is-success", expanded: "" },
            on: {
              click: function ($event) {
                return _vm.selectOperationType("ADD")
              },
            },
          },
          [_vm._v(" Add Rights ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "has-text-centered" },
      [
        _c(
          "b-button",
          {
            staticClass: "button-gap",
            attrs: { type: "is-danger", expanded: "" },
            on: {
              click: function ($event) {
                return _vm.selectOperationType("REMOVE")
              },
            },
          },
          [_vm._v(" Remove Rights ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }