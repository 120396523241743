var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "section" }, [
    _c("div", { staticClass: "container" }, [
      _c("h1", { staticClass: "title has-text-dark" }, [
        _c("div", { staticClass: "columns is-mobile" }, [
          _c("div", { staticClass: "column is-narrow" }, [
            _vm._v(" " + _vm._s(_vm.$t("kitchens.title")) + " "),
          ]),
          _c("div", { staticClass: "column" }, [
            _c(
              "button",
              {
                staticClass: "add-button button is-link is-small",
                on: {
                  click: function ($event) {
                    return _vm.openManagementModal()
                  },
                },
              },
              [_vm._v(" Kitchen Management ")]
            ),
          ]),
          _c(
            "div",
            [
              _c(
                "b-switch",
                {
                  attrs: {
                    size: "is-medium",
                    "passive-type": "is-dark",
                    type: "is-success",
                  },
                  model: {
                    value: _vm.showCurrentKitchens,
                    callback: function ($$v) {
                      _vm.showCurrentKitchens = $$v
                    },
                    expression: "showCurrentKitchens",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.showCurrentKitchens
                          ? "Current Kitchens"
                          : "Deleted Kitchens"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("hr"),
      _c(
        "section",
        [
          _c("KitchenFilter", {
            attrs: {
              activeConcepts: _vm.activeConceptsSorted,
              activePlatforms: _vm.activePlatformsSorted,
              kitchenStatuses: _vm.kitchenStatuses,
              selectedConcepts: _vm.selectedConcepts,
              selectedPlatforms: _vm.selectedPlatforms,
              selectedKitchenStatuses: _vm.selectedKitchenStatuses,
            },
          }),
        ],
        1
      ),
      _c(
        "section",
        [
          _c("b-loading", {
            attrs: { active: _vm.isLoading },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          }),
          _vm.kitchens.length > 0 &&
          _vm.selectedConcepts &&
          _vm.selectedConcepts.length > 0 &&
          _vm.locations &&
          _vm.locations.length > 0 &&
          _vm.selectedPlatforms &&
          _vm.selectedPlatforms.length > 0
            ? _c(
                "section",
                [
                  _c("KitchenTable", {
                    attrs: {
                      concepts: _vm.selectedConcepts,
                      countries: _vm.countries,
                      locations: _vm.locations,
                      platforms: _vm.selectedPlatforms,
                      selectedKitchenStatuses: _vm.selectedKitchenStatuses,
                    },
                    on: {
                      "edit-kitchen": _vm.openKitchenModal,
                      "edit-restaurant": _vm.openRestaurantModal,
                      "edit-restaurant-opening-hours": _vm.openRestaurantHours,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }