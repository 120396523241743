<template>
  <div class="has-text-centered" v-if="campaign">
    <b-loading :active.sync="isLoading"></b-loading>
    <b-field grouped>
      <b-field label="Campaign Type" style="justify-content: center;" expanded>
        <b-select
          type="text"
          v-model="campaign.campaign_type"
          placeholder="Campaign Type"
          expanded
          >
          <option v-for="(campaignType, index) in campaignTypes" :key="index" :value="campaignType">
            {{campaignType}}
          </option>
        </b-select>
      </b-field>
      <b-field label="Campaign Financing Model" style="justify-content: center;" v-if="!isEditingMode" expanded>
        <b-select
          type="text"
          v-model="campaign.financing_model"
          placeholder="Campaign Financing Model"
          expanded
          >
          <option v-for="(campaignFinancingModel, index) in campaignFinancingModels" :key="index" :value="campaignFinancingModel">
            {{campaignFinancingModel}}
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field grouped v-if="isDiscountCampaign">
      <b-field label="Discount Type" style="justify-content: center;" expanded :message="discountTypeMessage">
        <b-select
          type="text"
          v-model="campaign.discount_type"
          placeholder="Discount Type"
          expanded
          >
          <option v-for="(discountType, index) in campaignDiscountTypes" :key="index" :value="discountType">
            {{discountType}}
          </option>
        </b-select>
      </b-field>
      <b-field :label="discountValueFieldLabel" expanded>
        <b-input
          step="0.01"
          v-model="campaign.discount_value"
          type="number"
          :placeholder="discountValuePlaceHolder">
        </b-input>
      </b-field>
      <b-field label="Discount Over (Local Currency)" expanded v-if="isOverCampaign">
        <b-input
          step="0.01"
          v-model="campaign.discount_over"
          type="number"
          placeholder="30">
        </b-input>
      </b-field>
    </b-field>
    <b-field grouped v-if="isItemCampaign">
      <b-field label="Items" expanded>
        <b-autocomplete
          size="is-medium"
          expanded
          v-model="searchTerm"
          :data="filteredItems"
          :clear-on-select="true"
          placeholder="Select Item"
          icon="magnify"
          field="label"
          @select="option => selectItem(option)"
          :open-on-focus="true"
        >
        </b-autocomplete>
      </b-field>
    </b-field>
    <hr v-if="isItemCampaign && this.selectedCampaignItems.length > 0" style="height:1px;border:none;color:#333;background-color:#333;">
    <b-field grouped v-if="isItemCampaign && this.selectedCampaignItems.length > 0" style="align-content: center text-align: center; vertical-align: middle;">
        <b-table
          :data="selectedCampaignItems"
          :striped="true"
          :hoverable="true"
          :default-sort="['label', 'desc']"
          >
          <template slot-scope="props">
            <b-table-column field="itemLabel" label="Label" :width="columnWidth">
                {{ props.row.label }}
            </b-table-column>
            <b-table-column field="sellingPrice" label="Selling Price" :width="columnWidth">
                {{ props.row.selling_price }}
            </b-table-column>
            <b-table-column field="foodCost" label="Food Cost" :width="columnWidth">
                {{ props.row.foodCost }}
            </b-table-column>
            <b-table-column field="reimbursedFoodCost" label="Reimbursed Food Cost" :width="columnWidth">
              <b-input
                step="0.001"
                v-model="props.row.reimbursedFoodCost"
                type="number"
                placeholder="20">
              </b-input>
            </b-table-column>
            <b-table-column field="action" label="Actions" :width="columnWidth">
              <b-button type="button is-danger is-small is-outlined" @click="removeCampaignItem(props.row)"> Delete </b-button>
            </b-table-column>
          </template>
        </b-table>
    </b-field>
    <hr v-if="isItemCampaign && this.selectedItems.length > 0" style="height:1px;border:none;color:#333;background-color:#333;">
    <b-field grouped>
      <b-field label="Estimated Uplift (%)" expanded>
        <b-input
          step="0.01"
          v-model="campaign.estimated_uplift"
          type="number"
          placeholder="20">
        </b-input>
      </b-field>
      <b-field label="Budget (Local Currency)" expanded>
        <b-input
          step="0.01"
          v-model="campaign.budget"
          type="number"
          placeholder="10000">
        </b-input>
      </b-field>
    </b-field>
    <b-button v-if="!isEditingMode" expanded :disabled="isSetUpDisable()" :type="getButtonType()" @click="selectKitchens">{{getButtonLabel()}}</b-button>
  </div>
</template>

<script>
import {  mapActions, mapState } from 'vuex';
  export default {
  data() {
    return {
      searchTerm: '',
      columnWidth: 250,
      selectedItems: [],
    }
  },
  props: ['campaign', 'countryLocation', 'isEditingMode', 'isAdhocCampaign', 'campaignItems', 'menu'],
  computed: {
    ...mapState({
      isLoading: (state) => state.costs.fetchOne.pending,
      currentCost: (state) => state.costs.current,
      menuItems: (state) => state.menuItems.all,
      currentMenuItemsConceptUUID: (state) => state.menuItems.currentConceptUUID,
    }),
    campaignTypes() {
      return this.$store.getters['campaigns/getCampaignTypes'];
    },
    campaignFinancingModels() {
      var campaignFinancingModels = this.$store.getters['campaigns/getCampaignFinancingModels'];
      if (!(this.isDiscountCampaign && this.campaign.discount_type && this.campaign.discount_type.toLowerCase().includes('second'))) {
        return campaignFinancingModels.filter(financingModel => financingModel != 'TASTER_FOOD_COST_SECOND_ITEM');
      }
      return campaignFinancingModels;
    },
    campaignDiscountTypes() {
      return this.$store.getters['campaigns/getCampaignDiscountTypes'];
    },
    isDiscountCampaign() {
      return this.campaign && this.campaign.campaign_type && this.campaign.campaign_type.toLowerCase().includes('discount');
    },
    isDiscountItemCampaign() {
      return this.campaign && this.campaign.campaign_type && this.campaign.campaign_type.toLowerCase().includes('discount_items');
    },
    isItemCampaign() {
      return this.campaign && this.campaign.campaign_type && (this.campaign.campaign_type.toLowerCase().includes('discount_items') || this.campaign.campaign_type.toLowerCase().includes('bogo') || this.campaign.campaign_type.toLowerCase().includes('free_items') );
    },
    isOverCampaign() {
      return this.isDiscountCampaign && this.campaign.discount_type && this.campaign.discount_type.toLowerCase().includes('over');
    },
    discountTypeMessage() {
      if (this.campaign.discount_type == 'FLAT_OVER') {
        return 'Get {discount_value} for {discount_over} spent (in local currency) (eg Get 5€ for 20€ spent)'
      }
      else if (this.campaign.discount_type == 'PERCENTAGE') {
        return '{discount_value} % off'
      }
      else if (this.campaign.discount_type == 'PERCENTAGE_ON_SECOND_ITEM') {
        return '{discount_value} % off every two items'
      }
      else if (this.campaign.discount_type == 'PERCENTAGE_OVER') {
        return 'Get {discount_value} % off for {discount_over} spent (in local currency) (eg: Get 5% for 30€ spent)'
      }
      return ''
    },
    discountValueFieldLabel() {
      if (this.campaign.discount_type == 'PERCENTAGE' || this.campaign.discount_type == 'PERCENTAGE_ON_SECOND_ITEM' || this.campaign.discount_type == 'PERCENTAGE_OVER') {
        return 'Discount Value (%)';
      }
      if (this.campaign.discount_type == 'FLAT_OVER') {
        return 'Discount Value (Local Currency)';
      }
      return 'Discount Value'
    },
    discountValuePlaceHolder() {
      if (this.campaign.discount_type == 'PERCENTAGE' || this.campaign.discount_type == 'PERCENTAGE_ON_SECOND_ITEM' || this.campaign.discount_type == 'PERCENTAGE_OVER') {
        return '40%';
      }
      if (this.campaign.discount_type == 'FLAT_OVER') {
        return '100';
      }
      return ''
    },
    selectedCampaignItems() {
      if (this.campaignItems) {
        return this.campaignItems.concat(this.selectedItems);
      }
      return this.selectedItems;
    },
    items() {
      // if (this.menu) {
      //   return this.menu.all;
      // }
      if (this.menuItems && this.menuItems.length > 0) {
        return this.menuItems;
      }
      return [];
    },
    filteredItems() {
      if (this.items && this.items.length > 0) {
        return this.items.filter((item) => item.label.toLowerCase().includes(this.searchTerm.toLowerCase()) && !this.selectedItems.map(selectedItem => selectedItem.uuid).includes(item.uuid)).sort((item1, item2) => item1.label.localeCompare(item2.label));
      }
      return [];
    },
  },
  methods: {
    ...mapActions('menuItems', { fetchMenuItems: 'fetchAll' }),
    ...mapActions('menuInformation', { fetchMenuInformation: 'fetchAll' }),
    ...mapActions('costs', { fetchCost: 'fetchOne' }),
    fetchItems() {
      if (this.items.length == 0 || this.currentMenuItemsConceptUUID != this.campaign.concept_uuid) {
        // this.fetchMenuInformation({'concept_uuid': this.campaign.concept_uuid, 'country_location_uuid': this.campaign.country_location_uuid, 'country_uuid': this.countryLocation.country_uuid});
        this.fetchMenuItems({'concept_uuid': this.campaign.concept_uuid});
      }
    },
    selectItem(item) {
      if (item) {
        if (this.selectedItems.map(selectedItem => selectedItem.uuid).includes(item.uuid)) {
          this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem.uuid != item.uuid);
        }
        else {
          this.fetchCost({
            'type': 'MENU_ITEM',
            'uuid': item.uuid,
            'location_uuid': this.campaign.country_location_uuid,
            'quantity': 1,
            'unit': 'each'
          }).then((cost) => {
            this.selectedItems.push({...item, 'foodCost': cost.net_food_cost, 'reimbursedFoodCost': 0})
          });
        }
      if (this.isItemCampaign) {
        this.campaign.discounted_items = this.selectedItems;
      }
      }
    },
    removeCampaignItem(campaignItem) {
      if (campaignItem.uuid) {
        this.$emit('removeCampaignItem', campaignItem.uuid)
      }
      else {
        this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem.uuid != campaignItem.item_uuid);
      }
    },
    isSetUpDisable() {
      if (this.campaign.campaign_type == null || this.campaign.financing_model == null) {
        return true;
      }
      if (this.isDiscountCampaign && this.campaign.discount_value == null) {
        return true;
      }
      if (this.isOverCampaign && this.campaign.discount_over == null) {
        return true;
      }
      if (this.isItemCampaign && this.selectedItems.length == 0 && this.selectedItems.some(selectedItem => selectedItem.reimbursedFoodCost===null)) {
        return true;
      }
      return false;
    },
    selectKitchens() {
      if (this.isAdhocCampaign) {
        this.$emit('selectOneKitchen');
        }
      else {
        this.$emit('selectKitchens');
      }
    },
    getButtonLabel() {
      if (this.isAdhocCampaign) {
        return 'Create'
      }
      return 'Select Kitchens'
    },
    getButtonType() {
      if (this.isAdhocCampaign) {
        return 'is-success'
      }
      return 'is-info'
    },
  },
  watch: {
    campaign: {
      handler(val) {
        if (val && val.concept_uuid && val.country_location_uuid && this.countryLocation && this.countryLocation.country_uuid && this.isItemCampaign) {
          this.fetchItems()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.isItemCampaign) {
      this.fetchItems();
    }
  }
}
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
