<template>
  <div>
    <b-table
      :data="tasks"
      :striped="true"
      :hoverable="true"
      :default-sort="['desc']"
      >
      <template slot-scope="props">
        <b-table-column field="task" label="Task">
          {{props.row.id}}
        </b-table-column>
        <b-table-column field="available_tasks" label="Available Tasks">
          {{props.row.availableTasks}}
        </b-table-column>
        <b-table-column field="progress" label="Progress">
          <b-progress type="is-success" :value="props.row.progression" show-value> {{props.row.progression.toFixed(2)}} % </b-progress>
        </b-table-column>
        </template>
    </b-table>
  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
  props: ['allTasks', 'tasksByTaskGroup', 'tasksByTaskOwner', 'taskType'],
  data() {
    return {}
  },
  computed: {
    ...mapState({
      launch : (state) => state.launches.current,
    }),
    tasks() {
      if (this.taskType == 'All Tasks') {
        return this.allTasks
      }
      else if (this.taskType == 'By Task Groups') {
        return this.tasksByTaskGroup
      }
      else if (this.taskType == 'By Teams') {
        return this.tasksByTaskOwner
      }
      return []
    },
  },
  methods: {}
}
</script>

