var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("b-table", {
        ref: "table",
        attrs: {
          data: _vm.kitchensRestaurantConcept,
          detailed: "",
          hoverable: "",
          "default-sort": ["id", "asc"],
          "detail-key": "id",
          "show-detail-icon": _vm.showDetailIcon,
        },
        on: {
          "details-open": function (row, index) {
            return _vm.$buefy.toast.open("Expanded " + row.id)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "id",
                      label: "ID",
                      sortable: "",
                      searchable: "",
                    },
                  },
                  [
                    _vm.showDetailIcon
                      ? [_vm._v(" " + _vm._s(props.row.id) + " ")]
                      : [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.toggle(props.row)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(props.row.id) + " ")]
                          ),
                        ],
                  ],
                  2
                ),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "city",
                      label: "City",
                      sortable: "",
                      centered: "",
                      searchable: "",
                    },
                  },
                  [_vm._v(" " + _vm._s(props.row.city) + " ")]
                ),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "country",
                      label: "Country",
                      sortable: "",
                      centered: "",
                      searchable: "",
                    },
                  },
                  [_vm._v(" " + _vm._s(props.row.country) + " ")]
                ),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "franchise",
                      label: "Owner",
                      sortable: "",
                      "custom-sort": _vm.sortFranchise,
                      centered: "",
                    },
                  },
                  [
                    props.row.is_franchise
                      ? _c("b-tag", { attrs: { type: "is-link" } }, [
                          _vm._v(" Franchise "),
                        ])
                      : _c("b-tag", { attrs: { type: "is-primary" } }, [
                          _vm._v(" Taster "),
                        ]),
                  ],
                  1
                ),
                _c(
                  "b-table-column",
                  { attrs: { field: "status", label: "Status" } },
                  [
                    _c(
                      "b-taglist",
                      [
                        props.row.status.status == "empty"
                          ? _c("b-tag", { attrs: { type: "is-warning" } }, [
                              _vm._v(" Empty "),
                            ])
                          : _vm._e(),
                        props.row.status.kitchenOpeningHoursCompleted == false
                          ? _c("b-tag", { attrs: { type: "is-danger" } }, [
                              _vm._v(" Missing Opening Hours "),
                            ])
                          : _vm._e(),
                        props.row.status.kitchenPlatformIDCompleted == false
                          ? _c("b-tag", { attrs: { type: "is-danger" } }, [
                              _vm._v(" Missing Restaurant Platform ID "),
                            ])
                          : _vm._e(),
                        props.row.status.kitchenPOSCompleted == false
                          ? _c("b-tag", { attrs: { type: "is-danger" } }, [
                              _vm._v(" POS "),
                            ])
                          : _vm._e(),
                        props.row.status.kitchenOneTabletCompleted == false
                          ? _c("b-tag", { attrs: { type: "is-danger" } }, [
                              _vm._v(" OneTablet "),
                            ])
                          : _vm._e(),
                        props.row.status.kitchenLoyaltyCompleted == false
                          ? _c("b-tag", { attrs: { type: "is-danger" } }, [
                              _vm._v(" Loyalty "),
                            ])
                          : _vm._e(),
                        props.row.status.kitchenLoyaltyCompleted == true
                          ? _c("b-tag", { attrs: { type: "is-success" } }, [
                              _vm._v(" Loyalty "),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("b-table-column", [
                  _c(
                    "button",
                    {
                      staticClass: "button is-info is-small",
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            "edit-kitchen",
                            props.row.uuid,
                            {
                              city: props.row.city,
                              country: props.row.country,
                              country_uuid: props.row.country_uuid,
                            },
                            false
                          )
                        },
                      },
                    },
                    [_vm._v(" Show & Edit ")]
                  ),
                ]),
              ]
            },
          },
          {
            key: "detail",
            fn: function (kitchen_props) {
              return [
                _c("b-table", {
                  staticClass: "inner-table",
                  attrs: { data: kitchen_props.row.restaurants_by_concept },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (kitchen_props) {
                          return [
                            _c(
                              "b-table-column",
                              {
                                staticClass: "has-text-centered",
                                staticStyle: { "vertical-align": "middle" },
                                attrs: { field: "logo", label: "Restaurants" },
                              },
                              [
                                kitchen_props.row.concept_is_internal
                                  ? _c("div", [
                                      _c("img", {
                                        staticClass: "concept-logo",
                                        attrs: {
                                          src: kitchen_props.row.logo,
                                          alt: kitchen_props.row.label,
                                          onerror: "this.style.display='none'",
                                        },
                                      }),
                                    ])
                                  : _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(kitchen_props.row.label) +
                                          " "
                                      ),
                                    ]),
                              ]
                            ),
                            _c(
                              "b-table-column",
                              {
                                staticClass: "has-text-centered",
                                attrs: { field: "id", label: "" },
                              },
                              [
                                _c("b-table", {
                                  staticClass: "inner-table",
                                  attrs: { data: kitchen_props.row.platforms },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (restaurant_props) {
                                          return [
                                            _c(
                                              "b-table-column",
                                              {
                                                attrs: {
                                                  field: "id",
                                                  label: "Platform",
                                                },
                                              },
                                              [
                                                _c(
                                                  restaurant_props.row
                                                    .platform_url &&
                                                    restaurant_props.row.platform_url.includes(
                                                      "https"
                                                    )
                                                    ? "a"
                                                    : "span",
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      href: restaurant_props.row
                                                        .platform_url,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "concept-logo",
                                                      attrs: {
                                                        src: restaurant_props
                                                          .row.platform_logo,
                                                        alt: "platform_logo",
                                                        onerror:
                                                          "this.style.display='none'",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-table-column",
                                              {
                                                attrs: {
                                                  field: "status",
                                                  label: "Status",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-taglist",
                                                  [
                                                    _vm.restaurantIsCompleted(
                                                      restaurant_props.row
                                                    )
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-success",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Platform Info "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantIsMissingOpeningHours(
                                                      restaurant_props.row
                                                    )
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-danger",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Missing Opening Hours "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantIsMissingPlatformID(
                                                      restaurant_props.row
                                                    )
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-danger",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Missing Restaurant Platform ID "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    restaurant_props.row
                                                      .is_pos_integrated
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-success",
                                                            },
                                                          },
                                                          [_vm._v(" POS ")]
                                                        )
                                                      : _vm._e(),
                                                    restaurant_props.row
                                                      .is_pos_integrated ===
                                                    false
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-danger",
                                                            },
                                                          },
                                                          [_vm._v(" POS ")]
                                                        )
                                                      : _vm._e(),
                                                    restaurant_props.row
                                                      .is_active
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-success",
                                                            },
                                                          },
                                                          [_vm._v(" Active ")]
                                                        )
                                                      : _vm._e(),
                                                    restaurant_props.row
                                                      .is_active === false
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-danger",
                                                            },
                                                          },
                                                          [_vm._v(" Active ")]
                                                        )
                                                      : _vm._e(),
                                                    restaurant_props.row
                                                      .is_one_tablet
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-success",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " OneTablet "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    restaurant_props.row
                                                      .is_one_tablet === false
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-danger",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " OneTablet "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    restaurant_props.row
                                                      .is_loyalty_enable
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-success",
                                                            },
                                                          },
                                                          [_vm._v(" Loyalty ")]
                                                        )
                                                      : _vm._e(),
                                                    restaurant_props.row
                                                      .is_loyalty_enable ===
                                                    false
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-danger",
                                                            },
                                                          },
                                                          [_vm._v(" Loyalty ")]
                                                        )
                                                      : _vm._e(),
                                                    restaurant_props.row
                                                      .is_internal
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-success",
                                                            },
                                                          },
                                                          [_vm._v(" Internal ")]
                                                        )
                                                      : _vm._e(),
                                                    restaurant_props.row
                                                      .is_internal === false
                                                      ? _c(
                                                          "b-tag",
                                                          {
                                                            attrs: {
                                                              type: "is-link",
                                                            },
                                                          },
                                                          [_vm._v(" External ")]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("b-table-column", [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "button is-info is-small",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$emit(
                                                        "edit-restaurant",
                                                        kitchen_props.row
                                                          .kitchen_uuid,
                                                        restaurant_props.row
                                                          .restaurant_uuid,
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Show & Edit ")]
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }