<template>
  <div>
    <b-modal :active.sync="openModal"
             has-modal-card
             :on-cancel="close">
      <section>
          <div class="card" style="width: auto">
            <div class="card-content">
              <div class="media">
                <div class="media-left" v-if="creationModal===false">
                    <figure class="image is-48x48">
                        <img onerror="this.style.display='none'" :src="platform.logo" alt="Image">
                    </figure>
                </div>
                <div class="media-content">
                  <p v-if="platform.label" p class="title is-4">{{platform.label}}</p>
                  <p v-if="platform.label=='' && creationModal" p class="title is-4">{{ $t('platforms.add') }}</p>
                </div>
              </div>
            </div>
              <section class="card-content">
                <b-field grouped>
                  <b-field label="ID" label-position="inside">
                    <b-input
                      v-model="platform.id"
                      type="text"
                      required
                      placeholder="Platform Id">
                    </b-input>
                </b-field>
                </b-field>
                <b-field label="Label" label-position="inside">
                    <b-input
                      type="text"
                      required
                      v-model="platform.label"
                      placeholder="Platform Label"></b-input>
                </b-field>
              </section>
              <footer class="card-foot buttons is-right">
                  <b-button v-if="creationModal" type="button is-small is-outlined" @click="create">{{ $t('platforms.create') }}</b-button>
                  <b-button v-if="creationModal===false" type="button is-small is-outlined" @click="update">{{ $t('platforms.update') }}</b-button>
                  <b-button type="button is-danger is-small is-outlined" @click="close">{{ $t('platforms.close') }}</b-button>
              </footer>
          </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ['creationModal', 'platform', 'platformSchema'],
  data() {
    return {
      openModal: true,
      logo_file: null,
    }
  },
  methods: {
    create() {
      this.$emit('created', {
        id: this.platform.id,
        label: this.platform.label,
      });
      this.$parent.close();
    },
    update() {
      var updatedPlatform = {
          id: this.platform.id,
          is_deleted: this.platform.is_deleted,
          label: this.platform.label,
      };
      this.$emit('updated',
        this.platform.uuid,
        updatedPlatform
      );
      this.$parent.close();
    },
    close() {
      if (this.creationModal) {
        this.$emit('creationclosed', {});
      }
      else{
        this.$emit('updateclosed', {});
      }
      this.$parent.close();
    },
  },
}
</script>

<style>
</style>
