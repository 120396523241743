<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <section class="modal-card-body has-text-centered">
    <p class="title is-4"> Set Up Tablet Information </p>
    <b-steps v-model="activeStep" size="is-small" type="is-info">
      <b-step-item label="Explanation" step="1">
        <b-table :data="data">
          <template slot-scope="props">
            <b-table-column field="id" label="ID">
                <b-tag type="is-info"> {{ props.row.id }} </b-tag>
            </b-table-column>
            <b-table-column field="step" label="Step">
                {{ props.row.step }}
            </b-table-column>
            <b-table-column field="link" label="Link">
              <b-button
                v-if="props.row.link"
                tag="a"
                type="is-link"
                :href=props.row.link.url
                target="_blank">
                {{props.row.link.label}}
              </b-button>
            </b-table-column>
          </template>
        </b-table>
      </b-step-item>
      <b-step-item label="Tablet Information" step="2">
        <b-field label="Tablet ID" expanded>
          <b-input v-model="tablet_id"></b-input>
        </b-field>
        <b-field label="Tablet Password" expanded>
          <b-input v-model="tablet_password"></b-input>
        </b-field>
        <b-field label="Tablet PIN" expanded>
          <b-input v-model="tablet_pin"></b-input>
        </b-field>
        <b-button expanded :disabled="tablet_id == null || tablet_password == null" type="button is-success" @click="updateValue">Update</b-button>
      </b-step-item>
    </b-steps>
    </section>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      activeStep: 0,
      value: null,
      tablet_id: null,
      tablet_password: null,
      tablet_pin: null,
      data: [
        {
          'id': 1,
          'step': 'Go to Onboarding SpreadSheet',
          'link': {
            'label': 'Hub',
            'url': 'https://docs.google.com/spreadsheets/d/14XOAWy4JdmbMy3Rv2SxX26D3Y6u_8XNXGNIdOwObCYo/edit?ts=5f85bebc#gid=0',
          }
        },
        {
          'id': 2,
          'step': 'Get ID, Password & PIN (AA, AB, AC columns)',
          'link': null
        },
      ]
    }
  },
  props: [],
  computed: {},
  methods: {
    updateValue() {
      this.$emit('update', {
        'secrets': {
          'tablet_id': this.tablet_id,
          'tablet_password': this.tablet_password,
          'tablet_pin': this.tablet_pin,
        }
      });
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
