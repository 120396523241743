<template>
  <div id="app">
    <b-navbar shadow>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
            <img
                src="@/assets/logo.png"
                alt="Taster Logo"
            >
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="router-link" :to="{ path: '/kitchens' }" :active="$route.path.includes('/kitchens')">
          Kitchens
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/onboarding' }" :active="$route.path.includes('/onboarding')" v-if="displayOnboardingTab()">
          Lifecycle
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/marketing' }" :active="$route.path.includes('/marketing')" v-if="displayMarketingTab()">
          Marketing
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/supply' }" :active="$route.path.includes('/supply')" v-if="displaySupplyTab()">
          Supply
        </b-navbar-item>
      </template>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
import roles from '@/router/roles';

export default {
  methods: {
    displayOnboardingTab() {
      // return this.$auth.getUserRoles().includes(roles.launchViewer);
      return this.$auth.getUserRoles().includes(roles.kitchenLifecycleManager);
    },
    displayMarketingTab() {
      // return this.$auth.getUserRoles().includes(roles.campaignViewer) && this.$auth.getUserRoles().includes(roles.kitchenCampaignViewer);
      return this.$auth.getUserRoles().includes(roles.marketingManagerViewer) || this.$auth.getUserRoles().includes(roles.marketingManagerUser);
    },
    displaySupplyTab() {
      return this.$auth.getUserRoles().includes(roles.supplyPricesImporter);
    },
  }
};
</script>

<style scoped>
.navbar-item.is-active {
  font-weight: 500;
  background-color: #fafafa !important;
}
</style>
