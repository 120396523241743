var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card",
      staticStyle: { "overflow-y": "auto", "justify-content": "center" },
    },
    [
      _c("p", { staticClass: "title is-4" }, [
        _vm._v(" Send Uber Mail POS Integration "),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body has-text-centered" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label:
                  "Your Taster Mail (e.g jai.kharbanda@taster.com, sebastien.rybka@taster.com...)",
                expanded: "",
              },
            },
            [
              _c("b-input", {
                attrs: { type: "email" },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-button",
        {
          attrs: {
            expanded: "",
            disabled: _vm.value == null || !_vm.value.includes("@taster"),
            type: "button is-success",
          },
          on: { click: _vm.update },
        },
        [_vm._v("Send Mail")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }