var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "has-text-centered" }, [
    _c(
      "div",
      { staticClass: "has-text-centered" },
      [
        _c("h4", { staticClass: "title has-text-centered is-4" }, [
          _vm._v("Select Calendar Parameters"),
        ]),
        _c(
          "b-field",
          { attrs: { grouped: "" } },
          [
            _c(
              "b-field",
              { attrs: { label: "Country (Mandatory)", expanded: "" } },
              [
                _c(
                  "b-select",
                  {
                    attrs: { placeholder: "Campaign Country", expanded: "" },
                    model: {
                      value: _vm.currentSelection.country,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentSelection, "country", $$v)
                      },
                      expression: "currentSelection.country",
                    },
                  },
                  _vm._l(_vm.countries, function (country, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: country } },
                      [_vm._v(" " + _vm._s(country.id) + " ")]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "Campaign Dates", expanded: "" } },
              [
                _c("b-datepicker", {
                  attrs: {
                    placeholder: "Click to select...",
                    icon: "calendar-today",
                    position: "is-bottom-right",
                    locale: "fr-Fr",
                    range: "",
                  },
                  model: {
                    value: _vm.currentSelection.dates,
                    callback: function ($$v) {
                      _vm.$set(_vm.currentSelection, "dates", $$v)
                    },
                    expression: "currentSelection.dates",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-field",
          { attrs: { grouped: "" } },
          [
            _c(
              "b-field",
              {
                staticStyle: { "justify-content": "center" },
                attrs: { label: "Concept", expanded: "" },
              },
              [
                _c(
                  "b-select",
                  {
                    attrs: {
                      type: "text",
                      placeholder: "Concept",
                      expanded: "",
                    },
                    model: {
                      value: _vm.currentSelection.concept,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentSelection, "concept", $$v)
                      },
                      expression: "currentSelection.concept",
                    },
                  },
                  _vm._l(_vm.concepts, function (concept, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: concept } },
                      [_vm._v(" " + _vm._s(concept.label) + " ")]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _c(
              "b-field",
              {
                staticStyle: { "justify-content": "center" },
                attrs: { label: "Platform", expanded: "" },
              },
              [
                _c(
                  "b-select",
                  {
                    attrs: {
                      type: "text",
                      placeholder: "Platform",
                      expanded: "",
                    },
                    model: {
                      value: _vm.currentSelection.platform,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentSelection, "platform", $$v)
                      },
                      expression: "currentSelection.platform",
                    },
                  },
                  _vm._l(_vm.platforms, function (platform, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: platform } },
                      [_vm._v(" " + _vm._s(platform.label) + " ")]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-field",
          { attrs: { grouped: "" } },
          [
            _c(
              "b-field",
              {
                staticStyle: { "justify-content": "center" },
                attrs: { label: "Category", expanded: "" },
              },
              [
                _c(
                  "b-select",
                  {
                    attrs: {
                      type: "text",
                      placeholder: "Category",
                      expanded: "",
                    },
                    model: {
                      value: _vm.currentSelection.category,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentSelection, "category", $$v)
                      },
                      expression: "currentSelection.category",
                    },
                  },
                  _vm._l(
                    _vm.kitchenCategories,
                    function (kitchenCategory, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: kitchenCategory } },
                        [_vm._v(" " + _vm._s(kitchenCategory) + " ")]
                      )
                    }
                  ),
                  0
                ),
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "Kitchen", expanded: "" } },
              [
                _c(
                  "b-select",
                  {
                    attrs: { placeholder: "Campaign Kitchen", expanded: "" },
                    model: {
                      value: _vm.currentSelection.location,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentSelection, "location", $$v)
                      },
                      expression: "currentSelection.location",
                    },
                  },
                  _vm._l(_vm.countryLocationsLocal, function (location, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: location } },
                      [_vm._v(" " + _vm._s(location.id) + " ")]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.currentSelection.country && _vm.currentSelection.dates.length > 0
          ? _c(
              "b-button",
              {
                staticClass: "button-gap",
                attrs: { type: "is-info", expanded: "" },
                on: {
                  click: function ($event) {
                    return _vm.select()
                  },
                },
              },
              [_vm._v(" Select ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }