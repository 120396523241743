var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c(
        "div",
        { staticClass: "has-text-centered" },
        [
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                { attrs: { label: "Name", message: "", expanded: "" } },
                [
                  _c("b-input", {
                    attrs: { placeholder: "Campaign Name" },
                    model: {
                      value: _vm.campaign.label,
                      callback: function ($$v) {
                        _vm.$set(_vm.campaign, "label", $$v)
                      },
                      expression: "campaign.label",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isAdhocCampaign
            ? _c(
                "b-field",
                { attrs: { grouped: "" } },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Country", expanded: "" } },
                    [
                      !_vm.isEditingMode
                        ? _c(
                            "b-select",
                            {
                              attrs: {
                                placeholder: "Campaign Country",
                                expanded: "",
                              },
                              model: {
                                value: _vm.campaign.country_location_uuid,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.campaign,
                                    "country_location_uuid",
                                    $$v
                                  )
                                },
                                expression: "campaign.country_location_uuid",
                              },
                            },
                            _vm._l(_vm.countries, function (country, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: {
                                    value: _vm.getCountryLocationUUID(
                                      country.uuid
                                    ),
                                  },
                                },
                                [_vm._v(" " + _vm._s(country.id) + " ")]
                              )
                            }),
                            0
                          )
                        : _c("b-input", {
                            attrs: {
                              disabled: _vm.isEditingMode,
                              expanded: "",
                            },
                            model: {
                              value: _vm.campaignCountry,
                              callback: function ($$v) {
                                _vm.campaignCountry = $$v
                              },
                              expression: "campaignCountry",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { attrs: { label: "Kitchen", expanded: "" } },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: {
                            disabled: _vm.isEditingMode,
                            placeholder: "Campaign Kitchen",
                            expanded: "",
                          },
                          model: {
                            value: _vm.campaign.locationUUID,
                            callback: function ($$v) {
                              _vm.$set(_vm.campaign, "locationUUID", $$v)
                            },
                            expression: "campaign.locationUUID",
                          },
                        },
                        _vm._l(
                          _vm.getCountryLocations(
                            _vm.locations,
                            _vm.campaign.country_location_uuid
                          ),
                          function (location, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                domProps: { value: location.uuid },
                              },
                              [_vm._v(" " + _vm._s(location.id) + " ")]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              !_vm.isAdhocCampaign
                ? _c(
                    "b-field",
                    { attrs: { label: "Country", expanded: "" } },
                    [
                      !_vm.isEditingMode
                        ? _c(
                            "b-select",
                            {
                              attrs: {
                                placeholder: "Campaign Country",
                                expanded: "",
                              },
                              model: {
                                value: _vm.campaign.country_location_uuid,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.campaign,
                                    "country_location_uuid",
                                    $$v
                                  )
                                },
                                expression: "campaign.country_location_uuid",
                              },
                            },
                            _vm._l(_vm.countries, function (country, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: {
                                    value: _vm.getCountryLocationUUID(
                                      country.uuid
                                    ),
                                  },
                                },
                                [_vm._v(" " + _vm._s(country.id) + " ")]
                              )
                            }),
                            0
                          )
                        : _c("b-input", {
                            attrs: {
                              disabled: _vm.isEditingMode,
                              expanded: "",
                            },
                            model: {
                              value: _vm.campaignCountry,
                              callback: function ($$v) {
                                _vm.campaignCountry = $$v
                              },
                              expression: "campaignCountry",
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-field",
                {
                  staticStyle: { "justify-content": "center" },
                  attrs: { label: "Concept", expanded: "" },
                },
                [
                  _c(
                    "b-select",
                    {
                      attrs: {
                        disabled: _vm.isEditingMode,
                        type: "text",
                        placeholder: "Concept",
                        expanded: "",
                      },
                      model: {
                        value: _vm.campaign.concept_uuid,
                        callback: function ($$v) {
                          _vm.$set(_vm.campaign, "concept_uuid", $$v)
                        },
                        expression: "campaign.concept_uuid",
                      },
                    },
                    _vm._l(_vm.concepts, function (concept, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: concept.uuid } },
                        [_vm._v(" " + _vm._s(concept.label) + " ")]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  staticStyle: { "justify-content": "center" },
                  attrs: { label: "Platform", expanded: "" },
                },
                [
                  _c(
                    "b-select",
                    {
                      attrs: {
                        disabled: _vm.isEditingMode,
                        type: "text",
                        placeholder: "Platform",
                        expanded: "",
                      },
                      model: {
                        value: _vm.campaign.platform_uuid,
                        callback: function ($$v) {
                          _vm.$set(_vm.campaign, "platform_uuid", $$v)
                        },
                        expression: "campaign.platform_uuid",
                      },
                    },
                    _vm._l(_vm.platforms, function (platform, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: platform.uuid } },
                        [_vm._v(" " + _vm._s(platform.label) + " ")]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                { attrs: { label: "Campaign Date", expanded: "" } },
                [
                  _c("b-datepicker", {
                    attrs: {
                      placeholder: "Click to select...",
                      icon: "calendar-today",
                      position: "is-top-right",
                      locale: "fr-Fr",
                      range: "",
                    },
                    model: {
                      value: _vm.campaign.dates,
                      callback: function ($$v) {
                        _vm.$set(_vm.campaign, "dates", $$v)
                      },
                      expression: "campaign.dates",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "Max Optin Date", expanded: "" } },
                [
                  _c("b-datepicker", {
                    attrs: {
                      disabled: _vm.campaign.dates.length == 0,
                      placeholder: "Click to select...",
                      icon: "calendar-today",
                      position: "is-top-right",
                      locale: "fr-Fr",
                      "max-date": _vm.campaignMaxOptinDate,
                      "trap-focus": "",
                    },
                    model: {
                      value: _vm.campaign.max_optin_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.campaign, "max_optin_date", $$v)
                      },
                      expression: "campaign.max_optin_date",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Event (Not Mandatory)",
                    message: "",
                    expanded: "",
                  },
                },
                [
                  !_vm.newEvent
                    ? _c("b-autocomplete", {
                        attrs: {
                          rounded: "",
                          data: _vm.filteredEventLabels,
                          placeholder: "Find Existing Event",
                          icon: "magnify",
                          clearable: "",
                          "open-on-focus": true,
                        },
                        on: {
                          select: function (option) {
                            return (_vm.eventLabelSelected = option)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "empty",
                              fn: function () {
                                return [_vm._v("No results found")]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          478364837
                        ),
                        model: {
                          value: _vm.eventLabel,
                          callback: function ($$v) {
                            _vm.eventLabel = $$v
                          },
                          expression: "eventLabel",
                        },
                      })
                    : _c("b-input", {
                        attrs: {
                          type: "text",
                          placeholder: "New Event",
                          expanded: "",
                        },
                        model: {
                          value: _vm.eventLabel,
                          callback: function ($$v) {
                            _vm.eventLabel = $$v
                          },
                          expression: "eventLabel",
                        },
                      }),
                ],
                1
              ),
              _c(
                "b-field",
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        size: "is-small",
                        outlined: "",
                        rounded: "",
                        type: "is-info",
                      },
                      on: { click: _vm.addNewLabel },
                    },
                    [_vm._v(" " + _vm._s(_vm.eventButtonLabel) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isEditingMode
            ? _c(
                "b-button",
                {
                  attrs: {
                    expanded: "",
                    disabled: _vm.isSetUpDisable(),
                    type: "button is-info",
                  },
                  on: { click: _vm.setUp },
                },
                [_vm._v("Next Step")]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }