<template>
  <b-table
    :data="concepts"
    :striped="true"
    :hoverable="true"
    :default-sort="['is_deleted', 'desc']"
    >
    <template slot-scope="props">
        <b-table-column field="id" label="ID" :width="columnWidth" sortable>
            {{ props.row.id }}
        </b-table-column>
        <b-table-column field="label" label="Label" :width="columnWidth" sortable>
            {{ props.row.label }}
        </b-table-column>
        <b-table-column field="logo" label="Logo" :width="columnWidth">
          <img :src="props.row.logo"
                alt="concept.id"
                class="concept-logo"
                onerror="this.style.display='none'">
        </b-table-column>
        <b-table-column
            field="category"
            label="Category"
            centered>
            <b-tag>
              {{props.row.category}}
            </b-tag>
        </b-table-column>
        <b-table-column field="is_deleted" label="Active" :width="columnWidth" sortable>
          <b-switch
            v-model="props.row.is_deleted"
            passive-type='is-success'
            type='is-danger'
            @input="$emit('activate', props.row.uuid, !props.row.is_deleted)">
          </b-switch>
        </b-table-column>
        <b-table-column label="Actions">
          <b-button type="is-info is-small"
            @click="$emit('upload_picture', props.row.uuid)">
            Upload Picture
          </b-button>
          <button title="edit" class="button is-small is-light"
            @click="$emit('edit', props.row.uuid)">
            <b-icon icon="pencil" size="is-small"></b-icon>
          </button>
        </b-table-column>
      </template>
      <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon icon="emoticon-sad" size="is-large"></b-icon>
          </p>
          <p>Nothing here.</p>
        </div>
      </section>
    </template>
  </b-table>
</template>

<script>
/* eslint-disable */
export default {
  props: ['concepts'],
  data() {
    return {
      columnWidth: 250,
      isEmpty: false
    }
  },
}
</script>
