import axios from '@/axios';
import { baseMarketingUrl } from '@/apis'


const ressourcePath = `${baseMarketingUrl}/campaign-items`;


export default {
/* eslint-disable */
  createOne(opts) {
    return axios.post(ressourcePath, opts)
                .then(response => response.data)
  },
  updateOne(campaignItemUUID, opts) {
    return axios.patch(`${ressourcePath}/${campaignItemUUID}`, opts)
                .then(response => response.data)
  },
  deleteOne(campaignItemUUID) {
    return axios.delete(`${ressourcePath}/${campaignItemUUID}`)
                .then(response => response.data)
  },
  fetchOne(campaignItemUUID) {
    return axios.get(`${ressourcePath}/${campaignItemUUID}`)
                .then(response => response.data)
  },
  fetchAll({campaignUUID}) {
    return axios.get(ressourcePath, {params: {
      campaign_uuid: campaignUUID,
    }})
    .then(response => response.data)
  },
};
