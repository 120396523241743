var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table",
    {
      attrs: {
        data: _vm.concepts,
        striped: true,
        hoverable: true,
        "default-sort": ["is_deleted", "desc"],
      },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function (props) {
            return [
              _c(
                "b-table-column",
                {
                  attrs: {
                    field: "id",
                    label: "ID",
                    width: _vm.columnWidth,
                    sortable: "",
                  },
                },
                [_vm._v(" " + _vm._s(props.row.id) + " ")]
              ),
              _c(
                "b-table-column",
                {
                  attrs: {
                    field: "label",
                    label: "Label",
                    width: _vm.columnWidth,
                    sortable: "",
                  },
                },
                [_vm._v(" " + _vm._s(props.row.label) + " ")]
              ),
              _c(
                "b-table-column",
                {
                  attrs: {
                    field: "logo",
                    label: "Logo",
                    width: _vm.columnWidth,
                  },
                },
                [
                  _c("img", {
                    staticClass: "concept-logo",
                    attrs: {
                      src: props.row.logo,
                      alt: "concept.id",
                      onerror: "this.style.display='none'",
                    },
                  }),
                ]
              ),
              _c(
                "b-table-column",
                {
                  attrs: { field: "category", label: "Category", centered: "" },
                },
                [_c("b-tag", [_vm._v(" " + _vm._s(props.row.category) + " ")])],
                1
              ),
              _c(
                "b-table-column",
                {
                  attrs: {
                    field: "is_deleted",
                    label: "Active",
                    width: _vm.columnWidth,
                    sortable: "",
                  },
                },
                [
                  _c("b-switch", {
                    attrs: { "passive-type": "is-success", type: "is-danger" },
                    on: {
                      input: function ($event) {
                        return _vm.$emit(
                          "activate",
                          props.row.uuid,
                          !props.row.is_deleted
                        )
                      },
                    },
                    model: {
                      value: props.row.is_deleted,
                      callback: function ($$v) {
                        _vm.$set(props.row, "is_deleted", $$v)
                      },
                      expression: "props.row.is_deleted",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-table-column",
                { attrs: { label: "Actions" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-info is-small" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("upload_picture", props.row.uuid)
                        },
                      },
                    },
                    [_vm._v(" Upload Picture ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button is-small is-light",
                      attrs: { title: "edit" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("edit", props.row.uuid)
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "pencil", size: "is-small" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("template", { slot: "empty" }, [
        _c("section", { staticClass: "section" }, [
          _c(
            "div",
            { staticClass: "content has-text-grey has-text-centered" },
            [
              _c(
                "p",
                [
                  _c("b-icon", {
                    attrs: { icon: "emoticon-sad", size: "is-large" },
                  }),
                ],
                1
              ),
              _c("p", [_vm._v("Nothing here.")]),
            ]
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }