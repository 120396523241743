var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.openModal,
            scroll: "keep",
            "on-cancel": _vm.close,
            "full-screen": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.openModal = $event
            },
          },
        },
        [
          _c("section", [
            _c("div", { staticClass: "card", staticStyle: { width: "auto" } }, [
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "columns is-flex" }, [
                  _c(
                    "div",
                    { staticClass: "column is-narrow has-text-centered" },
                    [
                      _c("p", { staticClass: "title is-4" }, [
                        _vm._v(" " + _vm._s(_vm.campaignTitle)),
                      ]),
                    ]
                  ),
                  _vm.campaignConcept
                    ? _c("div", { staticClass: "column is-narrow" }, [
                        _c("img", {
                          staticClass: "concept-logo",
                          attrs: {
                            src: _vm.campaignConcept.logo,
                            alt: _vm.campaignConcept.label,
                            onerror: "this.style.display='none'",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.campaignPlatform
                    ? _c("div", { staticClass: "column is-narrow" }, [
                        _c("img", {
                          staticClass: "concept-logo",
                          attrs: {
                            src: _vm.campaignPlatform.logo,
                            alt: _vm.campaignPlatform.label,
                            onerror: "this.style.display='none'",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c(
                "section",
                { staticClass: "card-content" },
                [
                  _c(
                    "b-tabs",
                    {
                      attrs: { expanded: "" },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "b-tab-item",
                        { attrs: { label: "General" } },
                        [
                          _c("CampaignGeneralForm", {
                            attrs: {
                              activeStep: _vm.activeStep,
                              concepts: _vm.concepts,
                              countries: _vm.countries,
                              locations: _vm.locations,
                              platforms: _vm.platforms,
                              events: _vm.events,
                              isAdhocCampaign: _vm.isAdhocCampaign,
                              campaign: _vm.currentCampaign,
                              isEditingMode: _vm.isEditingMode,
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStep = $event
                              },
                              "update:campaign": function ($event) {
                                _vm.currentCampaign = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        { attrs: { label: "Campaign Type" } },
                        [
                          _c("CampaignTypeForm", {
                            attrs: {
                              countryLocation: _vm.campaignCountryLocation,
                              campaign: _vm.currentCampaign,
                              isEditingMode: _vm.isEditingMode,
                              campaignItems: _vm.campaignItems,
                              menu: _vm.currentMenu,
                            },
                            on: {
                              "update:campaign": function ($event) {
                                _vm.currentCampaign = $event
                              },
                              removeCampaignItem: _vm.removeItem,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        { attrs: { label: "Kitchens" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                "icon-left": "plus",
                                type: "is-info is-small",
                                rounded: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openAddKitchensModal()
                                },
                              },
                            },
                            [_vm._v(" Add Kitchens to Current Campaign ")]
                          ),
                          _c("hr"),
                          _c(
                            "b-tabs",
                            {
                              attrs: { expanded: "" },
                              model: {
                                value: _vm.activeTabCategory,
                                callback: function ($$v) {
                                  _vm.activeTabCategory = $$v
                                },
                                expression: "activeTabCategory",
                              },
                            },
                            _vm._l(
                              _vm.kitchenCategories,
                              function (category, index) {
                                return _c(
                                  "b-tab-item",
                                  {
                                    key: index,
                                    attrs: {
                                      label: _vm.getCategoryLabel(category),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.selectCategory(category)
                                      },
                                    },
                                  },
                                  [
                                    _c("CampaignKitchensForm", {
                                      attrs: {
                                        locations: _vm.locations,
                                        campaign: _vm.currentCampaign,
                                        kitchenCampaignsInformations:
                                          _vm.kitchenCampaignsInformations,
                                        isEditingMode: _vm.isEditingMode,
                                        category: category,
                                      },
                                      on: {
                                        "update:campaign": function ($event) {
                                          _vm.currentCampaign = $event
                                        },
                                        "update:kitchenCampaignsInformations":
                                          function ($event) {
                                            _vm.kitchenCampaignsInformations =
                                              $event
                                          },
                                        "update:kitchen-campaigns-informations":
                                          function ($event) {
                                            _vm.kitchenCampaignsInformations =
                                              $event
                                          },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "b-button",
            {
              attrs: {
                expanded: "",
                disabled: _vm.isUpdateDisable,
                type: "button is-link",
              },
              on: { click: _vm.update },
            },
            [_vm._v("Update")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }