import onboardingActionsAPI from '../../../apis/onboarding/actions';
import { onboardingActionTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  currentKitchen: null,
  current_error: '',
  activatePOSIntegration: {
    pending: false,
    error: null,
  },
  checkPOSIntegration: {
    pending: false,
    error: null,
  },
  configureTasterRestaurant: {
    pending: false,
    error: null,
  },
  closeRestaurants: {
    pending: false,
    error: null,
  },
  copyMenu: {
    pending: false,
    error: null,
  },
  copyProductsInformations: {
    pending: false,
    error: null,
  },
  copyRecipes: {
    pending: false,
    error: null,
  },
  createForecasts: {
    pending: false,
    error: null,
  },
  createKitchen: {
    pending: false,
    error: null,
  },
  createRestaurant: {
    pending: false,
    error: null,
  },
  createMail: {
    pending: false,
    error: null,
  },
  createIntercomInformation: {
    pending: false,
    error: null,
  },
  importIAMLocation: {
    pending: false,
    error: null,
  },
  fetchLaunchTeams: {
    pending: false,
    error: null,
  },
  grantIAMAccess: {
    pending: false,
    error: null,
  },
  launchTeams: [],
  setUpFakeOpeningHours: {
    pending: false,
    error: null,
  },
  setUpHolidays: {
    pending: false,
    error: null,
  },
  updateKitchen: {
    pending: false,
    error: null,
  },
  updateOpeningHours: {
    pending: false,
    error: null,
  },
  uploadMenu: {
    pending: false,
    error: null,
  },
  configureUATEnvironment: {
    pending: false,
    error: null,
  },
  migrateMenu: {
    pending: false,
    error: null,
  },
  exportMenu: {
    pending: false,
    error: null,
  },
  importDummyMenu: {
    pending: false,
    error: null,
  },
  openRestaurants: {
    pending: false,
    error: null,
  },
  setUpSubstitutesRules: {
    pending: false,
    error: null,
  },
  transferRestaurant: {
    pending: false,
    error: null,
  },
  updateRestaurant: {
    pending: false,
    error: null,
  },
};

const actions = {
  async activatePOSIntegration({ commit }, payload) {
    commit(onboardingActionTypes.ACTIVATE_POS_INTEGRATION_ACTION_REQUEST);
    return onboardingActionsAPI
      .activatePOSIntegration(payload)
      .then(function(response) {
        commit(onboardingActionTypes.ACTIVATE_POS_INTEGRATION_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.ACTIVATE_POS_INTEGRATION_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async checkPOSIntegration({ commit }, payload) {
    commit(onboardingActionTypes.CHECK_POS_INTEGRATION_ACTION_REQUEST);
    return onboardingActionsAPI
      .checkPOSIntegration(payload)
      .then(function(response) {
        commit(onboardingActionTypes.CHECK_POS_INTEGRATION_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.CHECK_POS_INTEGRATION_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async createKitchenAction({ commit }, payload) {
    commit(onboardingActionTypes.CREATE_KITCHEN_ACTION_REQUEST);
    return onboardingActionsAPI
      .createKitchen(payload)
      .then(function(kitchenCreactionActionResponse) {
        commit(onboardingActionTypes.CREATE_KITCHEN_ACTION_SUCCESS, kitchenCreactionActionResponse);
      })
      .catch(function(error) {
        commit(onboardingActionTypes.CREATE_KITCHEN_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async createRestaurantAction({ commit }, payload) {
    commit(onboardingActionTypes.CREATE_RESTAURANT_ACTION_REQUEST);
    return onboardingActionsAPI
      .createRestaurant(payload)
      .then(function(restaurantsCreactionActionResponse) {
        commit(onboardingActionTypes.CREATE_RESTAURANT_ACTION_SUCCESS, restaurantsCreactionActionResponse);
      })
      .catch(function(error) {
        commit(onboardingActionTypes.CREATE_RESTAURANT_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async createMailAction({ commit }, payload) {
    commit(onboardingActionTypes.CREATE_MAIL_ACTION_REQUEST);
    return onboardingActionsAPI
      .createMail(payload)
      .then(function(createMailActionActionResponse) {
        commit(onboardingActionTypes.CREATE_MAIL_ACTION_SUCCESS, createMailActionActionResponse);
        return createMailActionActionResponse;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.CREATE_MAIL_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async createIntercomInformationAction({ commit }, payload) {
    commit(onboardingActionTypes.CREATE_INTERCOM_INFORMATION_REQUEST);
    return onboardingActionsAPI
      .createIntercomInformation(payload)
      .then(function(createIntercomActionActionResponse) {
        commit(onboardingActionTypes.CREATE_INTERCOM_INFORMATION_SUCCESS, createIntercomActionActionResponse);
        return createIntercomActionActionResponse;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.CREATE_INTERCOM_INFORMATION_FAILURE, error.response.data.detail);
      });
  },
  async importIAMLocationAction({ commit }, payload) {
    commit(onboardingActionTypes.IMPORT_IAM_LOCATION_ACTION_REQUEST);
    return onboardingActionsAPI
      .importIAMLocationAction(payload)
      .then(function() {
        commit(onboardingActionTypes.IMPORT_IAM_LOCATION_ACTION_SUCCESS);
      })
      .catch(function(error) {
        commit(onboardingActionTypes.IMPORT_IAM_LOCATION_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async fetchLaunchTeams({ commit }, kitchenUUID) {
    commit(onboardingActionTypes.GET_LAUNCH_OWNERS_ACTION_REQUEST);
    return onboardingActionsAPI
      .fetchLaunchTeams(kitchenUUID)
      .then(function(response) {
        commit(onboardingActionTypes.GET_LAUNCH_OWNERS_ACTION_SUCCESS, response);
      })
      .catch(function(error) {
        commit(onboardingActionTypes.GET_LAUNCH_OWNERS_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async grantIAMAccessAction({ commit }, payload) {
    commit(onboardingActionTypes.GRANT_IAM_ACCESS_ACTION_REQUEST);
    return onboardingActionsAPI
      .grantIAMAccessAction(payload)
      .then(function(response) {
        commit(onboardingActionTypes.GRANT_IAM_ACCESS_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.GRANT_IAM_ACCESS_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async updateKitchen({ commit }, payload) {
    commit(onboardingActionTypes.UPDATE_KITCHEN_ACTION_REQUEST);
    return onboardingActionsAPI
      .updateKitchenAction(payload)
      .then(function(response) {
        commit(onboardingActionTypes.UPDATE_KITCHEN_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.UPDATE_KITCHEN_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async updateRestaurant({ commit, rootState }, payload) {
    commit(onboardingActionTypes.UPDATE_RESTAURANT_ACTION_REQUEST);
    return onboardingActionsAPI
      .updateRestaurantAction(payload)
      .then(function(response) {
        commit(onboardingActionTypes.UPDATE_RESTAURANT_ACTION_SUCCESS, {rootState, response});
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.UPDATE_RESTAURANT_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async copyMenu({ commit }, payload) {
    commit(onboardingActionTypes.COPY_MENU_ACTION_REQUEST);
    return onboardingActionsAPI
      .copyMenu(payload)
      .then(function(response) {
        commit(onboardingActionTypes.COPY_MENU_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.COPY_MENU_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async copyRecipes({ commit }, payload) {
    commit(onboardingActionTypes.COPY_RECIPES_ACTION_REQUEST);
    return onboardingActionsAPI
      .copyRecipes(payload)
      .then(function(response) {
        commit(onboardingActionTypes.COPY_RECIPES_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.COPY_RECIPES_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async createForecasts({ commit }, payload) {
    commit(onboardingActionTypes.CREATE_FORECASTS_ACTION_REQUEST);
    return onboardingActionsAPI
      .createForecasts(payload)
      .then(function(response) {
        commit(onboardingActionTypes.CREATE_FORECASTS_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.CREATE_FORECASTS_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async copyProductsInformations({ commit }, payload) {
    commit(onboardingActionTypes.COPY_PRODUCTS_INFORMATION_ACTION_REQUEST);
    return onboardingActionsAPI
      .copyProductsInformations(payload)
      .then(function(response) {
        commit(onboardingActionTypes.COPY_PRODUCTS_INFORMATION_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.COPY_PRODUCTS_INFORMATION_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async setUpHolidays({ commit }, payload) {
    commit(onboardingActionTypes.SET_UP_HOLIDAYS_ACTION_REQUEST);
    return onboardingActionsAPI
      .setUpHolidays(payload)
      .then(function(response) {
        commit(onboardingActionTypes.SET_UP_HOLIDAYS_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.SET_UP_HOLIDAYS_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async setUpFakeOpeningHours({ commit }, payload) {
    commit(onboardingActionTypes.SET_UP_FAKE_OPENING_HOURS_ACTION_REQUEST);
    return onboardingActionsAPI
      .setUpFakeOpeningHours(payload)
      .then(function(response) {
        commit(onboardingActionTypes.SET_UP_FAKE_OPENING_HOURS_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.SET_UP_FAKE_OPENING_HOURS_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async updateOpeningHoursPlatform({ commit }, payload) {
    commit(onboardingActionTypes.UPDATE_OPENING_HOURS_ACTION_REQUEST);
    return onboardingActionsAPI
      .updateOpeningHours(payload)
      .then(function(response) {
        commit(onboardingActionTypes.UPDATE_OPENING_HOURS_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.UPDATE_OPENING_HOURS_ACTION_FAILURE, error.response.data.detail);
        return {'status': 'Not OK'}
      });
  },
  async uploadMenu({ commit }, payload) {
    commit(onboardingActionTypes.UPLOAD_MENU_ACTION_REQUEST);
    return onboardingActionsAPI
      .uploadMenu(payload)
      .then(function(response) {
        commit(onboardingActionTypes.UPLOAD_MENU_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.UPLOAD_MENU_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async exportMenu({ commit }, payload) {
    commit(onboardingActionTypes.EXPORT_MENU_ACTION_REQUEST);
    return onboardingActionsAPI
      .exportMenu(payload)
      .then(function(response) {
        commit(onboardingActionTypes.EXPORT_MENU_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.EXPORT_MENU_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async importDummyMenu({ commit }, payload) {
    commit(onboardingActionTypes.IMPORT_DUMMY_MENU_ACTION_REQUEST);
    return onboardingActionsAPI
      .importDummyMenu(payload)
      .then(function(response) {
        commit(onboardingActionTypes.IMPORT_DUMMY_MENU_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.IMPORT_DUMMY_MENU_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async configureUATEnvironment({ commit }, payload) {
    commit(onboardingActionTypes.CONFIGURE_UAT_ACTION_REQUEST);
    return onboardingActionsAPI
      .configureUATEnvironment(payload)
      .then(function(response) {
        commit(onboardingActionTypes.CONFIGURE_UAT_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.CONFIGURE_UAT_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async migrateMenu({ commit }, payload) {
    commit(onboardingActionTypes.MIGRATE_MENU_ACTION_REQUEST);
    return onboardingActionsAPI
      .migrateMenu(payload)
      .then(function(response) {
        commit(onboardingActionTypes.MIGRATE_MENU_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.MIGRATE_MENU_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async configureTasterRestaurantAction({ commit, rootState }, payload) {
    commit(onboardingActionTypes.CONFIGURE_TASTER_RESTAURANT_ACTION_REQUEST);
    return onboardingActionsAPI
      .createClickAndCollectRestaurant(payload)
      .then(function(payload) {
        commit(onboardingActionTypes.CONFIGURE_TASTER_RESTAURANT_ACTION_SUCCESS, {rootState, payload});
        return payload;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.CONFIGURE_TASTER_RESTAURANT_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async closeRestaurantsAction({ commit, rootState }, payload) {
    commit(onboardingActionTypes.CLOSE_RESTAURANTS_ACTION_REQUEST);
    return onboardingActionsAPI
      .closeRestaurants(payload)
      .then(function(_) {
        commit(onboardingActionTypes.CLOSE_RESTAURANTS_ACTION_SUCCESS, {rootState, payload});
      })
      .catch(function(error) {
        commit(onboardingActionTypes.CLOSE_RESTAURANTS_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async openRestaurantsAction({ commit, rootState }, payload) {
    commit(onboardingActionTypes.OPEN_RESTAURANTS_ACTION_REQUEST);
    return onboardingActionsAPI
      .openRestaurants(payload)
      .then(function(_) {
        commit(onboardingActionTypes.OPEN_RESTAURANTS_ACTION_SUCCESS, {rootState, payload});
      })
      .catch(function(error) {
        commit(onboardingActionTypes.OPEN_RESTAURANTS_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async setUpSubstitutesRules({ commit }, payload) {
    commit(onboardingActionTypes.SET_UP_SUBSTITUTE_RULES_ACTION_REQUEST);
    return onboardingActionsAPI
      .setUpSubstitutesRules(payload)
      .then(function(response) {
        commit(onboardingActionTypes.SET_UP_SUBSTITUTE_RULES_ACTION_SUCCESS);
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.SET_UP_SUBSTITUTE_RULES_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async transferRestaurantAction({ commit, rootState }, payload) {
    commit(onboardingActionTypes.TRANSFER_RESTAURANT_ACTION_REQUEST);
    return onboardingActionsAPI
      .transferRestaurant(payload)
      .then(function(response) {
        commit(onboardingActionTypes.TRANSFER_RESTAURANT_ACTION_SUCCESS, {rootState, response});
        return response;
      })
      .catch(function(error) {
        commit(onboardingActionTypes.TRANSFER_RESTAURANT_ACTION_FAILURE, error.response.data.detail);
      });
  },
};

const mutations = {
  [onboardingActionTypes.CREATE_KITCHEN_ACTION_REQUEST](state) {
    state.createKitchen.pending = true;
  },
  [onboardingActionTypes.CREATE_KITCHEN_ACTION_SUCCESS](state, kitchenCreationActionResponse) {
    state.currentKitchen = kitchenCreationActionResponse.kitchen;
    state.createKitchen.pending = false;
    state.createKitchen.error = null;
  },
  [onboardingActionTypes.CREATE_KITCHEN_ACTION_FAILURE](state, error) {
    state.currentKitchen = null;
    state.createKitchen.pending = false;
    state.createKitchen.error = error;
  },
  [onboardingActionTypes.SET_CURRENT_KITCHEN](state, kitchen) {
    state.currentKitchen = kitchen;
  },
  [onboardingActionTypes.CREATE_RESTAURANT_ACTION_REQUEST](state) {
    state.createRestaurant.pending = true;
  },
  [onboardingActionTypes.CREATE_RESTAURANT_ACTION_SUCCESS](state, restaurantsCreationActionResponse) {
    state.createRestaurant.pending = false;
    state.createRestaurant.error = null;
  },
  [onboardingActionTypes.CREATE_RESTAURANT_ACTION_FAILURE](state, error) {
    state.createRestaurant.pending = false;
    state.createRestaurant.error = error;
  },
  [onboardingActionTypes.CREATE_MAIL_ACTION_REQUEST](state) {
    state.createMail.pending = true;
  },
  [onboardingActionTypes.CREATE_MAIL_ACTION_SUCCESS](state, createMailActionActionResponse) {
    state.currentKitchen = createMailActionActionResponse.kitchen;
    state.createMail.pending = false;
    state.createMail.error = null;
  },
  [onboardingActionTypes.CREATE_MAIL_ACTION_FAILURE](state, error) {
    state.createMail.pending = false;
    state.createMail.error = error;
  },
  [onboardingActionTypes.CREATE_INTERCOM_INFORMATION_REQUEST](state) {
    state.createIntercomInformation.pending = true;
  },
  [onboardingActionTypes.CREATE_INTERCOM_INFORMATION_SUCCESS](state, createIntercomInformationActionActionResponse) {
    state.currentKitchen = createIntercomInformationActionActionResponse.kitchen;
    state.createIntercomInformation.pending = false;
    state.createIntercomInformation.error = null;
  },
  [onboardingActionTypes.CREATE_INTERCOM_INFORMATION_FAILURE](state, error) {
    state.createIntercomInformation.pending = false;
    state.createIntercomInformation.error = error;
  },
  [onboardingActionTypes.IMPORT_IAM_LOCATION_ACTION_REQUEST](state) {
    state.importIAMLocation.pending = true;
  },
  [onboardingActionTypes.IMPORT_IAM_LOCATION_ACTION_SUCCESS](state) {
    state.importIAMLocation.pending = false;
    state.importIAMLocation.error = null;
  },
  [onboardingActionTypes.IMPORT_IAM_LOCATION_ACTION_FAILURE](state, error) {
    state.importIAMLocation.pending = false;
    state.importIAMLocation.error = error;
  },
  [onboardingActionTypes.GET_LAUNCH_OWNERS_ACTION_REQUEST](state) {
    state.fetchLaunchTeams.pending = true;
  },
  [onboardingActionTypes.GET_LAUNCH_OWNERS_ACTION_SUCCESS](state, response) {
    state.fetchLaunchTeams.pending = false;
    state.fetchLaunchTeams.error = null;
    state.launchTeams = response;
  },
  [onboardingActionTypes.GET_LAUNCH_OWNERS_ACTION_FAILURE](state, error) {
    state.fetchLaunchTeams.pending = false;
    state.fetchLaunchTeams.error = error;
  },
  [onboardingActionTypes.GRANT_IAM_ACCESS_ACTION_REQUEST](state) {
    state.grantIAMAccess.pending = true;
  },
  [onboardingActionTypes.GRANT_IAM_ACCESS_ACTION_SUCCESS](state) {
    state.grantIAMAccess.pending = false;
    state.grantIAMAccess.error = null;
  },
  [onboardingActionTypes.GRANT_IAM_ACCESS_ACTION_FAILURE](state, error) {
    state.grantIAMAccess.pending = false;
    state.grantIAMAccess.error = error;
  },
  [onboardingActionTypes.UPDATE_KITCHEN_ACTION_REQUEST](state) {
    state.updateKitchen.pending = true;
  },
  [onboardingActionTypes.UPDATE_KITCHEN_ACTION_SUCCESS](state) {
    state.updateKitchen.pending = false;
    state.updateKitchen.error = null;
  },
  [onboardingActionTypes.UPDATE_KITCHEN_ACTION_FAILURE](state, error) {
    state.updateKitchen.pending = false;
    state.updateKitchen.error = error;
  },
  [onboardingActionTypes.UPDATE_RESTAURANT_ACTION_REQUEST](state) {
    state.updateRestaurant.pending = true;
  },
  [onboardingActionTypes.UPDATE_RESTAURANT_ACTION_SUCCESS](state, response) {
    var updatedRestaurant = response.response.restaurant;
    var kitchenIndex = response.rootState.kitchens.all.findIndex(kitchen => kitchen.uuid === updatedRestaurant.kitchen_uuid);
    if (kitchenIndex !== -1) {
      var kitchen = response.rootState.kitchens.all[kitchenIndex];
      var restaurantIndex = kitchen.restaurants.findIndex(restaurant => restaurant.uuid === updatedRestaurant.uuid);
      if (restaurantIndex !== -1) {
        kitchen.restaurants.splice(restaurantIndex, 1, updatedRestaurant);
        response.rootState.kitchens.all.splice(kitchenIndex, 1, kitchen)
      }
    }
    state.updateRestaurant.pending = false;
    state.updateRestaurant.error = null;
  },
  [onboardingActionTypes.UPDATE_RESTAURANT_ACTION_FAILURE](state, error) {
    state.updateRestaurant.pending = false;
    state.updateRestaurant.error = error;
  },
  [onboardingActionTypes.COPY_MENU_ACTION_REQUEST](state) {
    state.copyMenu.pending = true;
  },
  [onboardingActionTypes.COPY_MENU_ACTION_SUCCESS](state) {
    state.copyMenu.pending = false;
    state.copyMenu.error = null;
  },
  [onboardingActionTypes.COPY_MENU_ACTION_FAILURE](state, error) {
    state.copyMenu.pending = false;
    state.copyMenu.error = error;
  },
  [onboardingActionTypes.COPY_RECIPES_ACTION_REQUEST](state) {
    state.copyRecipes.pending = true;
  },
  [onboardingActionTypes.COPY_RECIPES_ACTION_SUCCESS](state) {
    state.copyRecipes.pending = false;
    state.copyRecipes.error = null;
  },
  [onboardingActionTypes.COPY_RECIPES_ACTION_FAILURE](state, error) {
    state.copyRecipes.pending = false;
    state.copyRecipes.error = error;
  },
  [onboardingActionTypes.CREATE_FORECASTS_ACTION_REQUEST](state) {
    state.createForecasts.pending = true;
  },
  [onboardingActionTypes.CREATE_FORECASTS_ACTION_SUCCESS](state) {
    state.createForecasts.pending = false;
    state.createForecasts.error = null;
  },
  [onboardingActionTypes.CREATE_FORECASTS_ACTION_FAILURE](state, error) {
    state.createForecasts.pending = false;
    state.createForecasts.error = error;
  },
  [onboardingActionTypes.SET_UP_FAKE_OPENING_HOURS_ACTION_REQUEST](state) {
    state.setUpFakeOpeningHours.pending = true;
  },
  [onboardingActionTypes.SET_UP_FAKE_OPENING_HOURS_ACTION_SUCCESS](state) {
    state.setUpFakeOpeningHours.pending = false;
    state.setUpFakeOpeningHours.error = null;
  },
  [onboardingActionTypes.SET_UP_FAKE_OPENING_HOURS_ACTION_FAILURE](state, error) {
    state.setUpFakeOpeningHours.pending = false;
    state.setUpFakeOpeningHours.error = error;
  },
  [onboardingActionTypes.SET_UP_HOLIDAYS_ACTION_REQUEST](state) {
    state.setUpHolidays.pending = true;
  },
  [onboardingActionTypes.SET_UP_HOLIDAYS_ACTION_SUCCESS](state) {
    state.setUpHolidays.pending = false;
    state.setUpHolidays.error = null;
  },
  [onboardingActionTypes.SET_UP_HOLIDAYS_ACTION_FAILURE](state, error) {
    state.setUpHolidays.pending = false;
    state.setUpHolidays.error = error;
  },
  [onboardingActionTypes.UPDATE_OPENING_HOURS_ACTION_REQUEST](state) {
    state.updateOpeningHours.pending = true;
  },
  [onboardingActionTypes.UPDATE_OPENING_HOURS_ACTION_SUCCESS](state) {
    state.updateOpeningHours.pending = false;
    state.updateOpeningHours.error = null;
  },
  [onboardingActionTypes.UPDATE_OPENING_HOURS_ACTION_FAILURE](state, error) {
    state.updateOpeningHours.pending = false;
    state.updateOpeningHours.error = error;
  },
  [onboardingActionTypes.UPLOAD_MENU_ACTION_REQUEST](state) {
    state.uploadMenu.pending = true;
  },
  [onboardingActionTypes.UPLOAD_MENU_ACTION_SUCCESS](state) {
    state.uploadMenu.pending = false;
    state.uploadMenu.error = null;
  },
  [onboardingActionTypes.UPLOAD_MENU_ACTION_FAILURE](state, error) {
    state.uploadMenu.pending = false;
    state.uploadMenu.error = error;
  },
  [onboardingActionTypes.COPY_PRODUCTS_INFORMATION_ACTION_REQUEST](state) {
    state.copyProductsInformations.pending = true;
  },
  [onboardingActionTypes.COPY_PRODUCTS_INFORMATION_ACTION_SUCCESS](state) {
    state.copyProductsInformations.pending = false;
    state.copyProductsInformations.error = null;
  },
  [onboardingActionTypes.COPY_PRODUCTS_INFORMATION_ACTION_FAILURE](state, error) {
    state.copyProductsInformations.pending = false;
    state.copyProductsInformations.error = error;
  },
  [onboardingActionTypes.ACTIVATE_POS_INTEGRATION_ACTION_REQUEST](state) {
    state.activatePOSIntegration.pending = true;
  },
  [onboardingActionTypes.ACTIVATE_POS_INTEGRATION_ACTION_SUCCESS](state) {
    state.activatePOSIntegration.pending = false;
    state.activatePOSIntegration.error = null;
  },
  [onboardingActionTypes.ACTIVATE_POS_INTEGRATION_ACTION_FAILURE](state, error) {
    state.activatePOSIntegration.pending = false;
    state.activatePOSIntegration.error = error;
  },
  [onboardingActionTypes.CHECK_POS_INTEGRATION_ACTION_REQUEST](state) {
    state.checkPOSIntegration.pending = true;
  },
  [onboardingActionTypes.CHECK_POS_INTEGRATION_ACTION_SUCCESS](state) {
    state.checkPOSIntegration.pending = false;
    state.checkPOSIntegration.error = null;
  },
  [onboardingActionTypes.CHECK_POS_INTEGRATION_ACTION_FAILURE](state, error) {
    state.checkPOSIntegration.pending = false;
    state.checkPOSIntegration.error = error;
  },
  [onboardingActionTypes.CONFIGURE_TASTER_RESTAURANT_ACTION_REQUEST](state) {
    state.configureTasterRestaurant.pending = true;
  },
  [onboardingActionTypes.CONFIGURE_TASTER_RESTAURANT_ACTION_SUCCESS](state, response) {
    var createdRestaurant = response.payload.restaurant;
    var kitchenIndex = response.rootState.kitchens.all.findIndex(kitchen => kitchen.uuid === createdRestaurant.kitchen_uuid);
    if (kitchenIndex !== -1) {
      var kitchen = response.rootState.kitchens.all[kitchenIndex];
      kitchen.restaurants.push(createdRestaurant);
      response.rootState.kitchens.all.splice(kitchenIndex, 1, kitchen)
    }
    state.configureTasterRestaurant.pending = false;
    state.configureTasterRestaurant.error = null;
  },
  [onboardingActionTypes.CONFIGURE_TASTER_RESTAURANT_ACTION_FAILURE](state, error) {
    state.currentKitchen = null;
    state.configureTasterRestaurant.pending = false;
    state.configureTasterRestaurant.error = error;
  },
  [onboardingActionTypes.CLOSE_RESTAURANTS_ACTION_REQUEST](state) {
    state.closeRestaurants.pending = true;
  },
  [onboardingActionTypes.CLOSE_RESTAURANTS_ACTION_SUCCESS](state, response) {
    var kitchenIndex = response.rootState.kitchens.all.findIndex(kitchen => kitchen.uuid === response.payload.kitchen_uuid);
    if (kitchenIndex !== -1) {
      var kitchen = response.rootState.kitchens.all[kitchenIndex];
      var restaurantIndex = kitchen.restaurants.findIndex(restaurant => restaurant.concept_uuid === response.payload.concept_uuid && response.payload.platform_uuids.includes(restaurant.platform_uuid));
      if (restaurantIndex !== -1) {
        kitchen.restaurants.splice(restaurantIndex, 1);
        if (kitchen.restaurants.length == 0){
          response.rootState.kitchens.all.splice(kitchenIndex, 1)
        }
      }
    }
    state.closeRestaurants.pending = false;
    state.closeRestaurants.error = null;
  },
  [onboardingActionTypes.CLOSE_RESTAURANTS_ACTION_FAILURE](state, error) {
    state.closeRestaurants.pending = false;
    state.closeRestaurants.error = error;
  },
  [onboardingActionTypes.OPEN_RESTAURANTS_ACTION_REQUEST](state) {
    state.openRestaurants.pending = true;
  },
  [onboardingActionTypes.OPEN_RESTAURANTS_ACTION_SUCCESS](state, response) {
    var kitchenIndex = response.rootState.kitchens.all.findIndex(kitchen => kitchen.uuid === response.payload.kitchen_uuid);
    if (kitchenIndex !== -1) {
      var kitchen = response.rootState.kitchens.all[kitchenIndex];
      var restaurantIndex = kitchen.restaurants.findIndex(restaurant => restaurant.concept_uuid === response.payload.concept_uuid && response.payload.platform_uuids.includes(restaurant.platform_uuid));
      if (restaurantIndex !== -1) {
        kitchen.restaurants.splice(restaurantIndex, 1);
        if (kitchen.restaurants.length == 0){
          response.rootState.kitchens.all.splice(kitchenIndex, 1)
        }
      }
    }
    state.openRestaurants.pending = false;
    state.openRestaurants.error = null;
  },
  [onboardingActionTypes.OPEN_RESTAURANTS_ACTION_FAILURE](state, error) {
    state.openRestaurants.pending = false;
    state.openRestaurants.error = error;
  },
  [onboardingActionTypes.EXPORT_MENU_ACTION_REQUEST](state) {
    state.exportMenu.pending = true;
  },
  [onboardingActionTypes.EXPORT_MENU_ACTION_SUCCESS](state) {
    state.exportMenu.pending = false;
    state.exportMenu.error = null;
  },
  [onboardingActionTypes.EXPORT_MENU_ACTION_FAILURE](state, error) {
    state.exportMenu.pending = false;
    state.exportMenu.error = error;
  },
  [onboardingActionTypes.IMPORT_DUMMY_MENU_ACTION_REQUEST](state) {
    state.importDummyMenu.pending = true;
  },
  [onboardingActionTypes.IMPORT_DUMMY_MENU_ACTION_SUCCESS](state) {
    state.importDummyMenu.pending = false;
    state.importDummyMenu.error = null;
  },
  [onboardingActionTypes.IMPORT_DUMMY_MENU_ACTION_FAILURE](state, error) {
    state.importDummyMenu.pending = false;
    state.importDummyMenu.error = error;
  },
  [onboardingActionTypes.CONFIGURE_UAT_ACTION_REQUEST](state) {
    state.configureUATEnvironment.pending = true;
  },
  [onboardingActionTypes.CONFIGURE_UAT_ACTION_SUCCESS](state) {
    state.configureUATEnvironment.pending = false;
    state.configureUATEnvironment.error = null;
  },
  [onboardingActionTypes.CONFIGURE_UAT_ACTION_FAILURE](state, error) {
    state.configureUATEnvironment.pending = false;
    state.configureUATEnvironment.error = error;
  },
  [onboardingActionTypes.MIGRATE_MENU_ACTION_REQUEST](state) {
    state.migrateMenu.pending = true;
  },
  [onboardingActionTypes.MIGRATE_MENU_ACTION_SUCCESS](state) {
    state.migrateMenu.pending = false;
    state.migrateMenu.error = null;
  },
  [onboardingActionTypes.MIGRATE_MENU_ACTION_FAILURE](state, error) {
    state.migrateMenu.pending = false;
    state.migrateMenu.error = error;
  },
  [onboardingActionTypes.SET_UP_SUBSTITUTE_RULES_ACTION_REQUEST](state) {
    state.setUpSubstitutesRules.pending = true;
  },
  [onboardingActionTypes.SET_UP_SUBSTITUTE_RULES_ACTION_SUCCESS](state) {
    state.setUpSubstitutesRules.pending = false;
    state.setUpSubstitutesRules.error = null;
  },
  [onboardingActionTypes.SET_UP_SUBSTITUTE_RULES_ACTION_FAILURE](state, error) {
    state.setUpSubstitutesRules.pending = false;
    state.setUpSubstitutesRules.error = error;
  },
  [onboardingActionTypes.TRANSFER_RESTAURANT_ACTION_REQUEST](state) {
    state.transferRestaurant.pending = true;
  },
  [onboardingActionTypes.TRANSFER_RESTAURANT_ACTION_SUCCESS](state, response) {
    var createdRestaurant = response.response.restaurant;
    var kitchenIndex = response.rootState.kitchens.all.findIndex(kitchen => kitchen.uuid === createdRestaurant.kitchen_uuid);
    if (kitchenIndex !== -1) {
      var kitchen = response.rootState.kitchens.all[kitchenIndex];
      kitchen.restaurants.push(createdRestaurant);
      response.rootState.kitchens.all.splice(kitchenIndex, 1, kitchen)
    }
    state.transferRestaurant.pending = false;
    state.transferRestaurant.error = null;
  },
  [onboardingActionTypes.TRANSFER_RESTAURANT_ACTION_FAILURE](state, error) {
    state.transferRestaurant.pending = false;
    state.transferRestaurant.error = error;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
