<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close" full-screen>
      <section>
        <div class="card" style="width: auto">
          <div class="card-content">
            <div class="media">
              <div class="media-content">
               <p class="title is-4"> Access Request</p>
              </div>
            </div>
          </div>
          <section class="card-content">
            <b-steps v-model="activeStep" size="is-small" type="is-info" :has-navigation="false">
              <b-step-item label="Member Type Selection" step="1">
                <hr>
                <PeopleTypeSelectionForm :activeStep.sync="activeStep" :newUser.sync="newUser" :removeUserAction.sync="removeUserAction"/>
              </b-step-item>
              <b-step-item label="Member Selection" step="2" :visible="showStep(1)">
                <hr>
                <MemberConfigurationForm
                  :activeStep.sync="activeStep"
                  :groups="groups"
                  :googleGroups="googleGroups"
                  :users="users"
                  :newUser.sync="newUser"
                  :externalUserType.sync="externalUserType"
                  :removeUserAction.sync="removeUserAction"
                  @createUser="createUserFront"
                  @selectUser="selectUser"
                  @deleteUser="deleteUser"
                />
              </b-step-item>
              <b-step-item label="Member Type Selection" step="3">
                <hr>
                <RightsOperationForm :activeStep.sync="activeStep" :operationType.sync="operationType"/>
              </b-step-item>
              <b-step-item label="Rights Selection" step="4" :visible="showStep(3)">
                <hr>
                <RightsSelectionForm
                  :currentUser="currentUser"
                  :concepts="concepts"
                  :countries="countries"
                  :locations="locations"
                  :operationType="operationType"
                  @selectRights="selectRights"
                  @close="close"
                />
              </b-step-item>
            </b-steps>
          </section>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { accessTypes } from '@/store/mutation-types';
import { errorToasterDetailled } from '@/mixins';

import PeopleTypeSelectionForm from '@/components/Onboarding/People/Forms/PeopleTypeSelectionForm.vue'
import MemberConfigurationForm from '@/components/Onboarding/People/Forms/MemberConfigurationForm.vue'
import RightsOperationForm from '@/components/Onboarding/People/Forms/RightsOperationForm.vue'
import RightsSelectionForm from '@/components/Onboarding/People/Forms/RightsSelectionForm.vue'

export default {
  components: {
    PeopleTypeSelectionForm,
    MemberConfigurationForm,
    RightsOperationForm,
    RightsSelectionForm,
  },
  props: ['countries', 'concepts', 'kitchens', 'locations', 'platforms'],
  mixins: [errorToasterDetailled],
  data() {
    return {
      openModal: true,
      activeStep: 0,
      newUser: true,
      externalUserType: null,
      removeUserAction: false,
      operationType: 'ADD',
    }
  },
  computed: mapState({
    isLoading: (state) => state.accesses.fetchGoogleGroups.pending || state.accesses.fetchGroups.pending || state.accesses.fetchUsers.pending || state.accesses.createUser.pending || state.accesses.editRights.pending || state.accesses.removeUser.pending || state.accesses.fetchUser.pending || state.actions.createIntercomInformation.pending || state.actions.updateKitchen.pending,
    error: (state) => state.accesses.fetchGoogleGroups.error ||state.accesses.fetchGroups.error || state.accesses.fetchUsers.error || state.accesses.createUser.error || state.accesses.editRights.error || state.accesses.removeUser.error || state.accesses.fetchUser.error || state.actions.createIntercomInformation.error || state.actions.updateKitchen.error,
    googleGroups: (state) => state.accesses.googleGroups,
    groups: (state) => state.accesses.groups,
    users: (state) => state.accesses.users,
    currentUser: (state) => state.accesses.currentUser,
  }),
  methods: {
    ...mapActions('accesses', ['createUser', 'fetchGoogleGroups', 'fetchGroups', 'fetchUser', 'fetchUsers', 'editRights', 'removeUser']),
    ...mapActions('actions', ['createIntercomInformationAction', 'updateKitchen']),
    ...mapMutations('accesses', {'setCurrentUser': accessTypes.SET_CURRENT_USER}),
    createUserFront(opts) {
      this.createUser(opts).then(() => {
        if (!this.error) {
          this.activeStep = 3;
        }
      })
    },
    selectUser(selectedUser) {
      this.fetchUser(selectedUser.uuid).then(() => this.activeStep = 2);
    },
    deleteUser(opts) {
      this.removeUser(opts.userUUID)
          .then(() => this.close());
    },
    showStep(stepNumber) {
      if (this.activeStep == stepNumber || this.activeStep == stepNumber + 1 || this.activeStep == stepNumber + 2) {
        return true;
      }
      return false;
    },
    selectRights(opts) {
      if (this.newUser && this.externalUserType) {
        var kitchens = this.kitchens.filter(kitchen => opts.location_uuids.includes(kitchen.location_uuid));
        Promise.all([
          this.editRights({...opts, ...{'operation_type': this.operationType, 'userUUID': this.currentUser.uuid}}),
          ...kitchens.map(
            kitchen =>
              {
                var kitchenMailPartners = kitchen.mails.mail_partners || [];
                kitchenMailPartners.push({'mail': this.currentUser.email, 'role': this.externalUserType || ''})
                this.updateKitchen({
                  fields: [{
                    'mails': {...kitchen.mails, 'mail_partners': kitchenMailPartners}
                    }],
                  kitchen_uuid: kitchen.uuid,
                })
              }
          ),
        ]).then(() => {
          Promise.all([
            ...kitchens.map(
              kitchen => this.createIntercomInformationAction(
                {
                  kitchen_uuid: kitchen.uuid,
                }
            )),
          ])
        })
      }
      else {
        this.editRights({...opts, ...{'operation_type': this.operationType, 'userUUID': this.currentUser.uuid}});
      }
    },
    close() {
      this.$parent.close();
    },
  },
  mounted() {
    this.fetchGoogleGroups();
    this.fetchGroups();
    this.fetchUsers();
  },
}
</script>


<style>

.my-modal .modal-close {
  background: red;
}

</style>
