var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.kitchen
    ? _c(
        "div",
        { staticClass: "has-text-centered" },
        [
          _c(
            "b-tabs",
            {
              attrs: { expanded: "" },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "b-tab-item",
                { attrs: { label: "Personal Emails" } },
                [
                  _c(
                    "b-field",
                    { attrs: { grouped: "" } },
                    [
                      _c(
                        "b-field",
                        {
                          attrs: { label: "Manager First Name", expanded: "" },
                        },
                        [
                          _c("b-input", {
                            attrs: { placeholder: "Type Manager First Name" },
                            model: {
                              value: _vm.managerFirstNameComputed,
                              callback: function ($$v) {
                                _vm.managerFirstNameComputed = $$v
                              },
                              expression: "managerFirstNameComputed",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        { attrs: { label: "Manager Last Name", expanded: "" } },
                        [
                          _c("b-input", {
                            attrs: { placeholder: "Type Manager Last Name" },
                            model: {
                              value: _vm.managerLastNameComputed,
                              callback: function ($$v) {
                                _vm.managerLastNameComputed = $$v
                              },
                              expression: "managerLastNameComputed",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { attrs: { grouped: "" } },
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Manager Mail (can be fill later)",
                            expanded: "",
                          },
                        },
                        [
                          _c("b-input", {
                            staticClass: "has-text-centered",
                            attrs: { type: "email" },
                            model: {
                              value: _vm.managerMailComputed,
                              callback: function ($$v) {
                                _vm.managerMailComputed = $$v
                              },
                              expression: "managerMailComputed",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Kitchen Mail (can be fill later)",
                            expanded: "",
                          },
                        },
                        [
                          _c("b-input", {
                            staticClass: "has-text-centered",
                            attrs: { type: "email" },
                            model: {
                              value: _vm.kitchenMailComputed,
                              callback: function ($$v) {
                                _vm.kitchenMailComputed = $$v
                              },
                              expression: "kitchenMailComputed",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        expanded: "",
                        disabled:
                          (_vm.managerFirstName == null &&
                            _vm.managerFirstNameComputed == null) ||
                          (_vm.managerLastName == null &&
                            _vm.managerLastNameComputed == null),
                        type: "button is-success",
                      },
                      on: { click: _vm.createMails },
                    },
                    [_vm._v("Create Mails")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }