var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.openModal,
            scroll: "keep",
            "on-cancel": _vm.close,
            "full-screen": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.openModal = $event
            },
          },
        },
        [
          _vm.kitchen &&
          _vm.launch &&
          _vm.concept &&
          _vm.location &&
          _vm.platforms
            ? _c("section", [
                _c(
                  "div",
                  { staticClass: "card", staticStyle: { width: "auto" } },
                  [
                    _c("div", { staticClass: "card-content" }, [
                      _c(
                        "div",
                        { staticClass: "columns is-flex" },
                        [
                          _c("div", { staticClass: "column is-narrow" }, [
                            _c("h1", { staticClass: "title is-3" }, [
                              _vm._v(" " + _vm._s(_vm.kitchen.id) + " "),
                            ]),
                          ]),
                          _vm.concept.is_internal
                            ? _c(
                                "div",
                                {
                                  staticClass: "column is-narrow is-one-fifth",
                                },
                                [
                                  _c("img", {
                                    staticClass: "concept-logo",
                                    attrs: {
                                      src: _vm.concept.logo,
                                      alt: _vm.concept.label,
                                      onerror: "this.style.display='none'",
                                    },
                                  }),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "column is-narrow is-one-fifth",
                                },
                                [_vm._v(" " + _vm._s(_vm.concept.label) + " ")]
                              ),
                          _vm._l(
                            _vm.onboardingPlatforms,
                            function (platform, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "column" },
                                [
                                  _c(
                                    _vm.isRestaurantUrlDefined(platform)
                                      ? "a"
                                      : "span",
                                    {
                                      tag: "component",
                                      attrs: {
                                        href: _vm.getRestaurantUrl(platform),
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "concept-logo",
                                        attrs: {
                                          src: platform.logo,
                                          alt: platform.label,
                                          onerror: "this.style.display='none'",
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "div",
                            { staticClass: "column is-one-fifth" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    "icon-left": "plus",
                                    type: "is-warning is-light is-small",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openEditLaunchTaskGroupModal()
                                    },
                                  },
                                },
                                [_vm._v(" Add new tasks to current Launch ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "section",
                      { staticClass: "card-content" },
                      [
                        _c(
                          "b-tabs",
                          { attrs: { expanded: "" } },
                          [
                            _c(
                              "b-tab-item",
                              { attrs: { label: "Tasks", icon: "gavel" } },
                              [
                                _c("TasksLists", {
                                  attrs: {
                                    allTasks: _vm.allTasks,
                                    kitchen: _vm.kitchenWithoutDeleted,
                                    location: _vm.location,
                                    tasksByTaskGroup: _vm.tasksByTaskGroup,
                                    tasksByTaskOwner: _vm.tasksByTaskOwner,
                                    taskType: _vm.selectedTaskType,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-tab-item",
                              { attrs: { label: "Summary", icon: "book" } },
                              [
                                _c("LaunchTasksModalSummary", {
                                  attrs: {
                                    taskType: _vm.selectedTaskType,
                                    allTasks: _vm.allTasks,
                                    tasksByTaskGroup: _vm.tasksByTaskGroup,
                                    tasksByTaskOwner: _vm.tasksByTaskOwner,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-tab-item",
                              { attrs: { label: "Information", icon: "home" } },
                              [
                                _c("LaunchInformation", {
                                  attrs: {
                                    kitchen: _vm.kitchenWithoutDeleted,
                                    launch: _vm.launch,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }