<template>
  <div class="box modal-card" style="overflow: visible;">
    <p class="title is-4"> Set Up Holidays </p>
    <div class="has-text-centered">
      <b-field grouped>
        <b-field label="Start Date" expanded>
          <b-datepicker
            v-model="startDate"
            placeholder="Click to select..."
            icon="calendar-today"
            position="is-top-right"
            :unselectable-dates="unselectableStartDates"
            trap-focus>
          </b-datepicker>
        </b-field>
        <b-field label="End Date" expanded>
          <b-datepicker
            v-model="endDate"
            placeholder="Click to select..."
            icon="calendar-today"
            position="is-top-right"
            :unselectable-dates="unselectableEndDates"
            trap-focus>
          </b-datepicker>
        </b-field>
      </b-field>
      <b-button v-if="startDate && endDate" type="is-info" class="button-gap"  @click="set(startDate, endDate)" expanded>
        Set Up
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: ['conceptUUID', 'kitchenUUID', 'startDate', 'endDate'],
  data() {
    return {}
  },
  computed: {
    unselectableStartDates() {
      var pastDate = new Date();
      pastDate.setDate(pastDate.getDate() - 30);
      var pastDates = this.getDaysArray(pastDate, new Date());
      var unselectableStartDates = [...pastDates];
      return unselectableStartDates
    },
    unselectableEndDates() {
      var pastDate = new Date();
      pastDate.setDate(pastDate.getDate() - 30);
      var pastDates = this.getDaysArray(pastDate, new Date());
      // var unselectableEndDates = [new Date(), ...pastDates];
      var unselectableEndDates = [...pastDates];
      return unselectableEndDates
    }
  },
  methods: {
    set(startDate, endDate) {
      var startDateFormatted = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0];
      var endDateFormatted = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0];
      this.$emit('update', {
        concept_uuid: this.conceptUUID,
        kitchen_uuid: this.kitchenUUID,
        start_date: startDateFormatted,
        end_date: endDateFormatted
      })
      this.$emit('close');
    },
    getDaysArray(start, end) {
      for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
          arr.push(new Date(dt));
      }
      return arr;
    }
  }
}
</script>


<style>

.my-modal .modal-close {
  background: red;
}

</style>
