<template>
  <section>
    <b-dropdown
        v-model="selectedConcepts"
        multiple
        aria-role="list">
        <button class="button is-primary" type="button" slot="trigger">
            <span>Concepts ({{ selectedConcepts.length }})</span>
            <b-icon icon="menu-down"></b-icon>
        </button>
        <b-dropdown-item v-for="(concept, index) in activeConcepts"
          :key="index"
          :value="concept"
          aria-role="listitem">
            <span> {{ concept.label }} </span>
        </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-model="selectedPlatforms"
        multiple
        text-align="center">
        <button class="button is-primary" type="button" slot="trigger">
            <span>Platforms ({{ selectedPlatforms.length }})</span>
            <b-icon icon="menu-down"></b-icon>
        </button>
        <b-dropdown-item v-for="(platform, index) in activePlatforms"
          :key="index"
          :value="platform"
          aria-role="listitem">
            <span> {{ platform.label }} </span>
        </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-model="selectedKitchenStatuses"
        multiple
        text-align="center">
        <button class="button is-primary" type="button" slot="trigger">
            <span> Kitchen Owner ({{ selectedKitchenStatuses.length }})</span>
            <b-icon icon="menu-down"></b-icon>
        </button>
        <b-dropdown-item v-for="(kitchenStat, index) in kitchenStatusKeys"
          :key="index"
          :value="kitchenStat"
          aria-role="listitem">
            <span>
              {{ kitchenStat }}
            </span>
        </b-dropdown-item>
    </b-dropdown>
  </section>
</template>

<script>
export default {
  props: ['activeConcepts', 'activePlatforms', 'kitchenStatuses', 'selectedConcepts', 'selectedPlatforms', 'selectedKitchenStatuses'],
  data() {
    return {
      isOpen: 1,
    }
  },
  computed: {
    kitchenStatusKeys() {
      return this.kitchenStatuses.map((kitchenStat) => kitchenStat.label);
    },
  },
  methods: {},
};
</script>

<style>
.dropdown-trigger.a.dropdown-item.is-active {
  background-color: #ff3860;
}
</style>
