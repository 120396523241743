<template>
  <div>
    <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{message}}</p>
        <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
      </header>
    <section class="modal-card-body">
      <div class="content">
          Are you sure you want to Opt-Out <b>{{kitchenLabel}}</b> from the following campaign `{{campaignInformation.label}}`?
      </div>
      <b-field label="Reason" expanded>
        <b-select
          placeholder="Reason"
          v-model="reason"
          expanded>
          <option v-for="(reason, index) in optOutReasons" :key="index" :value="reason">
            {{ reason }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Comments" expanded>
        <b-input
          v-model="comment"
          type="text"
          required
          placeholder="Comments">
        </b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <b-button
          expanded
          label="Close"
          type="button is-warning"
          @click="$emit('close')" />
      <b-button
          :disabled="comment == null || reason == null"
          expanded
          label="Opt Out"
          type="button is-danger"
          @click="update"/>
    </footer>
    </div>
    </b-modal>
  </div>
</template>

<script>

  export default {
    components: {},
    data() {
      return {
        openModal: true,
        comment: null,
        reason: null,
      }
    },
    props: ['campaignInformation', 'kitchenLabel', 'message'],
    computed: {
      optOutReasons () {
        return this.$store.getters['kitchenCampaigns/getOptOutReasons'];
      },
    },
    methods: {
      update() {
        this.$emit('update', {'status_reason': this.reason, 'status_comment': this.comment});
        this.$emit('close');
      },
      close() {
        this.$emit('close');
      },
    },
    mounted() {}
}
</script>
