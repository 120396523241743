var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card",
      staticStyle: { "overflow-y": "auto", "justify-content": "center" },
    },
    [
      _c(
        "section",
        { staticClass: "modal-card-body has-text-centered" },
        [
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column is-11" }),
            _c(
              "div",
              { staticClass: "column is-1" },
              [
                _c("b-button", {
                  attrs: {
                    expanded: "",
                    type: "button is-danger is-small",
                    "icon-left": "close",
                    rounded: "",
                  },
                  on: { click: _vm.close },
                }),
              ],
              1
            ),
          ]),
          _c("h4", { staticClass: "title has-text-centered is-4" }, [
            _vm._v(" " + _vm._s(_vm.task.label)),
          ]),
          _c(
            "b-message",
            {
              attrs: { title: "Description", type: "is-info", closable: false },
            },
            [_vm._v(" " + _vm._s(_vm.task.description) + " ")]
          ),
          _vm.task.links && _vm.task.links.length > 0
            ? _c(
                "div",
                _vm._l(_vm.task.links, function (link, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            tag: "a",
                            type: "is-link",
                            href: link.url,
                            target: "_blank",
                          },
                        },
                        [_vm._v(" " + _vm._s(link.label) + " ")]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }