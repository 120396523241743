<template>
  <div class="box modal-card" style="overflow: visible;">
    <p class="title is-4"> New Task </p>
    <div class="has-text-centered">
      <b-button icon-left="plus" type="is-link" class="button-gap" @click="createTaskGroup()" expanded>
        Create Task Group
      </b-button>
    </div>
    <div class="has-text-centered">
      <b-button icon-left="plus" type="is-info" class="button-gap"  @click="createTask()" expanded>
        Create Task
      </b-button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: [],
    computed: {},
    methods: {
      createTaskGroup() {
        this.$emit('createTaskGroup');
        this.$parent.close();
      },
      createTask() {
        this.$emit('createTask');
        this.$parent.close();
      },
    }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
