<template>
  <div class="box modal-card" style="overflow-y: auto; justify-content: center;">
    <section class="modal-card-body has-text-centered">
      <b-field :label="fieldLabel" expanded>
        <b-numberinput
          v-if="fieldType == 'number'"
          v-model="value"
          type="number">
        </b-numberinput>
        <b-select
          v-model="value"
          v-else-if="fieldType == 'bool'"
        >
          <option v-for="boolValue in booleanValues" :value="boolValue" :key="boolValue">
            {{ boolValue }}
          </option>
        </b-select>
        <b-input v-model="value" v-else></b-input>
      </b-field>
    </section>
      <b-button expanded :disabled="value == null" type="button is-success" @click="updateValue">Set Up</b-button>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      value: this.defaultValue,
      booleanValues: [true, false],
    }
  },
  props: ['fieldLabel', 'fieldType', 'defaultValue'],
  computed: {},
  methods: {
    updateValue() {
      this.$emit('updateValue', this.value);
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
