var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "has-text-centered" }, [
    _vm.availableConcepts && _vm.availableConcepts.length > 0
      ? _c(
          "div",
          [
            _c("h4", { staticClass: "title has-text-centered is-4" }, [
              _vm._v("Select Concept"),
            ]),
            _c(
              "b-field",
              { staticStyle: { "justify-content": "center" } },
              [
                _c(
                  "b-select",
                  {
                    attrs: {
                      type: "text",
                      placeholder: "Concept",
                      expanded: "",
                    },
                    model: {
                      value: _vm.selectedConcept,
                      callback: function ($$v) {
                        _vm.selectedConcept = $$v
                      },
                      expression: "selectedConcept",
                    },
                  },
                  _vm._l(_vm.availableConcepts, function (concept, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: concept } },
                      [_vm._v(" " + _vm._s(concept.label) + " ")]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _c(
              "b-button",
              {
                attrs: {
                  expanded: "",
                  disabled: !_vm.selectedConcept,
                  type: "button is-success is-small",
                },
                on: { click: _vm.selectConcept },
              },
              [_vm._v("Choose")]
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("b", [
              _vm._v(
                " All available concepts are already created for " +
                  _vm._s(_vm.kitchen.id) +
                  " for all available platforms "
              ),
            ]),
            _c(
              "b-button",
              {
                staticClass: "button-gap",
                attrs: { expanded: "", type: "button is-danger is-small" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("Close")]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }