var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box modal-card", staticStyle: { overflow: "visible" } },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("p", { staticClass: "title is-4" }, [_vm._v(" Set Up Menu ")]),
      _c(
        "b-steps",
        {
          attrs: { size: "is-small", type: "is-info", "has-navigation": false },
          model: {
            value: _vm.activeStep,
            callback: function ($$v) {
              _vm.activeStep = $$v
            },
            expression: "activeStep",
          },
        },
        [
          _c("b-step-item", { attrs: { label: "Menu Type", step: "1" } }, [
            _c("hr"),
            _c("div", [
              _c(
                "div",
                { staticClass: "has-text-centered" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button-gap",
                      attrs: { type: "is-link", expanded: "" },
                      on: {
                        click: function ($event) {
                          return _vm.selectMenuType(true)
                        },
                      },
                    },
                    [_vm._v(" Copy Existing Menu ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "b-step-item",
            { attrs: { label: "Menu Configuration", step: "2" } },
            [
              _c("hr"),
              _vm.newMenu
                ? _c(
                    "div",
                    { staticClass: "has-text-centered" },
                    [
                      _c(
                        "h4",
                        { staticClass: "title has-text-centered is-4" },
                        [_vm._v("Select Among Existing Kitchens")]
                      ),
                      _c(
                        "b-field",
                        { attrs: { grouped: "" } },
                        [
                          _c(
                            "b-field",
                            { attrs: { label: "Country" } },
                            [
                              _c(
                                "b-select",
                                {
                                  attrs: {
                                    type: "text",
                                    placeholder: "Country",
                                    expanded: "",
                                  },
                                  model: {
                                    value: _vm.selectedCountry,
                                    callback: function ($$v) {
                                      _vm.selectedCountry = $$v
                                    },
                                    expression: "selectedCountry",
                                  },
                                },
                                _vm._l(
                                  _vm.getExistingKitchenCountries(
                                    _vm.countries,
                                    _vm.locations,
                                    _vm.kitchens
                                  ),
                                  function (kitchenCountry, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: {
                                          value: kitchenCountry.uuid,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(kitchenCountry.label) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { label: "Licensee" } },
                            [
                              _c(
                                "b-select",
                                {
                                  attrs: {
                                    type: "text",
                                    placeholder: "Licensee",
                                    expanded: "",
                                  },
                                  model: {
                                    value: _vm.selectedPartner,
                                    callback: function ($$v) {
                                      _vm.selectedPartner = $$v
                                    },
                                    expression: "selectedPartner",
                                  },
                                },
                                _vm._l(
                                  _vm.kitchenStatus,
                                  function (kitchenStat, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: kitchenStat.value },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(kitchenStat.label) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { label: "Kitchen", expanded: "" } },
                            [
                              _c(
                                "b-select",
                                {
                                  attrs: {
                                    type: "text",
                                    placeholder: "Kitchen",
                                    expanded: "",
                                  },
                                  model: {
                                    value: _vm.selectedExisingKitchen,
                                    callback: function ($$v) {
                                      _vm.selectedExisingKitchen = $$v
                                    },
                                    expression: "selectedExisingKitchen",
                                  },
                                },
                                _vm._l(
                                  _vm.getExistingKitchens(
                                    _vm.selectedCountry,
                                    _vm.selectedPartner,
                                    _vm.locations,
                                    _vm.kitchens,
                                    false,
                                    _vm.kitchenUUID
                                  ),
                                  function (kitchen, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: kitchen },
                                      },
                                      [_vm._v(" " + _vm._s(kitchen.id) + " ")]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.selectedCountry &&
                      _vm.selectedPartner != null &&
                      _vm.selectedExisingKitchen
                        ? _c(
                            "b-button",
                            {
                              staticClass: "button-gap",
                              attrs: { type: "is-info", expanded: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.copy(_vm.selectedExisingKitchen)
                                },
                              },
                            },
                            [_vm._v(" Copy ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _vm._v(" Go to "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.getPlatformURL(),
                            target: "_blank",
                          },
                        },
                        [_vm._v("Platform Manager")]
                      ),
                      _vm._v(" to create dedicated menu. "),
                      _c(
                        "b-button",
                        {
                          staticClass: "button-gap",
                          attrs: { type: "is-info", expanded: "" },
                          on: {
                            click: function ($event) {
                              return _vm.createManually()
                            },
                          },
                        },
                        [_vm._v(" Menu created ")]
                      ),
                    ],
                    1
                  ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }