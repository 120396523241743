import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import Buefy from 'buefy';
import auth from '@/auth';
import './assets/scss/app.scss';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Buefy);
Vue.use(auth)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
