<template>
  <div class="has-text-centered">
    <h4 class="title has-text-centered is-4">Select Concepts</h4>
      <b-field grouped>
        <b-field>
          <b-select rounded type="text" placeholder="Concept Type" @input="option => selectConceptTypes(option)" expanded>
            <option
              v-for="(type, index) in conceptTypes"
              :key="index"
              :value="type"
            >
              {{ type }}
            </option>
          </b-select>
        </b-field>
        <b-field expanded>
          <b-autocomplete
            rounded
            expanded
            v-model="searchTermConcept"
            :data="filteredConcepts"
            :clear-on-select="true"
            placeholder="Select Concept"
            icon="magnify"
            field="label"
            :append-to-body="true"
            :open-on-focus="true"
            @select="option => selectConcept(option)"
          >
          </b-autocomplete>
        </b-field>
        <b-field>
          <b-button size="is-small" outlined rounded type="is-info" @click="selectAllConcepts">
            Select All
          </b-button>
        </b-field>
      </b-field>
      <b-field label="" position="is-centered">
        <b-taglist>
          <b-tag v-for="(concept, index) in userConcepts" :key="index" type="is-info" closable close-icon="delete" close-type='is-danger' @close="selectConcept(concept)">
            {{concept.label}}
          </b-tag>
        </b-taglist>
      </b-field>
    <h4 class="title has-text-centered is-4">Select Kitchens</h4>
      <b-field grouped>
        <b-field>
          <b-select rounded type="text" placeholder="Country" @input="option => selectCountryLocations(option)" expanded>
            <option
              v-for="(country, index) in countriesSorted"
              :key="index"
              :value="country"
            >
              {{ country.label }}
            </option>
          </b-select>
        </b-field>
        <b-field expanded>
          <b-autocomplete
            rounded
            v-model="searchTermLocation"
            :data="filteredLocations"
            :clear-on-select="true"
            placeholder="Select Kitchen"
            icon="magnify"
            field="label"
            :append-to-body="true"
            :open-on-focus="true"
            @select="option => selectLocation(option)"
          >
          </b-autocomplete>
        </b-field>
        <b-field>
          <b-button size="is-small" outlined rounded type="is-info" @click="selectAllLocations">
            Select All
          </b-button>
        </b-field>
      </b-field>
      <b-field label="" position="is-centered">
        <b-taglist>
          <b-tag v-for="(location, index) in userLocations" :key="index" type="is-info" closable close-icon="delete" close-type='is-danger' @close="selectLocation(location)">
            {{location.label}}
          </b-tag>
        </b-taglist>
      </b-field>
      <hr :disabled="selectedConcepts.length == 0 && selectedLocations.length == 0" />
      <b-button :disabled="selectedConcepts.length == 0 && selectedLocations.length == 0" @click="selectRights()" type="button is-success" expanded>
        Select
      </b-button>
  </div>
</template>

<script>

import { auth, utils } from '@/mixins';

  export default {
  mixins: [auth, utils],
  data() {
    return {
      selectedConcepts: [],
      selectedLocations: [],
      searchTermConcept: '',
      searchTermLocation: '',
      conceptTypes: ['Internal', 'External'],
      areConceptsUpdated: false,
      areLocationsUpdated: false,
    }
  },
  props: ['currentUser', 'concepts', 'countries', 'locations', 'operationType'],
  computed: {
    conceptsSorted() {
      var conceptsSorted = [...this.concepts]
      return conceptsSorted.sort((concept1, concept2) => concept1.label.localeCompare(concept2.label));
    },
    countriesSorted() {
      var countriesSorted = [...this.countries]
      return countriesSorted.sort((country1, country2) => country1.label.localeCompare(country2.label));
    },
    locationsSorted() {
      var locationsSorted = [...this.locations]
      return locationsSorted.sort((location1, location2) => location1.label.localeCompare(location2.label));
    },
    userConceptUUIDS() {
      if (!this.currentUser || !this.currentUser.concepts) {
        return [];
      }
      return (this.currentUser.concepts.find(levelConcept => levelConcept['level'] == 'viewers') || {'uuids': []}).uuids;
    },
    userConcepts: {
      get() {
        if (this.selectedConcepts && this.selectedConcepts.length > 0) {
          return this.selectedConcepts
        }
        if (this.currentUser) {
          return this.conceptsSorted.filter(concept => this.userConceptUUIDS.includes(concept.uuid))
        }
        else {
          return []
        }
      },
      set(value) {
        this.selectedConcepts = value;
      }
    },
    userLocationUUIDs() {
      if (!this.currentUser || !this.currentUser.locations) {
        return [];
      }
      return (this.currentUser.locations.find(levelLocation => levelLocation['level'] == 'viewers') || {'uuids': []}).uuids;
    },
    userLocations: {
      get() {
        if (this.selectedLocations && this.selectedLocations.length > 0) {
          return this.selectedLocations
        }
        if (this.currentUser) {
          return this.locationsSorted.filter(location => this.userLocationUUIDs.includes(location.uuid))
        }
        else {
          return []
        }
      },
      set(value) {
        this.selectedLocations = value;
      }
    },
    filteredConcepts() {
      return this.conceptsSorted.filter((concept) => concept.label.toLowerCase().includes(this.searchTermConcept.toLowerCase()) && !this.userConcepts.map(concept => concept.uuid).includes(concept.uuid));
    },
    filteredLocations() {
      return this.locationsSorted.filter((location) => location.label.toLowerCase().includes(this.searchTermLocation.toLowerCase()) && !this.userLocations.map(location => location.uuid).includes(location.uuid))
    },
  },
  methods: {
    selectConceptTypes(conceptType) {
      var internalConcept = conceptType == 'Internal';
      for(let concept of this.conceptsSorted.filter(concept => concept.is_internal == internalConcept)) {
        this.selectConcept(concept);
      }
    },
    selectAllConcepts() {
      for(let concept of this.conceptsSorted) {
        this.selectConcept(concept);
      }
    },
    selectConcept(concept) {
      this.areConceptsUpdated = true;
      if (concept) {
        if (this.userConcepts.map(concept => concept.uuid).includes(concept.uuid)) {
          this.userConcepts = this.userConcepts.filter((selectedConcept) => selectedConcept.uuid != concept.uuid);
        }
        else {
          this.userConcepts = this.userConcepts.concat(concept);
        }
      }
    },
    selectCountryLocations(country) {
      for(let location of this.locationsSorted.filter(location => location.country_uuid == country.uuid)) {
        this.selectLocation(location);
      }
    },
    selectAllLocations() {
      for(let location of this.locationsSorted) {
        this.selectLocation(location);
      }
    },
    selectLocation(location) {
      this.areLocationsUpdated = true;
      if (location) {
        if (this.userLocations.map(location => location.uuid).includes(location.uuid)) {
          this.userLocations = this.userLocations.filter((selectedLocation) => selectedLocation.uuid != location.uuid);
        }
        else {
          this.userLocations = this.userLocations.concat(location);
        }
      }
    },
    selectRights() {
      var locationUUIDS = [];
      var conceptUUIDS = [];
      if (this.operationType == 'ADD') {
        locationUUIDS = this.selectedLocations.map(location => location.uuid).filter(locationUUID => ! this.userLocationUUIDs.includes(locationUUID));
        conceptUUIDS = this.selectedConcepts.map(concept => concept.uuid).filter(conceptUUID => ! this.userConceptUUIDS.includes(conceptUUID));
      }
      else {
        if (this.areConceptsUpdated) {
          conceptUUIDS = this.userConceptUUIDS.filter(conceptUUID => !this.selectedConcepts.map(concept => concept.uuid).includes(conceptUUID)).filter(uuid => this.concepts.map(concept => concept.uuid).includes(uuid));
        }
        if (this.areLocationsUpdated) {
          locationUUIDS = this.userLocationUUIDs.filter(locationUUID => !this.selectedLocations.map(location => location.uuid).includes(locationUUID)).filter(uuid => this.locations.map(location => location.uuid).includes(uuid));
        }
      }
      this.$emit('selectRights',
        {
          'location_uuids': locationUUIDS,
          'concept_uuids': conceptUUIDS
        }
      );
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

.dropdown-trigger.a.dropdown-item.is-active {
  background-color: #4BB543;
}
.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
