var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.launchesInformations,
            striped: true,
            hoverable: true,
            "default-sort": ["launch_date", "desc"],
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "id",
                        label: "ID",
                        width: _vm.columnWidth,
                        sortable: "",
                      },
                    },
                    [_vm._v(" " + _vm._s(props.row.kitchenID) + " ")]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "launch_date",
                        label: "Launch Date",
                        width: _vm.columnWidth,
                        sortable: "",
                        "custom-sort": _vm.sortByDate,
                      },
                    },
                    [_vm._v(" " + _vm._s(props.row.launchDate) + " ")]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "country",
                        label: "Country",
                        width: _vm.columnWidth,
                        sortable: "",
                      },
                    },
                    [_vm._v(" " + _vm._s(props.row.country) + " ")]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "owner",
                        label: "Owner",
                        width: _vm.columnWidth,
                        sortable: "",
                      },
                    },
                    [_vm._v(" " + _vm._s(props.row.owner) + " ")]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "concept",
                        label: "Concept",
                        width: _vm.columnWidth,
                      },
                    },
                    [
                      props.row.concept_internal
                        ? _c("div", [
                            _c("img", {
                              staticClass: "concept-logo",
                              attrs: {
                                src: props.row.concept_logo,
                                alt: props.row.concept_label,
                                onerror: "this.style.display='none'",
                              },
                            }),
                          ])
                        : _c("div", [
                            _vm._v(" " + _vm._s(props.row.concept_label) + " "),
                          ]),
                    ]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "platforms",
                        label: "Platforms",
                        width: _vm.columnWidth,
                      },
                    },
                    _vm._l(props.row.platforms, function (platform, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            _vm.isRestaurantUrlDefined(props.row, platform)
                              ? "a"
                              : "span",
                            {
                              tag: "component",
                              attrs: {
                                href: _vm.getRestaurantUrl(props.row, platform),
                                target: "_blank",
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "concept-logo",
                                attrs: {
                                  src: platform.logo,
                                  alt: platform.label,
                                  onerror: "this.style.display='none'",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Actions" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { type: "is-link is-small" },
                          on: {
                            click: function ($event) {
                              return _vm.openOnboarding(
                                props.row.launchUUID,
                                props.row.platforms,
                                props.row.concept_uuid,
                                props.row.concept_logo,
                                props.row.concept_label,
                                props.row.concept_internal
                              )
                            },
                          },
                        },
                        [_vm._v(" Onboarding ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: { icon: "emoticon-sad", size: "is-large" },
                      }),
                    ],
                    1
                  ),
                  _c("p", [_vm._v("Nothing here.")]),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }