var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.kitchen
    ? _c("div", { staticClass: "has-text-centered" }, [
        _vm.availablePlatforms && _vm.availablePlatforms.length > 0
          ? _c(
              "div",
              [
                _c("h4", { staticClass: "title has-text-centered is-4" }, [
                  _vm._v("Select Platforms"),
                ]),
                _c(
                  "b-field",
                  { staticStyle: { "justify-content": "center" } },
                  _vm._l(_vm.availablePlatforms, function (platform, index) {
                    return _c(
                      "b-checkbox",
                      {
                        key: index,
                        attrs: {
                          value: platform.uuid,
                          name: "platform",
                          "native-value": platform.uuid,
                        },
                        model: {
                          value: _vm.selectedPlatforms,
                          callback: function ($$v) {
                            _vm.selectedPlatforms = $$v
                          },
                          expression: "selectedPlatforms",
                        },
                      },
                      [
                        platform.logo
                          ? _c("div", [
                              _c("img", {
                                staticClass: "image",
                                attrs: {
                                  src: platform.logo,
                                  alt: "",
                                  width: "48",
                                },
                              }),
                            ])
                          : _c("div", [_vm._v(_vm._s(platform.label))]),
                      ]
                    )
                  }),
                  1
                ),
                _c(
                  "b-button",
                  {
                    attrs: {
                      expanded: "",
                      disabled: _vm.selectedPlatforms.length == 0,
                      type: "button is-success is-small",
                    },
                    on: { click: _vm.selectPlatforms },
                  },
                  [_vm._v("Choose")]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("b", [
                  _vm._v(
                    " All available platforms are already created for " +
                      _vm._s(_vm.kitchen.id) +
                      " and this concept "
                  ),
                ]),
                _c(
                  "b-button",
                  {
                    staticClass: "button-gap",
                    attrs: { expanded: "", type: "button is-danger is-small" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }