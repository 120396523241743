<template>
  <div class="has-text-centered" v-if="campaign">
    <b-field grouped>
      <b-field label="Name" message="" expanded>
        <b-input
          v-model="campaign.label"
          placeholder="Campaign Name"
        ></b-input>
      </b-field>
    </b-field>
    <b-field grouped v-if="isAdhocCampaign">
      <b-field label="Country" expanded>
        <b-select
          v-if="!isEditingMode"
          placeholder="Campaign Country"
          v-model="campaign.country_location_uuid"
          expanded>
          <option v-for="(country, index) in countries" :key="index" :value="getCountryLocationUUID(country.uuid)">
            {{ country.id }}
          </option>
        </b-select>
        <b-input
          v-else
          :disabled="isEditingMode"
          v-model="campaignCountry"
          expanded>
        </b-input>
      </b-field>
      <b-field label="Kitchen" expanded>
        <b-select
          :disabled="isEditingMode"
          placeholder="Campaign Kitchen"
          v-model="campaign.locationUUID"
          expanded>
          <option v-for="(location, index) in getCountryLocations(locations, campaign.country_location_uuid)" :key="index" :value="location.uuid">
            {{ location.id }}
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field grouped>
      <b-field label="Country" v-if="!isAdhocCampaign" expanded>
        <b-select
          v-if="!isEditingMode"
          placeholder="Campaign Country"
          v-model="campaign.country_location_uuid"
          expanded>
          <option v-for="(country, index) in countries" :key="index" :value="getCountryLocationUUID(country.uuid)">
            {{ country.id }}
          </option>
        </b-select>
        <b-input
          v-else
          :disabled="isEditingMode"
          v-model="campaignCountry"
          expanded>
        </b-input>
      </b-field>
      <b-field label="Concept" style="justify-content: center;" expanded>
        <b-select
          :disabled="isEditingMode"
          type="text"
          v-model="campaign.concept_uuid"
          placeholder="Concept"
          expanded
          >
          <option v-for="(concept, index) in concepts" :key="index" :value="concept.uuid">
            {{concept.label}}
          </option>
        </b-select>
      </b-field>
      <b-field label="Platform" style="justify-content: center;" expanded>
        <b-select
          :disabled="isEditingMode"
          type="text"
          v-model="campaign.platform_uuid"
          placeholder="Platform"
          expanded>
          <option v-for="(platform, index) in platforms" :key="index" :value="platform.uuid">
            {{platform.label}}
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field grouped>
      <b-field label="Campaign Date" expanded>
        <b-datepicker
          v-model="campaign.dates"
          placeholder="Click to select..."
          icon="calendar-today"
          position="is-top-right"
          locale='fr-Fr'
          range>
        </b-datepicker>
      </b-field>
      <b-field label="Max Optin Date" expanded>
        <b-datepicker
          :disabled="campaign.dates.length == 0"
          v-model="campaign.max_optin_date"
          placeholder="Click to select..."
          icon="calendar-today"
          position="is-top-right"
          locale='fr-Fr'
          :max-date="campaignMaxOptinDate"
          trap-focus>
        </b-datepicker>
      </b-field>
    </b-field>
    <b-field grouped>
      <b-field label="Event (Not Mandatory)" message="" expanded>
        <b-autocomplete
            v-if="!newEvent"
            rounded
            v-model="eventLabel"
            :data="filteredEventLabels"
            placeholder="Find Existing Event"
            icon="magnify"
            clearable
            :open-on-focus="true"
            @select="option => eventLabelSelected = option"
          >
            <template #empty>No results found</template>
        </b-autocomplete>
        <b-input
          v-else
          type="text"
          v-model="eventLabel"
          placeholder="New Event"
          expanded
        >
        </b-input>
      </b-field>
      <b-field>
        <b-button size="is-small" outlined rounded type="is-info" @click="addNewLabel">
          {{eventButtonLabel}}
        </b-button>
      </b-field>
    </b-field>
    <b-button v-if="!isEditingMode" expanded :disabled="isSetUpDisable()" type="button is-info" @click="setUp">Next Step</b-button>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import { commons, utils } from '@/mixins';
  import { eventsTypes } from '@/store/mutation-types';

  export default {
  data() {
    return {
      eventLabelSelected: null,
      newEvent: false,
    }
  },
  mixins: [commons, utils],
  props: ['activeStep', 'concepts', 'countries', 'locations', 'platforms', 'isAdhocCampaign', 'campaign', 'isEditingMode', 'events'],
  computed: {
    ...mapState({
      currentEventLabel: (state) => state.events.currentEventLabel,
    }),
    eventLabel: {
      get() {
        if (this.currentEventLabel) {
          return this.currentEventLabel
        }
        if (this.campaign && this.campaign.event_uuid) {
          var eventLabel = '';
          var event = this.events.find(event => event.uuid == this.campaign.event_uuid);
          if (event) {
            eventLabel = event.label;
          }
          return eventLabel
        }
        else {
          return ''
        }
      },
      set(value) {
          this.setCurrentEventLabel(value);
      }
    },
    campaignCountry() {
      if (this.locations && this.campaign && this.campaign.country_location_uuid) {
        var location = this.locations.find(location => location.uuid == this.campaign.country_location_uuid);
        if (location) {
          return location.id;
        }
      }
      return ''
    },
    filteredEventLabels() {
      if (this.events && this.events.length > 0) {
        var eventLabels = this.events.map(event => event.label);
        if (this.eventLabel) {
          eventLabels = eventLabels.filter((label) => label.toLowerCase().includes(this.eventLabel.toLowerCase()));
        }
        return eventLabels
      }
      return [];
    },
    eventButtonLabel() {
      if (this.newEvent) {
        return 'List'
      }
      return 'New'
    },
    campaignMaxOptinDate() {
      if (this.campaign.dates) {
        return this.getDateWithOffset(this.campaign.dates[0], -1)
      }
      return null
    }
  },
  methods: {
    ...mapMutations('events', { setCurrentEventLabel: eventsTypes.SET_CURRENT_EVENT_LABEL}),
    isSetUpDisable() {
      // if ((this.eventLabelSelected == null && (this.eventLabel == null || this.eventLabel == '')) || )
      if (this.campaign.concept_uuid == null || this.campaign.country_location_uuid == null || this.campaign.label == null || this.campaign.platform_uuid == null || this.campaign.dates.length != 2 || this.campaign.max_optin_date == null) {
        return true;
      }
      if (this.isAdhocCampaign && this.campaign.locationUUID == null) {
        return true;
      }
      return false;
    },
    setUp() {
      this.$emit('update:activeStep', this.activeStep + 1);
    },
    addNewLabel() {
      this.newEvent = !this.newEvent;
    },
    close() {
      this.$emit('close');
    },
  }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
