<template>
  <div class="has-text-centered" v-if="kitchen">
    <div v-if="availablePlatforms && availablePlatforms.length > 0">
      <h4 class="title has-text-centered is-4">Select Platforms</h4>
      <b-field style="justify-content: center;">
        <b-checkbox
          v-for="(platform, index) in availablePlatforms"
          v-model="selectedPlatforms"
          :key="index"
          :value="platform.uuid"
          name="platform"
          :native-value="platform.uuid"
        >
          <div v-if="platform.logo"><img :src="platform.logo" alt="" class="image" width="48" /></div>
          <div v-else>{{ platform.label }}</div>
        </b-checkbox>
      </b-field>
      <b-button expanded :disabled="selectedPlatforms.length == 0" type="button is-success is-small" @click="selectPlatforms">Choose</b-button>
    </div>
    <div v-else>
      <b> All available platforms are already created for {{kitchen.id}} and this concept </b>
      <b-button expanded type="button is-danger is-small" class="button-gap" @click="$emit('close')">Close</b-button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selectedPlatforms: [],
      }
    },
    props: ['activeStep', 'platforms', 'newRestaurants', 'kitchen', 'country', 'selectedConcept'],
    computed: {
      availablePlatforms() {
        if (!this.kitchen) {
          return [];
        }
        var platforms = this.platforms;
        if (this.country) {
          platforms = platforms.filter(platform => platform.available_country_uuids.includes(this.country.uuid));
        }
        if (this.newRestaurants) {
          if (this.kitchen && this.selectedConcept) {
            var restaurants = this.kitchen.restaurants || [];
            var platformUUIDSAlreadyExisting = restaurants.filter((restaurant) => !restaurant.is_deleted && restaurant.concept_uuid == this.selectedConcept.uuid).map((restaurant) => restaurant.platform_uuid);
            return platforms.filter((platform) => !platformUUIDSAlreadyExisting.includes(platform.uuid))
          }
          else {
            return platforms
          }
        }
        else {
          if (!this.kitchen.restaurants || this.kitchen.restaurants.length == 0) {
            return [];
          }
          if (this.kitchen && this.selectedConcept) {
            var platformUUIDAlreadyExisting = this.kitchen.restaurants.filter((restaurant) => !restaurant.is_deleted && restaurant.concept_uuid == this.selectedConcept.uuid).map((restaurant) => restaurant.platform_uuid);
            return platforms.filter((platform) => platformUUIDAlreadyExisting.includes(platform.uuid))
          }
          return []
        }
      }
    },
    methods: {
      selectPlatforms() {
        this.$emit('update:selectedPlatforms', this.selectedPlatforms);
        this.$emit("selectPlatforms");
      }
    }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
