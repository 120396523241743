var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "b-navbar",
        { attrs: { shadow: "" } },
        [
          _c(
            "template",
            { slot: "brand" },
            [
              _c(
                "b-navbar-item",
                { attrs: { tag: "router-link", to: { path: "/" } } },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/logo.png"),
                      alt: "Taster Logo",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "start" },
            [
              _c(
                "b-navbar-item",
                {
                  attrs: {
                    tag: "router-link",
                    to: { path: "/kitchens" },
                    active: _vm.$route.path.includes("/kitchens"),
                  },
                },
                [_vm._v(" Kitchens ")]
              ),
              _vm.displayOnboardingTab()
                ? _c(
                    "b-navbar-item",
                    {
                      attrs: {
                        tag: "router-link",
                        to: { path: "/onboarding" },
                        active: _vm.$route.path.includes("/onboarding"),
                      },
                    },
                    [_vm._v(" Lifecycle ")]
                  )
                : _vm._e(),
              _vm.displayMarketingTab()
                ? _c(
                    "b-navbar-item",
                    {
                      attrs: {
                        tag: "router-link",
                        to: { path: "/marketing" },
                        active: _vm.$route.path.includes("/marketing"),
                      },
                    },
                    [_vm._v(" Marketing ")]
                  )
                : _vm._e(),
              _vm.displaySupplyTab()
                ? _c(
                    "b-navbar-item",
                    {
                      attrs: {
                        tag: "router-link",
                        to: { path: "/supply" },
                        active: _vm.$route.path.includes("/supply"),
                      },
                    },
                    [_vm._v(" Supply ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }