var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box modal-card", staticStyle: { "overflow-y": "auto" } },
    [
      _vm._m(0),
      _vm.taskGroupTasks && _vm.taskGroupTasks.length > 0
        ? _c(
            "div",
            [
              _c("h6", { staticClass: "is-italic" }, [
                _vm._v(" You can grab task with ☰ to edit the order of tasks."),
              ]),
              _c("br"),
              _c(
                "Container",
                {
                  attrs: { "drag-handle-selector": ".column-drag-handle" },
                  on: { drop: _vm.onDrop },
                },
                _vm._l(_vm.taskGroupTasks, function (task) {
                  return _c(
                    "Draggable",
                    { key: task.uuid },
                    [
                      _c(
                        "b-field",
                        {
                          staticClass: "modifier has-text-centered",
                          attrs: { grouped: "" },
                        },
                        [
                          _c(
                            "h3",
                            { staticClass: "column-drag-handle is-size-9" },
                            [_vm._v(" ☰ ")]
                          ),
                          _c("b-field", [
                            _c(
                              "h3",
                              { staticStyle: { "margin-left": "10px" } },
                              [_vm._v(" " + _vm._s(task.label) + " ")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("hr"),
      _c(
        "b-button",
        {
          attrs: { expanded: "", type: "button is-success" },
          on: { click: _vm.updateTaskGroup },
        },
        [_vm._v("Update")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column is-narrow" }, [
        _c("p", { staticClass: "title is-4" }, [_vm._v(" Tasks ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }