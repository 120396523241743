var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box modal-card",
      staticStyle: { "overflow-y": "auto", "justify-content": "center" },
    },
    [
      _c("div", { staticClass: "title is-4" }, [_vm._v("Platform Commission")]),
      _c(
        "section",
        { staticClass: "has-text-centered" },
        [
          _c(
            "b-field",
            { attrs: { label: "Platform commission", expanded: "" } },
            [
              _c("b-numberinput", {
                attrs: { type: "number" },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isInternalLaunch
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "title is-4",
                staticStyle: { "margin-top": "32px" },
              },
              [_vm._v("Taster Commission")]
            ),
            _c("table", { staticClass: "table" }, [
              _vm._m(0),
              _c(
                "tbody",
                _vm._l(_vm.commissions, function (commission, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(commission.delivery_method))]),
                    _c("td", [_vm._v(_vm._s(commission.start_date))]),
                    _c("td", [_vm._v(_vm._s(commission.end_date))]),
                    _c(
                      "td",
                      [
                        _c("b-input", {
                          attrs: { type: "number" },
                          model: {
                            value: commission.rate,
                            callback: function ($$v) {
                              _vm.$set(commission, "rate", $$v)
                            },
                            expression: "commission.rate",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "b-button",
        {
          staticStyle: { "margin-top": "32px" },
          attrs: {
            expanded: "",
            loading: !_vm.hasCommissions || _vm.isLoading,
            disabled: !_vm.hasCommissions || _vm.isLoading,
            type: "button is-success",
          },
          on: { click: _vm.update },
        },
        [_vm._v("Set Up")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Delivery method")]),
        _c("th", [_vm._v("Start date")]),
        _c("th", [_vm._v("End date")]),
        _c("th", [_vm._v("Commmission rate")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }