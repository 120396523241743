import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';
import ListConcepts from '../views/ListConcepts.vue';
import ListKitchens from '../views/ListKitchens.vue';
import Supply from '../views/Supply.vue';
import ListPlatforms from '../views/ListPlatforms.vue';
import Main from '../views/Main';
import KitchenManagement from '../views/KitchenManagement.vue';
import ListOnboarding from '../views/ListOnboarding.vue';
import Onboarding from '../views/Onboarding.vue';
import Marketing from '../views/Marketing.vue';
import MarketingCampaign from '../views/MarketingCampaign.vue';
import roles from './roles';


Vue.use(VueRouter);

const isAuthorized = (authorize) => {
  if (!authorize || !authorize.length) {
    return true;
  } else if (Vue.prototype.$auth.getUserRoles().filter(value => authorize.includes(value)).length) {
    return true;
  }
  return false;
}

const isAuthenticated = (to, from, next) => {
  var { authorize } = to.meta;
  if (!Vue.prototype.$auth.isAuthenticated) {
    Vue.prototype.$auth.signIn().then(() => {
      if (isAuthorized(authorize)) {
        return next();
      }
      return next("")
    })
  } else {
    //Vue.prototype.$errorReporting.setUser(Vue.prototype.$auth.user.sub);
    if (isAuthorized(authorize)) {
      return next();
    }
    return next("")
  }
};


const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '',
        name: 'home',
        component: ListKitchens,
        meta: {authorize: [roles.kitchenTechAdministrator]}
      },
      {
        path: '/concepts',
        name: 'ListConcepts',
        component: ListConcepts,
        meta: {authorize: [roles.kitchenTechAdministrator]}
      },
      {
        path: '/kitchens',
        name: 'ListKitchens',
        component: ListKitchens,
        meta: {authorize: [roles.kitchenTechAdministrator]}
      },
      {
        path: '/kitchens/management',
        name: 'KitchenManagement',
        component: KitchenManagement,
        meta: {authorize: [roles.kitchenTechAdministrator]}
      },
      {
        path: '/platforms',
        name: 'ListPlatforms',
        component: ListPlatforms,
        meta: {authorize: [roles.kitchenTechAdministrator]}
      },
      {
        path: '/onboarding',
        name: 'ListOnboarding',
        component: ListOnboarding,
        meta: {authorize: [roles.kitchenTechAdministrator, roles.kitchenLifecycleManager, roles.kitchenOffboarder]}
      },
      {
        path: '/onboarding/:onboardingUUID',
        name: 'Onboarding',
        component: Onboarding,
        meta: {authorize: [roles.kitchenTechAdministrator, roles.kitchenLifecycleManager, roles.kitchenOffboarder]}
      },
      {
        path: '/marketing',
        name: 'Marketing',
        component: Marketing,
        meta: {authorize: [roles.kitchenTechAdministrator, roles.marketingManagerViewer, roles.marketingManagerUser]}
      },
      {
        path: '/campaigns/:campaignUUID',
        name: 'MarketingCampaign',
        component: MarketingCampaign,
        meta: {authorize: [roles.kitchenTechAdministrator, roles.marketingManagerViewer, roles.marketingManagerUser]}
      },
      {
        path: '/supply',
        name: 'Supply',
        component: Supply,
        meta: {authorize: [roles.kitchenTechAdministrator, roles.supplyPricesImporter]}
      },
    ]
  }
];

const router = new VueRouter({
  // mode: 'history',
  routes,
});

router.beforeEach(isAuthenticated);

export default router;
