var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.openModal,
            scroll: "keep",
            "on-cancel": _vm.close,
          },
          on: {
            "update:active": function ($event) {
              _vm.openModal = $event
            },
          },
        },
        [
          _c("section", [
            _c("div", { staticClass: "card", staticStyle: { width: "auto" } }, [
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "media" }, [
                  _c("div", { staticClass: "media-content" }, [
                    _vm.kitchen.id
                      ? _c(
                          "p",
                          { staticClass: "title is-4", attrs: { p: "" } },
                          [_vm._v(_vm._s(_vm.kitchen.id))]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c(
                "section",
                { staticClass: "card-content" },
                [
                  _c(
                    "b-tabs",
                    { attrs: { multiline: true, expanded: "" } },
                    [
                      _c(
                        "b-tab-item",
                        { attrs: { label: "Info", icon: "account-box" } },
                        [
                          _c(
                            "b-field",
                            { attrs: { grouped: "" } },
                            [
                              _c(
                                "b-field",
                                {
                                  staticClass: "control",
                                  attrs: {
                                    label: "ID",
                                    expanded: "",
                                    disabled: true,
                                    type: _vm.validationFieldType(
                                      _vm.kitchen.id
                                    ),
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      required: "",
                                      disabled: _vm.modificationDisabled,
                                      message: _vm.idMessage(),
                                      placeholder: "Kitchen Id",
                                    },
                                    model: {
                                      value: _vm.kitchen.id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.kitchen, "id", $$v)
                                      },
                                      expression: "kitchen.id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { label: "Owner" } },
                                [
                                  _c(
                                    "b-select",
                                    {
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.modificationDisabled,
                                        placeholder: "Kitchen Franchise",
                                      },
                                      model: {
                                        value: _vm.kitchen.is_franchise,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.kitchen,
                                            "is_franchise",
                                            $$v
                                          )
                                        },
                                        expression: "kitchen.is_franchise",
                                      },
                                    },
                                    _vm._l(
                                      _vm.kitchenStatuses,
                                      function (kitchenStat, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            domProps: {
                                              value: kitchenStat.value,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(kitchenStat.label) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { label: "Launch Date" } },
                            [
                              _c("b-datepicker", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  "show-week-number": "",
                                  placeholder: "Click to select...",
                                  icon: "calendar-today",
                                  "min-date": new Date(2017, 0, 1),
                                },
                                model: {
                                  value: _vm.kitchen.launch_date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.kitchen, "launch_date", $$v)
                                  },
                                  expression: "kitchen.launch_date",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { grouped: "" } },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "UUID", expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      required: "",
                                      disabled: true,
                                      placeholder: "Kitchen Uuid",
                                    },
                                    model: {
                                      value: _vm.kitchen.uuid,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.kitchen, "uuid", $$v)
                                      },
                                      expression: "kitchen.uuid",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Location UUID",
                                    expanded: "",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      required: "",
                                      disabled: true,
                                      placeholder: "Kitchen Uuid",
                                    },
                                    model: {
                                      value: _vm.kitchen.location_uuid,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.kitchen,
                                          "location_uuid",
                                          $$v
                                        )
                                      },
                                      expression: "kitchen.location_uuid",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        { attrs: { label: "Address", icon: "home" } },
                        [
                          _c("b", [_vm._v(" Address ")]),
                          !_vm.modificationDisabled
                            ? _c(
                                "b-switch",
                                {
                                  attrs: {
                                    "passive-type": "is-success",
                                    "false-value": "Google Map",
                                    "true-value": "Manual",
                                    type: "is-danger",
                                    size: "is-small",
                                  },
                                  model: {
                                    value: _vm.manualAddressMode,
                                    callback: function ($$v) {
                                      _vm.manualAddressMode = $$v
                                    },
                                    expression: "manualAddressMode",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.manualAddressMode) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-field",
                            { attrs: { grouped: "" } },
                            [
                              _vm.manualAddressMode == "Google Map"
                                ? _c(
                                    "b-field",
                                    { attrs: { label: "", expanded: "" } },
                                    [
                                      _c("vue-google-autocomplete", {
                                        attrs: {
                                          id: "map",
                                          classname: "google-autocomplete",
                                          country: [
                                            "fr",
                                            "uk",
                                            "es",
                                            "be",
                                            "nl",
                                          ],
                                          disabled: _vm.modificationDisabled,
                                        },
                                        on: {
                                          placechanged: _vm.getAddressData,
                                        },
                                        model: {
                                          value: _vm.selectedAddress,
                                          callback: function ($$v) {
                                            _vm.selectedAddress = $$v
                                          },
                                          expression: "selectedAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "b-field",
                                    { attrs: { expanded: "" } },
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          type: "text",
                                          placeholder: "Address",
                                        },
                                        model: {
                                          value: _vm.kitchen.address,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.kitchen,
                                              "address",
                                              $$v
                                            )
                                          },
                                          expression: "kitchen.address",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { grouped: "" } },
                            [
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Country",
                                    type: _vm.validationFieldType(
                                      _vm.kitchen.country
                                    ),
                                    expanded: "",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      required: "",
                                      disabled: true,
                                      placeholder: "Kitchen Country",
                                    },
                                    model: {
                                      value: _vm.kitchen.country,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.kitchen, "country", $$v)
                                      },
                                      expression: "kitchen.country",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "City",
                                    type: _vm.validationFieldType(
                                      _vm.kitchen.city
                                    ),
                                    expanded: "",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      required: "",
                                      disabled: true,
                                      placeholder: "Kitchen City",
                                    },
                                    model: {
                                      value: _vm.kitchen.city,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.kitchen, "city", $$v)
                                      },
                                      expression: "kitchen.city",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { grouped: "" } },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "Postcode", expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "Postcode",
                                      disabled:
                                        _vm.manualAddressMode != "Manual",
                                    },
                                    model: {
                                      value:
                                        _vm.kitchen.address_information
                                          .postcode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.kitchen.address_information,
                                          "postcode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "kitchen.address_information.postcode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { label: "Route", expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "Route",
                                      disabled:
                                        _vm.manualAddressMode != "Manual",
                                    },
                                    model: {
                                      value:
                                        _vm.kitchen.address_information.route,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.kitchen.address_information,
                                          "route",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "kitchen.address_information.route",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Street Number",
                                    expanded: "",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "Street Number",
                                      disabled:
                                        _vm.manualAddressMode != "Manual",
                                    },
                                    model: {
                                      value:
                                        _vm.kitchen.address_information
                                          .street_number,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.kitchen.address_information,
                                          "street_number",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "kitchen.address_information.street_number",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { grouped: "" } },
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "Latitude", expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      step: "0.000000000001",
                                      type: "number",
                                      placeholder: "Latitude",
                                      disabled:
                                        _vm.manualAddressMode != "Manual",
                                    },
                                    model: {
                                      value:
                                        _vm.kitchen.address_information
                                          .latitude,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.kitchen.address_information,
                                          "latitude",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "kitchen.address_information.latitude",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { label: "Longitude", expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      step: "0.000000000001",
                                      type: "number",
                                      placeholder: "Longitude",
                                      disabled:
                                        _vm.manualAddressMode != "Manual",
                                    },
                                    model: {
                                      value:
                                        _vm.kitchen.address_information
                                          .longitude,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.kitchen.address_information,
                                          "longitude",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "kitchen.address_information.longitude",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        {
                          attrs: { label: "Company", icon: "office-building" },
                        },
                        [
                          _c(
                            "b-field",
                            { attrs: { grouped: "" } },
                            [
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Company Name",
                                    expanded: "",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      required: "",
                                      disabled:
                                        _vm.modificationDisabled ||
                                        !_vm.canEditFinancialInformation(),
                                      placeholder: "Company Name",
                                    },
                                    model: {
                                      value: _vm.kitchen.company_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.kitchen,
                                          "company_name",
                                          $$v
                                        )
                                      },
                                      expression: "kitchen.company_name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Company Type",
                                    expanded: "",
                                  },
                                },
                                [
                                  _c(
                                    "b-select",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.modificationDisabled ||
                                          !_vm.canEditFinancialInformation(),
                                        type: "text",
                                        placeholder: "Company Type",
                                        expanded: "",
                                      },
                                      model: {
                                        value: _vm.kitchen.company_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.kitchen,
                                            "company_type",
                                            $$v
                                          )
                                        },
                                        expression: "kitchen.company_type",
                                      },
                                    },
                                    _vm._l(
                                      _vm.companyTypes,
                                      function (companyType, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            domProps: { value: companyType },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(companyType) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("b", [_vm._v(" Company Address ")]),
                          !_vm.modificationDisabled
                            ? _c(
                                "b-switch",
                                {
                                  attrs: {
                                    "passive-type": "is-success",
                                    "false-value": "Google Map",
                                    "true-value": "Manual",
                                    type: "is-danger",
                                    size: "is-small",
                                  },
                                  model: {
                                    value: _vm.manualCompanyAddressMode,
                                    callback: function ($$v) {
                                      _vm.manualCompanyAddressMode = $$v
                                    },
                                    expression: "manualCompanyAddressMode",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.manualCompanyAddressMode) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-field",
                            { attrs: { label: "", expanded: "" } },
                            [
                              _vm.manualCompanyAddressMode == "Google Map"
                                ? _c("vue-google-autocomplete", {
                                    attrs: {
                                      id: "mapCompany",
                                      classname: "google-autocomplete",
                                      country: ["fr", "uk", "es", "be", "nl"],
                                      disabled:
                                        _vm.modificationDisabled ||
                                        !_vm.canEditFinancialInformation(),
                                    },
                                    on: {
                                      placechanged: _vm.getCompanyAddressData,
                                    },
                                    model: {
                                      value: _vm.selectedCompanyAddress,
                                      callback: function ($$v) {
                                        _vm.selectedCompanyAddress = $$v
                                      },
                                      expression: "selectedCompanyAddress",
                                    },
                                  })
                                : _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "Address",
                                      disabled:
                                        _vm.modificationDisabled ||
                                        !_vm.canEditFinancialInformation(),
                                    },
                                    model: {
                                      value: _vm.kitchen.company_address,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.kitchen,
                                          "company_address",
                                          $$v
                                        )
                                      },
                                      expression: "kitchen.company_address",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "16px" } },
                                [
                                  _c("b", [_vm._v(" Contacts")]),
                                  !_vm.modificationDisabled &&
                                  _vm.canEditFinancialInformation()
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            "icon-left": "plus",
                                            size: "is-small",
                                            type: "is-text",
                                          },
                                          on: {
                                            click: function () {
                                              return _vm.kitchen.contacts.push({
                                                first_name: "",
                                                last_name: "",
                                                personal_mail: "",
                                                phone_number: "",
                                                role: "",
                                              })
                                            },
                                          },
                                        },
                                        [_vm._v("Add")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.kitchen.contacts,
                                function (contact, index) {
                                  return _c(
                                    "b-field",
                                    { key: index, attrs: { grouped: "" } },
                                    [
                                      _c(
                                        "b-field",
                                        {
                                          attrs: {
                                            label: "First Name",
                                            "label-position": "on-border",
                                            expanded: "",
                                          },
                                        },
                                        [
                                          _c("b-input", {
                                            attrs: {
                                              type: "text",
                                              size: "is-small",
                                              rounded: "",
                                              placeholder: "First Name",
                                              disabled:
                                                _vm.modificationDisabled ||
                                                !_vm.canEditFinancialInformation(),
                                              expanded: "",
                                            },
                                            model: {
                                              value: contact.first_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  contact,
                                                  "first_name",
                                                  $$v
                                                )
                                              },
                                              expression: "contact.first_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-field",
                                        {
                                          attrs: {
                                            label: "Last Name",
                                            "label-position": "on-border",
                                            expanded: "",
                                          },
                                        },
                                        [
                                          _c("b-input", {
                                            attrs: {
                                              type: "text",
                                              size: "is-small",
                                              rounded: "",
                                              placeholder: "Last Name",
                                              disabled:
                                                _vm.modificationDisabled ||
                                                !_vm.canEditFinancialInformation(),
                                              expanded: "",
                                            },
                                            model: {
                                              value: contact.last_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  contact,
                                                  "last_name",
                                                  $$v
                                                )
                                              },
                                              expression: "contact.last_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-field",
                                        {
                                          attrs: {
                                            label: "Mail",
                                            "label-position": "on-border",
                                            expanded: "",
                                          },
                                        },
                                        [
                                          _c("b-input", {
                                            attrs: {
                                              type: "text",
                                              size: "is-small",
                                              rounded: "",
                                              placeholder: "Mail",
                                              disabled:
                                                _vm.modificationDisabled ||
                                                !_vm.canEditFinancialInformation(),
                                              expanded: "",
                                            },
                                            model: {
                                              value: contact.personal_mail,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  contact,
                                                  "personal_mail",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contact.personal_mail",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-field",
                                        {
                                          attrs: {
                                            label: "Phone",
                                            "label-position": "on-border",
                                            expanded: "",
                                          },
                                        },
                                        [
                                          _c("b-input", {
                                            attrs: {
                                              type: "text",
                                              size: "is-small",
                                              rounded: "",
                                              placeholder: "Phone",
                                              disabled:
                                                _vm.modificationDisabled ||
                                                !_vm.canEditFinancialInformation(),
                                              expanded: "",
                                            },
                                            model: {
                                              value: contact.phone_number,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  contact,
                                                  "phone_number",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contact.phone_number",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-field",
                                        {
                                          attrs: {
                                            label: "Role",
                                            "label-position": "on-border",
                                            expanded: "",
                                          },
                                        },
                                        [
                                          _c("b-input", {
                                            attrs: {
                                              type: "text",
                                              size: "is-small",
                                              rounded: "",
                                              placeholder: "Role",
                                              disabled:
                                                _vm.modificationDisabled ||
                                                !_vm.canEditFinancialInformation(),
                                              expanded: "",
                                            },
                                            model: {
                                              value: contact.role,
                                              callback: function ($$v) {
                                                _vm.$set(contact, "role", $$v)
                                              },
                                              expression: "contact.role",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      !_vm.modificationDisabled
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                "icon-left": "delete",
                                                size: "is-small",
                                              },
                                              on: {
                                                click: function () {
                                                  return _vm.kitchen.contacts.splice(
                                                    index,
                                                    1
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Remove")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        { attrs: { label: "Back Office", icon: "contacts" } },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "title is-6",
                              staticStyle: { "text-align": "center" },
                            },
                            [_vm._v(" Mails ")]
                          ),
                          _c(
                            "b-field",
                            {
                              attrs: { horizontal: "", label: "Manager Mail" },
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  type: "mail",
                                  placeholder: "Manager Mail",
                                },
                                model: {
                                  value: _vm.kitchen.mails.mail_manager,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.mails,
                                      "mail_manager",
                                      $$v
                                    )
                                  },
                                  expression: "kitchen.mails.mail_manager",
                                },
                              }),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    type: "is-info",
                                    position: "is-right",
                                    label:
                                      "This mail is used for One Board, One Tablet, One Source & Deliveroo Back Office with Manager access",
                                    multilined: "",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      size: "is-small",
                                      icon: "help-circle-outline",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { horizontal: "", label: "Cook Mail" } },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  type: "mail",
                                  placeholder: "Cook Mail",
                                },
                                model: {
                                  value: _vm.kitchen.mails.mail_cook,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.mails,
                                      "mail_cook",
                                      $$v
                                    )
                                  },
                                  expression: "kitchen.mails.mail_cook",
                                },
                              }),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    type: "is-info",
                                    position: "is-right",
                                    label:
                                      "This mail is used for One Board, One Tablet, One Source & Deliveroo Back Office with level access",
                                    multilined: "",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      size: "is-small",
                                      icon: "help-circle-outline",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            {
                              attrs: {
                                horizontal: "",
                                label: "Deliveroo Tech Mail",
                              },
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled:
                                    _vm.modificationDisabled ||
                                    !_vm.canEditInformation(),
                                  type: "mail",
                                  placeholder: "Deliveroo Tech Mail",
                                },
                                model: {
                                  value: _vm.kitchen.mails.mail_deliveroo_tech,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.mails,
                                      "mail_deliveroo_tech",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.mails.mail_deliveroo_tech",
                                },
                              }),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    type: "is-info",
                                    position: "is-right",
                                    label:
                                      "This mail is used for Deliveroo Back Office for One Tablet -> do not edit it",
                                    multilined: "",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      size: "is-small",
                                      icon: "help-circle-outline",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "16px" } },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "title is-6",
                                      staticStyle: { "text-align": "center" },
                                    },
                                    [_vm._v(" Taster Partner Mails ")]
                                  ),
                                ]
                              ),
                              _vm._l(
                                _vm.kitchen.mails.mail_partners,
                                function (mail_partner, index) {
                                  return _c(
                                    "b-field",
                                    { key: index, attrs: { grouped: "" } },
                                    [
                                      _c(
                                        "b-field",
                                        { attrs: { expanded: "" } },
                                        [
                                          _c("b-input", {
                                            attrs: {
                                              type: "text",
                                              size: "is-small",
                                              rounded: "",
                                              placeholder: "Mail",
                                              disabled: true,
                                              expanded: "",
                                            },
                                            model: {
                                              value: mail_partner.mail,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  mail_partner,
                                                  "mail",
                                                  $$v
                                                )
                                              },
                                              expression: "mail_partner.mail",
                                            },
                                          }),
                                          _c("b-input", {
                                            staticStyle: {
                                              "margin-left": "10px",
                                            },
                                            attrs: {
                                              type: "text",
                                              size: "is-small",
                                              rounded: "",
                                              placeholder: "Role",
                                              disabled: true,
                                              expanded: "",
                                            },
                                            model: {
                                              value: mail_partner.role,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  mail_partner,
                                                  "role",
                                                  $$v
                                                )
                                              },
                                              expression: "mail_partner.role",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        { attrs: { label: "Supply", icon: "car-pickup" } },
                        [
                          _c("b", [_vm._v(" Delivery Address ")]),
                          !_vm.modificationDisabled
                            ? _c(
                                "b-switch",
                                {
                                  attrs: {
                                    "passive-type": "is-success",
                                    "false-value": "Google Map",
                                    "true-value": "Manual",
                                    type: "is-danger",
                                    size: "is-small",
                                  },
                                  model: {
                                    value: _vm.manualSupplyAddressMode,
                                    callback: function ($$v) {
                                      _vm.manualSupplyAddressMode = $$v
                                    },
                                    expression: "manualSupplyAddressMode",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.manualSupplyAddressMode) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-field",
                            { attrs: { label: "", expanded: "" } },
                            [
                              _vm.manualSupplyAddressMode == "Google Map"
                                ? _c("vue-google-autocomplete", {
                                    attrs: {
                                      id: "mapDelivery",
                                      classname: "google-autocomplete",
                                      country: ["fr", "uk", "es", "be", "nl"],
                                      disabled: _vm.modificationDisabled,
                                    },
                                    on: {
                                      placechanged: _vm.getDeliveryAddressData,
                                    },
                                    model: {
                                      value: _vm.selectedDeliveryAddress,
                                      callback: function ($$v) {
                                        _vm.selectedDeliveryAddress = $$v
                                      },
                                      expression: "selectedDeliveryAddress",
                                    },
                                  })
                                : _c("b-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "Address",
                                      disabled: _vm.modificationDisabled,
                                    },
                                    model: {
                                      value: _vm.kitchen.supply.address,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.kitchen.supply,
                                          "address",
                                          $$v
                                        )
                                      },
                                      expression: "kitchen.supply.address",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "16px" } },
                            [
                              _c("b", [_vm._v(" Delivery Contact")]),
                              !_vm.modificationDisabled &&
                              !_vm.kitchen.supply.delivery_contact
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        "icon-left": "plus",
                                        size: "is-small",
                                        type: "is-text",
                                      },
                                      on: {
                                        click: function () {
                                          return this$1.$set(
                                            _vm.kitchen.supply,
                                            "delivery_contact",
                                            {}
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Add")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.kitchen.supply.delivery_contact
                            ? _c(
                                "b-field",
                                { attrs: { grouped: "" } },
                                [
                                  _c(
                                    "b-field",
                                    {
                                      attrs: {
                                        label: "First Name",
                                        "label-position": "on-border",
                                        expanded: "",
                                      },
                                    },
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          type: "text",
                                          size: "is-small",
                                          rounded: "",
                                          placeholder: "First Name",
                                          disabled: _vm.modificationDisabled,
                                          expanded: "",
                                        },
                                        model: {
                                          value:
                                            _vm.kitchen.supply.delivery_contact
                                              .first_name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.kitchen.supply
                                                .delivery_contact,
                                              "first_name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "kitchen.supply.delivery_contact.first_name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-field",
                                    {
                                      attrs: {
                                        label: "Last Name",
                                        "label-position": "on-border",
                                        expanded: "",
                                      },
                                    },
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          type: "text",
                                          size: "is-small",
                                          rounded: "",
                                          placeholder: "Last Name",
                                          disabled: _vm.modificationDisabled,
                                          expanded: "",
                                        },
                                        model: {
                                          value:
                                            _vm.kitchen.supply.delivery_contact
                                              .last_name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.kitchen.supply
                                                .delivery_contact,
                                              "last_name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "kitchen.supply.delivery_contact.last_name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-field",
                                    {
                                      attrs: {
                                        label: "Mail",
                                        "label-position": "on-border",
                                        expanded: "",
                                      },
                                    },
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          type: "text",
                                          size: "is-small",
                                          rounded: "",
                                          placeholder: "Mail",
                                          disabled: _vm.modificationDisabled,
                                          expanded: "",
                                        },
                                        model: {
                                          value:
                                            _vm.kitchen.supply.delivery_contact
                                              .personal_mail,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.kitchen.supply
                                                .delivery_contact,
                                              "personal_mail",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "kitchen.supply.delivery_contact.personal_mail",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-field",
                                    {
                                      attrs: {
                                        label: "Phone",
                                        "label-position": "on-border",
                                        expanded: "",
                                      },
                                    },
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          type: "text",
                                          size: "is-small",
                                          rounded: "",
                                          placeholder: "Phone",
                                          disabled: _vm.modificationDisabled,
                                          expanded: "",
                                        },
                                        model: {
                                          value:
                                            _vm.kitchen.supply.delivery_contact
                                              .phone_number,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.kitchen.supply
                                                .delivery_contact,
                                              "phone_number",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "kitchen.supply.delivery_contact.phone_number",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "16px" } },
                            [
                              _c("b", [_vm._v(" Delivery Times")]),
                              !_vm.modificationDisabled &&
                              !_vm.kitchen.supply.delivery_times
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        "icon-left": "plus",
                                        size: "is-small",
                                        type: "is-text",
                                      },
                                      on: {
                                        click: function () {
                                          return this$1.$set(
                                            _vm.kitchen.supply,
                                            "delivery_times",
                                            {}
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Add")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.kitchen.supply.delivery_times
                            ? _c("div", { staticClass: "columns" }, [
                                _c(
                                  "div",
                                  { staticClass: "column is-narrow" },
                                  [
                                    _c("h2", [_vm._v(" Start Time")]),
                                    _c("b-timepicker", {
                                      attrs: {
                                        disabled: _vm.modificationDisabled,
                                        inline: "",
                                        incrementMinutes: _vm.minutes,
                                        size: "is-small",
                                      },
                                      model: {
                                        value: _vm.deliveryTimeStart,
                                        callback: function ($$v) {
                                          _vm.deliveryTimeStart = $$v
                                        },
                                        expression: "deliveryTimeStart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "column is-narrow" },
                                  [
                                    _c("h2", [_vm._v(" End Time")]),
                                    _c("b-timepicker", {
                                      attrs: {
                                        disabled: _vm.modificationDisabled,
                                        inline: "",
                                        incrementMinutes: _vm.minutes,
                                        size: "is-small",
                                      },
                                      model: {
                                        value: _vm.deliveryTimeEnd,
                                        callback: function ($$v) {
                                          _vm.deliveryTimeEnd = $$v
                                        },
                                        expression: "deliveryTimeEnd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            [
                              _c(
                                "b-field",
                                { attrs: { label: "Delivery Instructions" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      disabled: _vm.modificationDisabled,
                                      type: "text",
                                      placeholder: "Instructions for delivers",
                                    },
                                    model: {
                                      value:
                                        _vm.kitchen.supply
                                          .delivery_instructions,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.kitchen.supply,
                                          "delivery_instructions",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "kitchen.supply.delivery_instructions",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "16px" } },
                                [
                                  _c("b", [_vm._v(" Delivery Mail CC")]),
                                  !_vm.modificationDisabled
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            "icon-left": "plus",
                                            size: "is-small",
                                            type: "is-text",
                                          },
                                          on: {
                                            click: function () {
                                              return _vm.addDeliveryMail(
                                                _vm.kitchen
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Add")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.kitchen.supply.delivery_mails_cc,
                                function (mail, index) {
                                  return _c(
                                    "b-field",
                                    { key: index, attrs: { grouped: "" } },
                                    [
                                      _c(
                                        "b-field",
                                        {
                                          attrs: {
                                            label: "Mail",
                                            "label-position": "on-border",
                                            expanded: "",
                                          },
                                        },
                                        [
                                          _c("b-input", {
                                            attrs: {
                                              type: "text",
                                              size: "is-small",
                                              rounded: "",
                                              placeholder: "First Name",
                                              disabled:
                                                _vm.modificationDisabled,
                                              expanded: "",
                                            },
                                            model: {
                                              value: mail.mail,
                                              callback: function ($$v) {
                                                _vm.$set(mail, "mail", $$v)
                                              },
                                              expression: "mail.mail",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      !_vm.modificationDisabled
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                "icon-left": "delete",
                                                size: "is-small",
                                              },
                                              on: {
                                                click: function () {
                                                  return _vm.kitchen.supply.delivery_mails_cc.splice(
                                                    index,
                                                    1
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Remove")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        { attrs: { label: "Secrets", icon: "lock" } },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "title is-6",
                              staticStyle: { "text-align": "center" },
                            },
                            [_vm._v(" Passwords ")]
                          ),
                          _c(
                            "b-field",
                            {
                              attrs: {
                                horizontal: "",
                                label: "Manager Mail Password",
                              },
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled:
                                    _vm.modificationDisabled ||
                                    !_vm.canEditInformation(),
                                  type: "mail",
                                  placeholder: "Manager Mail Password",
                                },
                                model: {
                                  value:
                                    _vm.kitchen.secrets.password_mail_manager,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.secrets,
                                      "password_mail_manager",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.secrets.password_mail_manager",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            {
                              attrs: {
                                horizontal: "",
                                label: "Cook Mail Password",
                              },
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled:
                                    _vm.modificationDisabled ||
                                    !_vm.canEditInformation(),
                                  type: "mail",
                                  placeholder: "Cook Mail Password",
                                },
                                model: {
                                  value: _vm.kitchen.secrets.password_mail_cook,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.secrets,
                                      "password_mail_cook",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.secrets.password_mail_cook",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            {
                              attrs: {
                                horizontal: "",
                                label: "Deliveroo BackOffice Password",
                              },
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled:
                                    _vm.modificationDisabled ||
                                    !_vm.canEditInformation(),
                                  type: "mail",
                                  placeholder: "Deliveroo BackOffice Password",
                                },
                                model: {
                                  value:
                                    _vm.kitchen.secrets
                                      .password_deliveroo_back_office,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.secrets,
                                      "password_deliveroo_back_office",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.secrets.password_deliveroo_back_office",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            {
                              attrs: {
                                horizontal: "",
                                label: "One Tablet Pin",
                              },
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  type: "text",
                                  placeholder: "One Tablet Pin",
                                },
                                model: {
                                  value: _vm.kitchen.secrets.pin_one_tablet,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.secrets,
                                      "pin_one_tablet",
                                      $$v
                                    )
                                  },
                                  expression: "kitchen.secrets.pin_one_tablet",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            {
                              attrs: {
                                horizontal: "",
                                label: "Ops Base Password",
                              },
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  type: "text",
                                  placeholder: "Ops Base Password",
                                },
                                model: {
                                  value: _vm.kitchen.secrets.password_opsbase,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.secrets,
                                      "password_opsbase",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.secrets.password_opsbase",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        { attrs: { label: "Costs", icon: "credit-card" } },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                label: "Taster Commission",
                                expanded: "",
                              },
                            },
                            [
                              _c("b-numberinput", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  type: "number",
                                  placeholder: "Taster Commission",
                                },
                                model: {
                                  value:
                                    _vm.kitchen.financial_informations
                                      .taster_commission,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.financial_informations,
                                      "taster_commission",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.financial_informations.taster_commission",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            {
                              attrs: {
                                label: "Monthly Fixed Costs",
                                expanded: "",
                              },
                            },
                            [
                              _c("b-numberinput", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  type: "number",
                                  placeholder: "Monthly Fixed Costs",
                                },
                                model: {
                                  value:
                                    _vm.kitchen.financial_informations
                                      .fixed_costs,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.financial_informations,
                                      "fixed_costs",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.financial_informations.fixed_costs",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { label: "Deputy Kitchen Label" } },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  type: "text",
                                  placeholder: "Deputy Kitchen Label",
                                },
                                model: {
                                  value:
                                    _vm.kitchen.financial_informations
                                      .deputy_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.financial_informations,
                                      "deputy_id",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.financial_informations.deputy_id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        { attrs: { label: "Detail", icon: "attachment" } },
                        [
                          _c(
                            "b-field",
                            { attrs: { label: "Building Code", expanded: "" } },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  type: "text",
                                  placeholder: "Building Code",
                                },
                                model: {
                                  value:
                                    _vm.kitchen.kitchen_information
                                      .building_code,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.kitchen_information,
                                      "building_code",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.kitchen_information.building_code",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { label: "Instructions" } },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  type: "text",
                                  placeholder: "Instructions for delivers",
                                },
                                model: {
                                  value:
                                    _vm.kitchen.kitchen_information
                                      .instructions,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.kitchen_information,
                                      "instructions",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.kitchen_information.instructions",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { grouped: "" } },
                            [
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Outer Box Code",
                                    expanded: "",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      disabled: _vm.modificationDisabled,
                                      type: "text",
                                      placeholder: "Outer box code",
                                    },
                                    model: {
                                      value:
                                        _vm.kitchen.kitchen_information
                                          .outer_box_code,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.kitchen.kitchen_information,
                                          "outer_box_code",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "kitchen.kitchen_information.outer_box_code",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Inner Box Code",
                                    expanded: "",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      disabled: _vm.modificationDisabled,
                                      type: "text",
                                      placeholder: "Inner box code",
                                    },
                                    model: {
                                      value:
                                        _vm.kitchen.kitchen_information
                                          .inner_box_code,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.kitchen.kitchen_information,
                                          "inner_box_code",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "kitchen.kitchen_information.inner_box_code",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        { attrs: { label: "Phone", icon: "phone-hangup" } },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "title is-6",
                              staticStyle: { "text-align": "center" },
                            },
                            [_vm._v(" Communication Channels ")]
                          ),
                          _c(
                            "b-field",
                            { attrs: { horizontal: "", label: "Phone" } },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  type: "text",
                                  placeholder: "Kitchen Phone",
                                },
                                model: {
                                  value:
                                    _vm.kitchen.communication_channels.phone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.communication_channels,
                                      "phone",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.communication_channels.phone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            {
                              attrs: { horizontal: "", label: "Slack Channel" },
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  type: "text",
                                  placeholder: "Slack Channel",
                                },
                                model: {
                                  value:
                                    _vm.kitchen.communication_channels
                                      .slack_channel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.communication_channels,
                                      "slack_channel",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.communication_channels.slack_channel",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            { attrs: { horizontal: "", label: "Slack ID" } },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: _vm.modificationDisabled,
                                  type: "text",
                                  placeholder: "Slack ID",
                                },
                                model: {
                                  value:
                                    _vm.kitchen.communication_channels.slack_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kitchen.communication_channels,
                                      "slack_id",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kitchen.communication_channels.slack_id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        { attrs: { label: "QR Codes", icon: "qrcode" } },
                        [
                          _c(
                            "div",
                            [
                              _c("b-table", {
                                attrs: {
                                  data: _vm.kitchen.qr_codes,
                                  hoverable: "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        _c(
                                          "b-table-column",
                                          {
                                            attrs: {
                                              field: "QR Code",
                                              label: "QR Code",
                                            },
                                          },
                                          [
                                            _c("b-button", {
                                              attrs: {
                                                tag: "a",
                                                "icon-left": "qrcode",
                                                href: props.row.qr_code_path,
                                                target: "_blank",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-table-column",
                                          {
                                            attrs: {
                                              field: "Order Type",
                                              label: "Order Type",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(props.row.order_type) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-table-column",
                                          {
                                            attrs: {
                                              field: "Language",
                                              label: "Language",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(props.row.locale) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.showQrCodeUrl
                                          ? _c(
                                              "b-table-column",
                                              {
                                                attrs: {
                                                  field: "Url",
                                                  label: "Url",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(props.row.url) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-table-column",
                                          {
                                            attrs: {
                                              field: "ButtonUrl",
                                              label: "ButtonUrl",
                                            },
                                          },
                                          [
                                            !_vm.showQrCodeUrl
                                              ? _c("b-button", {
                                                  attrs: {
                                                    label: "Show URL",
                                                    size: "is-small",
                                                  },
                                                  on: {
                                                    click: function () {
                                                      return (_vm.showQrCodeUrl =
                                                        !_vm.showQrCodeUrl)
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.showQrCodeUrl
                                              ? _c("b-button", {
                                                  attrs: {
                                                    label: "Hide URL",
                                                    size: "is-small",
                                                  },
                                                  on: {
                                                    click: function () {
                                                      return (_vm.showQrCodeUrl =
                                                        !_vm.showQrCodeUrl)
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "b-tab-item",
                        { attrs: { label: "Management", icon: "worker" } },
                        [
                          _c(
                            "b-field",
                            { attrs: { grouped: "" } },
                            [
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Sales Manager",
                                    expanded: "",
                                  },
                                },
                                [
                                  !_vm.modificationDisabled
                                    ? _c("b-autocomplete", {
                                        attrs: {
                                          rounded: "",
                                          field: "name",
                                          placeholder: "Select Sales Manager",
                                          icon: "magnify",
                                          clearable: "",
                                          data: _vm.filteredUsersSales,
                                          "open-on-focus": true,
                                          "clear-on-select": true,
                                        },
                                        on: {
                                          select: function (option) {
                                            return _vm.selectUser(option, "SM")
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "empty",
                                              fn: function () {
                                                return [
                                                  _vm._v("No results found"),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          478364837
                                        ),
                                        model: {
                                          value: _vm.salesManagerLabel,
                                          callback: function ($$v) {
                                            _vm.salesManagerLabel = $$v
                                          },
                                          expression: "salesManagerLabel",
                                        },
                                      })
                                    : _c("b-input", {
                                        attrs: {
                                          disabled: _vm.modificationDisabled,
                                          type: "text",
                                          placeholder: "Sales Manager Label",
                                          expanded: "",
                                        },
                                        model: {
                                          value: _vm.salesManagerLabel,
                                          callback: function ($$v) {
                                            _vm.salesManagerLabel = $$v
                                          },
                                          expression: "salesManagerLabel",
                                        },
                                      }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Account Manager",
                                    expanded: "",
                                  },
                                },
                                [
                                  !_vm.modificationDisabled
                                    ? _c("b-autocomplete", {
                                        attrs: {
                                          rounded: "",
                                          field: "name",
                                          placeholder: "Select Account Manager",
                                          icon: "magnify",
                                          clearable: "",
                                          data: _vm.filteredUsersAccount,
                                          "open-on-focus": true,
                                          "clear-on-select": true,
                                        },
                                        on: {
                                          select: function (option) {
                                            return _vm.selectUser(option, "AM")
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "empty",
                                              fn: function () {
                                                return [
                                                  _vm._v("No results found"),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          478364837
                                        ),
                                        model: {
                                          value: _vm.accountManagerLabel,
                                          callback: function ($$v) {
                                            _vm.accountManagerLabel = $$v
                                          },
                                          expression: "accountManagerLabel",
                                        },
                                      })
                                    : _c("b-input", {
                                        attrs: {
                                          disabled: _vm.modificationDisabled,
                                          type: "text",
                                          placeholder: "Account Manager Label",
                                          expanded: "",
                                        },
                                        model: {
                                          value: _vm.accountManagerLabel,
                                          callback: function ($$v) {
                                            _vm.accountManagerLabel = $$v
                                          },
                                          expression: "accountManagerLabel",
                                        },
                                      }),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Operation Manager",
                                    expanded: "",
                                  },
                                },
                                [
                                  !_vm.modificationDisabled
                                    ? _c("b-autocomplete", {
                                        attrs: {
                                          rounded: "",
                                          field: "name",
                                          placeholder:
                                            "Select Operation Manager",
                                          icon: "magnify",
                                          clearable: "",
                                          data: _vm.filteredUsersOperation,
                                          "open-on-focus": true,
                                          "clear-on-select": true,
                                        },
                                        on: {
                                          select: function (option) {
                                            return _vm.selectUser(option, "OM")
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "empty",
                                              fn: function () {
                                                return [
                                                  _vm._v("No results found"),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          478364837
                                        ),
                                        model: {
                                          value: _vm.operationManagerLabel,
                                          callback: function ($$v) {
                                            _vm.operationManagerLabel = $$v
                                          },
                                          expression: "operationManagerLabel",
                                        },
                                      })
                                    : _c("b-input", {
                                        attrs: {
                                          disabled: _vm.modificationDisabled,
                                          type: "text",
                                          placeholder:
                                            "Operation Manager Label",
                                          expanded: "",
                                        },
                                        model: {
                                          value: _vm.operationManagerLabel,
                                          callback: function ($$v) {
                                            _vm.operationManagerLabel = $$v
                                          },
                                          expression: "operationManagerLabel",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-field",
                            {
                              staticStyle: { "justify-content": "center" },
                              attrs: { label: "Category", expanded: "" },
                            },
                            [
                              _c(
                                "b-select",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled: _vm.modificationDisabled,
                                    placeholder: "Category",
                                    expanded: "",
                                  },
                                  model: {
                                    value:
                                      _vm.kitchen.management_information
                                        .category,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.kitchen.management_information,
                                        "category",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "kitchen.management_information.category",
                                  },
                                },
                                _vm._l(
                                  _vm.kitchenCategories,
                                  function (kitchenCategory, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: kitchenCategory },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(kitchenCategory) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "footer",
                { staticClass: "card-foot buttons is-right" },
                [
                  _vm.modificationDisabled === false
                    ? _c(
                        "b-button",
                        {
                          attrs: { type: "button is-small is-outlined" },
                          on: { click: _vm.update },
                        },
                        [_vm._v(_vm._s(_vm.$t("kitchens.update")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { type: "button is-danger is-small is-outlined" },
                      on: { click: _vm.close },
                    },
                    [_vm._v(_vm._s(_vm.$t("kitchens.close")))]
                  ),
                ],
                1
              ),
              _vm.saveMessage
                ? _c(
                    "p",
                    {
                      staticClass:
                        "is-size-7 is-italic has-text-right has-text-weight-light has-text-danger",
                    },
                    [_vm._v(_vm._s(_vm.saveMessage))]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }