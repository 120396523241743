import conceptsAPI from '../../../apis/kitchen/concepts';
import { conceptsTypes } from '../../mutation-types.js'

/* eslint-disable */

const state = {
  all: [],
  selected: [],
  current: null,
  conceptCategories: ["Burger", "Asian"],
  schema: {},
  current_error: '',
  fetchAll: {
    pending: false,
    error: null,
  },
  fetchOne: {
    pending: false,
    error: null,
  },
  newConceptEmpty: {
    category: '',
    id: '',
    is_active: false,
    label: '',
    logo: '',
  },
  newConcept: {
    category: '',
    id: '',
    is_active: false,
    label: '',
    logo: '',
  },
  newPicture: {
    picture_type: '',
    picture: '',
    platform_uuid: '',
  }
};

const getters = {
  getNewConcept: state => {
    return state.newConcept;
  },
  getConcept: state => id => {
    return state.all.find(concept => concept.uuid === id)
  },
  getConceptLabels: state => {
    return state.all.map(value => value.label);
  },
  getActiveConcepts: state => {
    return state.all.filter(concept => !concept.is_deleted)
  },
  getInactiveConcepts: state => {
    return state.all.filter(concept => concept.is_deleted)
  },
  getConceptSchema: state => {
    return state.schema.components.schemas.ConceptRequest.properties
  },
  getConceptCategories: state => {
    return state.conceptCategories;
  },
  getNewPicture: state => {
    return state.newPicture;
  },
}

const actions = {
  async getConcepts({ commit }, queryParameters) {
    commit(conceptsTypes.FETCH_ALL_REQUEST);
    return conceptsAPI.getConcepts(queryParameters)
    .then(function (concepts) {
      commit(conceptsTypes.SET_CONCEPTS, concepts)
    })
    .catch(function (error) {
      commit(conceptsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  async fetchAll({ commit }, opts) {
    commit(conceptsTypes.FETCH_ALL_REQUEST);

    return conceptsAPI.fetchAll(opts)
      .then((concepts) => commit(conceptsTypes.FETCH_ALL_SUCCESS, concepts))
      .catch((error) => commit(conceptsTypes.FETCH_ALL_FAILURE, error));
  },
  async fetchOne({ commit }, conceptUUID) {
    commit(conceptsTypes.FETCH_ONE_REQUEST);

    return conceptsAPI
      .fetchOne(conceptUUID)
      .then(function(concept) {
        commit(conceptsTypes.FETCH_ONE_SUCCESS, concept);
      })
      .catch(function(error) {
        commit(conceptsTypes.FETCH_ONE_FAILURE, error.response.data.detail);
      });
  },
  async createConcept({ commit }, payload) {
    return conceptsAPI.createConcept(payload)
    .then(function (concept) {
      commit(conceptsTypes.CREATE_CONCEPT, concept)
    })
    .catch(function (error) {
      commit(conceptsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  resetNewConcept({ commit }) {
    commit(conceptsTypes.RESET_NEW_CONCEPT, '')
  },
  async updateConcept({ commit, dispatch }, payload) {
    return conceptsAPI.updateConcept(payload)
    .then(function (concept) {
      commit(conceptsTypes.UPDATE_CONCEPT, concept)
    })
    .catch(function (error) {
      commit(conceptsTypes.SET_CURRENT_ERROR, error.response.data.detail)
      dispatch('getConcepts')
    })
  },
  async updateConceptPicture({ commit, dispatch }, payload) {
    return conceptsAPI.updateConceptPicture(payload)
    .then(function (concept) {
      commit(conceptsTypes.UPDATE_CONCEPT, concept)
    })
    .catch(function (error) {
      commit(conceptsTypes.SET_CURRENT_ERROR, error.response.data.detail)
      dispatch('getConcepts')
    })
  },
  async getSchema({ commit }) {
    return conceptsAPI.getSchema()
    .then(function (schema) {
      commit(conceptsTypes.SET_SCHEMA, schema)
    })
    .catch(function (error) {
      commit(conceptsTypes.SET_CURRENT_ERROR, error.response.data.detail)
    })
  },
  resetCurrentError({ commit }) {
    commit(conceptsTypes.SET_CURRENT_ERROR, '')
  },
}

const mutations = {
  [conceptsTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [conceptsTypes.FETCH_ALL_SUCCESS](state, items) {
    state.all = items;
    state.fetchAll.pending = false;
  },
  [conceptsTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
  [conceptsTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [conceptsTypes.FETCH_ONE_SUCCESS](state, concept) {
    state.current = concept;
    const index = state.all.findIndex((item) => item.uuid === concept.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, concept);
    }
    state.fetchOne.pending = false;
    state.fetchOne.error = null;
  },
  [conceptsTypes.FETCH_ONE_FAILURE](state, error) {
    state.current = null;
    state.fetchOne.pending = false;
    state.fetchOne.error = error;
  },
  [conceptsTypes.SET_CONCEPTS] (state, concepts) {
    state.all = concepts
    state.selected = [...concepts];
    state.fetchAll.pending = false;
  },
  [conceptsTypes.CREATE_CONCEPT] (state, concept) {
    state.all.push(concept)
  },
  [conceptsTypes.RESET_NEW_CONCEPT] (state) {
    state.newConcept = Object.assign({}, state.newConceptEmpty);
  },
  [conceptsTypes.UPDATE_CONCEPT] (state, concept) {
    const index = state.all.findIndex(item => item.id === concept.id);
    if (index !== -1) {
      state.all.splice(index, 1, concept);
    };
  },
  [conceptsTypes.ACTIVATE_CONCEPT] (state, conceptUUID) {
    const index = state.all.findIndex(item => item.uuid === conceptUUID);
    if (index !== -1) {
      state.all[index]["is_active"] = true;
    };
  },
  [conceptsTypes.DEACTIVATE_CONCEPT] (state, conceptUUID) {
    const index = state.all.findIndex(item => item.uuid === conceptUUID);
    if (index !== -1) {
      state.all[index]["is_active"] = false;
    };
  },
  [conceptsTypes.SET_CURRENT_ERROR] (state, error) {
    state.current_error = error
    },
  [conceptsTypes.SET_SCHEMA] (state, schema) {
    state.schema = schema
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
