import axios from '@/axios';
import { baseOnboardingUrl } from '@/apis'


const ressourcePath = `${baseOnboardingUrl}/task_groups`;

export default {
/* eslint-disable */
  createOne(opts) {
    return axios.post(ressourcePath, opts)
                .then(response => response.data)
  },
  updateOne(taskGroupUUID, opts) {
    return axios.patch(`${ressourcePath}/${taskGroupUUID}`, opts)
                .then(response => response.data)
  },
  deleteOne(taskGroupUUID) {
    return axios.delete(`${ressourcePath}/${taskGroupUUID}`)
                .then(response => response.data)
  },
  fetchOne(taskGroupUUID) {
    return axios.get(`${ressourcePath}/${taskGroupUUID}`)
                .then(response => response.data)
  },
  fetchAll(queryParameters) {
    if (!queryParameters) {
      queryParameters = '';
    }
    return axios.get(`${ressourcePath}${queryParameters}`)
                .then(response => response.data)
  },
};
