var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section" },
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("div", [
        _c("h1", { staticClass: "title has-text-dark" }, [
          _c("div", { staticClass: "columns is-mobile" }, [
            _c("div", { staticClass: "column is-narrow" }, [
              _vm._v(" Marketing "),
            ]),
            _c("div", { staticClass: "column" }, [
              _c(
                "button",
                {
                  staticClass: "add-button button is-link",
                  on: {
                    click: function ($event) {
                      return _vm.openActionsModal()
                    },
                  },
                },
                [_vm._v(" Actions ")]
              ),
            ]),
            _c("div"),
          ]),
        ]),
        _c(
          "section",
          [
            _c("CampaignFilters", {
              attrs: {
                activeConcepts: _vm.availableConceptsFilters,
                activeCountries: _vm.availableCountriesFilters,
                activeLocations: _vm.availableLocationsFilters,
                activePlatforms: _vm.availablePlatformsFilters,
                campaignTimelines: _vm.campaignTimelines,
                campaignTypes: _vm.availableCampaignTypesFilters,
                selectedConcept: _vm.selectedConcept,
                selectedCountry: _vm.selectedCountry,
                selectedDates: _vm.selectedDates,
                selectedLocation: _vm.selectedLocation,
                selectedPlatform: _vm.selectedPlatform,
                selectedCampaignTimelines: _vm.selectedCampaignTimelines,
                selectedCampaignType: _vm.selectedCampaignType,
                displayLocationFilter: _vm.displayLocationFilter,
              },
              on: {
                "select-concept": _vm.selectConcept,
                "select-country": _vm.selectCountry,
                "select-dates": _vm.selectDates,
                "select-location": _vm.selectLocation,
                "select-platform": _vm.selectPlatform,
                "select-campaign-timelines": _vm.selectCampaignTimelines,
                "select-campaign-type": _vm.selectCampaignType,
                refresh: _vm.refresh,
              },
            }),
            _c("hr"),
            (_vm.campaigns || _vm.kitchenCampaigns) &&
            _vm.locations &&
            _vm.locations.length > 0
              ? _c(
                  "section",
                  [
                    _c(
                      "b-tabs",
                      {
                        attrs: { type: "is-toggle", expanded: "" },
                        model: {
                          value: _vm.activeTab,
                          callback: function ($$v) {
                            _vm.activeTab = $$v
                          },
                          expression: "activeTab",
                        },
                      },
                      [
                        _c(
                          "b-tab-item",
                          { attrs: { label: "Campaigns", icon: "view-list" } },
                          [
                            _vm.isMounted && !_vm.isLoading
                              ? _c("CampaignTable", {
                                  attrs: {
                                    concepts: _vm.activeConceptsSorted,
                                    countries: _vm.activeCountriesSorted,
                                    platforms: _vm.activePlatformsSorted,
                                    perPage: _vm.perPage,
                                    page: _vm.page,
                                  },
                                  on: {
                                    changePage: _vm.changePage,
                                    openNew: _vm.openCampaignModal,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "b-tab-item",
                          {
                            attrs: { label: "Kitchen Campaigns", icon: "home" },
                          },
                          [
                            _c(
                              "section",
                              [
                                _c(
                                  "b-tabs",
                                  {
                                    attrs: {
                                      type: "is-toggle",
                                      expanded: "",
                                      "destroy-on-hide": "",
                                    },
                                    model: {
                                      value: _vm.activeTabBaseState,
                                      callback: function ($$v) {
                                        _vm.activeTabBaseState = $$v
                                      },
                                      expression: "activeTabBaseState",
                                    },
                                  },
                                  _vm._l(
                                    _vm.baseStates,
                                    function (baseState, index) {
                                      return _c(
                                        "b-tab-item",
                                        {
                                          key: index,
                                          attrs: {
                                            label:
                                              _vm.getKitchenCampaignTabLabel(
                                                baseState
                                              ),
                                          },
                                        },
                                        [
                                          _vm.isMounted && !_vm.isLoading
                                            ? _c("KitchenCampaignTable", {
                                                key: _vm.refreshKitchenCampaignTableIndex,
                                                attrs: {
                                                  baseState: baseState,
                                                  concepts:
                                                    _vm.activeConceptsSorted,
                                                  countries:
                                                    _vm.activeCountriesSorted,
                                                  platforms:
                                                    _vm.activePlatformsSorted,
                                                  perPage:
                                                    _vm.perPageKitchenCampaign,
                                                  page: _vm.pageKitchenCampaign,
                                                },
                                                on: {
                                                  changePage:
                                                    _vm.changePageKitchenCampaign,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("hr"),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }