<template>
  <div>
    <div class="has-text-centered">
      <b-button type="is-link" class="button-gap" @click="createKitchenForm()" expanded>
        Create New Kitchen
      </b-button>
    </div>
    <div class="has-text-centered">
      <b-button type="is-info" class="button-gap"  @click="selectExistingKitchenForm()" expanded>
        Select Existing Kitchen
      </b-button>
    </div>
    <div class="has-text-centered">
      <b-button type="is-warning" class="button-gap"  @click="createExternalKitchenForm()" expanded>
        Create New Kitchen (Session)
      </b-button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: ['activeStep', 'newKitchen', 'newExternalKitchen'],
    computed: {},
    methods: {
      createKitchenForm() {
        this.$emit('update:activeStep', 1);
        this.$emit('update:newKitchen', true);
      },
      selectExistingKitchenForm() {
        this.$emit('update:newKitchen', false);
        this.$emit('update:activeStep', 1);
      },
      createExternalKitchenForm() {
        this.$emit('update:newKitchen', false);
        this.$emit('update:newExternalKitchen', true);
        this.$emit('update:activeStep', 1);
      },
      close() {
        this.$parent.close();
      },
    }
  }
</script>

<style scoped>

.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
