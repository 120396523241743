import onboardingActionsAPI from '../../../apis/supply/actions';
import { supplyActionTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  currentSpreadsheetURL: null,
  exportCatalogue: {
    pending: false,
    error: null,
  },
  importCatalogue: {
    pending: false,
    error: null,
  },
};

const actions = {
  async exportCatalogue({ commit }, payload) {
    commit(supplyActionTypes.EXPORT_CATALOGUE_ACTION_REQUEST);
    return onboardingActionsAPI
      .exportCatalogue(payload)
      .then(function(response) {
        commit(supplyActionTypes.EXPORT_CATALOGUE_ACTION_SUCCESS, response);
        return response;
      })
      .catch(function(error) {
        commit(supplyActionTypes.EXPORT_CATALOGUE_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async importCatalogue({ commit }, payload) {
    commit(supplyActionTypes.IMPORT_CATALOGUE_ACTION_REQUEST);
    return onboardingActionsAPI
      .importCatalogue(payload)
      .then(function(response) {
        commit(supplyActionTypes.IMPORT_CATALOGUE_ACTION_SUCCESS, response);
        return response;
      })
      .catch(function(error) {
        commit(supplyActionTypes.IMPORT_CATALOGUE_ACTION_FAILURE, error.response.data.detail);
      });
  },
};

const mutations = {
  [supplyActionTypes.EXPORT_CATALOGUE_ACTION_REQUEST](state) {
    state.exportCatalogue.pending = true;
  },
  [supplyActionTypes.EXPORT_CATALOGUE_ACTION_SUCCESS](state, response) {
    state.currentSpreadsheetURL = response.sheet_url;
    state.exportCatalogue.pending = false;
    state.exportCatalogue.error = null;
  },
  [supplyActionTypes.EXPORT_CATALOGUE_ACTION_FAILURE](state, error) {
    state.exportCatalogue.pending = false;
    state.exportCatalogue.error = error;
  },
  [supplyActionTypes.IMPORT_CATALOGUE_ACTION_REQUEST](state) {
    state.importCatalogue.pending = true;
  },
  [supplyActionTypes.IMPORT_CATALOGUE_ACTION_SUCCESS](state, response) {
    state.currentSpreadsheetURL = response.sheet_url;
    state.importCatalogue.pending = false;
    state.importCatalogue.error = null;
  },
  [supplyActionTypes.IMPORT_CATALOGUE_ACTION_FAILURE](state, error) {
    state.importCatalogue.pending = false;
    state.importCatalogue.error = error;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
