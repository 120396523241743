<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-modal :active.sync="openModal" scroll="keep" :on-cancel="close" full-screen>
      <section>
        <div class="card" style="width: auto">
          <div class="card-content">
            <div class="media">
              <div class="media-content">
               <p class="title is-4"> New Launch </p>
              </div>
            </div>
          </div>
          <section class="card-content">
            <b-steps v-model="activeStep" size="is-small" type="is-info" :has-navigation="false">
              <b-step-item label="Kitchen Selection" step="1">
                <hr>
                <KitchenSelectionForm :activeStep.sync="activeStep" :newKitchen.sync="newKitchen" :newExternalKitchen.sync="newExternalKitchen"/>
              </b-step-item>
              <b-step-item label="Kitchen Configuration" step="2" :visible="showStep(1)">
                <hr>
                <KitchenConfigurationForm
                  :activeStep.sync="activeStep"
                  :countries="countries"
                  :kitchens="kitchens"
                  :locations="locations"
                  :newKitchen.sync="newKitchen"
                  :newExternalKitchen.sync="newExternalKitchen"
                  @createKitchen="createKichen"
                  @selectKitchen="selectKitchen"
                />
              </b-step-item>
              <b-step-item label="Restaurant Selection" step="3" :visible="showStep(2)">
                <hr>
                <RestaurantSelectionForm :activeStep.sync="activeStep" :newRestaurants.sync="newRestaurants" :isTransfer.sync="isTransfer"/>
              </b-step-item>
              <b-step-item label="Concept Selection" step="4" :visible="showStep(3)">
                <hr>
                <ConceptSelectionForm
                  :activeStep.sync="activeStep"
                  :concepts="concepts"
                  :platforms="platforms"
                  :selectedConcept.sync="selectedConcept"
                  :newRestaurants.sync="newRestaurants"
                  :kitchen="currentKitchen"
                  @close="close"
                />
              </b-step-item>
              <b-step-item label="Platforms Selection" step="5" :visible="showStep(4)">
                <hr>
                <PlatformSelectionForm
                  :activeStep.sync="activeStep"
                  :platforms="platforms"
                  :selectedPlatforms.sync="selectedPlatforms"
                  :newRestaurants.sync="newRestaurants"
                  :kitchen="currentKitchen"
                  :country="currentCountry"
                  :selectedConcept="selectedConcept"
                  @selectPlatforms="selectPlatforms"
                  @close="close"
                />
              </b-step-item>
              <b-step-item label="Restaurant Configuration" step="6" :visible="showStep(5)">
                <hr>
                <RestaurantConfigurationForm
                  :activeStep.sync="activeStep"
                  :kitchen="currentKitchen"
                  :newRestaurants.sync="newRestaurants"
                  @createRestaurants="createRestaurants"
                />
              </b-step-item>
              <b-step-item label="Kitchen Mails Creation" step="7" :visible="showStep(6)">
                <hr>
                <KitchenMailsCreationForm
                  :activeStep.sync="activeStep"
                  :kitchen="currentKitchen"
                  :users=[]
                  @createMails="createMails"
                />
              </b-step-item>
              <b-step-item label="Team Accesses" step="8">
                <hr>
                <IAMForm
                  :kitchen="currentKitchen"
                  :launchTeams="launchTeams"
                  @selectMembers="selectMembers"
                />
              </b-step-item>
              <b-step-item label="Create Launch Process" step="9">
                <hr>
                <CreateLaunchForm
                  :kitchen="currentKitchen"
                  :selectedConcept="selectedConcept"
                  :launchTeams="launchTeams"
                  @createLaunchProcess="createLaunchProcess"
                />
              </b-step-item>
            </b-steps>
          </section>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { kitchensTypes, onboardingActionTypes } from '@/store/mutation-types';
import { errorToasterDetailled } from '@/mixins';

import KitchenSelectionForm from '@/components/Onboarding/Launch/Forms/KitchenSelectionForm.vue'
import KitchenConfigurationForm from '@/components/Onboarding/Launch/Forms/KitchenConfigurationForm.vue'
import ConceptSelectionForm from '@/components/Onboarding/Launch/Forms/ConceptSelectionForm.vue'
import IAMForm from '@/components/Onboarding/Launch/Forms/IAMForm.vue'
import PlatformSelectionForm from '@/components/Onboarding/Launch/Forms/PlatformSelectionForm.vue'
import RestaurantConfigurationForm from '@/components/Onboarding/Launch/Forms/RestaurantConfigurationForm.vue'
import RestaurantSelectionForm from '@/components/Onboarding/Launch/Forms/RestaurantSelectionForm.vue'
import KitchenMailsCreationForm from '@/components/Onboarding/Launch/Forms/KitchenMailsCreationForm.vue'
import CreateLaunchForm from '@/components/Onboarding/Launch/Forms/CreateLaunchForm.vue'

export default {
  components: {
    KitchenSelectionForm,
    KitchenConfigurationForm,
    RestaurantSelectionForm,
    ConceptSelectionForm,
    IAMForm,
    PlatformSelectionForm,
    RestaurantConfigurationForm,
    KitchenMailsCreationForm,
    CreateLaunchForm,
  },
  props: ['countries', 'concepts', 'kitchens', 'locations', 'platforms'],
  mixins: [errorToasterDetailled],
  data() {
    return {
      openModal: true,
      activeStep: 0,
      selectedConcept: null,
      selectedPlatforms: null,
      newKitchen: true,
      newExternalKitchen: false,
      newRestaurants: true,
      isTransfer: false,
      managerInformation: {'firstName': '', 'lastName': ''},
      launchSelectedTeams: [],
      displaySuccess: false,
      launchDate: null,
      tasterPlatformUUID: '36d8e8b3-5602-4d45-861e-8bff8a439ab4',
    }
  },
  computed: {
    ...mapState({
      currentKitchen: (state) => state.onboardingActions.currentKitchen,
      currentKitchenUUID: (state) => state.onboardingActions.currentKitchenUUID,
      isLoading: (state) => state.actions.createKitchen.pending || state.actions.createRestaurant.pending || state.actions.createMail.pending || state.actions.importIAMLocation.pending || state.actions.grantIAMAccess.pending || state.actions.fetchLaunchTeams.pending || state.accesses.fetchUsers.pending || state.kitchens.updateOne.pending,
      error: (state) => state.actions.createKitchen.error || state.actions.createRestaurant.error || state.actions.createMail.error || state.actions.importIAMLocation.error || state.actions.grantIAMAccess.error || state.actions.fetchLaunchTeams.error || state.accesses.fetchUsers.error || state.kitchens.updateOne.error,
      launchTeams: (state) => state.actions.launchTeams,
    }),
    currentCountry() {
      if (!this.currentKitchen) {
        return null;
      }
      else {
        if (this.currentKitchen.kitchen_country) {
          return this.currentKitchen.kitchen_country;
        }
        var location = this.locations.find(location => location.uuid == this.currentKitchen.location_uuid);
        return this.countries.find(country => country.uuid == location.country_uuid);
      }
    }
  },
  methods: {
    ...mapActions('accesses', ['fetchUsers']),
    ...mapActions('kitchens', ['getKitchen', 'updateKitchen']),
    ...mapActions('locations', ['getLocation']),
    ...mapActions('launches', ['createLaunch']),
    ...mapActions('onboardingActions', ['createKitchenAction', 'createRestaurantAction', 'createMailAction', 'fetchLaunchTeams', 'grantIAMAccessAction', 'importIAMLocationAction']),
    ...mapMutations('onboardingActions', {'setCurrentKitchen': onboardingActionTypes.SET_CURRENT_KITCHEN}),
    ...mapMutations('kitchens', {'addKitchen': kitchensTypes.ADD_KITCHEN}),
    createKichen(opts) {
      this.createKitchenAction(opts).then(() => {
        if (!this.error) {
          this.fetchLaunchTeams(this.currentKitchen.uuid)
          .then(() => this.addKitchen(this.currentKitchen))
          .then(() => {
            if (this.newExternalKitchen) {
              this.importIAMLocationAction({
                'grant_access_to_hq_team': true,
                'kitchen_uuid': this.currentKitchen.uuid,
                'selected_teams': this.launchSelectedTeams,
                'concept_uuid': null,
              }).then(() => this.$router.go({ name: 'ListOnboardings' }))
            }
            else {
              this.activeStep = 3;
            }
          })
        }
      })
    },
    selectKitchen(selectedKitchen) {
      this.setCurrentKitchen(selectedKitchen);
      this.fetchLaunchTeams(selectedKitchen.uuid);
      this.activeStep = 2;
    },
    selectPlatforms() {
      if (this.newRestaurants) {
        this.activeStep = 5;
      }
      else {
        if (this.currentKitchen.mails && this.currentKitchen.mails.mail_cook && this.currentKitchen.mails.mail_manager) {
          if (this.selectedConcept && this.selectedConcept.is_internal) {
              this.activeStep = 7;
          }
          else {
            this.fetchUsers()
            this.activeStep = 8;
          }
        }
        else {
          this.activeStep = 6;
        }
      }
    },
    createRestaurants(opts) {
      var restaurantsToCreate = [];
      this.launchDate = opts.launch_date;
      for (let platformUUID of this.selectedPlatforms) {
        if (platformUUID != this.tasterPlatformUUID) {
          restaurantsToCreate.push({
            'concept_uuid': this.selectedConcept.uuid,
            'kitchen_uuid': this.currentKitchen.uuid,
            'label': opts.label,
            'launch_date': opts.launch_date,
            'platform_uuid': platformUUID,
          })
        }
      }
      Promise.all([
        ...restaurantsToCreate.map((restaurant) => this.createRestaurantAction(restaurant)),
      ]).then(() => {
        if (this.currentKitchen.mails && this.currentKitchen.mails.mail_cook && this.currentKitchen.mails.mail_manager) {
          if (this.selectedConcept && this.selectedConcept.is_internal) {
              this.activeStep = 7;
          }
          else {
            this.fetchUsers()
            this.activeStep = 8;
          }
        }
        else {
          this.activeStep = 6;
        }
      })
    },
    createMails(opts) {
      this.managerInformation.firstName = opts.first_name;
      this.managerInformation.lastName = opts.last_name;
      this.createMail(
        opts.kitchenMail,
        'kitchen'
      ).then(() => {
        this.createMail(
          opts.managerMail,
          'manager',
          opts.first_name,
          opts.last_name
        )
      }).then(() => {
          if (this.selectedConcept && this.selectedConcept.is_internal) {
              this.activeStep = 7;
          }
          else {
            this.fetchUsers()
            this.activeStep = 8;
          }
      })
    },
    createMail(mail, mailType, firstName, lastName){
      var add_mail_to_google_franchise_group = true;
      if (this.selectedConcept && this.selectedConcept.is_internal) {
        add_mail_to_google_franchise_group = this.selectedConcept.is_internal;
      }
      var body = {
        'add_mail_to_google_franchise_group': add_mail_to_google_franchise_group,
        'mail_type': mailType,
        'mail': mail,
        'kitchen_uuid': this.currentKitchen.uuid,
      };
      if (firstName) {
        body['first_name'] = firstName;
      }
      if (lastName) {
        body['last_name'] = lastName;
      }
      if (mail) {
        return this.createMailAction(body);
      }
      else {
        var p = new Promise(function(resolve, reject) {
          if(true) { // eslint-disable-line no-constant-condition
            resolve('Success!');
          }
          else {
            reject('Failure!');
          }
        });
        return p
      }
    },
    selectMembers(launchTeams) {
      this.launchSelectedTeams = launchTeams
      this.fetchUsers()
      this.activeStep = 8
    },
    createLaunchProcess(owner, teams, management_information) {
    Promise.all([
      this.importIAMLocationAction({
          'grant_access_to_hq_team': true,
          'kitchen_uuid': this.currentKitchen.uuid,
          'selected_teams': this.launchSelectedTeams,
          'concept_uuid': this.selectedConcept.uuid,
      }),
      ]).then(
        () => Promise.all([
          this.updateKitchen(
            {
              'kitchenUUID': this.currentKitchen.uuid,
              'kitchen': {
                'management_information': management_information
              }
            }
          ),
          this.grantAccess(
            this.currentKitchen.mails.mail_manager,
            'manager',
            this.managerInformation.firstName,
            this.managerInformation.lastName,
          ),
          this.grantAccess(
            this.currentKitchen.mails.mail_cook,
            'kitchen',
            'Kitchen',
            this.currentKitchen.id,
          ),
        ])).then(
        () => {
          return this.createLaunch({
              'concept_uuid': this.selectedConcept.uuid,
              'kitchen_uuid': this.currentKitchen.uuid,
              'launch_date_max': this.getLaunchDate(this.currentKitchen, this.selectedConcept.uuid, this.selectedPlatforms, 'max'),
              'launch_date_min': this.getLaunchDate(this.currentKitchen, this.selectedConcept.uuid, this.selectedPlatforms, 'min'),
              'location_uuid': this.currentKitchen.location_uuid,
              'platform_uuids': this.selectedPlatforms,
              'owner': owner,
              'teams': teams,
              'is_transfer': this.isTransfer,
            }
          ).then((launch) => this.$router.go({ name: 'Onboarding', params: { onboardingUUID: launch.uuid } }))}
      )
    },
    grantAccess(mail, mailType, firstName, lastName) {
      var body = {
        'access_type': mailType,
        'concept_uuid': this.selectedConcept.uuid,
        'kitchen_uuid': this.currentKitchen.uuid,
        'mail': mail,
      };
      if (firstName) {
        body['first_name'] = firstName;
      }
      if (lastName) {
        body['last_name'] = lastName;
      }
      if (mail) {
        return this.grantIAMAccessAction(body);
      }
      else {
        var p = new Promise(function(resolve, reject) {
          if(true) { // eslint-disable-line no-constant-condition
            resolve('Success!');
          }
          else {
            reject('Failure!');
          }
        });
        return p
      }
    },
    showStep(stepNumber) {
      if (this.activeStep == stepNumber || this.activeStep == stepNumber + 1 || this.activeStep == stepNumber + 2) {
        return true;
      }
      return false;
    },
    getLaunchDate(kitchen, conceptUUID, platformUUIDs, operator) {
      if (this.launchDate) {
        return this.launchDate;
      }
      if (kitchen && kitchen.restaurants) {
        var restaurantLaunchDates = kitchen.restaurants.filter(restaurant => restaurant.concept_uuid == conceptUUID && platformUUIDs.includes(restaurant.platform_uuid))
                                                       .map(restaurant => new Date(restaurant.launch_date));
        restaurantLaunchDates = restaurantLaunchDates.sort(function(d1, d2){return d2-d1;});
        if (restaurantLaunchDates) {
          var date = null;
          if (operator == 'max') {
            date = restaurantLaunchDates[0]
          }
          if (operator == 'min') {
            date = restaurantLaunchDates[restaurantLaunchDates.length -1]
          }
          return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
        }
      }
      return kitchen.launch_date;
    },
    close() {
      if (this.creationModal) {
        this.$emit('closed', {});
      }
      else{
        this.$emit('closed', {});
      }
      this.$parent.close();
    },
  }
}
</script>


<style>

.my-modal .modal-close {
  background: red;
}

</style>
