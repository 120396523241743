var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "has-text-centered" },
    [
      _c("h4", { staticClass: "title has-text-centered is-4" }, [
        _vm._v("Select Concepts"),
      ]),
      _c(
        "b-field",
        { attrs: { grouped: "" } },
        [
          _c(
            "b-field",
            [
              _c(
                "b-select",
                {
                  attrs: {
                    rounded: "",
                    type: "text",
                    placeholder: "Concept Type",
                    expanded: "",
                  },
                  on: {
                    input: function (option) {
                      return _vm.selectConceptTypes(option)
                    },
                  },
                },
                _vm._l(_vm.conceptTypes, function (type, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: type } },
                    [_vm._v(" " + _vm._s(type) + " ")]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { expanded: "" } },
            [
              _c("b-autocomplete", {
                attrs: {
                  rounded: "",
                  expanded: "",
                  data: _vm.filteredConcepts,
                  "clear-on-select": true,
                  placeholder: "Select Concept",
                  icon: "magnify",
                  field: "label",
                  "append-to-body": true,
                  "open-on-focus": true,
                },
                on: {
                  select: function (option) {
                    return _vm.selectConcept(option)
                  },
                },
                model: {
                  value: _vm.searchTermConcept,
                  callback: function ($$v) {
                    _vm.searchTermConcept = $$v
                  },
                  expression: "searchTermConcept",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            [
              _c(
                "b-button",
                {
                  attrs: {
                    size: "is-small",
                    outlined: "",
                    rounded: "",
                    type: "is-info",
                  },
                  on: { click: _vm.selectAllConcepts },
                },
                [_vm._v(" Select All ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-field",
        { attrs: { label: "", position: "is-centered" } },
        [
          _c(
            "b-taglist",
            _vm._l(_vm.userConcepts, function (concept, index) {
              return _c(
                "b-tag",
                {
                  key: index,
                  attrs: {
                    type: "is-info",
                    closable: "",
                    "close-icon": "delete",
                    "close-type": "is-danger",
                  },
                  on: {
                    close: function ($event) {
                      return _vm.selectConcept(concept)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(concept.label) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("h4", { staticClass: "title has-text-centered is-4" }, [
        _vm._v("Select Kitchens"),
      ]),
      _c(
        "b-field",
        { attrs: { grouped: "" } },
        [
          _c(
            "b-field",
            [
              _c(
                "b-select",
                {
                  attrs: {
                    rounded: "",
                    type: "text",
                    placeholder: "Country",
                    expanded: "",
                  },
                  on: {
                    input: function (option) {
                      return _vm.selectCountryLocations(option)
                    },
                  },
                },
                _vm._l(_vm.countriesSorted, function (country, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: country } },
                    [_vm._v(" " + _vm._s(country.label) + " ")]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { expanded: "" } },
            [
              _c("b-autocomplete", {
                attrs: {
                  rounded: "",
                  data: _vm.filteredLocations,
                  "clear-on-select": true,
                  placeholder: "Select Kitchen",
                  icon: "magnify",
                  field: "label",
                  "append-to-body": true,
                  "open-on-focus": true,
                },
                on: {
                  select: function (option) {
                    return _vm.selectLocation(option)
                  },
                },
                model: {
                  value: _vm.searchTermLocation,
                  callback: function ($$v) {
                    _vm.searchTermLocation = $$v
                  },
                  expression: "searchTermLocation",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            [
              _c(
                "b-button",
                {
                  attrs: {
                    size: "is-small",
                    outlined: "",
                    rounded: "",
                    type: "is-info",
                  },
                  on: { click: _vm.selectAllLocations },
                },
                [_vm._v(" Select All ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-field",
        { attrs: { label: "", position: "is-centered" } },
        [
          _c(
            "b-taglist",
            _vm._l(_vm.userLocations, function (location, index) {
              return _c(
                "b-tag",
                {
                  key: index,
                  attrs: {
                    type: "is-info",
                    closable: "",
                    "close-icon": "delete",
                    "close-type": "is-danger",
                  },
                  on: {
                    close: function ($event) {
                      return _vm.selectLocation(location)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(location.label) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("hr", {
        attrs: {
          disabled:
            _vm.selectedConcepts.length == 0 &&
            _vm.selectedLocations.length == 0,
        },
      }),
      _c(
        "b-button",
        {
          attrs: {
            disabled:
              _vm.selectedConcepts.length == 0 &&
              _vm.selectedLocations.length == 0,
            type: "button is-success",
            expanded: "",
          },
          on: {
            click: function ($event) {
              return _vm.selectRights()
            },
          },
        },
        [_vm._v(" Select ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }