import taskGroupsAPI from '../../../apis/onboarding/taskGroups';
import { taskGroupsTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  current: null,
  currentTaskGroupTasks: null,
  current_error: '',
  fetchAll: {
    pending: false,
    error: null,
  },
  fetchOne: {
    pending: false,
    error: null,
  },
  createOne: {
    pending: false,
    error: null,
  },
  updateOne: {
    pending: false,
    error: null,
  },
  deleteOne: {
    pending: false,
    error: null,
  },
  newTaskGroup: {
    'is_required': true,
    'label': '',
    'launch_conditions': [],
  },
  newTaskGroupEmpty: {
    'is_required': true,
    'label': '',
    'launch_conditions': [],
  },
};

const actions = {
  async fetchAll({ commit }, queryParameters) {
    commit(taskGroupsTypes.FETCH_ALL_REQUEST);

    return taskGroupsAPI.fetchAll(queryParameters)
      .then((taskGroups) => commit(taskGroupsTypes.FETCH_ALL_SUCCESS, taskGroups))
      .catch((error) => commit(taskGroupsTypes.FETCH_ALL_FAILURE, error));
  },
  async fetchOne({ commit }, taskGroupUUID) {
    commit(taskGroupsTypes.FETCH_ONE_REQUEST);

    return taskGroupsAPI
      .fetchOne(taskGroupUUID)
      .then(function(taskGroup) {
        commit(taskGroupsTypes.FETCH_ONE_SUCCESS, taskGroup);
      })
      .catch(function(error) {
        commit(taskGroupsTypes.FETCH_ONE_FAILURE, error.response.data.detail);
      });
  },
  async createOne({ commit }, payload) {
    commit(taskGroupsTypes.CREATE_ONE_REQUEST);
    return taskGroupsAPI
      .createOne(payload)
      .then(function(taskGroup) {
        commit(taskGroupsTypes.CREATE_ONE_SUCCESS, taskGroup);
      })
      .catch(function(error) {
        commit(taskGroupsTypes.CREATE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async updateOne({ commit }, payload) {
    var newPayload = {...payload}
    var taskGroupUUID = payload.uuid;
    delete newPayload.uuid;
    commit(taskGroupsTypes.UPDATE_ONE_REQUEST);
    return taskGroupsAPI
      .updateOne(taskGroupUUID, newPayload)
      .then(function(taskGroup) {
        commit(taskGroupsTypes.UPDATE_ONE_SUCCESS, taskGroup);
      })
      .catch(function(error) {
        commit(taskGroupsTypes.UPDATE_ONE_FAILURE, error.response.data.detail);
      });
  },
  async deleteOne({ commit }, taskGroupUUID) {
    commit(taskGroupsTypes.DELETE_ONE_REQUEST);

    return taskGroupsAPI
      .deleteOne(taskGroupUUID)
      .then(function() {
        commit(taskGroupsTypes.DELETE_ONE_SUCCESS, taskGroupUUID);
      })
      .catch(function(error) {
        commit(taskGroupsTypes.DELETE_ONE_FAILURE, error.response.data.detail);
      });
  },
  resetNewTaskGroup({ commit }) {
    commit(taskGroupsTypes.RESET_NEW_TASK_GROUP);
  },
};

const mutations = {
  [taskGroupsTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [taskGroupsTypes.FETCH_ALL_SUCCESS](state, taskGroups) {
    state.all = taskGroups;
    state.fetchAll.pending = false;
  },
  [taskGroupsTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
  [taskGroupsTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [taskGroupsTypes.FETCH_ONE_SUCCESS](state, taskGroup) {
    state.current = taskGroup;
    const index = state.all.findIndex((item) => item.uuid === taskGroup.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, taskGroup);
    }
    state.fetchOne.pending = false;
    state.fetchOne.error = null;
  },
  [taskGroupsTypes.FETCH_ONE_FAILURE](state, error) {
    state.current = null;
    state.fetchOne.pending = false;
    state.fetchOne.error = error;
  },
  [taskGroupsTypes.CREATE_ONE_REQUEST](state) {
    state.createOne.pending = true;
  },
  [taskGroupsTypes.CREATE_ONE_SUCCESS](state, taskGroup) {
    state.current = taskGroup;
    state.all.push(taskGroup);
    state.createOne.pending = false;
    state.createOne.error = null;
  },
  [taskGroupsTypes.CREATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.createOne.pending = false;
    state.createOne.error = error;
  },
  [taskGroupsTypes.UPDATE_ONE_REQUEST](state) {
    state.updateOne.pending = true;
  },
  [taskGroupsTypes.UPDATE_ONE_SUCCESS](state, taskGroup) {
    state.current = taskGroup;
    const index = state.all.findIndex((item) => item.uuid === taskGroup.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, taskGroup);
    }
    state.updateOne.pending = false;
    state.updateOne.error = null;
  },
  [taskGroupsTypes.UPDATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.updateOne.pending = false;
    state.updateOne.error = error;
  },
  [taskGroupsTypes.DELETE_ONE_REQUEST](state) {
    state.deleteOne.pending = true;
  },
  [taskGroupsTypes.DELETE_ONE_SUCCESS](state, taskGroupUUID) {
    const index = state.all.findIndex((taskGroup) => taskGroup.uuid === taskGroupUUID);
    if (index !== -1) {
      state.all.splice(index, 1);
    }
    state.deleteOne.pending = false;
    state.deleteOne.error = null;
  },
  [taskGroupsTypes.DELETE_ONE_FAILURE](state, error) {
    state.deleteOne.pending = false;
    state.deleteOne.error = error;
  },
  [taskGroupsTypes.SET_CURRENT_TASKS](state, tasks) {
    state.currentTaskGroupTasks = tasks;
  },
  [taskGroupsTypes.RESET_NEW_TASK_GROUP](state) {
    state.newTaskGroup = Object.assign({}, state.newTaskGroupEmpty);
  },
};

const getters = {
  getNewTaskGroup: (state) => {
    return state.newTaskGroup;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
