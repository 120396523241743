var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.openModal,
            scroll: "keep",
            "on-cancel": _vm.close,
            "full-screen": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.openModal = $event
            },
          },
        },
        [
          _c("section", [
            _c("div", { staticClass: "card", staticStyle: { width: "auto" } }, [
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "columns is-flex" }, [
                  _c(
                    "div",
                    { staticClass: "column is-narrow has-text-centered" },
                    [
                      _c("p", { staticClass: "title is-4" }, [
                        _vm._v(" Calendar"),
                      ]),
                    ]
                  ),
                  _vm.currentSelection.concept
                    ? _c("div", { staticClass: "column is-narrow" }, [
                        _c("img", {
                          staticClass: "concept-logo",
                          attrs: {
                            src: _vm.currentSelection.concept.logo,
                            alt: _vm.currentSelection.concept.label,
                            onerror: "this.style.display='none'",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.currentSelection.platform
                    ? _c("div", { staticClass: "column is-narrow" }, [
                        _c("img", {
                          staticClass: "concept-logo",
                          attrs: {
                            src: _vm.currentSelection.platform.logo,
                            alt: _vm.currentSelection.platform.label,
                            onerror: "this.style.display='none'",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c(
                "section",
                { staticClass: "card-content" },
                [
                  _c(
                    "b-steps",
                    {
                      attrs: {
                        size: "is-small",
                        type: "is-info",
                        clickable: false,
                        "has-navigation": false,
                      },
                      model: {
                        value: _vm.step,
                        callback: function ($$v) {
                          _vm.step = $$v
                        },
                        expression: "step",
                      },
                    },
                    [
                      _c(
                        "b-step-item",
                        { attrs: { label: "Calendar Parameters", step: "1" } },
                        [
                          _c("hr"),
                          _c("CalendarParametersForm", {
                            attrs: {
                              activeStep: _vm.activeStepComputed,
                              concepts: _vm.concepts,
                              countries: _vm.countries,
                              kitchens: _vm.kitchens,
                              locations: _vm.locations,
                              platforms: _vm.platforms,
                              events: _vm.events,
                              currentSelection: _vm.currentSelection,
                            },
                            on: {
                              "update:activeStep": function ($event) {
                                _vm.activeStepComputed = $event
                              },
                              "update:active-step": function ($event) {
                                _vm.activeStepComputed = $event
                              },
                              "update:currentSelection": function ($event) {
                                _vm.currentSelection = $event
                              },
                              "update:current-selection": function ($event) {
                                _vm.currentSelection = $event
                              },
                              select: _vm.select,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Management Parameter",
                            step: "2",
                            clickable: false,
                          },
                        },
                        [
                          _c(
                            "div",
                            _vm._l(_vm.calendarTypes, function (calendarType) {
                              return _c(
                                "div",
                                {
                                  key: calendarType.label,
                                  staticClass: "has-text-centered",
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "button-gap",
                                      attrs: {
                                        "icon-left": calendarType.icon,
                                        type: "is-info",
                                        expanded: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCalendarType(
                                            calendarType
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(calendarType.label) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: "Calendar",
                            step: "3",
                            clickable: false,
                          },
                        },
                        [
                          _c("CampaignReport", {
                            attrs: {
                              kitchenCampaignInfo: _vm.kitchenCampaignInfo,
                              concepts: _vm.concepts,
                              countries: _vm.countries,
                              kitchens: _vm.kitchens,
                              locations: _vm.locations,
                              platforms: _vm.platforms,
                              kitchenCategory: _vm.kitchenCategory,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }