<template>
  <section>
    <b-table
      :data="kitchensRestaurantConcept"
      ref="table"
      detailed
      hoverable
      :default-sort="['id', 'asc']"
      detail-key="id"
      @details-open="(row, index) => $buefy.toast.open(`Expanded ${row.id}`)"
      :show-detail-icon="showDetailIcon"
    >
      <template slot-scope="props">
        <b-table-column field="id" label="ID" sortable searchable>
          <template v-if="showDetailIcon">
            {{ props.row.id }}
          </template>
          <template v-else>
            <a @click="toggle(props.row)">
              {{ props.row.id }}
            </a>
          </template>
        </b-table-column>
        <b-table-column field="city" label="City" sortable centered searchable>
          {{ props.row.city }}
        </b-table-column>
        <b-table-column field="country" label="Country" sortable centered searchable>
          {{ props.row.country }}
        </b-table-column>
        <b-table-column field="franchise" label="Owner" sortable :custom-sort="sortFranchise" centered>
          <b-tag v-if="props.row.is_franchise" type="is-link">
            Franchise
          </b-tag>
          <b-tag v-else type="is-primary">
            Taster
          </b-tag>
        </b-table-column>
        <b-table-column field="status" label="Status">
          <b-taglist>
            <b-tag v-if="props.row.status.status == 'empty'" type="is-warning">
              Empty
            </b-tag>
            <b-tag v-if="props.row.status.kitchenOpeningHoursCompleted == false" type="is-danger">
              Missing Opening Hours
            </b-tag>
            <b-tag v-if="props.row.status.kitchenPlatformIDCompleted == false" type="is-danger">
              Missing Restaurant Platform ID
            </b-tag>
            <b-tag v-if="props.row.status.kitchenPOSCompleted == false" type="is-danger">
              POS
            </b-tag>
            <b-tag v-if="props.row.status.kitchenOneTabletCompleted == false" type="is-danger">
              OneTablet
            </b-tag>
            <b-tag v-if="props.row.status.kitchenLoyaltyCompleted == false" type="is-danger">
              Loyalty
            </b-tag>
            <b-tag v-if="props.row.status.kitchenLoyaltyCompleted == true" type="is-success">
              Loyalty
            </b-tag>
          </b-taglist>
        </b-table-column>
        <b-table-column>
          <button class="button is-info is-small" @click="$emit('edit-kitchen', props.row.uuid, {'city':  props.row.city, 'country': props.row.country, 'country_uuid':  props.row.country_uuid}, false)">
            Show & Edit
        </button>
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="kitchen_props">
        <b-table :data="kitchen_props.row.restaurants_by_concept" class="inner-table">
          <template slot-scope="kitchen_props">
            <b-table-column field="logo" label="Restaurants" style="vertical-align:middle" class="has-text-centered">
              <div v-if="kitchen_props.row.concept_is_internal">
                <img
                  :src="kitchen_props.row.logo"
                  :alt="kitchen_props.row.label"
                  onerror="this.style.display='none'"
                  class="concept-logo"
                />
              </div>
              <div v-else>
                {{kitchen_props.row.label}}
              </div>
            </b-table-column>
            <!-- <b-table-column field="opening_hours" label="" style="vertical-align:middle">
              <button
                class="button is-small is-primary"
                @click="
                  $emit(
                    'edit-restaurant-opening-hours',
                    getKitchen(kitchen_props.row.kitchen_uuid),
                    kitchen_props.row.concept_uuid,
                    kitchen_props.row.opening_hours,
                  )
                "
              >
                Check Opening Hours
              </button>
            </b-table-column> -->
            <b-table-column field="id" label="" class="has-text-centered">
              <b-table :data="kitchen_props.row.platforms" class="inner-table">
                <template slot-scope="restaurant_props">
                  <b-table-column field="id" label="Platform">
                    <component
                      :is="restaurant_props.row.platform_url && restaurant_props.row.platform_url.includes('https') ? 'a' : 'span'"
                      :href="restaurant_props.row.platform_url"
                      target="_blank"
                    >
                      <img
                        :src="restaurant_props.row.platform_logo"
                        alt="platform_logo"
                        onerror="this.style.display='none'"
                        class="concept-logo"
                      />
                    </component>
                  </b-table-column>
                  <b-table-column field="status" label="Status">
                    <b-taglist>
                      <b-tag v-if="restaurantIsCompleted(restaurant_props.row)" type="is-success">
                        Platform Info
                      </b-tag>
                      <b-tag v-if="restaurantIsMissingOpeningHours(restaurant_props.row)" type="is-danger">
                        Missing Opening Hours
                      </b-tag>
                      <b-tag v-if="restaurantIsMissingPlatformID(restaurant_props.row)" type="is-danger">
                        Missing Restaurant Platform ID
                      </b-tag>
                      <b-tag v-if="restaurant_props.row.is_pos_integrated" type="is-success">
                        POS
                      </b-tag>
                      <b-tag v-if="restaurant_props.row.is_pos_integrated === false" type="is-danger">
                        POS
                      </b-tag>
                      <b-tag v-if="restaurant_props.row.is_active" type="is-success">
                        Active
                      </b-tag>
                      <b-tag v-if="restaurant_props.row.is_active === false" type="is-danger">
                        Active
                      </b-tag>
                      <b-tag v-if="restaurant_props.row.is_one_tablet" type="is-success">
                        OneTablet
                      </b-tag>
                      <b-tag v-if="restaurant_props.row.is_one_tablet === false" type="is-danger">
                        OneTablet
                      </b-tag>
                      <b-tag v-if="restaurant_props.row.is_loyalty_enable" type="is-success">
                        Loyalty
                      </b-tag>
                      <b-tag v-if="restaurant_props.row.is_loyalty_enable === false" type="is-danger">
                        Loyalty
                      </b-tag>
                      <b-tag v-if="restaurant_props.row.is_internal" type="is-success">
                        Internal
                      </b-tag>
                      <b-tag v-if="restaurant_props.row.is_internal === false" type="is-link">
                        External
                      </b-tag>
                    </b-taglist>
                  </b-table-column>
                  <b-table-column>
                    <button class="button is-info is-small" @click="$emit('edit-restaurant', kitchen_props.row.kitchen_uuid, restaurant_props.row.restaurant_uuid, false)">
                      Show & Edit
                  </button>
                  </b-table-column>
                </template>
              </b-table>
            </b-table-column>
          </template>
        </b-table>
      </template>
    </b-table>
  </section>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';

export default {
  props: ['concepts', 'countries', 'locations', 'platforms', 'selectedKitchenStatuses'],
  data() {
    return {
      showDetailIcon: true,
      columnWidth: 250,
      isEmpty: false,
    };
  },
  computed: {
    ...mapState({
      kitchens: (state) => state.kitchens.all,
      kitchenStatuses: (state) => state.kitchens.kitchenStatuses,
    }),
    kitchensRestaurantConcept() {
      var kitchensRestaurantConcept = [];
      var kitchens = this.filterKitchens(this.kitchens, this.selectedKitchenStatuses, this.kitchenStatuses);
      var concept_uuids = this.concepts.map(concept => concept.uuid);
      var platform_uuids = this.platforms.map(platform => platform.uuid);
      for (let kitchen of kitchens) {
        var location = this.locations.find(loc => loc.uuid == kitchen.location_uuid);
        var country = this.countries.find(country => country.uuid == location.country_uuid);
        var restaurants = [];
        if (kitchen.restaurants) {
          restaurants = kitchen.restaurants.filter(restaurant => concept_uuids.includes(restaurant.concept_uuid) && platform_uuids.includes(restaurant.platform_uuid));
        }
        var kitchenOpeningHoursCompleted = true;
        var kitchenPlatformIDCompleted = true;
        var kitchenPOSCompleted = true;
        var kitchenOneTabletCompleted = true;
        var kitchenLoyaltyCompleted = true;
        var kitchenByConcept = Object.assign({}, {...kitchen});
        if (location) {
          kitchenByConcept['city'] = location.city;
        }
        if (country) {
          kitchenByConcept['country'] = country.id;
          kitchenByConcept['country_uuid'] = country.uuid;
        }
        var restaurant_by_concept = {};

        for (let restaurant of restaurants) {

          var restaurantOpeningHoursCompleted = false;
          var restaurantPlatformIDMissing = false;
          var concept = this.concepts.find(concept => concept.uuid == restaurant.concept_uuid);
          var platform = this.platforms.find(platform => platform.uuid == restaurant.platform_uuid);

          if (!(concept.id in restaurant_by_concept)) {
            restaurant_by_concept[concept.id] = {
              label: concept.label,
              logo: concept.logo,
              concept_is_internal: concept.is_internal,
              kitchen_uuid: kitchen.uuid,
              opening_hours: restaurant.opening_hours,
              restaurant_information: restaurant.restaurant_information,
              concept_uuid: concept.uuid,
              platforms: [],
            };
          }

          for (var day in restaurant.opening_hours) {
            if (restaurant.opening_hours[day].lunch_shift != '' || restaurant.opening_hours[day].dinner_shift != '') {
              restaurantOpeningHoursCompleted = true;
            }
          }
          if (!Object.keys(restaurant.opening_hours).length || !restaurantOpeningHoursCompleted) {
            kitchenOpeningHoursCompleted = kitchenOpeningHoursCompleted && false;
          }
          if (restaurant.restaurant_platform_id == '') {
            restaurantPlatformIDMissing = true;
            kitchenPlatformIDCompleted = false;
          }
          if (restaurant.pos_integrated === false && restaurant.is_active && !restaurant.is_deleted) {
            kitchenPOSCompleted = false;
          }
          if (restaurant.is_one_tablet === false && restaurant.is_active && !restaurant.is_deleted) {
            kitchenOneTabletCompleted = false;
          }
          if (restaurant.is_loyalty_enable === false && restaurant.is_active && !restaurant.is_deleted) {
            kitchenLoyaltyCompleted = false;
          }
          restaurant_by_concept[concept.id]['platforms'].push(
            {
              platform_label: platform.label,
              platform_logo: platform.logo,
              platform_uuid: platform.uuid,
              platform_url: restaurant.url,
              platform_active: restaurant.is_active,
              is_delivery_enabled: restaurant.is_delivery_enabled,
              is_pickup_enabled: restaurant.is_pickup_enabled,
              is_pos_integrated: restaurant.is_pos_integrated,
              is_room_service_enabled: restaurant.is_room_service_enabled,
              is_active: restaurant.is_active,
              is_internal: restaurant.is_internal,
              is_loyalty_enable: restaurant.is_loyalty_enable,
              is_one_tablet: restaurant.is_one_tablet,
              is_supply_integrated: restaurant.is_supply_integrated,
              restaurant_uuid: restaurant.uuid,
              restaurant_label: restaurant.label,
              restaurant_launch_date: restaurant.launch_date,
              restaurant_platform_id: restaurant.restaurant_platform_id,
              restaurant_delivery_id: restaurant.restaurant_delivery_id,
              restaurant_platform_id_missing: restaurantPlatformIDMissing,
              restaurant_opening_hours_missing: !restaurantOpeningHoursCompleted,
            }
          );
        }
        kitchenByConcept['restaurants_by_concept'] = Object.values(restaurant_by_concept);
        if (Object.keys(restaurant_by_concept).length == 0) {
          var kitchen_status = 'empty';
        } else if (kitchenOpeningHoursCompleted && kitchenPlatformIDCompleted) {
          var kitchen_status = 'complete';
        } else {
          var kitchen_status = 'false';
        }
        kitchenByConcept.status = {
          kitchenOpeningHoursCompleted: kitchenOpeningHoursCompleted,
          kitchenPlatformIDCompleted: kitchenPlatformIDCompleted,
          kitchenPOSCompleted: kitchenPOSCompleted,
          kitchenOneTabletCompleted: kitchenOneTabletCompleted,
          kitchenLoyaltyCompleted: kitchenLoyaltyCompleted,
          status: kitchen_status,
        };
        kitchensRestaurantConcept.push(kitchenByConcept);
      }
      return kitchensRestaurantConcept;
    }
  },
  methods: {
    getKitchen(kitchenUUID) {
      return this.kitchensRestaurantConcept.find(kitchen => kitchen.uuid == kitchenUUID);
    },
    filterKitchens(kitchens, kitchenTypes, kitchenStatuses) {
      var filteredKitchens = [];
      if (kitchenTypes.length == 2) {
        filteredKitchens = kitchens
      }
      else if (kitchenTypes.length == 1) {
        var kitchen_is_franchise_value = kitchenStatuses.filter((kitchenStat) => kitchenStat.label == kitchenTypes[0])
                                                        .map((kitchenStat) => kitchenStat.value)[0];
        filteredKitchens = kitchens.filter((kitchen) => kitchen.is_franchise == kitchen_is_franchise_value);
      }
      return filteredKitchens;
    },
    sortFranchise(a, b, isAsc) {
      if (isAsc) {
        return b.is_franchise - a.is_franchise;
      }
      return a.is_franchise - b.is_franchise;
    },
    restaurantIsCompleted(restaurant) {
      return !(restaurant.restaurant_platform_id_missing || restaurant.restaurant_opening_hours_missing);
    },
    restaurantIsMissingOpeningHours(restaurant) {
      return restaurant.restaurant_opening_hours_missing;
    },
    restaurantIsMissingPlatformID(restaurant) {
      return restaurant.restaurant_platform_id_missing;
    },
  },
};
</script>

<style scoped>
.insidetable {
  border: none;
}
</style>

<style>
.switch-button-red-deactivate.switch input[type='checkbox'] + .check {
  background: #ff3860;
}
.switch-button-red-deactivate.switch:hover input[type='checkbox'] + .check {
  background: #ff3860;
}
tr.border_bottom:not(:last-child) {
  border-bottom: 1pt solid grey;
}
</style>
