var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentManagementInformation && _vm.managementType
    ? _c(
        "div",
        { staticClass: "card-kitchenInformation" },
        [
          _c(
            "Container",
            {
              attrs: {
                orientation: "horizontal",
                "drag-handle-selector": ".column-drag-handle",
                "drop-placeholder": _vm.upperDropPlaceholderOptions,
              },
              on: { "drag-start": _vm.dragStart },
            },
            _vm._l(_vm.kitchenInformation.children, function (column) {
              return _c("Draggable", { key: _vm.getColumnUniqueID(column) }, [
                _c(
                  "div",
                  { class: column.props.className },
                  [
                    _c("div", { staticClass: "card-column-header" }, [
                      _vm._v(" " + _vm._s(_vm.getName(column.name)) + " "),
                    ]),
                    _c(
                      "Container",
                      {
                        attrs: {
                          "group-name": "col",
                          "get-child-payload": _vm.getCardPayload(column.id),
                          "drag-class": "card-ghost",
                          "drop-class": "card-ghost-drop",
                          "drop-placeholder": _vm.dropPlaceholderOptions,
                        },
                        on: {
                          drop: function (e) {
                            return _vm.onCardDrop(column.id, e)
                          },
                        },
                      },
                      _vm._l(column.children, function (card) {
                        return _c("Draggable", { key: card.id }, [
                          _c(
                            "div",
                            {
                              class: card.props.className,
                              style: card.props.style,
                            },
                            [
                              _c("p", [
                                _c("b", [_vm._v(_vm._s(card.data.id) + " ")]),
                              ]),
                            ]
                          ),
                        ])
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }