export const conceptsTypes = {
  ACTIVATE_CONCEPT: 'ACTIVATE_CONCEPT',
  CREATE_CONCEPT: 'CREATE_CONCEPT',
  DEACTIVATE_CONCEPT: 'DEACTIVATE_CONCEPT',
  RESET_NEW_CONCEPT: 'RESET_NEW_CONCEPT',
  SET_CONCEPTS: 'SET_CONCEPTS',
  SET_SCHEMA: 'SET_SCHEMA',
  SET_CURRENT_ERROR: 'SET_CURRENT_ERROR',
  UPDATE_CONCEPT: 'UPDATE_CONCEPT',
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
}

export const platformsTypes = {
  ACTIVATE_PLATFORM: 'ACTIVATE_PLATFORM',
  CREATE_PLATFORM: 'CREATE_PLATFORM',
  DEACTIVATE_PLATFORM: 'DEACTIVATE_PLATFORM',
  RESET_NEW_PLATFORM: 'RESET_NEW_PLATFORM',
  SET_PLATFORMS: 'SET_PLATFORMS',
  SET_SCHEMA: 'SET_SCHEMA',
  SET_CURRENT_ERROR: 'SET_CURRENT_ERROR',
  UPDATE_PLATFORM: 'UPDATE_PLATFORM',
  SET_SELECTED: 'SET_SELECTED',
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
}

export const kitchensTypes = {
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  CREATE_KITCHEN: 'CREATE_KITCHEN',
  DELETE_KITCHEN: 'DELETE_KITCHEN',
  SET_KITCHENS: 'SET_KITCHENS',
  SET_SCHEMA: 'SET_SCHEMA',
  SET_CURRENT_ERROR: 'SET_CURRENT_ERROR',
  SET_CURRENT_SUCCESS: 'SET_CURRENT_SUCCESS',
  UPDATE_KITCHEN: 'UPDATE_KITCHEN',
  SET_RESTAURANT_TO_KITCHENS: 'SET_RESTAURANT_TO_KITCHENS',
  SET_EXPORT_KITCHEN_INFORMATION: 'SET_EXPORT_KITCHEN_INFORMATION',
  ADD_KITCHEN: 'ADD_KITCHEN',
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  SET_CURRENT_MANAGEMENT_INFORMATION: 'SET_CURRENT_MANAGEMENT_INFORMATION',
}

export const locationsTypes = {
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  CREATE_LOCATION: 'CREATE_LOCATION',
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  SET_LOCATIONS: 'SET_LOCATIONS',
  SET_CURRENT_ERROR: 'SET_CURRENT_ERROR',
}

export const restaurantsTypes = {
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  ACTIVATE_RESTAURANT: 'ACTIVATE_RESTAURANT',
  CREATE_RESTAURANT: 'CREATE_RESTAURANT',
  DEACTIVATE_RESTAURANT: 'DEACTIVATE_RESTAURANT',
  DELETE_RESTAURANT: 'DELETE_RESTAURANT',
  RESET_NEW_RESTAURANT: 'RESET_NEW_RESTAURANT',
  SET_RESTAURANTS: 'SET_RESTAURANTS',
  SET_SCHEMA: 'SET_SCHEMA',
  SET_CURRENT_ERROR: 'SET_CURRENT_ERROR',
  SET_CURRENT_SUCCESS: 'SET_CURRENT_SUCCESS',
  UPDATE_RESTAURANT: 'UPDATE_RESTAURANT',
  SET_EXPORT_OPENING_HOURS: 'SET_EXPORT_OPENING_HOURS',
}


export const countriesTypes = {
  SET_COUNTRIES: 'SET_COUNTRIES',
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST'
}

export const onboardingActionTypes = {
  ACTIVATE_POS_INTEGRATION_ACTION_REQUEST: 'ACTIVATE_POS_INTEGRATION_ACTION_REQUEST',
  ACTIVATE_POS_INTEGRATION_ACTION_SUCCESS: 'ACTIVATE_POS_INTEGRATION_ACTION_SUCCESS',
  ACTIVATE_POS_INTEGRATION_ACTION_FAILURE: 'ACTIVATE_POS_INTEGRATION_ACTION_FAILURE',
  CHECK_POS_INTEGRATION_ACTION_REQUEST: 'CHECK_POS_INTEGRATION_ACTION_REQUEST',
  CHECK_POS_INTEGRATION_ACTION_SUCCESS: 'CHECK_POS_INTEGRATION_ACTION_SUCCESS',
  CHECK_POS_INTEGRATION_ACTION_FAILURE: 'CHECK_POS_INTEGRATION_ACTION_FAILURE',
  CREATE_KITCHEN_ACTION_REQUEST: 'CREATE_KITCHEN_ACTION_REQUEST',
  CREATE_KITCHEN_ACTION_SUCCESS: 'CREATE_KITCHEN_ACTION_SUCCESS',
  CREATE_KITCHEN_ACTION_FAILURE: 'CREATE_KITCHEN_ACTION_FAILURE',
  SET_CURRENT_KITCHEN: 'SET_CURRENT_KITCHEN',
  CREATE_RESTAURANT_ACTION_REQUEST: 'CREATE_RESTAURANT_ACTION_REQUEST',
  CREATE_RESTAURANT_ACTION_SUCCESS: 'CREATE_RESTAURANT_ACTION_SUCCESS',
  CREATE_RESTAURANT_ACTION_FAILURE: 'CREATE_RESTAURANT_ACTION_FAILURE',
  CREATE_MAIL_ACTION_REQUEST: 'CREATE_MAIL_ACTION_REQUEST',
  CREATE_MAIL_ACTION_SUCCESS: 'CREATE_MAIL_ACTION_SUCCESS',
  CREATE_MAIL_ACTION_FAILURE: 'CREATE_MAIL_ACTION_FAILURE',
  CREATE_INTERCOM_INFORMATION_REQUEST: 'CREATE_INTERCOM_INFORMATION_REQUEST',
  CREATE_INTERCOM_INFORMATION_SUCCESS: 'CREATE_INTERCOM_INFORMATION_SUCCESS',
  CREATE_INTERCOM_INFORMATION_FAILURE: 'CREATE_INTERCOM_INFORMATION_FAILURE',
  GET_LAUNCH_OWNERS_ACTION_REQUEST: 'GET_LAUNCH_OWNERS_ACTION_REQUEST',
  GET_LAUNCH_OWNERS_ACTION_SUCCESS: 'GET_LAUNCH_OWNERS_ACTION_SUCCESS',
  GET_LAUNCH_OWNERS_ACTION_FAILURE: 'GET_LAUNCH_OWNERS_ACTION_FAILURE',
  GRANT_IAM_ACCESS_ACTION_REQUEST: 'GRANT_IAM_ACCESS_ACTION_REQUEST',
  GRANT_IAM_ACCESS_ACTION_SUCCESS: 'GRANT_IAM_ACCESS_ACTION_SUCCESS',
  GRANT_IAM_ACCESS_ACTION_FAILURE: 'GRANT_IAM_ACCESS_ACTION_FAILURE',
  IMPORT_IAM_LOCATION_ACTION_REQUEST: 'IMPORT_IAM_LOCATION_ACTION_REQUEST',
  IMPORT_IAM_LOCATION_ACTION_SUCCESS: 'IMPORT_IAM_LOCATION_ACTION_SUCCESS',
  IMPORT_IAM_LOCATION_ACTION_FAILURE: 'IMPORT_IAM_LOCATION_ACTION_FAILURE',
  UPDATE_KITCHEN_ACTION_REQUEST: 'UPDATE_KITCHEN_ACTION_REQUEST',
  UPDATE_KITCHEN_ACTION_SUCCESS: 'UPDATE_KITCHEN_ACTION_SUCCESS',
  UPDATE_KITCHEN_ACTION_FAILURE: 'UPDATE_KITCHEN_ACTION_FAILURE',
  UPDATE_RESTAURANT_ACTION_REQUEST: 'UPDATE_RESTAURANT_ACTION_REQUEST',
  UPDATE_RESTAURANT_ACTION_SUCCESS: 'UPDATE_RESTAURANT_ACTION_SUCCESS',
  UPDATE_RESTAURANT_ACTION_FAILURE: 'UPDATE_RESTAURANT_ACTION_FAILURE',
  COPY_MENU_ACTION_REQUEST: 'COPY_MENU_ACTION_REQUEST',
  COPY_MENU_ACTION_SUCCESS: 'COPY_MENU_ACTION_SUCCESS',
  COPY_MENU_ACTION_FAILURE: 'COPY_MENU_ACTION_FAILURE',
  COPY_RECIPES_ACTION_REQUEST: 'COPY_RECIPES_ACTION_REQUEST',
  COPY_RECIPES_ACTION_SUCCESS: 'COPY_RECIPES_ACTION_SUCCESS',
  COPY_RECIPES_ACTION_FAILURE: 'COPY_RECIPES_ACTION_FAILURE',
  CREATE_FORECASTS_ACTION_REQUEST: 'CREATE_FORECASTS_ACTION_REQUEST',
  CREATE_FORECASTS_ACTION_SUCCESS: 'CREATE_FORECASTS_ACTION_SUCCESS',
  CREATE_FORECASTS_ACTION_FAILURE: 'CREATE_FORECASTS_ACTION_FAILURE',
  SET_UP_FAKE_OPENING_HOURS_ACTION_REQUEST: 'SET_UP_FAKE_OPENING_HOURS_ACTION_REQUEST',
  SET_UP_FAKE_OPENING_HOURS_ACTION_SUCCESS: 'SET_UP_FAKE_OPENING_HOURS_ACTION_SUCCESS',
  SET_UP_FAKE_OPENING_HOURS_ACTION_FAILURE: 'SET_UP_FAKE_OPENING_HOURS_ACTION_FAILURE',
  SET_UP_HOLIDAYS_ACTION_REQUEST: 'SET_UP_HOLIDAYS_ACTION_REQUEST',
  SET_UP_HOLIDAYS_ACTION_SUCCESS: 'SET_UP_HOLIDAYS_ACTION_SUCCESS',
  SET_UP_HOLIDAYS_ACTION_FAILURE: 'SET_UP_HOLIDAYS_ACTION_FAILURE',
  UPLOAD_MENU_ACTION_REQUEST: 'UPLOAD_MENU_ACTION_REQUEST',
  UPLOAD_MENU_ACTION_SUCCESS: 'UPLOAD_MENU_ACTION_SUCCESS',
  UPLOAD_MENU_ACTION_FAILURE: 'UPLOAD_MENU_ACTION_FAILURE',
  COPY_PRODUCTS_INFORMATION_ACTION_REQUEST: 'COPY_PRODUCTS_INFORMATION_ACTION_REQUEST',
  COPY_PRODUCTS_INFORMATION_ACTION_SUCCESS: 'COPY_PRODUCTS_INFORMATION_ACTION_SUCCESS',
  COPY_PRODUCTS_INFORMATION_ACTION_FAILURE: 'COPY_PRODUCTS_INFORMATION_ACTION_FAILURE',
  UPDATE_OPENING_HOURS_ACTION_REQUEST: 'UPDATE_OPENING_HOURS_ACTION_REQUEST',
  UPDATE_OPENING_HOURS_ACTION_SUCCESS: 'UPDATE_OPENING_HOURS_ACTION_SUCCESS',
  UPDATE_OPENING_HOURS_ACTION_FAILURE: 'UPDATE_OPENING_HOURS_ACTION_FAILURE',
  CONFIGURE_TASTER_RESTAURANT_ACTION_REQUEST: 'CONFIGURE_TASTER_RESTAURANT_ACTION_REQUEST',
  CONFIGURE_TASTER_RESTAURANT_ACTION_SUCCESS: 'CONFIGURE_TASTER_RESTAURANT_ACTION_SUCCESS',
  CONFIGURE_TASTER_RESTAURANT_ACTION_FAILURE: 'CONFIGURE_TASTER_RESTAURANT_ACTION_FAILURE',
  EXPORT_MENU_ACTION_REQUEST: 'EXPORT_MENU_ACTION_REQUEST',
  EXPORT_MENU_ACTION_SUCCESS: 'EXPORT_MENU_ACTION_SUCCESS',
  EXPORT_MENU_ACTION_FAILURE: 'EXPORT_MENU_ACTION_FAILURE',
  IMPORT_DUMMY_MENU_ACTION_REQUEST: 'IMPORT_DUMMY_MENU_ACTION_REQUEST',
  IMPORT_DUMMY_MENU_ACTION_SUCCESS: 'IMPORT_DUMMY_MENU_ACTION_SUCCESS',
  IMPORT_DUMMY_MENU_ACTION_FAILURE: 'IMPORT_DUMMY_MENU_ACTION_FAILURE',
  CONFIGURE_UAT_ACTION_REQUEST: 'CONFIGURE_UAT_ACTION_REQUEST',
  CONFIGURE_UAT_ACTION_SUCCESS: 'CONFIGURE_UAT_ACTION_SUCCESS',
  CONFIGURE_UAT_ACTION_FAILURE: 'CONFIGURE_UAT_ACTION_FAILURE',
  MIGRATE_MENU_ACTION_REQUEST: 'MIGRATE_MENU_ACTION_REQUEST',
  MIGRATE_MENU_ACTION_SUCCESS: 'MIGRATE_MENU_ACTION_SUCCESS',
  MIGRATE_MENU_ACTION_FAILURE: 'MIGRATE_MENU_ACTION_FAILURE',
  CLOSE_RESTAURANTS_ACTION_REQUEST: 'CLOSE_RESTAURANTS_ACTION_REQUEST',
  CLOSE_RESTAURANTS_ACTION_SUCCESS: 'CLOSE_RESTAURANTS_ACTION_SUCCESS',
  CLOSE_RESTAURANTS_ACTION_FAILURE: 'CLOSE_RESTAURANTS_ACTION_FAILURE',
  OPEN_RESTAURANTS_ACTION_REQUEST: 'OPEN_RESTAURANTS_ACTION_REQUEST',
  OPEN_RESTAURANTS_ACTION_SUCCESS: 'OPEN_RESTAURANTS_ACTION_SUCCESS',
  OPEN_RESTAURANTS_ACTION_FAILURE: 'OPEN_RESTAURANTS_ACTION_FAILURE',
  SET_UP_SUBSTITUTE_RULES_ACTION_REQUEST: 'SET_UP_SUBSTITUTE_RULES_ACTION_REQUEST',
  SET_UP_SUBSTITUTE_RULES_ACTION_SUCCESS: 'SET_UP_SUBSTITUTE_RULES_ACTION_SUCCESS',
  SET_UP_SUBSTITUTE_RULES_ACTION_FAILURE: 'SET_UP_SUBSTITUTE_RULES_ACTION_FAILURE',
  TRANSFER_RESTAURANT_ACTION_REQUEST: 'TRANSFER_RESTAURANT_ACTION_REQUEST',
  TRANSFER_RESTAURANT_ACTION_SUCCESS: 'TRANSFER_RESTAURANT_ACTION_SUCCESS',
  TRANSFER_RESTAURANT_ACTION_FAILURE: 'TRANSFER_RESTAURANT_ACTION_FAILURE',
};

export const launchesTypes = {
  CREATE_LAUNCH_ACTION_REQUEST: 'CREATE_LAUNCH_ACTION_REQUEST',
  CREATE_LAUNCH_ACTION_SUCCESS: 'CREATE_LAUNCH_ACTION_SUCCESS',
  CREATE_LAUNCH_ACTION_FAILURE: 'CREATE_LAUNCH_ACTION_FAILURE',
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  UPDATE_LAUNCH_TASK_REQUEST: 'UPDATE_LAUNCH_TASK_REQUEST',
  UPDATE_LAUNCH_TASK_SUCCESS: 'UPDATE_LAUNCH_TASK_SUCCESS',
  UPDATE_LAUNCH_TASK_FAILURE: 'UPDATE_LAUNCH_TASK_FAILURE',
  REMOVE_LAUNCH_TASK_REQUEST: 'REMOVE_LAUNCH_TASK_REQUEST',
  REMOVE_LAUNCH_TASK_SUCCESS: 'REMOVE_LAUNCH_TASK_SUCCESS',
  REMOVE_LAUNCH_TASK_FAILURE: 'REMOVE_LAUNCH_TASK_FAILURE',
  ADD_LAUNCH_TASK_GROUP_REQUEST: 'ADD_LAUNCH_TASK_GROUP_REQUEST',
  ADD_LAUNCH_TASK_GROUP_SUCCESS: 'ADD_LAUNCH_TASK_GROUP_SUCCESS',
  ADD_LAUNCH_TASK_GROUP_FAILURE: 'ADD_LAUNCH_TASK_GROUP_FAILURE',
  REMOVE_LAUNCH_TASK_GROUP_REQUEST: 'REMOVE_LAUNCH_TASK_GROUP_REQUEST',
  REMOVE_LAUNCH_TASK_GROUP_SUCCESS: 'REMOVE_LAUNCH_TASK_GROUP_SUCCESS',
  REMOVE_LAUNCH_TASK_GROUP_FAILURE: 'REMOVE_LAUNCH_TASK_GROUP_FAILURE',
  SET_CURRENT_LAUNCH: 'SET_CURRENT_LAUNCH',
};

export const taskGroupsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  DELETE_ONE_REQUEST: 'DELETE_ONE_REQUEST',
  DELETE_ONE_SUCCESS: 'DELETE_ONE_SUCCESS',
  DELETE_ONE_FAILURE: 'DELETE_ONE_FAILURE',
  SET_CURRENT_TASKS: 'SET_CURRENT_TASKS',
  RESET_NEW_TASK_GROUP: 'RESET_NEW_TASK_GROUP',
};

export const tasksTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  DELETE_ONE_REQUEST: 'DELETE_ONE_REQUEST',
  DELETE_ONE_SUCCESS: 'DELETE_ONE_SUCCESS',
  DELETE_ONE_FAILURE: 'DELETE_ONE_FAILURE',
  RESET_NEW_TASK: 'RESET_NEW_TASK',
};

export const accessTypes = {
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  EDIT_RIGHTS_REQUEST: 'EDIT_RIGHTS_REQUEST',
  EDIT_RIGHTS_SUCCESS: 'EDIT_RIGHTS_SUCCESS',
  EDIT_RIGHTS_FAILURE: 'EDIT_RIGHTS_FAILURE',
  FETCH_GOOGLE_GROUPS_REQUEST: 'FETCH_GOOGLE_GROUPS_REQUEST',
  FETCH_GOOGLE_GROUPS_SUCCESS: 'FETCH_GOOGLE_GROUPS_SUCCESS',
  FETCH_GOOGLE_GROUPS_FAILURE: 'FETCH_GOOGLE_GROUPS_FAILURE',
  FETCH_GROUPS_REQUEST: 'FETCH_GROUPS_REQUEST',
  FETCH_GROUPS_SUCCESS: 'FETCH_GROUPS_SUCCESS',
  FETCH_GROUPS_FAILURE: 'FETCH_GROUPS_FAILURE',
  FETCH_USER_REQUEST: 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'FETCH_USERS_FAILURE',
  FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',
  REMOVE_USER_REQUEST: 'REMOVE_USER_REQUEST',
  REMOVE_USER_SUCCESS: 'REMOVE_USER_SUCCESS',
  REMOVE_USER_FAILURE: 'REMOVE_USER_FAILURE',
  SET_CURRENT_USER: 'SET_CURRENT_USER'
};

export const campaignsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  DELETE_ONE_REQUEST: 'DELETE_ONE_REQUEST',
  DELETE_ONE_SUCCESS: 'DELETE_ONE_SUCCESS',
  DELETE_ONE_FAILURE: 'DELETE_ONE_FAILURE',
  RESET_ONE: 'RESET_ONE',
  SET_SELECTED_CAMPAIGN_TIMELINES: 'SET_SELECTED_CAMPAIGN_TIMELINES',
  SET_SELECTED_CAMPAIGN_TYPE: 'SET_SELECTED_CAMPAIGN_TYPE',
  SET_CURRENT: 'SET_CURRENT',
  DUPLICATE: 'DUPLICATE'
};

export const kitchenCampaignsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  CREATE_MULTIPLE_REQUEST: 'CREATE_MULTIPLE_REQUEST',
  CREATE_MULTIPLE_SUCCESS: 'CREATE_MULTIPLE_SUCCESS',
  CREATE_MULTIPLE_FAILURE: 'CREATE_MULTIPLE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  DELETE_ONE_REQUEST: 'DELETE_ONE_REQUEST',
  DELETE_ONE_SUCCESS: 'DELETE_ONE_SUCCESS',
  DELETE_ONE_FAILURE: 'DELETE_ONE_FAILURE',
  UPDATE_ONE_STATUS_REQUEST: 'UPDATE_ONE_STATUS_REQUEST',
  UPDATE_ONE_STATUS_SUCCESS: 'UPDATE_ONE_STATUS_SUCCESS',
  UPDATE_ONE_STATUS_FAILURE: 'UPDATE_ONE_STATUS_FAILURE',
  RESET_ONE: 'RESET_ONE',
  SET_SELECTED_CAMPAIGN_TIMELINES: 'SET_SELECTED_CAMPAIGN_TIMELINES',
  SET_SELECTED_CAMPAIGN_TYPE: 'SET_SELECTED_CAMPAIGN_TYPE',
  SET_CURRENT: 'SET_CURRENT',
  SET_CURRENTS: 'SET_CURRENTS'
};

export const menusTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ALL_VARIANTS_REQUEST: 'FETCH_ALL_VARIANTS_REQUEST',
  FETCH_ALL_VARIANTS_SUCCESS: 'FETCH_ALL_VARIANTS_SUCCESS',
  FETCH_ALL_VARIANTS_FAILURE: 'FETCH_ALL_VARIANTS_FAILURE',
};

export const menuItemsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
};

export const menuInformationTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
};

export const eventsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  DELETE_ONE_REQUEST: 'DELETE_ONE_REQUEST',
  DELETE_ONE_SUCCESS: 'DELETE_ONE_SUCCESS',
  DELETE_ONE_FAILURE: 'DELETE_ONE_FAILURE',
  RESET_ONE: 'RESET_ONE',
  SET_CURRENT: 'SET_CURRENT',
  SET_CURRENT_EVENT_LABEL: 'SET_CURRENT_EVENT_LABEL',
};

export const campaignItemsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  DELETE_ONE_REQUEST: 'DELETE_ONE_REQUEST',
  DELETE_ONE_SUCCESS: 'DELETE_ONE_SUCCESS',
  DELETE_ONE_FAILURE: 'DELETE_ONE_FAILURE',
  RESET_ONE: 'RESET_ONE',
  SET_CURRENT: 'SET_CURRENT',
};

export const costsTypes = {
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
};


export const hubTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
};


export const supplyActionTypes = {
  EXPORT_CATALOGUE_ACTION_REQUEST: 'EXPORT_CATALOGUE_ACTION_REQUEST',
  EXPORT_CATALOGUE_ACTION_SUCCESS: 'EXPORT_CATALOGUE_ACTION_SUCCESS',
  EXPORT_CATALOGUE_ACTION_FAILURE: 'EXPORT_CATALOGUE_ACTION_FAILURE',
  IMPORT_CATALOGUE_ACTION_REQUEST: 'IMPORT_CATALOGUE_ACTION_REQUEST',
  IMPORT_CATALOGUE_ACTION_SUCCESS: 'IMPORT_CATALOGUE_ACTION_SUCCESS',
  IMPORT_CATALOGUE_ACTION_FAILURE: 'IMPORT_CATALOGUE_ACTION_FAILURE',
};
