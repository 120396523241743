import menuInformationAPI from '../../../apis/platform_state/menuInformation';
import { menuInformationTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  currentConceptUUID: null,
  currentCountryLocationUUID: null,
  fetchAll: {
    pending: false,
    error: null,
  },
};

const actions = {
  async fetchAll({ commit }, payload) {
    commit(menuInformationTypes.FETCH_ALL_REQUEST);
    return menuInformationAPI.fetchAll(payload)
      .then((menuInformations) => commit(menuInformationTypes.FETCH_ALL_SUCCESS, menuInformations))
      .catch((error) => commit(menuInformationTypes.FETCH_ALL_FAILURE, error));
  },
};

const mutations = {
  [menuInformationTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [menuInformationTypes.FETCH_ALL_SUCCESS](state, menuInformations) {
    state.currentConceptUUID = menuInformations.concept_uuid;
    state.currentCountryLocationUUID = menuInformations.location_uuid;
    state.all = menuInformations.restaurant_items.filter(restaurantItem => restaurantItem.type == "MENU_ITEM");
    state.fetchAll.pending = false;
  },
  [menuInformationTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
