import launchesAPI from '../../../apis/onboarding/launches';
import { launchesTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  current: null,
  current_error: '',
  createLaunch: {
    pending: false,
    error: null,
  },
  fetchOne: {
    pending: false,
    error: null,
  },
  fetchAll: {
    pending: false,
    error: null,
  },
  updateOne: {
    pending: false,
    error: null,
  },
  updateTask: {
    pending: false,
    error: null,
  },
  removeTask: {
    pending: false,
    error: null,
  },
  addTaskGroup: {
    pending: false,
    error: null,
  },
  removeTaskGroup: {
    pending: false,
    error: null,
  },
};

const actions = {
  async createLaunch({ commit }, payload) {
    commit(launchesTypes.CREATE_LAUNCH_ACTION_REQUEST);
    return launchesAPI
      .createLaunch(payload)
      .then(function (launch) {
        commit(launchesTypes.CREATE_LAUNCH_ACTION_SUCCESS, launch);
        return launch;
      })
      .catch(function (error) {
        commit(launchesTypes.CREATE_LAUNCH_ACTION_FAILURE, error.response.data.detail);
      });
  },
  async fetchAll({ commit }, opts) {
    commit(launchesTypes.FETCH_ALL_REQUEST);

    return launchesAPI.fetchAll(opts)
      .then((launches) => commit(launchesTypes.FETCH_ALL_SUCCESS, launches))
      .catch((error) => commit(launchesTypes.FETCH_ALL_FAILURE, error));
  },
  async fetchOne({ commit }, launchUUID) {
    commit(launchesTypes.FETCH_ONE_REQUEST);

    return launchesAPI
      .fetchOne(launchUUID)
      .then((launch) => commit(launchesTypes.FETCH_ONE_SUCCESS, launch))
      .catch((error) => commit(launchesTypes.FETCH_ONE_FAILURE, error));
  },
  async updateOne({ commit }, opts) {
    commit(launchesTypes.UPDATE_ONE_REQUEST);

    return launchesAPI
      .updateOne(opts)
      .then((launch) => commit(launchesTypes.UPDATE_ONE_SUCCESS, launch))
      .catch((error) => commit(launchesTypes.UPDATE_ONE_FAILURE, error));
  },
  async updateTask({ commit }, opts) {
    commit(launchesTypes.UPDATE_LAUNCH_TASK_REQUEST);

    return launchesAPI
      .updateTask(opts)
      .then((launch) => commit(launchesTypes.UPDATE_LAUNCH_TASK_SUCCESS, launch))
      .catch((error) => commit(launchesTypes.UPDATE_LAUNCH_TASK_FAILURE, error));
  },
  async removeTask({ commit }, opts) {
    commit(launchesTypes.REMOVE_LAUNCH_TASK_REQUEST);

    return launchesAPI
      .removeTask(opts)
      .then((launch) => commit(launchesTypes.REMOVE_LAUNCH_TASK_SUCCESS, launch))
      .catch((error) => commit(launchesTypes.REMOVE_LAUNCH_TASK_FAILURE, error));
  },
  async addTaskGroup({ commit }, opts) {
    commit(launchesTypes.ADD_LAUNCH_TASK_GROUP_REQUEST);

    return launchesAPI
      .addTaskGroup(opts)
      .then((launch) => commit(launchesTypes.ADD_LAUNCH_TASK_GROUP_SUCCESS, launch))
      .catch((error) => commit(launchesTypes.ADD_LAUNCH_TASK_GROUP_FAILURE, error));
  },
  async removeTaskGroup({ commit }, opts) {
    commit(launchesTypes.REMOVE_LAUNCH_TASK_GROUP_REQUEST);

    return launchesAPI
      .removeTaskGroup(opts)
      .then((launch) => commit(launchesTypes.REMOVE_LAUNCH_TASK_GROUP_SUCCESS, launch))
      .catch((error) => commit(launchesTypes.REMOVE_LAUNCH_TASK_GROUP_FAILURE, error));
  },
  async setCurrentLaunch({ commit }, launch) {
    commit(launchesTypes.SET_CURRENT_LAUNCH, launch);
  },
};

const mutations = {
  [launchesTypes.CREATE_LAUNCH_ACTION_REQUEST](state) {
    state.createLaunch.pending = true;
  },
  [launchesTypes.CREATE_LAUNCH_ACTION_SUCCESS](state, launch) {
    state.current = launch;
    state.all.push(launch);
    state.createLaunch.pending = false;
    state.createLaunch.error = null;
  },
  [launchesTypes.CREATE_LAUNCH_ACTION_FAILURE](state, error) {
    state.currentKitchen = null;
    state.createLaunch.pending = false;
    state.createLaunch.error = error;
  },
  [launchesTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [launchesTypes.FETCH_ALL_SUCCESS](state, launches) {
    state.all = launches;
    state.fetchAll.pending = false;
  },
  [launchesTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
  [launchesTypes.FETCH_ONE_REQUEST](state) {
    state.fetchOne.pending = true;
  },
  [launchesTypes.FETCH_ONE_SUCCESS](state, launch) {
    state.current = launch;
    state.fetchOne.pending = false;
  },
  [launchesTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
  [launchesTypes.UPDATE_ONE_REQUEST](state) {
    state.updateOne.pending = true;
  },
  [launchesTypes.UPDATE_ONE_SUCCESS](state, launch) {
    state.current = launch;
    const index = state.all.findIndex((item) => item.uuid === launch.uuid);
    if (index !== -1) {
      state.all.splice(index, 1, launch);
    }
    state.updateOne.pending = false;
  },
  [launchesTypes.UPDATE_ONE_FAILURE](state, error) {
    state.current = null;
    state.updateOne.pending = false;
    state.updateOne.error = error;
  },
  [launchesTypes.UPDATE_LAUNCH_TASK_REQUEST](state) {
    state.updateTask.pending = true;
  },
  [launchesTypes.UPDATE_LAUNCH_TASK_SUCCESS](state, launch) {
    state.current = launch;
    state.updateTask.pending = false;
  },
  [launchesTypes.UPDATE_LAUNCH_TASK_FAILURE](state, error) {
    state.current = null;
    state.updateTask.pending = false;
    state.updateTask.error = error;
  },
  [launchesTypes.REMOVE_LAUNCH_TASK_REQUEST](state) {
    state.removeTask.pending = true;
  },
  [launchesTypes.REMOVE_LAUNCH_TASK_SUCCESS](state, launch) {
    state.current = launch;
    state.removeTask.pending = false;
  },
  [launchesTypes.REMOVE_LAUNCH_TASK_FAILURE](state, error) {
    state.current = null;
    state.removeTask.pending = false;
    state.removeTask.error = error;
  },
  [launchesTypes.ADD_LAUNCH_TASK_GROUP_REQUEST](state) {
    state.addTaskGroup.pending = true;
  },
  [launchesTypes.ADD_LAUNCH_TASK_GROUP_SUCCESS](state, launch) {
    state.current = launch;
    state.addTaskGroup.pending = false;
  },
  [launchesTypes.ADD_LAUNCH_TASK_GROUP_FAILURE](state, error) {
    state.current = null;
    state.addTaskGroup.pending = false;
    state.addTaskGroup.error = error;
  },
  [launchesTypes.REMOVE_LAUNCH_TASK_GROUP_REQUEST](state) {
    state.removeTaskGroup.pending = true;
  },
  [launchesTypes.REMOVE_LAUNCH_TASK_GROUP_SUCCESS](state, launch) {
    state.current = launch;
    state.removeTaskGroup.pending = false;
  },
  [launchesTypes.REMOVE_LAUNCH_TASK_GROUP_FAILURE](state, error) {
    state.current = null;
    state.removeTaskGroup.pending = false;
    state.removeTaskGroup.error = error;
  },
  [launchesTypes.SET_CURRENT_LAUNCH](state, launch) {
    state.current = launch;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
