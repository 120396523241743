import axios from '@/axios';
import { baseUrlV2 } from '@/apis'


const ressourcePath = `${baseUrlV2}/countries`;

export default {
/* eslint-disable */
  getCountries(queryParameters) {
    if (!queryParameters) {
      queryParameters = '';
    }
    return axios.get(`${ressourcePath}${queryParameters}`)
                .then(response => response.data)
  },
};
