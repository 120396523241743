<template>
  <div class="box modal-card" style="overflow: visible;">
    <b-loading :active.sync="isLoading"></b-loading>
    <p class="title is-4"> Set Up Menu </p>
    <div>
      <b-field label="Menu" expanded>
        <b-select
          type="text"
          v-model="selectedMenu"
          placeholder="Menu"
          expanded
        >
          <option v-for="(menu, index) in menusSorted" :key="index" :value="menu">
            {{ menu.label }}
          </option>
        </b-select>
      </b-field>
      <b-button v-if="selectedMenu" type="is-info" class="button-gap"  @click="select()" expanded>
        Select
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { utils } from '@/mixins';

export default {
  mixins: [utils],
  components: {
  },
  props: ['conceptUUID'],
  data() {
    return {
      selectedMenu: null,
    }
  },
  computed: {
    ...mapState({
      menuVariants: (state) => state.menus.allVariants,
      isLoading: (state) => state.menus.fetchAllVariants.pending,
    }),
    menusSorted() {
      var menuVariantsSorted = [...this.menuVariants]
      for (let menuVariant of menuVariantsSorted) {
        menuVariant['label'] = menuVariant.labels[0].value
      }
      return menuVariantsSorted.sort((menu1, menu2) => menu1.label.localeCompare(menu2.label));
    },
  },
  methods: {
    select() {
      this.$emit('setUpMenu', {
        menu_uuid: this.selectedMenu.menu_uuid,
      })
      this.$emit('close');
    },
  },
  mounted() {
    Promise.all(
      [
        this.getMenuVariants(`?is_deleted=false&concept_uuid=${this.conceptUUID}`, true),
      ]
    )
  }
}
</script>


<style>

.my-modal .modal-close {
  background: red;
}

</style>
