import axios from '@/axios';
import { baseOnboardingUrl } from '@/apis'


const ressourcePath = `${baseOnboardingUrl}/tasks`;

export default {
/* eslint-disable */
  createOne(opts) {
    return axios.post(ressourcePath, opts)
                .then(response => response.data)
  },
  updateOne(taskUUID, opts) {
    return axios.patch(`${ressourcePath}/${taskUUID}`, opts)
                .then(response => response.data)
  },
  deleteOne(taskUUID) {
    return axios.delete(`${ressourcePath}/${taskUUID}`)
                .then(response => response.data)
  },
  fetchOne(taskUUID) {
    return axios.get(`${ressourcePath}/${taskUUID}`)
                .then(response => response.data)
  },
  fetchAll(queryParameters) {
    if (!queryParameters) {
      queryParameters = '';
    }
    return axios.get(`${ressourcePath}${queryParameters}`)
                .then(response => response.data)
  },
};
