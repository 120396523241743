<template>
  <section class="section">
    <div class="container">
      <h1 class="title has-text-dark">
        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ $t('kitchens.title') }}
          </div>
          <div class="column">
            <button class="add-button button is-link is-small" @click="openManagementModal()">
              Kitchen Management
            </button>
          </div>
          <div>
            <b-switch
              size="is-medium"
              v-model="showCurrentKitchens"
              passive-type='is-dark'
              type='is-success'
            >
              {{ showCurrentKitchens ? "Current Kitchens" : "Deleted Kitchens" }}
            </b-switch>
          </div>
        </div>
      </h1>
      <hr />
      <section>
        <KitchenFilter
          :activeConcepts="activeConceptsSorted"
          :activePlatforms="activePlatformsSorted"
          :kitchenStatuses="kitchenStatuses"
          :selectedConcepts="selectedConcepts"
          :selectedPlatforms="selectedPlatforms"
          :selectedKitchenStatuses="selectedKitchenStatuses"
        />
      </section>
      <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <section v-if="kitchens.length > 0 && selectedConcepts && selectedConcepts.length > 0 && locations && locations.length > 0 && selectedPlatforms && selectedPlatforms.length > 0">
          <KitchenTable
            :concepts="selectedConcepts"
            :countries="countries"
            :locations="locations"
            :platforms="selectedPlatforms"
            :selectedKitchenStatuses="selectedKitchenStatuses"
            @edit-kitchen="openKitchenModal"
            @edit-restaurant="openRestaurantModal"
            @edit-restaurant-opening-hours="openRestaurantHours"
          />
        </section>
      </section>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState, mapMutations } from 'vuex';
import { kitchensTypes, restaurantsTypes } from '@/store/mutation-types';
import { utils } from '@/mixins';

import KitchenFilter from '@/components/Kitchen/Kitchen/KitchenFilter.vue';
import KitchenForm from '@/components/Kitchen/Kitchen/KitchenForm.vue';
import KitchenTable from '@/components/Kitchen/Kitchen/KitchenTable.vue';
import OpeningHours from '@/components/Kitchen/Restaurant/OpeningHours.vue';
import RestaurantForm from '@/components/Kitchen/Restaurant/RestaurantForm.vue';

export default {
  mixins: [utils],
  data() {
    return {
      showCurrentKitchens: true,
    };
  },
  computed: mapState({
    kitchens: (state) => state.kitchens.all,
    activeConceptsSorted(state) {
      return state.concepts.all.sort((concept1, concept2) =>
        concept1.label.localeCompare(concept2.label),
      );
    },
    countries: (state) => state.countries.all,
    activePlatformsSorted(state) {
      return state.platforms.all.sort((platform1, platform2) =>
        platform1.label.localeCompare(platform2.label),
      );
    },
    kitchenStatuses: (state) => state.kitchens.kitchenStatuses,
    locations: (state) => state.locations.all,
    isLoading: (state) => state.kitchens.fetchAll.pending || state.kitchens.updateOne.pending || state.restaurants.updateOne.pending,
    current_error: (state) => state.kitchens.current_error || state.restaurants.current_error,
    current_restaurant_success: (state) => state.restaurants.current_success,
    current_success: (state) => state.kitchens.current_success,
    selectedConcepts: (state) => state.concepts.selected,
    selectedPlatforms: (state) => state.platforms.selected,
    selectedKitchenStatuses: (state) => state.kitchens.selectedKitchenStatuses,
    openingHoursToExport: (state) => state.restaurants.openingHoursToExport,
    kitchenInformationToExport: (state) => state.kitchens.kitchenInformationToExport,
  }),
  methods: {
    ...mapActions('accesses', ['fetchGroups', 'fetchUsers']),
    ...mapMutations('kitchens', { setCurrentKitchenSuccess: kitchensTypes.SET_CURRENT_SUCCESS }),
    ...mapMutations('restaurants', { setCurrenRestaurantSuccess: restaurantsTypes.SET_CURRENT_SUCCESS, setCurrentRestaurantError: restaurantsTypes.SET_CURRENT_ERROR }),
    openKitchenModal: function(kitchenUUID, kitchenInfo, modificationDisabled) {
      var kitchen = this.$store.getters['kitchens/getKitchen'](kitchenUUID);
      var location = this.locations.find(location => location.uuid == kitchen.location_uuid);
      kitchen.launch_date = new Date(kitchen.launch_date);
      kitchen =  {...{'management_information': {}}, ...kitchen};
      var kitchenNotModified = {...kitchen};
      this.$buefy.modal.open({
        component: KitchenForm,
        parent: this,
        hasModalCard: true,
        events: {
          updated: (kitchenUUID, kitchen) => this.updateKitchen(kitchenUUID, kitchen),
          updateclosed: () => {
            kitchen = Object.assign(kitchen, kitchenNotModified);
          }
        },
        props: {
          kitchen: {...kitchen, ...kitchenInfo},
          location: location,
          kitchenStatuses: this.kitchenStatuses,
          modificationDisabled: modificationDisabled,
        },
      });
    },
    updateKitchen: function(kitchenUUID, kitchen) {
      this.$store.dispatch('kitchens/updateKitchen', {
        kitchenUUID: kitchenUUID,
        kitchen: kitchen,
      });
    },
    openRestaurantModal: function(kitchenUUID, restaurantUUID, modificationDisabled) {
      var restaurant = this.$store.getters['kitchens/getRestaurant'](kitchenUUID, restaurantUUID);
      restaurant.launch_date = new Date(restaurant.launch_date);
      var restaurantNotModified = {...restaurant};
      this.$buefy.modal.open({
        component: RestaurantForm,
        parent: this,
        hasModalCard: true,
        events: {
          updated: (restaurantUUID, restaurant) => this.updateRestaurant(kitchenUUID, restaurantUUID, restaurant),
          updateclosed: () => {
            restaurant = Object.assign(restaurant, restaurantNotModified);
          }
        },
        props: {
          restaurant: restaurant,
          modificationDisabled: modificationDisabled,
        },
      });
    },
    updateRestaurant: function(kitchenUUID, restaurantUUID, restaurant) {
      restaurant['kitchen_uuid'] = kitchenUUID;
      this.$store.dispatch('restaurants/updateRestaurant', {
        restaurantUUID: restaurantUUID,
        restaurant: restaurant,
      });
    },
    openRestaurantHours: function(kitchen, conceptUUID, openingHours) {
      var openingHoursFilled = this.fillOpeningHours(openingHours);
      this.$buefy.modal.open({
        component: OpeningHours,
        parent: this,
        hasModalCard: true,
        events: {},
        props: {
          currentConcept: conceptUUID,
          kitchen: kitchen,
          openingHours: openingHoursFilled,
        },
      });
    },
    fillOpeningHours: function(openingHours) {
      var openingHoursFilled = Object.assign({}, openingHours);
      var daysSorted = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      for (var i = 0; i < daysSorted.length; i++) {
        var day = daysSorted[i];
        if (!(day in openingHoursFilled)) {
          openingHoursFilled[day] = { lunch_shift: [], dinner_shift: [] };
        }
      }
      return openingHoursFilled;
    },
    openManagementModal: function() {
      this.$router.push('/kitchens/management')
    },
    displayError(errorMessage) {
      this.$buefy.toast.open({
        duration: 5000,
        message: errorMessage,
        type: 'is-danger',
      });
      this.$store.dispatch('kitchens/resetCurrentError');
    },
    displayKitchenSuccess(successMessage) {
      this.$buefy.toast.open({
        duration: 5000,
        message: successMessage,
        type: 'is-success',
      });
      this.$store.dispatch('kitchens/resetCurrentSuccess');
    },
    displayRestaurantSuccess(successMessage) {
      this.$buefy.toast.open({
        duration: 5000,
        message: successMessage,
        type: 'is-success',
      });
      this.$store.dispatch('restaurants/resetCurrentSuccess');
    },
  },
  mounted() {
    this.getConcepts('?is_deleted=false', true);
    this.getCountries('?is_deleted=false', true);
    this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false', true);
    this.getPlatforms('?is_deleted=false', true);
    this.getLocations('?is_deleted=false', true);
    this.getGroups();
    this.getUsers();
  },
  watch: {
    current_error(newValue, oldValue) {
      if (newValue) {
        this.displayError(newValue);
      }
    },
    current_success(newValue, oldValue) {
      if (newValue) {
        this.displayKitchenSuccess(newValue);
      }
    },
    current_restaurant_success(newValue, oldValue) {
      if (newValue) {
        this.displayRestaurantSuccess(newValue);
      }
    },
    showCurrentKitchens(newVal, oldVal) {
      if (oldVal != newVal) {
        if (newVal == true) {
          this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=false&is_deleted=false', true);
          this.getConcepts('?is_deleted=false', true);
          this.getCountries('?is_deleted=false', true);
          this.getPlatforms('?is_deleted=false', true);
          this.getLocations('?is_deleted=false', true);
        }
        else {
          this.getKitchens('?load_restaurants=true&load_secrets=true&load_restaurants_deleted=true&is_deleted=true', true);
          this.getConcepts('', true);
          this.getCountries('', true);
          this.getPlatforms('', true);
          this.getLocations('', true);
        }
      }
    },
  },
  components: {
    KitchenFilter,
    KitchenTable,
  },
};
</script>

<style>
.add-button {
  margin-top: 8px;
}
</style>
