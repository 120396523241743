import menusAPI from '../../../apis/menu_manager/menus';
import { menusTypes } from '../../mutation-types.js';

/* eslint-disable */

const state = {
  all: [],
  allVariants: [],
  currentConceptUUID: null,
  fetchAll: {
    pending: false,
    error: null,
  },
  fetchAllVariants: {
    pending: false,
    error: null,
  },
};

const actions = {
  async fetchAll({ commit }, payload) {
    commit(menusTypes.FETCH_ALL_REQUEST);
    return menusAPI.fetchAll(payload)
      .then((menus) => commit(menusTypes.FETCH_ALL_SUCCESS, menus))
      .catch((error) => commit(menusTypes.FETCH_ALL_FAILURE, error));
  },
  async fetchAllVariants({ commit }, payload) {
    commit(menusTypes.FETCH_ALL_VARIANTS_REQUEST);
    return menusAPI.fetchAllVariants(payload)
      .then((menus) => commit(menusTypes.FETCH_ALL_VARIANTS_SUCCESS, menus))
      .catch((error) => commit(menusTypes.FETCH_ALL_VARIANTS_FAILURE, error));
  },
};

const mutations = {
  [menusTypes.FETCH_ALL_REQUEST](state) {
    state.fetchAll.pending = true;
  },
  [menusTypes.FETCH_ALL_SUCCESS](state, response) {
    state.all = response;
    state.fetchAll.pending = false;
  },
  [menusTypes.FETCH_ALL_FAILURE](state, error) {
    state.all = null;
    state.fetchAll.pending = false;
    state.fetchAll.error = error;
  },
  [menusTypes.FETCH_ALL_VARIANTS_REQUEST](state) {
    state.fetchAllVariants.pending = true;
  },
  [menusTypes.FETCH_ALL_VARIANTS_SUCCESS](state, response) {
    state.allVariants = response;
    state.fetchAllVariants.pending = false;
  },
  [menusTypes.FETCH_ALL_VARIANTS_FAILURE](state, error) {
    state.allVariants = null;
    state.fetchAllVariants.pending = false;
    state.fetchAllVariants.error = error;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
